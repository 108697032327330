export const DAILIES_THEMES: Record<
  string,
  { theme: string; description: string }
> = {
  Mon: {
    theme: 'Humanities Monday',
    description: 'Putting Liberal Arts Education to Good Use',
  },
  Tue: {
    theme: 'Pop Culture Tuesday',
    description: 'Your Trash is Their Treasure',
  },
  Wed: {
    theme: 'Wildcard Wednesday',
    description: 'Themes, News, Miscellaneous',
  },
  Thu: {
    theme: 'Hard & Soft Sciences Thursday',
    description: 'Science is Nothing but PARception',
  },
  Fri: {
    theme: 'Wordplay Friday',
    description: 'Linguists and Cruciverbalists Unite',
  },
  Sat: {
    theme: 'Fine Arts Saturday',
    description: 'From Mojitos to Mozart',
  },
  Sun: {
    theme: 'Stressful Sunday',
    description: 'The Hardest Trivia Questions, Watch Your Scratch Streaks!',
  },
};
