export const getTeamTotalScore = (roundScores: { [key: string]: number }) => {
  if (
    !roundScores ||
    typeof roundScores !== 'object' ||
    Object.keys(roundScores).length === 0
  )
    return 0;

  let parScore = 0;
  for (const questionIndex of Object.keys(roundScores)) {
    const value = roundScores[questionIndex] as any;

    // this is a bad construct coming from the "input server"
    const score = typeof value !== 'number' ? 0 : value;

    parScore = parScore + (score || 0);
  }

  return parScore;
};
