import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { DailyPlayersResults } from './DailyPlayersResults';
import { CommunityStats } from './CommunityStats';
import { StyledPaperItem } from '../../mui-theme/mui-components';
import { useProfileContext } from '../../contexts/ProfileProvider';
import useFetchDailySetsPlayed from './hooks/useFetchDailySetsPlayed';
import { useLobbyGamesContext } from '../../contexts/LobbyGamesProvider';
import DailiesTitle from './DailiesTitle/DailiesTitle';
import DailiesHeader from './DailiesHeader/DailiesHeader';
import useFetchDailesOverview from './hooks/useFetchDailesOverview';
import useFetchDailesSets from './hooks/useFetchDailesSets';
import { Scorecard } from '../../components/scorecard';
import { DetailedPlayer } from '../../types/player';
import useQuickPlayManager from '../quick-play/hooks/useQuickPlayManager';
import { PlayDailyConfirmDialog } from './dialogs/PlayDailyConfirmDialog';
import { DailiesSet } from './types';
import Streaks from './Streaks/Streaks';
import BasicLayout from '../../BasicLayout/BasicLayout';

const DailiesPage = () => {
  const { profile, mutateProfile } = useProfileContext();

  const { maintenance } = useLobbyGamesContext();

  const [openPlayDailyConfirmDialog, setOpenPlayDailyConfirmDialog] =
    useState(false);
  const [selectedDailySet, setSelectedDailySet] = useState<DailiesSet>();

  const { data: dailiesOverview, refetch } = useFetchDailesOverview(
    profile._id,
    selectedDailySet?.dailySet
  );

  const handleDailyCountdownStop = () => {
    refetch();
  };

  const { data: dailiesSets } = useFetchDailesSets();

  const {
    currentDailySet = null,
    nextDailySchedule = null,
    dailiesAuthor = null,
    questionStats = null,
    dailiesResults = null,
    playerDailyScores = null,
    dailyGame = null,
    dailiesStreaks = null,
  } = dailiesOverview || {};

  const { data: dailySetsPlayed } = useFetchDailySetsPlayed(profile._id);

  const onCreateGameSuccess = () => {
    const newProfile = {
      ...profile,
      lastDailySetPlayed: currentDailySet,
    };
    mutateProfile(newProfile);
  };

  const { createGame } = useQuickPlayManager(onCreateGameSuccess);

  const handleDailySetChange = (dailySet: DailiesSet) => {
    setSelectedDailySet(dailySet);
    refetch();
  };

  const handlePlayDailyClick = async () => {
    const dailySet = !selectedDailySet
      ? currentDailySet
      : selectedDailySet.dailySet;
    const missedDaily =
      selectedDailySet && selectedDailySet.dailySet !== currentDailySet;

    const createGameConfig = {
      dailySet,
      missedDaily,
    };

    await createGame(profile, createGameConfig);
  };

  const isMissedDaily =
    selectedDailySet &&
    currentDailySet !== selectedDailySet.dailySet &&
    !dailySetsPlayed.find((set) => set.dailySet === selectedDailySet.dailySet);

  const isPlayedDaily = !!dailySetsPlayed?.find(
    (set) => set.dailySet === selectedDailySet?.dailySet
  );

  return (
    <>
      <BasicLayout>
        <Grid container spacing={2} className="CONTAINER" sx={{ pb: 20 }}>
          <Grid item xs={12} sm={12}>
            <DailiesTitle />
          </Grid>

          <Grid item xs={12} sm={12} className="HEADER-STUFF">
            <DailiesHeader
              currentDailySet={currentDailySet}
              dailiesAuthor={dailiesAuthor}
              dailiesResults={dailiesResults}
              dailiesSets={dailiesSets}
              dailySetsPlayed={dailySetsPlayed}
              handleDailyCountdownStop={handleDailyCountdownStop}
              handleDailySetChange={handleDailySetChange}
              handlePlayDailyClick={() => setOpenPlayDailyConfirmDialog(true)}
              isMissedDaily={isMissedDaily}
              isPlayedDaily={isPlayedDaily}
              maintenance={maintenance}
              nextDailySchedule={nextDailySchedule}
              profile={profile}
              selectedDailySet={selectedDailySet}
            />
          </Grid>

          <Grid item xs={12} sm={12} className="STREAKS">
            <Streaks dailiesStreaks={dailiesStreaks} />
          </Grid>

          <Grid item xs={12} sm={6} className="COMMUNITY-STATS">
            <StyledPaperItem>
              <CommunityStats
                dailiesAuthor={dailiesAuthor}
                dailiesQuestionStats={questionStats}
                dailiesResults={dailiesResults}
                isAdmin={profile.admin}
                missedDaily={playerDailyScores?.missedDaily}
                playerDailyScores={playerDailyScores?.scores}
                profileId={profile._id}
              />
            </StyledPaperItem>
          </Grid>

          <Grid item xs={12} sm={6} className="RESULTS">
            <StyledPaperItem>
              <DailyPlayersResults dailiesResults={dailiesResults} />
            </StyledPaperItem>
          </Grid>

          {dailyGame ? (
            <Grid item xs={12} sm={12}>
              <StyledPaperItem>
                <Scorecard
                  detailedPlayers={
                    dailyGame.game.players as Record<string, DetailedPlayer>
                  }
                  gameId={dailyGame.game.gameId}
                  hideTitle={true}
                  isDaily
                  mode={'solo'}
                  questionSet={dailyGame.questionSet}
                />
              </StyledPaperItem>
            </Grid>
          ) : null}
        </Grid>
      </BasicLayout>

      {openPlayDailyConfirmDialog && (
        <PlayDailyConfirmDialog
          handlePlayDailyClick={handlePlayDailyClick}
          isMissedDaily={isMissedDaily}
          maintenance={maintenance}
          onClose={() => setOpenPlayDailyConfirmDialog(false)}
          open={openPlayDailyConfirmDialog}
          selectedDailySet={selectedDailySet}
        />
      )}
    </>
  );
};

export default DailiesPage;
