import React from 'react';
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  styled,
  Box,
  Card,
} from '@mui/material';
import { Profile } from '../../../types/player';
import { avesData } from './utils';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    textAlign: 'center',
    fontWeight: 600,
  },
}));

type PropTypes = {
  viewProfile: Profile;
};

export const Aves = ({ viewProfile }: PropTypes) => {
  return (
    <Box pt={2}>
      <Typography py={1} fontWeight={500}>
        Birds
      </Typography>

      <TableContainer
        sx={{
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              {avesData(viewProfile).map((item) => (
                <StyledTableCell sx={{ color: item.color }}>
                  {item.name}
                </StyledTableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>Lifetime</TableCell>
              {avesData(viewProfile).map((item) => (
                <StyledTableCell sx={{ color: item.color }}>
                  {item.lifeTime}
                </StyledTableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>Per 100 Qs</TableCell>
              {avesData(viewProfile).map((item) => (
                <StyledTableCell sx={{ color: item.color }}>
                  {item.per100Qs}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
