import React from 'react';
import {
  Avatar,
  Box,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ArenaTeamLiveRoundScores, Player } from '../../../../../types/player';
import { getAvatarSrc } from '../../../../../api/cloudinary';
import { addPlusToPositiveScores } from '../../../../../utils/game';
import { ArenaTeam } from '../../../../arena-team/types';
import { SmallPlayerScoreBox } from './SmallPlayerScoreBox';

const AVATAR_HEIGHT = 64;
const AVATAR_WIDTH = 64;
const AVATAR_DIVIDER_SPACING = 2;

type Props = {
  arenaTeamId?: string;
  arenaTeam: ArenaTeam;
  arenaTeamLiveRoundScores?: ArenaTeamLiveRoundScores;
  arenaTeamScore?: number;
  player?: Player;
  playerInfoDisplayPosition: 'left' | 'right';
  questionIndex: number;
  spectate?: boolean;
  teamBestBalls?: Record<string, Player[]>;
  timerLimitReached: boolean;
};

export const ArenaTeamScoreHeader = ({
  arenaTeamId,
  arenaTeam,
  arenaTeamLiveRoundScores,
  arenaTeamScore,
  player,
  playerInfoDisplayPosition,
  questionIndex,
  spectate,
  teamBestBalls,
  timerLimitReached,
}: Props) => {
  const { palette } = useTheme();

  const teamPurple = '#ec9bff';
  const teamBlue = '#00e0ff';

  if (!arenaTeam) {
    return <Box></Box>;
  }

  const roundScores = arenaTeamLiveRoundScores?.[arenaTeamId];
  const currentScore =
    typeof roundScores?.[questionIndex] !== 'number'
      ? null
      : roundScores?.[questionIndex];
  const correctAnswer = currentScore !== undefined && currentScore !== null;

  const { avatarPublicId, avatarUrl, teamName } = arenaTeam;

  const boxShadow = () => {
    // if (penalty?.penaltyTimestamp) return 'warning.main';

    if (!correctAnswer && !timerLimitReached) return 'none';

    return correctAnswer
      ? `${palette.success.main} 0px 0px 20px 5px, ${palette.success.main} 0px 0px 5px 0px, ${palette.success.main} 0px 0px 5px 0px`
      : `${palette.error.light} 0px 0px 10px 0px, ${palette.error.light} 0px 0px 0px 0px, ${palette.error.light} 0px 0px 0px 0px`;
  };

  const opaqueBackground = () => {
    // if (penalty?.penaltyTimestamp) return palette.warning.main;
    if (correctAnswer) return `${palette.success.main}e0`;
    return `${palette.error.main}e0`;
  };

  const showScore = () =>
    (correctAnswer && currentScore !== undefined) ||
    (!correctAnswer && timerLimitReached);

  const dividerBorderColor = `${
    playerInfoDisplayPosition === 'left' ? teamPurple : teamBlue
  }${'ba'}`;

  return (
    <Box
      className="PlayerScoreHeader"
      height={'100%'}
      mt={AVATAR_DIVIDER_SPACING}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {playerInfoDisplayPosition === 'right' ? (
          <Stack
            className="ScorePenaltyStack"
            direction={'row'}
            width={'35%'}
            alignItems={'flex-start'}
            justifyContent={'center'}
            spacing={8}
          >
            {/* Score */}
            <Stack className="ScoreStack" alignItems={'center'}>
              <Typography variant="overline">Team Score</Typography>
              <Typography
                fontSize={24}
                sx={{
                  textShadow: '0px 0px 12px #ffffff, 0px 0px 12px #f8ee95',
                }}
              >
                {addPlusToPositiveScores(arenaTeamScore)}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Box width={'35%'}></Box>
        )}

        <Box
          className="PlayerAvatarBox"
          display={'flex'}
          justifyContent={'center'}
          width={'30%'}
        >
          <Box height={AVATAR_HEIGHT}>
            <Avatar
              src={getAvatarSrc(avatarPublicId, avatarUrl, 200, 200)}
              variant={'rounded'}
              sx={{
                height: AVATAR_HEIGHT,
                width: AVATAR_WIDTH,
                objectFit: 'cover',
                zIndex: 1,
                boxShadow: boxShadow,
              }}
            />

            {showScore() ? (
              <Avatar
                variant={'rounded'}
                sx={{
                  height: AVATAR_HEIGHT,
                  width: AVATAR_WIDTH,
                  zIndex: 1,
                  position: 'relative',
                  right: 0,
                  bottom: AVATAR_HEIGHT,
                  backgroundColor: opaqueBackground,
                }}
              >
                <Typography variant="h4">
                  {addPlusToPositiveScores(currentScore)}
                </Typography>
              </Avatar>
            ) : null}
          </Box>
        </Box>

        {playerInfoDisplayPosition === 'left' ? (
          <Stack
            className="ScorePenaltyStack"
            direction={'row'}
            width={'35%'}
            alignItems={'flex-start'}
            justifyContent={'center'}
            spacing={8}
          >
            <Stack className="ScoreStack" alignItems={'center'}>
              <Typography variant="overline">Team Score</Typography>
              <Typography
                fontSize={24}
                sx={{
                  textShadow: '0px 0px 12px #ffffff, 0px 0px 12px #f8ee95',
                }}
              >
                {addPlusToPositiveScores(arenaTeamScore)}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Box width={'35%'}></Box>
        )}
      </Stack>

      <Divider
        sx={{
          mt: AVATAR_DIVIDER_SPACING,
          '&::before': {
            borderTop: 'thin solid',
            borderColor: dividerBorderColor,
          },
          '&::after': {
            borderTop: 'thin solid',
            borderColor: dividerBorderColor,
          },
        }}
      >
        <Typography
          sx={{
            color: playerInfoDisplayPosition === 'left' ? teamPurple : teamBlue,
          }}
        >
          {teamName}
        </Typography>
      </Divider>

      <Stack
        direction={'row'}
        width={'100%'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box width={'20%'} display="flex" alignItems={'center'}>
          {!spectate && player && (
            <SmallPlayerScoreBox
              player={player}
              timerLimitReached={timerLimitReached}
            />
          )}
        </Box>

        <Stack
          direction={'row'}
          pt={1}
          spacing={0.5}
          height={65}
          width={'60%'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {teamBestBalls?.[arenaTeamId]?.map((p, i) => (
            <Stack key={i}>
              <Avatar
                src={getAvatarSrc(p.avatarPublicId, p.avatarUrl, 50, 50)}
                variant="rounded"
                sx={{
                  border: '3px solid',
                  borderColor: 'success.main',
                  height: 40,
                  width: 40,
                }}
              >
                {!p.avatarUrl ? p.username.charAt(0) : null}
              </Avatar>
              <Typography
                variant="caption"
                width={40}
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {p.username}
              </Typography>
            </Stack>
          ))}
        </Stack>

        <Box width={'20%'}></Box>
      </Stack>
    </Box>
  );
};
