import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Countdown from 'react-countdown';
import { countdownRendererMinutes } from '../utils/countdown';
import useQuickPlayManager from '../pages/quick-play/hooks/useQuickPlayManager';
import { LobbyGame } from '../types/game';
import { useLocation, useNavigate } from 'react-router-dom';
import useArenaGameManager from '../pages/arena/hooks/useArenaGameManager';
import { useLoggedInUserContext } from '../contexts/LoggedInUserProvider';
import { useSocketContext } from '../contexts/SocketProvider';
import { ArenaTeam } from '../pages/arena-team/types';
import useFetchArenaTeams from '../pages/profile/ArenaProfile/hooks/useFetchArenaTeams';
import useUpdateJoinArenaTeam from '../pages/profile/ArenaProfile/hooks/useUpdateJoinArenaTeam';

type PropTypes = {
  arenaGame: LobbyGame;
  onClose: () => void;
  open: boolean;
  profileId: string;
  season: number;
};

export const JoinArenaTeamBeforeGameDialog = ({
  arenaGame,
  onClose,
  open,
  profileId,
  season,
}: PropTypes) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const { loggedInUser } = useLoggedInUserContext();
  const { socket } = useSocketContext();

  const { arenaStartTime, gameId } = arenaGame;

  const { handleJoinArenaTeamGame } = useArenaGameManager();

  const handleJoinSuccess = () => {
    handleJoinClick();
  };

  const { updateJoinArenaTeam } = useUpdateJoinArenaTeam(handleJoinSuccess);

  const [isJoiningTeam, setIsJoiningTeam] = useState(false);

  const { data: arenaTeamsData } = useFetchArenaTeams(season);

  const handleJoinClick = () => {
    handleJoinArenaTeamGame(profileId, gameId);
    if (location.pathname !== '/game-session') {
      navigate('/pub');
    }
    onClose();
  };

  const handleSelectTeam = (team: ArenaTeam) => {
    setIsJoiningTeam(true);

    updateJoinArenaTeam({
      profileId,
      teamId: team._id,
      teamName: team.teamName,
      season,
    });
  };

  return (
    <Dialog open={open} maxWidth={'md'} fullWidth>
      <DialogTitle textAlign={'center'}>
        YOU MUST JOIN A TEAM TO PARTICIPATE IN PUB NIGHT
      </DialogTitle>
      <DialogContent>
        <Stack py={2}>
          <Typography fontFamily={'noto-sans'} textAlign={'center'} py={2}>
            Pub Game is starting in:
          </Typography>
          {arenaStartTime && (
            <Typography
              variant="h6"
              textAlign={'center'}
              sx={{
                pb: 1,
                color: theme.palette.primary.light,
                textShadow: `0 0 1px ${theme.palette.primary.main}, 10px 0px 20px ${theme.palette.primary.main}, 0px 0px 16px ${theme.palette.primary.main}`,
              }}
            >
              <Countdown
                date={arenaStartTime}
                renderer={countdownRendererMinutes}
                onComplete={onClose}
              />
            </Typography>
          )}

          <Typography fontFamily={'noto-sans'} textAlign={'center'} pt={2}>
            Select a team to join the game
          </Typography>

          <Stack direction={'row'} py={4} spacing={4} justifyContent={'center'}>
            {arenaTeamsData?.map((team, i) => (
              <Card key={i}>
                <CardActionArea
                  onClick={() => handleSelectTeam(team)}
                  disabled={isJoiningTeam}
                >
                  <CardMedia
                    component="img"
                    height={160}
                    image={team.avatarUrl}
                    alt={team.teamName}
                    sx={{
                      objectFit: 'contain',
                    }}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      component="div"
                      textAlign={'center'}
                    >
                      {team.teamName}
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      component="div"
                      textAlign={'center'}
                    >
                      {team.members.length} Members
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </Stack>

          <Box width={'100%'} display="flex" justifyContent={'center'}>
            <Button onClick={onClose} disabled={isJoiningTeam}>
              I DONT WANT TO PLAY
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
