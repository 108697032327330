import { Stack, Typography } from '@mui/material';
import React from 'react';
import { getWhosOnlineCount } from '.';
import { Profile } from '../../types/player';
import { LoggedInUser } from '../../types/user';

type Props = {
  profile: Profile;
  loggedInUsers: LoggedInUser[];
};

const PlayersOnlineCount = ({ profile, loggedInUsers }: Props) => {
  return (
    <Stack
      direction={'row'}
      spacing={1}
      alignItems={'center'}
      justifyContent={'center'}
      overflow={'auto'}
    >
      <Typography color="success.main">
        {getWhosOnlineCount(loggedInUsers, profile?._id)}
      </Typography>{' '}
      <Typography textTransform={'capitalize'} whiteSpace={'nowrap'}>
        Player
        {getWhosOnlineCount(loggedInUsers, profile?._id) !== 1 ? 's' : ''}{' '}
        Online
      </Typography>
    </Stack>
  );
};

export default PlayersOnlineCount;
