import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { Stack } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { SUB_CATEGORIES } from './constants';
import usePostCategoryBans from './usePostCategoryBans';
import { useProfileContext } from '../../../contexts/ProfileProvider';

type PropTypes = {
  bannedCategories: string[];
  close: () => void;
  open: boolean;
  profileId: string;
};

export const CategoryBansDialog = ({
  bannedCategories,
  close,
  open,
  profileId,
}: PropTypes) => {
  const theme = useTheme();

  const { refetch } = useProfileContext();

  const [checked, setChecked] = useState<string[]>(bannedCategories);

  const onSuccess = () => {
    refetch();
    close();
  };

  const { mutate, isPending } = usePostCategoryBans(onSuccess);

  const handleToggle = (category: string) => () => {
    if (checked.length === 1 && !checked.includes(category)) return;
    let newChecked;
    if (checked.includes(category)) {
      newChecked = checked.filter((c) => c !== category);
    } else {
      newChecked = checked.concat(category);
    }
    setChecked(newChecked);
  };

  const handleSaveBan = async () => {
    mutate({
      profileId,
      categoryBans: checked,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="sm"
      sx={{
        '& .paper': {
          borderStyle: 'solid',
          borderColor: theme.palette.ranked.main,
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h5" textAlign={'center'} sx={{ letterSpacing: 5 }}>
          Ban Category
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          spacing={2}
          sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}
        >
          <Typography
            variant="h6"
            fontWeight={600}
            sx={{ color: theme.palette.warning.main }}
          >
            {1 - checked.length}
          </Typography>
          <Typography variant="h6">
            VOTE{checked.length !== 1 ? '' : 'S'} LEFT
          </Typography>
        </Stack>
        <List
          sx={{
            width: 300,
            maxWidth: 500,
            bgcolor: 'background.paper',
          }}
        >
          {SUB_CATEGORIES.map((category) => {
            const labelId = `checkbox-list-label-${category}`;

            return (
              <ListItem key={category} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(category)}
                  dense
                  sx={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.includes(category)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      checkedIcon={
                        <CloseIcon sx={{ color: theme.palette.warning.main }} />
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={category}
                    primaryTypographyProps={{
                      color: 'primary',
                      fontWeight: 'medium',
                      variant: 'body2',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close} fullWidth>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSaveBan}
          fullWidth
          disabled={isPending}
          startIcon={
            isPending ? <CircularProgress size={20} color="inherit" /> : null
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
