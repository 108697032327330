import { Card, Stack, Box, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import { ArenaPlayerGame, TrueSkill } from '../types';
import { useScorecardDialog } from '../../../../contexts/ScorecardDialogProvider';
import RecentGamesCard from './RecentGamesCard';
import { RecentGamesAllDialog } from './dialogs/RecentGamesAllDialog';

type Props = {
  arenaPlayerGames: ArenaPlayerGame[];
  profileId: string;
  season: number;
};

const RecentGames = ({ arenaPlayerGames, profileId, season }: Props) => {
  const { viewScorecardDialog } = useScorecardDialog();

  const [openRecentGamesAllDialog, setOpenRecentGamesAllDialog] =
    useState(false);

  return (
    <>
      <Box pt={2}>
        <Typography py={1} fontWeight={500}>
          Recent Games
        </Typography>

        <Stack
          direction={'row'}
          spacing={2}
          pt={2}
          px={2}
          alignContent={'center'}
          sx={{
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
          }}
        >
          {arenaPlayerGames?.length ? (
            arenaPlayerGames
              .sort((a, b) => b.createdAt - a.createdAt)
              .slice(0, 6)
              .map((game, i) => (
                <RecentGamesCard
                  key={i}
                  handleCardClick={viewScorecardDialog}
                  arenaPlayerGame={game}
                />
              ))
          ) : (
            <Box width="100%" height={'100%'}>
              <Typography py={8} variant="body2" textAlign={'center'}>
                No recent games
              </Typography>
            </Box>
          )}
        </Stack>

        <Box display={'flex'} justifyContent={'right'} py={1} pr={1}>
          {arenaPlayerGames && arenaPlayerGames.length > 6 && (
            <Button onClick={() => setOpenRecentGamesAllDialog(true)}>
              View All
            </Button>
          )}
        </Box>
      </Box>

      <RecentGamesAllDialog
        arenaPlayerGames={arenaPlayerGames}
        open={openRecentGamesAllDialog}
        onClose={() => setOpenRecentGamesAllDialog(false)}
      />
    </>
  );
};

export default RecentGames;
