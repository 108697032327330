import { useQuery } from '@tanstack/react-query';
import { fetchArenaOverview } from '../../../../api/apiServices';

const useFetchArenaOverview = (profileId: string, season?: number) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['arenaOverview', { profileId, season }],
    queryFn: fetchArenaOverview,
    enabled: !!profileId && !!season,
  });

  return {
    refetch,
    isLoading,
    data,
  };
};

export default useFetchArenaOverview;
