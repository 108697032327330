import { Components, Paper, styled, Theme } from '@mui/material';

// const ledStyle = (theme: Theme) => ({
//   display: 'block',
//   textShadow: `
//       0 0 0px ${theme.palette.primary.main},
//       0 0 0px ${theme.palette.primary.main},
//       0px 0px 16px ${theme.palette.primary.main}`,
// });

export const muiCustomComponent = (
  theme: Theme
): Components<Omit<Theme, 'components'>> => ({
  MuiLink: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: '#cda68c',
        textDecorationColor: '#cda68c66',
      }),
    },
  },
});
//   MuiDialog: {
//     styleOverrides: {
//       paper: {
//         backgroundColor: theme.palette.mode === 'dark' ? '#212121' : '#223e64',
//         backgroundImage: 'none',
//         border: '1px solid',
//         boxShadow: `
//             0px 0px 4px -1px ${theme.palette.primary.main},
//             0px 0px 5px 0px ${theme.palette.primary.main},
//             0px 0px 3px 0px ${theme.palette.primary.main},
//             inset 0px 0px 4px -1px ${theme.palette.primary.main},
//             inset 0px 0px 5px 0px ${theme.palette.primary.main},
//             inset 0px 0px 3px 0px ${theme.palette.primary.main}`,
//       },
//     },
//   },
//   MuiModal: {
//     styleOverrides: {
//       root: ({ theme }: any) => ({
//         ...(theme.palette.mode === 'dark' &&
//           {
//             // backgroundColor: '#3a3939ba',
//           }),
//       }),
//     },
//   },
//   MuiPaper: {
//     variants: [
//       {
//         props: { variant: 'error' },
//         style: {
//           boxShadow:
//             '0px 3px 5px -1px #c31b1b33, 0px 6px 10px 0px #d20a0a33, 0px 1px 18px 0px #cb0d0d4d',
//         },
//       },
//     ],
//   },
//   MuiTypography: {
//     variants: [
//       {
//         props: { variant: 'h1led' },
//         style: {
//           ...theme.typography.h1,
//           ...ledStyle(theme),
//         },
//       },
//       {
//         props: { variant: 'h2led' },
//         style: {
//           ...theme.typography.h2,
//           ...ledStyle(theme),
//         },
//       },
//       {
//         props: { variant: 'h3led' },
//         style: {
//           ...theme.typography.h3,
//           ...ledStyle(theme),
//         },
//       },
//       {
//         props: { variant: 'h4led' },
//         style: {
//           ...theme.typography.h4,
//           ...ledStyle(theme),
//         },
//       },
//       {
//         props: { variant: 'h5led' },
//         style: {
//           ...theme.typography.h5,
//           ...ledStyle(theme),
//         },
//       },
//       {
//         props: { variant: 'h6led' },
//         style: {
//           ...theme.typography.h6,
//           ...ledStyle(theme),
//         },
//       },
//       {
//         props: { variant: 'body1led' },
//         style: {
//           ...theme.typography.body1,
//           ...ledStyle(theme),
//         },
//       },
//       {
//         props: { variant: 'subtitle2led' },
//         style: {
//           ...theme.typography.subtitle2,
//           ...ledStyle(theme),
//         },
//       },
//     ],
//   },
// });

export const StyledPaperItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F9F5F2',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
