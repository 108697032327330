import React, { useState } from 'react';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LensBlur } from '@mui/icons-material';
import { CategoryBansDialog } from './CategoryBansDialog';
import { CreateGameConfig } from '../../../types/game';
import { ValorantButton } from '../../quick-play/ValorantButton/ValorantButton';
import { useLobbyGamesContext } from '../../../contexts/LobbyGamesProvider';
import { useProfileContext } from '../../../contexts/ProfileProvider';
import BasicLayout from '../../../BasicLayout/BasicLayout';

const RankedMenu = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { profile } = useProfileContext();
  const { maintenance, handlePlayClick2 } = useLobbyGamesContext();
  const playClicked = false;

  const [openCategoryBans, setOpenCategoryBans] = useState(false);

  const handlePlayNormal = () => {
    const createGameConfigs: CreateGameConfig = {
      allowSpectators: false,
      categorySet: 'all',
      isRanked: true,
      mode: 'sit-n-go',
    };
    handlePlayClick2(createGameConfigs);
    navigate('/lobby');
  };

  return (
    <>
      <BasicLayout>
        <Stack pt={4}>
          <Stack
            spacing={4}
            justifyContent={'center'}
            alignItems={'center'}
            py={1}
          >
            <Stack spacing={1}>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <LensBlur htmlColor={theme.palette.ranked.main} />
                <Typography>
                  Ranked games are live multiplayer games betweeen 2 to 10
                  players
                </Typography>
                <LensBlur htmlColor={theme.palette.ranked.main} />
              </Stack>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <LensBlur htmlColor={theme.palette.ranked.main} />
                <Typography>Boost your MMR with every win</Typography>
                <LensBlur htmlColor={theme.palette.ranked.main} />
              </Stack>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <LensBlur htmlColor={theme.palette.ranked.main} />
                Play 10 ranked matches to calibrate and earn a place on the
                leaderboard
                <LensBlur htmlColor={theme.palette.ranked.main} />
              </Stack>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <LensBlur htmlColor={theme.palette.ranked.main} />
                In ranked, you can ban a category
                <LensBlur htmlColor={theme.palette.ranked.main} />
              </Stack>
            </Stack>
            <Button
              variant="contained"
              color="primary"
              sx={{
                letterSpacing: 5,
              }}
              onClick={() => setOpenCategoryBans(true)}
            >
              Ban Category
            </Button>
          </Stack>

          <Typography py={2} textAlign={'center'}>
            Create a game for others to join in the lobby.
          </Typography>

          <Box pt={2} display="flex" justifyContent={'center'}>
            <ValorantButton
              disabled={playClicked || maintenance?.scheduled}
              label={'CREATE GAME'}
              loading={playClicked}
              loadingLabel={'CREATING'}
              onClick={handlePlayNormal}
              size="large"
            />
          </Box>
        </Stack>
      </BasicLayout>

      <CategoryBansDialog
        bannedCategories={profile.bannedCategories}
        close={() => setOpenCategoryBans(false)}
        open={openCategoryBans}
        profileId={profile._id}
      />
    </>
  );
};

export default RankedMenu;
