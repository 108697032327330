import React from 'react';
import { QuestionStats, QuestionStatsLean } from '../../types/question-stats';
import { Bar } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';

export const vibrantColors = [
  'rgb(0, 255, 0, 0.5)',
  'rgb(26, 255, 0, 0.5)',
  'rgb(51, 255, 0, 0.5)',
  'rgb(77, 255, 0, 0.5)',
  'rgb(102, 255, 0, 0.5)',
  'rgb(128, 255, 0, 0.5)',
  'rgb(153, 255, 0, 0.5)',
  'rgb(179, 255, 0, 0.5)',
  'rgb(204, 255, 0, 0.5)',
  'rgb(230, 255, 0, 0.5)',
  'rgb(255, 255, 0, 0.5)',
  'rgb(255, 230, 0, 0.5)',
  'rgb(255, 204, 0, 0.5)',
  'rgb(255, 179, 0, 0.5)',
  'rgb(255, 153, 0, 0.5)',
  'rgb(255, 128, 0, 0.5)',
  'rgb(255, 102, 0, 0.5)',
  'rgb(255, 77, 0, 0.5)',
  'rgb(255, 51, 0, 0.5)',
  'rgb(255, 26, 0, 0.5)',
  'rgb(255, 0, 0, 0.5)',
];

export const getBarChart = (questionStats: QuestionStats) => {
  if (!questionStats) return;
  const scoreCountsData = Object.values(questionStats.scoreCounts).map(
    (sc) => sc.length
  );
  const data = {
    labels: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ],
    datasets: [
      {
        data: scoreCountsData,
        backgroundColor: vibrantColors,
        borderColor: vibrantColors.map((color) => color.replace('0.5)', '1)')),
        borderWidth: 1,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };
  return (
    <Bar
      data={data}
      options={options}
      style={{
        borderBottom: '1px solid',
        borderColor: 'rgb(80, 100, 120)',
      }}
    />
  );
};

export const getBarChartForScorecard = (
  questionStats: QuestionStatsLean,
  displayQuestionStatSeen?: boolean
) => {
  if (!questionStats) return;
  const data = {
    labels: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ],
    datasets: [
      {
        data: questionStats.scoreCounts,
        backgroundColor: vibrantColors,
        borderColor: vibrantColors.map((color) => color.replace('0.5)', '1)')),
        borderWidth: 1,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false, // Ensure datalabels are not displayed
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <Box sx={{ position: 'relative' }}>
      {displayQuestionStatSeen ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            transform: 'translate(-50%, -50%)',
            opacity: 0.8,
          }}
        >
          {questionStats.seen}
        </div>
      ) : null}
      <Bar
        data={data}
        options={options}
        style={{
          borderBottom: '1px solid',
          borderColor: 'rgb(80, 100, 120)',
        }}
      />
    </Box>
  );
};
