import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Stack } from '@mui/material';
import { AUTH_TYPE_BUTTON_LABEL } from './constants';
import { useResetPassword } from '../../../../hooks/auth/useResetPassword';
import { TitleAuth } from './TitleAuth';
import { InputPassword } from './InputPassword';

type PropTypes = {
  token: string;
};
export const ResetPassword = ({ token }: PropTypes) => {
  const { error, resetPassword } = useResetPassword();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const passwordRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (!error) return;
    setPasswordErrorMessage(error.message);
  }, [error]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!password) {
      return setPasswordErrorMessage('Fill in all fields');
    }

    setPasswordErrorMessage('');
    resetPassword(token, password);
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 5 }}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2} padding={2}>
          <TitleAuth authType={'resetPassword'} />

          <InputPassword
            inputRef={passwordRef}
            passwordErrorMessage={passwordErrorMessage}
            setPasswordErrorMessage={setPasswordErrorMessage}
            password={password}
            setPassword={setPassword}
          />

          <Button type="submit" variant="contained" fullWidth>
            {AUTH_TYPE_BUTTON_LABEL['resetPassword']}
          </Button>
        </Stack>
      </form>
    </Container>
  );
};
