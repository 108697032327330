// ColorModeProvider.tsx
import React, { createContext, FC, ReactNode } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useColorMode } from './useColorMode';
import { muiCustomComponent } from './mui-components';

interface ColorModeContextType {
  toggleColorMode: () => void;
}

export const ColorModeContext = createContext<ColorModeContextType>({
  toggleColorMode: () => {},
});

type ColorModeProviderProps = {
  children: ReactNode;
};

export const ColorModeProvider: FC<ColorModeProviderProps> = ({ children }) => {
  const [theme, toggleColorMode] = useColorMode();

  return (
    <ColorModeContext.Provider value={{ toggleColorMode }}>
      <ThemeProvider
        theme={{ ...theme, components: muiCustomComponent(theme) }}
      >
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
