export const getTierBadge = (tier: string) => {
  switch (tier) {
    case 'Lumen':
      return '/tier-lumen.png';
    case 'Stella':
      return '/tier-stella.png';
    case 'Virtus':
      return '/tier-virtus.png';
    case 'Arcanus':
      return '/tier-arcanus.png';
    case 'Master':
      return '/tier-master.png';
    case 'Grandmaster':
      return '/tier-grandmaster.png';
    default:
      return '/tier-unranked.png';
  }
};
