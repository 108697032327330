import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ConfirmReady, GameDataFromServer, TeamPicking } from '../types/game';
import { useSocketContext } from './SocketProvider';
import { ChatMessage } from '../types/chat';
import { useProfileContext } from './ProfileProvider';
import { useLoggedInUserContext } from './LoggedInUserProvider';
import { LeagueGameType, SocketCallback } from '../types';
import { createContext, useContextSelector } from 'use-context-selector';
import { gameInProgress } from '../utils/loggedInUser';
import { Player } from '../types/player';
import { isMobile } from 'react-device-detect';
import {
  ArenaAchievement,
  ArenaCurrentSeason,
} from '../pages/profile/ArenaProfile/types';

interface GameContextType {
  gameId: string;
  setGameId: (gameId: string) => void;
  leagueGameType: LeagueGameType;
  setLeagueGameType: (type: LeagueGameType) => void;
  spectateGameId: string;
  setSpectateGameId: (gameId: string) => void;
  spectateLeagueGameType: LeagueGameType;
  setSpectateLeagueGameType: (type: LeagueGameType) => void;
  gameDataFromServer: GameDataFromServer;
  setGameDataFromServer: (gameDataFromServer: GameDataFromServer) => void;
  spectateGameDataFromServer: GameDataFromServer;
  setSpectateGameDataFromServer: (
    gameDataFromServer: GameDataFromServer
  ) => void;
  showPregame: boolean;
  setShowPregame: (open: boolean) => void;
  teamPickingStatus: TeamPicking;
  setTeamPickingStatus: (teamPicking: TeamPicking) => void;
  chatArena: ChatMessage[];
  chatGame: ChatMessage[];
  chatMembers: any[];
  allPlayersReady: string;
  showGameChat: boolean;
  setShowGameChat: (open: boolean) => void;
  showArenaChat: boolean;
  setShowArenaChat: (open: boolean) => void;
  showSpectatorChat: boolean;
  setShowSpectatorChat: (open: boolean) => void;
  requestTeamPickingStatus: () => void;
  clearGameData: () => void;
  clearChat: () => void;
  confirmReady: ConfirmReady;
  setConfirmReady: (confirmReady: ConfirmReady) => void;
  handleReadyClick: () => void;
  handleNotReadyClick: () => void;
  spectatePostGameId: string;
  setSpectatePostGameId: (gameId: string) => void;
  arenaAchievements: ArenaAchievement[];
  setArenaAchievements: (achievements: ArenaAchievement[]) => void;
  arenaPlayersProjectionAndTiers: Record<
    string,
    {
      projection: number;
      currentTier: string;
    }
  >;
  setArenaPlayersProjectionAndTiers: (
    expectedRank: Record<
      string,
      {
        projection: number;
        currentTier: string;
      }
    >
  ) => void;
}

const GameContext = createContext<GameContextType>(null);

type GameProviderProps = {
  children: ReactNode;
};

export const GameProvider: FC<GameProviderProps> = ({ children }) => {
  const { socket } = useSocketContext();
  const { profile } = useProfileContext();
  const { loggedInUser } = useLoggedInUserContext();
  const [initialLoad, setInitialLoad] = useState(true);
  const [gameId, setGameId] = useState('');
  const [leagueGameType, setLeagueGameType] = useState<LeagueGameType>();
  const [spectateGameId, setSpectateGameId] = useState('');
  const [spectateLeagueGameType, setSpectateLeagueGameType] =
    useState<LeagueGameType>();
  const [gameDataFromServer, setGameDataFromServer] =
    useState<GameDataFromServer>(null);
  const [spectateGameDataFromServer, setSpectateGameDataFromServer] =
    useState<GameDataFromServer>();
  const [showPregame, setShowPregame] = useState(false);
  const [teamPickingStatus, setTeamPickingStatus] = useState<TeamPicking>();
  const [chatGame, setChatGame] = useState<ChatMessage[]>();
  const [chatMembers, setChatMembers] = useState<any[]>();
  const [chatArena, setChatArena] = useState<ChatMessage[]>([]);
  const [allPlayersReady, setAllPlayersReady] = useState<string>();
  const [showArenaChat, setShowArenaChat] = useState(false);
  const [showGameChat, setShowGameChat] = useState(false);
  const [showSpectatorChat, setShowSpectatorChat] = useState(false);
  const [confirmReady, setConfirmReady] = useState<ConfirmReady>();
  const [spectatePostGameId, setSpectatePostGameId] = useState('');
  const [arenaAchievements, setArenaAchievements] =
    useState<ArenaAchievement[]>();
  const [arenaPlayersProjectionAndTiers, setArenaPlayersProjectionAndTiers] =
    useState<
      Record<
        string,
        {
          projection: number;
          currentTier: string;
        }
      >
    >({});

  const updatePlayerConfirmedReady = useCallback(
    (profileId: string, ready: boolean) => {
      setConfirmReady((prev) => {
        const player = prev.players[profileId];

        if (ready) {
          player.confirmedReady = true;
        } else {
          player.confirmedNotReady = true;
        }

        const updatedConfirmReady = {
          ...confirmReady,
          players: {
            ...prev.players,
            [profileId]: player,
          },
        };
        return updatedConfirmReady;
      });
    },
    [confirmReady]
  );

  useEffect(() => {
    if (!socket) return;

    socket.on('teamPickingStatus', setTeamPickingStatus);
    socket.on('chatArena', setChatArena);
    socket.on('chatGame', setChatGame);
    socket.on('chatMembers', setChatMembers);
    socket.on('allPlayersReady', (data, ack) => {
      setAllPlayersReady(data);

      // send received ack back to server
      if (typeof ack === 'function') {
        ack(profile._id);
      }
    });
    socket.on('qpConfirmReady', setConfirmReady);
    socket.on('qpPlayerIsReady', (profileId) =>
      updatePlayerConfirmedReady(profileId, true)
    );
    socket.on('qpPlayerIsNotReady', (profileId) =>
      updatePlayerConfirmedReady(profileId, false)
    );
    socket.on('gameDataFromServer', setGameDataFromServer);
    socket.on(
      'arenaPlayersProjectionAndTiers',
      setArenaPlayersProjectionAndTiers
    );

    return () => {
      socket.off('teamPickingStatus', setTeamPickingStatus);
      socket.off('chatArena', setChatArena);
      socket.off('chatGame', setChatGame);
      socket.off('chatMembers', setChatMembers);
      socket.off('allPlayersReady', setAllPlayersReady);
      socket.off('qpConfirmReady', setConfirmReady);
      socket.off('qpPlayerIsReady', updatePlayerConfirmedReady);
      socket.off('qpPlayerIsNotReady', updatePlayerConfirmedReady);
      socket.off('gameDataFromServer', setGameDataFromServer);
      socket.off(
        'arenaPlayersProjectionAndTiers',
        setArenaPlayersProjectionAndTiers
      );
    };
  }, [socket, updatePlayerConfirmedReady, showArenaChat]);

  useEffect(() => {
    if (!loggedInUser || !initialLoad) return;
    const {
      currentDailySet,
      currentGameId,
      currentLeagueGameType,
      currentMode,
      currentArenaSet,
    } = loggedInUser;

    if (currentGameId) {
      setGameId(currentGameId);

      if (isMobile || currentDailySet) {
        return;
      }

      if (!currentArenaSet) {
        setShowGameChat(true);
      }

      if (
        !currentArenaSet &&
        (currentMode === 'solo' ||
          currentMode === 'sit-n-go' ||
          (!currentLeagueGameType && currentMode === 'teams'))
      ) {
        return;
      }

      setLeagueGameType(currentLeagueGameType);
      if (!currentArenaSet && !gameInProgress(loggedInUser)) {
        setShowPregame(true);
      }
    } else {
      setGameId('');
      setSpectateGameId('');
      setLeagueGameType(null);
      setShowPregame(false);
    }
    setInitialLoad(false);
  }, [loggedInUser]);

  const requestTeamPickingStatus = useCallback(() => {
    if (!socket || !gameId || !profile) return;
    socket.emit('requestTeamPickingStatus', {
      gameId,
      profileId: profile._id,
    });
  }, [socket, gameId, profile]);

  const clearGameData = () => {
    setGameId('');
    setGameDataFromServer(null);
  };

  const clearChat = () => {
    setChatGame([]);
  };

  useEffect(() => {
    if (!loggedInUser) return;
    if (loggedInUser.currentGameId) {
      clearChat();
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (spectateGameDataFromServer && !spectateGameDataFromServer.arenaGame) {
      setShowSpectatorChat(true);
    }
  }, [spectateGameDataFromServer]);

  const handleReadyClick = () => {
    if (!profile || !socket) return;
    socket.emit('qpConfirmedReady', profile._id, (res: SocketCallback) => {
      // if (res.status === 'ok') {
      //   setConfirmedReady(true);
      // }
      // if (res.status === 'failed') {
      //   setSocketCallbackResponse(res);
      //   setOpenConfirmReadyModal({ open: false, username: undefined });
      // }
    });
  };

  const handleNotReadyClick = () => {
    if (!profile || !socket) return;
    socket.emit(
      'qpConfirmedNotReady',
      profile._id,
      (res: SocketCallback) => {}
    );
  };

  return (
    <GameContext.Provider
      value={{
        gameId,
        setGameId,
        leagueGameType,
        setLeagueGameType,
        gameDataFromServer,
        setGameDataFromServer,
        spectateGameId,
        setSpectateGameId,
        spectateLeagueGameType,
        setSpectateLeagueGameType,
        spectateGameDataFromServer,
        setSpectateGameDataFromServer,
        showGameChat,
        setShowGameChat,
        showArenaChat,
        setShowArenaChat,
        showSpectatorChat,
        setShowSpectatorChat,
        showPregame,
        setShowPregame,
        teamPickingStatus,
        setTeamPickingStatus,
        chatArena,
        chatGame,
        chatMembers,
        allPlayersReady,
        requestTeamPickingStatus,
        clearGameData,
        clearChat,
        confirmReady,
        setConfirmReady,
        handleReadyClick,
        handleNotReadyClick,
        spectatePostGameId,
        setSpectatePostGameId,
        arenaAchievements,
        setArenaAchievements,
        arenaPlayersProjectionAndTiers,
        setArenaPlayersProjectionAndTiers,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};

export const useGameContext = () => {
  const gameId = useContextSelector(GameContext, (context) => context?.gameId);
  const setGameId = useContextSelector(
    GameContext,
    (context) => context?.setGameId
  );

  const leagueGameType = useContextSelector(
    GameContext,
    (context) => context?.leagueGameType
  );
  const setLeagueGameType = useContextSelector(
    GameContext,
    (context) => context?.setLeagueGameType
  );

  const gameDataFromServer = useContextSelector(
    GameContext,
    (context) => context?.gameDataFromServer
  );
  const setGameDataFromServer = useContextSelector(
    GameContext,
    (context) => context?.setGameDataFromServer
  );

  const spectateGameId = useContextSelector(
    GameContext,
    (context) => context?.spectateGameId
  );
  const setSpectateGameId = useContextSelector(
    GameContext,
    (context) => context?.setSpectateGameId
  );

  const spectateLeagueGameType = useContextSelector(
    GameContext,
    (context) => context?.spectateLeagueGameType
  );
  const setSpectateLeagueGameType = useContextSelector(
    GameContext,
    (context) => context?.setSpectateLeagueGameType
  );

  const spectateGameDataFromServer = useContextSelector(
    GameContext,
    (context) => context?.spectateGameDataFromServer
  );
  const setSpectateGameDataFromServer = useContextSelector(
    GameContext,
    (context) => context?.setSpectateGameDataFromServer
  );

  const showPregame = useContextSelector(
    GameContext,
    (context) => context?.showPregame
  );
  const setShowPregame = useContextSelector(
    GameContext,
    (context) => context?.setShowPregame
  );

  const teamPickingStatus = useContextSelector(
    GameContext,
    (context) => context?.teamPickingStatus
  );
  const setTeamPickingStatus = useContextSelector(
    GameContext,
    (context) => context?.setTeamPickingStatus
  );

  const chatArena = useContextSelector(
    GameContext,
    (context) => context?.chatArena
  );

  const chatGame = useContextSelector(
    GameContext,
    (context) => context?.chatGame
  );

  const chatMembers = useContextSelector(
    GameContext,
    (context) => context?.chatMembers
  );

  const allPlayersReady = useContextSelector(
    GameContext,
    (context) => context?.allPlayersReady
  );

  const showGameChat = useContextSelector(
    GameContext,
    (context) => context?.showGameChat
  );

  const setShowGameChat = useContextSelector(
    GameContext,
    (context) => context?.setShowGameChat
  );

  const showArenaChat = useContextSelector(
    GameContext,
    (context) => context?.showArenaChat
  );

  const setShowArenaChat = useContextSelector(
    GameContext,
    (context) => context?.setShowArenaChat
  );

  const requestTeamPickingStatus = useContextSelector(
    GameContext,
    (context) => context?.requestTeamPickingStatus
  );

  const clearChat = useContextSelector(
    GameContext,
    (context) => context?.clearChat
  );

  const clearGameData = useContextSelector(
    GameContext,
    (context) => context?.clearGameData
  );

  const confirmReady = useContextSelector(
    GameContext,
    (context) => context?.confirmReady
  );

  const setConfirmReady = useContextSelector(
    GameContext,
    (context) => context?.setConfirmReady
  );

  const handleReadyClick = useContextSelector(
    GameContext,
    (context) => context?.handleReadyClick
  );

  const handleNotReadyClick = useContextSelector(
    GameContext,
    (context) => context?.handleNotReadyClick
  );

  const showSpectatorChat = useContextSelector(
    GameContext,
    (context) => context?.showSpectatorChat
  );

  const setShowSpectatorChat = useContextSelector(
    GameContext,
    (context) => context?.setShowSpectatorChat
  );

  const spectatePostGameId = useContextSelector(
    GameContext,
    (context) => context?.spectatePostGameId
  );

  const setSpectatePostGameId = useContextSelector(
    GameContext,
    (context) => context?.setSpectatePostGameId
  );

  const arenaAchievements = useContextSelector(
    GameContext,
    (context) => context?.arenaAchievements
  );

  const setArenaAchievements = useContextSelector(
    GameContext,
    (context) => context?.setArenaAchievements
  );

  const arenaPlayersProjectionAndTiers = useContextSelector(
    GameContext,
    (context) => context?.arenaPlayersProjectionAndTiers
  );

  const setArenaPlayersProjectionAndTiers = useContextSelector(
    GameContext,
    (context) => context?.setArenaPlayersProjectionAndTiers
  );

  return {
    gameId,
    setGameId,
    leagueGameType,
    setLeagueGameType,
    gameDataFromServer,
    setGameDataFromServer,
    spectateGameId,
    setSpectateGameId,
    spectateLeagueGameType,
    setSpectateLeagueGameType,
    spectateGameDataFromServer,
    setSpectateGameDataFromServer,
    showPregame,
    setShowPregame,
    teamPickingStatus,
    setTeamPickingStatus,
    chatArena,
    chatGame,
    chatMembers,
    allPlayersReady,
    showGameChat,
    showSpectatorChat,
    setShowSpectatorChat,
    setShowGameChat,
    showArenaChat,
    setShowArenaChat,
    requestTeamPickingStatus,
    clearChat,
    clearGameData,
    confirmReady,
    setConfirmReady,
    handleReadyClick,
    handleNotReadyClick,
    spectatePostGameId,
    setSpectatePostGameId,
    arenaAchievements,
    setArenaAchievements,
    arenaPlayersProjectionAndTiers,
    setArenaPlayersProjectionAndTiers,
  };
};
