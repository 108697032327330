import { useApi } from '../useApi';
import { Methods } from './constants';
import { useEffect } from 'react';
import { useProfileContext } from '../../contexts/ProfileProvider';
import { useGoogleLogin as useGoogleLoginOauth } from '@react-oauth/google';
import { Profile } from '../../types/player';

export const useGoogleLogin = () => {
  const { refetch } = useProfileContext();
  const { data, error, loading, sendRequest } = useApi();

  useEffect(() => {
    if (data && !error) {
      refetch();
    }
  }, [data, error, refetch]);

  const googleLogin = useGoogleLoginOauth({
    onSuccess: async (codeResponse: any) => {
      sendRequest({
        url: '/authenticate/google-sso',
        method: Methods.POST,
        body: codeResponse,
      });
    },
    flow: 'auth-code',
  });

  return { data, error, loading, googleLogin };
};
