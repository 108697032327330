import { useMutation } from '@tanstack/react-query';
import { updateGameSettings } from '../../api/apiServices';

const useUpdateGameSettings = (handleOnSuccess: (data: any) => void) => {
  const { mutate } = useMutation({
    mutationFn: updateGameSettings,
    onSuccess: handleOnSuccess,
  });

  return {
    updateGameSettings: mutate,
  };
};

export default useUpdateGameSettings;
