import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, Typography } from '@mui/material';

type PropTypes = {
  open: boolean;
};

export const RefreshBrowserDialog = ({ open }: PropTypes) => {
  return (
    <Dialog open={open}>
      <DialogTitle textAlign={'center'}>Refresh your browser</DialogTitle>
      <DialogContent sx={{ width: '100%', height: '100%' }}>
        <Stack spacing={2}>
          <Typography>
            Please refresh your browser to reconnect to PAR
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
