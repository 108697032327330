import React, { FormEvent, Ref, RefObject } from 'react';
import {
  Container,
  Stack,
  Typography,
  Collapse,
  Button,
  Grow,
  Box,
  Divider,
  Card,
} from '@mui/material';

import { PropTypes } from './propTypes';
import { ButtonGoogleAuth } from './components/ButtonGoogleAuth';
import { InputEmail } from './components/InputEmail';
import { InputPassword } from './components/InputPassword';
import { TitleAuth } from './components/TitleAuth';
import { AUTH_TYPE_BUTTON_LABEL } from './components/constants';
import { Logo } from '../../../components/header/logo/Logo';
import { FaDiscord, FaXTwitter } from 'react-icons/fa6';
import { SocialMediaIconButton } from '../../../custom-components/custom-icon-buttons';

export const LoginPresenter = ({
  authType,
  email,
  emailRef,
  password,
  passwordRef,
  emailErrorMessage,
  passwordErrorMessage,
  showVerifyEmailMessage,
  showResendVerificationMessage,
  showResetPasswordMessage,
  handleGoogleLogin,
  handleSignUpClick,
  handleSubmit,
  setAuthType,
  setEmail,
  setPassword,
  setPasswordErrorMessage,
  googleError,
}: PropTypes) => {
  return (
    <Box
      sx={{
        p: 4,
        bgcolor: 'black',
        height: '100vh',
      }}
    >
      <Container maxWidth="xs">
        <Box display={'flex'} justifyContent={'center'} my={4}>
          <img
            alt="PAR"
            src={'/logo-blue-transparent.png'}
            style={{ maxWidth: '200px' }}
          ></img>
        </Box>
        <Card
          sx={{
            mb: 2,
            '&.MuiCard-root': {
              backgroundColor: 'transparent',
              backgroundImage: 'none',
              boxShadow: 'none',
            },
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems={'center'}
            justifyContent={'center'}
            py={2}
          >
            <SocialMediaIconButton
              brandColor="#5865F2"
              link="https://discord.gg/upX48KPfHd"
              icon={<FaDiscord />}
            />
            <SocialMediaIconButton
              brandColor="#000000"
              link="https://twitter.com/ParTrivia"
              icon={<FaXTwitter />}
            />
          </Stack>

          <form onSubmit={handleSubmit}>
            <Stack spacing={2} px={4} pb={4}>
              {googleError && (
                <Typography
                  variant="subtitle2"
                  textAlign={'center'}
                  color="error"
                >
                  {googleError}
                </Typography>
              )}

              <TitleAuth authType={authType} />

              {showVerifyEmailMessage || showResetPasswordMessage ? (
                <Typography
                  variant="subtitle2"
                  textAlign={'center'}
                  color="success.main"
                >
                  Please check your email (including spam folder)
                </Typography>
              ) : null}

              {showResendVerificationMessage ? (
                <Typography
                  variant="subtitle2"
                  textAlign={'center'}
                  color="warning.main"
                >
                  Verification expired
                </Typography>
              ) : null}

              <InputEmail
                disabled={
                  showVerifyEmailMessage ||
                  showResetPasswordMessage ||
                  showResendVerificationMessage
                }
                inputRef={emailRef as RefObject<HTMLInputElement>}
                email={email}
                emailErrorMessage={emailErrorMessage}
                setEmail={setEmail}
              />

              {authType !== 'forgotPassword' &&
              authType !== 'resendVerification' ? (
                <InputPassword
                  disabled={showVerifyEmailMessage}
                  inputRef={passwordRef as RefObject<HTMLInputElement>}
                  passwordErrorMessage={passwordErrorMessage}
                  password={password}
                  setPassword={setPassword}
                  setPasswordErrorMessage={setPasswordErrorMessage}
                />
              ) : null}

              <Stack direction={'row'} spacing={1}>
                {authType === 'login' ? (
                  <Box display={'flex'} justifyContent={'right'} width={'100%'}>
                    <Button onClick={() => setAuthType('forgotPassword')}>
                      Forgot Password?
                    </Button>
                  </Box>
                ) : null}
                {(authType === 'forgotPassword' ||
                  authType === 'resendVerification') &&
                !showResetPasswordMessage ? (
                  <>
                    <Button onClick={() => setAuthType('login')} fullWidth>
                      Login
                    </Button>
                  </>
                ) : null}
              </Stack>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                color={authType === 'signUp' ? 'secondary' : 'primary'}
                disabled={showVerifyEmailMessage || showResetPasswordMessage}
              >
                {AUTH_TYPE_BUTTON_LABEL[authType]}
              </Button>

              {(authType === 'forgotPassword' ||
                authType === 'resendVerification' ||
                (authType === 'signUp' && !showVerifyEmailMessage)) &&
              !showResetPasswordMessage ? (
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  Return to
                  <Button
                    color="secondary"
                    onClick={() => setAuthType('login')}
                  >
                    Login
                  </Button>
                </Stack>
              ) : (
                <Button
                  color="secondary"
                  onClick={() => setAuthType('signUp')}
                  fullWidth
                >
                  Create free account
                </Button>
              )}

              {authType !== 'forgotPassword' &&
              authType !== 'resendVerification' &&
              !showVerifyEmailMessage ? (
                <>
                  <Divider sx={{ my: 2 }}> OR </Divider>

                  <Box display={'flex'} justifyContent={'center'}>
                    <ButtonGoogleAuth
                      text="Continue with Google"
                      handleButtonClick={handleGoogleLogin}
                    />
                  </Box>
                </>
              ) : null}
            </Stack>
          </form>
        </Card>
      </Container>
    </Box>
  );
};
