import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const timeSince = (date: Date, shortest?: boolean) => {
  if (typeof date !== 'object') {
    date = new Date(date);
  }

  var seconds = Math.floor((+new Date() - +date) / 1000);
  var intervalType;

  var interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = shortest ? 'yr' : 'year';
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = shortest ? 'mo' : 'month';
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = shortest ? 'd' : 'day';
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = shortest ? 'h' : 'hour';
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = shortest ? 'm' : 'min';
          } else {
            interval = seconds;
            intervalType = shortest ? 's' : 'sec';
          }
        }
      }
    }
  }

  if (shortest) {
    return interval + intervalType;
  }

  if (interval > 1 || interval === 0) {
    intervalType += 's';
  }

  return interval + ' ' + intervalType;
};

export const getAbbreviatedDayOfTheWeek = (dateInMs: number) => {
  const d = new Date(dateInMs);
  switch (d.getDay()) {
    case 0:
      return 'Sun';
    case 1:
      return 'Mon';
    case 2:
      return 'Tue';
    case 3:
      return 'Wed';
    case 4:
      return 'Thu';
    case 5:
      return 'Fri';
    case 6:
      return 'Sat';
  }
  return 'Sun';
};

export const utcToLocalDate = (ms: number) => {
  const date = new Date(ms);
  return date.toLocaleDateString();
};

export const utcToLocalTime = (ms: number) => {
  const date = new Date(ms);
  return date.toLocaleTimeString([], { timeStyle: 'short' });
};

export const utcToLocalDateShort = (ms: number) => {
  const options: any = {
    month: 'short',
    day: 'numeric',
  };
  const date = new Date(ms);
  return date.toLocaleDateString('en-us', options);
};

export const utcToLocalDateMedium = (ms: number) => {
  const options: any = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const date = new Date(ms);
  return date.toLocaleDateString('en-us', options);
};

export const utcToLocalDateLong = (ms: number) => {
  const options: any = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const date = new Date(ms);
  return date.toLocaleDateString('en-us', options);
};

export const utcToLocalDateTime = (ms: number) => {
  const date = new Date(ms);
  return date.toLocaleString();
};

export const utcToLocalDateTimeShort = (ms: number) => {
  const options: any = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  const date = new Date(ms);
  return date.toLocaleString(undefined, options);
};

export const utcToLocalDateTimeShorter = (ms: number) => {
  const options: any = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  const date = new Date(ms);
  return date.toLocaleString(undefined, options);
};

export const getPacificTime = () => {
  return dayjs().tz('America/Los_Angeles');
};

export const getLocalTimezoneAbbreviation = () => {
  const userTimezone = dayjs.tz.guess();

  // Get the current date and time
  const now = new Date();

  return new Intl.DateTimeFormat('en-US', {
    timeZone: userTimezone,
    timeZoneName: 'short',
  })
    .formatToParts(now)
    .find((part) => part.type === 'timeZoneName').value;
};
