import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import { LABEL_COLORS } from '../../play-menus/PunchcardMenu/constants';
import {
  getPunchcardValue,
  getIcon,
} from '../../play-menus/PunchcardMenu/utils';
import useGetPunchcard from '../../play-menus/PunchcardMenu/useGetPunchcard';

type Props = {
  profileId?: string;
};

const PunchcardView = ({ profileId }: Props) => {
  const { data: punchcard } = useGetPunchcard(profileId);

  return (
    <>
      {punchcard ? (
        <Stack direction={'row'} spacing={2} p={3}>
          <Stack spacing={2} minWidth={'160px'}>
            <Typography variant="body2" fontWeight={600}>
              All
            </Typography>
            <Stack
              direction={'row'}
              spacing={3}
              height={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                py: 2,
                px: 4,
                border: '1px solid',
                borderColor: 'border',
                borderRadius: 1,
              }}
            >
              <Stack spacing={0.5}>
                <Typography textAlign={'right'} color={LABEL_COLORS['average']}>
                  {getPunchcardValue(punchcard, 'all', 'bestAverage')}
                </Typography>
                <Typography textAlign={'right'} color={LABEL_COLORS['best']}>
                  {getPunchcardValue(punchcard, 'all', 'best')}
                </Typography>
                <Typography
                  textAlign={'right'}
                  color={LABEL_COLORS['gamesPlayed']}
                >
                  {getPunchcardValue(punchcard, 'all', 'gamesPlayed')}
                </Typography>
              </Stack>
              <Stack spacing={0.5}>
                {getIcon(
                  punchcard,
                  'all',
                  'bestAverage',
                  getPunchcardValue(punchcard, 'all', 'bestAverage')
                )}
                {getIcon(
                  punchcard,
                  'all',
                  'best',
                  getPunchcardValue(punchcard, 'all', 'best')
                )}
                {getIcon(
                  punchcard,
                  'all',
                  'gamesPlayed',
                  getPunchcardValue(punchcard, 'all', 'gamesPlayed')
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing={2} minWidth={'160px'}>
            <Typography variant="body2" fontWeight={600}>
              Pop Culture
            </Typography>
            <Stack
              direction={'row'}
              spacing={3}
              height={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                py: 2,
                px: 4,
                border: '1px solid',
                borderColor: 'border',
                borderRadius: 1,
              }}
            >
              <Stack spacing={0.5}>
                <Typography textAlign={'right'} color={LABEL_COLORS['average']}>
                  {getPunchcardValue(punchcard, 'popCulture', 'bestAverage')}
                </Typography>
                <Typography textAlign={'right'} color={LABEL_COLORS['best']}>
                  {getPunchcardValue(punchcard, 'popCulture', 'best')}
                </Typography>
                <Typography
                  textAlign={'right'}
                  color={LABEL_COLORS['gamesPlayed']}
                >
                  {getPunchcardValue(punchcard, 'popCulture', 'gamesPlayed')}
                </Typography>
              </Stack>
              <Stack spacing={0.5}>
                {getIcon(
                  punchcard,
                  'popCulture',
                  'bestAverage',
                  getPunchcardValue(punchcard, 'popCulture', 'bestAverage')
                )}
                {getIcon(
                  punchcard,
                  'popCulture',
                  'best',
                  getPunchcardValue(punchcard, 'popCulture', 'best')
                )}
                {getIcon(
                  punchcard,
                  'popCulture',
                  'gamesPlayed',
                  getPunchcardValue(punchcard, 'popCulture', 'gamesPlayed')
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing={2} minWidth={'160px'}>
            <Typography variant="body2" fontWeight={600}>
              Humanities
            </Typography>
            <Stack
              direction={'row'}
              spacing={3}
              height={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                py: 2,
                px: 4,
                border: '1px solid',
                borderColor: 'border',
                borderRadius: 1,
              }}
            >
              <Stack spacing={0.5}>
                <Typography textAlign={'right'} color={LABEL_COLORS['average']}>
                  {getPunchcardValue(punchcard, 'humanities', 'bestAverage')}
                </Typography>
                <Typography textAlign={'right'} color={LABEL_COLORS['best']}>
                  {getPunchcardValue(punchcard, 'humanities', 'best')}
                </Typography>
                <Typography
                  textAlign={'right'}
                  color={LABEL_COLORS['gamesPlayed']}
                >
                  {getPunchcardValue(punchcard, 'humanities', 'gamesPlayed')}
                </Typography>
              </Stack>
              <Stack spacing={0.5}>
                {getIcon(
                  punchcard,
                  'humanities',
                  'bestAverage',
                  getPunchcardValue(punchcard, 'humanities', 'bestAverage')
                )}
                {getIcon(
                  punchcard,
                  'humanities',
                  'best',
                  getPunchcardValue(punchcard, 'humanities', 'best')
                )}
                {getIcon(
                  punchcard,
                  'humanities',
                  'gamesPlayed',
                  getPunchcardValue(punchcard, 'humanities', 'gamesPlayed')
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing={2} minWidth={'160px'}>
            <Typography variant="body2" fontWeight={600}>
              Hard & Soft Sciences
            </Typography>
            <Stack
              direction={'row'}
              spacing={3}
              height={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                py: 2,
                px: 4,
                border: '1px solid',
                borderColor: 'border',
                borderRadius: 1,
              }}
            >
              <Stack spacing={0.5}>
                <Typography textAlign={'right'} color={LABEL_COLORS['average']}>
                  {getPunchcardValue(
                    punchcard,
                    'hardSoftSciences',
                    'bestAverage'
                  )}
                </Typography>
                <Typography textAlign={'right'} color={LABEL_COLORS['best']}>
                  {getPunchcardValue(punchcard, 'hardSoftSciences', 'best')}
                </Typography>
                <Typography
                  textAlign={'right'}
                  color={LABEL_COLORS['gamesPlayed']}
                >
                  {getPunchcardValue(
                    punchcard,
                    'hardSoftSciences',
                    'gamesPlayed'
                  )}
                </Typography>
              </Stack>
              <Stack spacing={0.5}>
                {getIcon(
                  punchcard,
                  'hardSoftSciences',
                  'bestAverage',
                  getPunchcardValue(
                    punchcard,
                    'hardSoftSciences',
                    'bestAverage'
                  )
                )}
                {getIcon(
                  punchcard,
                  'hardSoftSciences',
                  'best',
                  getPunchcardValue(punchcard, 'hardSoftSciences', 'best')
                )}
                {getIcon(
                  punchcard,
                  'hardSoftSciences',
                  'gamesPlayed',
                  getPunchcardValue(
                    punchcard,
                    'hardSoftSciences',
                    'gamesPlayed'
                  )
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing={2} minWidth={'160px'}>
            <Typography variant="body2" fontWeight={600}>
              Fine Arts
            </Typography>
            <Stack
              direction={'row'}
              spacing={3}
              height={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                py: 2,
                px: 4,
                border: '1px solid',
                borderColor: 'border',
                borderRadius: 1,
              }}
            >
              <Stack spacing={0.5}>
                <Typography textAlign={'right'} color={LABEL_COLORS['average']}>
                  {getPunchcardValue(punchcard, 'fineArts', 'bestAverage')}
                </Typography>
                <Typography textAlign={'right'} color={LABEL_COLORS['best']}>
                  {getPunchcardValue(punchcard, 'fineArts', 'best')}
                </Typography>
                <Typography
                  textAlign={'right'}
                  color={LABEL_COLORS['gamesPlayed']}
                >
                  {getPunchcardValue(punchcard, 'fineArts', 'gamesPlayed')}
                </Typography>
              </Stack>
              <Stack spacing={0.5}>
                {getIcon(
                  punchcard,
                  'fineArts',
                  'bestAverage',
                  getPunchcardValue(punchcard, 'fineArts', 'bestAverage')
                )}
                {getIcon(
                  punchcard,
                  'fineArts',
                  'best',
                  getPunchcardValue(punchcard, 'fineArts', 'best')
                )}
                {getIcon(
                  punchcard,
                  'fineArts',
                  'gamesPlayed',
                  getPunchcardValue(punchcard, 'fineArts', 'gamesPlayed')
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Box
          width={922}
          height={200}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default PunchcardView;
