import React from 'react';
import { Avatar } from '@mui/material';
import { getAvatarSrc } from '../../../../../api/cloudinary';
import { Penalty } from '../../../../../types/game';
import { ScoreAvatar } from './ScoreAvatar';

type Props = {
  avatarPublicId?: string;
  avatarUrl?: string;
  correctAnswer?: boolean;
  currentScore?: number;
  height: number;
  width: number;
  penalty?: Penalty;
  timerLimitReached: boolean;
  teamGame?: boolean;
};

const StyledActionAvatar = ({
  avatarPublicId,
  avatarUrl,
  correctAnswer,
  currentScore,
  height,
  width,
  penalty,
  timerLimitReached,
  teamGame,
}: Props) => {
  const showScore = () =>
    (correctAnswer && currentScore !== undefined) ||
    (!correctAnswer && timerLimitReached);

  return (
    <>
      {!showScore() ? (
        <Avatar
          src={getAvatarSrc(avatarPublicId, avatarUrl, 200, 200)}
          variant={teamGame ? 'square' : 'circular'}
          sx={{
            height,
            width,
            objectFit: 'cover',
            zIndex: 1,
            borderRadius: teamGame ? 2 : null,
          }}
        />
      ) : (
        <ScoreAvatar
          height={height}
          width={width}
          correctAnswer={correctAnswer}
          currentScore={currentScore}
          penalty={penalty}
          teamGame={teamGame}
        />
      )}
    </>
  );
};

export default StyledActionAvatar;
