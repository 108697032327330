import { cloneDeep, mean, round } from 'lodash';
import { useEffect, useState } from 'react';
import { useGameContext } from '../../../contexts/GameProvider';
import { useProfileContext } from '../../../contexts/ProfileProvider';
import { useSpectateSocketContext } from '../../../contexts/SocketProvider';
import { LeagueGameType, ReportInaccuracy, Spectator } from '../../../types';
import { SpectatorIncomingChat } from '../../../types/chat';
import {
  Mode,
  Teams,
  Team,
  Penalty,
  Assists,
  Question,
} from '../../../types/game';
import {
  ArenaTeamId,
  ArenaTeamLiveRoundScores,
  ArenaTeamRoundScores,
  DetailedPlayer,
  Player,
} from '../../../types/player';
import { ProperNameShortAnswer } from '../../../types/question';
import { TrueSkill } from '../../profile/ArenaProfile/types';
import { ArenaTeam } from '../../arena-team/types';
import { getFuzzyPenalty } from '../game/utils';
import useFetchArenaTeams from '../../profile/ArenaProfile/hooks/useFetchArenaTeams';
import { useArenaContext } from '../../../contexts/ArenaProvider';

export const useGameStatesSpectateServer = () => {
  const { profile } = useProfileContext();
  const { arenaSeason } = useArenaContext();
  const { spectateSocket } = useSpectateSocketContext();
  const {
    spectateGameId,
    spectateGameDataFromServer,
    setSpectateGameDataFromServer,
    setSpectateGameId,
    setSpectateLeagueGameType,
    setShowSpectatorChat,
    setShowGameChat,
  } = useGameContext();

  const [arenaGame, setArenaGame] = useState<number>();
  const [arenaSet, setArenaSet] = useState<number>();
  const [gameType, setGameType] = useState<'casual' | 'pro'>();
  const [categorySet, setCategorySet] = useState<string>();
  const [leagueGameType, setLeagueGameType] = useState<LeagueGameType>();
  const [mode, setMode] = useState<Mode>();
  const [detailedPlayers, setDetailedPlayers] =
    useState<Record<string, DetailedPlayer>>();
  const [questionSet, setQuestionSet] = useState<Question[]>();
  const [gameStarted, setGameStarted] = useState(false);
  const [countdown, setCountdown] = useState<number>();
  const [questionIndex, setQuestionIndex] = useState<number>();
  const [gameClock, setGameClock] = useState(0);
  const [revealQuestion, setRevealQuestion] = useState('');
  const [revealFullQuestion, setRevealFullQuestion] = useState<string>();
  const [questionMetadata, setQuestionMetadata] =
    useState<Record<string, any>>();
  const [revealAnswer, setRevealAnswer] = useState<string[][]>();
  const [timerLimitReached, setTimerLimitReached] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [unrevealedIndex, setUnrevealedIndex] = useState<number[]>();
  const [playerThem, setPlayerThem] = useState<Player>();
  const [properNameShortAnswer, setProperNameShortAnswer] =
    useState<ProperNameShortAnswer>();
  const [reportInaccuracy, setReportInaccuracy] =
    useState<ReportInaccuracy[]>();
  const [spectators, setSpectators] = useState<Spectator[]>([]);
  const [spectatorList, setSpectatorList] = useState<Spectator[]>();
  const [spectatorChat, setSpectatorChat] = useState<SpectatorIncomingChat[]>();
  const [delayBeforeQuestionRevealCount, setDelayBeforeQuestionRevealCount] =
    useState(10);
  const [nextQuestionDelayCount, setNextQuestionDelayCount] = useState(10);
  const [paused, setPaused] = useState(false);
  const [willPause, setWillPause] = useState(false);
  const [unpauseCount, setUnpauseCount] = useState<number>();
  const [cutoff, setCutoff] = useState<number>();
  const [bannedCategories, setBannedCategories] = useState<string[]>();
  const [mathFormula, setMathFormula] = useState<string>();
  const [questionImgUrl, setQuestionImgUrl] = useState<string>();
  const [connectCounter, setConnectCounter] = useState(0);
  const [disconnectCounter, setDisconnectCounter] = useState(0);
  const [players, setPlayers] = useState<Record<string, Player>>();
  const [playerSelf, setPlayerSelf] = useState<Player>();
  const [teams, setTeams] = useState<Teams>();
  const [myTeamId, setMyTeamId] = useState<string>();
  const [myTeam, setMyTeam] = useState<Team>();
  const [opponentTeam, setOpponentTeam] = useState<Team>();
  const [fuzzy, setFuzzy] = useState<Record<string, number>>();
  const [penalty, setPenalty] = useState<Penalty>();
  const [assists, setAssists] = useState<Assists>();
  const [freezeGameClock, setFreezeGameClock] = useState(false);
  // const [topScore, setTopScore] = useState<number>();
  // const [averageScore, setAverageScore] = useState<number>();
  const [arenaUpdateTrueSkill, setArenaUpdateTrueSkill] =
    useState<TrueSkill[]>();
  // const [arenaTeams, setArenaTeams] = useState<ArenaTeam[]>();
  const [arenaTeamRoundScores, setArenaTeamRoundScores] =
    useState<Record<ArenaTeamId, ArenaTeamRoundScores>>();
  const [arenaTeamsDetailed, setArenaTeamsDetailed] = useState<ArenaTeam[]>();
  const [arenaTeamLiveRoundScores, setArenaTeamLiveRoundScores] =
    useState<ArenaTeamLiveRoundScores>();
  const [arenaPlayersProjectionAndTiers, setArenaPlayersProjectionAndTiers] =
    useState<
      Record<
        string,
        {
          projection: number;
          currentTier: string;
        }
      >
    >({});
  const [teamBestBalls, setTeamBestBalls] = useState<Record<string, Player[]>>(
    {}
  );
  const [arenaRoundGamesScores, setArenaRoundGamesScores] = useState<
    Record<
      string,
      {
        [key: string]: number;
      }
    >
  >({});
  const [arenaTeams, setArenaTeams] = useState<ArenaTeam[]>();

  const { data: allArenaTeams } = useFetchArenaTeams(
    arenaGame ? arenaSeason : null
  );

  useEffect(() => {
    if (!spectateSocket || !profile || !spectateGameId) return;
    spectateSocket.on('detailedPlayers', setDetailedPlayers);
    spectateSocket.on('gameStarted', setGameStarted);
    spectateSocket.on('countdown', setCountdown);
    spectateSocket.on('questionIndex', setQuestionIndex);
    spectateSocket.on('questionMetadata', setQuestionMetadata);
    spectateSocket.on('gameClock', setGameClock);
    spectateSocket.on('revealAnswer', setRevealAnswer);
    spectateSocket.on('revealQuestion', updateQuestion);
    spectateSocket.on('revealFullQuestion', updateFullQuestionReveal);
    spectateSocket.on('timerLimitReached', setTimerLimitReached);
    spectateSocket.on('unrevealedIndex', setUnrevealedIndex);
    spectateSocket.on('gameOver', setGameOver);
    spectateSocket.on('questionSet', setQuestionSet);
    spectateSocket.on('properNameShortAnswer', setProperNameShortAnswer);
    spectateSocket.on('reportInaccuracy', setReportInaccuracy);
    spectateSocket.on('bannedCategories', setBannedCategories);
    spectateSocket.on(
      'delayBeforeQuestionRevealCount',
      setDelayBeforeQuestionRevealCount
    );
    spectateSocket.on('nextQuestionDelayCount', setNextQuestionDelayCount);
    spectateSocket.on('paused', setPaused);
    spectateSocket.on('willPause', setWillPause);
    spectateSocket.on('unpauseCount', setUnpauseCount);
    spectateSocket.on('cutoff', setCutoff);
    spectateSocket.on('spectators', setSpectators);
    spectateSocket.on('connect', handleConnect);
    spectateSocket.on('disconnect', handleDisconnect);
    spectateSocket.on('teams', setTeams);
    spectateSocket.on('players', setPlayers);
    spectateSocket.on('playerUpdate', updatePlayer);
    spectateSocket.on('fuzzy', updateFuzzy);
    spectateSocket.on('penalty', setPenalty);
    spectateSocket.on('assists', setAssists);
    // spectateSocket.on('spectatorChat', reverseSpectatorChat);
    spectateSocket.on('arenaUpdateTrueSkill', setArenaUpdateTrueSkill);
    spectateSocket.on(
      'arenaPlayersProjectionAndTiers',
      setArenaPlayersProjectionAndTiers
    );
    spectateSocket.on('arenaTeamRoundScores', setArenaTeamRoundScores); // for scorecard
    spectateSocket.on('arenaTeamsDetailed', setArenaTeamsDetailed); // for scorecard
    spectateSocket.on(
      'arenaTeamsLiveRoundScoresUpdate',
      updateArenaTeamRoundScores
    ); // for live game
    spectateSocket.on('arenaRoundGameScores', setArenaRoundGamesScores);

    spectateSocket.on('spectateData', handleSpectateData);

    spectateSocket.emit('spectateGameRequest', {
      profile: profile,
      gameId: spectateGameId,
    });

    spectateSocket.emit('requestSpectatorChatHistory', {
      gameId: spectateGameId,
    });

    return () => {
      spectateSocket.off('detailedPlayers', setDetailedPlayers);
      spectateSocket.off('gameStarted', setGameStarted);
      spectateSocket.off('countdown', setCountdown);
      spectateSocket.off('questionIndex', setQuestionIndex);
      spectateSocket.off('questionMetadata', setQuestionMetadata);
      spectateSocket.off('gameClock', setGameClock);
      spectateSocket.off('revealAnswer', setRevealAnswer);
      spectateSocket.off('revealQuestion', updateQuestion);
      spectateSocket.off('revealFullQuestion', updateFullQuestionReveal);
      spectateSocket.off('timerLimitReached', setTimerLimitReached);
      spectateSocket.off('unrevealedIndex', setUnrevealedIndex);
      spectateSocket.off('gameOver', setGameOver);
      spectateSocket.off('questionSet', setQuestionSet);
      spectateSocket.off('properNameShortAnswer', setProperNameShortAnswer);
      spectateSocket.off('reportInaccuracy', setReportInaccuracy);
      spectateSocket.off('bannedCategories', setBannedCategories);
      spectateSocket.off(
        'delayBeforeQuestionRevealCount',
        setDelayBeforeQuestionRevealCount
      );
      spectateSocket.off('nextQuestionDelayCount', setNextQuestionDelayCount);
      spectateSocket.off('paused', setPaused);
      spectateSocket.off('willPause', setWillPause);
      spectateSocket.off('unpauseCount', setUnpauseCount);
      spectateSocket.off('cutoff', setCutoff);
      spectateSocket.off('spectators', setSpectators);
      spectateSocket.off('connect', handleConnect);
      spectateSocket.off('disconnect', handleDisconnect);
      spectateSocket.off('teams', setTeams);
      spectateSocket.off('players', setPlayers);
      spectateSocket.off('playerUpdate', updatePlayer);
      spectateSocket.off('fuzzy', updateFuzzy);
      spectateSocket.off('penalty', setPenalty);
      spectateSocket.off('assists', setAssists);
      spectateSocket.off('arenaUpdateTrueSkill', setArenaUpdateTrueSkill);
      spectateSocket.off(
        'arenaPlayersProjectionAndTiers',
        setArenaPlayersProjectionAndTiers
      );
      spectateSocket.off('arenaTeamRoundScores', setArenaTeamRoundScores);
      spectateSocket.off('arenaTeamsDetailed', setArenaTeamsDetailed);
      spectateSocket.off(
        'arenaTeamsLiveRoundScoresUpdate',
        updateArenaTeamRoundScores
      ); // for live game
      spectateSocket.off('arenaRoundGameScores', setArenaRoundGamesScores);

      // spectateSocket.off('spectatorChat', reverseSpectatorChat);
      spectateSocket.off('spectateData', handleSpectateData);
    };
  }, [spectateSocket, profile, spectateGameId]);

  useEffect(() => {
    if (!players) return;
    setPlayerSelf(players[Object.keys(players)[0]]);

    if (Object.keys(players).length === 2) {
      setPlayerThem(players[Object.keys(players)[1]]);
    }

    // updateAverageScore(players);
  }, [profile, players]);

  useEffect(() => {
    if (!spectateGameDataFromServer) return;
    setLeagueGameType(spectateGameDataFromServer.leagueGameType);
    setMode(spectateGameDataFromServer.mode);
    setCategorySet(spectateGameDataFromServer.categorySet);
    setGameType(spectateGameDataFromServer.gameType);
    setArenaSet(spectateGameDataFromServer.arenaSet);
    setArenaGame(spectateGameDataFromServer.arenaGame);
  }, [spectateGameDataFromServer]);

  useEffect(() => {
    if (!teams) return;
    const myTeamId = 'teamA';
    const opponentTeamId = 'teamB';
    setMyTeamId(myTeamId);
    setMyTeam(teams[myTeamId]);
    setOpponentTeam(teams[opponentTeamId]);
  }, [teams]);

  useEffect(() => {
    if (!spectators) return;
    setSpectatorList(spectators);
  }, [spectators]);

  useEffect(() => {
    if (gameOver) {
      if (!arenaSet) {
        setShowSpectatorChat(false);
        setShowGameChat(true);
      }
    }
  }, [gameOver]);

  const requestDetailedPlayers = (gameId: string, profileId: string) => {
    if (!spectateSocket) return;
    spectateSocket.emit('requestDetailedPlayers', {
      gameId,
      profileId: profileId,
      playerProfileIds: [],
    });
  };

  const updateQuestion = (data: {
    revealQuestion: string;
    mathFormula?: string;
    imgUrl?: string;
  }) => {
    setRevealQuestion((prev) => data.revealQuestion);
    setMathFormula((prev) => data.mathFormula);
    setQuestionImgUrl((prev) => data.imgUrl);
  };

  const updateFullQuestionReveal = (data: {
    revealQuestion: string;
    mathFormula?: string;
    imgUrl?: string;
  }) => {
    setRevealFullQuestion(data.revealQuestion);
    if (data.mathFormula) {
      setMathFormula(data.mathFormula);
    }
    if (data.imgUrl) {
      setQuestionImgUrl(data.imgUrl);
    }
  };

  const handleSpectateData = (data: {
    arenaPlayersProjectionAndTiers: any;
    arenaTeams: any;
    arenaRoundGamesScores: any;
    arenaTeamsRoundScores: any;
    bannedCategories: any;
    countdown: any;
    cutoff: any;
    gameClock: any;
    gameOver: any;
    gameStarted: any;
    league: any;
    mode: any;
    players: any;
    questionIndex: any;
    questionMetadata: any;
    revealAnswer: any;
    revealQuestion: any;
    spectators: any;
    survivalChallengeLivesRemaining: any;
    survivalChallengePhaseTwoChallenge: any;
    survivalChallengeTimerLimit: any;
    teams: any;
    tournamentCorrectAnswersCount: any;
    tournamentQuestionTopScorers: any;
    tournamentRankedPlayersList: any;
    tournamentTotalPlayers: any;
    unrevealedIndex: any;
  }) => {
    setArenaPlayersProjectionAndTiers(data.arenaPlayersProjectionAndTiers);
    setArenaTeams(data.arenaTeams);
    setArenaRoundGamesScores(data.arenaRoundGamesScores);
    setArenaTeamLiveRoundScores(data.arenaTeamsRoundScores);
    setSpectators(data.spectators);
    setCutoff(data.cutoff);
    setCountdown(data.countdown);
    setQuestionMetadata(data.questionMetadata);
    setQuestionIndex(data.questionIndex);
    setPlayers(data.players);
    setGameStarted(data.gameStarted);
    setGameOver(data.gameOver);
    updateQuestion(data.revealQuestion);
    setUnrevealedIndex(data.unrevealedIndex);
    setBannedCategories(data.bannedCategories);
    setMode(data.mode);
    setTeams(data.teams);
  };

  const handleConnect = () => {
    setConnectCounter((prev) => prev + 1);
  };

  const handleDisconnect = () => {
    setDisconnectCounter((prev) => prev + 1);
  };

  const resetStatesFromMainServer = () => {
    setRevealQuestion('');
    setRevealFullQuestion(undefined);
    setMathFormula(undefined);
    setQuestionImgUrl(undefined);
    setTimerLimitReached(false);
  };

  const resetDelayClocks = () => {
    // TODO: refactor this logic
    // these are reset to an arbitrary high number to prevent conditions from rendering when equal to 0
    // the server will determine the values of these in between questions
    setDelayBeforeQuestionRevealCount(10);
    setNextQuestionDelayCount(10);
  };

  const updatePlayer = (player: Player) => {
    if (!player) return;
    setPlayers((prev) => ({
      ...prev,
      [player.profileId]: player,
    }));

    // setTopScore((prev) => {
    //   const totalScore =
    //     player.currentScore + getFuzzyPenalty(player.fuzzyScore);
    //   if (prev === undefined || totalScore < prev) {
    //     return totalScore;
    //   }
    //   return prev;
    // });

    if (!player.arenaTeamId || player.currentScore === null) return;
    updateTeamBestBallPlayer(player);
  };

  const updateTeamBestBallPlayer = (player: Player) => {
    const totalScore = player.currentScore + getFuzzyPenalty(player.fuzzyScore);

    setTeamBestBalls((prev) => {
      const arenaTeamId = player.arenaTeamId;
      const currentBestBallPlayer = prev[arenaTeamId];
      if (!currentBestBallPlayer) {
        return {
          ...prev,
          [arenaTeamId]: [player],
        };
      }
      const currentTotalScore =
        currentBestBallPlayer[0].currentScore +
        getFuzzyPenalty(currentBestBallPlayer[0].fuzzyScore);
      if (totalScore === currentTotalScore) {
        return {
          ...prev,
          [arenaTeamId]: prev[arenaTeamId].concat(player),
        };
      }
      return prev;
    });
  };

  const updateFuzzy = (fuzzy: Record<string, number>) => {
    setFuzzy((prev: any) => ({
      ...prev,
      ...fuzzy,
    }));
  };

  // const updateAverageScore = (players: Record<string, Player>) => {
  //   if (!players) return;
  //   const filteredPlayers = Object.values(players).filter(
  //     (p) => p.currentScore !== null || p.timerLimitReached
  //   );
  //   if (filteredPlayers.length === 0) return;
  //   const scores = filteredPlayers.map(
  //     (p) => p.currentScore + (p.fuzzyScore || 0) + (p.totalPenalty || 0)
  //   );
  //   const average = mean(scores);
  //   setAverageScore(round(average, 1));
  // };

  const updateArenaTeamRoundScores = (teamTopScore: {
    arenaTeamId: string;
    questionIndex: number;
    totalScore: number;
  }) => {
    if (!teamTopScore) return;
    const { arenaTeamId, questionIndex, totalScore } = teamTopScore;
    setArenaTeamLiveRoundScores((prev) => ({
      ...prev,
      [arenaTeamId]: {
        ...prev?.[arenaTeamId],
        [questionIndex]: totalScore,
      },
    }));
  };

  const resetStatesFromInputServer = () => {
    setAssists(undefined);
    setFuzzy(undefined);
    // setTopScore(undefined);
    // setAverageScore(undefined);
    setTeamBestBalls({});
    if (players) {
      const resetPlayers = cloneDeep(players);
      for (const profileId of Object.keys(players)) {
        resetPlayers[profileId].correctAnswer = false;
        resetPlayers[profileId].incorrectAnswer = false;
        resetPlayers[profileId].isPromptAnswer = false;
        resetPlayers[profileId].timerLimitReached = false;
        resetPlayers[profileId].fuzzyScore = null;
        resetPlayers[profileId].currentScore = null;
      }
      setPlayers(resetPlayers);
    }
    if (teams) {
      const resetTeams = cloneDeep(teams);
      for (const teamId of Object.keys(resetTeams)) {
        resetTeams[teamId].correctAnswer = false;
        resetTeams[teamId].correctAnswerPlayerUsername = null;
        resetTeams[teamId].incorrectAnswer = false;
        resetTeams[teamId].timerLimitReached = false;
        resetTeams[teamId].fuzzyScore = null;
        resetTeams[teamId].currentScore = null;
      }
      setTeams(resetTeams);
    }
  };

  const updateScoresOnTimeout = (par: number) => {
    const updateTimedOutPlayers = cloneDeep(players);
    for (const profileId of Object.keys(players)) {
      const p = updateTimedOutPlayers[profileId];
      if (p.currentScore === null && !p.timerLimitReached) {
        p.parScore = p.parScore + 20 - par;
        p.currentScore = 20 - par;
        p.timerLimitReached = p.correctAnswer ? false : true;
      }
    }
    setPlayers(updateTimedOutPlayers);
  };

  const stopSpectating = (profileId: string, gameId: string) => {
    setSpectateGameDataFromServer(null);
    setSpectateGameId(null);
    setSpectateLeagueGameType(null);
    setShowSpectatorChat(false);
    spectateSocket.emit('stopSpectating', {
      profileId,
      gameId,
    });
  };

  return {
    allArenaTeams,
    arenaGame,
    arenaRoundGamesScores,
    arenaSet,
    arenaPlayersProjectionAndTiers,
    arenaTeams,
    arenaUpdateTrueSkill,
    assists,
    bannedCategories,
    categorySet,
    connectCounter,
    countdown,
    cutoff,
    delayBeforeQuestionRevealCount,
    detailedPlayers,
    disconnectCounter,
    freezeGameClock,
    fuzzy,
    gameClock,
    gameOver,
    gameStarted,
    gameType,
    leagueGameType,
    mathFormula,
    mode,
    myTeam,
    nextQuestionDelayCount,
    opponentTeam,
    paused,
    penalty,
    players,
    playerSelf,
    playerThem,
    properNameShortAnswer,
    questionImgUrl,
    questionIndex,
    questionMetadata,
    questionSet,
    reportInaccuracy,
    requestDetailedPlayers,
    resetDelayClocks,
    resetStatesFromInputServer,
    resetStatesFromMainServer,
    revealAnswer,
    revealFullQuestion,
    revealQuestion,
    spectatorChat,
    spectatorList,
    spectators,
    stopSpectating,
    teamBestBalls,
    teams,
    timerLimitReached,
    unpauseCount,
    unrevealedIndex,
    updateScoresOnTimeout,
    willPause,
    arenaTeamRoundScores,
    arenaTeamsDetailed,
    arenaTeamLiveRoundScores,
  };
};
