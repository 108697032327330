import React from 'react';
import {
  Drawer as MuiDrawer,
  useTheme,
  styled,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Stack,
  Typography,
  Chip,
} from '@mui/material';
import {
  AdminPanelSettings,
  Ballot,
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import { useProfileContext } from '../contexts/ProfileProvider';
import NavListItem from './NavListItem';
import SideNavPlayButton from './SideNavPlayButton';
import { useLoggedInUserContext } from '../contexts/LoggedInUserProvider';
import { useLobbyGamesContext } from '../contexts/LobbyGamesProvider';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WeekendOutlinedIcon from '@mui/icons-material/WeekendOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useQuickPlayGamesContext } from '../contexts/QuickPlayGamesProvider';
import { FlickeringBeta } from './FlickeringBeta';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 200;
const collapsedDrawerWidth = 80;

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    width: open ? drawerWidth : collapsedDrawerWidth,
    overflow: 'visible',
    background: `linear-gradient(
      324deg, 
      #000000 0%, 
      #161c1d 50%, 
      #000000 100%
    )`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface ToggleButtonProps extends IconButtonProps {
  open: boolean;
}

const ToggleButton = styled(({ open, ...other }: ToggleButtonProps) => (
  <IconButton {...other} />
))(({ theme, open }) => ({
  position: 'absolute',
  top: '50%',
  right: open ? '-20px' : '-20px', // Adjust this value to move the button outside the drawer
  transform: 'translateY(-50%)',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  zIndex: 1301,
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));

type Props = {
  handleOpenPlayDrawer: () => void;
};

export const SideNav = ({ handleOpenPlayDrawer }: Props) => {
  const navigate = useNavigate();
  const { profile } = useProfileContext();
  const { loggedInUser } = useLoggedInUserContext();
  const { quickPlayGamesOpen } = useQuickPlayGamesContext();
  const { openPlayMenu2, setOpenPlayMenu2 } = useLobbyGamesContext();

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLogoClick = () => {
    navigate('/home');
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
        '& .MuiPaper-root': {
          zIndex: 1000,
        },
      }}
    >
      <DrawerHeader>
        <div
          style={{
            cursor: 'pointer',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            alt="PAR"
            style={{ width: '120px' }}
            src={
              open
                ? '/logo-blue-transparent.png'
                : '/logo-blue-transparent-xs.png'
            }
            className={open ? 'sidenav__logo-normal' : 'sidenav__logo-xs'}
            onClick={handleLogoClick}
          ></img>
          <FlickeringBeta />
        </div>
      </DrawerHeader>
      {/* <ToggleButton open={open} onClick={handleDrawerToggle}>
        {open ? <ChevronLeft /> : <ChevronRight />}
      </ToggleButton> */}

      <Stack p={2}>
        <SideNavPlayButton
          disablePlayButton={false}
          onlinePlayer={loggedInUser}
          openPlayMenu={openPlayMenu2}
          // onPlayClick={() => setOpenPlayMenu2(true)}
          onPlayClick={handleOpenPlayDrawer}
          size="large"
        />
      </Stack>

      <List>
        <NavListItem
          to={`/home`}
          startIcon={<HomeOutlinedIcon aria-label="Home Icon" />}
          label="Home"
          open={open}
        />
        <NavListItem
          to={`/profile/overview/${profile._id}`}
          startIcon={<AccountBoxOutlinedIcon aria-label="Profile Icon" />}
          label="Profile"
          open={open}
        />
        <NavListItem
          to="/lobby"
          startIcon={<WeekendOutlinedIcon aria-label="Games Icon" />}
          label="Lobby"
          endIcon={
            quickPlayGamesOpen?.length ? (
              <Chip
                variant="outlined"
                label="OPEN"
                size="small"
                sx={{
                  color: theme.palette.pro.main,
                  fontSize: 14,
                  border: `2px solid`,
                  borderColor: theme.palette.pro.main,
                  borderRadius: 1,
                  padding: '2px 2px',
                }}
              />
            ) : undefined
          }
          open={open}
        />
        <NavListItem
          to="/dailies"
          startIcon={<CalendarMonthOutlinedIcon aria-label="Calendar Icon" />}
          label="Dailies"
          open={open}
        />
        {/* <NavListItem
          to="/home"
          startIcon={<Ballot aria-label="Customs Icon" />}
          label="Customs"
          open={open}
        /> */}
      </List>

      {/* <ListItem disablePadding sx={{ position: 'absolute', bottom: '48px' }}>
        <ListItemButton>
          <ListItemIcon>
            <AdminPanelSettings />
          </ListItemIcon>
          {open ? <ListItemText primary="Admin" /> : null}
        </ListItemButton>
      </ListItem> */}
    </Drawer>
  );
};
