// useColorMode.ts
import React, { useState, useMemo } from 'react';
import { PaletteMode } from '@mui/material';
import { createTheme, responsiveFontSizes, Theme } from '@mui/material/styles';
import { leaguePalette, leaguePaletteLight } from './mui-palette';

export const useColorMode = (): [Theme, () => void] => {
  const [mode, setMode] = useState<PaletteMode>('dark');

  const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            // palette values for light mode
            ...leaguePaletteLight,
          }
        : {
            // palette values for dark mode
            ...leaguePalette,
          }),
    },
    typography: {
      button: {
        fontWeight: 600,
      },
      fontFamily: ['IBM Plex Mono', 'monospace'].join(','),
    },
  });

  const colorModeToggle = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const theme = useMemo(
    () => responsiveFontSizes(createTheme(getDesignTokens(mode))),
    [mode]
  );

  return [theme, colorModeToggle];
};
