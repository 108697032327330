import React, { useEffect } from 'react';
import { ResetPassword as SharedResetPassword } from '../auth/login/components/ResetPassword';
import { useProfileContext } from '../../contexts/ProfileProvider';
import { useNavigate, useParams } from 'react-router-dom';

export const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { profile } = useProfileContext();

  useEffect(() => {
    if (profile) {
      navigate('/home');
    }
  }, [navigate, profile]);

  return <SharedResetPassword token={token} />;
};
