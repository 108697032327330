import {
  Card,
  Box,
  Typography,
  Stack,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import {
  ArenaAdvancedStats,
  ArenaCategoryStat,
  ArenaCategoryStats,
} from '../types';
import ArenaStatsChart from './ArenaStatsChart';
import { mean } from 'lodash';
import useFetchArenaCategoryQuestions from '../hooks/useFetchArenaCategoryQuestions';
import { Question } from '../../../../types/game';
import { CategoryScorecardDialog } from './dialogs/CategoryScorecardDialog';
import { QuestionStatsLean } from '../../../../types/question-stats';

type Props = {
  arenaAdvancedStatsData: ArenaAdvancedStats;
  username: string;
};

const ArenaStats = ({ arenaAdvancedStatsData, username }: Props) => {
  const [openCategoryScorecardDialog, setOpenCategoryScorecardDialog] =
    useState(false);

  const [selectedQuestions, setSelectedQuestions] = useState<Question[]>([]);
  const [selectedQuestionStats, setSelectedQuestionStats] =
    useState<QuestionStatsLean[]>();
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [showMedians, setShowMedians] = useState(false);

  const handleOnSuccess = (data: any) => {
    const questions = data.data.questions as Question[];
    const questionStats = data.data.questionStats as QuestionStatsLean[];
    setSelectedQuestions(questions);
    setSelectedQuestionStats(questionStats);
    setOpenCategoryScorecardDialog(true);
  };

  const { fetchArenaCategoryQuestions } =
    useFetchArenaCategoryQuestions(handleOnSuccess);

  const getCategoryAverages = (arenaAdvancedStatsData: ArenaCategoryStats) => {
    const cateogoryAverages = [];

    for (const category of Object.keys(arenaAdvancedStatsData)) {
      const categoryStats = arenaAdvancedStatsData[
        category
      ] as ArenaCategoryStat[];

      const average = mean(categoryStats.map((stat) => stat.score));

      cateogoryAverages.push({
        category,
        average,
      });
    }

    return cateogoryAverages;
  };

  const categoryAverages = useMemo(
    () =>
      arenaAdvancedStatsData
        ? getCategoryAverages(arenaAdvancedStatsData.arenaAdvancedStats)
        : null,
    [arenaAdvancedStatsData]
  );

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
    const questionIds = arenaAdvancedStatsData.arenaAdvancedStats[category].map(
      (stat) => stat.questionId
    );
    console.log('questionIds', questionIds);
    fetchArenaCategoryQuestions({ questionIds });
  };

  const handleShowMediansChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowMedians(event.target.checked);
  };

  return (
    <>
      <Box pt={2}>
        <Typography py={1} fontWeight={500}>
          Category Averages
        </Typography>

        <Card
          sx={{
            bgcolor: '#383d42d1',
          }}
        >
          {categoryAverages && (
            <Stack
              direction="row"
              justifyContent={'space-between'}
              alignItems={'center'}
              pt={1}
              px={1}
            >
              <Typography variant="subtitle2">
                Click a category to see specific questions
              </Typography>

              <FormGroup sx={{ p: 0 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showMedians}
                      onChange={handleShowMediansChange}
                      size="small"
                    />
                  }
                  label="Medians"
                />
              </FormGroup>
            </Stack>
          )}
          <Box p={2} height={categoryAverages ? 500 : 200}>
            {categoryAverages ? (
              <ArenaStatsChart
                categoryAverages={categoryAverages}
                arenaAdvancedStatsAggs={
                  arenaAdvancedStatsData.arenaAdvancedStatsAggs
                }
                handleCategoryClick={handleCategoryClick}
                showMedians={showMedians}
              />
            ) : (
              <Box width="100%" height={'100%'}>
                <Typography py={8} textAlign={'center'}>
                  No stats yet
                </Typography>
              </Box>
            )}
          </Box>
        </Card>
      </Box>
      {/* 
      <RecentGamesAllDialog
        handleCardClick={viewScorecardDialog}
        open={openRecentGamesAllDialog}
        onClose={() => setOpenRecentGamesAllDialog(false)}
        trueSkillData={trueSkillData}
      /> */}

      {arenaAdvancedStatsData && selectedQuestions && selectedQuestionStats && (
        <CategoryScorecardDialog
          category={selectedCategory}
          onClose={() => setOpenCategoryScorecardDialog(false)}
          open={openCategoryScorecardDialog}
          questions={selectedQuestions}
          questionStats={selectedQuestionStats}
          stats={arenaAdvancedStatsData.arenaAdvancedStats[selectedCategory]}
          username={username}
        />
      )}
    </>
  );
};

export default ArenaStats;
