export const getInputColor = (correctAnswer: boolean) =>
  correctAnswer ? 'success' : 'error';

export const collapseWords = (words: string, isMath: boolean) => {
  const regex = isMath ? /[^\w\-.]+/g : /[\W_]+/g;
  const collapsed = words
    .trimEnd()
    .toUpperCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '') // removes accents
    .replace(/ & /g, 'AND')
    .replace(/&/g, 'AND')
    .replace(regex, '');
  const firstWord = collapsed.replace(/ .*/, '');
  if (firstWord === 'THE' || firstWord === 'AN' || firstWord === 'A') {
    return collapsed.substr(collapsed.indexOf(' ') + 1);
  }
  return collapsed;
};
