import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Avatar, DialogTitle, Link, Stack, Typography } from '@mui/material';
import { useGetPlayerProfile } from '../hooks/useGetPlayerProfile';
import { useUpdateProfileProperty } from '../hooks/useUpdateProfileProperty';
import { resizeImage } from '../api/cloudinary';
// import useFetchTeamProfile from '../pages/team/useFetchTeamProfile';
import usePostJoinRequestLeagueTeam from '../pages/league-registration/hooks/usePostJoinRequestLeagueTeam';
import { Profile } from '../types/player';
import { getSubscriptionPlan } from '../utils/profile';
import { useNavigate } from 'react-router-dom';
import useCreateCheckoutSession from '../pages/pricing/useCreateCheckoutSession';

type PropTypes = {
  close: () => void;
  invitedBy: string;
  open: boolean;
  profile: Profile;
};

export const InvitationWelcomeDialog = ({
  close,
  invitedBy,
  open,
  profile,
}: PropTypes) => {
  const navigate = useNavigate();

  const handleOnSuccess = (res) => {
    const url = res?.data?.url;
    if (!url) return;
    window.location.href = url;
  };

  const { createCheckoutSession } = useCreateCheckoutSession(handleOnSuccess);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleJoinRequestSuccess = () => setShowConfirmation(true);

  const { data: playerProfileData, fetchPlayerProfile } = useGetPlayerProfile();
  const { updateProfile } = useUpdateProfileProperty();
  const { postJoinLeagueTeamRequest } = usePostJoinRequestLeagueTeam(
    handleJoinRequestSuccess
  );

  // const { teamProfile } = useFetchTeamProfile(
  //   playerProfileData?.currentLeague?.teamId
  // );

  useEffect(() => {
    if (!invitedBy) return;
    fetchPlayerProfile(invitedBy);
  }, [invitedBy, fetchPlayerProfile]);

  const inviterAvatarPublicId = playerProfileData?.avatarPublicId;
  const inviterUsername = playerProfileData?.username;

  // const handleJoinRequestClick = () => {
  //   if (!teamProfile?.team?._id || !profile) return;
  //   postJoinLeagueTeamRequest({
  //     profileId: profile._id,
  //     teamId: teamProfile?.team?._id,
  //   });
  // };

  const handleClose = () => {
    if (!profile) return;
    updateProfile(profile._id, 'invitationWelcomeSeen', true);
    close();
  };

  const handleUpgradeClick = () => {
    if (!profile) return;
    updateProfile(profile._id, 'invitationWelcomeSeen', true);
    navigate('/pricing');
    close();
  };

  const subscriptionPlan = getSubscriptionPlan(profile);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>
        <Typography variant="h5" letterSpacing={2} textAlign={'center'}>
          TEAM INVITATION
        </Typography>
      </DialogTitle>
      <DialogContent>
        {showConfirmation ? (
          <Typography fontFamily={'noto-sans'} textAlign={'center'} py={2}>
            Your request has been submitted. You will be notified when the team
            captain approves your request.
          </Typography>
        ) : (
          <Stack py={4} spacing={4}>
            <Typography fontFamily={'noto-sans'} textAlign={'center'}>
              You have been invited by
            </Typography>
            <Stack
              direction={'row'}
              spacing={2}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Avatar
                src={resizeImage(inviterAvatarPublicId, 80, 80)}
                variant="rounded"
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
              <Typography variant="h5">{inviterUsername}</Typography>
            </Stack>
            {/* <Typography fontFamily={'noto-sans'} textAlign={'center'}>
              to join team{' '}
              <Typography fontWeight={600} display="inline" color="lightgreen">
                {teamProfile?.team?.teamName}
              </Typography>{' '}
              in the upcoming season
            </Typography> */}

            {subscriptionPlan === 'free' && (
              <Typography fontFamily={'noto-sans'} textAlign={'center'}>
                You need a league membership to join.{' '}
                <Link
                  onClick={() =>
                    createCheckoutSession({ lookup_key: 'preseason' })
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  Click here to upgrade.
                </Link>
              </Typography>
            )}
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {showConfirmation ? 'Close' : 'NO THANKS'}
        </Button>
        {/* {!showConfirmation && (
          <Button
            variant="contained"
            disabled={subscriptionPlan === 'free'}
            onClick={handleJoinRequestClick}
          >
            REQUEST TO JOIN
          </Button>
        )} */}
      </DialogActions>
    </Dialog>
  );
};
