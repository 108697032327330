import React, {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSocketContext } from './SocketProvider';
import { LobbyGame } from '../types/game';
import { useProfileContext } from './ProfileProvider';
import useFetchLobbyGames from '../pages/quick-play/hooks/useFetchLobbyGames';
import { Player } from '../types/player';
import useGetAllAchievements from '../hooks/useGetAllAchievements';
import { Achievement } from '../types';

interface QuickPlayGamesContextType {
  allAchievements: Achievement[];
  joinedQuickPlayGame: LobbyGame | undefined;
  quickPlayGamesOpen: LobbyGame[] | null;
  quickPlayGamesSorted: LobbyGame[] | null;
  openPlayDrawer: boolean;
  setOpenPlayDrawer: (open: boolean) => void;
}

export const QuickPlayGamesContext = createContext<QuickPlayGamesContextType>({
  allAchievements: [],
  joinedQuickPlayGame: undefined,
  quickPlayGamesOpen: null,
  quickPlayGamesSorted: null,
  openPlayDrawer: false,
  setOpenPlayDrawer: () => {},
});

type QuickPlayGamesProviderProps = {
  children: ReactNode;
};

export const useQuickPlayGamesContext = () => useContext(QuickPlayGamesContext);

export const QuickPlayGamesProvider: FC<QuickPlayGamesProviderProps> = ({
  children,
}) => {
  const { profile } = useProfileContext();
  const { socket } = useSocketContext();

  const [openPlayDrawer, setOpenPlayDrawer] = useState(false);

  const { lobbyGames: lobbyGamesCompleted } = useFetchLobbyGames(
    !!profile,
    100
  );

  const [quickPlayCurrentLobbyGames, setQuickPlayCurrentLobbyGames] = useState<
    LobbyGame[]
  >([]);

  const { data: allAchievements } = useGetAllAchievements();

  const quickPlayGamesOpen =
    quickPlayCurrentLobbyGames?.filter(
      (g) => !g.gameStarted && g.mode !== 'solo'
    ) || [];

  // FIX THIS
  const castedLobbyGamesCompleted =
    lobbyGamesCompleted as unknown as LobbyGame[];

  const combinedGames = castedLobbyGamesCompleted
    ? castedLobbyGamesCompleted.concat(
        quickPlayCurrentLobbyGames.filter((g) => g.mode !== 'solo')
      )
    : quickPlayCurrentLobbyGames.filter((g) => g.mode !== 'solo');

  const quickPlayGamesSorted =
    combinedGames?.sort((a, b) => b.createdAt - a.createdAt) || [];

  const joinedQuickPlayGame = quickPlayGamesOpen.find((g) =>
    g.activePlayers.includes(profile?._id)
  );

  const replaceGame = (currentLobbyGames: LobbyGame[], gameId: string) =>
    currentLobbyGames
      ? currentLobbyGames.filter((g) => g.gameId !== gameId)
      : [];

  const deleteGame = (currentLobbyGames: LobbyGame[], gameId: string) =>
    currentLobbyGames?.filter((g) => g.gameId !== gameId);

  const quickPlayLobbyGameUpdate = useCallback(
    (update: {
      gameId: string;
      game: LobbyGame;
      action: 'create' | 'update' | 'delete';
    }) => {
      const { gameId, game, action } = update;
      if (action === 'create' || action === 'update') {
        setQuickPlayCurrentLobbyGames((prev) =>
          replaceGame(prev, gameId)?.concat(game)
        );
        return;
      }
      if (action === 'delete') {
        setQuickPlayCurrentLobbyGames((prev) => deleteGame(prev, gameId));
        return;
      }
    },
    []
  );

  const quickPlayPlayerUpdate = (data: {
    gameId: string;
    player: Player;
    action: 'addPlayer';
  }) => {
    if (data.action === 'addPlayer') {
      setQuickPlayCurrentLobbyGames((prev) =>
        addPlayerToGame(data.player, prev)
      );

      const addPlayerToGame = (player: any, lobbyGames?: LobbyGame[]) => {
        const found = lobbyGames?.find((g) => g.gameId === data.gameId);
        if (!found) return lobbyGames;
        found.activePlayers.push(player.profileId);
        found.players[player.profileId] = player;
        const updatedLobbyGames = lobbyGames
          ?.filter((g) => g.gameId !== data.gameId)
          .concat(found);
        return updatedLobbyGames;
      };
      return;
    }
  };

  const quickPlayGamePropertyUpdate = (update: {
    gameId: string;
    property: keyof LobbyGame;
    data: any;
  }) => {
    // console.log('quickPlayGamePropertyUpdate', update);
    setQuickPlayCurrentLobbyGames((prev) => updateGameProperty(update, prev));
    const updateGameProperty = (
      update: {
        gameId: string;
        property: keyof LobbyGame;
        data: any;
      },
      lobbyGames?: LobbyGame[]
    ) => {
      const found: any = lobbyGames?.find((g) => g.gameId === update.gameId);
      if (!found) return lobbyGames;
      found[update.property] = update.data;
      const updatedLobbyGames = lobbyGames
        ?.filter((g) => g.gameId !== update.gameId)
        .concat(found);
      return updatedLobbyGames;
    };
  };

  useEffect(() => {
    if (!socket) return;

    socket.on('qpLobby', setQuickPlayCurrentLobbyGames);
    socket.on('qpLobbyGameUpdate', quickPlayLobbyGameUpdate);
    socket.on('qpLobbyPlayerUpdate', quickPlayPlayerUpdate);
    socket.on('qpLobbyGamePropertyUpdate', quickPlayGamePropertyUpdate);

    socket.emit('requestQuickPlayGames', { profileId: profile._id });

    return () => {
      socket.off('qpLobby', setQuickPlayCurrentLobbyGames);
      socket.off('qpLobbyGameUpdate', quickPlayLobbyGameUpdate);
      socket.off('qpLobbyPlayerUpdate', quickPlayPlayerUpdate);
      socket.off('qpLobbyGamePropertyUpdate', quickPlayGamePropertyUpdate);
    };
  }, [socket]);

  return (
    <QuickPlayGamesContext.Provider
      value={{
        allAchievements,
        joinedQuickPlayGame,
        quickPlayGamesOpen,
        quickPlayGamesSorted,
        openPlayDrawer,
        setOpenPlayDrawer,
      }}
    >
      {children}
    </QuickPlayGamesContext.Provider>
  );
};
