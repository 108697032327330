import {
  Avatar,
  Badge,
  Box,
  Divider,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StyledPaperItem } from '../../../../mui-theme/mui-components';
import { getAvatarSrc, resizeImage } from '../../../../api/cloudinary';
import { formattedScore } from '../../../../components/scorecard';
import { PrecisionGraphicScorecardIcon } from '../../../../components/scorecard/PrecisionGraphicScorecardIcons';
import { Team } from '../../../../types/game';
import {
  ArenaTeamLiveRoundScores,
  DetailedPlayer,
  Player,
} from '../../../../types/player';
import { ArenaTeam } from '../../../arena-team/types';
import { getBestBallRoundScores, getPlayerBestBallRoundScores } from './utils';
import { ArenaTeamsBestBallRoundScores } from './types';

const TeamAnswerAttemptsTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 350,
  },
});

const StatDescriptionsTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    fontSize: 14,
    border: '1px solid',
  },
}));

type PropTypes = {
  detailedPlayers: Record<string, DetailedPlayer>;
  myArenaTeam: ArenaTeam;
  myArenaTeamScore: number;
  otherArenaTeam: ArenaTeam;
  otherArenaTeamScore: number;
};

export const ScoreCardStatsArenaTeams = ({
  detailedPlayers,
  myArenaTeam,
  myArenaTeamScore,
  otherArenaTeam,
  otherArenaTeamScore,
}: PropTypes) => {
  const theme = useTheme();

  const teamAMembers = myArenaTeam?.members;
  const teamBMembers = otherArenaTeam?.members;

  const teamAPlayers = teamAMembers
    ? Object.values(detailedPlayers).filter((p) =>
        teamAMembers.includes(p.profileId)
      )
    : Object.values(detailedPlayers).filter(
        (p) => p.arenaTeamId === myArenaTeam._id
      );

  const teamBPlayers = teamBMembers
    ? Object.values(detailedPlayers).filter((p) =>
        teamBMembers.includes(p.profileId)
      )
    : Object.values(detailedPlayers).filter(
        (p) => p.arenaTeamId !== myArenaTeam._id
      );

  const myTeamRoundScores = getBestBallRoundScores(teamAPlayers);

  const otherTeamRoundScores = getBestBallRoundScores(teamBPlayers);

  const playerDisplay = (player: Player) => {
    return (
      <StyledPaperItem>
        <Stack
          direction={'row'}
          spacing={1}
          width={'100%'}
          height={'40px'}
          alignItems={'center'}
        >
          <Avatar
            src={getAvatarSrc(player.avatarPublicId, player.avatarUrl, 80, 80)}
            variant="rounded"
            sx={{
              height: 40,
              width: 40,
            }}
          />
          <Typography
            fontWeight={600}
            textAlign={'left'}
            width="100%"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'pre',
            }}
          >
            {player.username}
          </Typography>
        </Stack>
      </StyledPaperItem>
    );
  };

  const getPlayerStats = (
    player: DetailedPlayer,
    myTeamRoundScores: ArenaTeamsBestBallRoundScores,
    otherTeamRoundScores: ArenaTeamsBestBallRoundScores
  ) => {
    const myBestBallRoundScores = getPlayerBestBallRoundScores(
      myTeamRoundScores,
      player.profileId
    );
    const otherBestBallRoundScores =
      getPlayerBestBallRoundScores(otherTeamRoundScores);

    const roundScores = Object.values(myBestBallRoundScores);
    const questions = [];
    const penalties = [];
    const timeouts = [];
    const assists = [];
    const fuzzy = [];
    const attempts = [];
    let assistsCount = 0;
    let gets = 0;
    let penaltyCount = 0;
    let score = 0;
    let saves = 0;
    let advantagePlays = 0;
    for (let i = 0; i < roundScores.length; i++) {
      if (roundScores[i] !== null) {
        const playerScore =
          roundScores[i].score +
          roundScores[i]?.fuzzyPenalty +
          roundScores[i]?.penalty;
        gets = gets + 1;
        if (playerScore < 0) {
          score = score + playerScore;
        } else {
          saves = saves + 1;
        }
        const otherPlayerScore =
          otherBestBallRoundScores[i]?.score +
          otherBestBallRoundScores[i]?.fuzzyPenalty +
          otherBestBallRoundScores[i]?.penalty;
        const diff = playerScore - otherPlayerScore;
        if (diff < 0) {
          advantagePlays = advantagePlays + diff;
        }
      }
      if (roundScores[i]?.penalty) {
        penaltyCount = penaltyCount + 1;
      }
      if (roundScores[i]?.assist) {
        assistsCount = assistsCount + 1;
      }
      questions.push(roundScores[i]?.score);
      penalties.push(roundScores[i]?.penalty);
      assists.push(roundScores[i]?.assist);
      fuzzy.push(roundScores[i]?.fuzzyPenalty);
      timeouts.push({
        timerLimitReached: myBestBallRoundScores[i]?.timerLimitReached,
        score: myBestBallRoundScores[i]?.score,
      });
      attempts.push(roundScores[i]?.answerAttempts);
    }
    return {
      gets,
      assists,
      assistsCount,
      penaltyCount,
      score,
      saves,
      advantagePlays,
      questions,
      penalties,
      fuzzy,
      timeouts,
      attempts,
    };
  };

  const displayStat = (
    value: number | string,
    header?: boolean,
    addPlus?: boolean,
    color?: string,
    width?: number
  ) => {
    return (
      <StyledPaperItem
        sx={{
          paddingY: header ? 0 : null,
          bgcolor: header ? 'transparent' : null,
          boxShadow: header ? 'none' : null,
          backgroundImage: header ? 'none' : null,
        }}
      >
        <Stack
          direction={'row'}
          spacing={1}
          width={width || '40px'}
          height={header ? '60px' : '40px'}
          paddingX={1}
          alignItems={header ? 'end' : 'center'}
        >
          <Typography
            variant={header ? 'h6' : undefined}
            fontWeight={600}
            textAlign={'center'}
            width="100%"
            sx={{
              color: color || null,
            }}
          >
            {addPlus && typeof value === 'number' && value > 0 ? '+' : ''}
            {value}
          </Typography>
        </Stack>
      </StyledPaperItem>
    );
  };

  const underParScoreBallStyle = {
    bgcolor: `${theme.palette.success.main}40`,
    color: 'text.primary',
    textAlign: 'center',
    boxShadow: `0 0 5px 1px ${theme.palette.success.main}, 0 0 2px 3px ${theme.palette.success.main} inset`,
  };

  const timedoutScoreBallStyle = {
    bgcolor: `${theme.palette.error.main}40`,
    color: 'error.contrastText',
    display: 'inline-flex',
    boxShadow: `0 0 5px 1px ${theme.palette.error.main}, 0 0 2px 3px ${theme.palette.error.main} inset`,
  };

  const formatPlayerScore = (
    questionScore: number,
    penalty: number,
    fuzzy: number,
    timeout: { timerLimitReached: boolean; score: number },
    answerAttempts: { input: string; gameClock: number }[]
  ) => {
    if (questionScore === undefined || questionScore === null) {
      return (
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          width={'80px'}
          height={'100%'}
          position={'relative'}
          paddingX={5}
        >
          <Typography variant="h6" textAlign={'center'} width="100%">
            -
          </Typography>
        </Stack>
      );
    }
    let scoreStyle: any = {
      color: 'error.contrastText',
    };
    let avatarShape: any = 'rounded';
    if (questionScore < 0) {
      scoreStyle = underParScoreBallStyle;
      avatarShape = 'round';
    }
    if (timeout.timerLimitReached) {
      scoreStyle = timedoutScoreBallStyle;
      avatarShape = 'square';
    }
    return (
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        width={'80px'}
        height={'100%'}
        position={'relative'}
        paddingX={5}
      >
        <Badge badgeContent={penalty} color="warning">
          <TeamAnswerAttemptsTooltip
            placement="top"
            title={answerAttemptTooltip(answerAttempts)}
          >
            <Avatar sx={scoreStyle} variant={avatarShape}>
              {formattedScore(
                timeout.timerLimitReached ? timeout.score : questionScore
              )}
            </Avatar>
          </TeamAnswerAttemptsTooltip>
        </Badge>
        {fuzzy !== null ? (
          <PrecisionGraphicScorecardIcon
            fuzzyPenalty={fuzzy}
            position={'absolute'}
          />
        ) : null}
      </Stack>
    );
  };

  const answerAttemptTooltip = (
    answerAttempts: { input: string; gameClock: number }[]
  ) => {
    return (
      <Stack spacing={2} sx={{ padding: 1 }}>
        <Typography textAlign="center">Answer Attempts</Typography>
        {answerAttempts?.map((answer) =>
          answer.gameClock && answer.input ? (
            <Stack direction="row" spacing={3} minWidth={'350px'}>
              <Typography
                variant="body1"
                textAlign={'right'}
                sx={{
                  width: '40%',
                  color:
                    theme.palette.mode === 'light'
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  textShadow:
                    theme.palette.mode === 'dark'
                      ? `0 0 1px ${theme.palette.primary.main}, 10px 0px 20px ${theme.palette.primary.main}, 0px 0px 16px ${theme.palette.primary.main}`
                      : 'none',
                }}
              >
                {answer.gameClock.toFixed(2)} sec
              </Typography>
              <Typography
                variant="body1"
                textAlign={'left'}
                sx={{ width: '60%' }}
              >
                "{answer.input}"
              </Typography>
            </Stack>
          ) : (
            <Typography variant="body1">None</Typography>
          )
        )}
      </Stack>
    );
  };

  const questionLabels = [
    'Q1',
    'Q2',
    'Q3',
    'Q4',
    'Q5',
    'Q6',
    'Q7',
    'Q8',
    'Q9',
    'Q10',
  ];

  return (
    <Stack direction="row" overflow={'auto'} pb={20}>
      <Stack spacing={8} width={'350px'}>
        <Stack spacing={1}>
          <Stack height={'60px'}>
            <Stack direction={'row'} spacing={1} pl={1} alignItems={'center'}>
              <Typography
                variant="h5"
                sx={{
                  whiteSpace: 'pre',
                  color: '#ec9bff',
                }}
              >
                {myArenaTeam?.teamName}
              </Typography>
              <Avatar
                src={getAvatarSrc(
                  myArenaTeam.avatarPublicId,
                  myArenaTeam.avatarUrl,
                  80,
                  80
                )}
                variant="rounded"
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </Stack>
            <Typography pt={0.5} pl={2} fontWeight={600}>
              Score {myArenaTeamScore > 0 ? '+' : ''}
              {myArenaTeamScore}
            </Typography>
          </Stack>
          {teamAPlayers?.map((p) => playerDisplay(p))}
        </Stack>
        <Stack spacing={1}>
          <Stack height={'60px'}>
            <Stack direction={'row'} spacing={1} pl={1} alignItems={'center'}>
              <Typography
                variant="h5"
                sx={{
                  whiteSpace: 'pre',
                  color: '#00e0ff',
                }}
              >
                {otherArenaTeam?.teamName}
              </Typography>
              <Avatar
                src={getAvatarSrc(
                  otherArenaTeam.avatarPublicId,
                  otherArenaTeam.avatarUrl,
                  80,
                  80
                )}
                variant="rounded"
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </Stack>
            <Typography pt={0.5} pl={2} fontWeight={600}>
              Score {otherArenaTeamScore > 0 ? '+' : ''}
              {otherArenaTeamScore}
            </Typography>
          </Stack>
          {teamBPlayers?.map((p) => playerDisplay(p))}
        </Stack>
      </Stack>

      <Stack spacing={8} overflow={'auto'} ml={1}>
        <Stack spacing={1}>
          <Stack direction={'row'}>
            <StatDescriptionsTooltip
              title="Best Balls: top scorer for a question"
              placement="top"
            >
              <Stack spacing={1}>
                {displayStat('BB', true, false, theme.palette.primary.main, 60)}
              </Stack>
            </StatDescriptionsTooltip>
            <StatDescriptionsTooltip
              title="Total: sum of Best Balls scores"
              placement="top"
            >
              <Stack spacing={1} ml={1}>
                {displayStat(
                  'TOT',
                  true,
                  false,
                  theme.palette.primary.main,
                  60
                )}
              </Stack>
            </StatDescriptionsTooltip>
            <StatDescriptionsTooltip
              title="Advantage Play Score: sum of differences of your Best Ball scores vs the opposing team's Best Ball scores"
              placement="top"
            >
              <Stack spacing={1} ml={1}>
                {displayStat(
                  'APS',
                  true,
                  false,
                  theme.palette.primary.main,
                  60
                )}
              </Stack>
            </StatDescriptionsTooltip>
            <Stack direction={'row'} alignItems={'end'} height={'60px'} ml={1}>
              {questionLabels.map((n) => (
                <Stack
                  justifyContent={'center'}
                  alignItems={'end'}
                  width={'80px'}
                >
                  <Typography variant="h6" textAlign={'center'} width="100%">
                    {n}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
          {teamAPlayers?.map((p) => {
            const {
              gets,
              assists,
              assistsCount,
              penaltyCount,
              score,
              saves,
              advantagePlays,
              questions,
              penalties,
              fuzzy,
              timeouts,
              attempts,
            } = getPlayerStats(p, myTeamRoundScores, otherTeamRoundScores);
            return (
              <Stack direction={'row'}>
                <Stack spacing={1}>
                  {displayStat(
                    gets,
                    false,
                    false,
                    theme.palette.primary.main,
                    60
                  )}
                </Stack>
                <Stack spacing={1} ml={1}>
                  {displayStat(
                    score,
                    false,
                    true,
                    theme.palette.primary.main,
                    60
                  )}
                </Stack>
                <Stack spacing={1} ml={1}>
                  {displayStat(
                    advantagePlays,
                    false,
                    true,
                    theme.palette.primary.main,
                    60
                  )}
                </Stack>
                <StyledPaperItem sx={{ ml: 1, padding: 0 }}>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    height={'100%'}
                  >
                    {questions.map((q, i) =>
                      formatPlayerScore(
                        q,
                        penalties[i],
                        fuzzy[i],
                        timeouts[i],
                        attempts[i]
                      )
                    )}
                  </Stack>
                </StyledPaperItem>
              </Stack>
            );
          })}
        </Stack>
        <Stack spacing={1} ml={1}>
          <Stack direction={'row'}>
            <StatDescriptionsTooltip
              title="Best Balls: top scorer for a question"
              placement="top"
            >
              <Stack spacing={1}>
                {displayStat('BB', true, false, theme.palette.primary.main, 60)}
              </Stack>
            </StatDescriptionsTooltip>
            <StatDescriptionsTooltip
              title="Total: sum of Best Balls scores"
              placement="top"
            >
              <Stack spacing={1} ml={1}>
                {displayStat(
                  'TOT',
                  true,
                  false,
                  theme.palette.primary.main,
                  60
                )}
              </Stack>
            </StatDescriptionsTooltip>
            <StatDescriptionsTooltip
              title="Advantage Play Score: sum of differences of your Best Ball scores vs the opposing team's Best Ball scores"
              placement="top"
            >
              <Stack spacing={1} ml={1}>
                {displayStat(
                  'APS',
                  true,
                  false,
                  theme.palette.primary.main,
                  60
                )}
              </Stack>
            </StatDescriptionsTooltip>
            <Stack direction={'row'} alignItems={'end'} height={'60px'} ml={1}>
              {questionLabels.map((n) => (
                <Stack
                  justifyContent={'center'}
                  alignItems={'end'}
                  width={'80px'}
                >
                  <Typography variant="h6" textAlign={'center'} width="100%">
                    {n}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
          {teamBPlayers?.map((p) => {
            const {
              gets,
              assists,
              assistsCount,
              penaltyCount,
              score,
              saves,
              advantagePlays,
              questions,
              penalties,
              fuzzy,
              timeouts,
              attempts,
            } = getPlayerStats(p, otherTeamRoundScores, myTeamRoundScores);
            return (
              <Stack direction={'row'}>
                <Stack spacing={1}>
                  {displayStat(
                    gets,
                    false,
                    false,
                    theme.palette.primary.main,
                    60
                  )}
                </Stack>
                <Stack spacing={1} ml={1}>
                  {displayStat(
                    score,
                    false,
                    true,
                    theme.palette.primary.main,
                    60
                  )}
                </Stack>
                <Stack spacing={1} ml={1}>
                  {displayStat(
                    advantagePlays,
                    false,
                    true,
                    theme.palette.primary.main,
                    60
                  )}
                </Stack>
                <StyledPaperItem sx={{ ml: 1, padding: 0 }}>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    height={'100%'}
                  >
                    {questions.map((q, i) =>
                      formatPlayerScore(
                        q,
                        penalties[i],
                        fuzzy[i],
                        timeouts[i],
                        attempts[i]
                      )
                    )}
                  </Stack>
                </StyledPaperItem>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};
