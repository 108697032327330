import React from 'react';
import { Avatar } from '@mui/material';

type Props = {
  score: number;
};

const ScoreAvatar = ({ score }: Props) => {
  const variant = score < 0 ? 'circular' : 'rounded';

  return (
    <Avatar
      variant={variant}
      sx={{
        fontSize: 20,
        color: 'white',
        bgcolor: score < 0 ? '#0d2f14' : '#434343',
        height: 50,
        width: 50,
        boxShadow:
          score < 0 ? `0 0 5px 1px #0db586, 0 0 2px 3px #0db586 inset` : null,
        textShadow:
          'rgb(255, 186, 24) 0px 0px 0px, rgb(255, 186, 24) 0px 0px 0px, rgb(255, 186, 24) 0px 0px 16px',
      }}
    >
      {score > 0 ? '+' : ''}
      {score}
    </Avatar>
  );
};

export default ScoreAvatar;
