import React from 'react';
import { zeroPad } from 'react-countdown';

export const countdownRendererMinutes = ({ minutes, seconds }: any) => {
  return (
    <span>
      {zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  );
};

export const countdownRendererHours = ({ hours, minutes, seconds }: any) => {
  return (
    <span>
      {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  );
};

export const countdownRendererDays = ({
  days,
  hours,
  minutes,
  seconds,
}: any) => {
  return (
    <span>
      {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  );
};
