import React from 'react';
import { Profile } from '../../../types/player';
import Summary from './Summary';
import DailiesStreaksProfile from './DailiesStreaks';
import { DailiesGames } from './DailiesGames';

type Props = {
  isAdmin: boolean;
  profileId: string;
  viewProfile: Profile;
};

const DailiesProfile = ({ isAdmin, profileId, viewProfile }: Props) => {
  return (
    <>
      <Summary viewProfile={viewProfile} />

      <DailiesStreaksProfile viewProfile={viewProfile} />

      {(viewProfile._id === profileId || isAdmin) && (
        <DailiesGames viewProfile={viewProfile} />
      )}
    </>
  );
};

export default DailiesProfile;
