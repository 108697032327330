import React from 'react';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FlareIcon from '@mui/icons-material/Flare';
import DiamondIcon from '@mui/icons-material/Diamond';
import { TbAtom } from 'react-icons/tb';
import RocketIcon from '@mui/icons-material/Rocket';
import { MEDAL_COLORS, MEDAL_REQUIREMENTS } from './constants';
import {
  CategorySet,
  MedalCategory,
  Punchcard,
  PunchcardCategory,
} from './types';
import { Tooltip } from '@mui/material';
import { roundToOneDecimal } from '../../../utils/math';

export const getPunchcardValue = (
  punchard: Punchcard,
  categorySet: CategorySet,
  punchcardProp: string
) => {
  const value = punchard?.[categorySet]?.[punchcardProp];
  if (punchcardProp === 'gamesPlayed') {
    return value !== undefined && value !== null ? value : '--';
  }
  return value !== undefined && value !== null
    ? `${value > 0 ? '+' : ''}${roundToOneDecimal(value)}`
    : '--';
};

export const getMedalColor = (
  punchard: Punchcard,
  categorySet: CategorySet,
  punchcardProp: string
) => {
  if (
    punchcardProp === 'bestAverage' &&
    punchard?.[categorySet]?.['gamesPlayed'] < 10
  )
    return 'black';

  const value = punchard?.[categorySet]?.[punchcardProp];
  for (const key of Object.keys(MEDAL_REQUIREMENTS[punchcardProp]).reverse()) {
    if (
      punchcardProp === 'bestAverage' &&
      value <= MEDAL_REQUIREMENTS[punchcardProp][key as MedalCategory]
    ) {
      return MEDAL_COLORS[key as MedalCategory];
    }
    if (
      punchcardProp === 'best' &&
      value <= MEDAL_REQUIREMENTS[punchcardProp][key as MedalCategory]
    ) {
      return MEDAL_COLORS[key as MedalCategory];
    }
    if (
      punchcardProp === 'gamesPlayed' &&
      value >= MEDAL_REQUIREMENTS[punchcardProp][key as MedalCategory]
    ) {
      return MEDAL_COLORS[key as MedalCategory];
    }
  }
  return 'black';
};

export const getIcon = (
  punchcard: Punchcard,
  categorySet: CategorySet,
  punchcardProp: string,
  value?: number
) => {
  if (value === undefined || value === null)
    return (
      <EmojiEventsIcon
        sx={{
          visibility: 'hidden',
        }}
      />
    );
  const avgGamesPlayed = punchcard?.[categorySet]?.['gamesPlayed'] || 0;
  for (const key of Object.keys(MEDAL_REQUIREMENTS[punchcardProp]).reverse()) {
    if (
      punchcardProp === 'bestAverage' &&
      avgGamesPlayed >= 10 &&
      value <= MEDAL_REQUIREMENTS[punchcardProp][key as MedalCategory]
    ) {
      let icon = (
        <EmojiEventsIcon
          sx={{
            color: getMedalColor(punchcard, categorySet, punchcardProp),
            visibility:
              getMedalColor(punchcard, categorySet, punchcardProp) === 'black'
                ? 'hidden'
                : 'visible',
          }}
        />
      );
      if (key === 'antimatter') {
        icon = (
          <TbAtom
            size={'1.5rem'}
            color={'#cbcbcb'}
            style={{ boxShadow: '1px 1px 15px #FFF, 1px 1px 5px #fffefe' }}
          />
        );
      }
      if (key === 'unobtainium') {
        icon = <RocketIcon sx={{ color: 'red' }} />;
      }
      if (key === 'diamond') {
        icon = (
          <DiamondIcon
            sx={{
              color: getMedalColor(punchcard, categorySet, punchcardProp),
            }}
          />
        );
      }
      if (key === 'platinum') {
        icon = (
          <FlareIcon
            sx={{
              color: getMedalColor(punchcard, categorySet, punchcardProp),
            }}
          />
        );
      }
      return (
        <Tooltip
          title={`Historical best 10 game average: ${value}`}
          placement="top"
        >
          {icon}
        </Tooltip>
      );
    }
    if (
      punchcardProp === 'best' &&
      value <= MEDAL_REQUIREMENTS[punchcardProp][key as MedalCategory]
    ) {
      if (key === 'antimatter') {
        return (
          <TbAtom
            size={'1.5rem'}
            color={'#cbcbcb'}
            style={{ boxShadow: '1px 1px 15px #FFF, 1px 1px 5px #fffefe' }}
          />
        );
      }
      if (key === 'unobtainium') {
        return <RocketIcon sx={{ color: 'red' }} />;
      }
      if (key === 'diamond') {
        return (
          <DiamondIcon
            sx={{
              color: getMedalColor(punchcard, categorySet, punchcardProp),
            }}
          />
        );
      }
      if (key === 'platinum') {
        return (
          <FlareIcon
            sx={{
              color: getMedalColor(punchcard, categorySet, punchcardProp),
            }}
          />
        );
      }
      return (
        <EmojiEventsIcon
          sx={{
            color: getMedalColor(punchcard, categorySet, punchcardProp),
            visibility:
              getMedalColor(punchcard, categorySet, punchcardProp) === 'black'
                ? 'hidden'
                : 'visible',
          }}
        />
      );
    }
    if (
      punchcardProp === 'gamesPlayed' &&
      value >= MEDAL_REQUIREMENTS[punchcardProp][key as MedalCategory]
    ) {
      if (key === 'antimatter') {
        return (
          <TbAtom
            size={'1.5rem'}
            color={'#cbcbcb'}
            style={{ boxShadow: '1px 1px 15px #FFF, 1px 1px 5px #fffefe' }}
          />
        );
      }
      if (key === 'unobtainium') {
        return <RocketIcon sx={{ color: 'red' }} />;
      }
      if (key === 'diamond') {
        return (
          <DiamondIcon
            sx={{
              color: getMedalColor(punchcard, categorySet, punchcardProp),
            }}
          />
        );
      }
      if (key === 'platinum') {
        return (
          <FlareIcon
            sx={{
              color: getMedalColor(punchcard, categorySet, punchcardProp),
            }}
          />
        );
      }
      return (
        <EmojiEventsIcon
          sx={{
            color: getMedalColor(punchcard, categorySet, punchcardProp),
            visibility:
              getMedalColor(punchcard, categorySet, punchcardProp) === 'black'
                ? 'hidden'
                : 'visible',
          }}
        />
      );
    }
  }
  return (
    <EmojiEventsIcon
      sx={{
        color: getMedalColor(punchcard, categorySet, punchcardProp),
        visibility:
          getMedalColor(punchcard, categorySet, punchcardProp) === 'black'
            ? 'hidden'
            : 'visible',
      }}
    />
  );
};
