import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography,
  Link,
  Box,
  styled,
  tableCellClasses,
  Tooltip,
  Switch,
  FormControl,
  FormControlLabel,
  FormGroup,
  Button,
  Snackbar,
  SnackbarContent,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ScorecardTableRow } from './ScorecardTableRow';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';
import ListIcon from '@mui/icons-material/List';
import { GiRank3 } from 'react-icons/gi';
import {
  ReportInaccuracy,
  Team,
  VanquishedDemon,
  Mode,
  SeedDetails,
  Question,
} from '../../types/game';
import { DetailedPlayer } from '../../types/player';
import { StarterKitConfig } from '../../types/starter-kits';

import { useQuestionLikeDislike } from './hooks/useQuestionLikeDislike';

import { useSeedDetails } from './hooks/useSeedDetails';
import { fetchGet } from '../../api/common';
import {
  MODE_DISPLAY_NAME,
  PRO_CASUAL_DISPLAY,
  CATEGORY_SETS_LABEL,
} from '../../constants/constants';
import { GameflowChartDialog } from '../../dialogs/GameflowChartDialog';
import { ImageDisplay } from '../../dialogs/ImageDisplay';
import { ScorecardLegendDialog } from '../../dialogs/ScorecardLegendDialog';
import { LeagueGameType } from '../../types';
import { QuestionStatsLean } from '../../types/question-stats';

export const PlayerTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    width: '100px',
    height: '50px',
    borderLeft: '1px solid #333333',
    borderRight: '1px solid #333333',
  },
}));

export const formattedScore = (score: number) =>
  score > 0 ? `+${score}` : score;

type PropTypes = {
  arenaSet?: number;
  averageDifficulty?: number;
  backgroundColor?: string;
  bannedCategories?: string[];
  categorySelections?: string[];
  categorySet?: string;
  centerSnackbar?: boolean;
  demons?: boolean;
  demonsVanquished?: VanquishedDemon[];
  detailedPlayers?: Record<string, DetailedPlayer>;
  enableReportInaccuracy?: boolean;
  gameType?: 'casual' | 'pro';
  handicap?: number;
  handleOpenChatClick?: () => void;
  handleReportInaccuracyRowClick?: (
    row: Question,
    questionIndex: number
  ) => void;
  isDaily?: boolean;
  isGhostChallenge?: boolean;
  isMatchMaking?: boolean;
  showDislike?: boolean;
  showRatings?: boolean;
  forPublic?: boolean;
  gameId?: string;
  hideTitle?: boolean;
  hideUsernameLink?: boolean;
  league?: boolean;
  leagueGameType?: LeagueGameType;
  mode?: Mode;
  myTeam?: Team;
  opponentTeam?: Team;
  profileId?: string;
  questionSet: Question[];
  ranked?: boolean;
  reportedInaccuracies?: ReportInaccuracy[];
  seededBy?: string;
  selectedPlayer?: DetailedPlayer;
  showChat?: boolean;
  starterKit?: StarterKitConfig;
  survivalMode?: 'endurance' | 'challenge' | 'demon';
  tournament?: number;
  unreadChat?: boolean;
  variant?: 'normal' | 'survival' | 'starter-kit' | 'turbo';
};

export const Scorecard = ({
  arenaSet,
  averageDifficulty,
  backgroundColor,
  bannedCategories,
  categorySelections,
  categorySet,
  centerSnackbar,
  demons,
  demonsVanquished,
  detailedPlayers,
  enableReportInaccuracy,
  forPublic,
  gameId,
  gameType,
  handicap,
  handleOpenChatClick,
  handleReportInaccuracyRowClick,
  isDaily,
  isGhostChallenge,
  isMatchMaking,
  showDislike,
  showRatings,
  hideTitle,
  hideUsernameLink,
  league,
  leagueGameType,
  mode,
  myTeam,
  opponentTeam,
  profileId,
  questionSet,
  ranked,
  reportedInaccuracies,
  seededBy,
  selectedPlayer,
  showChat,
  starterKit,
  survivalMode,
  tournament,
  unreadChat,
  variant,
}: PropTypes) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { dislikeQuestion, likeQuestion } = useQuestionLikeDislike();
  const { data: seedDetailsData, fetchSeedDetails } =
    useSeedDetails<SeedDetails>();
  const [playerSelf, setPlayerSelf] = useState<DetailedPlayer>();
  const [playerThem, setPlayerThem] = useState<DetailedPlayer>();
  const [showAllScores, setShowAllScores] = useState(false);
  const [runningTotals, setRunningTotals] = useState(false);
  const [openLegend, setOpenLegend] = useState(false);
  const [questionDisliked, setQuestionDisliked] = useState<string[]>([]); // string of questionIds
  const [questionLiked, setQuestionLiked] = useState<string[]>([]); // string of questionIds
  const [openScoreChart, setOpenScoreChart] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [revealAnswers, setRevealAnswers] = useState(new Array(10).fill(false));
  const [openQuestionImage, setOpenQuestionImage] = useState(false);
  const [questionImgUrl, setQuestionImgUrl] = useState<string>();
  const [openLastQuestionStats, setOpenLastQuestionStats] = useState(false);
  const [lastQuestionStats, setLastQuestionStats] = useState<any>();
  const [questionStats, setQuestionStats] = useState<QuestionStatsLean[]>([]);
  const [loadingQuestionStats, setLoadingQuestionStats] = useState(true);
  const [displayQuestionStatSeen, setDisplayQuestionStatSeen] = useState(false);
  const [customQuestionComments, setCustomQuestionComments] = useState<
    { questionId: string; authorComment: string; referenceLink: string }[]
  >([]);

  const playersCount = Object.keys(detailedPlayers || {}).length;

  useEffect(() => {
    fetchQuestionStats();
    // fetchCustomQuestionComments();
  }, []);

  useEffect(() => {
    if (!detailedPlayers) return;
    if (mode === 'solo') {
      setPlayerSelf(Object.values(detailedPlayers)[0]);
    }

    if (mode === 'sit-n-go') {
      const self = detailedPlayers[profileId];
      setPlayerSelf(self);
    }

    if (mode === 'sit-n-go' && playersCount === 2) {
      const self = detailedPlayers[profileId];
      if (!self) {
        setPlayerSelf(Object.values(detailedPlayers)[0]);
        setPlayerThem(Object.values(detailedPlayers)[1]);
      } else {
        const self = detailedPlayers[profileId];
        setPlayerSelf(self);
        const playerThemProfileId = Object.keys(detailedPlayers).find(
          (p) => p !== profileId
        );
        setPlayerThem(detailedPlayers[playerThemProfileId]);
      }
    }
  }, [detailedPlayers]);

  const fetchQuestionStats = async () => {
    const res = await fetchGet(`/question-stats/game/${gameId}/${profileId}`);
    setLoadingQuestionStats(false);
    if (res.message) return;
    setQuestionStats(res);
    const dislikedAQuestionInSet = res.filter((qs) =>
      qs?.dislikedBy?.includes(profileId)
    );
    if (dislikedAQuestionInSet.length) {
      setQuestionDisliked((prev) => prev.concat(res.questionId));
    }
  };

  const fetchCustomQuestionComments = async () => {
    const res = await fetchGet(`/custom-questions/links/${gameId}`);
    if (res && res.length) {
      setCustomQuestionComments(res);
    }
  };

  const handleUsernameClick = async (e: any, profileId?: string) => {
    if (!profileId) return;
    e.stopPropagation();
    navigate(`/profile/overview/${profileId}`);
  };

  const handleTeamnameClick = async (e: any, teamId?: string) => {
    if (!teamId) return;
    e.stopPropagation();
    navigate(`/team/overview/${teamId}`);
  };

  const calcDifficultyGauge = (averageDifficulty: number) =>
    Math.round(33.33 * averageDifficulty - 50) / 100;

  const handleDislikeQuestion = async (questionId: string) => {
    dislikeQuestion(questionId, profileId);
    setQuestionDisliked((prev) => prev.concat(questionId));
  };

  const handleLikeQuestion = async (questionId: string) => {
    likeQuestion(questionId, profileId);
    setQuestionLiked((prev) => prev.concat(questionId));
  };

  const handleShareClick = async () => {
    navigator.clipboard.writeText(
      `https://app.partrivia.com/game/view/${gameId}`
    );
    setOpenToast(true);
  };

  const tableCellsTotals = (
    <>
      {enableReportInaccuracy ? <TableCell width={'2%'}></TableCell> : null}
      <TableCell
        colSpan={
          showDislike || showRatings ? 7 : !forPublic && !starterKit ? 6 : 5
        }
        sx={{
          textAlign: 'right',
          fontWeight: 600,
        }}
      >
        <Typography variant="h6">Totals:</Typography>
      </TableCell>
      {(mode === 'solo' || mode === 'sit-n-go') &&
      playersCount <= 2 &&
      playerSelf ? (
        <PlayerTableCell
          sx={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          {formattedScore(playerSelf.parScore)}
          {/* {handicap && seededBy !== playerSelf.profileId
            ? ` (${handicap > 0 ? '+' : ''}${handicap})`
            : ''} */}
        </PlayerTableCell>
      ) : null}
      {mode === 'sit-n-go' && playersCount === 2 && playerThem ? (
        <PlayerTableCell
          sx={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          {playerThem.parScore !== undefined
            ? formattedScore(playerThem.parScore)
            : 'N/A'}
          {/* {handicap && seededBy !== playerThem.profileId
            ? ` (${handicap > 0 ? '+' : ''}${handicap})`
            : ''} */}
        </PlayerTableCell>
      ) : null}
      {mode === 'teams' && myTeam ? (
        <PlayerTableCell
          sx={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          {formattedScore(myTeam.parScore)}
        </PlayerTableCell>
      ) : null}
      {mode === 'teams' && opponentTeam ? (
        <PlayerTableCell
          sx={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          {formattedScore(opponentTeam.parScore)}
        </PlayerTableCell>
      ) : null}
      {mode === 'sit-n-go' && playersCount > 2 && selectedPlayer ? (
        <PlayerTableCell
          sx={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          {selectedPlayer.parScore !== undefined
            ? formattedScore(selectedPlayer.parScore)
            : 'N/A'}
        </PlayerTableCell>
      ) : null}
    </>
  );
  const handleOpenScoreChartClick = () => setOpenScoreChart(true);

  const toastMessage = (
    <Stack direction="row" spacing={1}>
      <LinkIcon />
      <Typography fontWeight={600}>Link copied to clipboard</Typography>
    </Stack>
  );

  const shareTooltip = (
    <Stack spacing={1}>
      <Typography
        variant="body1"
        sx={{
          color:
            theme.palette.mode === 'light'
              ? theme.palette.secondary.main
              : theme.palette.text.primary,
          textShadow:
            theme.palette.mode === 'dark'
              ? `0 0 1px ${theme.palette.secondary.main}, 10px 0px 20px ${theme.palette.secondary.main}, 0px 0px 16px ${theme.palette.secondary.main}`
              : 'none',
        }}
      >
        Won on a great answer?
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color:
            theme.palette.mode === 'light'
              ? theme.palette.secondary.main
              : theme.palette.text.primary,
          textShadow:
            theme.palette.mode === 'dark'
              ? `0 0 1px ${theme.palette.secondary.main}, 10px 0px 20px ${theme.palette.secondary.main}, 0px 0px 16px ${theme.palette.secondary.main}`
              : 'none',
        }}
      >
        Beat a TV celebrity?
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color:
            theme.palette.mode === 'light'
              ? theme.palette.secondary.main
              : theme.palette.text.primary,
          textShadow:
            theme.palette.mode === 'dark'
              ? `0 0 1px ${theme.palette.secondary.main}, 10px 0px 20px ${theme.palette.secondary.main}, 0px 0px 16px ${theme.palette.secondary.main}`
              : 'none',
        }}
      >
        Share with friends!
      </Typography>
    </Stack>
  );

  const handleRevealAnswerClick = (index: number) => {
    const updated = Object.assign([], revealAnswers, { [index]: true });
    setRevealAnswers(updated);
  };

  const handleImageClick = (imgUrl: string) => {
    setQuestionImgUrl(imgUrl);
    setOpenQuestionImage(true);
  };

  const handleLastQuestionClick = async (questionId: string) => {
    const res = await fetchGet(`/stats/question/${questionId}`);
    setLastQuestionStats(res);
    setOpenLastQuestionStats(true);
  };

  const generateGameModeLabel = () => {
    let label = '';
    if (tournament) {
      return 'Tournament';
    }
    if (demons) {
      return 'Face Your Demons';
    }
    if (league) {
      return 'League';
    }
    if (starterKit) {
      return starterKit.starterKitName;
    }
    if (mode !== 'solo' && ranked) {
      label = label + 'Ranked - ';
    }
    if (mode !== 'solo' && !ranked) {
      label = label + 'Friendly - ';
    }
    label = label + MODE_DISPLAY_NAME[mode] + ' - ';
    if (variant !== 'survival') {
      label = label + PRO_CASUAL_DISPLAY[gameType] + ' - ';
    }
    if (variant !== 'survival') {
      if (categorySet === 'none') {
        if (categorySelections?.length === 1) {
          label = label + categorySelections.join(' ');
        } else {
          label = label + 'Mix';
        }
      } else {
        label = label + (CATEGORY_SETS_LABEL[categorySet] || '');
      }
    }
    if (variant === 'survival') {
      if (survivalMode === 'challenge') {
        label = label + 'Survival - Challenge Tower';
      }
      if (survivalMode === 'endurance') {
        label = label + 'Survival - Endurance';
      }
    }
    return label;
  };

  const handleMouseOverBarChart = (mouseover: boolean) => {
    setDisplayQuestionStatSeen(mouseover);
  };

  const getTeamMemberUsernames = (team: Team) => {
    const username = Object.values(detailedPlayers)
      .filter((p) => team.members.includes(p.profileId))
      .map((p) => p.username);
    return (
      <Stack>
        {username.map((name) => (
          <Typography variant="subtitle2">{name}</Typography>
        ))}
      </Stack>
    );
  };

  return (
    <>
      <Stack>
        <Stack direction={'row'}>
          <>
            <Box width={'25%'}></Box>
            <Stack spacing={1} sx={{ width: '50%' }}>
              {!hideTitle ? (
                <Typography textAlign="center" sx={{ letterSpacing: 5 }}>
                  SCORECARD
                </Typography>
              ) : null}
              {/* <Typography
                variant="caption"
                textAlign="center"
                sx={{ color: theme.palette.success.main }}
              >
                {generateGameModeLabel()}
              </Typography> */}
            </Stack>
            {/* <Box
              width={'25%'}
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              {!isDaily ? (
                <Tooltip title={shareTooltip} placement="left">
                  <Button
                    variant="outlined"
                    startIcon={<ShareIcon />}
                    onClick={handleShareClick}
                    size="large"
                    color="primary"
                    sx={{ position: 'absolute', bottom: 0 }}
                  >
                    Share
                  </Button>
                </Tooltip>
              ) : null}
            </Box> */}
          </>
        </Stack>
        <Stack
          direction={'row'}
          spacing={5}
          sx={{
            display: 'flex',
            justifyContent: 'right',
            paddingY: '5px',
            paddingX: '10px',
            // border: '1px solid',
            // borderRadius: '5px',
            // borderColor: theme.palette.secondary.dark,
          }}
        >
          <Stack direction={'row'} spacing={2} width={'60%'}>
            <Button
              variant="text"
              onClick={handleOpenScoreChartClick}
              startIcon={<SsidChartIcon />}
            >
              Game Flow
            </Button>
            <Button
              variant="text"
              onClick={() => setOpenLegend(true)}
              startIcon={<ListIcon />}
            >
              Legend
            </Button>
          </Stack>
          <Box width={'40%'} sx={{ display: 'flex', justifyContent: 'right' }}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                {/* {mode === 'sit-n-go' && playersCount > 2 ? (
                  <FormControlLabel
                    value="showAllScores"
                    control={
                      <Tooltip
                        title="Click a single row to show scores for only that row"
                        placement="top"
                      >
                        <Switch
                          checked={showAllScores}
                          onChange={() => setShowAllScores((prev) => !prev)}
                        />
                      </Tooltip>
                    }
                    label={
                      <Typography variant="subtitle2">
                        Show all scores
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                ) : null} */}
                <FormControlLabel
                  value="runningTotals"
                  control={
                    <Switch
                      checked={runningTotals}
                      onChange={() => setRunningTotals((prev) => !prev)}
                    />
                  }
                  label={
                    <Typography variant="subtitle2">Running totals</Typography>
                  }
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Stack>
        {bannedCategories && bannedCategories.length > 0 ? (
          <Stack
            direction={'row'}
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'left',
              marginTop: 1,
              marginBottom: 1,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Bans:
            </Typography>
            {bannedCategories.map((c) => (
              <Typography
                variant="caption"
                className="angled-strikethrough straight"
                sx={{
                  color: '#b0917f',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {c}
              </Typography>
            ))}
          </Stack>
        ) : null}
        <Box sx={{ padding: 1 }}>
          <Paper elevation={3} sx={{ borderRadius: 2 }}>
            <TableContainer
              component={Paper}
              sx={{ bgcolor: backgroundColor || 'black' }}
            >
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {enableReportInaccuracy ? (
                      <TableCell width={'2%'} style={{ top: 50 }}></TableCell>
                    ) : null}
                    {showDislike || showRatings ? (
                      <TableCell width="2%"></TableCell>
                    ) : null}
                    {!starterKit ? <TableCell width="2%"></TableCell> : null}
                    {!forPublic ? <TableCell width="2%"></TableCell> : null}
                    <TableCell>
                      <Typography variant={'body1'} fontWeight={600}>
                        Question
                      </Typography>
                    </TableCell>
                    <TableCell width="20%">
                      <Typography
                        variant={'body1'}
                        fontWeight={600}
                        sx={{ color: theme.palette.answers.main }}
                      >
                        Answer
                      </Typography>
                    </TableCell>
                    <TableCell width="10%">
                      <Typography variant={'body1'} fontWeight={600}>
                        Category
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant={'body1'}
                        textAlign={'left'}
                        fontWeight={600}
                      >
                        PAR
                      </Typography>
                    </TableCell>
                    {(mode === 'solo' ||
                      (mode === 'sit-n-go' && playersCount === 2)) &&
                    playerSelf ? (
                      <PlayerTableCell>
                        <Tooltip title={playerSelf.username} placement="top">
                          <Typography
                            variant={'subtitle2'}
                            fontWeight={600}
                            textAlign={'center'}
                            width={'60px'}
                            sx={{
                              color: theme.palette.primary.main,
                              wordBreak: 'break-all',
                            }}
                          >
                            {hideUsernameLink ? (
                              playerSelf.username.length > 13 ? (
                                playerSelf.username.slice(0, 13) + '.'
                              ) : (
                                playerSelf.username
                              )
                            ) : (
                              <Link
                                sx={{ cursor: 'pointer' }}
                                onClick={(e) =>
                                  handleUsernameClick(e, playerSelf?.profileId)
                                }
                              >
                                {playerSelf.username.length > 13
                                  ? playerSelf.username.slice(0, 13) + '.'
                                  : playerSelf.username}
                              </Link>
                            )}
                          </Typography>
                        </Tooltip>
                      </PlayerTableCell>
                    ) : null}
                    {mode === 'sit-n-go' && playersCount === 2 && playerThem ? (
                      <PlayerTableCell>
                        <Tooltip title={playerThem.username} placement="top">
                          <Typography
                            variant={'subtitle2'}
                            fontWeight={600}
                            textAlign={'center'}
                            width={'60px'}
                            sx={{
                              color: theme.palette.primary.main,
                              wordBreak: 'break-all',
                            }}
                          >
                            {hideUsernameLink ? (
                              playerThem.username.length > 13 ? (
                                playerThem.username.slice(0, 13) + '.'
                              ) : (
                                playerThem.username
                              )
                            ) : (
                              <Link
                                sx={{ cursor: 'pointer' }}
                                onClick={(e) =>
                                  handleUsernameClick(e, playerThem?.profileId)
                                }
                              >
                                {playerThem.username.length > 13
                                  ? playerThem.username.slice(0, 13) + '.'
                                  : playerThem.username}
                              </Link>
                            )}
                          </Typography>
                        </Tooltip>
                      </PlayerTableCell>
                    ) : null}
                    {mode === 'teams' && myTeam ? (
                      <PlayerTableCell>
                        <Tooltip
                          title={getTeamMemberUsernames(myTeam)}
                          placement="top"
                        >
                          <Link
                            sx={{ cursor: 'pointer' }}
                            onClick={(e) =>
                              handleTeamnameClick(e, myTeam.leagueTeamId)
                            }
                          >
                            {myTeam.username}
                          </Link>
                        </Tooltip>
                      </PlayerTableCell>
                    ) : null}
                    {mode === 'teams' && opponentTeam ? (
                      <PlayerTableCell>
                        <Tooltip
                          title={getTeamMemberUsernames(opponentTeam)}
                          placement="top"
                        >
                          <Link
                            sx={{ cursor: 'pointer' }}
                            onClick={(e) =>
                              handleTeamnameClick(e, opponentTeam.leagueTeamId)
                            }
                          >
                            {opponentTeam.username}
                          </Link>
                        </Tooltip>
                      </PlayerTableCell>
                    ) : null}
                    {mode === 'sit-n-go' &&
                    playersCount > 2 &&
                    selectedPlayer ? (
                      <PlayerTableCell>
                        <Tooltip
                          title={selectedPlayer.username}
                          placement="top"
                        >
                          <Typography
                            variant={'subtitle2'}
                            fontWeight={600}
                            textAlign={'center'}
                            width={'60px'}
                            sx={{
                              color: theme.palette.primary.main,
                              wordBreak: 'break-all',
                            }}
                          >
                            {hideUsernameLink ? (
                              selectedPlayer.username.length > 13 ? (
                                selectedPlayer.username.slice(0, 13) + '.'
                              ) : (
                                selectedPlayer.username
                              )
                            ) : (
                              <Link
                                sx={{ cursor: 'pointer' }}
                                onClick={(e) =>
                                  handleUsernameClick(
                                    e,
                                    selectedPlayer?.profileId
                                  )
                                }
                              >
                                {selectedPlayer.username.length > 13
                                  ? selectedPlayer.username.slice(0, 13) + '.'
                                  : selectedPlayer.username}
                              </Link>
                            )}
                          </Typography>
                        </Tooltip>
                      </PlayerTableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questionSet.map((q, index) => (
                    <ScorecardTableRow
                      key={index}
                      arenaSet={arenaSet}
                      customQuestionComment={customQuestionComments?.find(
                        (cq) => cq.questionId === q._id
                      )}
                      detailedPlayers={detailedPlayers}
                      demons={demons}
                      demonsVanquished={demonsVanquished}
                      displayQuestionStatSeen={displayQuestionStatSeen}
                      enableReportInaccuracy={enableReportInaccuracy}
                      handicap={handicap}
                      handleDislikeQuestion={handleDislikeQuestion}
                      handleLikeQuestion={handleLikeQuestion}
                      handleImageClick={handleImageClick}
                      handleLastQuestionClick={handleLastQuestionClick}
                      handleMouseOverBarChart={handleMouseOverBarChart}
                      handleReportInaccuracyRowClick={
                        handleReportInaccuracyRowClick
                      }
                      handleRevealAnswerClick={handleRevealAnswerClick}
                      hideAnswers={forPublic}
                      hideStats={forPublic || !!starterKit}
                      index={index}
                      league={league}
                      leagueGameType={leagueGameType}
                      loadingQuestionStats={loadingQuestionStats}
                      mode={mode}
                      myTeam={myTeam}
                      opponentTeam={opponentTeam}
                      playerSelf={playerSelf}
                      playerThem={playerThem}
                      profileId={profileId}
                      question={q}
                      questionStats={questionStats?.find(
                        (qs) => qs.questionId === q._id
                      )}
                      questionDisliked={questionDisliked}
                      questionLiked={questionLiked}
                      reportedInaccuracies={reportedInaccuracies}
                      revealAnswer={revealAnswers[index]}
                      runningTotals={runningTotals}
                      seededBy={seededBy}
                      selectedPlayer={selectedPlayer}
                      showAllScores={showAllScores}
                      showDislike={showDislike}
                      showRatings={showRatings}
                      totalQuestions={questionSet.length}
                      variant={variant}
                    />
                  ))}

                  <TableRow>{tableCellsTotals}</TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Stack>

      {questionImgUrl ? (
        <ImageDisplay
          closeImageDisplay={() => setOpenQuestionImage(false)}
          imgSource={questionImgUrl}
          openImageDisplay={openQuestionImage}
        />
      ) : null}

      <GameflowChartDialog
        closeScoreChart={() => setOpenScoreChart(false)}
        openScoreChart={openScoreChart}
        mode={mode}
        myTeam={myTeam}
        opponentTeam={opponentTeam}
        players={detailedPlayers}
        questionSet={questionSet}
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: centerSnackbar ? 'center' : 'left',
        }}
        open={openToast}
        onClose={() => setOpenToast(false)}
        autoHideDuration={4000}
      >
        <SnackbarContent
          style={{
            backgroundColor: theme.palette.success.main,
          }}
          message={toastMessage}
        />
      </Snackbar>

      <ScorecardLegendDialog
        open={openLegend}
        close={() => setOpenLegend(false)}
        theme={theme}
      />
    </>
  );
};
