import React, { useState } from 'react';
import { Stack, Typography, Box, Button } from '@mui/material';
import { ScorecardTable } from './ScorecardTable';
import { DetailedPlayer, Profile } from '../../../../types/player';
import { Question } from '../../../../types/game';
import ScoreAvatar from './ScoreAvatar';
import { WeekendOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import useDailiesStreakAchievements from '../../hooks/useDailiesStreakAchievements';
import DailiesStreaksDialog from '../../score-screen/DailiesStreaksDialog';

type PropTypes = {
  dailySet?: number;
  missedDaily?: boolean;
  players: Record<string, DetailedPlayer>;
  profileId: string;
  profile?: Profile;
  questions: Question[];
};
export const Scorecard = ({
  dailySet,
  missedDaily,
  players,
  profileId,
  profile,
  questions,
}: PropTypes) => {
  const navigate = useNavigate();

  const [openDailiesStreaksDialog, setOpenDailiesStreaksDialog] = useState(
    !!dailySet
  );

  const { dailiesStreakAchievements } = useDailiesStreakAchievements(
    profile,
    dailySet,
    players[profileId],
    missedDaily
  );

  const handleBackToLobbyClick = () => {
    navigate('/dailies');
  };

  return (
    <>
      <Stack position={'relative'} display={'flex'} justifyContent={'center'}>
        <Box sx={{ position: 'absolute', top: 10, left: 5 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleBackToLobbyClick()}
          >
            Back
          </Button>
        </Box>

        <Stack alignItems={'center'} justifyContent={'center'} mb={2}>
          <Typography
            variant="h6"
            my={1}
            textAlign={'center'}
            sx={{
              textShadow:
                'rgb(255, 186, 24) 0px 0px 0px, rgb(255, 186, 24) 0px 0px 0px, rgb(255, 186, 24) 0px 0px 16px',
            }}
          >
            Your score
          </Typography>
          <ScoreAvatar score={players[profileId].parScore} />
        </Stack>

        <Box width={'100%'}>
          <ScorecardTable
            players={players}
            profile={profile}
            profileId={profile?._id || profileId}
            questions={questions}
          />
        </Box>
      </Stack>

      {openDailiesStreaksDialog && dailiesStreakAchievements && (
        <DailiesStreaksDialog
          open={openDailiesStreaksDialog}
          onClose={() => setOpenDailiesStreaksDialog(false)}
          dailiesStreakAchievements={dailiesStreakAchievements}
        />
      )}
    </>
  );
};
