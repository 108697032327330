import React, { RefObject } from 'react';
import { Edit } from '@mui/icons-material';
import { TextField, IconButton } from '@mui/material';
import { AuthType } from './types';

type PropTypes = {
  disabled?: boolean;
  email: string;
  emailErrorMessage?: string;
  inputRef: RefObject<HTMLInputElement>;
  setEmail: (email: string) => void;
};
export const InputEmail = ({
  disabled,
  email,
  emailErrorMessage,
  inputRef,
  setEmail,
}: PropTypes) => {
  return (
    <TextField
      type="email"
      placeholder="Email"
      fullWidth
      variant="outlined"
      name="username"
      helperText={emailErrorMessage}
      error={!!emailErrorMessage}
      autoFocus
      disabled={disabled}
      inputRef={inputRef}
      value={email}
      onChange={(e) => setEmail(e.target.value)}
    />
  );
};
