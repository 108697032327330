import { PunchcardCategory } from './types';

export const LABEL_COLORS = {
  average: '#43B5C4',
  best: '#00C800',
  gamesPlayed: '#E43CE4',
};

export const MEDAL_COLORS: { [key: string]: string } = {
  bronze: '#CD7f32',
  silver: '#B4B4B4',
  gold: '#FFCA09',
  platinum: '#E5E4E2',
  diamond: '#54c7f0',
  unobtainium: '#ff0000',
  antimatter: '#cbcbcb',
};

export type MedalRequirements = {
  bronze: number;
  silver: number;
  gold: number;
  platinum: number;
  diamond: number;
  unobtainium: number;
  antimatter: number;
};

export const MEDAL_REQUIREMENTS: Record<PunchcardCategory, MedalRequirements> =
  {
    average: {
      bronze: 40,
      silver: 30,
      gold: 20,
      platinum: 0,
      diamond: -20,
      unobtainium: -40,
      antimatter: -50,
    },
    bestAverage: {
      bronze: 40,
      silver: 30,
      gold: 20,
      platinum: 0,
      diamond: -20,
      unobtainium: -40,
      antimatter: -50,
    },
    best: {
      bronze: 20,
      silver: 10,
      gold: 0,
      platinum: -20,
      diamond: -40,
      unobtainium: -60,
      antimatter: -70,
    },
    gamesPlayed: {
      bronze: 25,
      silver: 50,
      gold: 100,
      platinum: 150,
      diamond: 250,
      unobtainium: 500,
      antimatter: 1000,
    },
  };
