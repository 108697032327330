import { useEffect } from 'react';
import { Methods } from './auth/constants';
import { useApi } from './useApi';
import { useProfileContext } from '../contexts/ProfileProvider';
import { Profile } from '../types/player';

type Response = {
  message: string;
  profile: Profile;
};

export const useUpdateProfileProperty = () => {
  const { refetch } = useProfileContext();
  const { data, error, loading, sendRequest } = useApi<Response>();

  useEffect(() => {
    if (data && !error) {
      refetch();
    }
  }, [data, error, refetch]);

  const updateProfile = (
    profileId: string,
    property: keyof Profile,
    value: any
  ) => {
    sendRequest({
      url: '/profile/update',
      method: Methods.POST,
      body: { profileId, property, value },
    });
  };

  return { data, error, loading, updateProfile };
};
