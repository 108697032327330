import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  Stack,
  Button,
  Tooltip,
  Link,
  useTheme,
} from '@mui/material';
import React from 'react';
import {
  MODE_DISPLAY_NAME,
  MODE_ABBR,
  MAX_PLAYERS_IN_TEAMS,
  CATEGORY_SETS_LABEL,
  ABBREVIATED_CATEGORIES,
} from '../../constants/constants';
import { Game, LobbyGame } from '../../types/game';
import { useNavigate } from 'react-router-dom';
import { Player, Profile } from '../../types/player';
import { isMobile } from 'react-device-detect';
import { fetchGet } from '../../api/common';
import { utcToLocalDateTime } from '../../utils/date-time';
import { useLoggedInUserContext } from '../../contexts/LoggedInUserProvider';
import { useGameContext } from '../../contexts/GameProvider';
import { useGameManager } from '../../hooks/useGameManager';
import useLobbyGameManager from './hooks/useLobbyGameManager';
import { useScorecardDialog } from '../../contexts/ScorecardDialogProvider';
import { gameInProgress } from '../../utils/loggedInUser';
import useQuickPlayManager from './hooks/useQuickPlayManager';
import { assignRanks } from '../../utils/game';
import { round } from 'lodash';

type Props = {
  lobbyGamesPaged: LobbyGame[];
  profile: Profile;
};

const LobbyTable = ({ lobbyGamesPaged, profile }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { loggedInUser } = useLoggedInUserContext();
  const {
    handleJoinQuickPlayGame,
    handleStartSitAndGoClick,
    handleSpectateClick,
    handleLeaveGame,
    handleRemoveGameClick,
  } = useQuickPlayManager();
  const { viewScorecardDialog } = useScorecardDialog();

  const isJoined = (game: LobbyGame) => {
    if (!game.activePlayers) return false;
    return (
      Object.keys(game.activePlayers).includes(profile._id) && !game.gameOver
    );
  };

  const handleViewResultsClick = async (game: LobbyGame) => {
    viewScorecardDialog(game.gameId);
  };

  const results = (game: LobbyGame) => (
    <Stack>
      <Typography variant="body2">
        {utcToLocalDateTime(game.createdAt)}
      </Typography>
      <Link
        sx={{ cursor: 'pointer' }}
        onClick={() => handleViewResultsClick(game)}
      >
        View Results
      </Link>
    </Stack>
  );

  const stackName = (r: LobbyGame) => (
    <Tooltip placement="top" title={`Game ID: ${r.gameId}`}>
      <Stack>
        <Stack direction="row" spacing={1}>
          {r.isRanked ? (
            <Typography
              variant="body2"
              fontWeight={600}
              sx={{
                color: theme.palette.ranked.main,
              }}
            >
              Ranked
            </Typography>
          ) : null}
          {/* {r.ghostChallenge ? (
            <Tooltip title="This game was a Ghost Challenge" placement="bottom">
              <BiGhost
                style={{
                  fontSize: '18px',
                  color: theme.palette.warning.main,
                }}
              />
            </Tooltip>
          ) : null}
          {r.matchMaking ? (
            <Tooltip title="This game was Searched" placement="bottom">
              <SearchIcon
                style={{
                  fontSize: '22px',
                  color: theme.palette.dailies.main,
                }}
              />
            </Tooltip>
          ) : null}
          {r.handicap ? (
            <Tooltip
              title="A handicap was set for this game"
              placement="bottom"
            >
              <Box
                sx={{
                  marginLeft: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <BalanceIcon
                  style={{
                    fontSize: '20px',
                    color: theme.palette.handicap.main,
                  }}
                />
              </Box>
            </Tooltip>
          ) : null}
          {r.variant === 'survival' && r.survivalMode === 'endurance' ? (
            <Tooltip title="Survival - Endurance" placement="bottom">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <GiRaiseZombie
                  style={{
                    fontSize: '20px',
                    color: theme.palette.survival.main,
                  }}
                />
              </Box>
            </Tooltip>
          ) : null}
          {r.variant === 'survival' && r.survivalMode === 'challenge' ? (
            <Tooltip title="Survival - Challenge Tower" placement="bottom">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <GiStoneTower
                  style={{
                    fontSize: '16px',
                    color: theme.palette.survival.main,
                  }}
                />
              </Box>
            </Tooltip>
          ) : null}
          {r.demons ? (
            <Tooltip title="Seeded this match" placement="top">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <GiDevilMask
                  style={{
                    fontSize: '18px',
                    color: theme.palette.demon.main,
                  }}
                />
              </Box>
            </Tooltip>
          ) : null} */}
        </Stack>
        {r.customGameName || 'N/A'}
      </Stack>
    </Tooltip>
  );

  const category = (r: LobbyGame) =>
    r.categorySet === 'none' && r.categorySelections
      ? r.categorySelections
          .map((cs) => ABBREVIATED_CATEGORIES[cs] || cs)
          .join(', ')
      : CATEGORY_SETS_LABEL[r.categorySet];

  const handleUsernameClick = async (e: any, player: Player) => {
    e.stopPropagation();
    navigate(`/profile/overview/${player.profileId}`);
  };

  const getJoinedPlayers = (game: LobbyGame) => {
    if (!game.players || Object.keys(game.players).length === 0) return '--';
    const rankedPlayers = assignRanks(
      Object.values(game.players).sort((a, b) => a.parScore - b.parScore)
    );
    return (
      <Stack width={'100%'}>
        {rankedPlayers.slice(0, 20).map((p, i) => (
          <Link
            key={i}
            component="button"
            variant="body2"
            onClick={(e) => handleUsernameClick(e, p)}
            textAlign={'left'}
          >
            <Stack direction={'row'} sx={{ whiteSpace: 'nowrap' }}>
              {game.mode === 'sit-n-go'
                ? `${game.gameOver ? p.rank : i + 1}.`
                : ''}{' '}
              {game.mode === 'teams'
                ? `${
                    game.teams.teamA.members.includes(p.profileId)
                      ? 'A.'
                      : game.teams.teamB.members.includes(p.profileId)
                      ? 'B.'
                      : ''
                  }`
                : ''}{' '}
              {p.username}
              {(game.mode === 'match' || game.mode === 'sit-n-go') &&
              game.isRanked ? (
                <Stack direction={'row'}>
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    sx={{ color: theme.palette.ranked.main }}
                  >
                    &nbsp;(
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    sx={{ color: ratingColor(game.gameOver, p) }}
                  >
                    {getRatingNextToUsername(game.gameOver, p)}
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    sx={{ color: theme.palette.ranked.main }}
                  >
                    )
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
          </Link>
        ))}
      </Stack>
    );
  };

  const getRatingNextToUsername = (gameOver: boolean, player: Player) => {
    const formatRating = (rating: number) => {
      const rounded = round(rating, 0);
      return `${rounded > 0 ? '+' : ''}${rounded}`;
    };

    if (gameOver) {
      if (player.trueSkillGameRecords?.sitAndGoGamesPlayed < 10) {
        return 'calibrating';
      }
      return formatRating(player.trueSkill?.mmrDiffFromPrev || 0);
    }
    return (player.trueSkillGameRecords?.sitAndGoGamesPlayed || 0) < 10
      ? 'calibrating'
      : round(player.trueSkill?.mmr, 0);
  };

  const ratingColor = (gameOver: boolean, player: Player) => {
    if ((player.trueSkillGameRecords?.sitAndGoGamesPlayed || 0) < 10)
      return theme.palette.secondary.main;

    if (gameOver) {
      if (Math.round(player.trueSkill?.mmrDiffFromPrev || 0) > 0)
        return theme.palette.success.main;
      if (Math.round(player.trueSkill?.mmrDiffFromPrev || 0) < 0)
        return theme.palette.error.main;
      return theme.palette.warning.main;
    }

    return theme.palette.ranked.main;
  };

  const getStatus = (r: LobbyGame) => {
    if (
      r.mode === 'sit-n-go' &&
      !r.gameStarted &&
      r.activePlayers &&
      !r.confirmingPlayers
    ) {
      return `${r.activePlayers.length} / ${r.sitAndGoMaxPlayers}`;
    }
    if (
      r.mode === 'teams' &&
      !r.gameStarted &&
      r.activePlayers &&
      !r.confirmingPlayers
    ) {
      return `${r.activePlayers.length} / ${MAX_PLAYERS_IN_TEAMS}`;
    }
    if (!r.gameStarted && r.confirmingPlayers) {
      return 'Starting';
    }
    if (r.gameStarted && !r.gameOver) {
      return 'In progress';
    }
    return r.status;
  };

  const actionButton = (r: LobbyGame) => {
    if (r.gameOver) return null;
    if (
      !isMobile &&
      gameInProgress(loggedInUser) &&
      r.activePlayers.includes(profile._id) &&
      r.gameStarted
    ) {
      return (
        <Button
          variant="contained"
          color="warning"
          // onClick={() => handleRejoinGameClick(r.gameId)}
        >
          REJOIN
        </Button>
      );
    }
    if (
      !isMobile &&
      r.mode === 'sit-n-go' &&
      !r.gameStarted &&
      !r.activePlayers.includes(profile._id) &&
      !r.confirmingPlayers &&
      r.activePlayers.length < r.sitAndGoMaxPlayers
    ) {
      return (
        <Stack direction={'row'} spacing={1}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: 80 }}
            onClick={() => handleJoinQuickPlayGame(profile?._id, r.gameId)}
          >
            Join
          </Button>
          {(r.activePlayers.length === 0 && r.createdBy === profile._id) ||
          profile.admin ? (
            <Button
              sx={{ width: 80 }}
              variant="contained"
              color="error"
              onClick={() => handleRemoveGameClick(profile?._id, r.gameId)}
            >
              Remove
            </Button>
          ) : null}
        </Stack>
      );
    }
    if (
      !isMobile &&
      r.mode === 'teams' &&
      !r.gameStarted &&
      !r.confirmingPlayers &&
      r.activePlayers.length < MAX_PLAYERS_IN_TEAMS
    ) {
      if (!r.activePlayers.includes(profile._id)) {
        return (
          <Stack direction={'row'} spacing={1}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: 80 }}
              onClick={() => handleJoinQuickPlayGame(profile?._id, r.gameId)}
              // disabled={
              //   r.activePlayers.length >= MAX_PLAYERS_IN_TEAMS ||
              //   disabledTeamJoinButton
              // }
            >
              Join
            </Button>
            {r.activePlayers.length === 0 && r.createdBy === profile._id ? (
              <Button
                sx={{ width: 80 }}
                variant="contained"
                color="error"
                onClick={() => handleRemoveGameClick(profile?._id, r.gameId)}
              >
                Remove
              </Button>
            ) : null}
          </Stack>
        );
      }
    }
    if (
      !isMobile &&
      (r.mode === 'sit-n-go' || r.mode === 'teams') &&
      r.activePlayers.includes(profile._id) &&
      !r.gameStarted
    ) {
      return (
        <Stack direction={'row'} spacing={1}>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleLeaveGame(profile._id, r.gameId)}
          >
            Leave
          </Button>
          {(r.mode === 'sit-n-go' || r.mode === 'teams') &&
          r.activePlayers.length > 1 &&
          r.createdBy === profile._id ? (
            <Button
              variant="contained"
              color="success"
              onClick={() => handleStartSitAndGoClick(profile._id, r.gameId)}
            >
              Start
            </Button>
          ) : null}
        </Stack>
      );
    }
    if (
      (r.mode === 'solo' || r.mode === 'sit-n-go' || r.mode === 'teams') &&
      r.gameStarted &&
      !r.gameOver &&
      !r.activePlayers.find((profileId: string) => profileId === profile._id)
    ) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSpectateClick(profile._id, r.gameId)}
        >
          Spectate
        </Button>
      );
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: 2,
        bgcolor: 'black',
      }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              bgcolor: '#425779',
            }}
          >
            <TableCell width={'15%'} style={{ fontWeight: 600 }}>
              Name
            </TableCell>
            <TableCell width={'5%'} style={{ fontWeight: 600 }}>
              Mode
            </TableCell>
            <TableCell width={'10%'} style={{ fontWeight: 600 }}>
              Category
            </TableCell>
            <TableCell width={'20%'} style={{ fontWeight: 600 }}>
              Players
            </TableCell>
            <TableCell width={'10%'} style={{ fontWeight: 600 }}>
              Status
            </TableCell>
            <TableCell width={'15%'} style={{ fontWeight: 600 }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lobbyGamesPaged?.length ? (
            lobbyGamesPaged.map((r: LobbyGame) => (
              <Box key={r.gameId} width="100%" display="contents">
                {isJoined(r) ? (
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 12,
                      position: 'absolute',
                      backgroundColor: 'secondary.main',
                      color: 'secondary.contrastText',
                    }}
                  >
                    Joined
                  </Typography>
                ) : null}
                <TableRow
                  sx={{
                    border: isJoined(r) ? '2px solid' : 'none',
                    borderColor: isJoined(r) ? 'secondary.main' : 'none',
                  }}
                >
                  <TableCell>{stackName(r)}</TableCell>
                  <TableCell>{MODE_ABBR[r.mode]}</TableCell>
                  <TableCell>{category(r)}</TableCell>
                  <TableCell>{getJoinedPlayers(r)}</TableCell>
                  <TableCell>{getStatus(r)}</TableCell>
                  <TableCell>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      {r.status === 'Finished' ? results(r) : actionButton(r)}
                    </Stack>
                  </TableCell>
                </TableRow>
              </Box>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                No games to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LobbyTable;
