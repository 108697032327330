import { useQuery } from '@tanstack/react-query';
import { fetchDailiesOverview } from '../../../api/apiServices';

const useFetchDailesOverview = (profileId: string, dailySet?: number) => {
  const { data, refetch } = useQuery({
    queryKey: ['dailiesOverview', { profileId, dailySet }],
    queryFn: fetchDailiesOverview,
  });

  return {
    data,
    refetch,
  };
};

export default useFetchDailesOverview;
