import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import { MdFiberNew } from 'react-icons/md';

type DailiesStreakAchievements = {
  missedLastDaily: boolean;
  playedCurrent: number;
  playedAllTime: number;
  isNoTimeouts: boolean;
  noTimeoutsCurrent: number;
  noTimeoutsAllTime: number;
  isScratch: boolean;
  scratchCurrent: number;
  scratchAllTime: number;
  isParBirdieEagle: boolean;
  parBirdieEagleCurrent: number;
  parBirdieEagleAllTime: number;
};

type Props = {
  dailiesStreakAchievements: DailiesStreakAchievements;
  open: boolean;
  onClose: () => void;
};

const DailiesStreaksDialog = ({
  dailiesStreakAchievements,
  open,
  onClose,
}: Props) => {
  const theme = useTheme();

  const {
    missedLastDaily,
    playedCurrent,
    playedAllTime,
    isNoTimeouts,
    noTimeoutsCurrent,
    noTimeoutsAllTime,
    isScratch,
    scratchCurrent,
    scratchAllTime,
    isParBirdieEagle,
    parBirdieEagleCurrent,
    parBirdieEagleAllTime,
  } = dailiesStreakAchievements;

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle textAlign={'center'}>YOUR DAILIES STREAKS</DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          {missedLastDaily ? (
            <Stack spacing={1} justifyContent={'center'} alignItems={'center'}>
              <Typography
                textAlign={'center'}
                display="flex"
                alignItems={'center'}
              >
                Aww{' '}
                <SentimentDissatisfiedOutlinedIcon
                  color="secondary"
                  sx={{ ml: 1 }}
                />
              </Typography>
              <Typography textAlign={'center'}>
                You missed yesterday's daily
              </Typography>
              <Typography textAlign={'center'}>
                Your streaks have been reset
              </Typography>
            </Stack>
          ) : (
            <Typography textAlign={'center'} color="primary" fontWeight={600}>
              Nice! Keep up the good work!
            </Typography>
          )}

          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Streak</TableCell>
                  <TableCell align="center">Current</TableCell>
                  <TableCell align="center">All Time</TableCell>
                  <TableCell width={'1%'}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <CheckOutlinedIcon color="success" />
                  </TableCell>
                  <TableCell>Played</TableCell>
                  <TableCell align="center">{playedCurrent}</TableCell>
                  <TableCell align="center">{playedAllTime}</TableCell>
                  <TableCell>
                    {playedCurrent === playedAllTime && playedCurrent > 0 && (
                      <Tooltip title="New all-time record!" placement="right">
                        <Box>
                          <MdFiberNew
                            style={{
                              color: theme.palette.secondary.main,
                              fontSize: 30,
                            }}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {isNoTimeouts ? (
                      <CheckOutlinedIcon color="success" />
                    ) : (
                      <ClearOutlinedIcon color="error" />
                    )}
                  </TableCell>
                  <TableCell>No Timeouts</TableCell>
                  <TableCell align="center">{noTimeoutsCurrent}</TableCell>
                  <TableCell align="center">{noTimeoutsAllTime}</TableCell>
                  <TableCell>
                    {noTimeoutsCurrent === noTimeoutsAllTime &&
                      noTimeoutsCurrent > 0 && (
                        <Tooltip title="New all-time record!" placement="right">
                          <Box>
                            <MdFiberNew
                              style={{
                                color: theme.palette.secondary.main,
                                fontSize: 30,
                              }}
                            />
                          </Box>
                        </Tooltip>
                      )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {isScratch ? (
                      <CheckOutlinedIcon color="success" />
                    ) : (
                      <ClearOutlinedIcon color="error" />
                    )}
                  </TableCell>
                  <TableCell>Scratches</TableCell>
                  <TableCell align="center">{scratchCurrent}</TableCell>
                  <TableCell align="center">{scratchAllTime}</TableCell>
                  <TableCell>
                    {scratchCurrent === scratchAllTime &&
                      scratchCurrent > 0 && (
                        <Tooltip title="New all-time record!" placement="right">
                          <Box>
                            <MdFiberNew
                              style={{
                                color: theme.palette.secondary.main,
                                fontSize: 30,
                              }}
                            />
                          </Box>
                        </Tooltip>
                      )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {isParBirdieEagle ? (
                      <CheckOutlinedIcon color="success" />
                    ) : (
                      <ClearOutlinedIcon color="error" />
                    )}
                  </TableCell>
                  <TableCell>0-1-2</TableCell>
                  <TableCell align="center">{parBirdieEagleCurrent}</TableCell>
                  <TableCell align="center">{parBirdieEagleAllTime}</TableCell>
                  <TableCell>
                    {parBirdieEagleCurrent === parBirdieEagleAllTime &&
                      parBirdieEagleCurrent > 0 && (
                        <Tooltip title="New all-time record!" placement="right">
                          <Box>
                            <MdFiberNew
                              style={{
                                color: theme.palette.secondary.main,
                                fontSize: 30,
                              }}
                            />
                          </Box>
                        </Tooltip>
                      )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DailiesStreaksDialog;
