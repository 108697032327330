import {
  Box,
  Grow,
  Typography,
  Fade,
  CircularProgress,
  Stack,
  Chip,
  useTheme,
} from '@mui/material';
import React from 'react';
import { GameClock } from './GameClock';
import CustomQuestionAuthorDisplay from './CustomQuestionAuthorDisplay';
import GameSpinnerAnimation from './GameSpinnerAnimation';
import ParAndCategoryDisplay from './ParAndCategoryDisplay';

type Props = {
  category: string;
  displayCategoryAndPar: boolean;
  displayGameClock: boolean;
  freezeGameClock: boolean;
  gameHeaderLabel: string;
  gameClock: number;
  goToNextQuestionEarly: boolean;
  leagueMatch: number;
  mode: string;
  par: number;
  questionIndex: number;
  spectate: boolean;
  submittedByUsername: string;
  timerBeforeQuestionReveal: number;
  totalNumQuestions: number;
};

const CenterGameHeader = ({
  category,
  displayCategoryAndPar,
  displayGameClock,
  freezeGameClock,
  gameHeaderLabel,
  gameClock,
  goToNextQuestionEarly,
  par,
  questionIndex,
  spectate,
  submittedByUsername,
  timerBeforeQuestionReveal,
  totalNumQuestions,
}: Props) => {
  const displayCustomQuestionAuthor =
    submittedByUsername && (gameClock >= 20 || goToNextQuestionEarly);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      minWidth={'300px'}
      position="relative"
    >
      {displayCustomQuestionAuthor ? (
        <CustomQuestionAuthorDisplay
          displayCategoryAndPar={displayCategoryAndPar}
          submittedByUsername={submittedByUsername}
        />
      ) : null}

      {/* GAME HEADER LABEL */}
      <Typography
        variant="overline"
        sx={{
          textAlign: 'center',
          width: '100%',
        }}
      >
        {gameHeaderLabel}
      </Typography>

      {/* QUESTION COUNT */}
      <Typography variant="caption" textAlign="center" width="100%">
        {questionIndex + 1} of {totalNumQuestions}
      </Typography>

      <GameClock
        displayGameClock={displayGameClock}
        freezeGameClock={freezeGameClock}
        par={par}
        gameClock={gameClock}
        spectate={spectate}
      />

      <GameSpinnerAnimation
        displayGameClock={displayGameClock}
        timerBeforeQuestionReveal={timerBeforeQuestionReveal}
      />

      <ParAndCategoryDisplay
        category={category}
        par={par}
        displayCategoryAndPar={displayCategoryAndPar}
      />
    </Stack>
  );
};

export default CenterGameHeader;
