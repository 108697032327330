import React, {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useApi } from '../hooks/useApi';
import { GameDb, Question } from '../types/game';
import { ArenaTeam } from '../pages/arena-team/types';

interface ScorecardDialogContextType {
  arenaTeams: ArenaTeam[];
  close: () => void;
  gameData: GameDb;
  open: any;
  questionData: Question[];
  viewScorecardDialog: (gameId: string) => void;
}

export const ScorecardDialogContext = createContext<ScorecardDialogContextType>(
  {
    arenaTeams: [],
    close: () => {},
    gameData: null,
    open: false,
    questionData: null,
    viewScorecardDialog: () => {},
  }
);

type ScorecardDialogProviderProps = {
  children: ReactNode;
};

export const useScorecardDialog = () => useContext(ScorecardDialogContext);

export const ScorecardDialogProvider: FC<ScorecardDialogProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [gameData, setGameData] = useState<GameDb>();
  const [questionData, setQuestionData] = useState<Question[]>();
  const [arenaTeams, setArenaTeams] = useState<ArenaTeam[]>();
  const { data, error, sendRequest } = useApi<{
    game: GameDb;
    questionSet: Question[];
    arenaTeams?: ArenaTeam[];
  }>();

  useEffect(() => {
    if (data && !error) {
      setGameData(data.game);
      setQuestionData(data.questionSet);
      setArenaTeams(data.arenaTeams);
      setOpen(true);
    }
  }, [data, error]);

  const viewScorecardDialog = useCallback(
    (gameId: string) => {
      sendRequest({ url: `/games/result/${gameId}` });
    },
    [sendRequest]
  );

  const close = () => setOpen(false);

  return (
    <ScorecardDialogContext.Provider
      value={{
        arenaTeams,
        close,
        gameData,
        open,
        questionData,
        viewScorecardDialog,
      }}
    >
      {children}
    </ScorecardDialogContext.Provider>
  );
};
