export const isRemainderZero = (x: number, y: number) =>
  Math.round(x * 100) % Math.round(y * 100) === 0;

export const roundToOneDecimal = (value: number) => Math.round(value * 10) / 10;

export const roundToTwoDecimals = (value: number) =>
  Math.round(value * 100) / 100;

export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const sum = (array: number[]) => array.reduce((a, b) => a + b, 0);

export const average = (array: number[]) =>
  array.reduce((a, b) => a + b) / array.length;

export const movingAverage = (
  oldAverage: number,
  newValue: number,
  newTotal: number
) => oldAverage * ((newTotal - 1) / newTotal) + newValue / newTotal;

export const median = (array: number[]) => {
  const sorted = Array.from(array).sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
};
