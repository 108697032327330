import {
  Box,
  Button,
  Link,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { fetchGet, fetchPost } from '../../api/common';

const headerMap = {
  ID: 'id',
  Question: 'question',
  'Display Answer': 'answer',
  'Word Count': 'wordCount',
  Par: 'par',
  Category: 'category',
  'Answer Length Count': 'answerLength',
  Difficulty: 'difficulty',
  'Answer Slack': 'answerSlack',
  'Tag Proper Name?': 'properName',
  'Alternate Answers': 'alternateAnswers',
  'Prompt Answer': 'promptAnswer',
  Worth: 'worth',
  Tournament: 'tournament',
  'Tournament Set': 'tournamentSet',
  Daily: 'daily',
  'Daily Set': 'dailySet',
  'Daily Date': 'dailyDate',
  'Arena Set': 'arenaSet',
  'Sort Order': 'sortOrder',
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const QuestionsSetup = () => {
  const [file, setFile] = useState<File>();
  const [csvData, setCsvData] = useState<Record<string, string>[]>();
  const [upsertedIds, setUpsertedIds] = useState<string[]>();
  const [modifiedIds, setModifiedIds] = useState<string[]>();
  const { enqueueSnackbar } = useSnackbar();

  const fileReader = new FileReader();

  const handleImportCsvFile = (e: any) => {
    const file = e.target.files[0];
    setFile(file);
    importCsv(file);
  };

  const importCsv = (file: File) => {
    fileReader.onload = async (event: any) => {
      const csvString = event.target.result;
      const res = await fetchPost('/admin/csv-to-json', { csvString });
      setCsvData(res.data);
    };

    fileReader.readAsText(file);
  };

  const downloadCsv = (csvString: string) => {
    const link = document.createElement('a');
    const BOM = '\uFEFF';
    const file = new Blob([BOM + csvString], {
      type: 'text/csv;charset=UTF-8',
    });
    link.href = URL.createObjectURL(file);
    link.download = `par-questions.csv`;
    link.click();
  };

  const handleUploadQuestions = async () => {
    try {
      const res = await fetchPost('/admin/upload-questions', { csvData });
      enqueueSnackbar(
        `Upload successful: inserted ${
          res.results.nInserted + res.results.nUpserted
        } documents and modified ${res.results.nModified} documents`,
        { variant: 'success' }
      );
      if (res.results.upserted.length)
        setUpsertedIds(res.results.upserted.map((r: any) => r._id));
      const toBeModified = csvData?.filter((r) => r.id).map((r) => r.id);
      if (toBeModified && toBeModified.length === res.results.nModified)
        setModifiedIds(toBeModified);
      setFile(undefined);
    } catch (e) {
      enqueueSnackbar('Upload failed', { variant: 'error' });
    }
  };

  const handleDownloadModifiedQuestions = async () => {
    const res = await fetchPost('/admin/download-questions', {
      questionIds: modifiedIds,
    });
    downloadCsv(res.data);
  };

  const handleDownloadInsertedQuestions = async () => {
    const res = await fetchPost('/admin/download-questions', {
      questionIds: upsertedIds,
    });
    downloadCsv(res.data);
  };

  const handleDownloadAllQuestions = async () => {
    const res = await fetchGet('/admin/download-all-questions');
    downloadCsv(res.data);
  };

  return (
    <>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'center'}
        marginBottom={2}
        mt={'50px'}
      >
        <Button variant="contained" component="label">
          Import CSV File
          <input
            type={'file'}
            id={'csvFileInput'}
            accept={'.csv'}
            onChange={handleImportCsvFile}
            hidden
          />
        </Button>
        <Button
          variant="contained"
          component="label"
          onClick={handleUploadQuestions}
          disabled={!csvData || !file}
        >
          Upload Questions
        </Button>
      </Stack>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'center'}
        marginBottom={2}
      >
        <Button
          variant="outlined"
          component="label"
          onClick={handleDownloadModifiedQuestions}
          disabled={!modifiedIds?.length}
        >
          Download Modified Questions
        </Button>
        <Button
          variant="outlined"
          component="label"
          onClick={handleDownloadInsertedQuestions}
          disabled={!upsertedIds?.length}
        >
          Download Inserted Questions
        </Button>
        <Button
          variant="outlined"
          component="label"
          onClick={handleDownloadAllQuestions}
        >
          Download All Questions
        </Button>
      </Stack>
      <Typography variant="h6" textAlign={'center'} marginBottom={2}>
        Filename: {file?.name}
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small" sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell width={'3%'}>ID</TableCell>
              <TableCell width={'30%'}>Question</TableCell>
              <TableCell width={'8%'}>Display Answer</TableCell>
              <TableCell width={'5%'}>Word Count</TableCell>
              <TableCell width={'4%'}>Par</TableCell>
              <LightTooltip title="Category" placement="top">
                <StyledTableCell width={'7%'}>Category</StyledTableCell>
              </LightTooltip>
              <TableCell width={'5%'}>Answer Length Count</TableCell>
              <LightTooltip title="Difficulty" placement="top">
                <StyledTableCell width={'5%'}>Difficulty</StyledTableCell>
              </LightTooltip>
              <TableCell width={'5%'}>Answer Slack</TableCell>
              <TableCell width={'5%'}>Tag Proper Name?</TableCell>
              <TableCell width={'8%'}>Alternate Answers</TableCell>
              <TableCell width={'5%'}>Prompt Answer</TableCell>
              <LightTooltip title="Tournament" placement="top">
                <StyledTableCell width={'5%'}>Tournament</StyledTableCell>
              </LightTooltip>
              <LightTooltip title="Tournament Set" placement="top">
                <StyledTableCell width={'5%'}>Tournament Set</StyledTableCell>
              </LightTooltip>
              <LightTooltip title="Daily" placement="top">
                <TableCell width={'5%'}>Daily</TableCell>
              </LightTooltip>
              <LightTooltip title="Daily Set" placement="top">
                <TableCell width={'5%'}>Daily Set</TableCell>
              </LightTooltip>
              <LightTooltip title="Mobile Daily" placement="top">
                <TableCell width={'5%'}>Mob Daily</TableCell>
              </LightTooltip>
              <LightTooltip title="Mobile Daily Set" placement="top">
                <TableCell width={'5%'}>Mob Daily Set</TableCell>
              </LightTooltip>
              <LightTooltip title="Mobile Daily Topic" placement="top">
                <TableCell width={'5%'}>Mob Daily Topic</TableCell>
              </LightTooltip>
              <LightTooltip title="Arena Set" placement="top">
                <TableCell width={'5%'}>Arena Set</TableCell>
              </LightTooltip>
              <LightTooltip title="Sort Order" placement="top">
                <TableCell width={'5%'}>Sort Order</TableCell>
              </LightTooltip>
            </TableRow>
          </TableHead>
          <TableBody>
            {csvData ? (
              csvData.map((row) => (
                <TableRow key={row.question} hover>
                  <LightTooltip title={row.id || 'DNE'} placement="top">
                    <StyledTableCell>{row.id}</StyledTableCell>
                  </LightTooltip>
                  <LightTooltip title={row.question} placement="top">
                    <StyledTableCell>{row.question}</StyledTableCell>
                  </LightTooltip>
                  <LightTooltip title={row.answer} placement="top-start">
                    <StyledTableCell>{row.answer}</StyledTableCell>
                  </LightTooltip>
                  <StyledTableCell>{row.wordCount}</StyledTableCell>
                  <StyledTableCell>{row.par}</StyledTableCell>
                  <LightTooltip title={row.category} placement="top">
                    <StyledTableCell>{row.category}</StyledTableCell>
                  </LightTooltip>
                  <StyledTableCell>{row.answerLength}</StyledTableCell>
                  <StyledTableCell>{row.difficulty}</StyledTableCell>
                  <StyledTableCell>{row.answerSlack}</StyledTableCell>
                  <StyledTableCell>{row.properName}</StyledTableCell>
                  <LightTooltip title={row.alternateAnswers} placement="top">
                    <StyledTableCell>{row.alternateAnswers}</StyledTableCell>
                  </LightTooltip>
                  <LightTooltip title={row.promptAnswer} placement="top">
                    <StyledTableCell>{row.promptAnswer}</StyledTableCell>
                  </LightTooltip>
                  <StyledTableCell>{row.tournament}</StyledTableCell>
                  <StyledTableCell>{row.tournamentSet}</StyledTableCell>
                  <StyledTableCell>{row.daily}</StyledTableCell>
                  <StyledTableCell>{row.dailySet}</StyledTableCell>
                  <StyledTableCell>{row.mobileDaily}</StyledTableCell>
                  <StyledTableCell>{row.mobileDailySet}</StyledTableCell>
                  <StyledTableCell>{row.mobileDailyTopic}</StyledTableCell>
                  <StyledTableCell>{row.arenaSet}</StyledTableCell>
                  <StyledTableCell>{row.sortOrder}</StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={100}
                  sx={{ textAlign: 'center', paddingTop: 2, paddingBottom: 2 }}
                >
                  Nothing to preview
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default QuestionsSetup;
