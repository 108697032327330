import React from 'react';
import { ArenaAchievement, UserArenaAchievement } from '../types';
import {
  Card,
  CardActionArea,
  Stack,
  useTheme,
  Avatar,
  styled,
  TooltipProps,
  Tooltip,
  tooltipClasses,
  Typography,
  Badge,
  BadgeProps,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Chip,
} from '@mui/material';
import { GROUP_BACKGROUND_COLORS, RARITY_COLORS } from './constants';
import Check from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { GiLaurelCrown } from 'react-icons/gi';
import { IoIosLeaf } from 'react-icons/io';
import { GiBorderedShield } from 'react-icons/gi';
import { GiGriffinShield } from 'react-icons/gi';
import { FaGem } from 'react-icons/fa';
import { BsFillMoonStarsFill } from 'react-icons/bs';
import AchievementTooltipBody from './AchievementTooltipBody';

export const AchievementTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#111a19',
    color: 'white',
    fontSize: 16,
    borderRadius: 5,
    border: '1px solid',
    borderColor: '#a48672',
    maxWidth: '100%',
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    left: 16,
    top: 13,
    padding: '0 4px',
  },
}));

const RarityBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    left: 56,
    bottom: 0,
    padding: '0 4px',
  },
}));

export const getRarityIcon = (
  rarity: string,
  noBackground?: boolean,
  size?: number
) => {
  switch (rarity) {
    case 'prestige':
      return (
        <GiLaurelCrown
          size={size || 22}
          color={RARITY_COLORS[rarity]}
          style={{
            backgroundColor: !noBackground ? 'rgba(0, 0, 0, 0.8)' : null,
            borderRadius: '50%',
          }}
        />
      );
    case 'common':
      return (
        <IoIosLeaf
          size={size || 22}
          color={RARITY_COLORS[rarity]}
          style={{
            backgroundColor: !noBackground ? 'rgba(0, 0, 0, 0.8)' : null,
            borderRadius: '50%',
          }}
        />
      );
    case 'uncommon':
      return (
        <BsFillMoonStarsFill
          size={size || 22}
          color={RARITY_COLORS[rarity]}
          style={{
            backgroundColor: !noBackground ? 'rgba(0, 0, 0, 0.8)' : null,
            borderRadius: '50%',
          }}
        />
      );
    case 'rare':
      return (
        <FaGem
          size={size || 22}
          color={RARITY_COLORS[rarity]}
          style={{
            backgroundColor: !noBackground ? 'rgba(0, 0, 0, 0.8)' : null,
            borderRadius: '50%',
          }}
        />
      );
    default:
      return null;
  }
};

type Props = {
  achievement: ArenaAchievement;
  canUpdateShowcase?: boolean;
  handleAchievementClick?: () => void;
  hideAchievedIcon?: boolean;
  userAchievement: UserArenaAchievement;
};

const AchievementCard = ({
  achievement,
  canUpdateShowcase,
  handleAchievementClick,
  hideAchievedIcon,
  userAchievement,
}: Props) => {
  const theme = useTheme();

  return (
    <StyledBadge
      badgeContent={<CheckCircleIcon color="success" />}
      invisible={hideAchievedIcon || !userAchievement?.achievedAt}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Card
        sx={{
          m: 1,
          bgcolor: GROUP_BACKGROUND_COLORS[achievement.group - 1],
        }}
      >
        <CardActionArea onClick={handleAchievementClick}>
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            position={'relative'}
          >
            <AchievementTooltip
              title={
                <AchievementTooltipBody
                  achievement={achievement}
                  userArenaAchievement={userAchievement}
                />
              }
              placement="top"
            >
              <Avatar
                variant="rounded"
                src={achievement.imageUrl}
                alt="image"
                sx={{
                  width: 95,
                  height: 95,
                  filter: userAchievement?.achievedAt ? '' : 'grayscale(100%)',
                  opacity: userAchievement?.achievedAt ? 1 : 0.5,
                  '& img': {
                    objectFit: 'contain',
                  },
                }}
              />
            </AchievementTooltip>
          </Stack>
        </CardActionArea>

        {userAchievement?.count > 1 && (
          <Chip
            label={userAchievement?.count}
            color="primary"
            size="small"
            sx={{
              position: 'absolute',
              bottom: 5,
              right: 0,
            }}
          ></Chip>
        )}
      </Card>
    </StyledBadge>
  );
};

export default AchievementCard;
