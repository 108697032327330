import React, { useEffect, useMemo, useState } from 'react';
import {
  Route,
  Navigate,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { useProfileContext } from './contexts/ProfileProvider';
import { Login } from './pages/auth/Login';
import { Header } from './components/header';
import {
  useGameloopSocketContext,
  useInputSocketContext,
  useSocketContext,
  useSpectateSocketContext,
} from './contexts/SocketProvider';
import {
  connectProfileToSocket,
  connectProfileToSocketGameloop,
  connectProfileToSocketLiveInput,
  connectProfileToSocketSpectate,
} from './sockets/helpers';
import { Footer } from './components/footer/Footer';
import './styles.css';
import { Verify } from './pages/auth/Verify';
import { VerifyInvite } from './pages/auth/VerifyInvite';
import { GameLoading } from './pages/game-loading/GameLoading';
import { GameSession } from './pages/game-session';
import { SpectateGameSession } from './pages/game-session/SpectateGameSession';
import { GlobalActions } from './pages/global-actions';
import { GlobalDialogs } from './pages/global-dialogs';
import ProfilePageOLD from './pages/profile_old';
import ProfilePage from './pages/profile';
import TeamOverview from './pages/team-old';
import PricingPage from './pages/pricing';

import { AdminControlPanelTab } from './components/admin-control-panel/AdminControlPanelTab';
import { AdminControlPanel } from './components/admin-control-panel/AdminControlPanel';
import QuickPlayPage from './pages/quick-play';
import AdminConfigPage from './pages/admin/AdminConfigPage';
import LeagueSchedulesPage from './pages/league-schedules';
import LeagueFaqPage from './pages/league-faq';
import AccountPage from './pages/account/Account';
import GameSettingsPage from './pages/game-settings/GameSettings';
import HelpPage from './pages/help/HelpPage';
import QuestionsSetup from './pages/admin/QuestionsSetup';
import InaccuraciesReport from './pages/admin/InaccuraciesReport';
import ReviewQuestions from './pages/admin/ReviewQuestions';
import DailiesPageOLD from './pages/dailies_old';
import DailiesPage from './pages/dailies';
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  RadialLinearScale,
  Filler,
} from 'chart.js';
import { isMobile } from 'react-device-detect';
import { ResetPassword } from './pages/auth/ResetPassword';
import AdminArenaSchedule from './pages/admin/ArenaSchedule';
import ArenaPage from './pages/arena';
import ArenaTeam from './pages/arena-team/ArenaTeam';
import { Home } from './pages/home';
import { SideNav } from './navigation/SideNav';
import LobbyPage from './pages/lobby';
import NormalMenu from './pages/play-menus/NormalMenu/NormalMenu';
import RankedMenu from './pages/play-menus/RankedMenu/RankedMenu';
import PunchcardMenu from './pages/play-menus/PunchcardMenu/PunchcardMenu';
import { useLoggedInUserContext } from './contexts/LoggedInUserProvider';

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  RadialLinearScale,
  Filler
);

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const { profile, profileIsLoading } = useProfileContext();

  const { refreshBrowser } = useLoggedInUserContext();

  const { connectSocket, disconnectSocket, isSocketConnected, socket } =
    useSocketContext();

  const {
    connectInputSocket,
    disconnectInputSocket,
    isInputSocketConnected,
    inputSocket,
  } = useInputSocketContext();

  const {
    connectGameloopSocket,
    disconnectGameloopSocket,
    isGameloopSocketConnected,
    gameloopSocket,
  } = useGameloopSocketContext();

  const {
    connectSpectateSocket,
    disconnectSpectateSocket,
    isSpectateSocketConnected,
    spectateSocket,
  } = useSpectateSocketContext();

  const [openAdminControlPanel, setOpenAdminControlPanel] = useState(false);

  const isNavigationAllowed = useMemo(
    () => shouldNavigate(location.pathname),
    [location.pathname]
  );

  useEffect(() => {
    if (profileIsLoading || profile || isNavigationAllowed) return;
    navigate('/login');
  }, [profile, profileIsLoading, isNavigationAllowed, navigate]);

  useEffect(() => {
    if (profile && !refreshBrowser) {
      connectSocket();
      connectInputSocket();
      connectGameloopSocket();
      connectSpectateSocket();
    } else {
      disconnectSocket();
      disconnectInputSocket();
      disconnectGameloopSocket();
      disconnectSpectateSocket();
    }
  }, [profile]);

  useEffect(() => {
    if (!isSocketConnected) return;
    connectProfileToSocket(socket, profile, isMobile);
  }, [isSocketConnected]);

  useEffect(() => {
    if (!isInputSocketConnected) return;
    connectProfileToSocketLiveInput(inputSocket, profile);
  }, [isInputSocketConnected]);

  useEffect(() => {
    if (!isGameloopSocketConnected) return;
    connectProfileToSocketGameloop(gameloopSocket, profile);
  }, [isGameloopSocketConnected]);

  useEffect(() => {
    if (!isSpectateSocketConnected) return;
    connectProfileToSocketSpectate(spectateSocket, profile);
  }, [isSpectateSocketConnected]);

  const hideHeader =
    location.pathname !== '/login' &&
    !location.pathname.includes('verify') &&
    !location.pathname.includes('reset-password') &&
    !location.pathname.includes('game-loading') &&
    !location.pathname.includes('game-session') &&
    !location.pathname.includes('spectate');

  if (profileIsLoading) {
    return null;
  }

  const AuthenticatedRoute = ({ children }) => {
    if (!profile) {
      return <Navigate to="/login" replace />;
    }

    if (
      !isSocketConnected ||
      !isInputSocketConnected
      // !isSpectateSocketConnected
    ) {
      return null;
    }

    return children;
  };

  const routes = (
    <Routes>
      <Route
        path="login"
        element={!!profile ? <Navigate to="/home" replace /> : <Login />}
      />
      <Route path="reset-password/:token" element={<ResetPassword />} />
      <Route path="verify/:token" element={<Verify />} />
      <Route path="verify-invite/:token" element={<VerifyInvite />} />
      <Route path="game-loading" element={<GameLoading />} />
      <Route path="game-session" element={<GameSession />} />
      <Route path="spectate" element={<SpectateGameSession />} />
      <Route
        path="game-settings"
        element={
          <AuthenticatedRoute>
            <GameSettingsPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="help"
        element={
          <AuthenticatedRoute>
            <HelpPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="admin/config"
        element={
          <AuthenticatedRoute>
            <AdminConfigPage />
          </AuthenticatedRoute>
        }
      />
      <Route path="admin/questions-setup" element={<QuestionsSetup />} />
      <Route
        path="admin/inaccuracies-report"
        element={<InaccuraciesReport />}
      />
      <Route path="admin/review-questions" element={<ReviewQuestions />} />
      <Route path="admin/pub-night-schedule" element={<AdminArenaSchedule />} />
      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  );

  const sideNavRoutes = (
    <Routes>
      <Route
        path="home"
        element={
          <AuthenticatedRoute>
            <Home />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="profile/overview/:profileId"
        element={
          <AuthenticatedRoute>
            <ProfilePage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="lobby"
        element={
          <AuthenticatedRoute>
            <LobbyPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="dailies"
        element={
          <AuthenticatedRoute>
            <DailiesPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="play/normal"
        element={
          <AuthenticatedRoute>
            <NormalMenu />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="play/ranked"
        element={
          <AuthenticatedRoute>
            <RankedMenu />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="play/punchcard"
        element={
          <AuthenticatedRoute>
            <PunchcardMenu />
          </AuthenticatedRoute>
        }
      />
    </Routes>
  );

  const routesWithSideNav = [
    '/home',
    '/profile/overview/',
    '/lobby',
    '/dailies',
    '/play',
  ];

  return (
    <>
      <CssBaseline />

      {hideHeader && <Header profile={profile} />}

      {routesWithSideNav.some((route) => location.pathname.startsWith(route))
        ? sideNavRoutes
        : routes}

      {/* {!!profile && !inGameSession ? <BottomNav /> : null} */}

      {hideHeader && <Footer />}

      <GlobalDialogs />
      <GlobalActions />

      {/* {profile?.admin ? (
        <AdminControlPanelTab open={() => setOpenAdminControlPanel(true)} />
      ) : null} */}
      {profile?.admin ? (
        <AdminControlPanel
          closeDrawer={() => setOpenAdminControlPanel(false)}
          open={openAdminControlPanel}
          profileId={profile._id}
        />
      ) : null}
    </>
  );
}

export default App;

const shouldNavigate = (pathname: string) => {
  const skipPaths = [
    PATHNAMES.GAME_VIEW,
    PATHNAMES.FORGOT_PASSWORD,
    PATHNAMES.VERIFY,
    PATHNAMES.RESET_PASSWORD,
  ];
  return skipPaths.some((path) => pathname.includes(path));
};

const PATHNAMES = {
  GAME_VIEW: '/game/view',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY: '/verify',
  RESET_PASSWORD: '/reset-password',
};
