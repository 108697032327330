import { Stack, Avatar, Typography, Divider, Link } from '@mui/material';
import React from 'react';
import { ArenaAchievement, UserArenaAchievement } from '../types';
import { getRarityIcon } from './AchievementCard';
import { RARITY_COLORS } from './constants';
import dayjs from 'dayjs';
import { useScorecardDialog } from '../../../../contexts/ScorecardDialogProvider';

type Props = {
  achievement: ArenaAchievement;
  userArenaAchievement: UserArenaAchievement;
};

const AchievementTooltipBody = ({
  achievement,
  userArenaAchievement,
}: Props) => {
  const { viewScorecardDialog } = useScorecardDialog();

  return (
    <Stack>
      <Stack direction={'row'} spacing={2} p={2}>
        <Avatar
          variant="rounded"
          src={achievement.imageUrl}
          alt="image"
          sx={{ width: 'auto', height: 100 }}
        />

        <Stack spacing={2}>
          <Typography
            variant="h6"
            textAlign={'center'}
            fontWeight={600}
            letterSpacing={2}
          >
            {achievement.title?.toUpperCase()}
          </Typography>

          <Typography variant="body2" textAlign={'center'}>
            {achievement.condition}
          </Typography>

          <Stack
            direction={'row'}
            spacing={1}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography
              variant="body2"
              textAlign={'center'}
              sx={{
                color: RARITY_COLORS[achievement.rarity],
              }}
            >
              Rarity: {achievement.rarity?.toUpperCase()}
            </Typography>
            {getRarityIcon(achievement.rarity)}
          </Stack>
        </Stack>
      </Stack>

      {userArenaAchievement?.achievedAt && (
        <>
          <Divider />
          <Stack py={2} spacing={2}>
            <Typography textAlign={'center'}>
              First Achieved:{' '}
              {dayjs(userArenaAchievement.achievedAt).format('ll')}
            </Typography>

            <Link
              component="button"
              onClick={() => viewScorecardDialog(userArenaAchievement?.gameId)}
            >
              View Game
            </Link>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default AchievementTooltipBody;
