import axios from 'axios';

const API_HOST = process.env.REACT_APP_API;

const apiClient = axios.create({
  baseURL: `${API_HOST}`, // This will prepend `/api` to all URLs
  withCredentials: true,
});

export default apiClient;
