import { useQuery } from '@tanstack/react-query';
import { fetchLobbyGames } from '../../../api/apiServices';

const useFetchLobbyGames = (profileExists: boolean, limit: number) => {
  const { data, refetch } = useQuery({
    queryKey: ['lobbyGames', { limit }],
    queryFn: fetchLobbyGames,
    enabled: profileExists,
  });

  return {
    refetch,
    lobbyGames: data?.games || [],
  };
};

export default useFetchLobbyGames;
