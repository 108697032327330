import { useQuery } from '@tanstack/react-query';
import { getPunchcardGames } from '../../../../api/apiServices';

const useGetPunchcardGames = (profileId: string, categorySet: string) => {
  const { data, isPending, refetch } = useQuery({
    queryKey: ['getPunchcardGames', { profileId, categorySet }],
    queryFn: getPunchcardGames,
    enabled: !!profileId && !!categorySet,
  });

  return {
    refetch,
    data,
    isPending,
  };
};

export default useGetPunchcardGames;
