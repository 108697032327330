import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { timeSince } from '../../../utils/date-time';
import { ChatMessage } from '../../../types/chat';
import Linkify from 'linkify-react';
import './LinkStyles.css';

type Props = {
  chatChannel: string;
  colorAssignment: Record<string, string>;
  chatMessages: ChatMessage[];
  showTimestamp?: boolean;
};

const ChatMessages = ({
  chatChannel,
  colorAssignment,
  chatMessages,
  showTimestamp,
}: Props) => {
  const theme = useTheme();

  const options = {
    attributes: {
      className: 'linkify-link',
      target: '_blank', // Opens links in a new tab
      rel: 'noopener noreferrer', // Security for opening links in a new tab
    },
  };

  return (
    <>
      {chatMessages?.map((msg, i) => (
        <Stack
          key={i}
          direction={'row'}
          alignItems={'center'}
          sx={{ position: 'relative' }}
        >
          <Typography
            ml={1}
            textAlign={'left'}
            width={'100%'}
            fontFamily={'noto-sans'}
            sx={{ color: '#dbdbdb' }}
          >
            <span
              style={{
                color:
                  chatChannel === 'game'
                    ? colorAssignment[msg.username]
                    : theme.palette.primary.main,
                marginRight: 10,
                textAlign: 'left',
                minWidth: '50px',
                maxWidth: '120px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'pre',
              }}
            >
              {msg.username}:
            </span>
            <Linkify options={options}>{msg.text}</Linkify>
          </Typography>
          {showTimestamp ? (
            <Typography
              fontSize={12}
              paddingX={1}
              fontFamily={'noto-sans'}
              color="gray"
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            >
              {timeSince(new Date(msg.createdAt || 0), true)} ago
            </Typography>
          ) : null}
        </Stack>
      ))}
    </>
  );
};

export default ChatMessages;
