import React from 'react';
import {
  Box,
  Popover,
  Typography,
  List,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  useTheme,
  Badge,
  BadgeProps,
  styled,
} from '@mui/material';
import { BiGhost } from 'react-icons/bi';
import { FaSeedling } from 'react-icons/fa';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Achievement } from '../../../types/achievement';
import { Notification } from '../../../types/notifications';
import { utcToLocalDateTime } from '../../utils/date-utils';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

export const NotifBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.notification.main,
  },
}));

type PropTypes = {
  anchorEl: Element;
  handleAchievementClick: (achievement: Achievement) => void;
  handleCloseNotifications: () => void;
  handleViewResultsClick: (gameId: string) => void;
  newNotifications?: Notification[];
  notifications?: Notification[];
  open: boolean;
};

export const NotificationsPopover = ({
  anchorEl,
  handleAchievementClick,
  handleCloseNotifications,
  handleViewResultsClick,
  newNotifications,
  notifications,
  open,
}: PropTypes) => {
  const theme = useTheme();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleCloseNotifications}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box sx={{ bgcolor: '#555555', paddingY: 1, paddingX: 2 }}>
        <Typography
          variant="h6"
          textAlign={'center'}
          letterSpacing={4}
          fontWeight={600}
          mb={1}
          sx={{ color: theme.palette.primary.light }}
        >
          NOTIFICATIONS
        </Typography>
        <List
          dense
          sx={{
            width: '400px',
            maxHeight: '50vh',
            overflow: 'auto',
          }}
        >
          {notifications && notifications.length ? (
            notifications.map((n, i) => (
              <ListItemButton
                key={i}
                sx={{
                  marginBottom: 0.5,
                  paddingY: 0,
                  background:
                    'linear-gradient(106deg, rgb(47 47 47) 0%, rgb(52 50 49) 35%, rgb(101 101 101) 100%)',
                  '&:hover': {
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                onClick={() =>
                  n.type === 'achievement-earned'
                    ? handleAchievementClick(n.target as Achievement)
                    : handleViewResultsClick(n.gameId)
                }
              >
                <NotifBadge
                  variant="dot"
                  badgeContent={
                    !!newNotifications.find((nn) => nn._id === n._id) || 0
                  }
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <ListItemAvatar>
                    {n.type === 'achievement-earned' ? (
                      <Avatar src={n.imageUrl} />
                    ) : n.type === 'seeded-game-completed' ? (
                      <Avatar>
                        <FaSeedling
                          style={{
                            fontSize: '14px',
                            color: theme.palette.success.main,
                          }}
                        />
                      </Avatar>
                    ) : n.type === 'ghost-challenge-completed' ? (
                      <Avatar>
                        <BiGhost
                          style={{
                            fontSize: '18px',
                            color: theme.palette.warning.main,
                          }}
                        />
                      </Avatar>
                    ) : n.type === 'custom-question-approved' ? (
                      <Avatar>
                        <CheckCircleOutlineIcon
                          style={{
                            fontSize: '48px',
                            color: theme.palette.success.main,
                          }}
                        />
                      </Avatar>
                    ) : n.type === 'custom-question-rejected' ? (
                      <Avatar>
                        <RemoveCircleOutlineIcon
                          style={{
                            fontSize: '48px',
                            color: theme.palette.error.main,
                          }}
                        />
                      </Avatar>
                    ) : n.type === 'custom-question-seen' ? (
                      <Avatar>
                        <VisibilityIcon
                          style={{
                            fontSize: '24px',
                          }}
                        />
                      </Avatar>
                    ) : (
                      <Avatar>
                        <MessageOutlinedIcon
                          style={{
                            fontSize: '24px',
                          }}
                        />
                      </Avatar>
                    )}
                  </ListItemAvatar>
                </NotifBadge>
                <ListItemText
                  primary={n.text}
                  secondary={utcToLocalDateTime(n.createdAt)}
                  sx={{
                    '&:hover': {
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              </ListItemButton>
            ))
          ) : (
            <Typography
              textAlign={'center'}
              sx={{
                background:
                  'linear-gradient(106deg, rgb(47 47 47) 0%, rgb(52 50 49) 35%, rgb(101 101 101) 100%)',
              }}
            >
              You don't have any notifications
            </Typography>
          )}
        </List>
      </Box>
    </Popover>
  );
};
