import React, { useState } from 'react';
import {
  Button,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Maintenance } from '../../types';
import { useSocketContext } from '../../contexts/SocketProvider';
import BasicLayout from '../../BasicLayout/BasicLayout';

const AdminConfigPage = () => {
  const { socket } = useSocketContext();

  const [adminMaintenance, setAdminMaintenance] = useState<Maintenance>();
  const [maintenancePassword, setMaintenancePassword] = useState('');
  const [maintenanceError, setMaintenanceError] = useState('');

  const handleBannerMessage = (message: string) => {
    setAdminMaintenance((prev: any) => ({ ...prev, bannerMessage: message }));
  };

  const handlePassword = (password: string) => {
    setMaintenancePassword(password);
  };

  const handleScheduleMaintenanceSwitch = (checked: boolean) => {
    setMaintenanceError('');
    setAdminMaintenance((prev: any) => ({ ...prev, scheduled: checked }));
    socket.emit(
      'maintenance',
      {
        scheduled: checked,
        bannerMessage: adminMaintenance?.bannerMessage,
        password: maintenancePassword,
      },
      maintenanceCallback
    );
  };

  const maintenanceCallback = (error: string) => {
    if (error === 'Wrong Password') {
      setAdminMaintenance((prev: any) => ({ ...prev, scheduled: false }));
      setMaintenanceError(error);
    }
  };

  const handleResetRedisConfigs = () => {
    socket.emit('resetRedisConfigs');
  };

  return (
    <BasicLayout>
      <Grid container spacing={3}>
        <Grid item sm={3} md={3}>
          <Stack>
            {maintenanceError ? (
              <Typography color="error.main">{maintenanceError}</Typography>
            ) : null}
            <Typography variant="h5">Schedule Maintenance</Typography>
            <TextField
              id="bannerMessage"
              label="Banner message"
              variant="standard"
              onChange={(e) => handleBannerMessage(e.target.value)}
            />
            <TextField
              id="password"
              label="Password"
              type={'password'}
              variant="standard"
              onChange={(e) => handlePassword(e.target.value)}
            />
            <Stack direction={'row'} mt={2}>
              <Typography variant="body1">Set to maintenance mode</Typography>
              <Switch
                checked={adminMaintenance?.scheduled || false}
                onChange={(e) =>
                  handleScheduleMaintenanceSwitch(e.target.checked)
                }
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item sm={3} md={3}>
          <Button onClick={handleResetRedisConfigs}>Reset Redis Configs</Button>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default AdminConfigPage;
