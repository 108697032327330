import { useQuery } from '@tanstack/react-query';
import { fetchCurrentDaily } from '../../../api/apiServices';

const useFetchCurrentDaily = () => {
  const { data } = useQuery({
    queryKey: ['currentDaily'],
    queryFn: fetchCurrentDaily,
  });

  return {
    data,
  };
};

export default useFetchCurrentDaily;
