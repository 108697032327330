import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  styled,
  useTheme,
  Card,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Profile } from '../../../types/player';
import {
  PanickyTypography,
  PassableTypography,
  PoisedTypography,
  PristineTypography,
} from '../../game-session/game/player-input/PrecisionGraphic';
import {
  PristineBox,
  PoisedBox,
  PassableBox,
  PanickyBox,
} from '../../game-session/game/player-input/PrecisionGraphicIcons';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    textAlign: 'center',
    fontWeight: 600,
  },
}));

type PropTypes = {
  viewProfile: Profile;
};

export const Precision = ({ viewProfile }: PropTypes) => {
  const {
    precision,
    totalGamesPlayedBeforeFuzzyPenaltyLaunch,
    questionsSeenCount,
  } = viewProfile;

  const theme = useTheme();

  const calcPer100 = (precisionCount: number, questionsSeenCount: number) => {
    if (!precisionCount) return '';
    const val =
      (precisionCount /
        (questionsSeenCount -
          (totalGamesPlayedBeforeFuzzyPenaltyLaunch || 0) * 10)) *
      100;
    return `${val.toFixed(1)}`;
  };

  return (
    <Box pt={2}>
      <Typography py={1} fontWeight={500}>
        Precision
      </Typography>

      <TableContainer
        sx={{
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <StyledTableCell>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme.palette.success.main,
                    }}
                  >
                    Putts
                  </Typography>
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{ position: 'relative' }}>
                <PanickyBox
                  top={'50%'}
                  bottom={''}
                  left={'50%'}
                  right={''}
                  transform={'translate(-50%, -50%)'}
                >
                  <PanickyTypography variant="h6">P</PanickyTypography>
                </PanickyBox>
              </StyledTableCell>
              <StyledTableCell sx={{ position: 'relative' }}>
                <PassableBox
                  top={'50%'}
                  bottom={''}
                  left={'50%'}
                  right={''}
                  transform={'translate(-50%, -50%)'}
                >
                  <PassableTypography variant={'body2'}>P</PassableTypography>
                </PassableBox>
              </StyledTableCell>
              <StyledTableCell sx={{ position: 'relative' }}>
                <PoisedBox
                  top={'50%'}
                  bottom={''}
                  left={'50%'}
                  right={''}
                  transform={'translate(-50%, -50%)'}
                >
                  <PoisedTypography variant="h4">P</PoisedTypography>
                </PoisedBox>
              </StyledTableCell>
              <StyledTableCell sx={{ position: 'relative' }}>
                <PristineBox
                  top={'50%'}
                  bottom={''}
                  left={'50%'}
                  right={''}
                  transform={'translate(-50%, -50%)'}
                >
                  <PristineTypography variant={'h6'}>P</PristineTypography>
                </PristineBox>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lifetime</TableCell>
              <StyledTableCell sx={{ color: theme.palette.success.main }}>
                {precision?.['putt']}
              </StyledTableCell>
              <StyledTableCell sx={{ color: 'orange' }}>
                {precision?.['panicky']}
              </StyledTableCell>
              <StyledTableCell sx={{ color: '#64faff' }}>
                {precision?.['passable']}
              </StyledTableCell>
              <StyledTableCell sx={{ color: 'red' }}>
                {precision?.['poised']}
              </StyledTableCell>
              <StyledTableCell sx={{ color: '#f1b7f1' }}>
                {precision?.['pristine']}
              </StyledTableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>Per 100 Qs</TableCell>
              <StyledTableCell sx={{ color: theme.palette.success.main }}>
                {calcPer100(precision?.['putt'], questionsSeenCount)}
              </StyledTableCell>
              <StyledTableCell sx={{ color: 'orange' }}>
                {calcPer100(precision?.['panicky'], questionsSeenCount)}
              </StyledTableCell>
              <StyledTableCell sx={{ color: '#64faff' }}>
                {calcPer100(precision?.['passable'], questionsSeenCount)}
              </StyledTableCell>
              <StyledTableCell sx={{ color: 'red' }}>
                {calcPer100(precision?.['poised'], questionsSeenCount)}
              </StyledTableCell>
              <StyledTableCell sx={{ color: '#f1b7f1' }}>
                {calcPer100(precision?.['pristine'], questionsSeenCount)}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
