import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ToggleStats } from '../pages/game-session/ToggleStats';
import { VanquishedDemon, Teams, Mode, Question } from '../types/game';
import { StarterKitConfig } from '../types/starter-kits';
import { average } from '../utils/math';
import { Scorecard } from '../components/scorecard';
import {
  ArenaTeamId,
  ArenaTeamLiveRoundScores,
  ArenaTeamRoundScores,
  DetailedPlayer,
  Player,
} from '../types/player';
import CloseIcon from '@mui/icons-material/Close';
import { assignRanks } from '../utils/game';
import { SitAndGoLeaderboard } from '../pages/game-session/game/SitAndGoLeaderboard';
import { ScoreCardStatsTeams } from '../pages/game-session/score-screen/ScoreCardStatsTeams';
import { ScoreCardStatsSnG } from '../pages/game-session/score-screen/ScoreCardStatsSnG';
import { ArenaTeam } from '../pages/arena-team/types';
import { ArenaTeamsLiveLeaderboard } from '../pages/game-session/game/ArenaTeamsLiveLeaderboard';
import MmrResultsDialog from '../pages/game-session/score-screen/MmrResultsDialog/MmrResultsDialog';
import useFetchArenaMmrResults from '../hooks/useFetchArenaMmrResults';
import { ScoreCardStatsArenaTeams } from '../pages/game-session/score-screen/ScoreCardStatsArenaTeams/ScoreCardStatsArenaTeams';
import { getTeamTotalScore } from '../pages/game-session/utils';

type PropTypes = {
  arenaSet?: number;
  arenaTeams?: ArenaTeam[];
  arenaTeamRoundScores?: Record<ArenaTeamId, ArenaTeamRoundScores>;
  bannedCategories?: string[];
  categorySelections?: string[];
  categorySet?: string;
  demons?: boolean;
  demonsVanquished?: VanquishedDemon[];
  gameId: string;
  gameType?: 'casual' | 'pro';
  handicap?: number;
  isDaily?: boolean;
  league?: boolean;
  leagueGameType?:
    | 'league'
    | 'friendly'
    | 'swiss-tournament'
    | 'tournament'
    | 'tournament-tiebreaker';
  onClose: () => void;
  open: boolean;
  mode: Mode;
  players: Record<string, DetailedPlayer>;
  profileId: string;
  questionSet: Question[];
  ranked: boolean;
  seededBy?: string;
  starterKit?: StarterKitConfig;
  survivalMode?: 'endurance' | 'challenge' | 'demon';
  teams?: Teams;
  variant?: 'normal' | 'survival' | 'starter-kit' | 'turbo';
};

export const ScorecardDialog = ({
  arenaSet,
  arenaTeams,
  arenaTeamRoundScores,
  bannedCategories,
  categorySelections,
  categorySet,
  demons,
  demonsVanquished,
  gameId,
  isDaily,
  gameType,
  handicap,
  league,
  leagueGameType,
  onClose,
  open,
  mode,
  players,
  profileId,
  questionSet,
  ranked,
  seededBy,
  starterKit,
  survivalMode,
  teams,
  variant,
}: PropTypes) => {
  const [sortedPlayers, setSortedPlayers] = useState<Player[]>();
  const [selectedPlayer, setSelectedPlayer] = useState<DetailedPlayer>();
  const [averageDifficulty, setAverageDifficulty] = useState<number>();
  const [detailedPlayerSelf, setDetailedPlayerSelf] =
    useState<DetailedPlayer>();
  const [mmrResultsDialogOpen, setMmrResultsDialogOpen] = useState(false);

  const [toggleQuestionsStats, setToggleQuestionsStats] = useState<
    'scorecard' | 'stats' | 'teams'
  >('scorecard');

  const playersCount = Object.values(players).length;

  const { data: arenaMmrResults } = useFetchArenaMmrResults(
    gameId,
    mmrResultsDialogOpen
  );

  useEffect(() => {
    if (!open) return;
    setToggleQuestionsStats('scorecard');
    setSortedPlayers(undefined);
    setAverageDifficulty(undefined);
    if (players[profileId]) {
      setSelectedPlayer(players[profileId]);
    } else {
      setSelectedPlayer(undefined);
    }
  }, [open]);

  const sortPlayers = (players: Record<string, Player>) => {
    const sortedPlayers = Object.values(players).sort(
      (a, b) => a.parScore - b.parScore
    );
    return assignRanks(sortedPlayers);
  };

  useEffect(() => {
    if (mode === 'sit-n-go') {
      setSortedPlayers(sortPlayers(players));
    }
    setDetailedPlayerSelf(players[profileId]);
  }, [players]);

  useEffect(() => {
    if (!questionSet) return;
    const averageDifficulty = average(questionSet.map((q) => q.difficulty));
    setAverageDifficulty(averageDifficulty);
  }, [questionSet]);

  const handlePoolLeaderboardUsernameClick = (profileId: string) => {
    setSelectedPlayer(players[profileId]);
  };

  const handleShowStatsChange = (
    event: any,
    value: 'scorecard' | 'stats' | 'teams'
  ) => {
    if (value === null) return;
    setToggleQuestionsStats(value);
  };

  const arenaTeamLiveRoundScores = useMemo(
    () =>
      arenaTeamRoundScores
        ? convertToArenaTeamLiveRoundScores(arenaTeamRoundScores)
        : undefined,
    [arenaTeamRoundScores]
  );

  const arenaTeamId = arenaTeams?.[0]?._id;
  const myArenaTeam = useMemo(
    () => arenaTeams?.find((team) => team._id === arenaTeamId),
    [arenaTeams, arenaTeamId]
  );
  const myArenaTeamScore = useMemo(
    () =>
      arenaTeamLiveRoundScores?.[arenaTeamId]
        ? getTeamTotalScore(arenaTeamLiveRoundScores[arenaTeamId])
        : 0,
    [arenaTeamLiveRoundScores, arenaTeamId]
  );
  const otherArenaTeam = useMemo(
    () => arenaTeams?.find((team) => team._id !== arenaTeamId),
    [arenaTeams, arenaTeamId]
  );
  const otherArenaTeamScore = useMemo(
    () =>
      arenaTeamLiveRoundScores?.[otherArenaTeam?._id]
        ? getTeamTotalScore(arenaTeamLiveRoundScores[otherArenaTeam?._id])
        : 0,
    [arenaTeamLiveRoundScores, otherArenaTeam]
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          sx: {
            minHeight: '90vh',
            maxHeight: '90vh',
          },
        }}
      >
        <DialogTitle>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              marginTop: '10px',
              marginRight: '10px',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack direction={'row'}>
            <Stack width={'100%'} overflow={'auto'}>
              {mode === 'sit-n-go' && playersCount > 2 && (
                <Stack direction={'row'} justifyContent={'center'} px={1}>
                  <ToggleStats
                    arenaSet={arenaSet}
                    handleShowStatsChange={handleShowStatsChange}
                    mode={mode}
                    toggleQuestionsStats={toggleQuestionsStats}
                  />
                </Stack>
              )}

              {mode === 'teams' &&
              toggleQuestionsStats === 'stats' &&
              players &&
              teams ? (
                <Box mt={2}>
                  <ScoreCardStatsTeams
                    detailedPlayers={players}
                    league={league}
                    myTeam={teams.teamA}
                    otherTeam={teams.teamB}
                  />
                </Box>
              ) : null}

              {mode === 'sit-n-go' &&
              playersCount > 2 &&
              toggleQuestionsStats === 'stats' &&
              players ? (
                <Box mt={2} display={'flex'} justifyContent={'center'}>
                  <ScoreCardStatsSnG detailedPlayers={players} />
                </Box>
              ) : null}

              {toggleQuestionsStats === 'teams' &&
              players &&
              myArenaTeam &&
              myArenaTeamScore &&
              otherArenaTeam &&
              otherArenaTeamScore ? (
                <ScoreCardStatsArenaTeams
                  detailedPlayers={players}
                  myArenaTeam={myArenaTeam}
                  myArenaTeamScore={myArenaTeamScore}
                  otherArenaTeam={otherArenaTeam}
                  otherArenaTeamScore={otherArenaTeamScore}
                />
              ) : null}

              {toggleQuestionsStats === 'scorecard' ? (
                <Scorecard
                  arenaSet={arenaSet}
                  averageDifficulty={averageDifficulty}
                  bannedCategories={bannedCategories}
                  categorySelections={categorySelections}
                  categorySet={categorySet}
                  demons={demons}
                  demonsVanquished={demonsVanquished}
                  detailedPlayers={players}
                  gameId={gameId}
                  gameType={gameType}
                  handicap={handicap}
                  hideTitle
                  isDaily={isDaily}
                  league={league}
                  leagueGameType={leagueGameType}
                  mode={mode}
                  myTeam={teams?.teamA}
                  opponentTeam={teams?.teamB}
                  profileId={profileId}
                  questionSet={questionSet}
                  ranked={ranked}
                  seededBy={seededBy}
                  selectedPlayer={selectedPlayer}
                  showDislike={!!players[profileId]}
                  showRatings={!!players[profileId]}
                  starterKit={starterKit}
                  survivalMode={survivalMode}
                  variant={variant}
                />
              ) : null}
            </Stack>

            {mode === 'sit-n-go' && playersCount > 2 ? (
              <Stack>
                {arenaSet && arenaTeams && arenaTeamLiveRoundScores && (
                  <>
                    <ArenaTeamsLiveLeaderboard
                      arenaTeamId={detailedPlayerSelf?.arenaTeamId}
                      arenaTeams={arenaTeams}
                      arenaTeamLiveRoundScores={arenaTeamLiveRoundScores}
                      handleTeamClick={() => {}}
                      selectedTeamId={''}
                    />

                    <Divider />
                  </>
                )}

                <SitAndGoLeaderboard
                  gameOver={true}
                  modalView={true}
                  onUsernameClick={handlePoolLeaderboardUsernameClick}
                  sortedPlayers={sortedPlayers}
                  profileId={profileId}
                />
              </Stack>
            ) : null}
          </Stack>
        </DialogContent>
      </Dialog>

      {mmrResultsDialogOpen && arenaMmrResults && (
        <MmrResultsDialog
          arenaUpdateTrueSkill={arenaMmrResults}
          open={mmrResultsDialogOpen}
          onClose={() => setMmrResultsDialogOpen(false)}
          sortedPlayers={sortedPlayers}
        />
      )}
    </>
  );
};

const convertToArenaTeamLiveRoundScores = (
  arenaTeamRoundScores: Record<ArenaTeamId, ArenaTeamRoundScores>
) => {
  if (Object.keys(arenaTeamRoundScores).length === 0) return undefined;

  const arenaTeamLiveRoundScores: ArenaTeamLiveRoundScores = {};
  Object.keys(arenaTeamRoundScores).forEach((teamId) => {
    arenaTeamLiveRoundScores[teamId] = {};

    for (const questionIndex of Object.keys(arenaTeamRoundScores[teamId])) {
      const score = arenaTeamRoundScores[teamId][questionIndex];
      arenaTeamLiveRoundScores[teamId][questionIndex] = score;
    }
  });
  return arenaTeamLiveRoundScores;
};
