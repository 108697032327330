import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

const REASONS = [
  {
    value: 'format',
    description: 'Format/incomplete (par, category, alts, etc)',
  },
  { value: 'confusing', description: `Confusing wording/couldn't understand` },
  { value: 'flow', description: `Poor flow/ordering of clues` },
  {
    value: 'not-notable',
    description: `Not notable (research before selecting)`,
  },
  {
    value: 'unintersting',
    description: `Uninteresting content (aka "I just don't like it")`,
  },
];

type PropTypes = {
  close: () => void;
  handleLowRatingSubmit: (
    rating: number,
    questionId: string,
    reason: string
  ) => void;
  open: { open: boolean; rating: number; questionId: string };
};

export const CustomQuestionRatingReasonDialog = ({
  close,
  handleLowRatingSubmit,
  open,
}: PropTypes) => {
  const theme = useTheme();
  const [reason, setReason] = useState<string>();

  const handleReasonChange = (event: any, type: string) => {
    if (type !== null) {
      setReason(type);
    }
  };

  const handleSubmit = () => {
    if (!reason) return;
    const rating = open.rating;
    const questionId = open.questionId;
    handleLowRatingSubmit(rating, questionId, reason);
    close();
  };

  return (
    <Dialog open={open.open}>
      <DialogContent>
        <Stack spacing={2}>
          <Box
            className="submitted-by-stamp submitted-by-stamp-username"
            textAlign={'center'}
            sx={{ width: '100%', fontSize: '24px' }}
          >
            HELP THE QUESTION AUTHOR
          </Box>
          {/* <Typography variant="h5" textAlign={'center'} letterSpacing={2}>
            
          </Typography> */}
          <Typography textAlign={'center'}>
            Provide feedback for your 1 star rating
          </Typography>
          <FormControl sx={{ mb: 2 }}>
            <RadioGroup
              value={reason}
              onChange={(e) => handleReasonChange(e, e.target.value)}
              sx={{ color: theme.palette.primary.main }}
            >
              {REASONS?.map((r) => (
                <FormControlLabel
                  key={r.value}
                  value={r.value}
                  control={<Radio />}
                  label={r.description}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} fullWidth>
          Cancel rating
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          fullWidth
          disabled={!reason}
        >
          Submit Reason
        </Button>
      </DialogActions>
    </Dialog>
  );
};
