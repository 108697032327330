import {
  Drawer,
  useTheme,
  Stack,
  IconButton,
  Button,
  TextField,
  Typography,
  Switch,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useGameManager } from '../../hooks/useGameManager';
import useGenerateLeagueSchedule from '../../hooks/useGenerateLeagueSchedule';
import { GenerateScheduleDialog } from './GenerateScheduleDialog';
import { useLobbyGamesContext } from '../../contexts/LobbyGamesProvider';
import useUpdateArenaLadder from '../../pages/profile/ArenaProfile/hooks/useUpdateArenaLadder';
import useFetchArenaSchedule from '../../pages/profile/ArenaProfile/hooks/useFetchArenaSchedule';
import { useGameContext } from '../../contexts/GameProvider';
import { useSocketContext } from '../../contexts/SocketProvider';
import { useArenaContext } from '../../contexts/ArenaProvider';

const drawerWidth = 240;

type Props = {
  closeDrawer: () => void;
  open: boolean;
  profileId: string;
};

export const AdminControlPanel = ({ closeDrawer, open, profileId }: Props) => {
  const theme = useTheme();

  const { socket } = useSocketContext();

  const { arenaNight: currentArenaNight, showArena } = useArenaContext();

  const { data: arenaScheduleData } = useFetchArenaSchedule();

  const filteredArenaSchedule = arenaScheduleData?.filter(
    (sch) => !sch.completedAt
  );
  const sortedArenaSchedule = filteredArenaSchedule?.sort(
    (a, b) => a.startingAt - b.startingAt
  );
  const currentArenaSchedule = sortedArenaSchedule?.[0];

  const [arenaNight, setArenaNight] = useState<number>(0);
  const [arenaGameNumber, setArenaGameNumber] = useState<number>(1);
  const [arenaRoundNumber, setArenaRoundNumber] = useState<number>(1);
  const [arenaSet, setArenaSet] = useState<number>(0);
  const [timeLimit, setTimeLimit] = useState(24);
  const [arenaStartTimeMin, setArenaStartTimeMin] = useState<number>(1.5);

  const [openGenerateScheduleDialog, setOpenGenerateScheduleDialog] =
    useState(false);

  // TEMP: REMOVE
  const { arenaGame } = useLobbyGamesContext();
  const {
    handleCreateArenaGame,
    handleCreateArenaTeamGamesForRound,
    handleStartArenaGame,
    handleStartArenaTeamGames,
  } = useGameManager();
  const { generateLeagueSchedule } = useGenerateLeagueSchedule();
  const { updateArenaLadder } = useUpdateArenaLadder();

  useEffect(() => {
    if (currentArenaSchedule) {
      setArenaNight(currentArenaSchedule?.arenaNight);

      const nextArenaGame = currentArenaSchedule?.arenaGames?.find(
        (game) => !game.completed
      );
      setArenaGameNumber(nextArenaGame?.arenaGame || 1);
      setArenaSet(nextArenaGame?.arenaSet);
    }
  }, [currentArenaSchedule]);

  const handleGenerateScheduleClick = (league: string, division: string) => {
    generateLeagueSchedule({ leagueName: league, division });
  };

  const handleCreateArenaGameClick = () => {
    handleCreateArenaGame(
      profileId,
      arenaNight,
      arenaGameNumber,
      arenaSet,
      timeLimit,
      arenaStartTimeMin
    );
  };

  const handleCreateArenaTeamGamesForRoundClick = () => {
    const season = 1;
    handleCreateArenaTeamGamesForRound(
      profileId,
      currentArenaNight,
      arenaRoundNumber,
      season,
      arenaSet,
      timeLimit,
      arenaStartTimeMin
    );
  };

  const handleShowArena = (showArena: boolean) => {
    socket.emit('showArena', { showArena, profileId: profileId });
  };

  const generateArenaSchedule = () => {
    socket.emit('generateArenaTeamGames', {
      profileId: profileId,
      season: 1,
      arenaNight: currentArenaNight,
    });
  };

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            height: 400,
            width: drawerWidth,
            zIndex: 9999,
            top: '5%',
            backgroundColor: theme.palette.background.default,
            border: '1px solid',
            borderLeft: 'none',
            borderColor: theme.palette.success.main,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <IconButton
          onClick={closeDrawer}
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
          }}
        >
          <ChevronLeftIcon />
        </IconButton>

        <Stack
          mt={6}
          px={2}
          sx={{ position: 'relative', justifyContent: 'center' }}
        >
          <Stack spacing={2}>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography variant="caption">Show Arena</Typography>
              <Switch
                checked={showArena}
                onChange={(e) => handleShowArena(e.target.checked)}
              />
            </Stack>

            <Stack direction="row" spacing={2}>
              <TextField
                value={arenaNight}
                label="Night"
                onChange={(e) => setArenaNight(Number(e.target.value))}
                size="small"
              />

              <TextField
                value={timeLimit}
                label="Time Limit"
                onChange={(e) => setTimeLimit(Number(e.target.value))}
                size="small"
              />
            </Stack>

            <Stack direction="row" spacing={2}>
              {/* <TextField
                value={arenaGameNumber}
                label="Game"
                onChange={(e) => setArenaGameNumber(Number(e.target.value))}
                size="small"
              /> */}

              <TextField
                value={arenaRoundNumber}
                label="Round"
                onChange={(e) => setArenaRoundNumber(Number(e.target.value))}
                size="small"
              />

              <TextField
                value={arenaSet}
                label="Set"
                onChange={(e) => setArenaSet(Number(e.target.value))}
                size="small"
              />
            </Stack>

            <Stack direction="row" spacing={2}>
              <TextField
                type={'number'}
                inputProps={{ step: 0.5 }}
                value={arenaStartTimeMin}
                label="Timer (min)"
                onChange={(e) => setArenaStartTimeMin(Number(e.target.value))}
                size="small"
              />

              <Button
                variant="contained"
                onClick={handleCreateArenaTeamGamesForRoundClick}
              >
                Create Arena
              </Button>
            </Stack>

            {/* <Button
              variant="contained"
              onClick={() => handleStartArenaGame(profileId, arenaGame.gameId)}
            >
              Start Arena
            </Button> */}

            <Button
              variant="contained"
              onClick={() => handleStartArenaTeamGames(profileId)}
            >
              Start Team Games
            </Button>
            <Button variant="contained" onClick={() => generateArenaSchedule()}>
              Generate Arena Schedule
            </Button>
            <Button
              variant="contained"
              onClick={() => updateArenaLadder({ season: 1 })}
            >
              Update Arena Ladder
            </Button>
            {/* <Button
              variant="contained"
              onClick={() => handleGenerateLeagueGames(profileId)}
            >
              Create Games
            </Button>
            <Button
              variant="contained"
              onClick={() => handleStartScheduledLeagueGames(profileId)}
            >
              Start Games
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenGenerateScheduleDialog(true)}
            >
              Generate Schedule
            </Button> */}
          </Stack>
        </Stack>
      </Drawer>

      <GenerateScheduleDialog
        open={openGenerateScheduleDialog}
        close={() => setOpenGenerateScheduleDialog(false)}
        handleGenerateScheduleClick={handleGenerateScheduleClick}
      />
    </>
  );
};
