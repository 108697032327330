import { styled, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';

type BoxProps = {
  position: 'absolute' | 'relative';
  top: string;
  left: string;
};

export const PristineBox = styled(Box)<BoxProps>(({ position, top, left }) => ({
  position: position,
  top: top,
  left: left,
  backgroundColor: 'lightgray',
  borderRadius: 4,
  boxShadow: `
          inset 0 0 50px #e0e0e0,      /* inner white */
          inset 20px 0 80px #f0f,   /* inner left magenta short */
          inset -20px 0 80px #0ff,  /* inner right cyan short */
          inset 20px 0 300px #f0f,  /* inner left magenta broad */
          inset -20px 0 300px #0ff, /* inner right cyan broad */
          0 0 15px #8e8e8e,            /* outer white */
          0px 0 20px #f0f,        /* outer left magenta */
          0px 0 20px #0ff;         /* outer right cyan */`,
}));

export const PristineTypography = styled(Typography)(() => ({
  fontFamily: 'blacksword',
  textAlign: 'center',
  letterSpacing: 8,
  color: 'darkgreen',
  fontWeight: 600,
}));

const PristineTypographyPenalty = styled(Typography)(() => ({
  fontFamily: 'round-pixel',
  textAlign: 'center',
  position: 'absolute',
  top: '-11px',
  left: '160px',
  color: 'darkgreen',
  backgroundColor: '#fcd4fd',
  borderRadius: 4,
}));

export const PoisedBox = styled(Box)<BoxProps>(({ position, top, left }) => ({
  position: position,
  top: top,
  left: left,
}));

export const PoisedTypography = styled(Typography)(() => ({
  fontFamily: 'barbatrick',
  textAlign: 'center',
  color: 'red',
}));

const PoisedTypographyPenalty = styled(Typography)(() => ({
  fontFamily: 'round-pixel',
  textAlign: 'center',
  position: 'absolute',
  top: '-10px',
  left: '170px',
  color: 'red',
  fontStyle: 'italic',
}));

export const PassableBox = styled(Box)<BoxProps>(({ position, top, left }) => ({
  position: position,
  top: top,
  left: left,
  paddingLeft: '1px',
  paddingRight: '1px',
  border: '1px solid',
  borderColor: 'cadetblue',
}));

export const PassableTypography = styled(Typography)(() => ({
  fontFamily: 'round-pixel',
  textAlign: 'center',
  paddingLeft: '1px',
  border: '1px solid',
  borderColor: 'cadetblue',
  color: 'cadetblue',
}));

const PassableTypographyPenalty = styled(Typography)(() => ({
  fontFamily: 'round-pixel',
  textAlign: 'center',
  position: 'absolute',
  top: '-11px',
  left: '180px',
  backgroundColor: 'cadetblue',
  borderRadius: 4,
}));

export const PanickyBox = styled(Box)<BoxProps>(({ position, top, left }) => ({
  position: position,
  top: top,
  left: left,
  paddingLeft: '7px',
  paddingRight: '7px',
  border: '1px solid orange',
  borderRadius: 4,
}));

export const PanickyTypography = styled(Typography)(() => ({
  fontFamily: 'schoolbell',
  textAlign: 'center',
}));

const PanickyTypographyPenalty = styled(Typography)(() => ({
  fontFamily: 'round-pixel',
  textAlign: 'center',
  position: 'absolute',
  top: '-9px',
  left: '145px',
  backgroundColor: 'orange',
  borderRadius: 4,
}));

type PrecisionLabels = 'Pristine' | 'Poised' | 'Passable' | 'Panicky';

type PropTypes = {
  fuzzyPenalty: number;
  position?: 'absolute' | 'relative';
};

export const PrecisionGraphicScorecardIcon = ({
  fuzzyPenalty,
  position = 'absolute',
}: PropTypes) => {
  const [precisionLabel, setPrecisionLabel] = useState<PrecisionLabels>();

  useEffect(() => {
    if (fuzzyPenalty === -1) {
      setPrecisionLabel('Pristine');
    }
    if (fuzzyPenalty === 0) {
      setPrecisionLabel('Poised');
    }
    if (fuzzyPenalty === 1) {
      setPrecisionLabel('Passable');
    }
    if (fuzzyPenalty === 2) {
      setPrecisionLabel('Panicky');
    }
  }, [fuzzyPenalty]);

  return (
    <>
      {precisionLabel === 'Pristine' ? (
        <Tooltip title="Pristine! -1 to your score" placement="top">
          <PristineBox position={position} top={'0'} left={'0'}>
            <PristineTypography variant={'caption'}>P</PristineTypography>
          </PristineBox>
        </Tooltip>
      ) : precisionLabel === 'Poised' ? (
        <Tooltip
          title="Poised! Close spelling accepted. No change to score"
          placement="top"
        >
          <PoisedBox position={position} top={'0'} left={'0'}>
            <PoisedTypography variant={'h6'}>P</PoisedTypography>
          </PoisedBox>
        </Tooltip>
      ) : precisionLabel === 'Passable' ? (
        <Tooltip
          title="Passable! Ok spelling accepted. +1 to your score"
          placement="top"
        >
          <PassableBox position={position} top={'0'} left={'0'}>
            <PassableTypography variant={'caption'}>P </PassableTypography>
          </PassableBox>
        </Tooltip>
      ) : precisionLabel === 'Panicky' ? (
        <Tooltip
          title="Panicky! Barely acceptable spelling. +2 to your score"
          placement="top"
        >
          <PanickyBox position={position} top={'0'} left={'0'}>
            <PanickyTypography variant={'subtitle2'}>P</PanickyTypography>
          </PanickyBox>
        </Tooltip>
      ) : null}
    </>
  );
};
