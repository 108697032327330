import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HeaderTabs } from '../tabs';
import { LobbyGame } from '../../../types/game';
import { Profile } from '../../../types/player';
import { FaDiscord, FaXTwitter } from 'react-icons/fa6';
import { SocialMediaIconButton } from '../../../custom-components/custom-icon-buttons';
import { getSubscriptionPlan } from '../../../utils/profile';
import SearchAutocomplete from './SearchAutocomplete';
import {
  NotifBadge,
  NotificationsPopover,
} from '../notifications/NotificationsPopover';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useFetchNotifications from '../../../hooks/useFetchNotifications';
import { useUpdateProfileProperty } from '../../../hooks/useUpdateProfileProperty';
import { Notification } from '../../../types/notifications';
import { getNewNotifications } from '../notifications/utils';
import SettingsIcon from '@mui/icons-material/Settings';
import { fetchGet } from '../../../api/common';

type PropTypes = {
  openLeagueGames?: LobbyGame[];
  profile: Profile;
};

export const TopNav = ({ openLeagueGames, profile }: PropTypes) => {
  const navigate = useNavigate();

  const [lastNotificationSeen, setLastNotificationSeen] = useState(
    profile?.lastNotificationSeen
  );

  const { updateProfile } = useUpdateProfileProperty();

  const { notifications } = useFetchNotifications(profile?._id);

  const newNotifications = getNewNotifications(
    lastNotificationSeen,
    notifications
  );

  const [anchorNotificationEl, setAnchorNotificationEl] =
    useState<HTMLButtonElement | null>(null);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [anchorAdminEl, setAnchorAdminEl] = useState<null | HTMLElement>(null);
  const [openAdminMenu, setOpenAdminMenu] = useState(false);
  const [reportInaccuraciesCount, setReportInaccuraciesCount] = useState(0);
  const [unreviewedQuestions, setUnreviewedQuestions] = useState(0);

  useEffect(() => {
    if (profile?.admin) {
      fetchInaccuraciesReport();
      // fetchUserSubmittedQuestions();
    }
  }, [profile]);

  const handleRegistrationClick = () => navigate('/league-registration');
  const handleUpgradeClick = () => navigate('/pricing');

  const [openSearchPlayers, setOpenSearchPlayers] = useState(false);

  const handleOpenNotifications = async (event: any) => {
    setAnchorNotificationEl(event.currentTarget);
    setOpenNotifications(true);
  };

  const handleCloseNotifications = () => {
    setOpenNotifications(false);
    if (!newNotifications || !newNotifications.length) return;
    updateProfile(profile._id, 'lastNotificationSeen', notifications[0]._id);
    setLastNotificationSeen(notifications[0]._id);
  };

  const handleOpenAdminMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorAdminEl(event.currentTarget);
    setOpenAdminMenu(true);
  };

  const fetchInaccuraciesReport = async () => {
    const res = await fetchGet('/inaccuracy-report/all');
    setReportInaccuraciesCount(res.length);
  };

  const fetchUserSubmittedQuestions = async () => {
    const res = await fetchGet(`/custom-questions/all/${profile._id}/true`);
    setUnreviewedQuestions(res.length);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
        flex={1}
      >
        <Box display={'flex'} justifyContent={'left'} alignItems={'center'}>
          {/* <HeaderTabs profile={profile} orientation="horizontal" /> */}
          {/* <Button
            variant="outlined"
            color="primary"
            size="small"
            sx={{ ml: 2 }}
            onClick={handleRegistrationClick}
          >
            League Registration
          </Button> */}
        </Box>
        {/* <Notifications
          lastNotificationSeen={profile.lastNotificationSeen}
          profileId={profile._id}
        /> */}

        <Stack
          direction="row"
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
          px={2}
        >
          <SearchAutocomplete
            openSearchPlayers={openSearchPlayers}
            setOpenSearchPlayers={setOpenSearchPlayers}
          />

          <NotifBadge
            variant={'dot'}
            badgeContent={newNotifications?.length || 0}
            overlap="circular"
          >
            <IconButton onClick={handleOpenNotifications} size="small">
              <NotificationsIcon />
            </IconButton>
          </NotifBadge>

          {profile?.admin && (
            <IconButton onClick={handleOpenAdminMenu} size="small">
              <SettingsIcon />
            </IconButton>
          )}

          {/* {subscriptionPlan === 'free' && (
            <Button
              variant="outlined"
              color="creamOrange"
              size="small"
              onClick={handleUpgradeClick}
              sx={{ mx: 4, letterSpacing: 2 }}
            >
              Upgrade
            </Button>
          )} */}
          {/*         
        <IconButton>
          <HelpCenterOutlinedIcon />
        </IconButton> */}

          {/* <SocialMediaIconButton
            brandColor="#5865F2"
            link="https://discord.gg/upX48KPfHd"
            icon={<FaDiscord />}
          />
          <SocialMediaIconButton
            brandColor="#000000"
            link="https://twitter.com/ParTrivia"
            icon={<FaXTwitter />}
          /> */}
        </Stack>
      </Stack>

      <NotificationsPopover
        anchorEl={anchorNotificationEl}
        handleAchievementClick={() => {}}
        handleCloseNotifications={handleCloseNotifications}
        handleViewResultsClick={() => {}}
        newNotifications={newNotifications}
        notifications={notifications}
        open={openNotifications}
      />

      <Menu
        anchorEl={anchorAdminEl}
        open={openAdminMenu}
        onClose={() => setOpenAdminMenu(false)}
      >
        <MenuItem onClick={() => navigate('/admin/config')}>Config</MenuItem>
        <MenuItem onClick={() => navigate('/admin/questions-setup')}>
          Questions Setup
        </MenuItem>
        <MenuItem onClick={() => navigate('/admin/inaccuracies-report')}>
          Inaccuracies Report
          <Chip
            sx={{ ml: 1 }}
            color="error"
            label={reportInaccuraciesCount}
            size="small"
          />
        </MenuItem>
        <MenuItem onClick={() => navigate('/admin/review-questions')}>
          Review Questions
          <Chip
            sx={{ ml: 1 }}
            color="success"
            label={unreviewedQuestions}
            size="small"
          />
        </MenuItem>
        {/* <MenuItem onClick={handleAdminArenaScheduleClick}>
          Pub Night Schedule
        </MenuItem> */}
      </Menu>
    </>
  );
};
