import {
  Typography,
  Stack,
  Avatar,
  Tooltip,
  useTheme,
  Chip,
  Box,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DailiesStreaks, Winner } from '../types';
import { getAvatarSrc } from '../../../api/cloudinary';
import { InfoOutlined } from '@mui/icons-material';
import { CustomTooltip } from '../../../components/custom-tooltip/CustomTooltip';
import { STREAKS_TOOLTIPS } from './constants';

type PropTypes = {
  dailiesStreaks: DailiesStreaks;
  streakCategory: 'played' | 'timeouts' | 'scratches' | 'parBirdieEagle';
};

const StreaksList = ({ dailiesStreaks, streakCategory }: PropTypes) => {
  const theme = useTheme();
  const [leaders, setLeaders] = useState<Winner[]>();
  const [chasers, setChasers] = useState<Winner[]>();
  const [title, setTitle] = useState<string>('');
  const [allTimeStat, setAllTimeStat] = useState<number>();

  useEffect(() => {
    if (streakCategory === 'played') {
      setLeaders(dailiesStreaks?.usersPlayedTop);
      setChasers(dailiesStreaks?.usersPlayedChasers);
      setTitle('Played');
      setAllTimeStat(
        dailiesStreaks?.usersPlayedTop[0]?.dailiesStats
          .consecutiveDailiesPlayedAllTime
      );
    }
    if (streakCategory === 'timeouts') {
      setLeaders(dailiesStreaks?.usersNoTimeOutTop);
      setChasers(dailiesStreaks?.usersNoTimeOutChasers);
      setTitle('No Timeouts');
      setAllTimeStat(
        dailiesStreaks?.usersNoTimeOutTop[0]?.dailiesStats
          .consecutiveDailiesNoTimeOutsAllTime
      );
    }
    if (streakCategory === 'scratches') {
      setLeaders(dailiesStreaks?.usersParOrBetterTop);
      setChasers(dailiesStreaks?.usersParOrBetterChasers);
      setTitle('Scratches');
      setAllTimeStat(
        dailiesStreaks?.usersParOrBetterTop[0]?.dailiesStats
          .consecutiveDailiesParOrBetterAllTime
      );
    }
    if (streakCategory === 'parBirdieEagle') {
      setLeaders(dailiesStreaks?.users012Top);
      setChasers(dailiesStreaks?.users012Chasers);
      setTitle('0-1-2');
      setAllTimeStat(
        dailiesStreaks?.users012Top[0]?.dailiesStats
          .consecutiveDailies012AllTime
      );
    }
  }, [streakCategory]);

  return (
    <Box px={0.5} position="relative">
      <CustomTooltip title={STREAKS_TOOLTIPS[streakCategory]} placement="top">
        <InfoOutlined
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        />
      </CustomTooltip>
      <Typography
        fontWeight={600}
        mb={1}
        sx={{
          textShadow:
            theme.palette.mode === 'dark'
              ? `0 0 0px ${theme.palette.dailies.main}, 0 0 0px ${theme.palette.dailies.main}, 0px 0px 16px ${theme.palette.dailies.main}`
              : 'none',
        }}
      >
        {title}
      </Typography>
      <Stack spacing={2}>
        <Typography fontWeight={600}>All Time</Typography>
        <Typography
          variant="h5"
          sx={{
            textShadow:
              theme.palette.mode === 'dark'
                ? `0 0 0px ${theme.palette.dailies.main}, 0 0 0px ${theme.palette.dailies.main}, 0px 0px 16px ${theme.palette.dailies.main}`
                : 'none',
          }}
        >
          {allTimeStat || '?'}
        </Typography>
        <Stack
          direction={'row'}
          sx={{
            flexWrap: 'wrap',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {leaders?.length > 8 ? (
            <Chip
              variant="outlined"
              label={`${leaders?.length} Players`}
              color="primary"
            />
          ) : leaders?.length > 0 ? (
            leaders.map((u, i) => (
              <Tooltip key={i} title={u.username} placement="top">
                <Avatar
                  src={getAvatarSrc(u.avatarPublicId, undefined, 50, 50)}
                  variant="rounded"
                  sx={{ margin: '2px', height: 35, width: 35 }}
                />
              </Tooltip>
            ))
          ) : (
            <Avatar
              variant="rounded"
              sx={{ margin: '2px', height: 35, width: 35 }}
            >
              ?
            </Avatar>
          )}
        </Stack>
        <Stack
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography fontWeight={600} mb={2}>
            Current Chasers
          </Typography>
          <Stack spacing={0.5}>
            {chasers?.length
              ? chasers.map((u, i) => (
                  <Stack key={i} direction={'row'} spacing={2} width={'100%'}>
                    <Avatar
                      src={getAvatarSrc(u.avatarPublicId, undefined, 50, 50)}
                      variant="rounded"
                      sx={{
                        height: 25,
                        width: 25,
                      }}
                    />
                    <Typography
                      width={'80%'}
                      textAlign={'left'}
                      sx={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {u.username}
                    </Typography>
                    <Typography textAlign={'right'} width={'20%'}>
                      {streakCategory === 'played'
                        ? u.dailiesStats.consecutiveDailiesPlayedCurrent
                        : streakCategory === 'timeouts'
                        ? u.dailiesStats.consecutiveDailiesNoTimeOutsCurrent
                        : streakCategory === 'scratches'
                        ? u.dailiesStats.consecutiveDailiesParOrBetterCurrent
                        : streakCategory === 'parBirdieEagle'
                        ? u.dailiesStats.consecutiveDailies012Current
                        : null}
                    </Typography>
                  </Stack>
                ))
              : 'No chasers yet!'}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default StreaksList;
