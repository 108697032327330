import { useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer'; // Assuming you're using a library like this
import { useLoggedInUserContext } from '../contexts/LoggedInUserProvider';
import { useProfileContext } from '../contexts/ProfileProvider';
import { useSocketContext } from '../contexts/SocketProvider';

// Assuming this is a constant value defined somewhere in your code
const FIVE_MINUTES_MS = 300000;

export const useIdleStatus = () => {
  const { profile } = useProfileContext();
  const { socket } = useSocketContext();

  // FIGURE THIS OUT: why is this rerendering when loggedInUsers updates??? Might try Zustand
  const { loggedInUser } = useLoggedInUserContext();

  const onIdle = useCallback(() => {
    if (!socket || !profile) return;
    socket.emit('idleUser', { profileId: profile._id, isIdle: true });
  }, [socket, profile]);

  const onActive = useCallback(() => {
    if (!socket || !profile) return;
    socket.emit('idleUser', { profileId: profile._id, isIdle: false });
  }, [socket, profile]);

  const onAction = useCallback(() => {
    if (!socket || !profile || !loggedInUser?.isIdle) return;
    socket.emit('idleUser', { profileId: profile._id, isIdle: false });
  }, [socket, profile, loggedInUser]);

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: FIVE_MINUTES_MS,
    throttle: 500,
  });
};
