import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  Tooltip,
  TableHead,
  Avatar,
  Stack,
  Box,
  styled,
  tooltipClasses,
  TooltipProps,
  tableCellClasses,
  Chip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveIcon from '@mui/icons-material/Remove';
import { BsCloudRainHeavy } from 'react-icons/bs';
import { GiCakeSlice } from 'react-icons/gi';
import { BsAsterisk } from 'react-icons/bs';
import { sum, sumBy } from 'lodash';
import { getAvatarSrc, resizeImage } from '../../api/cloudinary';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StarIcon from '@mui/icons-material/Star';
import { RoundScore } from '../../types/player';
import { DailiesAuthor, DailiesResult } from './types';
import { LockOutlined } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    position: 'relative',
    borderLeft: `1px solid #4c4e50`,
  },
}));

const PostitionedTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    position: 'relative',
    paddingLeft: 0,
    paddingRight: 40,
  },
}));

const PaddedLeftTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    paddingLeft: 40,
  },
}));

const NoPaddedLeftTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    paddingLeft: 0,
  },
}));

const TopScoresTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export type DailiesQuestionStat = {
  bestScores: { profileId: string; score: number; username: string }[];
  questionNumber: number;
  averageScore: number;
  medianScore: number;
};

type PropTypes = {
  dailiesAuthor: DailiesAuthor;
  dailiesQuestionStats?: DailiesQuestionStat[];
  dailiesResults?: DailiesResult[]; // using for avatarUrls
  isAdmin: boolean;
  missedDaily?: boolean;
  playerDailyScores?: Record<number, RoundScore>;
  profileId: string;
};

export const CommunityStats = ({
  dailiesAuthor,
  dailiesQuestionStats,
  dailiesResults,
  isAdmin,
  missedDaily,
  playerDailyScores,
  profileId,
}: PropTypes) => {
  const [hardestQuestions, setHardestQuestions] = useState<number[]>();
  const [easiestQuestions, setEasiestQuestions] = useState<number[]>();
  const theme = useTheme();

  useEffect(() => {
    if (!dailiesQuestionStats) return;
    const medianScores = dailiesQuestionStats?.map((q) => q.medianScore);
    const lowestScore = Math.min(...medianScores);
    const highestScore = Math.max(...medianScores);
    const easiestQuestionNumbers: number[] = [];
    const hardestQuestionNumbers: number[] = [];
    medianScores.forEach((val, i) => {
      if (val === highestScore) {
        hardestQuestionNumbers.push(i);
      }
      if (val === lowestScore) {
        easiestQuestionNumbers.push(i);
      }
    });
    setEasiestQuestions(easiestQuestionNumbers);
    setHardestQuestions(hardestQuestionNumbers);
  }, [dailiesQuestionStats]);

  const getPlayerScoreNumber = (questionNumber: number) => {
    if (!playerDailyScores) return '--';
    const player = playerDailyScores[questionNumber];
    if (player === undefined) return '--';
    const score =
      player.score + (player.penalty || 0) + (player.fuzzyPenalty || 0);
    return score;
  };

  const getPlayerScore = (questionNumber: number) => {
    if (!playerDailyScores) return '--';
    const player = playerDailyScores[questionNumber];
    if (player === undefined) return '--';
    const score =
      player.score + (player.penalty || 0) + (player.fuzzyPenalty || 0);
    return `${score > 0 ? '+' : ''}${score}`;
  };

  const playerTotal = () => {
    if (!playerDailyScores) return null;
    const total = sumBy(
      Object.values(playerDailyScores),
      (o) => o.score + o.penalty + (o.fuzzyPenalty || 0)
    );
    return total;
  };

  const getTotalAverage = () => {
    if (!dailiesQuestionStats || dailiesQuestionStats[0]?.averageScore === null)
      return null;
    const avgSum = sum(dailiesQuestionStats.map((q) => q.averageScore));
    return `${avgSum > 0 ? '+' : ''}${avgSum.toFixed(1)}`;
  };

  const getTotalMedian = () => {
    if (!dailiesQuestionStats || dailiesQuestionStats[0]?.medianScore === null)
      return null;
    const medSum = +sum(dailiesQuestionStats.map((q) => q.medianScore)).toFixed(
      1
    );
    return medSum;
  };

  const getTotalTopScores = () => {
    if (
      !dailiesQuestionStats ||
      dailiesQuestionStats[0]?.bestScores.length === 0
    )
      return '--';
    const topScoreSum = sum(
      dailiesQuestionStats.map((q) => q.bestScores[0]?.score)
    );
    return `${topScoreSum > 0 ? '+' : ''}${topScoreSum}`;
  };

  const getDotTooltip = (questionNumber: number) => {
    if (!playerDailyScores) return '--';
    const player = playerDailyScores[questionNumber];
    const questionStat = dailiesQuestionStats?.find(
      (q) => q.questionNumber === questionNumber
    );
    if (!questionStat) return '--';
    const score = player.score + player.penalty + (player.fuzzyPenalty || 0);
    if (score <= questionStat?.bestScores[0]?.score) {
      return 'You are a top scorer for this question';
    }
    if (score === questionStat?.medianScore) {
      return 'You scored the median for this question';
    }
    if (score < questionStat?.medianScore) {
      return 'You scored better than the median for this question';
    }
    if (score > questionStat?.medianScore) {
      return 'You scored worse than the median for this question';
    }
    return '--';
  };

  const removeIcon = (
    <RemoveIcon
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'gray',
      }}
    />
  );

  const getArrowIcon = (questionNumber: number) => {
    if (!playerDailyScores) return removeIcon;
    const player = playerDailyScores[questionNumber];
    const questionStat = dailiesQuestionStats?.find(
      (q) => q.questionNumber === questionNumber
    );
    if (!questionStat) return <RemoveIcon />;
    const score = player.score + player.penalty + (player.fuzzyPenalty || 0);
    const topScoreUsers = dailiesQuestionStats[questionNumber].bestScores;
    if (
      questionStat?.medianScore === undefined ||
      questionStat?.medianScore === null
    )
      return <></>;
    if (score === topScoreUsers[0]?.score) {
      return (
        <StarIcon
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'yellow',
          }}
        />
      );
    }
    if (score === questionStat?.medianScore) {
      return removeIcon;
    }
    if (score < questionStat?.medianScore) {
      return (
        <KeyboardArrowDownIcon
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: theme.palette.success.main,
          }}
        />
      );
    }
    if (score > questionStat?.medianScore) {
      return (
        <KeyboardArrowUpIcon
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: theme.palette.error.main,
          }}
        />
      );
    }
    return removeIcon;
  };

  const getArrowIconTotal = (playerTotal: number, medianTotal: number) => {
    if (playerTotal === medianTotal) {
      return removeIcon;
    }
    if (playerTotal < medianTotal) {
      return (
        <KeyboardArrowDownIcon
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: theme.palette.success.main,
          }}
        />
      );
    }
    if (playerTotal > medianTotal) {
      return (
        <KeyboardArrowUpIcon
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: theme.palette.error.main,
          }}
        />
      );
    }
    return removeIcon;
  };

  const getAvatarPublicId = (
    profileId: string,
    width: number,
    height: number
  ) => {
    const player = dailiesResults?.find((e) => e.profileId === profileId);
    if (!player) return undefined;
    return getAvatarSrc(player.avatarPublicId, player.avatarUrl, width, height);
  };

  const getBestScores = (questionNumber: number) => {
    if (!dailiesQuestionStats) return null;
    const users = dailiesQuestionStats[questionNumber].bestScores;
    return (
      <>
        {users.length > 5 ? (
          <Stack direction="row" spacing={1}>
            <Typography
              variant="body1"
              textAlign={'right'}
              paddingRight={2}
              width={'34px'}
            >
              {users[0]?.score > 0 ? '+' : ''}
              {users[0]?.score}
            </Typography>
            <TopScoresTooltip
              title={bestScoreTooltipList(users)}
              placement="top"
            >
              <Chip
                label={`${users.length} players`}
                variant="outlined"
                size="small"
                color="primary"
              />
            </TopScoresTooltip>
            {getPlayerScoreNumber(questionNumber) === users[0]?.score ? (
              <>
                <ArrowBackIcon sx={{ color: 'gray' }} />
                <Avatar
                  src={getAvatarPublicId(profileId, 50, 50)}
                  variant="rounded"
                  sx={{
                    height: 25,
                    width: 25,
                  }}
                />
              </>
            ) : null}
          </Stack>
        ) : (
          <Stack direction="row" spacing={1}>
            {users.length ? (
              <Typography
                variant="body1"
                textAlign={'right'}
                paddingRight={2}
                width={'34px'}
              >
                {users[0]?.score > 0 ? '+' : ''}
                {users[0]?.score}
              </Typography>
            ) : null}
            {users.map((u, i) => (
              <TopScoresTooltip
                key={i}
                title={bestScoreTooltip(u.profileId, u.score, u.username)}
                placement="top"
              >
                <Avatar
                  src={
                    getAvatarPublicId(u.profileId, 50, 50) ||
                    u.username.charAt(0)
                  }
                  variant="rounded"
                  sx={{
                    height: 25,
                    width: 25,
                  }}
                />
              </TopScoresTooltip>
            ))}
          </Stack>
        )}
      </>
    );
  };

  const bestScoreTooltipList = (
    users: { profileId: string; score: number; username: string }[]
  ) => {
    return (
      <Stack spacing={1}>
        {users.map((u) => (
          <Stack direction="row" spacing={1}>
            <Avatar
              src={
                getAvatarPublicId(u.profileId, 50, 50) || u.username.charAt(0)
              }
              variant="rounded"
              sx={{
                height: 25,
                width: 25,
              }}
            />
            <Typography
              variant="subtitle1"
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '150px',
                color:
                  theme.palette.mode === 'light'
                    ? theme.palette.secondary.main
                    : theme.palette.text.primary,
                textShadow:
                  theme.palette.mode === 'dark'
                    ? `0 0 1px ${theme.palette.secondary.main}, 10px 0px 20px ${theme.palette.secondary.main}, 0px 0px 16px ${theme.palette.secondary.main}`
                    : 'none',
              }}
            >
              {u.username}
            </Typography>
            <Typography
              variant="subtitle1"
              textAlign="center"
              sx={{
                color:
                  theme.palette.mode === 'light'
                    ? theme.palette.dailies.main
                    : theme.palette.text.primary,
                textShadow:
                  theme.palette.mode === 'dark'
                    ? `0 0 1px ${theme.palette.dailies.main}, 10px 0px 20px ${theme.palette.dailies.main}, 0px 0px 16px ${theme.palette.dailies.main}`
                    : 'none',
              }}
            >
              {u.score > 0 ? '+' : ''}
              {u.score}
            </Typography>
          </Stack>
        ))}
      </Stack>
    );
  };

  const bestScoreTooltip = (
    profileId: string,
    score: number,
    username: string
  ) => {
    return (
      <Box>
        <Stack direction={'row'} spacing={1}>
          <Avatar
            src={getAvatarPublicId(profileId, 25, 25) || username.charAt(0)}
            variant="rounded"
            sx={{
              height: 25,
              width: 25,
            }}
          />
          <Typography
            variant="body1"
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: '150px',
              color:
                theme.palette.mode === 'light'
                  ? theme.palette.secondary.main
                  : theme.palette.text.primary,
              textShadow:
                theme.palette.mode === 'dark'
                  ? `0 0 1px ${theme.palette.secondary.main}, 10px 0px 20px ${theme.palette.secondary.main}, 0px 0px 16px ${theme.palette.secondary.main}`
                  : 'none',
            }}
          >
            {username}
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            sx={{
              color:
                theme.palette.mode === 'light'
                  ? theme.palette.dailies.main
                  : theme.palette.text.primary,
              textShadow:
                theme.palette.mode === 'dark'
                  ? `0 0 1px ${theme.palette.dailies.main}, 10px 0px 20px ${theme.palette.dailies.main}, 0px 0px 16px ${theme.palette.dailies.main}`
                  : 'none',
            }}
          >
            {score > 0 ? '+' : ''}
            {score}
          </Typography>
        </Stack>
      </Box>
    );
  };

  return (
    <>
      <Typography
        variant="h6"
        mb={2}
        sx={{
          color: theme.palette.text.primary,
          textShadow:
            theme.palette.mode === 'dark'
              ? `0 0 1px ${theme.palette.dailies.main}, 10px 0px 20px ${theme.palette.dailies.main}, 0px 0px 16px ${theme.palette.dailies.main}`
              : 'none',
        }}
      >
        Community Stats
      </Typography>
      <TableContainer component={Paper} sx={{ bgcolor: 'black' }}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Q#</Typography>
              </TableCell>
              {/* <NoPaddedLeftTableCell width={'2%'}>
                <Tooltip title="Average" placement="top">
                  <Typography textAlign={'right'}>Avg</Typography>
                </Tooltip>
              </NoPaddedLeftTableCell> */}
              <TableCell width={'2%'}>
                <Tooltip title="Median" placement="top">
                  <Typography textAlign={'right'}>Med</Typography>
                </Tooltip>
              </TableCell>
              <PaddedLeftTableCell width={'80%'}>
                <Tooltip title="Median" placement="top">
                  <Typography>Top Scores</Typography>
                </Tooltip>
              </PaddedLeftTableCell>
              <TableCell width={'5%'}>
                <Typography textAlign={'right'}>You</Typography>
                {missedDaily ? (
                  <Tooltip
                    title={missedDaily ? 'You missed this daily' : ''}
                    placement="left-end"
                  >
                    <Box>
                      <BsAsterisk
                        style={{
                          position: 'absolute',
                          top: 0,
                          color: theme.palette.warning.main,
                        }}
                      />
                    </Box>
                  </Tooltip>
                ) : null}
              </TableCell>
              <PostitionedTableCell width={'5%'}></PostitionedTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {playerDailyScores ||
            profileId === dailiesAuthor?.authorProfileId ||
            isAdmin ? (
              <>
                {dailiesQuestionStats?.map((q) => (
                  <TableRow hover key={q.questionNumber}>
                    <TableCell>
                      <Stack direction={'row'} spacing={2}>
                        <Typography variant="body1">
                          {+q.questionNumber + 1}.
                        </Typography>
                        {hardestQuestions?.includes(+q.questionNumber) ? (
                          <Tooltip
                            title="toughest question based on median"
                            placement="top"
                          >
                            <Typography sx={{ position: 'relative' }}>
                              <BsCloudRainHeavy
                                style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                  color: 'red',
                                }}
                              />
                            </Typography>
                          </Tooltip>
                        ) : null}
                        {easiestQuestions?.includes(+q.questionNumber) ? (
                          <Tooltip
                            title="easiest question based on median"
                            placement="top"
                          >
                            <Typography sx={{ position: 'relative' }}>
                              <GiCakeSlice
                                style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                  color: 'pink',
                                }}
                              />
                            </Typography>
                          </Tooltip>
                        ) : null}
                      </Stack>
                    </TableCell>
                    {playerDailyScores ||
                    profileId === dailiesAuthor?.authorProfileId ||
                    isAdmin ? (
                      <>
                        {/* <NoPaddedLeftTableCell>
                          <Typography variant="body1" textAlign={'right'}>
                            {q.averageScore > 0 ? '+' : ''}
                            {q.averageScore !== null
                              ? q.averageScore.toFixed(1)
                              : '--'}
                          </Typography>
                        </NoPaddedLeftTableCell> */}
                        <TableCell>
                          <Typography variant="body1" textAlign={'right'}>
                            {q.medianScore > 0 ? '+' : ''}
                            {q.medianScore !== null ? q.medianScore : '--'}
                          </Typography>
                        </TableCell>
                        <PaddedLeftTableCell>
                          {getBestScores(q.questionNumber)}
                        </PaddedLeftTableCell>
                        <StyledTableCell>
                          <Typography variant="body1" textAlign={'right'}>
                            {getPlayerScore(q.questionNumber)}
                          </Typography>
                        </StyledTableCell>
                        <PostitionedTableCell>
                          <Typography variant="body1">
                            {playerDailyScores ? (
                              <Tooltip title={getDotTooltip(q.questionNumber)}>
                                {getArrowIcon(q.questionNumber)}
                              </Tooltip>
                            ) : null}
                          </Typography>
                        </PostitionedTableCell>
                      </>
                    ) : null}
                  </TableRow>
                ))}
                {dailiesQuestionStats ? (
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="body1"
                        textAlign={'left'}
                        fontWeight={600}
                      >
                        Tot
                      </Typography>
                    </TableCell>
                    {/* <NoPaddedLeftTableCell>
                      <Typography
                        variant="body1"
                        textAlign={'right'}
                        fontWeight={600}
                      >
                        {dailiesQuestionStats.length ? getTotalAverage() : '--'}
                      </Typography>
                    </NoPaddedLeftTableCell> */}
                    <TableCell>
                      <Typography
                        variant="body1"
                        textAlign={'right'}
                        fontWeight={600}
                      >
                        {dailiesQuestionStats.length
                          ? `${
                              getTotalMedian() > 0 ? '+' : ''
                            }${getTotalMedian()}`
                          : '--'}
                      </Typography>
                    </TableCell>
                    <PaddedLeftTableCell>
                      <Typography
                        variant="body1"
                        textAlign={'left'}
                        fontWeight={600}
                      >
                        {dailiesQuestionStats.length
                          ? getTotalTopScores()
                          : '--'}
                      </Typography>
                    </PaddedLeftTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="body1"
                        textAlign={'right'}
                        fontWeight={600}
                      >
                        {playerDailyScores
                          ? `${playerTotal() > 0 ? '+' : ''}${playerTotal()}`
                          : '--'}
                      </Typography>
                    </StyledTableCell>
                    <PostitionedTableCell>
                      <Typography variant="body1">
                        {playerDailyScores && dailiesQuestionStats
                          ? getArrowIconTotal(playerTotal(), getTotalMedian())
                          : null}
                      </Typography>
                    </PostitionedTableCell>
                  </TableRow>
                ) : null}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={6} height={400}>
                  <Typography
                    textAlign={'center'}
                    display="flex"
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <LockOutlined color="warning" sx={{ mr: 1 }} />
                    Play the daily to see stats
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
