import React, { useState } from 'react';
import useFetchArenaSchedule from '../profile/ArenaProfile/hooks/useFetchArenaSchedule';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { CreateNewArenaScheduleDialog } from './dialogs/CreateNewArenaScheduleDialog';
import BasicLayout from '../../BasicLayout/BasicLayout';

const AdminArenaSchedule = () => {
  const { data: arenaScheduleData, refetch } = useFetchArenaSchedule();

  const [openNewArenaScheduleDialog, setOpenNewArenaScheduleDialog] =
    useState(false);

  return (
    <>
      <BasicLayout>
        <Grid container pt={3}>
          <Grid item md={8}>
            <Stack spacing={2}>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenNewArenaScheduleDialog(true)}
                >
                  Add New Pub Schedule
                </Button>
              </Box>

              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Pub Night</TableCell>
                      <TableCell>Pub Sets</TableCell>
                      <TableCell>Scheduled?</TableCell>
                      <TableCell>Starting At</TableCell>
                      <TableCell>Complete At</TableCell>
                      <TableCell>Updated At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {arenaScheduleData?.length ? (
                      arenaScheduleData
                        .sort((a, b) => b.startingAt - a.startingAt)
                        .map((sch, index) => (
                          <TableRow hover key={index}>
                            <TableCell>{sch.arenaNight}</TableCell>
                            <TableCell>
                              {sch?.arenaGames
                                ?.map((ag) => ag.arenaSet)
                                .join(', ')}
                            </TableCell>
                            <TableCell>
                              {sch.scheduled ? 'Yes' : 'No'}
                            </TableCell>
                            <TableCell>
                              {dayjs(sch.startingAt).format(
                                'YYYY-MM-DD hh:mm a'
                              )}
                            </TableCell>
                            <TableCell>
                              {sch.completedAt
                                ? dayjs(sch.completedAt).format(
                                    'YYYY-MM-DD hh:mm a'
                                  )
                                : '--'}
                            </TableCell>
                            <TableCell>
                              {dayjs(sch.updatedAt).format(
                                'YYYY-MM-DD hh:mm a'
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell align="center">
                          <Typography variant="body2">
                            No players joined yet
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Grid>
        </Grid>
      </BasicLayout>

      <CreateNewArenaScheduleDialog
        onClose={() => setOpenNewArenaScheduleDialog(false)}
        onCreatedNewSchedule={refetch}
        open={openNewArenaScheduleDialog}
      />
    </>
  );
};

export default AdminArenaSchedule;
