import React from 'react';
import { Typography, useTheme } from '@mui/material';

export const FlickeringBeta = () => {
  const theme = useTheme();

  return (
    <Typography
      position="absolute"
      data-text="U"
      letterSpacing={4}
      zIndex={1}
      sx={{
        top: 40,
        left: 130,
        textShadow: `
    1px 0px 4px ${theme.palette.primary.light}, 
    2px 0px 4px ${theme.palette.primary.light}, 
    3px 0px 4px ${theme.palette.primary.light},
    2px 0px 3px ${theme.palette.primary.light}, 
    2px 3px 15px ${theme.palette.primary.light}, 
    2px 0px 15px, 5px 0px 125px,
    20px 0vw 200px ${theme.palette.primary.light}, 
    40px 0vw 200px ${theme.palette.primary.light};`,
      }}
    >
      B<span className="flicker-slow">E</span>T
      <span className="flicker-fast">A</span>
    </Typography>
  );
};
