import { ArenaTeam, ArenaTeamAdvancedStats } from '../pages/arena-team/types';
import {
  ArenaNightyAwards,
  ArenaNightyAwardsResponse,
} from '../pages/arena_old/ArenaNightlyAwards/types';
import {
  DailiesGames,
  DailiesSet,
  DailySetsPlayed,
} from '../pages/dailies_old/types';
import { PunchcardUser, RankedUser } from '../pages/home/MmrLeaderboard/types';
import {
  Punchcard,
  PunchcardGames,
} from '../pages/play-menus/PunchcardMenu/types';
import {
  ArenaAchievement,
  ArenaCurrentSeason,
  ArenaLadderPlayer,
  ArenaOverview,
  ArenaSchedule,
  ArenaUserAchievements,
  TrueSkill,
  ArenaAdvancedStats,
  ArenaPlayer,
} from '../pages/profile/ArenaProfile/types';
import { Achievement } from '../types/achievement';
import { Game } from '../types/game';
import { Profile } from '../types/player';
import apiClient from './apiClient';
import { API_ROUTES } from './apiRoutes';
import {
  FetchAccountExistsResponse,
  FetchAnsweredQuestionsResponse,
  FetchDailiesOverviewResponse,
  FetchGeocodeResponse,
  FetchLeagueTeamsResponse,
  FetchLobbyGamesResponse,
  FetchNotifcationResponse,
  FetchPlayerProfileResponse,
  FetchPredictionResponse,
  FetchProfileResponse,
  FetchScheduleGamesResponse,
  FetchSchedulesResponse,
  FetchTeamProfileResponse,
  FetchUpdatePlayMenuSettingsResponse,
} from './response-types/responseTypes';
import {
  ApproveDenyJoinTeamRequestBody,
  ApproveDenyLeagueTeamBody,
  BaseLeagueTeamBody,
  CheckoutSessionBody,
  CreateLeagueTeamBody,
  FetchProfilesBody,
  GenerateLeagueScheduleBody,
  SendTeamEmailInvite,
  UpdateGameSettingsBody,
  UpdatePlayMenuSettingsBody,
  UpdateTeamBody,
  UpdateUserLocationBody,
} from './types';

export const postLogout = async () => {
  const res = await apiClient.post(API_ROUTES.logout);
  return res.data;
};

export const createLeagueTeam = (body: CreateLeagueTeamBody) =>
  apiClient.post(API_ROUTES.createLeagueTeam, body);

export const approveDenyLeagueTeam = (body: ApproveDenyLeagueTeamBody) =>
  apiClient.post(API_ROUTES.approveDenyLeagueTeam, body);

export const approveDenyJoinTeamRequest = (
  body: ApproveDenyJoinTeamRequestBody
) => apiClient.post(API_ROUTES.approveDenyJoinTeamRequest, body);

export const fetchProfileAnsweredQuestions = async ({
  queryKey,
}): Promise<FetchAnsweredQuestionsResponse> => {
  const [, { profileId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchProfileAnsweredQuestions}/${profileId}`
  );
  return res.data;
};

export const fetchSeasonSchedules = async ({
  queryKey,
}): Promise<FetchSchedulesResponse> => {
  const [, { season, leagueName, division }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchSeasonSchedules}/${season}/${leagueName}/${division}`
  );
  return res.data;
};

export const fetchWeekSchedules = async ({
  queryKey,
}): Promise<FetchSchedulesResponse> => {
  const [, { season, week }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchWeekSchedules}/${season}/${week}`
  );
  return res.data;
};

export const fetchScheduleGames = async ({
  queryKey,
}): Promise<FetchScheduleGamesResponse> => {
  const [, { scheduleId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchScheduleGames}/${scheduleId}`
  );
  return res.data;
};

export const fetchPlacesPredictions = async ({
  queryKey,
}): Promise<FetchPredictionResponse> => {
  const [, { input }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchPlacesPredictions}?input=${input}`
  );
  return res.data;
};

export const fetchPlacesGeocode = async ({
  queryKey,
}): Promise<FetchGeocodeResponse> => {
  const [, { placeId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchPlacesGeocode}/${placeId}`
  );
  return res.data;
};

export const fetchTeamAnsweredQuestions = async ({
  queryKey,
}): Promise<FetchAnsweredQuestionsResponse> => {
  const [, { teamId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchTeamAnsweredQuestions}/${teamId}`
  );
  return res.data;
};

export const fetchLeaguePlayerProfile = async ({
  queryKey,
}): Promise<FetchPlayerProfileResponse> => {
  const [, { profileId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchLeaguePlayerProfile}/${profileId}`
  );
  return res.data;
};

export const fetchLeagueTeams = async ({
  queryKey,
}): Promise<FetchLeagueTeamsResponse> => {
  const [, { season, leagueName, division }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchLeagueTeams}/${season}/${leagueName}/${division}`
  );
  return res.data;
};

export const fetchTeamProfile = async ({
  queryKey,
}): Promise<FetchTeamProfileResponse> => {
  const [, { teamId }] = queryKey;
  const res = await apiClient.get(`${API_ROUTES.fetchTeamProfile}/${teamId}`);
  return res.data;
};

export const fetchAccountExists = async ({
  queryKey,
}): Promise<FetchAccountExistsResponse> => {
  const [, { email }] = queryKey;
  const res = await apiClient.get(`${API_ROUTES.fetchAccountExists}/${email}`);
  return res.data;
};

export const fetchProfile = async (): Promise<FetchProfileResponse> => {
  const res = await apiClient.get(API_ROUTES.fetchProfile);
  return res.data;
};

export const fetchProfileById = async ({
  queryKey,
}): Promise<FetchProfileResponse> => {
  const [, { profileId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchProfileById}/${profileId}`
  );
  return res.data;
};

export const fetchProfiles = async (body: FetchProfilesBody) => {
  const res = await apiClient.post(API_ROUTES.fetchProfiles, body);
  return res.data;
};

export const postUpdateLeagueTeam = async (body: UpdateTeamBody) =>
  apiClient.post(API_ROUTES.updateLeagueTeam, body);

export const postJoinLeagueTeamRequest = async (body: BaseLeagueTeamBody) =>
  apiClient.post(API_ROUTES.joinLeagueTeamRequest, body);

export const sendTeamEmailInvite = (body: SendTeamEmailInvite) =>
  apiClient.post(API_ROUTES.sendEmailInvite, body);

export const updateUserLocation = (body: UpdateUserLocationBody) =>
  apiClient.post(API_ROUTES.updateUserLocation, body);

export const generateLeagueSchedule = (body: GenerateLeagueScheduleBody) =>
  apiClient.post(API_ROUTES.generateLeagueSchedules, body);

export const createCheckoutSession = async (body: CheckoutSessionBody) =>
  apiClient.post(API_ROUTES.createCheckoutSession, body);

export const fetchLobbyGames = async ({
  queryKey,
}): Promise<FetchLobbyGamesResponse> => {
  const [, { limit }] = queryKey;
  const res = await apiClient.get(`${API_ROUTES.fetchLobbyGames}/${limit}`);
  return res.data;
};

export const updatePlayMenuSettings = (
  body: UpdatePlayMenuSettingsBody
): Promise<FetchUpdatePlayMenuSettingsResponse> =>
  apiClient.post(API_ROUTES.updatePlayMenuSettings, body);

export const updateGameSettings = (
  body: UpdateGameSettingsBody
): Promise<FetchUpdatePlayMenuSettingsResponse> =>
  apiClient.post(API_ROUTES.updateGameSettings, body);

export const fetchNotifications = async ({
  queryKey,
}): Promise<FetchNotifcationResponse> => {
  const [, { profileId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchNotifications}/${profileId}`
  );
  return res.data;
};

export const fetchDailiesOverview = async ({
  queryKey,
}): Promise<FetchDailiesOverviewResponse> => {
  const [, { profileId, dailySet }] = queryKey;

  let url = `${API_ROUTES.fetchDailiesOverview}/${profileId}`;

  if (dailySet !== undefined && dailySet !== null) {
    url += `?dailySet=${encodeURIComponent(dailySet)}`;
  }

  const res = await apiClient.get(url);
  return res.data;
};

export const fetchDailiesSets = async (): Promise<DailiesSet[]> => {
  const res = await apiClient.get(`${API_ROUTES.fetchDailiesSets}`);
  return res.data;
};

export const fetchDailySetsPlayed = async ({
  queryKey,
}): Promise<DailySetsPlayed[]> => {
  const [, { profileId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchDailySetsPlayed}/${profileId}`
  );
  return res.data;
};

export const fetchCurrentDaily = async (): Promise<{
  currentDailySet: number;
  nextDailySchedule: number;
}> => {
  const res = await apiClient.get(`${API_ROUTES.fetchCurrentDaily}`);
  return res.data;
};

export const fetchDailiesGames = async ({
  queryKey,
}): Promise<DailiesGames> => {
  const [, { profileId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchDailiesGames}/${profileId}/10/0`
  );
  return res.data;
};

export const fetchArenaCurrentSeason =
  async (): Promise<ArenaCurrentSeason> => {
    const res = await apiClient.get(`${API_ROUTES.fetchArenaCurrentSeason}`);
    return res.data;
  };

export const fetchArenaOverview = async ({
  queryKey,
}): Promise<ArenaOverview> => {
  const [, { profileId, season }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchArenaOverview}/${profileId}/${season}`
  );
  return res.data;
};

export const fetchArenaSchedule = async (): Promise<ArenaSchedule[]> => {
  const res = await apiClient.get(`${API_ROUTES.fetchArenaSchedule}`);
  return res.data;
};

export const postArenaSchedule = async ({
  arenaNight,
  startingAt,
  arenaGames,
  timeLimit,
}) =>
  apiClient.post(API_ROUTES.postArenaSchedule, {
    arenaNight,
    startingAt,
    arenaGames,
    timeLimit,
  });

export const postArenaAchievementsShowcase = async ({
  profileId,
  achievement,
  showcaseSlot,
}) =>
  apiClient.post(API_ROUTES.updateArenaAchievementsShowcase, {
    profileId,
    achievement,
    showcaseSlot,
  });

export const fetchArenaLadder = async ({
  queryKey,
}): Promise<ArenaLadderPlayer[]> => {
  const [, { season }] = queryKey;
  const res = await apiClient.get(`${API_ROUTES.fetchArenaLadder}/${season}`);
  return res.data;
};

export const fetchArenaNightlyAwards = async ({
  queryKey,
}): Promise<ArenaNightyAwardsResponse> => {
  const [, { season, arenaNight }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchArenaNightlyAwards}/${season}/${arenaNight}`
  );
  return res.data;
};

export const fetchArenaNightGames = async ({ queryKey }): Promise<Game[]> => {
  const [, { arenaNight }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchArenaNightGames}/${arenaNight}`
  );
  return res.data;
};

export const fetchArenaAchievements = async (): Promise<ArenaAchievement[]> => {
  const res = await apiClient.get(`${API_ROUTES.fetchArenaAchievements}`);
  return res.data;
};

export const fetchArenaTeams = async ({ queryKey }): Promise<ArenaTeam[]> => {
  const [, { season }] = queryKey;
  const res = await apiClient.get(`${API_ROUTES.fetchArenaTeams}/${season}`);
  return res.data;
};

export const fetchArenaTeamsWithProfiles = async ({
  queryKey,
}): Promise<{
  arenaTeams: ArenaTeam[];
  arenaPlayers: ArenaPlayer[];
}> => {
  const [, { season }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchArenaTeamsWithProfiles}/${season}`
  );
  return res.data;
};

export const fetchUserArenaAchievements = async ({
  queryKey,
}): Promise<{
  arenaAchievements: ArenaAchievement[];
  arenaUserAchievements: ArenaUserAchievements;
}> => {
  const [, { profileId, season }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchUserArenaAchievements}/${profileId}/${season}`
  );
  return res.data;
};

export const fetchArenaGames = async ({ queryKey }): Promise<Game[]> => {
  const [, { profileId, season }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchArenaGames}/${profileId}/${season}`
  );
  return res.data;
};

export const fetchArenaAdvancedStats = async ({
  queryKey,
}): Promise<ArenaAdvancedStats> => {
  const [, { profileId, season }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchArenaAdvancedStats}/${profileId}/${season}`
  );
  return res.data;
};

export const fetchArenaTeamAdvancedStats = async ({
  queryKey,
}): Promise<ArenaTeamAdvancedStats> => {
  const [, { teamId, season }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchArenaTeamAdvancedStats}/${teamId}/${season}`
  );
  return res.data;
};

export const updateArenaLadder = ({ season }) =>
  apiClient.post(API_ROUTES.updateArenaLadder, { season });

export const fetchArenaCategoryQuestions = ({ questionIds }) =>
  apiClient.post(API_ROUTES.fetchArenaCategoryQuestions, { questionIds });

export const fetchArenaCurrentTiers = ({ profileIds, season }) =>
  apiClient.post(API_ROUTES.fetchArenaCurrentTiers, { profileIds, season });

export const updateJoinArenaTeam = ({ teamId, teamName, profileId, season }) =>
  apiClient.post(API_ROUTES.updateJoinArenaTeam, {
    teamId,
    teamName,
    profileId,
    season,
  });

export const fetchArenaTeam = async ({
  queryKey,
}): Promise<{ arenaTeam: ArenaTeam; arenaPlayers: ArenaPlayer[] }> => {
  const [, { teamId }] = queryKey;
  const res = await apiClient.get(`${API_ROUTES.fetchArenaTeam}/${teamId}`);
  return res.data;
};

export const fetchArenaMmrResults = async ({
  queryKey,
}): Promise<TrueSkill[]> => {
  const [, { gameId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchArenaMmrResults}/${gameId}`
  );
  return res.data;
};

export const getMmrRankings = async (): Promise<RankedUser[]> => {
  const res = await apiClient.get(`${API_ROUTES.fetchMmrRankings}`);
  return res.data;
};

export const getPunchcardRankings = async (): Promise<PunchcardUser[]> => {
  const res = await apiClient.get(`${API_ROUTES.fetchPunchcardRankings}`);
  return res.data;
};

export const getPunchcard = async ({ queryKey }): Promise<Punchcard> => {
  const [, { profileId }] = queryKey;
  const res = await apiClient.get(`${API_ROUTES.fetchPunchcard}/${profileId}`);
  return res.data;
};

export const getPunchcardGames = async ({
  queryKey,
}): Promise<PunchcardGames> => {
  const [, { profileId, categorySet }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchPunchcardGames}/${profileId}/${categorySet}`
  );
  return res.data;
};

export const postCategoryBans = ({ profileId, categoryBans }) =>
  apiClient.post(API_ROUTES.postCategoryBans, {
    profileId,
    categoryBans,
  });

export const getAllAchievements = async (): Promise<Achievement[]> => {
  const res = await apiClient.get(`${API_ROUTES.getAllAchievements}`);
  return res.data;
};
