import { Chip, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';
import { DAILIES_THEMES } from '../constants';
import { getPacificTime } from '../../../utils/date-time';
import { DAILY_DAYS_OF_WEEK } from './constants';
import { isMobile } from 'react-device-detect';

const themeTooltip = (description: string) => {
  return <Typography variant="caption">{description}</Typography>;
};

const DailiesTitle = () => {
  const theme = useTheme();

  const dayOfWeekAbbr = getPacificTime().format('ddd');

  const chips = isMobile
    ? DAILY_DAYS_OF_WEEK.filter((data) => data.label === dayOfWeekAbbr)
    : DAILY_DAYS_OF_WEEK;

  return (
    <Stack spacing={2}>
      <Typography
        variant="h3"
        textAlign="center"
        sx={{
          color:
            theme.palette.mode === 'light'
              ? theme.palette.dailies.main
              : theme.palette.text.primary,
          textShadow:
            theme.palette.mode === 'dark'
              ? `0 0 1px ${theme.palette.dailies.main}, 10px 0px 20px ${theme.palette.dailies.main}, 0px 0px 16px ${theme.palette.dailies.main}`
              : 'none',
        }}
      >
        Dailies
      </Typography>
      <Stack
        direction={'row'}
        spacing={2}
        mb={2}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {chips.map((data, i) => {
          return (
            <Tooltip
              key={i}
              title={themeTooltip(DAILIES_THEMES[data.label].description)}
              placement="top"
            >
              <Chip
                variant={'outlined'}
                label={
                  dayOfWeekAbbr === data.label
                    ? DAILIES_THEMES[dayOfWeekAbbr].theme
                    : data.label
                }
                sx={{
                  borderColor:
                    dayOfWeekAbbr === data.label
                      ? theme.palette.mode === 'light'
                        ? theme.palette.dailies.main
                        : theme.palette.text.primary
                      : 'none',
                  color:
                    theme.palette.mode === 'light'
                      ? theme.palette.dailies.main
                      : theme.palette.text.primary,
                }}
              />
            </Tooltip>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default DailiesTitle;
