import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGameContext } from '../../contexts/GameProvider';
import { useIdleStatus } from '../../hooks/useIdleStatus';
import { PlayMenu } from '../../components/play-menu';
import { useProfileContext } from '../../contexts/ProfileProvider';
import { useLobbyGamesContext } from '../../contexts/LobbyGamesProvider';
import { CreateGameConfig } from '../../types/game';

export const GlobalActions = () => {
  const navigate = useNavigate();

  const { profile } = useProfileContext();

  const {
    createGameConfig,
    maintenance,
    openPlayMenu,
    setOpenPlayMenu,
    openPlayMenu2,
    setOpenPlayMenu2,
    handlePlayClick,
    setCreateGameConfig,
  } = useLobbyGamesContext();

  const {
    allPlayersReady,
    gameId,
    gameDataFromServer,
    setShowPregame,
    setShowGameChat,
    setConfirmReady,
  } = useGameContext();

  useIdleStatus();

  useEffect(() => {
    if (allPlayersReady !== gameId) return;
    setShowPregame(false);
    setShowGameChat(false);
    setConfirmReady(null);
    const { mode, arenaSet } = gameDataFromServer;
    navigate('/game-loading', {
      state: { fromQuickPlay: !arenaSet, fromArena: !!arenaSet },
    });
  }, [allPlayersReady]);

  const handleCreateGameConfigUpdate = (opts: CreateGameConfig) => {
    setCreateGameConfig(opts);
  };

  const diabledPlayButton =
    createGameConfig?.categorySet === 'none' &&
    createGameConfig?.categorySelections.length === 0;

  return (
    <>
      {/* {profile && (
        <PlayMenu
          closePlayMenu={() => setOpenPlayMenu(false)}
          demoCount={5}
          disablePlayButton={diabledPlayButton}
          handleCreateGameConfigUpdate={handleCreateGameConfigUpdate}
          maintenance={maintenance}
          onCategoryBanClick={() => {}}
          onPlayClick={handlePlayClick}
          onViewSearchPoolClick={() => {}}
          openPlayMenu={openPlayMenu}
          profile={profile}
        />
      )} */}
    </>
  );
};
