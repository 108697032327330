import { useQuery } from '@tanstack/react-query';
import { fetchArenaMmrResults } from '../api/apiServices';

const useFetchArenaMmrResults = (gameId: string, shouldFetch?: boolean) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['arenaMmrResults', { gameId }],
    queryFn: fetchArenaMmrResults,
    enabled: !!gameId && shouldFetch,
  });

  return {
    refetch,
    isLoading,
    data,
  };
};

export default useFetchArenaMmrResults;
