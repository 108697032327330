import React, { useMemo, useState } from 'react';
import {
  Box,
  Grid,
  LinearProgress,
  Pagination,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useProfileContext } from '../../contexts/ProfileProvider';
import LobbyTable from './LobbyTable';
import PlayButton from './PlayButton';
import { useLoggedInUserContext } from '../../contexts/LoggedInUserProvider';
import PlayMenuDialog from './dialogs/PlayMenuDialog';
import { FetchUpdatePlayMenuSettingsResponse } from '../../api/response-types/responseTypes';
import { useQuickPlayGamesContext } from '../../contexts/QuickPlayGamesProvider';
import BasicLayout from '../../BasicLayout/BasicLayout';

const LobbyPage = () => {
  const theme = useTheme();

  const { profile, mutateProfile } = useProfileContext();
  const { loggedInUser } = useLoggedInUserContext();

  const { quickPlayGamesSorted } = useQuickPlayGamesContext();

  const [openPlayMenuDialog, setOpenPlayMenuDialog] = useState(false);

  const handleUpdatePlayMenuSettings = (
    data: FetchUpdatePlayMenuSettingsResponse
  ) => {
    const profile = data.data.profile;
    mutateProfile(profile);
  };

  const [pageNum, setPageNum] = useState(1);

  const start = 10 * pageNum - 10;
  const end = 10 * pageNum;

  const quickPlayGamesPaginated = useMemo(
    () => quickPlayGamesSorted?.slice(start, end),
    [quickPlayGamesSorted, start, end]
  );

  const handlePaginateOnChange = (event: any, pageNum: number) => {
    setPageNum(pageNum);
  };

  return (
    <>
      <BasicLayout>
        <Grid container spacing={3}>
          <Grid item sm={12} md={12} pb={10}>
            <Stack
              direction={'row'}
              mb={1}
              justifyContent={'space-between'}
              alignItems={'end'}
            >
              <Typography variant={'h5'} fontWeight={600} color="secondary">
                Live Multiplayer
              </Typography>
              <Pagination count={10} onChange={handlePaginateOnChange} />
            </Stack>

            {quickPlayGamesSorted ? (
              <LobbyTable
                lobbyGamesPaged={quickPlayGamesPaginated}
                profile={profile}
              />
            ) : (
              <Stack>
                <Typography textAlign={'center'} mt={5} mb={5}>
                  Loading lobby...
                </Typography>
                <LinearProgress />
              </Stack>
            )}
          </Grid>
        </Grid>
      </BasicLayout>
      {/* 
      <PlayMenuDialog
        close={() => setOpenPlayMenuDialog(false)}
        open={openPlayMenuDialog}
        profile={profile}
        onUpdatePlayMenuSettings={handleUpdatePlayMenuSettings}
      /> */}
    </>
  );
};

export default LobbyPage;
