import React, { FC, ReactNode } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { Socket } from 'socket.io-client';
import { useSocket } from '../hooks/useSocket';
import {
  socketGameloopPath,
  socketInputPath,
  socketManagerPath,
  socketSpectatePath,
} from '../sockets/sockets';

interface SocketContextType {
  connectSocket: () => void;
  disconnectSocket: () => void;
  isSocketConnected: boolean;
  socket: Socket | null;
  connectInputSocket: () => void;
  disconnectInputSocket: () => void;
  isInputSocketConnected: boolean;
  inputSocket: Socket | null;
  connectGameloopSocket: () => void;
  disconnectGameloopSocket: () => void;
  isGameloopSocketConnected: boolean;
  gameloopSocket: Socket | null;
  connectSpectateSocket: () => void;
  disconnectSpectateSocket: () => void;
  isSpectateSocketConnected: boolean;
  spectateSocket: Socket | null;
}

export const SocketContext = createContext<SocketContextType>(null!); // Note: the assertion `null!` is used for initial value.

type SocketProviderProps = {
  children: ReactNode;
};

export const SocketProvider: FC<SocketProviderProps> = ({ children }) => {
  const isProduction =
    process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'production';

  const managerSocketUrl = isProduction
    ? process.env.REACT_APP_SOCKET
    : 'http://localhost:4000';
  const gameloopSocketUrl = isProduction
    ? process.env.REACT_APP_SOCKET
    : 'http://localhost:4005';
  const inputSocketUrl = isProduction
    ? process.env.REACT_APP_SOCKET
    : 'http://localhost:4001';
  const spectateSocketUrl = isProduction
    ? process.env.REACT_APP_SOCKET
    : 'http://localhost:4004';

  const { connectSocket, disconnectSocket, isSocketConnected, socket } =
    useSocket(managerSocketUrl, {
      path: socketManagerPath,
      transports: ['websocket'],
    });

  const {
    connectSocket: connectInputSocket,
    disconnectSocket: disconnectInputSocket,
    isSocketConnected: isInputSocketConnected,
    socket: inputSocket,
  } = useSocket(inputSocketUrl, {
    path: socketInputPath,
    transports: ['websocket'],
  });

  const {
    connectSocket: connectGameloopSocket,
    disconnectSocket: disconnectGameloopSocket,
    isSocketConnected: isGameloopSocketConnected,
    socket: gameloopSocket,
  } = useSocket(gameloopSocketUrl, {
    path: socketGameloopPath,
    transports: ['websocket'],
  });

  const {
    connectSocket: connectSpectateSocket,
    disconnectSocket: disconnectSpectateSocket,
    isSocketConnected: isSpectateSocketConnected,
    socket: spectateSocket,
  } = useSocket(spectateSocketUrl, {
    path: socketSpectatePath,
    transports: ['websocket'],
  });

  return (
    <SocketContext.Provider
      value={{
        connectSocket,
        disconnectSocket,
        isSocketConnected,
        socket,
        connectInputSocket,
        disconnectInputSocket,
        isInputSocketConnected,
        inputSocket,
        connectGameloopSocket,
        disconnectGameloopSocket,
        isGameloopSocketConnected,
        gameloopSocket,
        connectSpectateSocket,
        disconnectSpectateSocket,
        isSpectateSocketConnected,
        spectateSocket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  const connectSocket = useContextSelector(
    SocketContext,
    (ctx) => ctx.connectSocket
  );
  const disconnectSocket = useContextSelector(
    SocketContext,
    (ctx) => ctx.disconnectSocket
  );
  const isSocketConnected = useContextSelector(
    SocketContext,
    (ctx) => ctx.isSocketConnected
  );
  const socket = useContextSelector(SocketContext, (ctx) => ctx.socket);

  return { connectSocket, disconnectSocket, isSocketConnected, socket };
};

export const useInputSocketContext = () => {
  const connectInputSocket = useContextSelector(
    SocketContext,
    (ctx) => ctx.connectInputSocket
  );
  const disconnectInputSocket = useContextSelector(
    SocketContext,
    (ctx) => ctx.disconnectInputSocket
  );
  const isInputSocketConnected = useContextSelector(
    SocketContext,
    (ctx) => ctx.isInputSocketConnected
  );
  const inputSocket = useContextSelector(
    SocketContext,
    (ctx) => ctx.inputSocket
  );

  return {
    connectInputSocket,
    disconnectInputSocket,
    isInputSocketConnected,
    inputSocket,
  };
};

export const useSpectateSocketContext = () => {
  const connectSpectateSocket = useContextSelector(
    SocketContext,
    (ctx) => ctx.connectSpectateSocket
  );
  const disconnectSpectateSocket = useContextSelector(
    SocketContext,
    (ctx) => ctx.disconnectSpectateSocket
  );
  const isSpectateSocketConnected = useContextSelector(
    SocketContext,
    (ctx) => ctx.isSpectateSocketConnected
  );
  const spectateSocket = useContextSelector(
    SocketContext,
    (ctx) => ctx.spectateSocket
  );

  return {
    connectSpectateSocket,
    disconnectSpectateSocket,
    isSpectateSocketConnected,
    spectateSocket,
  };
};

export const useGameloopSocketContext = () => {
  const connectGameloopSocket = useContextSelector(
    SocketContext,
    (ctx) => ctx.connectGameloopSocket
  );
  const disconnectGameloopSocket = useContextSelector(
    SocketContext,
    (ctx) => ctx.disconnectGameloopSocket
  );
  const isGameloopSocketConnected = useContextSelector(
    SocketContext,
    (ctx) => ctx.isGameloopSocketConnected
  );
  const gameloopSocket = useContextSelector(
    SocketContext,
    (ctx) => ctx.gameloopSocket
  );

  return {
    connectGameloopSocket,
    disconnectGameloopSocket,
    isGameloopSocketConnected,
    gameloopSocket,
  };
};
