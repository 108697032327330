import {
  Grow,
  Stack,
  Chip,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import React from 'react';

type Props = {
  category: string;
  par: number;
  displayCategoryAndPar: boolean;
};

const ParAndCategoryDisplay = ({
  category,
  par,
  displayCategoryAndPar,
}: Props) => {
  const theme = useTheme();

  const smAndDownBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grow in={displayCategoryAndPar} timeout={1500}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        marginTop={1}
      >
        <Chip
          label={category || 'None'}
          color="info"
          variant="outlined"
          size={smAndDownBreakpoint ? 'small' : 'medium'}
          sx={{
            width: 'fit-content',
            fontSize: { xs: 'small', md: 'medium' },
            alignSelf: 'center',
            display: { xs: 'none', sm: 'flex' },
          }}
        />
        <Chip
          label={
            <Typography>
              PAR <strong>{par}</strong>
            </Typography>
          }
          color="info"
          variant="outlined"
          size={smAndDownBreakpoint ? 'small' : 'medium'}
          sx={{
            boxShadow: `${theme.palette.primary.main} 0px 0px 5px -1px, ${theme.palette.primary.main} 0px 0px 1px 0px, ${theme.palette.primary.main} 0px 0px 0px 0px`,
            width: 'fit-content',
            fontSize: { xs: 'small', md: 'medium' },
            alignSelf: 'center',
            display: { xs: 'none', sm: 'flex' },
          }}
        />
      </Stack>
    </Grow>
  );
};

export default ParAndCategoryDisplay;
