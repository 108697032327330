import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Stack, Avatar, useTheme } from '@mui/material';
import React from 'react';
import { ExpandIcon } from '.';
import { useNavigate } from 'react-router-dom';
import PlayersOnlineCount from './PlayersOnlineCount';
import { LoggedInUser } from '../../types/user';
import { Profile } from '../../types/player';
import { getAvatarSrc } from '../../api/cloudinary';

type Props = {
  loggedInUsers: LoggedInUser[];
  openMessagesDrawer: boolean;
  profile: Profile;
};

const WhosOnlineHeader = ({
  loggedInUsers,
  openMessagesDrawer,
  profile,
}: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleAvatarClick = (event) => {
    event.stopPropagation();
    navigate(`/profile/overview/${profile._id}`);
  };

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      spacing={2}
      width={'100%'}
    >
      <Avatar
        src={getAvatarSrc(
          profile?.avatarPublicId,
          profile?.avatarUrl,
          100,
          100
        )}
        onClick={handleAvatarClick}
        sx={{
          width: 32,
          height: 32,
          border: `1px solid ${theme.palette.primary.main}`,
          '&:hover': {
            boxShadow: `0px 0px 4px -1px ${theme.palette.primary.dark}, 0px 0px 5px 0px ${theme.palette.primary.dark}, 0px 0px 10px 0px ${theme.palette.primary.dark}`,
          },
        }}
      />

      {profile ? (
        <PlayersOnlineCount profile={profile} loggedInUsers={loggedInUsers} />
      ) : null}

      <ExpandIcon aria-label="expand">
        {openMessagesDrawer ? <ExpandMore /> : <ExpandLess />}
      </ExpandIcon>
    </Stack>
  );
};

export default WhosOnlineHeader;
