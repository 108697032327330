import {
  Box,
  Button,
  Card,
  CardActionArea,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import Countdown from 'react-countdown';
import { countdownRendererHours } from '../../utils/countdown';
import { getPacificTime } from '../../utils/date-time';
import { DAILY_DAYS_OF_WEEK } from '../dailies_old/DailiesTitle/constants';
import { DAILIES_THEMES } from '../dailies_old/constants';
import { useNavigate } from 'react-router-dom';
import NewspaperIcon from '@mui/icons-material/Newspaper';

type Props = {
  disableDaily: boolean;
  handlePlayDailyClick: (e: any) => void;
  nextDailySchedule: number;
};

const DailiesCard = ({
  disableDaily,
  handlePlayDailyClick,
  nextDailySchedule,
}: Props) => {
  const theme = useTheme();
  const betweenSmLgScreen = useMediaQuery(
    theme.breakpoints.between('sm', 'lg')
  );

  const navigate = useNavigate();

  const dayOfWeekAbbr = getPacificTime().format('ddd');

  const dailyData = DAILY_DAYS_OF_WEEK.find(
    (data) => data.label === dayOfWeekAbbr
  );

  return (
    <CardActionArea
      sx={{
        height: '100%',
        width: 360,
        border: '1px solid',
        borderColor: theme.palette.border,
        borderRadius: 1,
      }}
      onClick={() => navigate('/dailies')}
    >
      <Stack
        width={'100%'}
        height={'100%'}
        justifyContent={'center'}
        alignContent={'center'}
        p={2}
      >
        <Box
          pb={1}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignContent={'center'}
        >
          <NewspaperIcon />
        </Box>

        <Typography
          variant={betweenSmLgScreen ? 'body1' : 'h6'}
          textAlign={'center'}
          fontWeight={600}
          letterSpacing={2}
        >
          TODAY'S DAILY
        </Typography>
        <Box py={2}>
          <Typography textAlign={'center'}>
            {DAILIES_THEMES[dailyData.label].theme}
          </Typography>
          <Typography variant="subtitle2" textAlign={'center'} pt={1}>
            "{DAILIES_THEMES[dailyData.label].description}"
          </Typography>
        </Box>

        {disableDaily ? (
          <Stack>
            <Typography variant="caption" textAlign={'center'}>
              Next daily:
            </Typography>
            <Typography variant="h6" textAlign={'center'}>
              {nextDailySchedule ? (
                <Countdown
                  date={nextDailySchedule}
                  renderer={countdownRendererHours}
                />
              ) : (
                'N/A'
              )}
            </Typography>
          </Stack>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              variant="outlined"
              onClick={handlePlayDailyClick}
              color="secondary"
              size={betweenSmLgScreen ? 'small' : 'medium'}
            >
              PLAY DAILY
            </Button>
          </Box>
        )}
      </Stack>
    </CardActionArea>
  );
};

export default DailiesCard;
