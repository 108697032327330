import React, { memo, useState } from 'react';
import { ValorantButton } from '../pages/quick-play/ValorantButton/ValorantButton';
import { LoggedInUser } from '../types/user';
import { gameInProgress } from '../utils/loggedInUser';

type PropTypes = {
  disablePlayButton: boolean;
  onlinePlayer?: LoggedInUser;
  openPlayMenu: boolean;
  onPlayClick: (onlinePlayer?: LoggedInUser) => void;
  size?: 'small' | 'large';
};

const SideNavPlayButton = ({
  disablePlayButton,
  onlinePlayer,
  openPlayMenu,
  onPlayClick,
  size,
}: PropTypes) => {
  const [playClicked, setPlayClicked] = useState(false);

  const isGameInProgress = gameInProgress(onlinePlayer);

  const handlePlayClick = (onlinePlayer: LoggedInUser) => {
    if (openPlayMenu) {
      setPlayClicked(true);
    }
    onPlayClick(onlinePlayer);
  };

  return (
    <ValorantButton
      disabled={disablePlayButton || playClicked}
      label="PLAY"
      loading={playClicked}
      onClick={() => handlePlayClick(onlinePlayer)}
      size={size}
      showAnimation={!openPlayMenu && !isGameInProgress}
    />
  );
};

export default memo(SideNavPlayButton);
