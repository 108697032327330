import { Card, Stack, Box, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import { ArenaAchievement, ArenaUserAchievements } from '../types';
import AchievementCard from './AchievementCard';

type Props = {
  arenaAchievementsData: {
    arenaAchievements: ArenaAchievement[];
    arenaUserAchievements: ArenaUserAchievements;
  };
  canUpdateShowcase: boolean;
  handleAchievementClick?: () => void;
  profileId: string;
};

const ArenaAchievements = ({
  arenaAchievementsData,
  canUpdateShowcase,
  handleAchievementClick,
  profileId,
}: Props) => {
  const arenaAchievements = arenaAchievementsData?.arenaAchievements;
  const arenaUserAchievements = arenaAchievementsData?.arenaUserAchievements;
  const sortedArenaAchievements = arenaAchievements?.sort(
    (a, b) => a.sortOrder - b.sortOrder
  );
  const groupedArenaAchievements = sortedArenaAchievements?.sort(
    (a, b) => a.group - b.group
  );

  const [openRecentGamesAllDialog, setOpenRecentGamesAllDialog] =
    useState(false);

  const getUserAchievement = (achievement: string) => {
    return arenaUserAchievements?.achievements.find(
      (ach) => ach.achievement === achievement
    );
  };

  return (
    <>
      <Box pt={2}>
        <Typography py={1} fontWeight={500}>
          Achievements
        </Typography>

        <Stack
          direction={'row'}
          pt={2}
          px={2}
          alignContent={'center'}
          flexWrap={'wrap'}
          sx={{
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
          }}
        >
          {groupedArenaAchievements?.map((ach, i) => (
            <AchievementCard
              key={i}
              achievement={ach}
              handleAchievementClick={handleAchievementClick}
              canUpdateShowcase={canUpdateShowcase}
              userAchievement={getUserAchievement(ach.achievement)}
            />
          ))}
        </Stack>

        <Box display={'flex'} justifyContent={'right'} py={1} pr={1}>
          {/* <Button onClick={() => setOpenRecentGamesAllDialog(true)}>
              View All
            </Button> */}
        </Box>
      </Box>
      {/* 
      <RecentGamesAllDialog
        handleCardClick={viewScorecardDialog}
        open={openRecentGamesAllDialog}
        onClose={() => setOpenRecentGamesAllDialog(false)}
        trueSkillData={trueSkillData}
      /> */}
    </>
  );
};

export default ArenaAchievements;
