import { useApi } from '../useApi';
import { Methods } from './constants';
import { useEffect } from 'react';
import { useProfileContext } from '../../contexts/ProfileProvider';
import { Profile } from '../../types/player';

export const useResetPassword = () => {
  const { refetch } = useProfileContext();
  const { data, error, loading, sendRequest } = useApi();

  useEffect(() => {
    if (data && !error) {
      refetch();
    }
  }, [data, error, refetch]);

  const resetPassword = (token: string, newPassword: string) => {
    sendRequest({
      url: `/authenticate/reset-password/${token}`,
      method: Methods.POST,
      body: { password: newPassword },
    });
  };

  return { data, error, loading, resetPassword };
};
