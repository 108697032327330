import React from 'react';
import { ArenaPlayerGame, TrueSkill } from '../types';
import {
  Card,
  CardActionArea,
  Stack,
  Typography,
  Divider,
  useTheme,
  Badge,
  BadgeProps,
  styled,
} from '@mui/material';
import { utcToLocalDateShort } from '../../../../utils/date-time';
import { getPerformanceIcon } from './utils';
import { green } from '@mui/material/colors';
import { getOrdinalSuffix } from '../../../../utils/utils';

type Props = {
  handleCardClick: (gameId: string) => void;
  arenaPlayerGame: ArenaPlayerGame;
  isMostRecent?: boolean;
};

const RecentGamesCard = ({
  handleCardClick,
  arenaPlayerGame,
  isMostRecent,
}: Props) => {
  const theme = useTheme();
  return (
    // <Badge
    //   color="primary"
    //   badgeContent={isMostRecent ? 'New' : null}
    //   anchorOrigin={{
    //     vertical: 'top',
    //     horizontal: 'left',
    //   }}
    // >
    <Card
      sx={{
        bgcolor: '#060615',
        width: 100,
      }}
    >
      <CardActionArea onClick={() => handleCardClick(arenaPlayerGame.gameId)}>
        <Stack spacing={2} py={1} px={2}>
          <Stack justifyContent={'center'} alignContent={'center'}>
            <Typography textAlign={'center'} fontWeight={600}>
              {getOrdinalSuffix(arenaPlayerGame.placement)}
            </Typography>
            <Typography variant="caption" textAlign={'center'}>
              out of {arenaPlayerGame.totalPlayers}
            </Typography>
          </Stack>
          <Divider />
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Typography
              variant="h6"
              textAlign={'center'}
              fontWeight={600}
              color={
                arenaPlayerGame.teamResult === 'win'
                  ? green[400]
                  : arenaPlayerGame.teamResult === 'loss'
                  ? 'error'
                  : 'gray'
              }
            >
              {!arenaPlayerGame.teamResult
                ? 'N/A'
                : arenaPlayerGame.teamResult === 'win'
                ? 'W'
                : arenaPlayerGame.teamResult === 'loss'
                ? 'L'
                : 'T'}
            </Typography>
          </Stack>
          <Typography variant="caption" textAlign={'center'} color="grey">
            {utcToLocalDateShort(arenaPlayerGame.createdAt)}
          </Typography>
        </Stack>
      </CardActionArea>
    </Card>
    // </Badge>
  );
};

export default RecentGamesCard;
