import { Stack, InputLabel, Box, Typography, useTheme } from '@mui/material';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import { PrecisionGraphic } from './PrecisionGraphic';
import { PrecisionGraphicIcon } from './PrecisionGraphicIcons';

import { Mode } from '../../../../types/game';
import { Team, Teams } from '../../../../types/game';
import { Player } from '../../../../types/player';
import { PenaltyLockBar } from '../../../../components/penalty-lock-bar';

let isTeamCorrectAnswerTimeout;

type PropTypes = {
  answer?: string[][];
  fb?: boolean;
  fuzzyScore?: number;
  inputPlaceholder: string;
  inputRef?: RefObject<HTMLSpanElement>;
  inputText?: string;
  isAdmin?: boolean;
  liveInputCache?: Record<string, string>;
  mode: Mode;
  myTeam?: Team;
  onEnterKeyPress?: () => void; // optional so we can omit when spectate mode
  onPause?: () => void;
  onInputChange?: (input: string) => void;
  player: Player;
  playersCount?: number;
  preventInput?: boolean;
  locked?: boolean;
  questionIndex?: number;
  reverse?: boolean;
  spectateSocket?: Socket;
  spectate: boolean;
  teams?: Teams;
  timerLimitReached: boolean;
  updateLiveInputCache?: (liveInput: Record<string, string>) => void;
};

export const Inputter = ({
  answer,
  fb,
  fuzzyScore,
  inputPlaceholder,
  inputRef,
  inputText,
  isAdmin,
  liveInputCache,
  mode,
  myTeam,
  onEnterKeyPress,
  onPause,
  onInputChange,
  player,
  playersCount,
  locked,
  questionIndex,
  preventInput,
  reverse,
  spectateSocket,
  teams,
  timerLimitReached,
  spectate,
  updateLiveInputCache,
}: PropTypes) => {
  const theme = useTheme();
  const [clearTryAgain, setClearTryAgain] = useState(false);
  const [clearPrompt, setClearPrompt] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>();
  const [alertColor, setAlertColor] = useState<string>();
  const [submittedPromptAnswer, setSubmittedPromptAnswer] = useState<string>();
  const [answeredQuestionIndex, setAnsweredQuestionIndex] = useState(-1);

  // Answer Text when not spectating
  const inputTextRef = useRef<HTMLSpanElement>(null);
  const playerInputsRef = useRef<Record<string, string>>({});
  const profileIdRef = useRef<string>();
  const spectateInputRef = useRef<HTMLDivElement>(null);

  // listener for ENTER key press
  useEffect(() => {
    if (spectate) return;
    const listener = (e: any) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        e.preventDefault();
        onEnterKeyPress && onEnterKeyPress();
      }
      if (e.code === 'F9') {
        e.preventDefault();
        onPause && onPause();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  useEffect(() => {
    if (!spectateSocket) return;
    spectateSocket.on('playerInputLive', playerLiveInput);
    setTimeout(() => {
      if (liveInputCache && player && spectateInputRef.current) {
        spectateInputRef.current.innerText =
          liveInputCache[player.profileId] || '';
      } else if (spectateInputRef.current) {
        spectateInputRef.current.innerText = '';
      }
    }, 0);
  }, [spectateSocket]);

  const playerLiveInput = (liveInput: { profileId: string; input: string }) => {
    playerInputsRef.current[liveInput.profileId] = liveInput.input;
    updateLiveInputCache && updateLiveInputCache(liveInput);
    if (
      spectateInputRef.current &&
      profileIdRef.current &&
      playerInputsRef.current[profileIdRef.current]
    ) {
      spectateInputRef.current.innerText =
        playerInputsRef.current[profileIdRef.current];
    }
  };

  useEffect(() => {
    playerInputsRef.current = {};
    if (spectateInputRef.current) spectateInputRef.current.innerText = '';
    setSubmittedPromptAnswer(undefined);
  }, [questionIndex]);

  useEffect(() => {
    if (player) profileIdRef.current = player.profileId;
    if (player.incorrectAnswer) {
      setClearTryAgain(false);
    }
    if (player.isPromptAnswer) {
      setClearPrompt(false);
    }
  }, [player]);

  useEffect(() => {
    if (!myTeam) return;
    if (myTeam.correctAnswer || myTeam.timerLimitReached) {
      setClearTryAgain(true);
      setClearPrompt(true);
    }
  }, [myTeam]);

  useEffect(() => {
    if (player.correctAnswer && !player.fuzzyScore) {
      setAlertMessage('Nice putt!');
      setAlertColor('success.main');
      return;
    }
    if (!locked && player.incorrectAnswer && !clearTryAgain) {
      setAlertMessage('Try again');
      setAlertColor('error.main');
      return;
    }
    if (player.isPromptAnswer && !clearPrompt) {
      setAlertMessage('Be more specific... remaining part accepted');
      setAlertColor('warning.main');
      if (!player.incorrectAnswer) {
        setSubmittedPromptAnswer(inputText);
      }
      if (inputRef && inputRef.current) inputRef.current.textContent = '';
      return;
    }
    if (locked && !spectate) {
      setAlertMessage(
        mode === 'match'
          ? 'Your guess revealed to your opponent'
          : '1 point penalty'
      );
      setAlertColor('error.main');
      return;
    }
    setAlertMessage(undefined);
    setAlertColor(undefined);
  }, [player, clearTryAgain, clearPrompt, locked]);

  const showInput = () => {
    if (mode === 'teams') {
      if (myTeam && isTeamCorrectAnswer) {
        return !myTeam.correctAnswer && !timerLimitReached;
      } else {
        return true;
      }
    }
    return !player.correctAnswer && !timerLimitReached;
  };
  const isShowInputControl = !spectate && !!onInputChange && inputRef;

  const [isTeamCorrectAnswer, setIsTeamCorrectAnswer] = useState(false);

  const spectatingMatch = spectate && mode === 'sit-n-go' && playersCount === 2;
  const spectatingSng = spectate && mode === 'sit-n-go' && playersCount > 2;
  const spectatingTeams = spectate && mode === 'teams';
  const spectatingLargeMultiplayer = spectatingSng || spectatingTeams;

  useEffect(() => {
    if (!myTeam || spectate) return;
    if (
      myTeam.correctAnswer &&
      !timerLimitReached &&
      answeredQuestionIndex < questionIndex
    ) {
      setAnsweredQuestionIndex(questionIndex);
      clearTimeout(isTeamCorrectAnswerTimeout);
      setIsTeamCorrectAnswer(true);
      isTeamCorrectAnswerTimeout = setTimeout(() => {
        setIsTeamCorrectAnswer(false);
      }, 1000);
    }
  }, [myTeam]);

  useEffect(() => {
    if (isShowInputControl) {
      inputRef?.current?.focus();
    }
  }, [showInput]);

  const isWrongAnswerAndTimeLimitReached = () => {
    if (mode === 'teams' && myTeam) {
      return !myTeam.correctAnswer && timerLimitReached;
    }
    return !player.correctAnswer && timerLimitReached;
  };

  const isCorrectAnswer = () => {
    if (!spectate && mode === 'teams' && myTeam) {
      return myTeam.correctAnswer;
    }
    return player.correctAnswer;
  };

  const handleInputChange = (text: string) => {
    setClearTryAgain(true);
    setClearPrompt(true);
    onInputChange(text);
  };

  // This is important!!
  // Even though the answer checking on the server is very fast,
  //  a fast typist can type extra characters before the answer results are returned.
  //  This can cause confusion because what the user submitted is not what is displayed.
  //  So we must prevent input until results are returned
  const handleBeforeInput = (e: any) => {
    if (preventInput) {
      e.preventDefault();
    }
  };

  const handleInputBlur = () => {
    // setTimeout to prevent "Uncaught RangeError: Maximum call stack size exceeded"
    // When this event occurs, it immediately (after the current call stack is clear, thanks to setTimeout) refocuses the input.
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 0);
  };

  const showInputControlReadOnly = (
    <Typography paddingY="5px" paddingX="20px">
      <span
        ref={spectateInputRef}
        className="span-input"
        data-ph={inputPlaceholder}
        style={{
          color: player.correctAnswer ? theme.palette.success.main : null,
          fontWeight: 600,
          fontSize: spectatingLargeMultiplayer ? 18 : 24,
          overflowWrap: spectatingLargeMultiplayer ? 'anywhere' : 'unset',
          display: locked ? 'none' : 'inherit',
        }}
      ></span>
    </Typography>
  );

  // Note: Do not do anything that will remove the input span from the DOM, such as ternary-ing it with the "locked" flag
  // We do not want to remove the input span from the dom since it requires special behavior
  //  such as clearing the input after a penalty or not clearing
  // I can't think of a way right now to guarantee inputs get cleared or not cleared
  //  without using some janky settimeouts to guarantee action after full rendering.
  const desktopInputter = (
    <Stack className="Inputter-Stack">
      {locked ? <PenaltyLockBar /> : null}
      <Stack direction="row" width={'100%'} justifyContent={'center'}>
        {submittedPromptAnswer &&
        (player.correctAnswer || player.timerLimitReached) ? (
          <Typography
            color={player.correctAnswer ? 'success.main' : 'error.main'}
            fontWeight={600}
            border={
              player.correctAnswer
                ? '1px solid success.main'
                : '1px solid error.main'
            }
            paddingY="5px"
            textAlign={spectatingLargeMultiplayer ? 'left' : 'center'}
            sx={{
              fontSize: spectatingLargeMultiplayer ? 18 : 24,
              overflowWrap: spectatingLargeMultiplayer ? 'anywhere' : 'unset',
            }}
          >
            ({submittedPromptAnswer})
          </Typography>
        ) : null}
        {showInput() ? (
          isShowInputControl ? (
            <span
              className="span-input"
              ref={inputRef}
              contentEditable="true"
              data-ph={
                myTeam?.correctAnswer ? 'Chat while you wait' : inputPlaceholder
              }
              onBeforeInput={handleBeforeInput}
              onBlur={handleInputBlur}
              onInput={(e) => handleInputChange(e.currentTarget.innerText)}
              style={{
                display: locked ? 'none' : 'inherit',
                width: 'fit-content',
              }}
            ></span>
          ) : (
            showInputControlReadOnly
          )
        ) : isWrongAnswerAndTimeLimitReached() ? (
          <Typography
            ref={spectate ? spectateInputRef : inputTextRef}
            color="error.main"
            border="1px solid error.main"
            paddingY="5px"
            paddingX="20px"
            textAlign={spectatingLargeMultiplayer ? 'left' : 'center'}
            sx={{
              fontSize: spectatingLargeMultiplayer ? 18 : 24,
              overflowWrap: spectatingLargeMultiplayer ? 'anywhere' : 'unset',
            }}
          >
            {(spectate ? spectateInputRef.current?.innerText : inputText) ||
              inputPlaceholder}
          </Typography>
        ) : isCorrectAnswer() ? (
          <Stack>
            <Typography
              ref={spectate ? spectateInputRef : inputTextRef}
              color={'success.main'}
              fontWeight={600}
              border={`1px solid success.main`}
              paddingY="5px"
              paddingX="20px"
              textAlign={spectatingLargeMultiplayer ? 'left' : 'center'}
              sx={{
                fontSize: spectatingLargeMultiplayer ? 18 : 24,
                overflowWrap: spectatingLargeMultiplayer ? 'anywhere' : 'unset',
              }}
            >
              {spectate ? spectateInputRef.current?.innerText : inputText}
            </Typography>
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );

  return (
    <>
      <Stack direction="row" justifyContent={'center'}>
        <Stack
          className="Inputter-Stack"
          sx={{ position: 'relative', alignItems: 'center' }}
        >
          {fuzzyScore && fuzzyScore > 0 ? (
            spectatingLargeMultiplayer ? (
              <PrecisionGraphicIcon
                fuzzyScore={fuzzyScore}
              ></PrecisionGraphicIcon>
            ) : (
              <PrecisionGraphic
                fuzzyScore={fuzzyScore}
                reverse={reverse}
              ></PrecisionGraphic>
            )
          ) : alertMessage && !spectate ? (
            <InputLabel
              sx={{
                textAlign: 'center',
                color: alertColor,
                mb: { xs: '-16px', sm: 0 },
              }}
            >
              {alertMessage}
            </InputLabel>
          ) : (
            <Box height={spectatingLargeMultiplayer ? '0px' : '23px'}></Box>
          )}
          {submittedPromptAnswer &&
          !player.correctAnswer &&
          !player.timerLimitReached ? (
            <Typography variant="caption">({submittedPromptAnswer})</Typography>
          ) : null}
          {!spectatingLargeMultiplayer && myTeam?.correctAnswer ? (
            <Typography
              textAlign={'center'}
              color="success.main"
              fontWeight={600}
            >
              {myTeam?.correctAnswerPlayerUsername} answered correctly!
            </Typography>
          ) : null}
          {desktopInputter}
        </Stack>

        {/* Refactor below. Edge case for spectating sit-n-go */}
        {spectatingLargeMultiplayer &&
        (alertMessage === 'Try again' ||
          alertMessage === 'Be more specific...' ||
          alertMessage === '1 point penalty') ? (
          <InputLabel sx={{ textAlign: 'center', color: alertColor }}>
            {alertMessage}
          </InputLabel>
        ) : null}
      </Stack>
    </>
  );
};
