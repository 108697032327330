import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { SocketProvider } from './contexts/SocketProvider';
import { ProfileProvider } from './contexts/ProfileProvider';
import { LoggedInUserProvider } from './contexts/LoggedInUserProvider';
import { ErrorDialogProvider } from './contexts/ErrorDialogProvider';
import { LobbyGamesProvider } from './contexts/LobbyGamesProvider';
import { GameProvider } from './contexts/GameProvider';
import { ScorecardDialogProvider } from './contexts/ScorecardDialogProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ColorModeProvider } from './mui-theme/ColorModeContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { QuickPlayGamesProvider } from './contexts/QuickPlayGamesProvider';
import * as Sentry from '@sentry/react';
import posthog from 'posthog-js'
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ArenaProvider } from './contexts/ArenaProvider';

posthog.init('phc_yzeyIWnwPVdR7nuG6lC6UD7xL7LlliyEgbVTA8tKWnS', { api_host: 'https://app.posthog.com' })

Sentry.init({
  dsn: 'https://7c565a2bffbe441e1deb82a925c200c2@o4507117120520192.ingest.us.sentry.io/4507117124059136',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', new RegExp(`^https://app.partrivia.com`)
],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.REACT_APP_ENV !== 'dev',
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <SnackbarProvider maxSnack={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <BrowserRouter>
            <ProfileProvider>
              <SocketProvider>
                <LoggedInUserProvider>
                  <ErrorDialogProvider>
                    <GameProvider>
                      <ArenaProvider>
                        <QuickPlayGamesProvider>
                          <LobbyGamesProvider>
                            <ScorecardDialogProvider>
                              <ColorModeProvider>
                                <App />
                              </ColorModeProvider>
                            </ScorecardDialogProvider>
                          </LobbyGamesProvider>
                        </QuickPlayGamesProvider>
                      </ArenaProvider>
                    </GameProvider>
                  </ErrorDialogProvider>
                </LoggedInUserProvider>
              </SocketProvider>
            </ProfileProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </SnackbarProvider>
    </GoogleOAuthProvider>
  </QueryClientProvider>

  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// eslint-disable-next-line no-unused-expressions, no-restricted-globals
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="7c99bda3-b150-5f2a-9aef-a92375eb40a8")}catch(e){}}();
//# debugId=7c99bda3-b150-5f2a-9aef-a92375eb40a8
