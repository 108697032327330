import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Container, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { CorrectAnswer } from '../CorrectAnswer';
import { Inputter } from './Inputter';
import './styles.css';
import { Socket } from 'socket.io-client';
import { getAvatarSrc } from '../../../../api/cloudinary';

import { Mode } from '../../../../types/game';
import { Team, Penalty } from '../../../../types/game';
import { Player } from '../../../../types/player';
import { ArenaTeam } from '../../../arena-team/types';

type PropTypes = {
  arenaGame?: boolean;
  arenaTeamId?: string;
  fuzzy?: Record<string, number>;
  isAdmin: boolean;
  league?: boolean;
  mode: Mode;
  myArenaTeam?: ArenaTeam;
  myTeam?: Team;
  otherArenaTeam?: ArenaTeam;
  opponentTeam?: Team;
  penalty?: Penalty;
  questionIndex: number;
  selectedProfileIds?: string[];
  sortedPlayers?: Player[];
  inputSocket?: Socket;
  spectateSocket?: Socket;
  timerLimitReached: boolean;
};

export const SpectatePlayerInput = ({
  arenaGame,
  arenaTeamId,
  fuzzy,
  isAdmin,
  league,
  mode,
  myArenaTeam,
  myTeam,
  otherArenaTeam,
  opponentTeam,
  penalty,
  questionIndex,
  selectedProfileIds,
  sortedPlayers,
  inputSocket,
  spectateSocket,
  timerLimitReached,
}: PropTypes) => {
  const [liveInputCache, setLiveInputCache] = useState<Record<string, string>>(
    {}
  );
  const [FB, setFB] = useState<Record<string, string>>({});
  const [selectedPlayersLocked, setSelectedPlayersLocked] =
    useState<Record<string, boolean>>();

  const playersCount = sortedPlayers?.length;

  const selectedPlayers = useMemo(
    () =>
      sortedPlayers?.map((p) => {
        if (selectedProfileIds.includes(p.profileId)) {
          return p;
        }
      }),
    [selectedProfileIds, sortedPlayers]
  );

  useEffect(() => {
    if (isAdmin && inputSocket) {
      inputSocket.on('input-f', (username: string) => updateFB('f', username));
      inputSocket.on('input-b', (username: string) => updateFB('b', username));
    }
  }, []);

  useEffect(() => {
    if (!penalty) return;
    setSelectedPlayersLocked((prev) => ({
      ...prev,
      [penalty.profileId]: true,
    }));
    setTimeout(() => {
      setSelectedPlayersLocked((prev) => ({
        ...prev,
        [penalty.profileId]: false,
      }));
    }, 1000);
  }, [penalty]);

  useEffect(() => {
    setLiveInputCache({});
  }, [questionIndex]);

  const myTeamPlayers = useMemo(
    () => sortedPlayers?.filter((p) => myTeam?.members.includes(p.profileId)),
    [sortedPlayers, myTeam]
  );

  const opponentTeamPlayers = useMemo(
    () =>
      sortedPlayers?.filter((p) => opponentTeam?.members.includes(p.profileId)),
    [sortedPlayers, opponentTeam]
  );

  const myArenaTeamPlayers = useMemo(
    () =>
      sortedPlayers?.filter((p) => p.arenaTeamId === arenaTeamId).slice(0, 4),
    [sortedPlayers, arenaTeamId]
  );

  const otherArenaTeamPlayers = useMemo(
    () =>
      sortedPlayers?.filter((p) => p.arenaTeamId !== arenaTeamId).slice(0, 4),
    [sortedPlayers, arenaTeamId]
  );

  const updateFB = (type: 'f' | 'b', username: string) => {
    FB[username] = type;
    setFB(FB);
  };

  const updateLiveInputCache = (liveInput: Record<string, string>) => {
    liveInputCache[liveInput.profileId] = liveInput.input;
    setLiveInputCache(liveInputCache);
  };

  const sitAndGoInputDisplay = (p: Player) => (
    <Stack
      key={p.profileId}
      direction="row"
      spacing={2}
      marginTop={2}
      sx={{ textAlign: 'left', width: '100%' }}
      alignItems={'center'}
    >
      <Tooltip title={p.username} placement="right-start">
        <Avatar
          src={getAvatarSrc(p.avatarPublicId, p.avatarUrl, 50, 50)}
          variant="rounded"
          sx={{
            height: '40px',
            width: '40px',
            position: 'relative',
            filter: isAdmin && FB[p.username] === 'b' ? 'blur(0.2rem)' : '',
          }}
        >
          {!p.avatarUrl ? p.username.charAt(0) : null}
        </Avatar>
      </Tooltip>
      <Stack direction="row" spacing={1}>
        <Inputter
          fuzzyScore={fuzzy?.[p.profileId]}
          inputPlaceholder={p.username}
          liveInputCache={liveInputCache}
          mode={mode}
          playersCount={playersCount}
          player={p}
          locked={selectedPlayersLocked?.[p.profileId]}
          questionIndex={questionIndex}
          spectateSocket={spectateSocket}
          spectate={true}
          timerLimitReached={timerLimitReached}
          updateLiveInputCache={updateLiveInputCache}
        />
        <CorrectAnswer
          abbreviateAves={
            (selectedPlayers && selectedPlayers?.length > 4) || mode === 'teams'
          }
          mode={mode}
          player={p}
          smallFont
          spectate={true}
        />
      </Stack>
    </Stack>
  );

  return (
    <Container>
      {!arenaGame && mode === 'sit-n-go' && playersCount > 2 ? (
        <>
          {selectedPlayers && selectedPlayers?.length <= 4 ? (
            <Stack
              spacing={2}
              width="100%"
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Box width="auto">
                {selectedPlayers?.map((p) => sitAndGoInputDisplay(p))}
              </Box>
            </Stack>
          ) : (
            <Stack direction={'row'} width="100%" px={8}>
              <Stack spacing={2} width="50%">
                {selectedPlayers
                  ?.slice(0, 4)
                  .map((p) => sitAndGoInputDisplay(p))}
              </Stack>
              <Stack spacing={2} width="50%">
                {selectedPlayers
                  ?.slice(4, 8)
                  .map((p) => sitAndGoInputDisplay(p))}
              </Stack>
            </Stack>
          )}
        </>
      ) : arenaGame ? (
        <Stack
          direction={'row'}
          width="100%"
          justifyContent={'space-between'}
          pl={8}
        >
          <Stack spacing={3} width="40%">
            <Stack spacing={1}>
              {myArenaTeamPlayers?.map((p) => {
                // since "players" gets updated as players answer correctly,
                // after we find the teams, we need to use players for rendering
                const player = sortedPlayers?.find(
                  (player) => player.profileId === p.profileId
                );
                return sitAndGoInputDisplay(player);
              })}
            </Stack>
          </Stack>
          <Stack spacing={3} width="40%">
            <Stack spacing={1}>
              {otherArenaTeamPlayers?.map((p) => {
                // since "players" gets updated as players answer correctly,
                // after we find the teams, we need to use players for rendering
                const player = sortedPlayers?.find(
                  (player) => player.profileId === p.profileId
                );
                return sitAndGoInputDisplay(player);
              })}
            </Stack>
          </Stack>
        </Stack>
      ) : mode === 'teams' ? (
        <Stack
          direction={'row'}
          width="100%"
          justifyContent={'space-between'}
          paddingX={5}
        >
          <Stack spacing={3} width="40%">
            <Typography variant="h6" textAlign={'center'} fontWeight={600}>
              Team {myTeam?.username}
            </Typography>
            <Stack spacing={1}>
              {myTeamPlayers?.map((p) => {
                // since "players" gets updated as players answer correctly,
                // after we find the teams, we need to use players for rendering
                if (p.joinedGame) {
                  const player = sortedPlayers?.find(
                    (player) => player.profileId === p.profileId
                  );
                  return sitAndGoInputDisplay(player);
                }
              })}
            </Stack>
          </Stack>
          {!league || isAdmin ? (
            <Stack spacing={3} width="40%">
              <Typography variant="h6" textAlign={'center'} fontWeight={600}>
                Team {opponentTeam?.username}
              </Typography>
              <Stack spacing={1}>
                {opponentTeamPlayers?.map((p) => {
                  // since "players" gets updated as players answer correctly,
                  // after we find the teams, we need to use players for rendering
                  if (p.joinedGame) {
                    const player = sortedPlayers?.find(
                      (player) => player.profileId === p.profileId
                    );
                    return sitAndGoInputDisplay(player);
                  }
                })}
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      ) : (
        <Stack direction={'row'} spacing={10}>
          {selectedPlayers?.map((p, i) => (
            <Stack
              key={i}
              direction="row"
              spacing={2}
              sx={{ textAlign: 'center' }}
            >
              {i === 0 ? (
                <Avatar
                  src={getAvatarSrc(p.avatarPublicId, p.avatarUrl, 50, 50)}
                  variant="rounded"
                  sx={{
                    height: '50px',
                    width: '50px',
                    position: 'relative',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    filter:
                      isAdmin && FB[p.username] === 'b' ? 'blur(0.2rem)' : '',
                  }}
                />
              ) : null}
              <Stack justifyContent={'center'}>
                <Inputter
                  fuzzyScore={fuzzy?.[p.profileId]}
                  inputPlaceholder={p.username}
                  liveInputCache={liveInputCache}
                  mode={mode}
                  player={p}
                  locked={selectedPlayersLocked?.[p.profileId]}
                  questionIndex={questionIndex}
                  playersCount={playersCount}
                  spectateSocket={spectateSocket}
                  spectate={true}
                  timerLimitReached={timerLimitReached}
                  updateLiveInputCache={updateLiveInputCache}
                />
                <CorrectAnswer player={p} spectate={true}></CorrectAnswer>
              </Stack>
              {i === 1 ? (
                <Avatar
                  src={getAvatarSrc(p.avatarPublicId, p.avatarUrl, 50, 50)}
                  variant="rounded"
                  sx={{
                    height: '50px',
                    width: '50px',
                    position: 'relative',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    filter:
                      isAdmin && FB[p.username] === 'b' ? 'blur(0.2rem)' : '',
                  }}
                />
              ) : null}
            </Stack>
          ))}
        </Stack>
      )}
    </Container>
  );
};
