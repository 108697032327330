import { Paper, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { random } from 'lodash';
import React, { useEffect, useState } from 'react';
import './styles.css';

const tip1 = (
  <Paper elevation={6}>
    <Typography component="h1" variant="h6" marginBottom={1}>
      In Casual only, underlines are displayed as a reminder
    </Typography>
    <img
      style={{ width: '40%' }}
      src="./underline-example.png"
      alt="underline-example"
    ></img>
    <Typography component="h1" variant="h6" marginTop={1}>
      you only need to type that word for credit
    </Typography>
  </Paper>
);

const tip2 = (
  <Paper elevation={6}>
    <Typography component="h1" variant="h6">
      Close misspellings are acceptable and are graded by precision.
    </Typography>
  </Paper>
);

const tip3 = (
  <Paper elevation={6}>
    <Typography component="h1" variant="h6">
      Remember to hit (ENTER) or (RETURN) to submit your answer
    </Typography>
  </Paper>
);

const tip4 = (
  <Paper elevation={6}>
    <Typography component="h1" variant="h6">
      Often times, the appearance of the blank tiles is already a big clue!
    </Typography>
  </Paper>
);

const tip5 = (
  <Paper elevation={6}>
    <Typography component="h1" variant="h6">
      Ctrl + Backspace (Cmd + Delete for Mac) deletes the whole word.
    </Typography>
  </Paper>
);

const tip6 = (
  <Paper elevation={6}>
    <Typography component="h1" variant="h6">
      You will be penalized by 1 point and locked out for 1 second if you guess
      incorrectly before the letters are revealed
    </Typography>
  </Paper>
);

const tips = [tip1, tip2, tip3, tip4, tip5, tip6];

type PropTypes = {
  countdown: number;
};

export const Countdown = ({ countdown }: PropTypes) => {
  const [tipIndex, setTipIndex] = useState<number>();

  useEffect(() => {
    const r = random(tips.length - 1);
    setTipIndex(r);
  }, []);

  return (
    <>
      <Container sx={{ width: '100%', textAlign: 'center', marginTop: 5 }}>
        {tipIndex !== undefined ? (
          <>
            <Typography component="h1" variant="h4" marginBottom={5}>
              Tip:
            </Typography>
            {tips[tipIndex]}
          </>
        ) : null}
        <div className="countdown">{countdown}</div>
      </Container>
    </>
  );
};
