import React, { useEffect, useMemo, useState } from 'react';
import { SpectatePlayerInput } from './player-input/SpectatePlayerInput';
import { GameHeader } from './game-header';
import { PlayerInput } from './player-input/PlayerInput';
import { Socket } from 'socket.io-client';
import {
  Stack,
  Box,
  Paper,
  Typography,
  Container,
  Button,
  Tooltip,
  Divider,
  useTheme,
  Fab,
  Drawer,
  Chip,
  Avatar,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import './styles.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import PauseIcon from '@mui/icons-material/Pause';
import { TeamInputLive } from './TeamInputLive';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { ConnectionStats } from './ConnectionStats';
import { DisplaySizeToggles } from './DisplaySizeToggles';

import { sortPlayers } from './helpers';
import { ScoreBar } from './score-bar';
import { DraggableChat } from '../../../dialogs/DraggableChat';
import { RevealGuess } from './RevealGuess';
import { QuestionAnswer } from './question-answer/QuestionAnswer';
import { CorrectAnswer } from './CorrectAnswer';
import { SpectateSidebar } from './spectate-sidebar';
import { SPECTATOR_CHAT_WIDTH } from './spectate-sidebar/constants';
import { SpectateSidebarOpenTab } from './spectate-sidebar/SpectateSidebarOpenTab';
import { Spectator } from '../../../types';
import { SpectatorIncomingChat } from '../../../types/chat';
import { Assists, Mode, Team, Penalty, Teams } from '../../../types/game';
import {
  ArenaTeamLiveRoundScores,
  Player,
  RoundScore,
} from '../../../types/player';
import { ProperNameShortAnswer } from '../../../types/question';
import { SIT_AND_GO_DRAWER_WIDTH } from './constants';
import { SitAndGoLeaderboard } from './SitAndGoLeaderboard';
import MyStatsLeaderboard from './MyStatsLeaderboard';
import TopScore from './TopScore';
import { ArenaTeam } from '../../arena-team/types';
import { ArenaTeamsLiveLeaderboard } from './ArenaTeamsLiveLeaderboard';
import { getTeamTotalScore } from '../utils';
import OtherGamesScores from './OtherGamesScores';

type PropTypes = {
  answer?: string[][];
  arenaGame?: number;
  arenaProjection?: number;
  arenaPlayersProjectionAndTiers?: Record<
    string,
    { projection: number; currentTier: string }
  >;
  arenaRoundGamesScores?: Record<
    string,
    {
      [key: string]: number;
    }
  >;
  arenaTeams?: ArenaTeam[];
  arenaTeamLiveRoundScores?: ArenaTeamLiveRoundScores;
  assists?: Assists;
  category: string;
  categorySelections?: string[];
  categorySet: string;
  clearAfterPenalty?: boolean;
  dailySet?: number;
  delayBeforeQuestionRevealCount: number;
  displaySize: 'sm' | 'md' | 'lg';
  forceReconnectSocketLiveInput: () => void;
  freezeGameClock: boolean;
  fullQuestion?: string;
  fuzzy?: Record<string, number>;
  gameClock: number;
  gameId: string;
  gameOver: boolean;
  gameType: 'casual' | 'pro';
  handleDisplaySizeChange: (e: any, size: 'sm' | 'md' | 'lg') => void;
  handlePause: () => void;
  isAdmin: boolean;
  isTeamSpectator?: boolean;
  leagueMatch?: number;
  mathFormula?: string;
  missedDaily?: boolean;
  mode: Mode;
  myTeam?: Team;
  nextQuestionDelayCount: number;
  opponentTeam?: Team;
  otherArenaTeams?: ArenaTeam[];
  par: number;
  paused: boolean;
  penalty?: Penalty;
  playerSelf: Player;
  players: Record<string, Player>;
  playerThem?: Player;
  profileId: string;
  properNameShortAnswer?: ProperNameShortAnswer;
  question: string;
  questionImgUrl?: string;
  questionIndex: number;
  rejoin: boolean;
  resetDelayClocks: () => void;
  roundScores?: Record<number, RoundScore>;
  showConnectionStats?: boolean;
  socket?: Socket;
  inputSocket?: Socket;
  spectateSocket?: Socket;
  spectate: boolean;
  spectatorChat?: SpectatorIncomingChat[];
  spectateGameId?: string;
  spectatorList: Spectator[];
  handleExitSpectate?: () => void;
  submittedByUsername?: string;
  teamBestBalls?: Record<string, Player[]>;
  teams: Teams;
  timerLimitReached: boolean;
  totalNumQuestions: number | undefined;
  unrevealedIndex?: number[];
  username: string;
  willPause: boolean;
};

export const Game = ({
  answer,
  arenaProjection,
  arenaGame,
  arenaPlayersProjectionAndTiers,
  arenaRoundGamesScores,
  arenaTeams,
  arenaTeamLiveRoundScores,
  assists,
  category,
  categorySelections,
  categorySet,
  clearAfterPenalty,
  dailySet,
  delayBeforeQuestionRevealCount,
  displaySize,
  forceReconnectSocketLiveInput,
  freezeGameClock,
  fullQuestion,
  fuzzy,
  gameClock,
  gameId,
  gameOver,
  gameType,
  handleDisplaySizeChange,
  handlePause,
  isAdmin,
  isTeamSpectator,
  leagueMatch,
  mathFormula,
  missedDaily,
  mode,
  myTeam,
  nextQuestionDelayCount,
  opponentTeam,
  otherArenaTeams,
  par,
  paused,
  penalty,
  playerSelf,
  players,
  playerThem,
  profileId,
  properNameShortAnswer,
  question,
  questionImgUrl,
  questionIndex,
  rejoin,
  resetDelayClocks,
  roundScores,
  showConnectionStats,
  socket,
  spectatorChat,
  inputSocket,
  spectateSocket,
  spectate,
  spectateGameId,
  spectatorList,
  handleExitSpectate,
  submittedByUsername,
  teamBestBalls,
  teams,
  timerLimitReached,
  totalNumQuestions,
  unrevealedIndex,
  username,
  willPause,
}: PropTypes) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [sortedPlayers, setSortedPlayers] = useState<Player[]>();
  const [filteredPlayersProfileIds, setFilteredPlayersProfileIds] =
    useState<string[]>();
  const [playerThemGuess, setPlayerThemGuess] = useState<string>();
  const [displayGameClock, setDisplayGameClock] = useState(false);
  const [displayCategoryAndPar, setDisplayCategoryAndPar] = useState(true);
  const [focusInput, setFocusInput] = useState(false);
  const [connectCounter, setConnectCounter] = useState(0);
  const [disconnectCounter, setDisconnectCounter] = useState(0);
  const [openSpectatorChat, setOpenSpectatorChat] = useState(spectate);
  const [selectedTier, setSelectedTier] = useState('All');
  const [selectedTeamId, setSelectedTeamId] = useState<string>();
  const [hoveredTier, setHoveredTier] = useState<string>(null);
  const [soloTeamsToggleValue, setSoloTeamsToggleValue] = useState<
    'solo' | 'teams'
  >('solo');
  const [selectedProfileIds, setSelectedProfileIds] = useState<string[]>(
    Object.values(players).length <= 2
      ? Object.values(players).map((p) => p.profileId)
      : Object.values(players).length > 2
      ? Object.values(players)
          .slice(0, 8)
          .map((p) => p.profileId)
      : []
  );

  const playersCount = Object.keys(players).length;

  const spectateSng = spectate && mode === 'sit-n-go' && playersCount > 2;
  const spectateTeams = spectate && mode === 'teams';

  const arenaTeamId = playerSelf?.arenaTeamId || arenaTeams?.[0]._id;
  const myArenaTeam = useMemo(
    () => arenaTeams?.find((team) => team._id === arenaTeamId),
    [arenaTeams, arenaTeamId]
  );
  const myArenaTeamScore = useMemo(
    () =>
      arenaTeamLiveRoundScores?.[arenaTeamId]
        ? getTeamTotalScore(arenaTeamLiveRoundScores[arenaTeamId])
        : 0,
    [arenaTeamLiveRoundScores, arenaTeamId]
  );
  const otherArenaTeam = useMemo(
    () => arenaTeams?.find((team) => team._id !== arenaTeamId),
    [arenaTeams, arenaTeamId]
  );
  const otherArenaTeamScore = useMemo(
    () =>
      arenaTeamLiveRoundScores?.[otherArenaTeam?._id]
        ? getTeamTotalScore(arenaTeamLiveRoundScores[otherArenaTeam?._id])
        : 0,
    [arenaTeamLiveRoundScores, otherArenaTeam]
  );

  const handleConnect = () => {
    setConnectCounter((prev) => prev + 1);
  };

  const handleDisconnect = () => {
    setDisconnectCounter((prev) => prev + 1);
  };

  useEffect(() => {
    if (!socket) return;
    // every component can have a socket listener like these connects and disconnects
    //  so that component specific states can be handled in isolation
    // the counters are a clean workaround to handle updating states since the listeners
    //  contain the intial states on mount
    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);
    return () => {
      socket.off('connect', handleConnect);
      socket.off('disconnect', handleDisconnect);
    };
  }, [socket]);

  useEffect(() => {
    // not sure if needed, sometimes when a user gets disconnected then reconnect
    // they cannot see the gameclock, so this attempts to ensure the game clock gets displayed
    setDisplayGameClock(true);
  }, [connectCounter]);

  const displayPlayerByTier = (player: Player, tier: string) => {
    if (!tier) return true;
    if (tier === 'All') return true;
    return (
      arenaPlayersProjectionAndTiers?.[player.profileId]?.currentTier === tier
    );
  };

  useEffect(() => {
    const sortedPlayers = sortPlayers(players);
    const sortedFilteredPlayers = filteredPlayersProfileIds?.length
      ? sortedPlayers.filter((p) =>
          filteredPlayersProfileIds?.includes(p.profileId)
        )
      : sortedPlayers;
    setSortedPlayers(sortedFilteredPlayers);
  }, [players, filteredPlayersProfileIds]);

  useEffect(() => {
    if (!penalty || !playerThem) return;
    setPlayerThemGuess(
      penalty.profileId === playerThem.profileId
        ? penalty?.revealedAnswer
        : undefined
    );
  }, [penalty]);

  useEffect(() => {
    if (delayBeforeQuestionRevealCount <= 0) {
      setDisplayGameClock(true);
      resetDelayClocks();
    }
  }, [delayBeforeQuestionRevealCount]);

  useEffect(() => {
    // this is not pretty, could probably handle this better from the serve
    if (
      nextQuestionDelayCount <= 3 &&
      (timerLimitReached || playerSelf?.correctAnswer || myTeam?.correctAnswer)
    ) {
      setDisplayGameClock(false);
      setDisplayCategoryAndPar(false);
      return;
    }
    if (
      delayBeforeQuestionRevealCount === 1 &&
      !timerLimitReached &&
      !playerSelf?.correctAnswer &&
      !myTeam?.correctAnswer
    ) {
      setDisplayGameClock(false);
      return;
    }
    if (!timerLimitReached) {
      setDisplayCategoryAndPar(true);
    }
  }, [
    delayBeforeQuestionRevealCount,
    nextQuestionDelayCount,
    timerLimitReached,
    playerSelf,
    myTeam,
  ]);

  useEffect(() => {
    if (!spectate) return;
    setDisplayGameClock(true);
  }, [spectate]);

  useEffect(() => {
    if (!rejoin) return;
    setDisplayGameClock(true);
    setDisplayCategoryAndPar(true);
  }, [rejoin]);

  const handlePoolLeaderboardUsernameClick = (profileId: string) => {
    if (spectate && !selectedProfileIds.includes(profileId)) {
      if (selectedProfileIds.length < 8) {
        setSelectedProfileIds((prev) => prev.concat(profileId));
      } else {
        setSelectedProfileIds((prev) => prev.slice(0, 7).concat(profileId));
      }
    }
  };

  const playerMeAnsweredFirst = () => {
    const playerMeScore = playerSelf?.currentScore || 0;
    const playerThemScore = playerThem?.currentScore || 0;
    return (
      // BUG: If both players have score 0 and then playerMe answers on par and gets score 0 this will return false, but it should return true and show the opponent's timer
      (!!playerMeScore && !playerThemScore) || playerMeScore < playerThemScore
    );
  };

  const teamAnsweredFirst = (team: 'teamA' | 'teamB') => {
    if (!teams) return false;
    const teamScore = teams[team].currentScore;
    const otherTeam = team === 'teamA' ? 'teamB' : 'teamA';
    const otherTeamScore = teams[otherTeam].currentScore;
    return teamScore !== null && otherTeamScore === null;
  };

  const displayOpponentTimer = () => {
    if (mode === 'teams' && teams && myTeam) {
      return freezeGameClock && teamAnsweredFirst(myTeam.id) && !spectate;
    }
    if (mode === 'sit-n-go') {
      return freezeGameClock && !spectate;
    }
    return (
      freezeGameClock && playerMeAnsweredFirst() && playerThem && !spectate
    );
  };

  const opponentGameClock = (
    <Typography
      className={`opponent-timer ${displayOpponentTimer() ? 'fade-in' : ''}`}
      sx={{
        visibility: displayOpponentTimer() ? 'visible' : 'hidden',
        position: 'absolute',
        bottom: '54px',
        left: 'calc(50% - 150px)',
      }}
    >
      Opponent's timer 00:{gameClock < 10 ? '0' : null}
      {gameClock || 0}
    </Typography>
  );

  const scoreBars = () => {
    if (!playerSelf) return <></>;
    return (
      <Stack className="ScoreBarStack">
        <ScoreBar
          avatar={playerSelf.avatarUrl}
          mode={mode}
          score={playerSelf.parScore}
          username={playerSelf.username}
          usernameLocation={'left'}
        />
        {playersCount === 2 && playerThem ? (
          <>
            <ScoreBar
              avatar={playerThem.avatarUrl}
              mode={mode}
              score={playerThem.parScore}
              username={playerThem.username}
              usernameLocation={'right'}
            />
          </>
        ) : null}
      </Stack>
    );
  };

  const teamScoreBars = () => {
    return (
      <>
        <ScoreBar
          avatar={''}
          mode={mode}
          score={myTeam?.parScore || 0}
          username={myTeam?.username}
          usernameLocation={'left'}
        />
        <ScoreBar
          avatar={''}
          mode={mode}
          score={opponentTeam?.parScore || 0}
          username={opponentTeam?.username}
          usernameLocation={'right'}
        />
      </>
    );
  };

  const arenaTeamsScoreBars = () => {
    return (
      <>
        <ScoreBar
          avatar={''}
          limit={80}
          mode={mode}
          score={myArenaTeamScore || 0}
          secondaryAvatar={spectate ? undefined : playerSelf?.avatarPublicId}
          secondaryScore={spectate ? undefined : playerSelf?.parScore}
          username={myArenaTeam?.teamName}
          usernameLocation={'left'}
        />
        <ScoreBar
          avatar={''}
          limit={80}
          mode={mode}
          score={otherArenaTeamScore || 0}
          username={otherArenaTeam?.teamName}
          usernameLocation={'right'}
        />
      </>
    );
  };

  const spectatorsTooltip = (
    <>
      <Typography variant="h6">Spectators</Typography>
      <Divider />
      <Stack mt={2}>
        {spectatorList?.map((s) => (
          <Typography key={s.username}>{s.username}</Typography>
        ))}
      </Stack>
    </>
  );

  // const spectatorChatTooltip = (
  //   <Stack>
  //     <Typography variant="body1">[Enter] open chat</Typography>
  //     <Typography variant="body1">[ESC] close chat</Typography>
  //   </Stack>
  // );

  const exitSpectateTooltip = (
    <Typography variant="body1">Leave spectate</Typography>
  );

  const spectatorToolbar = (
    <Stack
      spacing={1}
      sx={{
        width: 'fit-content',
        marginTop: '-140px',
        position: 'fixed',
        zIndex: 100,
      }}
    >
      {spectate && spectatorList?.length > 0 ? (
        <Tooltip title={exitSpectateTooltip} placement="right">
          <Button
            variant="outlined"
            startIcon={<ExitToAppIcon />}
            onClick={handleExitSpectate}
          ></Button>
        </Tooltip>
      ) : null}
      {spectatorList?.length > 0 ? (
        <Tooltip
          title={spectatorList?.length ? spectatorsTooltip : null}
          placement="right"
        >
          <Button variant="outlined" startIcon={<VisibilityIcon />}>
            {spectatorList?.length}
          </Button>
        </Tooltip>
      ) : null}
    </Stack>
  );

  const assistAnimation = (
    <Box
      width={'100%'}
      sx={{
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      <Box className="float-fade">
        <Stack
          direction={'row'}
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <HandshakeIcon />
          <Typography variant="h6" textAlign={'center'} fontWeight={600}>
            Nice assist!
          </Typography>
        </Stack>
      </Box>
    </Box>
  );

  const handlePauseClick = () => {
    setFocusInput((prev) => !prev);
    if (mode !== 'solo' && !isAdmin) return;
    handlePause();
  };

  const handleSelectedProfileIdsFilter = (
    filter: 'removeAll' | 'top4' | 'top8'
  ) => {
    if (filter === 'removeAll') {
      setSelectedProfileIds([]);
      return;
    }
    if (filter === 'top4') {
      const top4 = sortedPlayers.slice(0, 4).map((p) => p.profileId);
      setSelectedProfileIds(top4);
      return;
    }
    if (filter === 'top8') {
      const top8 = sortedPlayers.slice(0, 8).map((p) => p.profileId);
      setSelectedProfileIds(top8);
      return;
    }
  };

  const filterPlayersByTier = (tier: string) => {
    const filteredPlayers = Object.values(players)?.filter((p) =>
      displayPlayerByTier(p, tier)
    );
    setFilteredPlayersProfileIds(filteredPlayers.map((p) => p.profileId));
  };

  const handleTierClick = (tier: string) => {
    setSelectedTeamId(undefined);
    filterPlayersByTier(tier);
    setSelectedTier(tier);
    if (tier === 'All') {
      setSelectedProfileIds(
        Object.values(players)
          .map((p) => p.profileId)
          .slice(0, 7)
      );
      return;
    }
    setSelectedProfileIds(
      Object.values(players)
        .filter(
          (p) =>
            arenaPlayersProjectionAndTiers[p.profileId]?.currentTier === tier
        )
        .map((p) => p.profileId)
        .slice(0, 7)
    );
  };

  const handleTierHover = (tier: string) => {
    setHoveredTier(tier);
  };

  const handleTeamClick = (teamId: string) => {
    setSelectedTier(undefined);
    setSelectedTeamId(teamId);
    const filteredPlayers = Object.values(players)?.filter(
      (p) => p.arenaTeamId === teamId
    );
    // setFilteredPlayersProfileIds(filteredPlayers.map((p) => p.profileId));
    setSelectedProfileIds(filteredPlayers.map((p) => p.profileId).slice(0, 7));
  };

  const handleSoloTeamChange = (e: any, value: 'solo' | 'teams') => {
    if (!value) return;
    setSoloTeamsToggleValue(value);
  };

  return (
    <>
      {spectate && mode === 'teams' ? (
        <SpectateSidebar
          chat={spectatorChat}
          closeChat={() => setOpenSpectatorChat(false)}
          gameId={spectateGameId}
          handleExitSpectate={handleExitSpectate}
          openChat={openSpectatorChat}
          profileId={profileId}
          spectateSocket={spectateSocket}
          spectatorList={spectatorList}
          username={username}
        />
      ) : null}

      {!openSpectatorChat && spectate && mode === 'teams' ? (
        <SpectateSidebarOpenTab handleOpen={() => setOpenSpectatorChat(true)} />
      ) : null}

      <Box
        width={
          openSpectatorChat && mode === 'teams'
            ? `calc(100% - ${SPECTATOR_CHAT_WIDTH}px)`
            : mode === 'sit-n-go'
            ? `calc(100% - ${SIT_AND_GO_DRAWER_WIDTH}px)`
            : '100%'
        }
      >
        {showConnectionStats && !gameOver ? (
          <ConnectionStats
            disconnectCounter={disconnectCounter}
            inputSocket={inputSocket}
            hasLeaderboard={mode === 'sit-n-go'}
            profileId={profileId}
            socket={socket}
          />
        ) : null}
        <DisplaySizeToggles
          displaySize={displaySize}
          handleDisplaySizeChange={handleDisplaySizeChange}
          hasLeaderboard={mode === 'sit-n-go'}
        />
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Container
            className="GameHeaderContainer"
            sx={{
              position: 'sticky',
              top: 0,
              background: 'linear-gradient(to bottom, #182a3fa6, #000000)',
              py: 2,
            }}
          >
            {arenaGame
              ? arenaTeamsScoreBars()
              : spectateSng
              ? null
              : mode === 'teams'
              ? teamScoreBars()
              : scoreBars()}

            <Stack spacing={1}>
              <GameHeader
                arenaGame={arenaGame}
                arenaTeamLiveRoundScores={arenaTeamLiveRoundScores}
                category={category}
                categorySelections={categorySelections}
                categorySet={categorySet}
                dailySet={dailySet}
                delayBeforeQuestionRevealCount={delayBeforeQuestionRevealCount}
                displayCategoryAndPar={displayCategoryAndPar}
                displayGameClock={displayGameClock}
                freezeGameClock={freezeGameClock}
                gameClock={gameClock}
                goToNextQuestionEarly={!!fullQuestion} // if fullQuestion was received, that means all players answered correctly and will now move to the next question before the 20 sec mark
                leagueMatch={leagueMatch}
                missedDaily={missedDaily}
                mode={mode}
                myArenaTeam={myArenaTeam}
                myArenaTeamScore={myArenaTeamScore}
                otherArenaTeam={otherArenaTeam}
                otherArenaTeamScore={otherArenaTeamScore}
                myTeam={myTeam}
                nextQuestionDelayCount={nextQuestionDelayCount}
                otherTeam={opponentTeam}
                par={par}
                players={players}
                playerSelf={playerSelf}
                playerThem={playerThem}
                questionIndex={questionIndex}
                submittedByUsername={submittedByUsername}
                teamBestBalls={teamBestBalls}
                timerLimitReached={timerLimitReached}
                totalNumQuestions={totalNumQuestions}
                spectate={spectate}
              />
              {opponentGameClock}
              <RevealGuess
                avatarPublicId={playerThem?.avatarPublicId}
                playerThemGuess={playerThemGuess}
              />
            </Stack>
          </Container>
          <Container
            sx={{
              height:
                displaySize === 'sm'
                  ? '220px'
                  : displaySize === 'md'
                  ? '260px'
                  : '340px',
            }}
          >
            <QuestionAnswer
              answer={answer}
              correctAnswer={playerSelf?.correctAnswer || myTeam?.correctAnswer}
              displaySize={displaySize}
              fullQuestion={fullQuestion}
              mathFormula={mathFormula}
              properNameShortAnswer={properNameShortAnswer}
              question={question}
              questionImgUrl={questionImgUrl}
              questionIndex={questionIndex}
              spectate={spectate}
              timerLimitReached={timerLimitReached}
              unrevealedIndex={unrevealedIndex}
            />
          </Container>
          {spectatorToolbar}

          {willPause ? (
            <Typography
              variant="h6"
              textAlign={'center'}
              sx={{
                border: '1px solid',
                borderColor: theme.palette.info.main,
                borderRadius: '.25rem',
              }}
            >
              Game will PAUSE before the start of the next question
            </Typography>
          ) : null}
          <Paper
            elevation={6}
            sx={{
              height: {
                xs: 300,
                sm: `${
                  spectateSng || spectateTeams
                    ? '18rem'
                    : mode === 'teams'
                    ? '12rem'
                    : '8rem'
                }`,
              },
              width: { xs: '100vw', sm: '100%' },
              position: 'sticky',
              bottom: 0,
              zIndex: { xs: '10', sm: null },
            }}
          >
            {spectate && mode === 'sit-n-go' ? (
              <Stack
                direction={'row'}
                spacing={2}
                sx={{ position: 'absolute' }}
              >
                <Button
                  variant="text"
                  onClick={() => handleSelectedProfileIdsFilter('removeAll')}
                >
                  REMOVE ALL
                </Button>
                <Button
                  variant="text"
                  onClick={() => handleSelectedProfileIdsFilter('top4')}
                >
                  TOP 4
                </Button>
                {Object.keys(players).length > 4 ? (
                  <Button
                    variant="text"
                    onClick={() => handleSelectedProfileIdsFilter('top8')}
                  >
                    TOP 8
                  </Button>
                ) : null}
              </Stack>
            ) : null}

            <Stack
              direction={'row'}
              width={'100%'}
              height={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                }}
              >
                {!spectate && myTeam ? (
                  <TeamInputLive
                    assists={assists}
                    fuzzy={fuzzy}
                    gameId={gameId}
                    mode={mode}
                    myTeam={myTeam}
                    penalty={penalty}
                    players={players}
                    profileId={profileId}
                    questionIndex={questionIndex}
                    inputSocket={inputSocket}
                    spectateSocket={spectateSocket}
                    spectate={spectate}
                    username={username}
                  />
                ) : null}
              </Box>
              <Stack
                spacing={0}
                alignItems="center"
                justifyContent="center"
                width={'100%'}
                height={'100%'}
              >
                {!spectate ? (
                  <Stack position="relative" justifyContent={'center'}>
                    {assists && assists.assisterProfileId === profileId
                      ? assistAnimation
                      : null}
                    {isTeamSpectator ? (
                      <Stack spacing={1}>
                        <Typography variant="subtitle2" textAlign={'center'}>
                          You are sitting out as a spectator this match
                        </Typography>
                        <Typography variant="subtitle2" textAlign={'center'}>
                          Do not help your teammates
                        </Typography>
                      </Stack>
                    ) : playerSelf ? (
                      <>
                        <PlayerInput
                          answer={answer}
                          category={category}
                          clearAfterPenalty={clearAfterPenalty}
                          correctAnswer={playerSelf?.correctAnswer}
                          fuzzy={fuzzy}
                          focusInput={focusInput}
                          forceReconnectSocketLiveInput={
                            forceReconnectSocketLiveInput
                          }
                          gameId={gameId}
                          mode={mode}
                          myTeam={myTeam}
                          onPause={handlePause}
                          penalty={penalty}
                          playerSelf={playerSelf}
                          questionIndex={questionIndex}
                          inputSocket={inputSocket}
                          spectateSocket={spectateSocket}
                          spectate={spectate}
                          teams={teams}
                          timerLimitReached={timerLimitReached}
                        />
                        <CorrectAnswer
                          mode={mode}
                          player={playerSelf}
                          myTeam={myTeam}
                        />
                      </>
                    ) : null}
                  </Stack>
                ) : (
                  <SpectatePlayerInput
                    arenaGame={!!arenaGame}
                    arenaTeamId={arenaTeamId}
                    fuzzy={fuzzy}
                    isAdmin={isAdmin}
                    mode={mode}
                    myTeam={myTeam}
                    opponentTeam={opponentTeam}
                    penalty={penalty}
                    questionIndex={questionIndex}
                    selectedProfileIds={selectedProfileIds}
                    sortedPlayers={Object.values(players)}
                    inputSocket={inputSocket}
                    spectateSocket={spectateSocket}
                    timerLimitReached={timerLimitReached}
                  />
                )}
              </Stack>
            </Stack>
          </Paper>
          <Stack direction={'row'}>
            {!gameOver && isAdmin ? (
              <Tooltip title="F9 to pause">
                <Fab
                  variant="extended"
                  color="info"
                  size="small"
                  sx={{ margin: '8px' }}
                  onClick={handlePauseClick}
                >
                  <PauseIcon sx={{ mr: { xs: 0, md: 1 } }} />
                  <Box
                    component="span"
                    sx={{
                      display: { xs: 'none', md: 'inherit' },
                    }}
                  >
                    {willPause ? 'Unpause' : 'Pause'}
                  </Box>
                </Fab>
              </Tooltip>
            ) : null}
          </Stack>
          {mode === 'sit-n-go' ? (
            <Drawer
              sx={{
                width: { xs: 144, sm: SIT_AND_GO_DRAWER_WIDTH },
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: { xs: 144, sm: SIT_AND_GO_DRAWER_WIDTH },
                  boxSizing: 'border-box',
                  bgcolor: '#333333',
                  maxHeight: { xs: 416, sm: '100%' },
                },
              }}
              variant="permanent"
              anchor="right"
            >
              {!spectate && sortedPlayers && (
                <>
                  <MyStatsLeaderboard
                    profileId={profileId}
                    questionIndex={questionIndex}
                    roundScores={roundScores}
                    sortedPlayers={sortedPlayers}
                  />

                  <Divider />
                </>
              )}

              {arenaRoundGamesScores && (
                <OtherGamesScores
                  arenaRoundGamesScores={arenaRoundGamesScores}
                  otherArenaTeams={otherArenaTeams}
                />
              )}

              {/* <Box py={1}>
                <TopScore
                  averageScore={averageScore}
                  borderColor={'#565656'}
                  topScore={topScore}
                />
              </Box> */}

              {/* {arenaGame && (
                <ToggleButtonGroup
                  color="primary"
                  value={soloTeamsToggleValue}
                  exclusive
                  onChange={handleSoloTeamChange}
                  size="small"
                >
                  <ToggleButton value="teams" fullWidth>
                    Teams
                  </ToggleButton>
                  <ToggleButton value="solo" fullWidth>
                    Everyone
                  </ToggleButton>
                </ToggleButtonGroup>
              )} */}

              {/* {soloTeamsToggleValue === 'teams' &&
                arenaTeams &&
                arenaTeamLiveRoundScores && (
                  <ArenaTeamsLiveLeaderboard
                    arenaTeamId={playerSelf?.arenaTeamId}
                    arenaTeams={arenaTeams}
                    arenaTeamLiveRoundScores={arenaTeamLiveRoundScores}
                    handleTeamClick={handleTeamClick}
                    selectedTeamId={selectedTeamId}
                    sortedPlayers={sortedPlayers}
                    spectate={spectate}
                    teamBestBalls={teamBestBalls}
                  />
                )} */}

              {soloTeamsToggleValue === 'solo' && sortedPlayers ? (
                <SitAndGoLeaderboard
                  arenaGame={arenaGame}
                  arenaTeamId={playerSelf?.arenaTeamId}
                  // arenaProjection={arenaProjection}
                  // arenaPlayersProjectionAndTiers={
                  //   arenaPlayersProjectionAndTiers
                  // }
                  gameOver={false}
                  // handleTierClick={handleTierClick}
                  // handleTierHover={handleTierHover}
                  // hoveredTier={hoveredTier}
                  disableChat={!timerLimitReached && !playerSelf?.correctAnswer}
                  onUsernameClick={handlePoolLeaderboardUsernameClick}
                  sortedPlayers={sortedPlayers}
                  profileId={profileId}
                  questionIndex={questionIndex}
                  selectedTier={selectedTier}
                  spectate={spectate}
                />
              ) : null}
            </Drawer>
          ) : null}
        </Box>
      </Box>
    </>
  );
};
