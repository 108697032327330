import { SocketCallback } from '../../../types';
import {
  GameDataFromServer,
  CreateGameConfig,
  Mode,
} from '../../../types/game';
import { Profile } from '../../../types/player';
import {
  useInputSocketContext,
  useSocketContext,
  useSpectateSocketContext,
} from '../../../contexts/SocketProvider';
import { useErrorDialog } from '../../../contexts/ErrorDialogProvider';
import { useGameContext } from '../../../contexts/GameProvider';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useChats } from '../../../components/footer/Chats/hooks/useChats';
import { useCallback } from 'react';

const useQuickPlayManager = (onCreateGameSuccess?: () => void) => {
  const navigate = useNavigate();

  const { socket } = useSocketContext();
  const { spectateSocket } = useSpectateSocketContext();
  const { inputSocket } = useInputSocketContext();

  const {
    clearChat,
    clearGameData,
    setGameId,
    setGameDataFromServer,
    setLeagueGameType,
    setShowPregame,
    setShowArenaChat,
    setShowGameChat,
    setSpectateGameDataFromServer,
    spectateGameId,
    spectatePostGameId,
    setSpectateGameId,
    setSpectateLeagueGameType,
  } = useGameContext();

  const { setMessage, setTitle, setOpen } = useErrorDialog();

  const createGame = async (
    profile: Profile,
    createGameConfig: CreateGameConfig
  ) => {
    if (!socket) return;
    socket.emit('qpCanCreateGame', profile._id, (res: SocketCallback) => {
      if (res.status === 'ok') {
        if (createGameConfig.dailySet) {
          handleStartDailyGame(profile, createGameConfig);
        } else {
          handleCreateGame(profile, createGameConfig);
        }
      }
      if (res.status === 'failed') requestFailed(res.message);
    });
  };

  const handleCreateGame = (
    profile: Profile,
    createGameConfig: CreateGameConfig
  ) => {
    if (!socket) return;

    const {
      allowSpectators,
      categorySelections,
      categorySet,
      customGameName,
      gameType,
      isPunchcard,
      mode,
    } = createGameConfig;

    socket.emit(
      'qpCreateGameAndJoin',
      {
        allowSpectators,
        categorySelections,
        categorySet: categorySet,
        customGameName: customGameName,
        enableCustomQuestionsInSolos:
          profile.settings?.enableCustomQuestionsInSolos,
        fastNext: profile.settings?.fastNext,
        gameType: gameType,
        mode,
        profileId: profile._id,
        isPunchcard,
      },
      (res: SocketCallback) => {
        if (res.status === 'ok') {
          const gameData: GameDataFromServer = res.data;
          createGameSuccess(profile._id, gameData);
        }
        if (res.status === 'failed') {
          requestFailed(res.message || '');
        }
      }
    );
  };

  const handleStartDailyGame = (
    profile: Profile,
    createGameConfig: CreateGameConfig
  ) => {
    if (!socket) return;

    const { dailySet, missedDaily } = createGameConfig;

    socket.emit(
      'startDailyGame',
      {
        dailySet,
        missedDaily,
        profileId: profile._id,
        isMobile,
      },
      (res: SocketCallback) => {
        if (res.status === 'ok') {
          const gameData: GameDataFromServer = res.data;
          createGameSuccess(profile._id, gameData);
        }
        if (res.status === 'failed') {
          requestFailed(res.message || '');
        }
      }
    );
  };

  const handleJoinQuickPlayGame = (profileId: string, gameId: string) => {
    if (!socket) return;
    socket.emit('qpJoinGame', { gameId, profileId }, (res: SocketCallback) => {
      if (res.status === 'ok') {
        const gameDataFromServer: GameDataFromServer = res.data;
        joinQuickPlayGameSuccess(profileId, gameDataFromServer);
      }
      if (res.status === 'failed') {
        requestFailed(res.message || '');
      }
    });
  };

  const joinQuickPlayGameSuccess = (
    profileId: string,
    gameDataFromServer: GameDataFromServer
  ) => {
    setGameDataFromServer(gameDataFromServer);
    const { gameId, gameOver, gameStarted, leagueGameType, mode, arenaSet } =
      gameDataFromServer;
    setGameId(gameId);
    setLeagueGameType(leagueGameType);
    inputSocket.emit('joinQuickPlayGame', { gameId, profileId });
    if (gameStarted) {
      navigate('/game-loading', {
        state: {
          fromQuickPlay: true,
          rejoin: true,
        },
      });
      return;
    }

    if (mode === 'sit-n-go') {
      if (gameDataFromServer.arenaNight) {
        setShowArenaChat(true);
      } else {
        setShowGameChat(true);
        setShowPregame(true);
      }
    }
  };

  const createGameSuccess = (
    profileId: string,
    gameDataFromServer: GameDataFromServer
  ) => {
    const { dailySet, gameId, mode } = gameDataFromServer;
    setGameId(gameId);
    setGameDataFromServer(gameDataFromServer);

    inputSocket.emit('joinQuickPlayGame', { gameId, profileId });

    if (mode === 'solo') {
      onCreateGameSuccess && onCreateGameSuccess();
      navigate('/game-loading', {
        state: {
          fromQuickPlay: !dailySet,
          fromDailies: dailySet > 0,
          rejoin: false,
        },
      });
    } else {
      if (mode === 'sit-n-go' || mode === 'teams') {
        setShowPregame(true);
        setShowGameChat(true);
      }
      onCreateGameSuccess && onCreateGameSuccess();
    }
  };

  const requestFailed = (message?: string) => {
    setOpen(true);
    if (message === 'Game in progress') {
      setTitle(message);
      setMessage(`You have a game in progress. Wait until it's over.`);
    } else if (message === 'Already in game') {
      setTitle(message);
      setMessage(
        `You already joined a game. Go to the lobby and leave that game to start a new one`
      );
    } else if (message === 'Player not found') {
      setTitle(message);
      setMessage(`Please refresh this page`);
    } else {
      setTitle(message);
    }
  };

  const handleStartSitAndGoClick = (profileId: string, gameId: string) => {
    if (!socket) return;
    socket.emit(
      'qpStartSitAndGo',
      { profileId, gameId },
      (res: SocketCallback) => {
        if (res.status === 'ok') {
          console.log('Sit and go started');
        }
        if (res.status === 'failed') {
          console.log('Sit and go failed');
        }
      }
    );
  };

  const handleSpectateClick = useCallback(
    (profileId: string, gameId: string) => {
      if (!spectateSocket) return;
      spectateSocket.emit(
        'canSpectateGame',
        profileId,
        gameId,
        (res: SocketCallback) => {
          if (res.status === 'ok') {
            if (spectatePostGameId) {
              leaveChat(profileId, spectatePostGameId);
              clearChat();
            }
            const gameData: GameDataFromServer = res.data;
            spectateGameSuccess(gameData);
          }
          if (res.status === 'failed') {
            requestFailed(res.message || '');
          }
        }
      );
    },
    [spectateSocket, spectatePostGameId]
  );

  const spectateGameSuccess = (gameDataFromServer: GameDataFromServer) => {
    const { gameId, leagueGameType } = gameDataFromServer;
    setSpectateGameId(gameId);
    setSpectateLeagueGameType(leagueGameType);
    setSpectateGameDataFromServer(gameDataFromServer);
    navigate('/spectate', {
      state: {
        fromQuickPlay: true,
      },
    });
  };

  const handleRejoinGame = (profileId: string, gameId: string) => {
    if (!socket) return;
    socket.emit('canRejoinGame', profileId, (res: SocketCallback) => {
      if (res.status === 'ok') {
        const gameData: GameDataFromServer = res.data;
        rejoinGameSuccess(profileId, gameData);
      }
      if (res.status === 'failed') {
        requestFailed(res.message || '');
      }
    });
  };

  const rejoinGameSuccess = (
    profileId: string,
    gameDataFromServer: GameDataFromServer
  ) => {
    const { gameId } = gameDataFromServer;
    setGameId(gameId);
    setGameDataFromServer(gameDataFromServer);
    inputSocket.emit('joinLeagueGame', { gameId, profileId });
    navigate('/game-loading', {
      state: {
        rejoin: true,
      },
    });
  };

  const handleLeaveGame = (profileId: string, gameId: string) => {
    if (!socket) return;
    socket.emit('leaveGame', {
      gameId,
      profileId,
    });
    clearGameData();
    setGameId('');
    setSpectateGameId('');
    setShowPregame(false);
    setShowGameChat(false);
  };

  const handleRemoveGameClick = (profileId: string, gameId: string) => {
    if (!socket) return;
    socket.emit('deleteGame', {
      profileId,
      gameId,
    });
  };

  const leaveChat = (profileId: string, gameId: string) => {
    socket.emit(
      'leaveChat',
      {
        gameId,
        profileId,
      },
      (res: SocketCallback) => {
        if (res.status === 'ok') {
          setShowGameChat(false);
        }
      }
    );
    return true;
  };

  return {
    createGame,
    handleJoinQuickPlayGame,
    handleStartSitAndGoClick,
    handleSpectateClick,
    handleLeaveGame,
    handleRemoveGameClick,
    handleRejoinGame,
    leaveChat,
  };
};

export default useQuickPlayManager;
