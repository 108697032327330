import React from 'react';
import { DrawerButtonWhosOnline } from './DrawerButtonWhosOnline';
import PlayersOnline from './PlayersOnline';
import { Profile } from '../../types/player';
import { LoggedInUser } from '../../types/user';
import { DrawerButton } from '.';
import WhosOnlineHeader from './WhosOnlineHeader';

type Props = {
  openDrawer: boolean;
  profile: Profile;
  loggedInUsers: LoggedInUser[];
  setOpenDrawer: (isOpen: boolean) => void;
};

export const MessagesDrawer = ({
  openDrawer,
  profile,
  loggedInUsers,
  setOpenDrawer,
}: Props) => {
  return (
    <>
      <DrawerButton
        sx={{ width: '100%', bgcolor: 'black' }}
        onClick={(event) => setOpenDrawer(false)}
      >
        <WhosOnlineHeader
          profile={profile}
          loggedInUsers={loggedInUsers}
          openMessagesDrawer={openDrawer}
        />
      </DrawerButton>
      <PlayersOnline profile={profile} loggedInUsers={loggedInUsers} />
    </>
  );
};
