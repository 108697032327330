import { Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

let interval: any;

export const FrameRate = () => {
  const [fps, setFps] = useState(0);
  const frameCountRef = useRef(0);
  const lastFrameTimeRef = useRef(performance.now());

  useEffect(() => {
    countFrame();
    interval = setInterval(() => {
      const fps = calculateFPS();
      setFps(fps);
      // Update the status indicator based on the FPS value
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  function countFrame() {
    frameCountRef.current = frameCountRef.current + 1;
    requestAnimationFrame(countFrame);
  }

  function calculateFPS() {
    const currentTime = performance.now();
    const elapsedTime = currentTime - lastFrameTimeRef.current;
    const fps = (frameCountRef.current / elapsedTime) * 1000;
    frameCountRef.current = 0;
    lastFrameTimeRef.current = currentTime;
    return fps;
  }

  return (
    <Typography variant={'caption'} textAlign={'right'}>
      FPS: {fps.toFixed(1)}
    </Typography>
  );
};
