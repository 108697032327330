import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { StyledPaperItem } from '../../../mui-theme/mui-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DailiesStreaks } from '../types';
import StreaksList from './StreaksList';

type Props = {
  dailiesStreaks: DailiesStreaks;
};

const Streaks = ({ dailiesStreaks }: Props) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            justifyContent: 'center',
          },
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color:
              theme.palette.mode === 'light'
                ? theme.palette.dailies.main
                : theme.palette.text.primary,
            textShadow:
              theme.palette.mode === 'dark'
                ? `0 0 1px ${theme.palette.dailies.main}, 10px 0px 20px ${theme.palette.dailies.main}, 0px 0px 16px ${theme.palette.dailies.main}`
                : 'none',
            letterSpacing: 10,
            marginLeft: 5,
          }}
        >
          STREAKS
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {dailiesStreaks ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} className="PLAYED">
              <StyledPaperItem
                sx={{
                  height: '100%',
                  width: '100%',
                  border: '1px solid',
                  borderColor: '#424a51',
                }}
              >
                <StreaksList
                  dailiesStreaks={dailiesStreaks}
                  streakCategory={'played'}
                />
              </StyledPaperItem>
            </Grid>

            <Grid item xs={12} sm={3} className="NO-TIMEOUTS">
              <StyledPaperItem
                sx={{
                  height: '100%',
                  width: '100%',
                  border: '1px solid',
                  borderColor: '#424a51',
                }}
              >
                <StreaksList
                  dailiesStreaks={dailiesStreaks}
                  streakCategory={'timeouts'}
                />
              </StyledPaperItem>
            </Grid>

            <Grid item xs={12} sm={3} className="SCRATCHES">
              <StyledPaperItem
                sx={{
                  height: '100%',
                  width: '100%',
                  border: '1px solid',
                  borderColor: '#424a51',
                }}
              >
                <StreaksList
                  dailiesStreaks={dailiesStreaks}
                  streakCategory={'scratches'}
                />
              </StyledPaperItem>
            </Grid>

            <Grid item xs={12} sm={3} className="PAR-BIRDIE-EAGLE">
              <StyledPaperItem
                sx={{
                  height: '100%',
                  width: '100%',
                  border: '1px solid',
                  borderColor: '#424a51',
                }}
              >
                <StreaksList
                  dailiesStreaks={dailiesStreaks}
                  streakCategory={'parBirdieEagle'}
                />
              </StyledPaperItem>
            </Grid>
          </Grid>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};

export default Streaks;
