import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { SIT_AND_GO_DRAWER_WIDTH } from './constants';

type PropTypes = {
  displaySize: 'sm' | 'md' | 'lg';
  handleDisplaySizeChange: (e: any, size: 'sm' | 'md' | 'lg') => void;
  hasLeaderboard: boolean;
};
export const DisplaySizeToggles = ({
  displaySize,
  handleDisplaySizeChange,
  hasLeaderboard,
}: PropTypes) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 200,
        right: hasLeaderboard ? `${SIT_AND_GO_DRAWER_WIDTH + 10}px` : '10px',
        zIndex: 999,
      }}
    >
      <ToggleButtonGroup
        value={displaySize}
        exclusive
        onChange={handleDisplaySizeChange}
        size="small"
      >
        <ToggleButton value="sm">sm</ToggleButton>
        <ToggleButton value="md">md</ToggleButton>
        <ToggleButton value="lg">lg</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};
