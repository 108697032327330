import React from 'react';
import {
  Badge,
  BadgeProps,
  Box,
  IconButton,
  Paper,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DrawerButton, getWhosOnlineCount } from '.';
import WhosOnlineHeader from './WhosOnlineHeader';
import { Profile } from '../../types/player';
import { LoggedInUser } from '../../types/user';
import GroupIcon from '@mui/icons-material/Group';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 17,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

type Props = {
  loggedInUsers: LoggedInUser[];
  openMessagesDrawer: boolean;
  profile: Profile;
  setOpenMessagesDrawer: (open: boolean) => void;
};

export const DrawerButtonWhosOnline = ({
  loggedInUsers,
  openMessagesDrawer,
  profile,
  setOpenMessagesDrawer,
}: Props) => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const under370 = useMediaQuery('(max-width:370px)');

  return xsOnly ? (
    <IconButton
      aria-label="cart"
      size="medium"
      sx={{ backgroundColor: '#42a5f53d', ml: 2, mb: under370 ? 6 : 0 }}
      onClick={(event) => setOpenMessagesDrawer(!openMessagesDrawer)}
    >
      <StyledBadge
        badgeContent={String(getWhosOnlineCount(loggedInUsers, profile?._id))}
        color="secondary"
      >
        <GroupIcon fontSize="large" />
      </StyledBadge>
    </IconButton>
  ) : (
    <Box
      sx={{
        [theme.breakpoints.up(1024)]: { left: 0 },
        [theme.breakpoints.between(0, 1024)]: { left: 368 },
        height: 42,
        width: '100%',
        bgcolor: 'black',
      }}
    >
      <DrawerButton
        onClick={(event) => setOpenMessagesDrawer(!openMessagesDrawer)}
        sx={{ height: '100%' }}
      >
        <WhosOnlineHeader
          profile={profile}
          loggedInUsers={loggedInUsers}
          openMessagesDrawer={openMessagesDrawer}
        />
      </DrawerButton>
    </Box>
  );
};
