import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useUpdateProfileProperty } from '../../../hooks/useUpdateProfileProperty';
import GooglePlaces from './GooglePlaces';
import { GooglePlacesPrediction } from '../../../api/response-types/responseTypes';
import useFetchPlacesGeocode from './useFetchPlacesGeocode';
import useUpdateUserLocation from './useUpdateUserLocation';

type PropTypes = {
  close: () => void;
  fullName?: string;
  handleSaveSuccess: () => void;
  location?: string;
  open: boolean;
  profileId: string;
  genderPronoun?: string;
  username: string;
};

export const EditUsernameDialog = ({
  close,
  fullName,
  handleSaveSuccess,
  location,
  open,
  profileId,
  genderPronoun,
  username,
}: PropTypes) => {
  const { updateProfile } = useUpdateProfileProperty();
  const { updateUserLocation } = useUpdateUserLocation();

  const [isSaving, setIsSaving] = useState(false);
  const [newUsername, setNewUsername] = useState(username);
  const [newFullName, setNewFullName] = useState<string>(fullName);
  const [pronoun, setPronoun] = useState<string>(genderPronoun);
  const [placeId, setPlaceId] = useState<string>();
  const [userLocation, setUserLocation] = useState<{
    location: string;
    locationLatitude: number;
    locationLongitude: number;
    locationPlaceId: string;
  }>();

  const { geocode } = useFetchPlacesGeocode(placeId);

  useEffect(() => {
    if (geocode) {
      setUserLocation((prev) => ({
        ...prev,
        locationLatitude: geocode.geometry.location.lat,
        locationLongitude: geocode.geometry.location.lng,
      }));
    }
  }, [geocode, placeId]);

  const handlePronounChange = (event: SelectChangeEvent) => {
    setPronoun(event.target.value);
  };

  const handlePredictionSelect = (prediction: GooglePlacesPrediction) => {
    setUserLocation((prev) => ({
      ...prev,
      location: prediction.description,
      locationPlaceId: prediction.place_id,
    }));
    setPlaceId(prediction.place_id);
  };

  const handleSaveProfileData = async () => {
    setIsSaving(true);
    if (newUsername !== username)
      updateProfile(profileId, 'username', newUsername.trim());
    if (newFullName) updateProfile(profileId, 'fullName', newFullName);
    if (pronoun) updateProfile(profileId, 'genderPronoun', pronoun);
    if (userLocation) updateUserLocation({ profileId, userLocation });

    // awful, lazy, but whatever right now
    setTimeout(() => {
      setIsSaving(false);
      handleSaveSuccess();
      close();
    }, 2000);
  };

  const disabledSave = !newUsername;

  return (
    <Dialog open={open} onClose={close} maxWidth={'xs'} fullWidth>
      <DialogTitle>
        <Typography letterSpacing={2} textAlign={'center'}>
          EDIT PROFILE
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3} padding={2}>
          <TextField
            error={newUsername === ''}
            value={newUsername}
            autoFocus
            label="Username"
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewUsername(event.target.value);
            }}
          />

          <TextField
            value={newFullName}
            autoFocus
            label="Full Name (Optional)"
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewFullName(event.target.value);
            }}
          />

          <GooglePlaces
            handlePredictionSelect={handlePredictionSelect}
            value={location}
          />

          <FormControl fullWidth>
            <InputLabel>Pronoun (Optional)</InputLabel>
            <Select
              variant="outlined"
              value={pronoun}
              onChange={handlePronounChange}
              label="Pronoun (Optional)"
            >
              <MenuItem value={'He/Him'}>He/Him</MenuItem>
              <MenuItem value={'She/Her'}>She/Her</MenuItem>
              <MenuItem value={'They/Their'}>They/Them</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} fullWidth>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSaveProfileData}
          fullWidth
          disabled={disabledSave}
          endIcon={
            isSaving ? <CircularProgress size={20} color="secondary" /> : null
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
