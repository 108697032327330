import { Box, Fade, CircularProgress } from '@mui/material';
import React from 'react';

type Props = {
  displayGameClock: boolean;
  timerBeforeQuestionReveal: number;
};

const GameSpinnerAnimation = ({
  displayGameClock,
  timerBeforeQuestionReveal,
}: Props) => {
  return (
    <Box
      sx={{
        height: 90,
        display: displayGameClock ? 'none' : 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Fade in={!displayGameClock}>
        <CircularProgress
          variant="determinate"
          value={timerBeforeQuestionReveal}
        />
      </Fade>
    </Box>
  );
};

export default GameSpinnerAnimation;
