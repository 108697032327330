import {
  Box,
  Card,
  Divider,
  Grid,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { useProfileContext } from '../../contexts/ProfileProvider';
import useUpdateGameSettings from './useUpdateGameSettings';
import { FetchUpdatePlayMenuSettingsResponse } from '../../api/response-types/responseTypes';
import BasicLayout from '../../BasicLayout/BasicLayout';

const GameSettingsPage = () => {
  const { profile, mutateProfile } = useProfileContext();

  const handleOnSuccess = (data: FetchUpdatePlayMenuSettingsResponse) => {
    const profile = data.data.profile;
    mutateProfile(profile);
  };

  const { updateGameSettings } = useUpdateGameSettings(handleOnSuccess);

  const handleClearAfterPenalty = (checked: boolean) => {
    const updatedSettings = {
      ...profile.settings,
      clearAfterPenalty: checked,
    };

    updateGameSettings(updatedSettings);
  };

  const handleConnectionStats = (checked: boolean) => {
    const updatedSettings = {
      ...profile.settings,
      connectionStats: checked,
    };

    updateGameSettings(updatedSettings);
  };

  const handleFastNextSwitch = (checked: boolean) => {
    const updatedSettings = {
      ...profile.settings,
      fastNext: checked,
    };

    updateGameSettings(updatedSettings);
  };

  return (
    <BasicLayout>
      <Grid container spacing={3}>
        <Grid item xs={4} md={4}></Grid>
        <Grid item xs={4} md={4}>
          <Card
            sx={{
              padding: 2,
            }}
          >
            <Typography
              variant="h5"
              mb={2}
              textAlign={'center'}
              letterSpacing={2}
            >
              GAME SETTINGS
            </Typography>

            <Divider />

            <Stack sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Stack alignItems="center" direction={'row'} my={1}>
                <Box width="80%">
                  <Typography variant="body1">
                    Clear answer after penalty
                    <Tooltip
                      placement="top"
                      title="Turn this option on if you prefer to have your answer cleared after a penalty"
                    >
                      <InfoIcon sx={{ ml: 1, verticalAlign: 'bottom' }} />
                    </Tooltip>
                  </Typography>
                </Box>
                <Box
                  width="20%"
                  sx={{ display: 'flex', justifyContent: 'right' }}
                >
                  <Switch
                    checked={!!profile.settings?.clearAfterPenalty}
                    onChange={(e) => handleClearAfterPenalty(e.target.checked)}
                  />
                </Box>
              </Stack>

              <Stack alignItems="center" direction={'row'} my={1}>
                <Box width="80%">
                  <Typography variant="body1">
                    Show connection stats
                    <Tooltip
                      placement="top"
                      title="This will display connection status, fps, and ping during the game"
                    >
                      <InfoIcon sx={{ ml: 1, verticalAlign: 'bottom' }} />
                    </Tooltip>
                  </Typography>
                </Box>
                <Box
                  width="20%"
                  sx={{ display: 'flex', justifyContent: 'right' }}
                >
                  <Switch
                    checked={!!profile.settings?.connectionStats}
                    onChange={(e) => handleConnectionStats(e.target.checked)}
                  />
                </Box>
              </Stack>

              <Stack alignItems="center" direction={'row'} my={1}>
                <Box width="80%">
                  <Typography variant="body1">
                    Fast skip to next question
                    <Tooltip
                      placement="top"
                      title="Turn this option on to quickly move to the next question after answering or timing out. Only allowed in casual/pro/survival solo modes, haunts, and seeded games."
                    >
                      <InfoIcon sx={{ ml: 1, verticalAlign: 'bottom' }} />
                    </Tooltip>
                  </Typography>
                </Box>
                <Box
                  width="20%"
                  sx={{ display: 'flex', justifyContent: 'right' }}
                >
                  <Switch
                    checked={!!profile.settings?.fastNext}
                    onChange={(e) => handleFastNextSwitch(e.target.checked)}
                  />
                </Box>
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={4} md={4}></Grid>
      </Grid>
    </BasicLayout>
  );
};

export default GameSettingsPage;
