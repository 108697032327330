import {
  Typography,
  TableContainer,
  Paper,
  Link,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Avatar,
  useTheme,
  Box,
  Badge,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { getAvatarSrc, resizeImage } from '../../api/cloudinary';
import { utcToLocalTime } from '../../utils/date-time';
import { DailiesResult } from './types';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

interface HeadCell {
  disablePadding: boolean;
  id: keyof DailiesResult;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'username',
    numeric: false,
    disablePadding: false,
    label: 'Player',
  },
  {
    id: 'score',
    numeric: false,
    disablePadding: false,
    label: 'Score',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Played At',
  },
];

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DailiesResult
  ) => void;
  order: Order;
  orderBy: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | boolean },
  b: { [key in Key]: number | string | boolean }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof DailiesResult) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type PropTypes = {
  dailiesResults?: DailiesResult[];
};

export const DailyPlayersResults = ({ dailiesResults }: PropTypes) => {
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof DailiesResult>('createdAt');

  const navigate = useNavigate();
  const theme = useTheme();

  const handleUsernameClick = async (e: any, profileId: string) => {
    e.stopPropagation();
    navigate(`/profile/overview/${profileId}`);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DailiesResult
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <Typography
        variant="h6"
        mb={2}
        sx={{
          color: theme.palette.text.primary,
          textShadow:
            theme.palette.mode === 'dark'
              ? `0 0 1px ${theme.palette.dailies.main}, 10px 0px 20px ${theme.palette.dailies.main}, 0px 0px 16px ${theme.palette.dailies.main}`
              : 'none',
        }}
      >
        Who Played Their Daily?
      </Typography>
      <TableContainer component={Paper} sx={{ height: 453, bgcolor: 'black' }}>
        <Table stickyHeader aria-label="simple table" size="small">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {dailiesResults &&
              dailiesResults?.sort(getComparator(order, orderBy)).map((r) => (
                <TableRow hover key={r.username}>
                  <TableCell>
                    <Stack direction={'row'} spacing={2}>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        badgeContent={
                          r.isMobile && (
                            <PhoneAndroidIcon
                              sx={{
                                background: theme.palette.background.default,
                                padding: '3px',
                                borderRadius: '16px',
                              }}
                              fontSize="small"
                            />
                          )
                        }
                      >
                        <Avatar
                          src={getAvatarSrc(
                            r.avatarPublicId,
                            r.avatarUrl,
                            50,
                            50
                          )}
                          variant="rounded"
                          sx={{
                            height: 25,
                            width: 25,
                          }}
                        >
                          {!r.avatarPublicId ? r.username.charAt(0) : null}
                        </Avatar>
                      </Badge>
                      <Link
                        component="button"
                        variant="body2"
                        onClick={(e) => handleUsernameClick(e, r.profileId)}
                        textAlign={'left'}
                      >
                        <Typography
                          sx={{
                            lineHeight: '25px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            width: '150px',
                          }}
                        >
                          {' '}
                          {r.username}
                        </Typography>
                      </Link>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {r.score > 0 ? '+' : ''}
                    {r.score}
                  </TableCell>
                  <TableCell>{utcToLocalTime(r.createdAt)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
