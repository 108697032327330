import { Notification } from '../../../types/notifications';

export const getNewNotifications = (
  lastNotificationSeen?: string,
  notifications?: Notification[]
) => {
  if (!lastNotificationSeen) return notifications;

  const findIndex = notifications?.findIndex(
    (notif) => notif._id === lastNotificationSeen
  );

  const newNotifications =
    findIndex >= 0 ? notifications.slice(0, findIndex) : notifications;

  return newNotifications;
};
