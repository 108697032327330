import { Box, Button, Typography, Stack, Link } from '@mui/material';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useLoggedInUserContext } from '../../contexts/LoggedInUserProvider';
import { useProfileContext } from '../../contexts/ProfileProvider';
import { useGameManager } from '../../hooks/useGameManager';
import { LobbyGame } from '../../types/game';

type Props = {
  anyGamesInProgress: boolean;
  joinableLeagueGame: LobbyGame;
  showJoin: boolean;
  showRejoin: boolean;
};

export const ActionHeader = ({
  anyGamesInProgress,
  joinableLeagueGame,
  showJoin,
  showRejoin,
}: Props) => {
  const navigate = useNavigate();
  const { profile } = useProfileContext();

  const { loggedInUser } = useLoggedInUserContext();
  const { handleJoinLeagueGame, handleRejoinGame } = useGameManager();

  const handleSpectateLink = () =>
    navigate('/tournament', { state: { showLiveGames: true } });

  return (
    <Box>
      {/* {showJoin || showRejoin ? ( */}
      {showRejoin ? (
        <Stack
          direction="row"
          spacing={2}
          height={'36px'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            background: showJoin
              ? 'linear-gradient(106deg, rgb(30 30 30) 0%, rgb(40 100 20) 50%, rgb(30 30 30) 100%)'
              : showRejoin
              ? 'linear-gradient(106deg, rgb(30 30 30) 0%, rgb(63 52 4) 50%, rgb(30 30 30) 100%)'
              : null,
          }}
        >
          {/* {showJoin ? (
            <>
              <Typography>
                {joinableLeagueGame.gameStarted
                  ? 'Your game already started, you can still join:'
                  : 'Your game is starting soon:'}
              </Typography>
              <Button
                variant="outlined"
                size="small"
                onClick={() =>
                  handleJoinLeagueGame(profile._id, joinableLeagueGame.gameId)
                }
                disabled={joinableLeagueGame.activePlayers.includes(profile._id)}
              >
                {joinableLeagueGame.activePlayers.includes(profile._id)
                  ? 'ALREADY JOINED'
                  : 'JOIN NOW'}
              </Button>
            </>
          ) : showRejoin ? ( */}
          {showRejoin ? (
            <>
              <Typography>You have a game in progress:</Typography>
              <Button
                variant="outlined"
                size="small"
                onClick={() =>
                  handleRejoinGame(profile._id, loggedInUser.currentGameId)
                }
              >
                REJOIN
              </Button>
            </>
          ) : null}
        </Stack>
      ) : anyGamesInProgress ? (
        <Stack
          direction="row"
          spacing={2}
          height={'50px'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            background:
              'linear-gradient(106deg, rgb(30 30 30) 0%, rgb(50 70 100) 50%, rgb(30 30 30) 100%)',
          }}
        >
          <Typography fontWeight={600}>
            There are live games in progress,
          </Typography>
          <Link onClick={handleSpectateLink} sx={{ cursor: 'pointer' }}>
            check out the games
          </Link>
        </Stack>
      ) : null}
    </Box>
  );
};
