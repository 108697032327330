import {
  Stack,
  Typography,
  Avatar,
  Divider,
  useTheme,
  useMediaQuery,
  Card,
} from '@mui/material';
import React from 'react';
import AchievementCard from '../ArenaProfile/ArenaAchievements/AchievementCard';
import QuickPlayAchievementCard from '../QuickPlayProfile/QuickPlayAchievementCard';
import { ArenaAchievement, ArenaUserAchievements } from '../ArenaProfile/types';
import { Achievement } from '../../../types/achievement';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { yellow } from '@mui/material/colors';
import ArenaShowcase from './ArenaShowcase';

type Props = {
  arenaAchievementsData: {
    arenaAchievements: ArenaAchievement[];
    arenaUserAchievements: ArenaUserAchievements;
  };
  canEditShowcase: boolean;
  onEditSuccess: () => void;
  profileId: string;
  quickPlayShowcase: Achievement[];
};

const ProfileSidebar = ({
  arenaAchievementsData,
  canEditShowcase,
  onEditSuccess,
  profileId,
  quickPlayShowcase,
}: Props) => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const mdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const smDownScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack
      spacing={{ sm: 1, md: 2 }}
      p={2}
      sx={{
        border: '1px solid',
        borderColor: theme.palette.border,
        borderRadius: 1,
      }}
    >
      <Stack
        direction={'row'}
        spacing={{ xs: 2, sm: 1, md: 2 }}
        alignItems="center"
        justifyContent="center"
      >
        <EmojiEventsIcon sx={{ color: yellow[400] }} />
        <Typography
          variant={smScreen ? 'body1' : 'h6'}
          fontWeight={600}
          letterSpacing={2}
        >
          SHOWCASE
        </Typography>
        <EmojiEventsIcon sx={{ color: yellow[400] }} />
      </Stack>
      {/* 
      <Typography>Season Achievements</Typography>

      <ArenaShowcase
        arenaAchievementsData={arenaAchievementsData}
        canEditShowcase={canEditShowcase}
        onEditSuccess={onEditSuccess}
        profileId={profileId}
      /> */}

      <Divider
        sx={{
          height: 2,
        }}
      />

      <Typography textAlign={'center'}>Achievements</Typography>

      <Stack
        direction={'row'}
        py={1}
        px={2}
        justifyContent={smDownScreen ? 'center' : 'space-between'}
      >
        {quickPlayShowcase?.map((ach, i) =>
          ach ? (
            <QuickPlayAchievementCard
              key={i}
              achievement={ach}
              achieved={true}
            />
          ) : (
            <Avatar
              variant="rounded"
              alt="image"
              sx={{
                width: smDownScreen ? 40 : mdScreen ? 64 : 95,
                height: smDownScreen ? 40 : mdScreen ? 64 : 95,
              }}
            >
              ?
            </Avatar>
          )
        )}
      </Stack>
    </Stack>
  );
};

export default ProfileSidebar;
