import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import React from 'react';
import { GameModeToggleOptions } from './constants';

type Props = {
  gameMode: GameModeToggleOptions;
  handleGameModeChange: (
    event: React.MouseEvent<HTMLElement>,
    gameMode: GameModeToggleOptions
  ) => void;
};

const GameModesToggleGroup = ({ gameMode, handleGameModeChange }: Props) => {
  return (
    <ToggleButtonGroup
      orientation="vertical"
      value={gameMode}
      exclusive
      onChange={handleGameModeChange}
    >
      <ToggleButton
        value={GameModeToggleOptions.NORMAL}
        aria-label={GameModeToggleOptions.NORMAL}
      >
        Normal
      </ToggleButton>
      <ToggleButton
        value={GameModeToggleOptions.RANKED}
        aria-label={GameModeToggleOptions.RANKED}
      >
        Ranked
      </ToggleButton>
      <ToggleButton
        value={GameModeToggleOptions.PUNCHCARD}
        aria-label={GameModeToggleOptions.PUNCHCARD}
      >
        Punchcard
      </ToggleButton>
      <ToggleButton
        value={GameModeToggleOptions.CHALLENGE_TOWER}
        aria-label={GameModeToggleOptions.CHALLENGE_TOWER}
        disabled
      >
        Challenge Tower
      </ToggleButton>
      <ToggleButton
        value={GameModeToggleOptions.SEARCH_SEED}
        aria-label={GameModeToggleOptions.SEARCH_SEED}
        disabled
      >
        Search/Seed
      </ToggleButton>
      <ToggleButton
        value={GameModeToggleOptions.TEAMS}
        aria-label={GameModeToggleOptions.TEAMS}
        disabled
      >
        Teams
      </ToggleButton>
      <ToggleButton
        value={GameModeToggleOptions.BOTS}
        aria-label={GameModeToggleOptions.BOTS}
        disabled
      >
        Bots
      </ToggleButton>
      <ToggleButton
        value={GameModeToggleOptions.GHOST_CHALLENGE}
        aria-label={GameModeToggleOptions.GHOST_CHALLENGE}
        disabled
      >
        Ghost Challenge
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default GameModesToggleGroup;
