import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useVerify } from '../../hooks/auth/useVerify';
import { useProfileContext } from '../../contexts/ProfileProvider';

export const Verify = () => {
  const { token } = useParams();
  const { error, loading, verify } = useVerify();
  const navigate = useNavigate();
  const { profile } = useProfileContext();

  useEffect(() => {
    verify(token);
  }, [token]);

  useEffect(() => {
    if (loading) return;
    if (error) {
      navigate('/login', {
        state: {
          resendVerficationEmail: error?.email,
          showResendVerification: true,
        },
      });
    }
  }, [loading, error]);

  useEffect(() => {
    if (profile) {
      navigate('/home');
    }
  }, [profile]);

  return <></>;
};
