import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Question } from '../../../../../types/game';
import CategoryScorecard from './CategoryScorecard';
import { ArenaCategoryStat } from '../../types';
import { QuestionStatsLean } from '../../../../../types/question-stats';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type PropTypes = {
  category: string;
  onClose: () => void;
  open: boolean;
  questions: Question[];
  questionStats: QuestionStatsLean[];
  stats: ArenaCategoryStat[];
  username: string;
};

export const CategoryScorecardDialog = ({
  category,
  onClose,
  open,
  questions,
  questionStats,
  stats,
  username,
}: PropTypes) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle textAlign={'center'}>
        Category Specific Questions
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CategoryScorecard
          category={category}
          questions={questions}
          questionStats={questionStats}
          stats={stats}
          username={username}
        />
      </DialogContent>
    </Dialog>
  );
};
