import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  Card,
  TableHead,
  Link,
  Stack,
  Tooltip,
  Pagination,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Player, Profile } from '../../../types/player';
import { GameDb } from '../../../types/game';
import { utcToLocalDateTime } from '../../../utils/date-time';
import { useScorecardDialog } from '../../../contexts/ScorecardDialogProvider';
import { fetchGet } from '../../../api/common';
import { GiDevilMask, GiRaiseZombie, GiStoneTower } from 'react-icons/gi';
import { BiGhost } from 'react-icons/bi';
import { FaSeedling } from 'react-icons/fa';
import BalanceIcon from '@mui/icons-material/Balance';
import SearchIcon from '@mui/icons-material/Search';
import { CATEGORY_SETS_LABEL, MODE_ABBR } from '../../../constants/constants';
import { assignRanks } from '../../../utils/game';
import { useNavigate } from 'react-router-dom';

type PropTypes = {
  viewProfile: Profile;
};

export const RecentGames = ({ viewProfile }: PropTypes) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { viewScorecardDialog } = useScorecardDialog();

  const [gameRows, setGameRows] = useState<GameDb[]>();
  const [gamesCount, setGamesCount] = useState<number>();
  const [pageNum, setPageNum] = useState(1);

  const fetchGames = async (profileId: string, limit = 10, offset = 0) => {
    const games = await fetchGet(
      `/games/profile/${profileId}/${limit}/${offset}`
    );
    setGameRows(games);
  };

  const fetchGamesCount = async (profileId: string) => {
    const res = await fetchGet(`/games/count/${profileId}`);
    setGamesCount(res.gamesCount);
  };

  useEffect(() => {
    const offset = 10 * pageNum - 10;
    const limit = 10;
    fetchGamesCount(viewProfile._id);
    fetchGames(viewProfile._id, limit, offset);
  }, [pageNum]);

  const handleViewResultsClick = async (game: GameDb) => {
    viewScorecardDialog(game.gameId);
  };

  const createOptionsLabel = (game: GameDb) => {
    if (game.daily) {
      return 'Daily';
    }
    if (game.demo) {
      return 'Demo';
    }
    if (game.starterKit) {
      return `${game.starterKit.starterKitName} ${game.starterKit.order} ${game.starterKit.clue}`;
    }
    return `${MODE_ABBR[game.mode]} ${
      game.gameType === 'casual' ? 'Casual' : 'Pro'
    } ${
      game.categorySet && game.categorySet !== 'none'
        ? CATEGORY_SETS_LABEL[game.categorySet]
        : !game.ranked &&
          game.variant === 'normal' &&
          game.categorySelections?.length
        ? '[' + game.categorySelections.join(' ') + ']'
        : game.categorySet === 'none'
        ? 'N/A'
        : CATEGORY_SETS_LABEL[game.categorySet]
    }`;
  };

  const results = (game: GameDb) => (
    <Stack>
      <Typography variant="body2">
        {utcToLocalDateTime(game.createdAt)}
      </Typography>
      <Link
        sx={{ cursor: 'pointer' }}
        onClick={() => handleViewResultsClick(game)}
      >
        View Results
      </Link>
    </Stack>
  );

  const stackName = (r: GameDb) => (
    <Tooltip placement="top" title={`Game ID: ${r.gameId}`}>
      <Stack>
        <Stack direction="row" spacing={1}>
          {r.ranked ? (
            <Typography
              variant="body2"
              fontWeight={600}
              sx={{
                color: theme.palette.ranked.main,
              }}
            >
              Ranked
            </Typography>
          ) : null}
          {r.ghostChallenge ? (
            <BiGhost
              style={{
                fontSize: '18px',
                color: theme.palette.warning.main,
              }}
            />
          ) : null}
          {r.matchMaking ? (
            <Tooltip title="This game was Searched" placement="bottom">
              <SearchIcon
                style={{
                  fontSize: '22px',
                  color: theme.palette.dailies.main,
                }}
              />
            </Tooltip>
          ) : null}
          {r.handicap ? (
            <Tooltip
              title="A handicap was set for this game"
              placement="bottom"
            >
              <Box
                sx={{
                  marginLeft: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <BalanceIcon
                  style={{
                    fontSize: '20px',
                    color: theme.palette.handicap.main,
                  }}
                />
              </Box>
            </Tooltip>
          ) : null}
          {r.variant === 'survival' && r.survivalMode === 'endurance' ? (
            <Tooltip title="Survival - Endurance" placement="bottom">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <GiRaiseZombie
                  style={{
                    fontSize: '20px',
                    color: theme.palette.survival.main,
                  }}
                />
              </Box>
            </Tooltip>
          ) : null}
          {r.variant === 'survival' && r.survivalMode === 'challenge' ? (
            <Tooltip title="Survival - Challenge Tower" placement="bottom">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <GiStoneTower
                  style={{
                    fontSize: '16px',
                    color: theme.palette.survival.main,
                  }}
                />
              </Box>
            </Tooltip>
          ) : null}
          {r.variant === 'survival' && r.survivalMode === 'demon' ? (
            <Tooltip title="Survival - Demons" placement="bottom">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <GiDevilMask
                  style={{
                    fontSize: '18px',
                    color: theme.palette.demon.main,
                  }}
                />
              </Box>
            </Tooltip>
          ) : null}
        </Stack>
        {r.customGameName || 'N/A'}
      </Stack>
    </Tooltip>
  );

  const handleUsernameClick = async (e: any, player: Player) => {
    e.stopPropagation();
    navigate(`/profile/overview/${player.profileId}`);
  };

  const getJoinedPlayers = (game: GameDb) => {
    if (!game.players || Object.keys(game.players).length === 0) return '--';
    const rankedPlayers = assignRanks(
      Object.values(game.players).sort((a, b) => a.parScore - b.parScore)
    );
    return (
      <Stack width={'100%'}>
        {rankedPlayers.map((p, i) => (
          <Link
            key={i}
            component="button"
            variant="body2"
            onClick={(e) => handleUsernameClick(e, p)}
            textAlign={'left'}
          >
            <Stack direction={'row'} sx={{ whiteSpace: 'nowrap' }}>
              {game.mode === 'sit-n-go' ? `${p.rank}.` : ''} {p.username}
              {(game.mode === 'match' || game.mode === 'sit-n-go') &&
              game.ranked ? (
                <Stack direction={'row'}>
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    sx={{ color: theme.palette.ranked.main }}
                  >
                    &nbsp;(
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    sx={{ color: ratingColor(p) }}
                  >
                    {getRatingNextToUsername(p)}
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    sx={{ color: theme.palette.ranked.main }}
                  >
                    )
                  </Typography>
                </Stack>
              ) : null}
              {game.matchMaking && game.matchMakingSeededBy === p.profileId ? (
                <Tooltip title="Seeded this match" placement="top">
                  <Box
                    sx={{
                      marginLeft: 1,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FaSeedling
                      style={{
                        fontSize: '14px',
                        color: theme.palette.success.main,
                      }}
                    />
                  </Box>
                </Tooltip>
              ) : null}
            </Stack>
          </Link>
        ))}
      </Stack>
    );
  };

  const ratingColor = (player: Player) => {
    if (Math.round(player.rankedRatingDiff || 0) > 0)
      return theme.palette.success.main;
    if (Math.round(player.rankedRatingDiff || 0) < 0)
      return theme.palette.error.main;
    return theme.palette.warning.main;
  };

  const getRatingNextToUsername = (player: Player) => {
    const formatRating = (rating: number) => {
      const rounded = Math.round(rating);
      return `${rounded > 0 ? '+' : ''}${rounded}`;
    };
    return formatRating(player.rankedRatingDiff || 0);
  };

  const getGameScores = (data: GameDb) => {
    if (
      data &&
      data.players &&
      (data.mode === 'solo' || data.mode === 'sit-n-go')
    ) {
      const playerMe = data.players[viewProfile._id];
      const myScore = playerMe?.parScore;
      if (myScore === undefined) return '';
      return `${myScore > 0 ? '+' + myScore : myScore}`;
    }
    if (data.mode !== 'solo') {
      const playerMe = data.players[viewProfile._id];
      const myScore = playerMe?.parScore;
      if (!myScore === undefined) return '';
      const playerThemProfileId = Object.keys(data.players).find(
        (profileId) => profileId !== viewProfile._id
      );
      const playerThem = playerThemProfileId
        ? data.players[playerThemProfileId]
        : undefined;
      const theirScore = playerThem?.parScore;
      if (theirScore === undefined) return '';
      return `${myScore > 0 ? '+' + myScore : myScore} to ${
        theirScore > 0 ? '+' + theirScore : theirScore
      }`;
    }
  };

  const handlePaginateOnChange = (event: any, pageNum: number) => {
    setPageNum(pageNum);
  };

  return (
    <Box pt={2}>
      <Stack
        direction={'row'}
        pt={2}
        justifyContent={'space-between'}
        alignContent={'end'}
      >
        <Typography py={1} fontWeight={500}>
          Recent Games
        </Typography>

        {gamesCount ? (
          <Pagination
            count={Math.ceil(gamesCount / 10)}
            onChange={handlePaginateOnChange}
            page={pageNum}
          />
        ) : null}
      </Stack>

      <TableContainer
        sx={{
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={'15%'} style={{ fontWeight: 600 }}>
                Name
              </TableCell>
              <TableCell width={'15%'} style={{ fontWeight: 600 }}>
                Mode
              </TableCell>
              <TableCell width={'8%'} style={{ fontWeight: 600 }}>
                Players
              </TableCell>
              <TableCell width={'10%'} style={{ fontWeight: 600 }}>
                Scores
              </TableCell>
              <TableCell width={'10%'} style={{ fontWeight: 600 }}>
                Results
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gameRows?.map((row: GameDb) => (
              <TableRow
                hover
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                // onClick={() => handleRowClick(row)}
                // sx={{ cursor: 'pointer' }}
              >
                <TableCell>{stackName(row)}</TableCell>
                <TableCell>{createOptionsLabel(row)}</TableCell>
                <TableCell>{getJoinedPlayers(row)}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {getGameScores(row)}
                </TableCell>
                <TableCell>{results(row)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
