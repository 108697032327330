import React, { useEffect, useState } from 'react';
import { Avatar, Box, Chip, Typography } from '@mui/material';
import { resizeImage } from '../../../api/cloudinary';

type PropTypes = {
  avatarPublicId?: string;
  playerThemGuess?: string;
};

export const RevealGuess = ({ avatarPublicId, playerThemGuess }: PropTypes) => {
  const [guessCache, setGuessCache] = useState<string[]>([]);

  useEffect(() => {
    if (!playerThemGuess) return;
    setGuessCache((prev) => prev.concat(playerThemGuess));
    setTimeout(() => {
      setGuessCache((prev) => prev.slice(1));
    }, 3000);
  }, [playerThemGuess]);

  return (
    <>
      {guessCache.map((guess, index) => (
        <Chip
          key={index}
          avatar={
            <Avatar
              alt="Natacha"
              src={avatarPublicId ? resizeImage(avatarPublicId, 100, 100) : ''}
            />
          }
          label={
            <Box sx={{ display: 'flex' }}>
              <Typography variant="subtitle1" marginRight={1}>
                incorrectly guessed{' '}
              </Typography>
              <Typography variant="h6" color="warning.main">
                {guess}
              </Typography>
            </Box>
          }
          variant="outlined"
          sx={{
            width: 'fit-content',
            alignSelf: 'center',
          }}
        />
      ))}
    </>
  );
};
