import { useMutation } from '@tanstack/react-query';
import { postArenaSchedule } from '../../../api/apiServices';

const useMutateArenaSchedule = (handleOnSuccess: () => void) => {
  const { mutate } = useMutation({
    mutationFn: postArenaSchedule,
    onSuccess: handleOnSuccess,
  });

  return {
    postArenaSchedule: mutate,
  };
};

export default useMutateArenaSchedule;
