import { LoggedInUser } from '../../types/user';

export const sortUsers = (loggedInUsers?: LoggedInUser[]) => {
  if (!loggedInUsers) return [];
  const onlineUsers = loggedInUsers
    .filter((u) => u.online && !u.isIdle)
    .sort((a, b) => b.loggedInAt - a.loggedInAt);
  const idleUsers = loggedInUsers
    .filter((u) => u.online && u.isIdle)
    .sort((a, b) => b.loggedInAt - a.loggedInAt);
  const offlineUsers = loggedInUsers
    .filter((u) => !u.online)
    .sort((a, b) => b.disconnectedAt - a.disconnectedAt);
  return onlineUsers.concat(idleUsers).concat(offlineUsers);
};
