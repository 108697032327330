import { useMutation } from '@tanstack/react-query';
import { postCategoryBans } from '../../../api/apiServices';

const usePostCategoryBans = (handleJoinRequestSuccess: () => void) => {
  const { mutate, isPending } = useMutation({
    mutationFn: postCategoryBans,
    onSuccess: handleJoinRequestSuccess,
  });

  return {
    mutate,
    isPending,
  };
};

export default usePostCategoryBans;
