import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';

type PropTypes = {
  close: () => void;
  message?: string;
  open: boolean;
  title?: string;
};

export const ErrorMessageDialog = ({
  close,
  message,
  open,
  title,
}: PropTypes) => {
  return (
    <Dialog open={open} onClose={close} maxWidth={'md'}>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="h5">{title || 'Hmm...'}</Typography>
          <Typography>{message}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close} fullWidth>
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
};
