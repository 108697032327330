import {
  keyframes,
  styled,
  Tooltip,
  Typography,
  typographyClasses,
  useTheme,
} from '@mui/material';
import React from 'react';
import './styles.css';
import { getAveName, getAveShineColor } from './helpers';
import { Mode, Team } from '../../../types/game';
import { Player } from '../../../types/player';

const shine = keyframes`
  0% {
    background-position: -400px;
  }
  100% {
    background-position: 400px;
  }
`;

type PropTypes = {
  abbreviateAves?: boolean;
  mode?: Mode;
  myTeam?: Team;
  player: Player;
  smallFont?: boolean;
  spectate?: boolean;
};

export const CorrectAnswer = ({
  abbreviateAves,
  mode,
  myTeam,
  player,
  smallFont,
  spectate,
}: PropTypes) => {
  const theme = useTheme();
  const formattedScore = () => {
    if (!spectate && mode === 'teams' && myTeam) {
      return myTeam.currentScore && myTeam.currentScore > 0
        ? `+${myTeam.currentScore}`
        : myTeam.currentScore;
    }
    return player.currentScore && player.currentScore > 0
      ? `+${player.currentScore}`
      : player.currentScore;
  };
  const msgColor =
    player.correctAnswer || myTeam?.correctAnswer
      ? 'success.main'
      : 'error.main';

  const ShinyTypography = styled(Typography)(() => ({
    [`&.${typographyClasses.root}`]: {
      fontSize: smallFont ? 18 : 24,
      letterSpacing: 2,
      animation: `${shine} 3s infinite`,
      backgroundClip: 'text',
      // backgroundImage:
      //   'linear-gradient(-40deg, transparent 0%, transparent 40%, #fff 50%, transparent 60%, transparent 100%)',
      backgroundRepeat: 'no-repeat',
      color: getAveShineColor(
        getAveName(player.currentScore || 0),
        theme.palette.mode
      ),
    },
  }));

  const showPlayerAve = (player: Player) =>
    player.currentScore !== null &&
    player.currentScore !== undefined &&
    player.currentScore < 2;

  const showTeamAve = (team: Team) =>
    team.currentScore !== null &&
    team.currentScore !== undefined &&
    team.currentScore < 2;

  return (
    <>
      {!spectate && mode === 'teams' && myTeam ? (
        <>
          {myTeam.correctAnswer ? (
            showTeamAve(myTeam) ? (
              <Tooltip
                title={abbreviateAves ? getAveName(myTeam.currentScore) : ''}
                placement="left-start"
              >
                <ShinyTypography
                  padding={1}
                  color={msgColor}
                  textAlign={'center'}
                >
                  {abbreviateAves
                    ? getAveName(myTeam.currentScore).toUpperCase().slice(0, 3)
                    : getAveName(myTeam.currentScore).toUpperCase()}{' '}
                  ({formattedScore()})
                </ShinyTypography>
              </Tooltip>
            ) : (
              <Typography padding={1} color={msgColor} textAlign={'center'}>
                You got a{formattedScore()}
              </Typography>
            )
          ) : null}
          {myTeam.timerLimitReached ? (
            <Typography
              padding={spectate ? 1 : 2}
              color={msgColor}
              textAlign={'center'}
            >
              Time is up
            </Typography>
          ) : null}
        </>
      ) : (
        <>
          {player.correctAnswer ? (
            showPlayerAve(player) ? (
              <Tooltip
                title={abbreviateAves ? getAveName(player.currentScore) : ''}
                placement="left-start"
              >
                <ShinyTypography
                  padding={1}
                  color={msgColor}
                  textAlign={'center'}
                >
                  {abbreviateAves
                    ? getAveName(player.currentScore).toUpperCase().slice(0, 3)
                    : getAveName(player.currentScore).toUpperCase()}{' '}
                  ({formattedScore()})
                </ShinyTypography>
              </Tooltip>
            ) : (
              <Typography padding={1} color={msgColor} textAlign={'center'}>
                {!spectate ? 'You got a ' : ''}
                {formattedScore()}
              </Typography>
            )
          ) : null}
          {player.timerLimitReached ? (
            <Typography
              padding={spectate ? 1 : 2}
              color={msgColor}
              textAlign={'center'}
            >
              {!spectate ? 'Your time is up' : 'Time is up'}
            </Typography>
          ) : null}
        </>
      )}
    </>
  );
};
