import { useApi } from './useApi';
import { useCallback, useEffect, useState } from 'react';
import { useSocketContext } from '../contexts/SocketProvider';

export const useClientVersion = () => {
  const { data, error, loading, sendRequest } = useApi<{
    clientVersion: number;
  }>();
  const { socket, disconnectSocket } = useSocketContext();
  const [latestClientVersion, setLatestClientVersion] = useState();
  const [refreshBrowser, setRefreshBrowser] = useState(false);

  useEffect(() => {
    if (!socket) return;

    socket.on('latestClientVersion', setLatestClientVersion);

    return () => {
      socket.off('latestClientVersion', setLatestClientVersion);
    };
  }, [socket]);

  useEffect(() => {
    if (!latestClientVersion || !data) return;
    if (latestClientVersion !== data.clientVersion) {
      disconnectSocket();
      setRefreshBrowser(true);
    }
  }, [latestClientVersion, data]);

  const fetchClientVersion = useCallback(() => {
    sendRequest({
      url: `/config/client-version`,
    });
  }, [sendRequest]);

  return {
    fetchClientVersion,
    refreshBrowser,
  };
};
