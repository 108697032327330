import React from 'react';
import { Box, Button, Divider, Drawer, Stack, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { HeaderTabs } from '../tabs';
import { useLogout } from '../../../hooks/auth/useLogout';
import { LobbyGame } from '../../../types/game';
import { Profile } from '../../../types/player';
import { DrawerHeader } from './drawer-header';
import { FaDiscord, FaXTwitter } from 'react-icons/fa6';
import { SocialMediaIconButton } from '../../../custom-components/custom-icon-buttons';
import { useProfileContext } from '../../../contexts/ProfileProvider';

type PropTypes = {
  closeDrawer: () => void;
  mobileOpen: boolean;
  profile: Profile;
  openLeagueGames?: LobbyGame[];
  handleDrawerToggle: () => void;
  setMobileOpen: (open: boolean) => void;
};
export const HeaderDrawerMenu = ({
  closeDrawer,
  mobileOpen,
  profile,
  openLeagueGames,
  handleDrawerToggle,
  setMobileOpen,
}: PropTypes) => {
  const navigate = useNavigate();

  const { clearProfile } = useProfileContext();

  const onLogoutSuccess = () => {
    clearProfile();
    navigate('/login');
  };

  const { mutate: logout } = useLogout(onLogoutSuccess);

  const handleRegistrationClick = () => navigate('/league-registration');
  const handleUpgradeClick = () => navigate('/pricing');

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <Drawer
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', lg: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
      }}
    >
      <Stack spacing={1} textAlign={'left'}>
        <DrawerHeader handleCloseUserMenu={closeDrawer} profile={profile} />
        <Divider />
        <HeaderTabs
          profile={profile}
          orientation="vertical"
          onNavigated={closeDrawer}
        />
        {/* <Box mx={2}>
          <Stack spacing={2} marginX={2}>
            <Button
              variant="outlined"
              color="creamOrange"
              onClick={handleUpgradeClick}
              sx={{ letterSpacing: 2 }}
            >
              BUY LEAGUE PASS
            </Button>
          </Stack>
        </Box> */}
        <Divider>
          <Typography variant="overline">Follow us</Typography>
        </Divider>
        <Stack
          direction="row"
          spacing={3}
          alignItems={'center'}
          justifyContent={'center'}
          px={2}
          width={'100%'}
          pb={1}
        >
          <SocialMediaIconButton
            brandColor="#5865F2"
            link="https://discord.gg/upX48KPfHd"
            icon={<FaDiscord />}
          />
          <SocialMediaIconButton
            brandColor="#000000"
            link="https://twitter.com/ParTrivia"
            icon={<FaXTwitter />}
          />
        </Stack>
        <Divider />
        <Box alignSelf={'flex-start'} px={2}>
          <Button
            variant="text"
            startIcon={<LogoutIcon />}
            onClick={handleLogoutClick}
          >
            Logout
          </Button>
        </Box>
      </Stack>
    </Drawer>
  );
};
