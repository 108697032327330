import React from 'react';
import { Paper, Typography, useTheme } from '@mui/material';
import {
  boxShadowGameLetters,
  boxShadowGameLettersReveal,
} from './custom-style';

type PropTypes = {
  correctAnswer: boolean;
  displaySize: 'sm' | 'md' | 'lg';
  entireAnswerDisplayed: boolean;
  letter: string;
  letterIndex: number;
  spectate?: boolean;
  timerLimitReached: boolean;
  underline: boolean;
  unrevealedIndex?: number[];
  wordIndex: number;
};

export const Letter = ({
  correctAnswer,
  displaySize,
  entireAnswerDisplayed,
  letter,
  letterIndex,
  spectate,
  timerLimitReached,
  underline,
  unrevealedIndex,
  wordIndex,
}: PropTypes) => {
  const theme = useTheme();

  const isUnrevealedLetter = () =>
    unrevealedIndex &&
    wordIndex === unrevealedIndex[0] &&
    letterIndex === unrevealedIndex[1];

  const borderShadowLetters = () => {
    if (spectate) return boxShadowGameLetters(theme.palette.primary);
    if (entireAnswerDisplayed && correctAnswer)
      return boxShadowGameLettersReveal(theme.palette.success);
    if (timerLimitReached && !correctAnswer)
      return boxShadowGameLettersReveal(theme.palette.error);
    return boxShadowGameLetters(theme.palette.primary);
  };

  const underlineBorderBottom = underline ? '3px solid' : null;

  return (
    <>
      <Paper
        elevation={6}
        className={`${isUnrevealedLetter() ? 'answer-letter-animation' : ''}`}
        variant={timerLimitReached && !correctAnswer ? 'error' : 'elevation'}
        sx={{
          width:
            displaySize === 'sm'
              ? '30px'
              : displaySize === 'md'
              ? '35px'
              : '40px',
          height:
            displaySize === 'sm'
              ? '30px'
              : displaySize === 'md'
              ? '35px'
              : '40px',
          alignContent: 'center',
          display: { xs: 'none', sm: 'grid' },
          boxShadow: borderShadowLetters(),
          borderBottom: underline ? '1px solid #e7ff00' : 'inherit',
          backgroundColor: isUnrevealedLetter()
            ? '#de89a17a'
            : 'rgba(18, 18, 18, 0.9)',
          marginTop: '8px',
        }}
      >
        <Typography
          textAlign="center"
          variant={
            displaySize === 'sm' ? 'h6' : displaySize === 'md' ? 'h5' : 'h4'
          }
        >
          {letter === '' ? ' ' : letter}
        </Typography>
      </Paper>

      {/* Mobile version: */}
      <Paper
        elevation={6}
        className={`${isUnrevealedLetter() ? 'answer-letter-animation' : ''}`}
        sx={{
          width: '30px',
          height: '30px',
          alignContent: 'center',
          display: { xs: 'grid', sm: 'none' },
          boxShadow: borderShadowLetters(),
          borderBottom: underlineBorderBottom,
          backgroundColor: isUnrevealedLetter() ? 'secondary.main' : 'initial',
          marginTop: '8px',
        }}
      >
        <Typography textAlign="center" variant="h6">
          {letter === '' ? ' ' : letter}
        </Typography>
      </Paper>
    </>
  );
};
