import { useApi } from '../useApi';
import { useEffect } from 'react';
import { useProfileContext } from '../../contexts/ProfileProvider';
import { Profile } from '../../types/player';
import { Methods } from './constants';

export const useAcceptInvite = () => {
  const { refetch } = useProfileContext();
  const { data, error, loading, sendRequest } = useApi();

  useEffect(() => {
    if (data && !error) {
      refetch();
    }
  }, [data, error, refetch]);

  const acceptInvite = (token: string, password: string) => {
    sendRequest({
      url: `/authenticate/accept-invite/`,
      method: Methods.POST,
      body: { token, password },
    });
  };

  return { data, error, loading, acceptInvite };
};
