import React, {
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  IconButton,
  InputBase,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { ChatChannel, ChatMessage } from '../../../types/chat';
import ChatMessages from './ChatMessages';
import { useChats } from './hooks/useChats';
import { canLeaveChat } from '../../../utils/loggedInUser';
import { LoggedInUser } from '../../../types/user';
import { useLocation } from 'react-router-dom';
import { CHAT_COLORS } from '../../../constants/constants';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RemoveIcon from '@mui/icons-material/Remove';
import { CustomTabItem } from './CustomTab';

type PropTypes = {
  inputFocused: boolean;
  loggedInUser: LoggedInUser;
  onInputFocus: (focused: boolean) => void;
  profileId: string;
  username: string;
};

const Chats = ({ inputFocused, onInputFocus, username }: PropTypes) => {
  const theme = useTheme();

  const [chatChannel, setChatChannel] = useState<ChatChannel>('general');
  const chatChannelRef = useRef(chatChannel);

  const {
    chatArena,
    chatGame,
    chatGeneral,
    chatMembers,
    emitChatGeneral,
    resetUnreadChats,
    spectatorChat,
    totalUnread,
  } = useChats(inputFocused, chatChannel);

  const [inputHeight, setInputHeight] = useState(56);
  const [showTimestamp, setShowTimestamp] = useState(false);
  const [colorAssignment, setColorAssignment] = useState<
    Record<string, string>
  >({});

  const inputBaseRef = useRef<HTMLInputElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!boxRef.current) return;
    setTimeout(() => {
      scrollToBottomOfChat();
    }, 500);
  }, [boxRef.current]);

  // useEffect(() => {
  //   if (showSpectatorChat) {
  //     setTimeout(() => {
  //       requestSpectatorChatHistory();
  //       setChatChannel('spectate');
  //       chatChannelRef.current = 'spectate';
  //       focusInputBase();
  //     }, 200);
  //   } else {
  //     setChatChannel('general');
  //     chatChannelRef.current = 'general';
  //   }
  // }, [showSpectatorChat]);

  useEffect(() => {
    if (!inputFocused) return;
    resetUnreadChats(chatChannel);
  }, [inputFocused, chatChannel]);

  // Because of multiline input we need to dynamically adjust the height of the messages box area
  useEffect(() => {
    if (inputBaseRef.current) {
      setInputHeight(
        inputBaseRef.current.clientHeight === 0
          ? 56
          : inputBaseRef.current.clientHeight
      );
    }
  }, []);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (!inputBaseRef.current?.value) return;
        const text = inputBaseRef.current.value;
        if (chatChannelRef.current === 'general') {
          emitChatGeneral(text, username);
        }
        inputBaseRef.current.value = '';
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [emitChatGeneral]);

  useEffect(() => {
    if (!chatMembers) return;
    assignColorToUsernames(chatMembers);
  }, [chatMembers]);

  const handleInput = () => {
    if (inputBaseRef.current) {
      setInputHeight(inputBaseRef.current.clientHeight);
    }
  };

  const focusInputBase = () => inputBaseRef.current?.focus();

  const chatMessages = useMemo(
    () =>
      chatChannel === 'general'
        ? chatGeneral
        : chatChannel === 'arena'
        ? chatArena
        : chatChannel === 'game'
        ? chatGame
        : chatChannel === 'spectate'
        ? spectatorChat
        : [],
    [chatChannel, chatGeneral, chatGame, spectatorChat, chatArena]
  );

  useEffect(() => {
    scrollToBottomOfChat();
  }, [chatMessages]);

  const assignColorToUsernames = (
    chatMembers: {
      profileId: string;
      username: string;
    }[]
  ) => {
    const colorAssignment: Record<string, string> = {};
    let colorIndex = 0;

    chatMembers.forEach(({ username }) => {
      colorAssignment[username] = CHAT_COLORS[colorIndex % CHAT_COLORS.length];
      colorIndex++;
    });

    setColorAssignment(colorAssignment);
  };

  const scrollToBottomOfChat = () => {
    const scrollHeight = boxRef.current?.scrollHeight;
    if (scrollHeight && boxRef?.current) {
      boxRef.current.scrollTop = scrollHeight;
    }
  };

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => onInputFocus(false)}
    >
      <Box
        width={'100%'}
        sx={{
          position: 'absolute',
          bottom: 0,
        }}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          pl={1}
          bgcolor={'black'}
        >
          <Typography fontWeight={600} color="secondary">
            All Chat
          </Typography>
          <IconButton onClick={() => onInputFocus(false)} color="secondary">
            <RemoveIcon />
          </IconButton>
        </Stack>

        <Stack
          direction={'row'}
          onClick={!inputFocused ? focusInputBase : undefined}
        >
          <Box
            ref={boxRef}
            height={`calc(400px - ${inputHeight}px - 88px)`}
            bgcolor={'black'}
            overflow={'auto'}
            width={'100%'}
            position="relative"
          >
            <Box
              width={'100%'}
              height={'100%'}
              p={0.5}
              position={'absolute'}
              bottom={0}
              onMouseEnter={() => setShowTimestamp(true)}
              onMouseLeave={() => setShowTimestamp(false)}
            >
              <ChatMessages
                chatChannel={chatChannel}
                colorAssignment={colorAssignment}
                chatMessages={chatMessages}
                showTimestamp={showTimestamp}
              />
            </Box>
          </Box>
        </Stack>

        <Box
          sx={{
            width: '100%',
            px: 1.5,
            height: '35px',
            display: 'flex',
            alignItems: 'center',
            bgcolor: '#0c171e',
          }}
        >
          {totalUnread ? (
            <Box
              sx={{
                pr: 1,
              }}
            >
              <Avatar
                sx={{
                  bgcolor: 'notification.main',
                  width: 20,
                  height: 20,
                }}
              >
                <Typography variant="subtitle2" color="white" fontWeight={600}>
                  {totalUnread}
                </Typography>
              </Avatar>
            </Box>
          ) : null}
          <InputBase
            type="text"
            inputRef={inputBaseRef}
            fullWidth
            onInput={handleInput}
            onFocus={() => onInputFocus(true)}
            startAdornment={
              <Typography
                fontFamily={'noto-sans'}
                sx={{
                  pr: 0.5,
                  whiteSpace: 'nowrap',
                  color: theme.palette.primary.main,
                }}
              >
                To ({chatChannel === 'arena' ? 'pub' : chatChannel}):
              </Typography>
            }
            sx={{
              fontFamily: 'noto-sans',
            }}
          />
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default Chats;
