import React, { useEffect, useState } from 'react';
import {
  Box,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Collapse,
  Stack,
  Avatar,
  Tooltip,
  Badge,
  styled,
  tableCellClasses,
  Button,
  Rating,
  CircularProgress,
  Link,
  tooltipClasses,
  TooltipProps,
  useTheme,
} from '@mui/material';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import { PlayerTableCell, formattedScore } from '.';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { CgAsterisk } from 'react-icons/cg';
import { RevealAnswerAnimation } from './RevealAnswerAnimation';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CallMadeIcon from '@mui/icons-material/CallMade';
import {
  Mode,
  Question,
  ReportInaccuracy,
  Team,
  VanquishedDemon,
} from '../../types/game';
import { DetailedPlayer } from '../../types/player';
import { isTimedOut } from './utils';
import { getAvatarSrc } from '../../api/cloudinary';
import { PrecisionGraphicScorecardIcon } from './PrecisionGraphicScorecardIcons';

import './SubmittedByStamp.scss';
import { fetchPost } from '../../api/common';
import { CustomQuestionRatingReasonDialog } from '../../dialogs/CustomQuestionRatingReasonDialog';
import { LeagueGameType } from '../../types';
import { QuestionStatsLean } from '../../types/question-stats';
import { getBarChartForScorecard } from './QuestionStatsBarChart';

const CustomQuestionTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'black',
    maxWidth: '500px',
  },
}));

const TableCellSurvivalLastQuestion = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    border: 'none',
    padding: 0,
  },
}));

type PropTypes = {
  arenaSet?: number;
  customQuestionComment?: {
    questionId: string;
    authorComment: string;
    referenceLink: string;
  };
  demons?: boolean;
  demonsVanquished?: VanquishedDemon[];
  detailedPlayers?: Record<string, DetailedPlayer>;
  displayQuestionStatSeen?: boolean;
  enableReportInaccuracy?: boolean;
  handicap?: number;
  handleDislikeQuestion: (questionId: string) => void;
  handleLikeQuestion: (questionId: string) => void;
  handleImageClick?: (imgUrl: string) => void;
  handleLastQuestionClick?: (questionId: string) => void;
  handleLowRatingClick?: (rating: number, questionId: string) => void;
  handleMouseOverBarChart: (mouseover: boolean) => void;
  handleReportInaccuracyRowClick?: (
    row: Question,
    questionIndex: number
  ) => void;
  handleRevealAnswerClick?: (index: number) => void;
  hideAnswers?: boolean;
  hideStats?: boolean;
  index: number;
  league?: boolean;
  leagueGameType?: LeagueGameType;
  loadingQuestionStats?: boolean;
  mode?: Mode;
  myTeam?: Team;
  opponentTeam?: Team;
  playerSelf?: DetailedPlayer;
  playerThem?: DetailedPlayer;
  seededBy?: string;
  selectedPlayer?: DetailedPlayer;
  profileId: string;
  question: Question;
  questionDisliked: string[];
  questionLiked: string[];
  questionStats?: QuestionStatsLean;
  revealAnswer?: boolean;
  reportedInaccuracies?: ReportInaccuracy[];
  runningTotals: boolean;
  showAllScores: boolean;
  showDislike?: boolean;
  showRatings?: boolean;
  totalQuestions: number;
  variant?: 'normal' | 'survival' | 'starter-kit' | 'turbo';
};

export const ScorecardTableRow = ({
  arenaSet,
  customQuestionComment,
  demons,
  demonsVanquished,
  detailedPlayers,
  displayQuestionStatSeen,
  enableReportInaccuracy,
  handicap,
  handleDislikeQuestion,
  handleLikeQuestion,
  handleImageClick,
  handleLastQuestionClick,
  handleLowRatingClick,
  handleMouseOverBarChart,
  handleReportInaccuracyRowClick,
  handleRevealAnswerClick,
  hideAnswers,
  hideStats,
  index,
  league,
  leagueGameType,
  loadingQuestionStats,
  mode,
  myTeam,
  opponentTeam,
  question,
  questionDisliked,
  questionLiked,
  questionStats,
  playerSelf,
  playerThem,
  profileId,
  selectedPlayer,
  reportedInaccuracies,
  revealAnswer,
  runningTotals,
  seededBy,
  showAllScores,
  showDislike,
  showRatings,
  totalQuestions,
  variant,
}: PropTypes) => {
  const theme = useTheme();

  const [expandRow, setExpandRow] = useState<Record<number, boolean>>({});
  const [showDownArrow, setShowDownArrow] = useState<number>();
  const [showUpArrow, setShowUpArrow] = useState<number>();
  const [customQuestionRating, setCustomQuestionRating] = useState(
    questionStats?.stars?.find((s) => s.profileId === profileId)?.rating || 0
  );
  const [openRatingReasonDialog, setOpenRatingReasonDialog] = useState({
    open: false,
    rating: null,
    questionId: null,
  });

  const playersCount = Object.values(detailedPlayers).length;

  useEffect(() => {
    const updated: Record<number, boolean> = {};
    for (let i = 0; i < totalQuestions; i++) {
      updated[i] = showAllScores;
    }
    setExpandRow(updated);
    setShowDownArrow(undefined);
    setShowUpArrow(undefined);
  }, [showAllScores]);

  useEffect(() => {
    if (!questionStats || !profileId) return;
    const found = questionStats?.stars?.find(
      (s) => s.profileId === profileId
    )?.rating;
    setCustomQuestionRating(found);
  }, [questionStats, profileId]);

  const underParScoreBallStyle = {
    bgcolor: `${theme.palette.success.main}40`,
    color: 'text.primary',
    textAlign: 'center',
    boxShadow: `0 0 5px 1px ${theme.palette.success.main}, 0 0 2px 3px ${theme.palette.success.main} inset`,
  };

  const timedoutScoreBallStyle = {
    bgcolor: `${theme.palette.error.main}40`,
    color: 'error.contrastText',
    display: 'inline-flex',
    boxShadow: `0 0 5px 1px ${theme.palette.error.main}, 0 0 2px 3px ${theme.palette.error.main} inset`,
  };

  const getPlayerRunningTotalScore = (
    player: DetailedPlayer,
    index: number,
    handicap?: number,
    seededBy?: string
  ) => {
    let total = 0;
    if (handicap && seededBy && player.profileId !== seededBy) {
      total = handicap;
    }
    for (let i = 0; i <= index; i++) {
      total =
        total +
        player.roundScores[i].score +
        player.roundScores[i].penalty +
        (player.roundScores[i].fuzzyPenalty || 0);
    }
    return total;
  };

  const getTeamRunningTotalScore = (team: Team, index: number) => {
    let total = 0;
    for (let i = 0; i <= index; i++) {
      total =
        total +
        team.roundScores[i].score +
        team.roundScores[i].penalty +
        (team.roundScores[i].fuzzyPenalty || 0);
    }
    return total;
  };

  const answerAttemptTooltip = (
    questionIndex: number,
    player?: DetailedPlayer
  ) => {
    return (
      <Stack spacing={2} sx={{ padding: 1 }}>
        <Typography textAlign="center">Answer Attempts</Typography>
        {!player ? (
          <Typography variant="body1">None</Typography>
        ) : (
          player.roundScores[questionIndex].answerAttempts.map((answer, i) => (
            <Stack key={i} direction="row" spacing={3} minWidth={'250px'}>
              <Typography
                variant="body1"
                textAlign={'right'}
                sx={{
                  width: '40%',
                  color:
                    theme.palette.mode === 'light'
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  textShadow:
                    theme.palette.mode === 'dark'
                      ? `0 0 1px ${theme.palette.primary.main}, 10px 0px 20px ${theme.palette.primary.main}, 0px 0px 16px ${theme.palette.primary.main}`
                      : 'none',
                }}
              >
                {answer.gameClock.toFixed(2)} sec
              </Typography>
              <Typography
                variant="body1"
                textAlign={'left'}
                sx={{ width: '60%' }}
              >
                "{answer.input}"
              </Typography>
            </Stack>
          ))
        )}
      </Stack>
    );
  };

  const TeamAnswerAttemptsTooltip = styled(
    ({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    )
  )({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  const teamAnswerAttemptTooltip = (questionIndex: number, team?: Team) => {
    return (
      <Stack spacing={2} sx={{ padding: 1 }}>
        <Typography textAlign="center">Answer Attempts</Typography>
        {!team || !team.roundScores[questionIndex].answerAttempts ? (
          <Typography variant="body1">None</Typography>
        ) : (
          team.roundScores[questionIndex].answerAttempts.map((answer) => (
            <Stack
              key={answer.username}
              direction="row"
              spacing={3}
              minWidth={'400px'}
            >
              <Typography
                variant="body1"
                textAlign={'right'}
                fontWeight={600}
                sx={{
                  width: '100px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'pre',
                }}
              >
                {answer.username}
              </Typography>
              <Typography
                variant="body1"
                textAlign={'center'}
                sx={{
                  color:
                    theme.palette.mode === 'light'
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  textShadow:
                    theme.palette.mode === 'dark'
                      ? `0 0 1px ${theme.palette.primary.main}, 10px 0px 20px ${theme.palette.primary.main}, 0px 0px 16px ${theme.palette.primary.main}`
                      : 'none',
                }}
              >
                {answer.gameClock.toFixed(2)} sec
              </Typography>
              <Typography variant="body1" textAlign={'center'}>
                "{answer.input}"
              </Typography>
            </Stack>
          ))
        )}
      </Stack>
    );
  };

  const alternateAnswersTooltip = (alternateAnswers: string[]) => {
    const filtered = alternateAnswers.filter((a) => !!a);
    return (
      <Stack spacing={2}>
        <Typography textAlign="center">Alternate Answers</Typography>
        {!filtered.length ? (
          <Typography variant="body1" textAlign="center">
            none
          </Typography>
        ) : (
          filtered.map((a) => (
            <Typography
              key={a}
              variant="body1"
              textAlign="center"
              sx={{
                color: theme.palette.secondary.main,
              }}
            >
              {a}
            </Typography>
          ))
        )}
      </Stack>
    );
  };

  const formatPlayerScore = (
    index: number,
    par: number,
    precisionIconLocation: 'corner' | 'bottom',
    player?: DetailedPlayer
  ) => {
    if (!player || !player.roundScores[index]) return 'N/A';
    const score = runningTotals
      ? getPlayerRunningTotalScore(player, index, handicap, seededBy)
      : player.roundScores[index].score;

    let scoreStyle: any = {
      bgcolor: theme.palette.mode === 'dark' ? '#434343' : 'undefined',
      color: 'error.contrastText',
    };
    let avatarShape: any = 'rounded';
    if (score < 0 && !runningTotals) {
      scoreStyle = underParScoreBallStyle;
      avatarShape = 'round';
    }
    if (isTimedOut(par, score, leagueGameType, arenaSet) && !runningTotals) {
      scoreStyle = timedoutScoreBallStyle;
      avatarShape = 'square';
    }
    if (runningTotals) {
      scoreStyle = {
        bgcolor: theme.palette.mode === 'dark' ? 'transparent' : 'undefined',
        color: 'error.contrastText',
        display: 'inline-flex',
      };
    }
    return (
      <Stack
        spacing={precisionIconLocation === 'bottom' ? 2 : 0}
        sx={{
          position: precisionIconLocation === 'bottom' ? 'relative' : 'none',
          alignItems: 'center',
        }}
      >
        <Badge badgeContent={player.roundScores[index].penalty} color="warning">
          <Tooltip placement="top" title={answerAttemptTooltip(index, player)}>
            <Avatar sx={scoreStyle} variant={avatarShape}>
              {formattedScore(score)}
            </Avatar>
          </Tooltip>
        </Badge>
        {player.roundScores[index].hasOwnProperty('fuzzyPenalty') &&
        player.roundScores[index].fuzzyPenalty !== null ? (
          <PrecisionGraphicScorecardIcon
            fuzzyPenalty={player.roundScores[index].fuzzyPenalty}
            position={
              precisionIconLocation === 'bottom' ? 'relative' : 'absolute'
            }
          />
        ) : null}
      </Stack>
    );
  };

  const formatTeamScore = (
    index: number,
    par: number,
    precisionIconLocation: 'corner' | 'bottom',
    team?: Team
  ) => {
    if (!team || !team.roundScores[index]) return 'N/A';
    const score = runningTotals
      ? getTeamRunningTotalScore(team, index)
      : team.roundScores[index].score;

    let scoreStyle: any = {
      bgcolor: theme.palette.mode === 'dark' ? '#434343' : 'undefined',
      color: 'error.contrastText',
    };
    let avatarShape: any = 'rounded';
    if (score < 0 && !runningTotals) {
      scoreStyle = underParScoreBallStyle;
      avatarShape = 'round';
    }
    if (isTimedOut(par, score, leagueGameType, arenaSet) && !runningTotals) {
      scoreStyle = timedoutScoreBallStyle;
      avatarShape = 'square';
    }
    if (runningTotals) {
      scoreStyle = {
        bgcolor: theme.palette.mode === 'dark' ? 'transparent' : 'undefined',
        color: 'error.contrastText',
        display: 'inline-flex',
      };
    }
    return (
      <Stack
        spacing={precisionIconLocation === 'bottom' ? 2 : 0}
        sx={{
          position: precisionIconLocation === 'bottom' ? 'relative' : 'none',
          alignItems: 'center',
        }}
      >
        <Badge badgeContent={team.roundScores[index].penalty} color="warning">
          <TeamAnswerAttemptsTooltip
            placement="top"
            title={teamAnswerAttemptTooltip(index, team)}
          >
            <Avatar sx={scoreStyle} variant={avatarShape}>
              {formattedScore(score)}
            </Avatar>
          </TeamAnswerAttemptsTooltip>
        </Badge>
        {team.roundScores[index].hasOwnProperty('fuzzyPenalty') &&
        team.roundScores[index].fuzzyPenalty !== null ? (
          <PrecisionGraphicScorecardIcon
            fuzzyPenalty={team.roundScores[index].fuzzyPenalty}
            position={
              precisionIconLocation === 'bottom' ? 'relative' : 'absolute'
            }
          />
        ) : null}
      </Stack>
    );
  };

  const handleRowClick = () => {
    if (mode !== 'sit-n-go') return;
    setExpandRow((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
    setShowDownArrow(undefined);
    setShowUpArrow(undefined);
  };

  const handleMouseEnter = () => {
    if (mode !== 'sit-n-go' || Object.keys(detailedPlayers).length > 10) return;
    if (!expandRow[index]) {
      setShowDownArrow(index);
    } else {
      setShowDownArrow(undefined);
      setShowUpArrow(undefined);
    }
  };

  const handleMouseLeave = () => {
    if (mode !== 'sit-n-go' || Object.keys(detailedPlayers).length > 10) return;
    if (expandRow[index]) {
      setShowUpArrow(index);
    } else {
      setShowDownArrow(undefined);
    }
  };

  const handleStarredCustomQuestion = async (
    value: number,
    questionId: string
  ) => {
    if (value === 0) return;
    if (value < 2) {
      setOpenRatingReasonDialog({ open: true, rating: value, questionId });
      return;
    }
    setCustomQuestionRating(value);
    const res = await fetchPost('/stats/stars/', {
      profileId: profileId,
      questionId: questionId,
      stars: value,
    });
  };

  const handleLowRatingSubmit = async (
    rating: number,
    questionId: string,
    reason: string
  ) => {
    const res = await fetchPost('/stats/stars/', {
      profileId: profileId,
      questionId: questionId,
      stars: rating,
      reason: reason,
    });
    setCustomQuestionRating(rating);
  };

  const calculateColspan = () => {
    let colSpan = 6;
    if (playerThem) {
      colSpan = colSpan + 1;
    }
    if (playerSelf) {
      colSpan = colSpan + 1;
    }
    if (enableReportInaccuracy) {
      colSpan = colSpan + 1;
    }
    if (showDislike || showRatings) {
      colSpan = colSpan + 1;
    }
    return colSpan;
  };

  const alternateAnswersExists = (alternateAnswers: string[]) =>
    alternateAnswers.filter((a) => !!a).length;

  const displayQuestion = (
    question: string,
    fullQuestion?: string,
    mathFormula?: string,
    questionImgUrl?: string
  ) => {
    if (!question && !fullQuestion) return <></>;

    const questionWords = fullQuestion
      ? fullQuestion.split(' ')
      : question.split(' ');

    let words = '';
    const elements = [];
    let isBold = false;

    for (const word of questionWords) {
      if (word === '<math-formula>') {
        if (words) {
          elements.push({
            type: isBold ? 'bold' : 'text',
            content: words.trim(),
          });
          words = '';
        }
        elements.push({ type: 'math-formula' });
      } else if (word === '<image>') {
        if (words) {
          elements.push({
            type: isBold ? 'bold' : 'text',
            content: words.trim(),
          });
          words = '';
        }
        elements.push({ type: 'image' });
      } else if (word.startsWith('|') && word.endsWith('|')) {
        if (words) {
          elements.push({
            type: isBold ? 'bold' : 'text',
            content: words.trim(),
          });
          words = '';
        }
        elements.push({ type: 'bold', content: word.slice(1, -1).trim() });
        isBold = false;
      } else if (word.startsWith('|')) {
        if (words) {
          elements.push({
            type: isBold ? 'bold' : 'text',
            content: words.trim(),
          });
          words = '';
        }
        words += ' ' + word.slice(1);
        isBold = true;
      } else if (word.endsWith('|')) {
        words += ' ' + word.slice(0, -1);
        elements.push({ type: 'bold', content: words.trim() });
        words = '';
        isBold = false;
      } else {
        words += ' ' + word;
      }
    }

    if (words) {
      elements.push({ type: isBold ? 'bold' : 'text', content: words.trim() });
    }

    return (
      <>
        {elements.map((e, i) => {
          if (e.type === 'math-formula' && mathFormula) {
            return (
              <MathJax key={i} style={{ display: 'inline', fontSize: 28 }}>
                {`\\(${mathFormula}\\)`}
              </MathJax>
            );
          } else if (e.type === 'image' && questionImgUrl) {
            return (
              <Box
                key={i}
                component="img"
                src={questionImgUrl}
                alt="Question image"
              />
            );
          } else if (e.type === 'bold') {
            return (
              <Box
                key={i}
                component="span"
                style={{
                  fontWeight: 'bold',
                  color: theme.palette.dailies.main,
                }} // Change 'blue' to the desired color
              >
                {' '}
                {e.content}{' '}
              </Box>
            );
          } else {
            return (
              <Box key={i} component="span">
                {e.content}
              </Box>
            );
          }
        })}
      </>
    );
  };

  // const displayQuestion = (
  //   question: string,
  //   mathFormula?: string,
  //   submittedByUsername?: string,
  //   questionImgUrl?: string
  // ) => {
  //   const questionWords = question.split(' ');

  //   let words = '';
  //   const elements = [];
  //   for (const word of questionWords) {
  //     if (word === '<math-formula>') {
  //       words = words + ' ';
  //       elements.push(words);
  //       elements.push('<math-formula>');
  //       words = '';
  //     } else {
  //       words = words + ' ' + word;
  //     }
  //   }
  //   elements.push(words);
  //   return (
  //     <Stack direction="row" spacing={1}>
  //       {questionImgUrl ? (
  //         <>
  //           <Button onClick={() => handleImageClick(questionImgUrl)}>
  //             <Box
  //               component="img"
  //               src={questionImgUrl}
  //               alt="image"
  //               sx={{
  //                 height: '50px',
  //                 width: '50px',
  //                 objectFit: 'cover',
  //                 filter: 'grayscale(100%)',
  //               }}
  //             />
  //           </Button>
  //         </>
  //       ) : null}
  //       <Stack>
  //         {submittedByUsername ? (
  //           <Stack direction={'row'} spacing={2} width="100%">
  //             <Tooltip
  //               title={`This question was submitted by ${submittedByUsername}`}
  //               placement="top"
  //             >
  //               <Box className="submitted-by-stamp-small submitted-by-stamp-username-small">
  //                 Question by: {submittedByUsername}
  //               </Box>
  //             </Tooltip>
  //             {!!customQuestionComment?.authorComment ||
  //             !!customQuestionComment?.referenceLink ? (
  //               <CustomQuestionTooltip
  //                 title={customQuestionMoreTooltip()}
  //                 placement="top"
  //               >
  //                 <AutoStoriesIcon
  //                   sx={{
  //                     color: '#ddc6ff',
  //                     fontSize: '24px',
  //                   }}
  //                 />
  //               </CustomQuestionTooltip>
  //             ) : null}
  //           </Stack>
  //         ) : null}
  //         {elements.map((e, i) => {
  //           if (e === '<math-formula>') {
  //             return (
  //               <MathJaxContext key={i}>
  //                 <MathJax
  //                   style={{ display: 'inline', fontSize: 20 }}
  //                 >{`\\(${mathFormula}\\)`}</MathJax>
  //               </MathJaxContext>
  //             );
  //           } else {
  //             return <Box key={i}>{e}</Box>;
  //           }
  //         })}
  //       </Stack>
  //     </Stack>
  //   );
  // };

  const isDemonVanquished = (question: Question) => {
    if (!question || !demonsVanquished) return null;
    const found = demonsVanquished.find((dv) => dv.questionId === question._id);
    if (found) {
      const score = found.prevAnsweredAt - question.par;
      return `${score > 0 ? '+' : ''}${score}`;
    }
    return null;
  };

  const isDemonVanquishedTopPerformer = (question: Question) => {
    if (!question || !demonsVanquished) return false;
    const found = demonsVanquished.find((dv) => dv.questionId === question._id);
    if (found && found.topPerformer) {
      return true;
    }
    return false;
  };

  const customQuestionMoreTooltip = () => {
    if (!customQuestionComment) return null;
    return (
      <Stack
        spacing={2}
        sx={{ padding: 2, border: '1px solid', borderColor: '#d9bb6f' }}
      >
        <Stack>
          <Typography sx={{ color: '#ddc6ff' }}>
            Comment by the author:
          </Typography>
          <Typography>{customQuestionComment.authorComment}</Typography>
        </Stack>
        <Stack>
          <Stack
            direction="row"
            spacing={1}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography sx={{ color: '#ddc6ff' }}>
              Link provided by author:
            </Typography>
            <CallMadeIcon display="inline" />
          </Stack>
          <Typography>
            <Link
              target={'_blank'}
              sx={{
                cursor: 'pointer',
              }}
              href={customQuestionComment.referenceLink}
            >
              {customQuestionComment.referenceLink}
            </Link>
          </Typography>
        </Stack>
      </Stack>
    );
  };

  return (
    <>
      {openRatingReasonDialog.open && (
        <CustomQuestionRatingReasonDialog
          close={() =>
            setOpenRatingReasonDialog({
              open: false,
              rating: null,
              questionId: null,
            })
          }
          handleLowRatingSubmit={handleLowRatingSubmit}
          open={openRatingReasonDialog}
        />
      )}
      <TableRow
        hover
        sx={{
          '& > *': {
            borderBottom:
              Object.keys(detailedPlayers).length <= 10 ? 'unset' : null,
          },
          '&:hover': {
            cursor:
              mode === 'sit-n-go' && Object.keys(detailedPlayers).length <= 10
                ? 'pointer'
                : 'default',
          },
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {enableReportInaccuracy && handleReportInaccuracyRowClick ? (
          <TableCell>
            <Tooltip placement="top" title="Report Inaccuracy">
              <IconButton
                sx={{
                  padding: 0,
                  color: !!reportedInaccuracies?.find(
                    (e) => e.questionIndex === index
                  )
                    ? 'gray'
                    : 'error.main',
                }}
                onClick={() => handleReportInaccuracyRowClick(question, index)}
              >
                <FlagCircleIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        ) : null}
        {showDislike && !question.submittedByUsername ? (
          <TableCell>
            <Stack direction={'row'} spacing={1}>
              <Tooltip
                placement="top"
                title={`Don't dislike a question just because you got it wrong. Dislike objectively bad questions.`}
              >
                <IconButton
                  sx={{
                    padding: 0,
                    color: 'primary.main',
                  }}
                  onClick={() => handleDislikeQuestion(question._id)}
                  disabled={
                    questionDisliked.includes(question._id) ||
                    questionLiked.includes(question._id)
                  }
                >
                  <ThumbDownIcon />
                </IconButton>
              </Tooltip>
              <IconButton
                sx={{
                  padding: 0,
                  color: 'primary.main',
                }}
                onClick={() => handleLikeQuestion(question._id)}
                disabled={
                  questionLiked.includes(question._id) ||
                  questionDisliked.includes(question._id)
                }
              >
                <ThumbUpIcon />
              </IconButton>
            </Stack>
          </TableCell>
        ) : null}
        {showRatings && question.submittedByUsername ? (
          <TableCell>
            <Rating
              value={customQuestionRating}
              max={3}
              onChange={(event, newValue) =>
                handleStarredCustomQuestion(newValue, question._id)
              }
              disabled={
                !!customQuestionRating ||
                !!questionStats?.stars?.find((s) => s.profileId === profileId)
              }
            />
          </TableCell>
        ) : null}
        {!hideStats ? (
          <TableCell>
            <Box sx={{ height: 30, width: 60 }}>
              {loadingQuestionStats ? (
                <CircularProgress />
              ) : questionStats ? (
                <Tooltip
                  title={`Seen by ${questionStats.seen} player${
                    questionStats.seen !== 1 ? 's' : ''
                  }`}
                  placement="top"
                >
                  <Box
                    onMouseEnter={() => handleMouseOverBarChart(true)}
                    onMouseLeave={() => handleMouseOverBarChart(false)}
                  >
                    {getBarChartForScorecard(
                      questionStats,
                      displayQuestionStatSeen
                    )}
                  </Box>
                </Tooltip>
              ) : (
                <Typography textAlign={'center'}>N/A</Typography>
              )}
            </Box>
          </TableCell>
        ) : null}
        <TableCell sx={{ position: 'relative' }} onClick={handleRowClick}>
          <KeyboardArrowDownIcon
            sx={{
              position: 'absolute',
              visibility: showDownArrow === index ? 'visible' : 'hidden',
            }}
          />
          <KeyboardArrowUpIcon
            sx={{
              position: 'absolute',
              visibility: showUpArrow === index ? 'visible' : 'hidden',
            }}
          />
          <Typography
            fontWeight={600}
            sx={{
              visibility:
                showDownArrow === index || showUpArrow === index
                  ? 'hidden'
                  : 'visible',
            }}
          >
            #{index + 1}
          </Typography>
        </TableCell>
        <TableCell onClick={handleRowClick}>
          {displayQuestion(
            question.question,
            question.mathFormula,
            question.submittedByUsername,
            question.imgUrl
          )}
        </TableCell>
        <TableCell onClick={handleRowClick}>
          {hideAnswers && !revealAnswer ? (
            <Button
              variant="outlined"
              fullWidth
              onClick={() => handleRevealAnswerClick(index)}
            >
              <RevealAnswerAnimation />
            </Button>
          ) : (
            <Tooltip
              title={
                alternateAnswersExists(question.alternateAnswers)
                  ? alternateAnswersTooltip(question.alternateAnswers)
                  : null
              }
              placement="top-start"
            >
              <Typography sx={{ color: theme.palette.answers.main }}>
                {question.answer}
                {alternateAnswersExists(question.alternateAnswers) ? (
                  <CgAsterisk
                    style={{
                      color: theme.palette.answers.main,
                    }}
                  />
                ) : null}
              </Typography>
            </Tooltip>
          )}
        </TableCell>
        <TableCell onClick={handleRowClick}>
          <Typography>{question.category}</Typography>
        </TableCell>
        <TableCell onClick={handleRowClick}>
          <Typography>{question.par}</Typography>
        </TableCell>
        {(mode === 'solo' || (mode === 'sit-n-go' && playersCount === 2)) &&
        playerSelf ? (
          <PlayerTableCell sx={{ position: 'relative' }}>
            {formatPlayerScore(index, question.par, 'corner', playerSelf)}
          </PlayerTableCell>
        ) : null}
        {mode === 'sit-n-go' && playersCount === 2 && playerThem ? (
          <PlayerTableCell sx={{ position: 'relative' }}>
            {formatPlayerScore(index, question.par, 'corner', playerThem)}
          </PlayerTableCell>
        ) : null}
        {mode === 'teams' && myTeam ? (
          <PlayerTableCell sx={{ position: 'relative' }}>
            {formatTeamScore(index, question.par, 'corner', myTeam)}
          </PlayerTableCell>
        ) : null}
        {mode === 'teams' && opponentTeam ? (
          <PlayerTableCell sx={{ position: 'relative' }}>
            {formatTeamScore(index, question.par, 'corner', opponentTeam)}
          </PlayerTableCell>
        ) : null}
        {mode === 'sit-n-go' && playersCount > 2 && selectedPlayer ? (
          <PlayerTableCell sx={{ position: 'relative' }}>
            {formatPlayerScore(index, question.par, 'corner', selectedPlayer)}
          </PlayerTableCell>
        ) : null}
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            overflowX: 'auto',
            maxWidth: '200px',
          }}
          colSpan={calculateColspan()}
        >
          <Collapse in={expandRow[index]} timeout={'auto'} unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Stack
                direction="row"
                spacing={5}
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 3,
                  marginBottom: 3,
                }}
              >
                {detailedPlayers &&
                  Object.values(detailedPlayers)?.map((p) => (
                    <Stack
                      key={p.profileId}
                      sx={{
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        src={getAvatarSrc(
                          p?.avatarPublicId,
                          p?.avatarUrl,
                          50,
                          50
                        )}
                        variant="rounded"
                        sx={{
                          margin: '2px',
                          height: 35,
                          width: 35,
                        }}
                      />
                      <Typography
                        sx={{
                          marginBottom: 2,
                          textAlign: 'left',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          width: '50px',
                        }}
                      >
                        {p.username}
                      </Typography>
                      {formatPlayerScore(index, question.par, 'bottom', p)}
                    </Stack>
                  ))}
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
