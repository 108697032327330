import { LoggedInUser } from '../types/user';

export const gameInProgress = (loggedInUser: LoggedInUser) =>
  loggedInUser?.currentGameStarted && !loggedInUser?.currentGameOver;

export const canLeaveChat = (
  loggedInUser: LoggedInUser,
  spectateGameId?: string
) =>
  loggedInUser?.currentGameJoined &&
  loggedInUser?.currentGameStarted &&
  loggedInUser?.currentGameOver;

export const canJoinGame = (loggedInUser: LoggedInUser) =>
  !loggedInUser?.currentGameId ||
  (loggedInUser?.currentGameId &&
    loggedInUser?.currentGameJoined &&
    loggedInUser.currentGameOver);
