import { useQuery } from '@tanstack/react-query';
import { fetchProfile } from '../api/apiServices';

const useFetchProfile = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['profile'],
    queryFn: fetchProfile,
    retry: false,
  });

  return {
    isLoading,
    profile: data,
    refetch,
  };
};

export default useFetchProfile;
