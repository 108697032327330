import { useQuery } from '@tanstack/react-query';
import { getPunchcardRankings } from '../../../api/apiServices';

const useGetPunchcardRankings = (fetch?: boolean) => {
  const { data, isPending, refetch } = useQuery({
    queryKey: ['getPunchcardRankings'],
    queryFn: getPunchcardRankings,
    enabled: fetch,
  });

  return {
    refetch,
    data,
    isPending,
  };
};

export default useGetPunchcardRankings;
