import {
  Grid,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  useTheme,
  SelectChangeEvent,
  Box,
  Tooltip,
  IconButton,
} from '@mui/material';
import React from 'react';
import { StyledPaperItem } from '../../../mui-theme/mui-components';
import { utcToLocalDate } from '../../../utils/date-time';
import { Maintenance } from '../../../types';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DailiesAuthor, DailiesSet, DailySetsPlayed } from '../types';
import dayjs, { Dayjs } from 'dayjs';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { DailiesButton } from './DailiesButton';
import { Profile } from '../../../types/player';
import DailiesRadarChart from './DailiesRadarChart';
import { getRecent4GameScoresPerDay } from './utils';
import { CustomTooltip } from '../../../components/custom-tooltip/CustomTooltip';
import { InfoOutlined } from '@mui/icons-material';

type Props = {
  currentDailySet: number;
  nextDailySchedule: number;
  dailiesAuthor: DailiesAuthor;
  selectedDailySet: DailiesSet;
  dailiesResults: any;
  dailiesSets: DailiesSet[];
  dailySetsPlayed: DailySetsPlayed[];
  isMissedDaily: boolean;
  isPlayedDaily?: boolean;
  maintenance: Maintenance;
  handleDailyCountdownStop: () => void;
  handleDailySetChange: (dailySet: DailiesSet) => void;
  handlePlayDailyClick: () => void;
  profile: Profile;
};

const DailiesHeader = ({
  currentDailySet,
  nextDailySchedule,
  dailiesAuthor,
  selectedDailySet,
  dailiesResults,
  dailiesSets,
  dailySetsPlayed,
  isMissedDaily,
  isPlayedDaily,
  maintenance,
  handleDailyCountdownStop,
  handleDailySetChange,
  handlePlayDailyClick,
  profile,
}: Props) => {
  const theme = useTheme();

  const CustomDay = (props: PickersDayProps<Dayjs>) => {
    const date = props.day;

    const dailySetThisDay = dailiesSets.find(
      (ds) => ds.date === date.format('YYYY-MM-DD')
    );

    const isToday = date.isSame(dayjs(), 'day');
    const isFuture = date.isAfter(dayjs(), 'day');

    if (isFuture) {
      return <PickersDay {...props} />;
    }

    if (isToday) {
      return (
        <PickersDay
          {...props}
          sx={{
            border: '3px solid',
            borderColor: theme.palette.gold.main,
          }}
        />
      );
    }

    if (!dailySetThisDay) {
      return (
        <Tooltip title="No Daily this day" placement="top">
          <span>
            <PickersDay {...props} sx={{ color: '#484848' }} />
          </span>
        </Tooltip>
      );
    }

    const isHighlighted = dailySetsPlayed.find(
      (set) => set.dailySet === dailySetThisDay.dailySet
    );

    const matchedStyles = isHighlighted
      ? // ? { border: '1px solid', borderColor: theme.palette.dailies.main }
        { bgcolor: '#004b95' }
      : {};

    return <PickersDay {...props} sx={{ ...matchedStyles }} />;
  };

  const handleSelectDate = (date: Dayjs | null) => {
    const found = dailiesSets.find(
      (ds) => ds.date === date.format('YYYY-MM-DD')
    );

    const isToday = date.isSame(dayjs(), 'day');

    if (!found && !isToday) return;

    handleDailySetChange(found);
  };

  const radarChartData = getRecent4GameScoresPerDay(dailySetsPlayed);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} className="HEADER-LEFT">
        <Stack height="100%" width="100%" spacing={3}>
          {dailiesSets && dailySetsPlayed && (
            <StaticDatePicker
              defaultValue={dayjs()}
              onChange={handleSelectDate}
              disableFuture
              minDate={dayjs(dailiesSets[0].date)}
              maxDate={dayjs().endOf('month')}
              slots={{
                day: CustomDay,
              }}
              slotProps={{
                toolbar: {
                  hidden: true,
                },
                actionBar: {
                  actions: [],
                },
              }}
              sx={{
                '& .MuiDateCalendar-root': {
                  height: '100%',
                },
                '& .MuiPickersSlideTransition-root': {
                  minHeight: 200,
                },
              }}
            />
          )}
        </Stack>
      </Grid>

      <Grid item xs={12} sm={4} className="HEADER-MIDDLE">
        <Stack spacing={2} height="100%" display="grid">
          <DailiesButton
            dailiesAuthor={dailiesAuthor}
            currentDailySet={currentDailySet}
            isMissedDaily={isMissedDaily}
            isPlayedDaily={isPlayedDaily}
            nextDailySchedule={nextDailySchedule}
            handleDailyCountdownStop={handleDailyCountdownStop}
            handlePlayDailyClick={handlePlayDailyClick}
            maintenance={maintenance}
            profile={profile}
          />
          <StyledPaperItem>
            <Stack
              py={2}
              height={'100%'}
              spacing={4}
              alignItems="center"
              justifyContent="center"
            >
              <Typography>
                {dayjs(selectedDailySet?.date).format('dddd, MMMM D, YYYY')}
              </Typography>
              <Typography
                variant="h3"
                color={
                  theme.palette.mode === 'light'
                    ? theme.palette.dailies.main
                    : theme.palette.text.primary
                }
                sx={{
                  textShadow:
                    theme.palette.mode === 'dark'
                      ? `0 0 0px ${theme.palette.dailies.main}, 0 0 0px ${theme.palette.dailies.main}, 0px 0px 16px ${theme.palette.dailies.main}`
                      : 'none',
                }}
              >
                {dailiesResults?.length || 0}
              </Typography>
              <Typography>
                {selectedDailySet
                  ? `plays for Daily #${selectedDailySet.dailySet}`
                  : 'plays today'}
              </Typography>
            </Stack>
          </StyledPaperItem>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={4} className="HEADER-RIGHT">
        <Stack spacing={2} height="100%" display="grid">
          <StyledPaperItem sx={{ position: 'relative' }}>
            <DailiesRadarChart radarChartData={radarChartData} />
            <CustomTooltip
              title={
                'This is a spider chart. It will display up to your most recent 4 games per day. Keep playing Dailies to see your shape!'
              }
              placement="top"
            >
              <InfoOutlined
                sx={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                }}
              />
            </CustomTooltip>
          </StyledPaperItem>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DailiesHeader;
