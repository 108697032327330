import React, { useEffect, useState } from 'react';
import { Countdown } from './countdown';
import { Game } from './game';
import { ScoreScreenLoading } from './ScoreScreenLoading';
import { ScoreScreen } from './score-screen/ScoreScreen';
import { useGameStatesSpectateServer } from './hooks/useGameStatesSpectateServer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGameContext } from '../../contexts/GameProvider';
import { useProfileContext } from '../../contexts/ProfileProvider';
import {
  useInputSocketContext,
  useSocketContext,
  useSpectateSocketContext,
} from '../../contexts/SocketProvider';
import { FetchUpdatePlayMenuSettingsResponse } from '../../api/response-types/responseTypes';
import useUpdateGameSettings from '../game-settings/useUpdateGameSettings';

type LocationState = {
  fromQuickPlay: boolean;
};

export const SpectateGameSession = () => {
  const location = useLocation();
  const locationState = location.state as LocationState;
  const navigate = useNavigate();

  const { profile, mutateProfile } = useProfileContext();
  const { socket } = useSocketContext();
  const { inputSocket } = useInputSocketContext();
  const { spectateSocket } = useSpectateSocketContext();
  const { spectateGameId, spectateLeagueGameType, setSpectatePostGameId } =
    useGameContext();
  const {
    allArenaTeams,
    arenaGame,
    arenaRoundGamesScores,
    arenaSet,
    arenaPlayersProjectionAndTiers,
    arenaTeams,
    arenaTeamLiveRoundScores,
    arenaUpdateTrueSkill,
    assists,
    categorySet,
    countdown,
    delayBeforeQuestionRevealCount,
    detailedPlayers,
    freezeGameClock,
    fuzzy,
    gameClock,
    gameOver,
    gameType,
    mathFormula,
    mode,
    myTeam,
    nextQuestionDelayCount,
    opponentTeam,
    paused,
    penalty,
    players,
    playerSelf,
    playerThem,
    properNameShortAnswer,
    questionImgUrl,
    questionIndex,
    questionMetadata,
    questionSet,
    requestDetailedPlayers,
    resetDelayClocks,
    resetStatesFromInputServer,
    resetStatesFromMainServer,
    revealAnswer,
    revealFullQuestion,
    revealQuestion,
    spectatorChat,
    spectatorList,
    stopSpectating,
    teamBestBalls,
    teams,
    timerLimitReached,
    unrevealedIndex,
    updateScoresOnTimeout,
    willPause,
    arenaTeamRoundScores,
    arenaTeamsDetailed,
  } = useGameStatesSpectateServer();

  useEffect(() => {
    window.addEventListener('beforeunload', () => handleExitSpectate());
    window.addEventListener('popstate', () => handleExitSpectate());

    return () => {
      window.removeEventListener('beforeunload', () => handleExitSpectate());
      window.removeEventListener('popstate', () => handleExitSpectate());
    };
  }, []);

  useEffect(() => {
    if (location.state) {
      // Clear the state after first render
      navigate(location.pathname, { replace: true, state: null });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (!spectateGameId && !locationState?.fromQuickPlay) {
      navigate('/home');
    }
  }, [locationState, spectateGameId]);

  useEffect(() => {
    // reset states after each question.
    // first question is set from init, so no need to reset it
    if (questionIndex === undefined || questionIndex < 1) return;
    resetStatesFromMainServer();
    resetStatesFromInputServer();
  }, [questionIndex]);

  useEffect(() => {
    if (!timerLimitReached || !players) return;
    updateScoresOnTimeout(questionMetadata.par);
  }, [timerLimitReached]);

  useEffect(() => {
    if (!gameOver || !profile) return;
    requestDetailedPlayers(spectateGameId, profile._id);

    socket.emit('joinPostGameChatAsSpectator', {
      gameId: spectateGameId,
      profileId: profile._id,
      username: profile.username,
    });

    setSpectatePostGameId(spectateGameId);
  }, [gameOver]);

  const handleExitSpectate = () => {
    if (!spectateGameId || !profile) return;
    stopSpectating(profile._id, spectateGameId);

    if (
      spectateLeagueGameType === 'swiss-tournament' ||
      spectateLeagueGameType === 'tournament' ||
      spectateLeagueGameType === 'tournament-tiebreaker'
    ) {
      navigate('/tournament', { state: { showLiveGames: true } });
    } else if (arenaSet) {
      navigate('/pub');
    } else {
      navigate('/quick-play');
    }
  };

  const handleLeaveScoreScreen = () => {
    if (!spectateGameId || !profile) return;
    stopSpectating(profile._id, spectateGameId);
  };

  const showGameStarting = countdown === undefined && !gameOver;
  const gameInProgress =
    spectateGameId &&
    questionMetadata &&
    questionIndex !== undefined &&
    countdown === 0 &&
    profile &&
    players &&
    !gameOver;
  const showScoreScreenLoading =
    spectateGameId && questionSet && !gameOver && !detailedPlayers;
  const showScorescreen =
    spectateGameId && questionSet && gameOver && detailedPlayers;

  const handleOnSuccess = (data: FetchUpdatePlayMenuSettingsResponse) => {
    const profile = data.data.profile;
    mutateProfile(profile);
  };

  const { updateGameSettings } = useUpdateGameSettings(handleOnSuccess);

  const handleDisplaySizeChange = (_e: any, size: 'sm' | 'md' | 'lg') => {
    const updatedSettings = {
      ...profile.settings,
      displaySize: size,
    };

    updateGameSettings(updatedSettings);
  };

  const otherArenaTeams = allArenaTeams?.filter(
    (team) => !arenaTeams?.map((t) => t._id).includes(team._id)
  );

  return (
    <>
      {showGameStarting ? (
        <div className="game-starting-msg ">Game starting...</div>
      ) : null}
      {countdown > 0 ? <Countdown countdown={countdown} /> : null}
      {gameInProgress ? (
        <Game
          arenaGame={arenaGame}
          arenaRoundGamesScores={arenaRoundGamesScores}
          answer={revealAnswer}
          arenaPlayersProjectionAndTiers={arenaPlayersProjectionAndTiers}
          arenaTeams={arenaTeams}
          arenaTeamLiveRoundScores={arenaTeamLiveRoundScores}
          assists={assists}
          category={questionMetadata.category}
          categorySet={categorySet}
          clearAfterPenalty={profile.settings?.clearAfterPenalty}
          delayBeforeQuestionRevealCount={delayBeforeQuestionRevealCount}
          displaySize={profile.settings?.displaySize}
          forceReconnectSocketLiveInput={() => {}}
          freezeGameClock={freezeGameClock}
          fullQuestion={revealFullQuestion}
          fuzzy={fuzzy}
          gameClock={gameClock}
          gameId={spectateGameId}
          gameOver={gameOver}
          gameType={gameType}
          handleDisplaySizeChange={handleDisplaySizeChange}
          handlePause={() => {}}
          isAdmin={profile.admin}
          mathFormula={mathFormula}
          mode={mode}
          myTeam={myTeam}
          nextQuestionDelayCount={nextQuestionDelayCount}
          opponentTeam={opponentTeam}
          otherArenaTeams={otherArenaTeams}
          par={questionMetadata.par}
          paused={paused}
          penalty={penalty}
          playerSelf={playerSelf}
          players={players}
          playerThem={playerThem}
          profileId={profile._id}
          properNameShortAnswer={properNameShortAnswer}
          question={revealQuestion}
          questionImgUrl={questionImgUrl}
          questionIndex={questionIndex}
          rejoin={false}
          resetDelayClocks={resetDelayClocks}
          showConnectionStats={profile.settings?.connectionStats}
          socket={socket}
          inputSocket={inputSocket}
          spectateSocket={spectateSocket}
          spectate={true}
          spectatorChat={spectatorChat}
          spectateGameId={spectateGameId}
          spectatorList={spectatorList}
          submittedByUsername={questionMetadata.submittedByUsername}
          handleExitSpectate={handleExitSpectate}
          teamBestBalls={teamBestBalls}
          teams={teams}
          timerLimitReached={timerLimitReached}
          totalNumQuestions={questionMetadata.questionSetCount}
          unrevealedIndex={unrevealedIndex}
          username={profile.username}
          willPause={willPause}
        />
      ) : null}
      {showScoreScreenLoading ? <ScoreScreenLoading /> : null}
      {showScorescreen ? (
        <ScoreScreen
          arenaGame={arenaGame}
          arenaSet={arenaSet}
          arenaTeamRoundScores={arenaTeamRoundScores}
          arenaTeamLiveRoundScores={arenaTeamLiveRoundScores}
          arenaTeamsDetailed={arenaTeamsDetailed}
          arenaTeams={arenaTeams}
          arenaUpdateTrueSkill={arenaUpdateTrueSkill}
          categorySet={categorySet}
          cutoff={null}
          detailedPlayers={detailedPlayers}
          fromQuickPlay={locationState?.fromQuickPlay}
          gameId={spectateGameId}
          gameOver={gameOver}
          gameType={gameType}
          handleLeaveScoreScreen={handleLeaveScoreScreen}
          leagueGameType={spectateLeagueGameType}
          mode={mode}
          myTeam={myTeam}
          opponentTeam={opponentTeam}
          profile={profile}
          questionSet={questionSet}
          socket={socket}
          spectateSocket={spectateSocket}
          spectate={true}
          spectatorList={spectatorList}
        />
      ) : null}
    </>
  );
};
