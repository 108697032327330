import {
  Avatar,
  Badge,
  Box,
  Divider,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StyledPaperItem } from '../../../mui-theme/mui-components';
import { resizeImage } from '../../../api/cloudinary';
import { formattedScore } from '../../../components/scorecard';
import { PrecisionGraphicScorecardIcon } from '../../../components/scorecard/PrecisionGraphicScorecardIcons';
import { Team } from '../../../types/game';
import { DetailedPlayer, Player } from '../../../types/player';

const TeamAnswerAttemptsTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 350,
  },
});

const StatDescriptionsTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    fontSize: 14,
    border: '1px solid',
  },
}));

type PropTypes = {
  detailedPlayers: Record<string, DetailedPlayer>;
  league?: boolean;
  myTeam: Team;
  otherTeam: Team;
};

export const ScoreCardStatsTeams = ({
  detailedPlayers,
  league,
  myTeam,
  otherTeam,
}: PropTypes) => {
  const theme = useTheme();
  const [teamAPlayers, setTeamAPlayers] = useState<DetailedPlayer[]>();
  const [teamBPlayers, setTeamBPlayers] = useState<DetailedPlayer[]>();

  useEffect(() => {
    const teamAPlayers = Object.values(detailedPlayers).filter(
      (p) => myTeam.members.includes(p.profileId) && p.joinedGame
    );
    setTeamAPlayers(teamAPlayers);
    const teamBPlayers = Object.values(detailedPlayers).filter(
      (p) => otherTeam.members.includes(p.profileId) && p.joinedGame
    );
    setTeamBPlayers(teamBPlayers);
  }, []);

  const playerDisplay = (player: Player) => {
    return (
      <StyledPaperItem>
        <Stack
          direction={'row'}
          spacing={1}
          width={'100%'}
          height={'40px'}
          alignItems={'center'}
        >
          <Avatar
            src={resizeImage(player.avatarPublicId, 80, 80)}
            variant="rounded"
            sx={{
              height: 40,
              width: 40,
            }}
          />
          <Typography
            fontWeight={600}
            textAlign={'left'}
            width="100%"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'pre',
            }}
          >
            {player.username}
          </Typography>
        </Stack>
      </StyledPaperItem>
    );
  };

  const getPlayerStats = (
    player: DetailedPlayer,
    myTeam: Team,
    otherTeam: Team
  ) => {
    const teamRoundScores = Object.values(myTeam.roundScores);
    const otherTeamRoundScores = Object.values(otherTeam.roundScores);
    const roundScores = Object.values(player.roundScores);
    const questions = [];
    const penalties = [];
    const timeouts = [];
    const assists = [];
    const fuzzy = [];
    const attempts = [];
    let assistsCount = 0;
    let gets = 0;
    let penaltyCount = 0;
    let score = 0;
    let saves = 0;
    let advantagePlays = 0;
    for (let i = 0; i < roundScores.length; i++) {
      if (roundScores[i].score !== null) {
        const playerScore = roundScores[i].score + roundScores[i].fuzzyPenalty;
        gets = gets + 1;
        if (playerScore < 0) {
          score = score + playerScore;
        } else {
          saves = saves + 1;
        }
        const diff = playerScore - otherTeamRoundScores[i].score;
        if (diff < 0) {
          advantagePlays = advantagePlays + diff;
        }
      }
      if (roundScores[i].penalty) {
        penaltyCount = penaltyCount + 1;
      }
      if (roundScores[i].assist) {
        assistsCount = assistsCount + 1;
      }
      questions.push(roundScores[i].score);
      penalties.push(roundScores[i].penalty);
      assists.push(roundScores[i].assist);
      fuzzy.push(roundScores[i].fuzzyPenalty);
      timeouts.push({
        timerLimitReached: teamRoundScores[i].timerLimitReached,
        score: teamRoundScores[i].score,
      });
      attempts.push(roundScores[i].answerAttempts);
    }
    return {
      gets,
      assists,
      assistsCount,
      penaltyCount,
      score,
      saves,
      advantagePlays,
      questions,
      penalties,
      fuzzy,
      timeouts,
      attempts,
    };
  };

  const displayStat = (
    value: number | string,
    header?: boolean,
    addPlus?: boolean,
    color?: string,
    width?: number
  ) => {
    return (
      <StyledPaperItem
        sx={{
          paddingY: header ? 0 : null,
          bgcolor: header ? 'transparent' : null,
          boxShadow: header ? 'none' : null,
          backgroundImage: header ? 'none' : null,
        }}
      >
        <Stack
          direction={'row'}
          spacing={1}
          width={width || '40px'}
          height={header ? '60px' : '40px'}
          paddingX={1}
          alignItems={header ? 'end' : 'center'}
        >
          <Typography
            variant={header ? 'h6' : undefined}
            fontWeight={600}
            textAlign={'center'}
            width="100%"
            sx={{
              color: color || null,
            }}
          >
            {addPlus && typeof value === 'number' && value > 0 ? '+' : ''}
            {value}
          </Typography>
        </Stack>
      </StyledPaperItem>
    );
  };

  const underParScoreBallStyle = {
    bgcolor: `${theme.palette.success.main}40`,
    color: 'text.primary',
    textAlign: 'center',
    boxShadow: `0 0 5px 1px ${theme.palette.success.main}, 0 0 2px 3px ${theme.palette.success.main} inset`,
  };

  const timedoutScoreBallStyle = {
    bgcolor: `${theme.palette.error.main}40`,
    color: 'error.contrastText',
    display: 'inline-flex',
    boxShadow: `0 0 5px 1px ${theme.palette.error.main}, 0 0 2px 3px ${theme.palette.error.main} inset`,
  };

  const formatPlayerScore = (
    questionScore: number,
    penalty: number,
    assist: number,
    fuzzy: number,
    timeout: { timerLimitReached: boolean; score: number },
    answerAttempts: { input: string; gameClock: number }[]
  ) => {
    if (questionScore === null && !timeout.timerLimitReached) {
      return (
        <Stack
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'80px'}
          height={'40px'}
          paddingX={5}
        >
          {penalty ? (
            <>
              <TeamAnswerAttemptsTooltip
                placement="top"
                title={answerAttemptTooltip(answerAttempts)}
              >
                <Avatar
                  variant="circular"
                  sx={{
                    bgcolor: theme.palette.warning.main,
                    height: '20px',
                    width: '20px',
                  }}
                >
                  <Typography variant="caption">{penalty}</Typography>
                </Avatar>
              </TeamAnswerAttemptsTooltip>
              {assist ? (
                <Tooltip title="assist" placement="top">
                  <Avatar
                    variant="circular"
                    sx={{
                      height: '20px',
                      width: '20px',
                    }}
                  >
                    <Typography variant="caption">{penalty}</Typography>
                  </Avatar>
                </Tooltip>
              ) : null}
            </>
          ) : assist ? (
            <Tooltip title="assist" placement="top">
              <Avatar
                variant="circular"
                sx={{
                  height: '20px',
                  width: '20px',
                }}
              >
                <Typography variant="caption">{assist}</Typography>
              </Avatar>
            </Tooltip>
          ) : (
            <Typography padding={1}>-</Typography>
          )}
        </Stack>
      );
    }
    let scoreStyle: any = {
      color: 'error.contrastText',
    };
    let avatarShape: any = 'rounded';
    if (questionScore < 0) {
      scoreStyle = underParScoreBallStyle;
      avatarShape = 'round';
    }
    if (timeout.timerLimitReached) {
      scoreStyle = timedoutScoreBallStyle;
      avatarShape = 'square';
    }
    return (
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        width={'80px'}
        height={'100%'}
        position={'relative'}
        paddingX={5}
      >
        <Badge badgeContent={penalty} color="warning">
          <TeamAnswerAttemptsTooltip
            placement="top"
            title={answerAttemptTooltip(answerAttempts)}
          >
            <Avatar sx={scoreStyle} variant={avatarShape}>
              {formattedScore(
                timeout.timerLimitReached ? timeout.score : questionScore
              )}
            </Avatar>
          </TeamAnswerAttemptsTooltip>
        </Badge>
        {fuzzy !== null ? (
          <PrecisionGraphicScorecardIcon
            fuzzyPenalty={fuzzy}
            position={'absolute'}
          />
        ) : null}
      </Stack>
    );
  };

  const answerAttemptTooltip = (
    answerAttempts: { input: string; gameClock: number }[]
  ) => {
    return (
      <Stack spacing={2} sx={{ padding: 1 }}>
        <Typography textAlign="center">Answer Attempts</Typography>
        {answerAttempts.map((answer) =>
          answer.gameClock && answer.input ? (
            <Stack direction="row" spacing={3} minWidth={'350px'}>
              <Typography
                variant="body1"
                textAlign={'right'}
                sx={{
                  width: '40%',
                  color:
                    theme.palette.mode === 'light'
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  textShadow:
                    theme.palette.mode === 'dark'
                      ? `0 0 1px ${theme.palette.primary.main}, 10px 0px 20px ${theme.palette.primary.main}, 0px 0px 16px ${theme.palette.primary.main}`
                      : 'none',
                }}
              >
                {answer.gameClock.toFixed(2)} sec
              </Typography>
              <Typography
                variant="body1"
                textAlign={'left'}
                sx={{ width: '60%' }}
              >
                "{answer.input}"
              </Typography>
            </Stack>
          ) : (
            <Typography variant="body1">None</Typography>
          )
        )}
      </Stack>
    );
  };

  const questionLabels = league
    ? [
        'Q1',
        'Q2',
        'Q3',
        'Q4',
        'Q5',
        'Q6',
        'Q7',
        'Q8',
        'Q9',
        'Q10',
        'Q11',
        'Q12',
        'Q13',
        'Q14',
        'Q15',
        'Q16',
        'Q17',
        'Q18',
        'Q19',
        'Q20',
      ]
    : ['Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Q6', 'Q7', 'Q8', 'Q9', 'Q10'];

  return (
    <Stack direction="row" overflow={'auto'}>
      <Stack spacing={8} width={'350px'}>
        <Stack spacing={1}>
          <Stack height={'60px'}>
            <Typography
              variant="h5"
              pl={2}
              sx={{
                whiteSpace: 'pre',
                color: myTeam?.id === 'teamA' ? '#00e0ff' : '#ec9bff',
              }}
            >
              Team {myTeam?.username}
            </Typography>
            <Typography pt={0.5} pl={2} fontWeight={600}>
              Score {myTeam?.parScore > 0 ? '+' : ''}
              {myTeam?.parScore}
            </Typography>
          </Stack>
          {teamAPlayers?.map((p) => playerDisplay(p))}
        </Stack>
        <Stack spacing={1}>
          <Stack height={'60px'}>
            <Typography
              variant="h5"
              pl={2}
              sx={{
                whiteSpace: 'pre',
                color: otherTeam?.id === 'teamA' ? '#00e0ff' : '#ec9bff',
              }}
            >
              Team {otherTeam?.username}
            </Typography>
            <Typography pt={0.5} pl={2} fontWeight={600}>
              Score {otherTeam?.parScore > 0 ? '+' : ''}
              {otherTeam?.parScore}
            </Typography>
          </Stack>
          {teamBPlayers?.map((p) => playerDisplay(p))}
        </Stack>
      </Stack>

      <Stack spacing={8} overflow={'auto'} ml={1}>
        <Stack spacing={1}>
          <Stack direction={'row'}>
            <StatDescriptionsTooltip
              title="Gets: correct answers submitted for team"
              placement="top"
            >
              <Stack spacing={1}>{displayStat('G', true)}</Stack>
            </StatDescriptionsTooltip>
            <StatDescriptionsTooltip
              title="Penalties: penalty strokes"
              placement="top"
            >
              <Stack spacing={1}>{displayStat('P', true)}</Stack>
            </StatDescriptionsTooltip>
            <StatDescriptionsTooltip
              title="Assists: instances where player directly helped teammate's Get"
              placement="top"
            >
              <Stack spacing={1}>{displayStat('A', true)}</Stack>
            </StatDescriptionsTooltip>
            <StatDescriptionsTooltip
              title="Score: sum of Gets that scored less than 0"
              placement="top"
            >
              <Stack spacing={1} ml={1}>
                {displayStat('Sc', true, false, theme.palette.primary.main, 60)}
              </Stack>
            </StatDescriptionsTooltip>
            <StatDescriptionsTooltip
              title="Saves: number of Gets that scored 0 or greater"
              placement="top"
            >
              <Stack spacing={1}>
                {displayStat(
                  'Sv',
                  true,
                  false,
                  theme.palette.primary.main,
                  undefined
                )}
              </Stack>
            </StatDescriptionsTooltip>
            <StatDescriptionsTooltip
              title="Advantage Play Score: sum of differences when Gets scored better than opposing team"
              placement="top"
            >
              <Stack spacing={1}>
                {displayStat(
                  'APS',
                  true,
                  false,
                  theme.palette.primary.main,
                  60
                )}
              </Stack>
            </StatDescriptionsTooltip>
            <Stack direction={'row'} alignItems={'end'} height={'60px'} ml={1}>
              {questionLabels.map((n) => (
                <Stack
                  justifyContent={'center'}
                  alignItems={'end'}
                  width={'80px'}
                >
                  <Typography variant="h6" textAlign={'center'} width="100%">
                    {n}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
          {teamAPlayers?.map((p) => {
            const {
              gets,
              assists,
              assistsCount,
              penaltyCount,
              score,
              saves,
              advantagePlays,
              questions,
              penalties,
              fuzzy,
              timeouts,
              attempts,
            } = getPlayerStats(p, myTeam, otherTeam);
            return (
              <Stack direction={'row'}>
                <Stack spacing={1}>{displayStat(gets)}</Stack>
                <Stack spacing={1}>{displayStat(penaltyCount)}</Stack>
                <Stack spacing={1}>{displayStat(assistsCount)}</Stack>
                <Stack spacing={1} ml={1}>
                  {displayStat(
                    score,
                    false,
                    true,
                    theme.palette.primary.main,
                    60
                  )}
                </Stack>
                <Stack spacing={1}>
                  {displayStat(
                    saves,
                    false,
                    false,
                    theme.palette.primary.main,
                    undefined
                  )}
                </Stack>
                <Stack spacing={1}>
                  {displayStat(
                    advantagePlays,
                    false,
                    true,
                    theme.palette.primary.main,
                    60
                  )}
                </Stack>
                <StyledPaperItem sx={{ ml: 1, padding: 0 }}>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    height={'100%'}
                  >
                    {questions.map((q, i) =>
                      formatPlayerScore(
                        q,
                        penalties[i],
                        assists[i],
                        fuzzy[i],
                        timeouts[i],
                        attempts[i]
                      )
                    )}
                  </Stack>
                </StyledPaperItem>
              </Stack>
            );
          })}
        </Stack>
        <Stack spacing={1} ml={1}>
          <Stack direction={'row'}>
            <StatDescriptionsTooltip
              title="Gets: correct answers submitted for team"
              placement="top"
            >
              <Stack spacing={1}>{displayStat('G', true)}</Stack>
            </StatDescriptionsTooltip>
            <StatDescriptionsTooltip
              title="Penalties: penalty strokes"
              placement="top"
            >
              <Stack spacing={1}>{displayStat('P', true)}</Stack>
            </StatDescriptionsTooltip>
            <StatDescriptionsTooltip
              title="Assists: instances where player directly helped teammate's Get"
              placement="top"
            >
              <Stack spacing={1}>{displayStat('A', true)}</Stack>
            </StatDescriptionsTooltip>
            <StatDescriptionsTooltip
              title="Score: sum of Gets that scored less than 0"
              placement="top"
            >
              <Stack spacing={1} ml={1}>
                {displayStat('Sc', true, false, theme.palette.primary.main, 60)}
              </Stack>
            </StatDescriptionsTooltip>
            <StatDescriptionsTooltip
              title="Saves: number of Gets that scored 0 or greater"
              placement="top"
            >
              <Stack spacing={1}>
                {displayStat(
                  'Sv',
                  true,
                  false,
                  theme.palette.primary.main,
                  undefined
                )}
              </Stack>
            </StatDescriptionsTooltip>
            <StatDescriptionsTooltip
              title="Advantage Play Score: sum of differences when Gets scored better than opposing team"
              placement="top"
            >
              <Stack spacing={1}>
                {displayStat(
                  'APS',
                  true,
                  false,
                  theme.palette.primary.main,
                  60
                )}
              </Stack>
            </StatDescriptionsTooltip>
            <Stack direction={'row'} alignItems={'end'} height={'60px'} ml={1}>
              {questionLabels.map((n) => (
                <Stack
                  justifyContent={'center'}
                  alignItems={'end'}
                  width={'80px'}
                >
                  <Typography variant="h6" textAlign={'center'} width="100%">
                    {n}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
          {teamBPlayers?.map((p) => {
            const {
              gets,
              assists,
              assistsCount,
              penaltyCount,
              score,
              saves,
              advantagePlays,
              questions,
              penalties,
              fuzzy,
              timeouts,
              attempts,
            } = getPlayerStats(p, otherTeam, myTeam);
            return (
              <Stack direction={'row'}>
                <Stack spacing={1}>{displayStat(gets)}</Stack>
                <Stack spacing={1}>{displayStat(penaltyCount)}</Stack>
                <Stack spacing={1}>{displayStat(assistsCount)}</Stack>
                <Stack spacing={1} ml={1}>
                  {displayStat(
                    score,
                    false,
                    true,
                    theme.palette.primary.main,
                    60
                  )}
                </Stack>
                <Stack spacing={1}>
                  {displayStat(
                    saves,
                    false,
                    false,
                    theme.palette.primary.main,
                    undefined
                  )}
                </Stack>
                <Stack spacing={1}>
                  {displayStat(
                    advantagePlays,
                    false,
                    true,
                    theme.palette.primary.main,
                    60
                  )}
                </Stack>
                <StyledPaperItem sx={{ ml: 1, padding: 0 }}>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    height={'100%'}
                  >
                    {questions.map((q, i) =>
                      formatPlayerScore(
                        q,
                        penalties[i],
                        assists[i],
                        fuzzy[i],
                        timeouts[i],
                        attempts[i]
                      )
                    )}
                  </Stack>
                </StyledPaperItem>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};
