import { useState, useCallback } from 'react';
import io from 'socket.io-client';

export const useSocket = (url: string, options = {}) => {
  const [socket, setSocket] = useState(null);
  const [isSocketConnected, setIsSocketConnected] = useState(false);

  // Function to initiate the socket connection
  const connectSocket = useCallback(() => {
    console.log('connectSocket', url, options, JSON.stringify(options));
    if (socket && socket.connected) return;

    const newSocket = io(url, { ...options, transports: ['websocket'] });

    newSocket.on('connect', () => {
      setIsSocketConnected(true);
    });

    newSocket.on('disconnect', () => {
      setIsSocketConnected(false);
    });

    newSocket.on('connect_error', (error) => {
      console.error('Connection Error:', error);
    });

    setSocket(newSocket);
  }, [url, options, socket]);

  // Function to manually disconnect the socket
  const disconnectSocket = useCallback(() => {
    if (socket) {
      socket.disconnect();
    }
  }, [socket]);

  return { socket, isSocketConnected, connectSocket, disconnectSocket };
};
