import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Star } from '@mui/icons-material'; // Assuming you are using Material UI icons
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ArenaAdvancedStatsAggs } from '../types';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  annotationPlugin
);

const colorRange = [
  '#FF00FF', // Fuchsia / Magenta
  '#9932CC', // Dark Orchid
  '#800080', // Purple
  '#6A5ACD', // Slate Blue
  '#4169E1', // Royal Blue
  '#1E90FF', // Dodger Blue
  '#00CED1', // Dark Turquoise
  '#40E0D0', // Turquoise
  '#48D1CC', // Medium Turquoise
  '#20B2AA', // Light Sea Green
  '#32CD32', // Lime Green
  '#7CFC00', // Lawn Green
  '#7FFF00', // Chartreuse
  '#ADFF2F', // Green-Yellow
  '#F0E68C', // Khaki
  '#FFD700', // Gold
  '#FFA500', // Orange
  '#FF7F50', // Coral
  '#FF6347', // Tomato
  '#FF4500', // Orange Red
];

type Props = {
  categoryAverages: {
    category: string;
    average: number;
  }[];
  arenaAdvancedStatsAggs: {
    categoryStatsAverages: ArenaAdvancedStatsAggs;
    categoryStatsMedians: ArenaAdvancedStatsAggs;
  };
  handleCategoryClick: (category: string) => void;
  showMedians?: boolean;
};

const ArenaStatsChart = ({
  arenaAdvancedStatsAggs,
  handleCategoryClick,
  categoryAverages,
  showMedians,
}: Props) => {
  const sortedCategoryAverages = categoryAverages.sort((a, b) => {
    if (isNaN(a.average)) return 1;
    if (isNaN(b.average)) return -1;
    return a.average - b.average;
  });

  const labels = sortedCategoryAverages.map((c) => c.category);

  const rgbColorRange = (opacity?: number) =>
    colorRange.map((hex) => {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return `rgb(${r}, ${g}, ${b}, ${opacity || 1})`;
    });

  const sortedGlobalAverages = labels.map(
    (label) => arenaAdvancedStatsAggs?.categoryStatsAverages[label]
  );

  const sortedGlobalMedians = labels.map(
    (label) => arenaAdvancedStatsAggs?.categoryStatsMedians[label]
  );

  // const averageAnnotations = sortedGlobalAverages?.map((label, index) => ({
  //   type: 'point',
  //   xValue: sortedGlobalAverages[index],
  //   yValue: index,
  //   radius: 6,
  //   backgroundColor: 'lightgrey',
  // }));

  const medianAnnotations = sortedGlobalMedians?.map((label, index) => ({
    type: 'point',
    xValue: sortedGlobalMedians[index],
    yValue: index,
    radius: 6,
    backgroundColor: 'lightgrey',
  }));

  const options: any = {
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
    scales: {
      x: {
        position: 'top' as const,
        beginAtZero: true,
        ticks: {
          color: 'white',
          precision: 0,
          callback: function (value) {
            return value > 0 ? `+${value}` : value;
          },
        },
      },
      y: {
        ticks: {
          color: 'white',
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      annotation: {
        annotations: showMedians ? medianAnnotations : null,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'Category Average Scores',
      },
      datalabels: {
        display: (context) => context.dataset.data[context.dataIndex] === 0,
        formatter: () => '★',
        color: 'gold',
        font: {
          size: 16,
        },
        anchor: 'end',
        align: 'end',
        offset: -10,
      },
    },
    onClick: (e: any, elements: any) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].index;
        const category = data.labels[elementIndex];
        handleCategoryClick(category);
      }
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: sortedCategoryAverages.map((c) => c.average),
        backgroundColor: rgbColorRange(),
      },
    ],
  };

  return <Bar options={options} data={data} />;
};

export default ArenaStatsChart;
