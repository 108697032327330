import {
  Avatar,
  Box,
  Button,
  Fab,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PlusOneIcon from '@mui/icons-material/PlusOne';

import { CorrectAnswer } from './CorrectAnswer';
import { resizeImage } from '../../../api/cloudinary';
import { PenaltyLockBar } from '../../../components/penalty-lock-bar';
import { Assists, Mode, Team, Penalty } from '../../../types/game';
import { Player } from '../../../types/player';
import { PrecisionGraphicIcon } from './player-input/PrecisionGraphicIcons';

type PropTypes = {
  assists?: Assists;
  fuzzy?: Record<string, number>;
  gameId: string;
  mode: Mode;
  myTeam: Team;
  penalty?: Penalty;
  players: Record<string, Player>;
  profileId: string;
  questionIndex: number;
  inputSocket: Socket;
  spectateSocket?: Socket;
  spectate?: boolean;
  username: string;
};

export const TeamInputLive = ({
  assists,
  fuzzy,
  gameId,
  mode,
  myTeam,
  penalty,
  players,
  profileId,
  questionIndex,
  inputSocket,
  spectateSocket,
  spectate,
  username,
}: PropTypes) => {
  const theme = useTheme();
  const [teamPlayers, setTeamPlayers] = useState<Player[]>();
  const [playersLocked, setPlayersLocked] = useState<Record<string, boolean>>(
    {}
  );
  const [teamAssistsDisabled, setTeamAssistsDisabled] = useState(false);
  const [teamAssists, setTeamAssists] = useState<Record<string, boolean>>();

  const playerInputsRef = useRef<Record<string, string>>({});
  const spanRefs = useRef<Record<string, HTMLSpanElement | null>>({});
  const showAssistButtonsRef = useRef(false);
  const teamPlayersRef = useRef<string[]>();
  const questionIndexRef = useRef<number>();

  useEffect(() => {
    const listener = (e: any) => {
      if (!showAssistButtonsRef.current) return;
      if (e.altKey && (e.code === 'Digit1' || e.code === 'Numpad1')) {
        e.preventDefault();
        if (teamPlayersRef.current.length >= 1) {
          handleAssistClick(teamPlayersRef.current[0]);
        }
      }
      if (e.altKey && (e.code === 'Digit2' || e.code === 'Numpad2')) {
        e.preventDefault();
        if (teamPlayersRef.current.length >= 2) {
          handleAssistClick(teamPlayersRef.current[1]);
        }
      }
      if (e.altKey && (e.code === 'Digit3' || e.code === 'Numpad3')) {
        e.preventDefault();
        if (teamPlayersRef.current.length >= 3) {
          handleAssistClick(teamPlayersRef.current[2]);
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  useEffect(() => {
    if (!spectateSocket || spectate) return;
    spectateSocket.emit('joinTeamGame', {
      profileId: profileId,
      gameId: gameId,
      team: myTeam?.id,
    });
    spectateSocket.on('teamInputLive', updateTeamLiveInput);
    return () => {
      spectateSocket.off('teamInputLive');
    };
  }, [spectateSocket]);

  useEffect(() => {
    if (
      myTeam.correctAnswer &&
      myTeam.correctAnswerPlayerUsername === username
    ) {
      showAssistButtonsRef.current = true;
    }
    if (!myTeam.members || teamPlayers?.length) return;
    const updateTeamPlayers = Object.values(players)
      .filter((p) => myTeam.members.includes(p.profileId) && p.joinedGame)
      .filter((p) => p.profileId !== profileId);
    setTeamPlayers(updateTeamPlayers);
    teamPlayersRef.current = updateTeamPlayers.map((p) => p.profileId);
  }, [myTeam]);

  useEffect(() => {
    if (!penalty) return;
    setPlayersLocked((prev) => ({
      ...prev,
      [penalty.profileId]: true,
    }));
    setTimeout(() => {
      setPlayersLocked((prev) => ({
        ...prev,
        [penalty.profileId]: false,
      }));
    }, 1000);
  }, [penalty]);

  useEffect(() => {
    questionIndexRef.current = questionIndex;
    if (!playerInputsRef) return;
    for (const spanRef of Object.values(spanRefs.current)) {
      spanRef.innerText = '';
    }
    showAssistButtonsRef.current = false;
    setTeamAssistsDisabled(false);
  }, [questionIndex]);

  useEffect(() => {
    if (assists) {
      if (assists.team === myTeam.id) {
        const updatedTeamAssists = {};
        updatedTeamAssists[assists.assisterProfileId] = true;
        setTeamAssists(updatedTeamAssists);
        setTeamAssistsDisabled(true);
      }
    } else {
      setTeamAssists({});
    }
  }, [assists]);

  const handleAssistClick = (profileId: string) => {
    inputSocket.emit('playerAssist', {
      gameId,
      questionIndex: questionIndexRef.current,
      assisterProfileId: profileId,
      assistValue: 1,
      team: myTeam.id,
    });
  };

  const updateTeamLiveInput = (liveInput: {
    profileId: string;
    input: string;
  }) => {
    if (spanRefs.current[liveInput.profileId]) {
      spanRefs.current[liveInput.profileId].innerText = liveInput.input;
    }
  };

  const showInputControlReadOnly = (profileId: string, username: string) => (
    <Typography paddingY="5px" paddingX="10px">
      <span
        ref={(el) => (spanRefs.current[profileId] = el)}
        className="span-input"
        data-ph={username}
        style={{
          fontSize: 18,
          color:
            myTeam.correctAnswerPlayerUsername === username
              ? `${theme.palette.success.main}`
              : null,
          overflowWrap: 'anywhere',
        }}
      ></span>
    </Typography>
  );

  const teamInputDisplay = (p: Player, index: number) => (
    <Stack
      direction="row"
      spacing={2}
      marginTop={2}
      sx={{ textAlign: 'left', width: '100%' }}
    >
      <Tooltip title={p.username} placement="right-start">
        <Avatar
          src={resizeImage(p.avatarPublicId, 50, 50)}
          variant="rounded"
          sx={{
            height: '40px',
            width: '40px',
            position: 'relative',
          }}
        >
          {!p.avatarUrl ? p.username.charAt(0) : null}
        </Avatar>
      </Tooltip>
      <Stack direction="row" spacing={1} position="relative">
        <Box
          sx={{
            position: 'absolute',
            left: 20,
          }}
        >
          {playersLocked[p.profileId] ? (
            <PenaltyLockBar hideText opacity={0.8} />
          ) : null}
        </Box>
        <Stack
          className="Inputter-Stack"
          sx={{ position: 'relative', alignItems: 'center', maxWidth: '400px' }}
        >
          {fuzzy?.[p.profileId] ? (
            <PrecisionGraphicIcon fuzzyScore={fuzzy?.[p.profileId]} />
          ) : null}
          {showInputControlReadOnly(p.profileId, p.username)}
        </Stack>
        {myTeam.timerLimitReached ||
        myTeam.correctAnswerPlayerUsername === p.username ? (
          <CorrectAnswer
            abbreviateAves
            mode={mode}
            myTeam={myTeam}
            player={p}
            smallFont
            spectate={true}
          />
        ) : null}
      </Stack>
      {showAssistButtonsRef.current ? (
        <Tooltip title={`Alt + ${index + 1}`} placement="right">
          <Fab
            size="small"
            onClick={() => handleAssistClick(p.profileId)}
            disabled={teamAssistsDisabled}
          >
            {teamAssists[p.profileId] ? <PlusOneIcon /> : <HandshakeIcon />}
          </Fab>
        </Tooltip>
      ) : null}
    </Stack>
  );

  return (
    <Stack spacing={2} ml={2}>
      <Typography
        sx={{
          width: 'fit-content',
          paddingY: 0.5,
          borderBottom: '1px solid',
        }}
      >
        Team {myTeam?.username}
      </Typography>
      <Stack spacing={1}>
        {teamPlayers?.map((p, i) => {
          if (!spanRefs.current[p.profileId]) {
            spanRefs.current[p.profileId] = null;
          }
          return teamInputDisplay(p, i);
        })}
      </Stack>
    </Stack>
  );
};
