export enum GameModeToggleOptions {
  NORMAL = 'normal',
  RANKED = 'ranked',
  PUNCHCARD = 'punchcard',
  CHALLENGE_TOWER = 'challenge-tower',
  SEARCH_SEED = 'search-seed',
  TEAMS = 'teams',
  BOTS = 'bots',
  GHOST_CHALLENGE = 'ghost-challenge',
  DEMONS = 'demons',
}
