export const SIT_AND_GO_DRAWER_WIDTH = 300;

export const TIERS_BOTTOM_UP = [
  'All',
  'Unranked',
  'Lumen',
  'Stella',
  'Virtus',
  'Arcanus',
  'Master',
  'Grandmaster',
];
