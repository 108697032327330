import { useQuery } from '@tanstack/react-query';
import { getPunchcard } from '../../../api/apiServices';

const useGetPunchcard = (profileId: string) => {
  const { data, isPending, refetch } = useQuery({
    queryKey: ['getPunchcard', { profileId }],
    queryFn: getPunchcard,
    enabled: !!profileId,
  });

  return {
    refetch,
    data,
    isPending,
  };
};

export default useGetPunchcard;
