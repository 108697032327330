import {
  Avatar,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import { Mode } from '../../../../types/game';
import { getAvatarSrc } from '../../../../api/cloudinary';

const SCOREBAR_HEIGHT = 16;

type PropTypes = {
  avatar: string;
  limit?: number;
  mode: Mode;
  secondaryAvatar?: string;
  secondaryScore?: number;
  score: number;
  username: string;
  usernameLocation: 'left' | 'right';
};

export const ScoreBar = ({
  limit,
  mode,
  score,
  secondaryAvatar,
  secondaryScore,
  username,
}: PropTypes) => {
  const maxScore = limit || mode === 'teams' ? 80 : 40;
  const ref = useRef<HTMLDivElement>(null);
  const [widthLeft, setWidthLeft] = useState<number | null>(null);

  const theme = useTheme();

  // get the width of the progressbar
  useEffect(() => {
    const elementLeft = ref.current;

    function updateWidth() {
      if (elementLeft) {
        const { width } = elementLeft.getBoundingClientRect();
        setWidthLeft(width);
      }
    }

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [ref]);

  // Customize the scale of the progress bar (by default MUI uses a scale of 0 to 100).
  const normalise = (value: number) => {
    if (value < maxScore) {
      return (value * 100) / maxScore;
    } else {
      // return ((value + 10) * 100) / (value + 10);
      return 100;
    }
  };

  // turn negative score into normalised positive score
  const negativeScore = (score: number) => normalise(score * -1);

  const scorePosition = (score: number) => {
    if (score === 0) {
      return 16;
    } else if (score < 0 && score <= -maxScore) {
      return widthLeft ? -widthLeft + 16 : 0;
    } else if (score < 0) {
      return ((widthLeft ?? 0) / maxScore) * score + 16;
    } else if (score > 0 && score < maxScore) {
      return ((widthLeft ?? 0) / maxScore) * score + 16;
    } else if (score >= maxScore) {
      return widthLeft ? widthLeft + 16 : 0;
    }
  };

  return (
    <Stack
      alignItems="center"
      direction="row"
      position={'relative'}
      spacing={0}
      width="100%"
      className="STACK-CONTAINER"
    >
      <Stack
        direction="row"
        alignItems="center"
        width="50%"
        color="#dbd6cc"
        className="STACK-LEFT"
        my={1}
      >
        <Typography
          left={score < 0 ? '50%' : 'undefined'}
          right={score > 0 ? '50%' : 'undefined'}
          paddingX={1}
          position="absolute"
          variant="h6"
          fontSize={{ xs: 16, sm: 16 }}
          zIndex={1}
        >
          {username}
        </Typography>
        <LinearProgress
          variant="determinate"
          color="inherit"
          value={negativeScore(score)}
          sx={{
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            height: { xs: 8, sm: SCOREBAR_HEIGHT },
            width: '100%',
            transform: 'scale(-1, 1)',
          }}
        />
      </Stack>
      <Stack
        direction="row"
        width="50%"
        alignItems="center"
        color="#dbd6cc"
        className="STACK-RIGHT"
      >
        <LinearProgress
          ref={ref}
          variant="determinate"
          color="inherit"
          value={normalise(score)}
          sx={{
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            height: { xs: 8, sm: SCOREBAR_HEIGHT },
            width: '100%',
          }}
        />
        <Avatar
          sx={{
            height: { xs: 21, sm: 32 },
            position: 'absolute',
            width: { xs: 21, sm: 32 },
            right: `calc(50% - ${scorePosition(score)}px)`,
            transition: 'right 0.5s ease-out',
            zIndex: 1,
            backgroundColor: '#6d8ea0',
            border: '1px solid #182A3E',
          }}
        >
          <Typography fontSize={{ xs: 12, sm: 16 }}>
            {score > 0 ? `+${score}` : score}
          </Typography>
        </Avatar>
      </Stack>

      {secondaryAvatar && secondaryScore !== undefined && (
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          color="#dbd6cc"
          sx={{
            position: 'absolute',
          }}
        >
          <Avatar
            src={getAvatarSrc(secondaryAvatar, undefined, 80, 80)}
            sx={{
              position: 'absolute',
              height: { xs: 21, sm: 32 },
              width: { xs: 21, sm: 32 },
              right: `calc(50% - ${scorePosition(secondaryScore)}px)`,
              transition: 'right 0.5s ease-out',
              zIndex: 1,
              border: '1px solid #182A3E',
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};
