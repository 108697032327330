import React from 'react';
import { Box, Chip } from '@mui/material';
import { MenuItemProps } from './PlayersOnline';

type Props = {
  selectedMenuItem: MenuItemProps;
  setSelectedMenuItem: (menuItem: MenuItemProps | null) => void;
};
export const FilterBadge = ({
  selectedMenuItem,
  setSelectedMenuItem,
}: Props) => {
  const handleDeleteFilter = () => {
    setSelectedMenuItem(null);
  };

  return selectedMenuItem ? (
    <Box
      height={40}
      width={'100%'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Chip label={selectedMenuItem.label} onDelete={handleDeleteFilter} />
    </Box>
  ) : null;
};
