import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle, List, Stack, Typography } from '@mui/material';

type PropTypes = {
  onClose: () => void;
  open: boolean;
};

export const NewPlayersDialog = ({ onClose, open }: PropTypes) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle textAlign={'center'}>Welcome!</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography
            textAlign={'center'}
            fontFamily={'noto-sans'}
            color="creamOrange.main"
          >
            Before we start, PAR is best experienced on a desktop/laptop. We are
            actively working on optimizing the mobile experience.
          </Typography>

          <Typography textAlign={'center'} fontFamily={'noto-sans'}>
            If you've never played a PAR game before, we recommend playing a
            Normal game first. Click the green PLAY button at the top left of
            this site (not available on mobile yet, jump to Dailies if you are
            on your phone).
          </Typography>

          <Typography textAlign={'center'} fontFamily={'noto-sans'}>
            After you've done that, try playing a Daily.
          </Typography>

          <Typography textAlign={'center'} fontFamily={'noto-sans'}>
            Once you're comfortable with the game and you want something bigger,
            play a live Ranked game against other players. This does require
            other players to be online so you might not always get a game. Or
            try leveling up your Punchcard.
          </Typography>

          <Typography textAlign={'center'} fontFamily={'noto-sans'}>
            Be sure to browse profiles and other areas of the site. Join our
            Discord if you have any questions or want to chat with other
            players.
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
