import { Avatar, Typography, useTheme } from '@mui/material';
import React from 'react';
import { addPlusToPositiveScores } from '../../../../../utils/game';
import { Penalty } from '../../../../../types/game';

type Props = {
  correctAnswer?: boolean;
  currentScore?: number;
  height: number;
  width: number;
  penalty?: Penalty;
  teamGame?: boolean;
};
export const ScoreAvatar = ({
  correctAnswer,
  currentScore,
  height,
  width,
  penalty,
  teamGame,
}: Props) => {
  const { palette } = useTheme();

  const color = () => {
    // if (penalty?.penaltyTimestamp) return 'warning.main';
    if (correctAnswer) return 'success.contrastText';
    return 'error.contrastText';
  };

  const opaqueBackground = () => {
    if (penalty?.penaltyTimestamp) return palette.warning.main;
    if (correctAnswer) return `${palette.success.main}`;
    return `${palette.error.main}`;
  };

  return (
    <Avatar
      variant={teamGame ? 'square' : 'circular'}
      sx={{
        height,
        width,
        zIndex: 1,
        backgroundColor: opaqueBackground,
      }}
    >
      <Typography variant="h4" color={color}>
        {addPlusToPositiveScores(currentScore)}
      </Typography>
    </Avatar>
  );
};
