import React, { useState } from 'react';
import {
  IconButton,
  InputAdornment,
  InputBase,
  Menu,
  MenuItem,
  alpha,
  styled,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  margin: '8px 12px',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const menuOptions = [
  { label: 'Status is Online', filterOn: 'online', filterValue: true },
  {
    label: 'Open to Haunts',
    filterOn: 'ghostChallengeStatus',
    filterValue: 'yes',
  },
];

type MenuItem = {
  label: string;
  filterOn: string;
  filterValue: string | boolean;
};

type Props = {
  selectedMenuItem: MenuItem;
  setSearchTerm: (value: string) => void;
  setSelectedMenuItem: (item: any) => void;
};

export const SearchDrawer = ({
  selectedMenuItem,
  setSearchTerm,
  setSelectedMenuItem,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorEl);

  const handleSearchInput = (event: React.FormEvent<HTMLDivElement>) => {
    setSearchTerm(event.target['value']);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option: any) => {
    handleClose();
    setSelectedMenuItem(option);
  };

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Recently online"
        inputProps={{ 'aria-label': 'search' }}
        onInput={(event) => handleSearchInput(event)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              sx={{ marginRight: 0 }}
              aria-label="search filters"
              edge="end"
              onClick={handleClick}
            >
              <TuneIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
                },
              }}
            >
              {menuOptions.map((option) => (
                <MenuItem
                  key={option.label}
                  selected={option === selectedMenuItem}
                  onClick={() => handleMenuItemClick(option)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </InputAdornment>
        }
      />
    </Search>
  );
};
