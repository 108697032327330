import { useQuery } from '@tanstack/react-query';
import { fetchNotifications } from '../api/apiServices';

const useFetchNotifications = (profileId: string) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['notifications', { profileId }],
    queryFn: fetchNotifications,
    retry: false,
  });

  return {
    isLoading,
    notifications: data,
    refetch,
  };
};

export default useFetchNotifications;
