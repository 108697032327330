import { useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useErrorDialog } from '../../contexts/ErrorDialogProvider';
import { useLobbyGamesContext } from '../../contexts/LobbyGamesProvider';
import { useLoggedInUserContext } from '../../contexts/LoggedInUserProvider';
import { useProfileContext } from '../../contexts/ProfileProvider';
import { useScorecardDialog } from '../../contexts/ScorecardDialogProvider';
import { useClientVersion } from '../../hooks/useClientVersion';
import { ErrorMessageDialog } from '../../dialogs/ErrorMessageDialog';
import { RefreshBrowserDialog } from '../../dialogs/RefreshBrowserDialog';
import { ScorecardDialog } from '../../dialogs/ScorecardDialog';
import { InvitationWelcomeDialog } from '../../dialogs/InvitationWelcomeDialog';
import { JoinGameDialog } from '../../dialogs/JoinGameDialog';
import { WelcomeDialog } from '../../dialogs/WelcomeDialog';
import { DetailedPlayer } from '../../types/player';
import { useLocation } from 'react-router-dom';
import { gameInProgress } from '../../utils/loggedInUser';
import { useGameContext } from '../../contexts/GameProvider';
import { ConfirmReadyDialog } from '../../dialogs/ConfirmReadyDialog';
import { JoinArenaGameDialog } from '../../dialogs/JoinArenaGameDialog';
import { setLocalStorageItem } from '../../utils/local-storage';
import { JoinArenaTeamGameDialog } from '../../dialogs/JoinArenaTeamGameDialog';
import { useArenaContext } from '../../contexts/ArenaProvider';
import { JoinArenaTeamBeforeGameDialog } from '../../dialogs/JoinArenaTeamBeforeGameDialog';

export const GlobalDialogs = () => {
  const theme = useTheme();
  const location = useLocation();
  const { profile } = useProfileContext();

  const {
    close: closeScorecardDialog,
    gameData,
    open: openScorecardDialog,
    questionData,
    arenaTeams,
  } = useScorecardDialog();

  const {
    close: closeErrorDialog,
    open: openErrorDialog,
    message: errorMessage,
    title: errorTitle,
  } = useErrorDialog();

  const { loggedInUser, refreshBrowser: refreshBrowserLoggedInUser } =
    useLoggedInUserContext();

  const { fetchClientVersion, refreshBrowser: refreshBrowserClientVersion } =
    useClientVersion();

  const { arenaSeason } = useArenaContext();
  const { arenaGame, arenaTeamGameOpenForJoining, arenaTeamGames } =
    useLobbyGamesContext();
  const { confirmReady, handleReadyClick, handleNotReadyClick } =
    useGameContext();

  const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false);
  const [openInvitationWelcomeDialog, setOpenInvitationWelcomeDialog] =
    useState(false);
  const [openConfirmReadyDialog, setOpenConfirmReadyDialog] = useState(false);
  const [openJoinArenaGameDialog, setOpenJoinArenaGameDialog] = useState(false);
  const [openJoinArenaTeamGameDialog, setOpenJoinArenaTeamGameDialog] =
    useState(false);
  const [
    openJoinArenaTeamBeforeGameDialog,
    setOpenJoinArenaTeamBeforeGameDialog,
  ] = useState(false);

  const arenaGameOpenForJoining =
    profile &&
    !gameInProgress(loggedInUser) &&
    arenaGame &&
    !arenaGame.activePlayers?.includes(profile._id);

  const {
    arenaSet,
    arenaTeamRoundScores,
    categorySet,
    gameId,
    gameType,
    handicap,
    daily,
    league,
    mode,
    players,
    matchMakingSeededBy,
    teams,
    ranked,
    variant,
  } = gameData || {};

  // useEffect(() => {
  //   fetchClientVersion();
  // }, [fetchClientVersion]);

  useEffect(() => {
    if (!profile) return;
    setOpenInvitationWelcomeDialog(
      profile.invitedBy && !profile.invitationWelcomeSeen
    );
    setOpenWelcomeDialog(!profile.welcomeSeen);
  }, [profile]);

  useEffect(() => {
    if (confirmReady) {
      setOpenConfirmReadyDialog(true);
    } else {
      setOpenConfirmReadyDialog(false);
    }
  }, [confirmReady]);

  const openRefreshBrowserDialog =
    (refreshBrowserLoggedInUser || refreshBrowserClientVersion) &&
    location.pathname !== '/login';

  useEffect(() => {
    if (arenaGameOpenForJoining) {
      const arenaGameId = localStorage.getItem('arenaGameId');
      if (arenaGameId === arenaGame?.gameId || arenaGame.gameStarted) return;
      if (arenaTeamGameOpenForJoining.whoCanJoin?.includes(profile._id)) {
        setOpenJoinArenaTeamGameDialog(true);
      }
    }
  }, [arenaGameOpenForJoining]);

  useEffect(() => {
    if (arenaTeamGameOpenForJoining) {
      const arenaTeamGameId = localStorage.getItem('arenaTeamGameId');
      if (
        arenaTeamGameId === arenaTeamGameOpenForJoining?.gameId ||
        arenaTeamGameOpenForJoining.gameStarted
      ) {
        return;
      }

      if (arenaTeamGameOpenForJoining.whoCanJoin?.includes(profile._id)) {
        setOpenJoinArenaTeamGameDialog(true);
      }
    }
  }, [arenaTeamGameOpenForJoining]);

  const handleJoinArenaGameDialogClose = () => {
    setOpenJoinArenaGameDialog(false);
    setLocalStorageItem('arenaGameId', arenaGame?.gameId);
  };

  const handleJoinArenaTeamGameDialogClose = () => {
    setOpenJoinArenaTeamGameDialog(false);
    setOpenJoinArenaTeamBeforeGameDialog(false);
    setLocalStorageItem('arenaTeamGameId', arenaGame?.gameId);
  };

  return (
    <>
      {confirmReady && (
        <ConfirmReadyDialog
          open={openConfirmReadyDialog}
          close={() => setOpenConfirmReadyDialog(false)}
          confirmReady={confirmReady}
          handleNotReadyClick={handleNotReadyClick}
          handleReadyClick={handleReadyClick}
        />
      )}
      <RefreshBrowserDialog open={openRefreshBrowserDialog} />
      {gameData && profile ? (
        <ScorecardDialog
          arenaSet={arenaSet}
          arenaTeams={arenaTeams}
          arenaTeamRoundScores={arenaTeamRoundScores}
          onClose={closeScorecardDialog}
          categorySet={categorySet}
          gameId={gameId}
          gameType={gameType}
          handicap={handicap}
          isDaily={daily}
          league={league}
          open={openScorecardDialog}
          mode={mode}
          players={players as Record<string, DetailedPlayer>}
          profileId={profile._id}
          questionSet={questionData}
          seededBy={matchMakingSeededBy}
          teams={teams}
          ranked={ranked}
          variant={variant}
        />
      ) : null}
      <ErrorMessageDialog
        close={closeErrorDialog}
        message={errorMessage}
        open={!!openErrorDialog}
        title={errorTitle}
      />
      <InvitationWelcomeDialog
        close={() => setOpenInvitationWelcomeDialog(false)}
        invitedBy={profile?.invitedBy}
        open={!!openInvitationWelcomeDialog}
        profile={profile}
      />
      {/* <WelcomeDialog
        avatarUrl={profile?.avatarUrl}
        close={() => setOpenWelcomeDialog(false)}
        open={!!openWelcomeDialog}
        profileId={profile?._id}
        username={profile?.username}
      /> */}

      {openJoinArenaGameDialog && (
        <JoinArenaGameDialog
          arenaGame={arenaGame}
          onClose={handleJoinArenaGameDialogClose}
          open={openJoinArenaGameDialog}
          profileId={profile._id}
        />
      )}
      {openJoinArenaTeamGameDialog && (
        <JoinArenaTeamGameDialog
          arenaGame={arenaTeamGameOpenForJoining}
          onClose={handleJoinArenaTeamGameDialogClose}
          open={openJoinArenaTeamGameDialog}
          profileId={profile._id}
        />
      )}
      {openJoinArenaTeamBeforeGameDialog && (
        <JoinArenaTeamBeforeGameDialog
          arenaGame={arenaTeamGameOpenForJoining}
          onClose={handleJoinArenaTeamGameDialogClose}
          open={openJoinArenaTeamBeforeGameDialog}
          profileId={profile._id}
          season={arenaSeason}
        />
      )}
    </>
  );
};
