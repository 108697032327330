import StarIcon from '@mui/icons-material/Star';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Drawer,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Socket } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { ToggleStats } from '../ToggleStats';
import { ScoreCardStatsTeams } from './ScoreCardStatsTeams';
import { Scorecard } from '../../../components/scorecard';
import { useLoggedInUserContext } from '../../../contexts/LoggedInUserProvider';
import {
  Achievement,
  LeagueGameType,
  ParticipantLosses,
  Spectator,
  TournamentResult,
} from '../../../types';
import { Mode, Question, Team } from '../../../types/game';
import {
  ArenaTeamLiveRoundScores,
  ArenaTeamRoundScores,
  DetailedPlayer,
  Profile,
} from '../../../types/player';
import useDailiesStreakAchievements from '../hooks/useDailiesStreakAchievements';
import DailiesStreaksDialog from './DailiesStreaksDialog';
import { sortPlayers } from '../game/helpers';
import { SitAndGoLeaderboard } from '../game/SitAndGoLeaderboard';
import { SIT_AND_GO_DRAWER_WIDTH } from '../game/constants';
import {
  TrueSkill,
  UserArenaAchievement,
} from '../../profile/ArenaProfile/types';
import ArenaTrueSkillDialog from './ArenaTrueSkillDialog';
import ArenaAchievementsDialog from './ArenaAchievementsDialog/ArenaAchievementsDialog';
import { ScoreCardStatsSnG } from './ScoreCardStatsSnG';
import { ARCANA_ACHIEVEMENTS } from './ArenaAchievementsDialog/constants';
import MmrResultsDialog from './MmrResultsDialog/MmrResultsDialog';
import { ArenaTeam } from '../../arena-team/types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ScoreCardStatsArenaTeams } from './ScoreCardStatsArenaTeams/ScoreCardStatsArenaTeams';
import { getTeamTotalScore } from '../utils';
import { getAvatarSrc } from '../../../api/cloudinary';

type PropTypes = {
  achievements?: Achievement[];
  arenaGame?: number;
  arenaProjection?: number;
  arenaSet?: number;
  arenaPlayersProjectionAndTiers?: Record<
    string,
    {
      projection: number;
      currentTier: string;
    }
  >;
  arenaTeamRoundScores?: Record<string, ArenaTeamRoundScores>;
  arenaTeamsDetailed?: ArenaTeam[];
  arenaTeams?: ArenaTeam[];
  arenaTeamLiveRoundScores?: ArenaTeamLiveRoundScores;
  categorySet?: string;
  cutoff?: number;
  dailySet?: number;
  detailedPlayers?: Record<string, DetailedPlayer>;
  fromDailies?: boolean;
  fromArena?: boolean;
  fromQuickPlay?: boolean;
  gameId: string;
  gameOver: boolean;
  gameType?: 'casual' | 'pro';
  handleLeaveScoreScreen?: () => void;
  isPunchcard?: boolean;
  isRanked?: boolean;
  leagueGameType: LeagueGameType;
  missedDaily?: boolean;
  mode: Mode;
  myTeam?: Team;
  opponentTeam?: Team;
  participantLosses?: ParticipantLosses;
  profile: Profile;
  questionSet: Question[];
  arenaAchievements?: UserArenaAchievement[];
  arenaUpdateTrueSkill?: TrueSkill[];
  socket: Socket;
  spectateSocket: Socket;
  spectate?: boolean;
  spectatorList: Spectator[];
  tournamentChampions?: TournamentResult;
};

export const ScoreScreen = ({
  achievements,
  arenaGame,
  arenaProjection,
  arenaSet,
  arenaPlayersProjectionAndTiers,
  arenaTeamRoundScores,
  arenaTeamsDetailed,
  arenaTeams,
  arenaTeamLiveRoundScores,
  categorySet,
  cutoff,
  dailySet,
  detailedPlayers,
  fromDailies,
  fromArena,
  fromQuickPlay,
  gameId,
  gameOver,
  gameType,
  handleLeaveScoreScreen,
  isPunchcard,
  isRanked,
  leagueGameType,
  missedDaily,
  mode,
  myTeam,
  opponentTeam,
  participantLosses,
  profile,
  questionSet,
  arenaAchievements,
  arenaUpdateTrueSkill,
  socket,
  spectateSocket,
  spectate,
  spectatorList,
  tournamentChampions,
}: PropTypes) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { refetchLoggedInUser } = useLoggedInUserContext();

  const [openDailiesStreaksDialog, setOpenDailiesStreaksDialog] = useState(
    !!dailySet
  );
  const [toggleQuestionsStats, setToggleQuestionsStats] = useState<
    'scorecard' | 'stats' | 'teams'
  >('scorecard');

  const [hoveredTier, setHoveredTier] = useState<string>(null);

  const [mmrResultsDialogOpen, setMmrResultsDialogOpen] = useState(false);

  const detailedPlayerSelf = spectate
    ? Object.values(detailedPlayers)[0]
    : detailedPlayers[profile._id];

  const [selectedPlayer, setSelectedPlayer] =
    useState<DetailedPlayer>(detailedPlayerSelf);
  const [sortedPlayers, setSortedPlayers] = useState<DetailedPlayer[]>([]);
  const [filteredPlayersProfileIds, setFilteredPlayersProfileIds] =
    useState<string[]>();

  const [filteredArenaAchievements, setFilteredArenaAchievements] = useState<
    UserArenaAchievement[]
  >(arenaAchievements || []);

  const { dailiesStreakAchievements } = useDailiesStreakAchievements(
    profile,
    dailySet,
    detailedPlayerSelf,
    missedDaily
  );

  const [selectedTier, setSelectedTier] = useState('All');
  const [selectedTeamId, setSelectedTeamId] = useState<string>();

  const [soloTeamsToggleValue, setSoloTeamsToggleValue] = useState<
    'solo' | 'teams'
  >('solo');

  const arenaTeamId = detailedPlayerSelf?.arenaTeamId;
  const myArenaTeam = useMemo(
    () => arenaTeams?.find((team) => team._id === arenaTeamId),
    [arenaTeams, arenaTeamId]
  );
  const myArenaTeamScore = useMemo(
    () =>
      arenaTeamLiveRoundScores?.[arenaTeamId]
        ? getTeamTotalScore(arenaTeamLiveRoundScores[arenaTeamId])
        : 0,
    [arenaTeamLiveRoundScores, arenaTeamId]
  );
  const otherArenaTeam = useMemo(
    () => arenaTeams?.find((team) => team._id !== arenaTeamId),
    [arenaTeams, arenaTeamId]
  );
  const otherArenaTeamScore = useMemo(
    () =>
      arenaTeamLiveRoundScores?.[otherArenaTeam?._id]
        ? getTeamTotalScore(arenaTeamLiveRoundScores[otherArenaTeam?._id])
        : 0,
    [arenaTeamLiveRoundScores, otherArenaTeam]
  );

  useEffect(() => {
    const sortedPlayers = sortPlayers(detailedPlayers);
    const sortedFilteredPlayers = filteredPlayersProfileIds?.length
      ? sortedPlayers.filter((p) =>
          filteredPlayersProfileIds?.includes(p.profileId)
        )
      : sortedPlayers;
    setSortedPlayers(sortedFilteredPlayers as DetailedPlayer[]);
  }, [detailedPlayers, filteredPlayersProfileIds]);

  const handleBackToLobbyClick = () => {
    handleLeaveScoreScreen && handleLeaveScoreScreen();

    refetchLoggedInUser(profile._id);

    const isTourney =
      leagueGameType === 'swiss-tournament' ||
      leagueGameType === 'tournament' ||
      leagueGameType === 'tournament-tiebreaker';

    if (isTourney) {
      navigate('/tournament', { state: { showLiveGames: true } });
    } else if (isRanked) {
      navigate('/lobby');
    } else if (fromDailies) {
      navigate('/dailies');
    } else if (arenaSet) {
      navigate('/pub');
    } else if (isPunchcard) {
      navigate('/play/punchcard');
    } else {
      navigate('/home');
    }
  };

  const handleShowStatsChange = (
    event: any,
    value: 'scorecard' | 'stats' | 'teams'
  ) => {
    if (value === null) return;
    setToggleQuestionsStats(value);
  };

  const displayPlayerByTier = (player: DetailedPlayer, tier: string) => {
    if (!tier) return true;
    if (tier === 'All') return true;
    return (
      arenaPlayersProjectionAndTiers?.[player.profileId]?.currentTier === tier
    );
  };

  const playersCount = Object.keys(detailedPlayers).length;

  const playerArenaTrueSkillFound = arenaUpdateTrueSkill?.find(
    (player) => player.profileId === profile._id
  );

  const updatedMmr = playerArenaTrueSkillFound?.mmrDiffFromPrev || 0;
  const showTrueSkillDialog =
    playerArenaTrueSkillFound?.wasCalibrated ||
    playerArenaTrueSkillFound?.wasPromoted;

  const [trueSkillDialogOpen, setTrueSkillDialogOpen] = useState(false);

  const [openArenaAchievementsDialog, setOpenArenaAchievementsDialog] =
    useState(false);

  useEffect(() => {
    if (showTrueSkillDialog) {
      setTrueSkillDialogOpen(true);
    }
  }, [showTrueSkillDialog]);

  useEffect(() => {
    if (arenaAchievements && arenaAchievements.length > 0) {
      const firstTimeAchievements = arenaAchievements.filter(
        (ach) => ach.count === 1
      );
      const arcanaAchievements = arenaAchievements.filter((ach) =>
        ARCANA_ACHIEVEMENTS.includes(ach.achievement)
      );
      const filteredAchievements =
        firstTimeAchievements.concat(arcanaAchievements);

      const uniqFilteredAchievements = [
        ...new Map(
          filteredAchievements.map((item) => [item.achievement, item])
        ).values(),
      ];
      if (filteredAchievements.length > 0) {
        setFilteredArenaAchievements(arenaAchievements);
        setOpenArenaAchievementsDialog(true);
      }
    }
  }, [arenaAchievements]);

  const showScorecardToggle = playersCount > 2;

  const handleAchievementsClick = () => {
    setFilteredArenaAchievements(arenaAchievements || []);
    setOpenArenaAchievementsDialog(true);
  };

  const handleUsernameClick = (profileId: string) => {
    setSelectedPlayer(detailedPlayers[profileId]);
  };

  const filterPlayersByTier = (tier: string) => {
    const sortedPlayers = sortPlayers(detailedPlayers) as DetailedPlayer[];
    const filteredPlayers = sortedPlayers.filter((p) =>
      displayPlayerByTier(p, tier)
    );
    setSortedPlayers(filteredPlayers);
  };

  const handleTierClick = (tier: string) => {
    filterPlayersByTier(tier);
    setSelectedTier(tier);
    setSelectedTeamId(undefined);
  };

  const handleTierHover = (tier: string) => {
    setHoveredTier(tier);
  };

  const handleTeamClick = (teamId: string) => {
    setSelectedTier(undefined);
    setSelectedTeamId(teamId);
    const sortedPlayers = sortPlayers(detailedPlayers) as DetailedPlayer[];
    const filteredPlayers = sortedPlayers.filter(
      (p) => p.arenaTeamId === teamId
    );
    setSortedPlayers(filteredPlayers);
  };

  const handleSoloTeamChange = (e: any, value: 'solo' | 'teams') => {
    if (!value) return;
    setSoloTeamsToggleValue(value);
  };

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          bgcolor: theme.palette.background.default,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ padding: 2, width: '100%', overflow: 'auto' }}>
          {showScorecardToggle ? (
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              spacing={4}
              marginY={1}
            >
              <Box>
                {gameOver ? (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ChevronLeftIcon />}
                    onClick={() => handleBackToLobbyClick()}
                  >
                    {leagueGameType === 'swiss-tournament' ||
                    leagueGameType === 'tournament' ||
                    leagueGameType === 'tournament-tiebreaker'
                      ? 'Back to Tourney'
                      : isRanked
                      ? 'Back to Lobby'
                      : arenaGame
                      ? 'Back to the Pub'
                      : fromDailies
                      ? 'Back to Dailies'
                      : isPunchcard
                      ? 'Back to Punchcard'
                      : 'Back to Home'}
                  </Button>
                ) : null}
              </Box>

              <ToggleStats
                arenaSet={arenaSet}
                handleShowStatsChange={handleShowStatsChange}
                mode={mode}
                toggleQuestionsStats={toggleQuestionsStats}
              />
            </Stack>
          ) : (
            <Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={<ChevronLeftIcon />}
                onClick={() => handleBackToLobbyClick()}
              >
                {leagueGameType === 'swiss-tournament' ||
                leagueGameType === 'tournament' ||
                leagueGameType === 'tournament-tiebreaker'
                  ? 'Back to Tourney'
                  : isRanked
                  ? 'Back to Lobby'
                  : fromArena
                  ? 'Back to the Pub'
                  : fromDailies
                  ? 'Back to Dailies'
                  : isPunchcard
                  ? 'Back to Punchcard'
                  : 'Back to Home'}
              </Button>
            </Box>
          )}

          {mode === 'teams' &&
          toggleQuestionsStats === 'stats' &&
          detailedPlayers &&
          myTeam &&
          opponentTeam ? (
            <Box mt={2} display={'flex'} justifyContent={'center'}>
              <ScoreCardStatsTeams
                detailedPlayers={detailedPlayers}
                league={!!leagueGameType}
                myTeam={myTeam}
                otherTeam={opponentTeam}
              />
            </Box>
          ) : null}

          {mode === 'sit-n-go' &&
          playersCount > 2 &&
          toggleQuestionsStats === 'stats' &&
          detailedPlayers ? (
            <Box mt={2} display={'flex'} justifyContent={'center'}>
              <ScoreCardStatsSnG detailedPlayers={detailedPlayers} />
            </Box>
          ) : null}

          {arenaGame &&
          toggleQuestionsStats === 'teams' &&
          detailedPlayers &&
          myArenaTeam &&
          otherArenaTeam ? (
            <Box mt={2} display={'flex'} justifyContent={'center'}>
              <ScoreCardStatsArenaTeams
                detailedPlayers={detailedPlayers}
                myArenaTeam={myArenaTeam}
                myArenaTeamScore={myArenaTeamScore}
                otherArenaTeam={otherArenaTeam}
                otherArenaTeamScore={otherArenaTeamScore}
              />
            </Box>
          ) : null}

          {toggleQuestionsStats === 'scorecard' && (
            <Stack spacing={2} pt={2} justifyContent={'center'}>
              {achievements && achievements.length > 0 && (
                <Stack
                  sx={{
                    p: 2,
                    border: '1px solid',
                    borderColor: theme.palette.border,
                    borderRadius: 1,
                  }}
                >
                  <Typography textAlign="center" sx={{ letterSpacing: 5 }}>
                    ACHIEVEMENTS ({achievements.length})
                  </Typography>

                  <Stack direction={'row'} flexWrap={'wrap'} pt={2}>
                    {achievements.map((a, index) => (
                      <Stack direction={'row'} spacing={2} key={index} m={1}>
                        <Box position="relative">
                          <Avatar
                            variant="square"
                            src={getAvatarSrc(
                              a.imagePublicId,
                              a.imageUrl,
                              100,
                              100
                            )}
                            sx={{
                              border: '1px solid',
                              borderColor: theme.palette.border,
                              borderRadius: 1,
                              width: 50,
                              height: 50,
                            }}
                          />
                          {a.new && (
                            <Tooltip title="New Achievement" placement="top">
                              <StarIcon
                                color="secondary"
                                sx={{
                                  position: 'absolute',
                                  top: '-10px',
                                  left: '-10px',
                                  outline: '1px solid #12c4ff',
                                }}
                              />
                            </Tooltip>
                          )}
                        </Box>

                        <Stack>
                          <Typography variant="body2" color="secondary">
                            {a.displayName}
                          </Typography>
                          <Tooltip title={a.condition} placement="top">
                            <Typography
                              variant="caption"
                              sx={{
                                width: '200px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {a.condition}
                            </Typography>
                          </Tooltip>
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              )}

              {detailedPlayerSelf ? (
                <Stack
                  sx={{
                    p: 2,
                    border: '1px solid',
                    borderColor: theme.palette.border,
                    borderRadius: 1,
                  }}
                >
                  <Scorecard
                    arenaSet={arenaSet}
                    backgroundColor={'#121212'}
                    categorySet={categorySet}
                    detailedPlayers={detailedPlayers}
                    gameId={gameId}
                    gameType={gameType}
                    hideTitle={mode === 'teams' || mode === 'sit-n-go'}
                    league={!!leagueGameType}
                    leagueGameType={leagueGameType}
                    mode={mode}
                    myTeam={myTeam}
                    opponentTeam={opponentTeam}
                    profileId={profile._id}
                    questionSet={questionSet}
                    selectedPlayer={selectedPlayer}
                  />
                </Stack>
              ) : null}
            </Stack>
          )}

          {/* {toggleQuestionsStats === 'teams' &&
          arenaTeamsDetailed &&
          arenaTeamRoundScores &&
          detailedPlayers ? (
            <ScoreCardArenaTeams
              arenaTeamsDetailed={arenaTeamsDetailed}
              arenaTeamRoundScores={arenaTeamRoundScores}
              detailedPlayers={detailedPlayers}
            />
          ) : null} */}
        </Box>
        {mode === 'sit-n-go' ? (
          <Drawer
            sx={{
              width: { xs: 144, sm: SIT_AND_GO_DRAWER_WIDTH },
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: { xs: 144, sm: SIT_AND_GO_DRAWER_WIDTH },
                boxSizing: 'border-box',
                bgcolor: '#333333',
                maxHeight: { xs: 416, sm: '100%' },
              },
            }}
            variant="permanent"
            anchor="right"
          >
            {/* {arenaGame && (
              <ToggleButtonGroup
                color="primary"
                value={soloTeamsToggleValue}
                exclusive
                onChange={handleSoloTeamChange}
                size="small"
              >
                <ToggleButton value="teams" fullWidth>
                  Teams
                </ToggleButton>
                <ToggleButton value="solo" fullWidth>
                  Everyone
                </ToggleButton>
              </ToggleButtonGroup>
            )} */}

            {/* {soloTeamsToggleValue === 'teams' &&
              arenaTeams &&
              arenaTeamLiveRoundScores && (
                <ArenaTeamsLiveLeaderboard
                  arenaTeamId={detailedPlayerSelf?.arenaTeamId}
                  arenaTeams={arenaTeams}
                  arenaTeamLiveRoundScores={arenaTeamLiveRoundScores}
                  handleTeamClick={handleTeamClick}
                  selectedTeamId={selectedTeamId}
                  sortedPlayers={sortedPlayers}
                  spectate={spectate}
                />
              )} */}

            {soloTeamsToggleValue === 'solo' && (
              <SitAndGoLeaderboard
                arenaGame={arenaGame}
                arenaTeamId={detailedPlayerSelf?.arenaTeamId}
                // arenaProjection={arenaProjection}
                // arenaPlayersProjectionAndTiers={arenaPlayersProjectionAndTiers}
                gameOver={true}
                // handleTierClick={handleTierClick}
                // handleTierHover={handleTierHover}
                // hoveredTier={hoveredTier}
                onUsernameClick={handleUsernameClick}
                sortedPlayers={sortedPlayers}
                profileId={profile._id}
                selectedTier={selectedTier}
                spectate={spectate}
              />
            )}
          </Drawer>
        ) : null}
      </Box>

      {openDailiesStreaksDialog && dailiesStreakAchievements && (
        <DailiesStreaksDialog
          open={openDailiesStreaksDialog}
          onClose={() => setOpenDailiesStreaksDialog(false)}
          dailiesStreakAchievements={dailiesStreakAchievements}
        />
      )}

      {showTrueSkillDialog && playerArenaTrueSkillFound && (
        <ArenaTrueSkillDialog
          open={trueSkillDialogOpen}
          onClose={() => setTrueSkillDialogOpen(false)}
          arenaTrueSkill={playerArenaTrueSkillFound}
        />
      )}

      {openArenaAchievementsDialog && (
        <ArenaAchievementsDialog
          open={openArenaAchievementsDialog}
          onClose={() => setOpenArenaAchievementsDialog(false)}
          arenaAchievementsThisGame={filteredArenaAchievements}
        />
      )}

      <MmrResultsDialog
        arenaUpdateTrueSkill={arenaUpdateTrueSkill}
        open={mmrResultsDialogOpen}
        onClose={() => setMmrResultsDialogOpen(false)}
        sortedPlayers={sortedPlayers}
      />
    </>
  );
};
