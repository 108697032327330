import { average } from '../../../utils/math';
import { DailySetsPlayed } from '../types';

export const getRecent4GameScoresPerDay = (
  dailySetsPlayed: DailySetsPlayed[]
) => {
  if (!dailySetsPlayed) return [];

  const dayOfWeekAvgScores = [];

  for (let i = 0; i <= 6; i++) {
    const filtered = dailySetsPlayed.filter((set) => set.dailyDayOfWeek === i);
    const latest4 = filtered.slice(-4);
    if (latest4.length === 0) {
      dayOfWeekAvgScores.push(null);
      continue;
    }
    const avg = Math.round(average(latest4.map((set) => set.score)));
    dayOfWeekAvgScores.push(avg);
  }

  return dayOfWeekAvgScores;
};
