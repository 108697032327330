import { Box, Fade, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../styles.css';

type PropTypes = {
  displayGameClock: boolean;
  freezeGameClock: boolean;
  gameClock: number;
  par: number;
  spectate: boolean;
};

export const GameClock = ({
  displayGameClock,
  freezeGameClock,
  par,
  gameClock,
  spectate,
}: PropTypes) => {
  const [timerSnapshot, setTimerSnapshot] = useState(0);

  useEffect(() => {
    if (freezeGameClock && timerSnapshot === 0) {
      setTimerSnapshot(gameClock);
    } else if (!freezeGameClock && timerSnapshot > 0) {
      setTimerSnapshot(0);
    }
  }, [freezeGameClock, gameClock, timerSnapshot]);

  return (
    <Box
      sx={{
        height: 90,
        display: !displayGameClock ? 'none' : 'flex',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Fade in={displayGameClock}>
        {/* A bug in the Grow mui transitions requires this <div>
      https://github.com/mui/material-ui/issues/27154#issuecomment-1117386458 */}
        <div>
          <Typography
            className={gameClock === par ? 'game-timer-grow' : ''}
            textAlign="center"
            letterSpacing="0.25em"
            variant="h2"
            ml={'.25em'} // balances the letterSpacing gap on the last character
            mt={0.5}
            mb={1}
          >
            {/* spectators don't have a freeze on the gamclock and don't see the "opponent timer"
             */}
            {spectate ? (
              <>
                00:{gameClock < 10 ? '0' : null}
                {gameClock}
              </>
            ) : (
              <>
                00:
                {(freezeGameClock ? timerSnapshot : gameClock) < 10
                  ? '0'
                  : null}
                {freezeGameClock ? timerSnapshot : gameClock || 0}
              </>
            )}
          </Typography>
        </div>
      </Fade>
    </Box>
  );
};
