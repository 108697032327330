import React from 'react';
import {
  Button,
  CircularProgress,
  CircularProgressProps,
  Stack,
  circularProgressClasses,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/material';
import './ValorantButton.scss';
import GridLoader from 'react-spinners/GridLoader';

function FacebookCircularProgress(props: CircularProgressProps) {
  return (
    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={25}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: '#9affd0',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={25}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

type PropTypes = {
  buttonZIndex?: number;
  displaySearchSpinner?: boolean;
  disabled?: boolean;
  label: string;
  loading?: boolean;
  loadingLabel?: string;
  onClick: () => void;
  size?: 'small' | 'large' | 'xl';
  showAnimation?: boolean;
};

export const ValorantButton = ({
  buttonZIndex,
  displaySearchSpinner,
  disabled,
  label,
  loading,
  loadingLabel,
  onClick,
  size,
  showAnimation,
}: PropTypes) => {
  return (
    <Button
      className={`valorant-container`}
      sx={{
        zIndex: buttonZIndex || 0,
        padding: 0,
        '&:hover': { backgroundColor: 'transparent' },
      }}
      disabled={disabled}
      onClick={onClick}
    >
      <Box className={`${disabled ? 'disabled' : 'valorant-link'} ${size}`}>
        <Box className="valorant-text">
          {showAnimation ? <Box className="valorant-bg"></Box> : null}
          <Box className="valorant-base"></Box>
          {loading ? (
            <Stack
              direction={'row'}
              spacing={2}
              width={'100%'}
              pl={2}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {displaySearchSpinner ? (
                <GridLoader size={4} loading={true} color={'#9affd0'} />
              ) : (
                <FacebookCircularProgress />
              )}
              {/* <Box className="valorant-label">{loadingLabel}</Box> */}
            </Stack>
          ) : (
            <Box className="valorant-label">{label}</Box>
          )}
        </Box>
      </Box>
    </Button>
  );
};
