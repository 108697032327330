import { useQuery } from '@tanstack/react-query';
import { fetchPlacesGeocode } from '../../../api/apiServices';

const useFetchPlacesGeocode = (placeId: string) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['geocode', { placeId }],
    queryFn: fetchPlacesGeocode,
    enabled: !!placeId,
  });

  return {
    refetch,
    isLoading,
    geocode: data?.geocode[0],
  };
};

export default useFetchPlacesGeocode;
