import React from 'react';
import { Avatar, Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import { Player } from '../../../../../types/player';
import { Team } from '../../../../../types/game';
import StyledActionAvatar from '../ActionAvatar/StyledActionAvatar';
import PlayerSlantedLineTitle, {
  MIRROR_OFFSET,
  SLANTED_LINE_WIDTH,
  TOTAL_WIDTH,
} from '../ActionAvatar/PlayerSlantedLineTitle';
import { ScorePenaltyColumnsDisplay } from '../PlayerScoreBox/ScorePenaltyColumnsDisplay';
import { getAvatarSrc } from '../../../../../api/cloudinary';

const SCORE_BOX_WIDTH = 350;

type Props = {
  players: Record<string, Player>;
  playerInfoDisplayPosition: 'left' | 'right';
  team: Team;
  timerLimitReached: boolean;
};

const TeamScoreBox = ({
  players,
  playerInfoDisplayPosition,
  team,
  timerLimitReached,
}: Props) => {
  const { palette } = useTheme();

  if (!team) {
    return <Box width={SCORE_BOX_WIDTH}></Box>;
  }

  const flexDirection =
    playerInfoDisplayPosition === 'left' ? 'row' : 'row-reverse';

  const boxShadow = () => {
    // if (penalty?.penaltyTimestamp) return 'warning.main';

    if (!team.correctAnswer && !timerLimitReached) return 'none';

    return team.correctAnswer
      ? `${palette.success.main} 0px 0px 20px 5px, ${palette.success.main} 0px 0px 5px 0px, ${palette.success.main} 0px 0px 5px 0px`
      : `${palette.error.light} 0px 0px 10px 0px, ${palette.error.light} 0px 0px 0px 0px, ${palette.error.light} 0px 0px 0px 0px`;
  };

  const getTeamMembersJoinedGame = (players: Record<string, Player>) =>
    Object.values(players).filter(
      (p) => team.members.includes(p.profileId) && p.joinedGame
    );

  return (
    // <TeamActionAvatar
    //   players={players}
    //   position={playerInfoDisplayPosition}
    //   team={team}
    //   timerLimitReached={timerLimitReached}
    // />
    <Stack
      className="TeamScoreContainerStack"
      height={160}
      width={SCORE_BOX_WIDTH}
      mb={getTeamMembersJoinedGame(players).length}
    >
      <Box
        className="TeamScoreBox"
        display={'flex'}
        pt={3}
        pb={2}
        px={2}
        alignItems={'flex-start'}
        borderRadius={0}
        boxShadow={boxShadow}
        flexDirection={flexDirection}
      >
        <StyledActionAvatar
          avatarPublicId={team.avatarPublicId}
          avatarUrl={team.avatarUrl}
          correctAnswer={team.correctAnswer}
          currentScore={team.currentScore}
          height={56}
          width={56}
          // penalty={penalty}
          timerLimitReached={timerLimitReached}
          teamGame
        />

        <Box ml={-2} mt={1} className="PlayerSlantedLineBox">
          <PlayerSlantedLineTitle
            playerInfoDisplayPosition={playerInfoDisplayPosition}
            text={team.username}
          />
        </Box>

        <Box
          className="ScorePenaltyBox"
          position={'relative'}
          top={8}
          width={TOTAL_WIDTH - SLANTED_LINE_WIDTH - MIRROR_OFFSET}
          right={playerInfoDisplayPosition === 'left' ? '152px' : '-152px'}
        >
          <ScorePenaltyColumnsDisplay
            playerInfoDisplayPosition={playerInfoDisplayPosition}
            penalty={team.totalPenalty}
            score={team.parScore}
          />
        </Box>
      </Box>

      <Stack p={0.5} className="TeamMembersStack">
        {getTeamMembersJoinedGame(players).map((p) => (
          <Stack
            className="MemberStack"
            direction={'row'}
            spacing={1}
            mb={0.25}
            justifyContent={
              playerInfoDisplayPosition === 'left' ? 'flex-start' : 'flex-end'
            }
          >
            {playerInfoDisplayPosition === 'left' ? (
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                }}
                src={getAvatarSrc(p.avatarPublicId, p.avatarUrl, 200, 200)}
              />
            ) : null}
            <Typography
              maxWidth={'300px'}
              color={
                team.correctAnswer &&
                team.correctAnswerPlayerUsername === p.username
                  ? 'success.main'
                  : undefined
              }
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {p.username}
            </Typography>
            {playerInfoDisplayPosition === 'right' ? (
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                }}
                src={getAvatarSrc(p.avatarPublicId, p.avatarUrl, 200, 200)}
              />
            ) : null}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default TeamScoreBox;
