import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, Box, Stack, Typography, useTheme } from '@mui/material';

import { CgAsterisk } from 'react-icons/cg';
import '../fonts.css';
import {
  PristineBox,
  PristineTypography,
  PoisedBox,
  PoisedTypography,
  PassableBox,
  PassableTypography,
  PanickyBox,
  PanickyTypography,
} from '../components/scorecard/PrecisionGraphicScorecardIcons';

type PropTypes = {
  close: () => void;
  open: boolean;
  theme: any;
};

export const ScorecardLegendDialog = ({ close, open, theme }: PropTypes) => {
  return (
    <Dialog open={open} onClose={close} maxWidth="md">
      <DialogTitle>
        <Typography
          variant="h4"
          textAlign={'center'}
          sx={{
            textShadow:
              theme.palette.mode === 'dark'
                ? `0 0 0px ${theme.palette.secondary.main}, 0 0 0px ${theme.palette.secondary.main}, 0px 0px 16px ${theme.palette.secondary.main}`
                : 'none',
          }}
        >
          Legend
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ width: '100%', height: '100%' }}>
        <Stack direction="row" spacing={5}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <Avatar
                sx={{
                  height: '25px',
                  width: '25px',
                  bgcolor: '#434343',
                  color: 'error.contrastText',
                }}
                variant={'rounded'}
              >
                {' '}
              </Avatar>
              <Typography variant="caption" lineHeight={2}>
                Correct answer (aka "Putt")
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Avatar
                sx={{
                  height: '25px',
                  width: '25px',
                  bgcolor: `${theme.palette.success.main}40`,
                  color: 'text.primary',
                  textAlign: 'center',
                  boxShadow: `0 0 5px 1px ${theme.palette.success.main}, 0 0 2px 3px ${theme.palette.success.main} inset`,
                }}
              >
                {' '}
              </Avatar>
              <Typography variant="caption" lineHeight={2}>
                Correct answer under PAR (0)
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Avatar
                sx={{
                  height: '25px',
                  width: '25px',
                  bgcolor: `${theme.palette.error.main}40`,
                  color: 'error.contrastText',
                  display: 'inline-flex',
                  boxShadow: `0 0 5px 1px ${theme.palette.error.main}, 0 0 2px 3px ${theme.palette.error.main} inset`,
                }}
                variant={'square'}
              >
                {' '}
              </Avatar>
              <Typography variant="caption" lineHeight={2}>
                Timeout
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Avatar
                sx={{
                  height: '25px',
                  width: '25px',
                  bgcolor: theme.palette.warning.main,
                  display: 'inline-flex',
                }}
              >
                {' '}
              </Avatar>
              <Typography variant="caption" lineHeight={2}>
                Penalty (wrong guess before box reveal)
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Box sx={{ height: '25px', width: '25px' }}>
                <CgAsterisk
                  style={{
                    color: theme.palette.secondary.main,
                  }}
                />
              </Box>
              <Typography variant="caption" lineHeight={2}>
                Alternate answers (hover answer)
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <PristineBox position={'relative'} top={'0'} left={'0'}>
                <PristineTypography variant={'caption'}>P</PristineTypography>
              </PristineBox>
              <Typography variant="caption" lineHeight={2}>
                Pristine: perfectly typed answer on first attempt (-1 bonus)
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <PoisedBox position={'relative'} top={'0'} left={'0'}>
                <PoisedTypography variant={'h6'}>P</PoisedTypography>
              </PoisedBox>
              <Typography variant="caption" lineHeight={2}>
                Poised: close enough typed answer (0 penalty)
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <PassableBox position={'relative'} top={'0'} left={'0'}>
                <PassableTypography variant={'caption'}>P</PassableTypography>
              </PassableBox>
              <Typography variant="caption" lineHeight={2}>
                Passable: acceptable typed answer (+1 penalty)
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <PanickyBox position={'relative'} top={'0'} left={'0'}>
                <PanickyTypography variant={'caption'}>P</PanickyTypography>
              </PanickyBox>
              <Typography variant="caption" lineHeight={2}>
                Panicky: barely acceptable typed answer (+2 penalty)
              </Typography>
            </Stack>
            <Typography variant="caption">
              {`* Go to Help -> Spelling Leniency to view "Precision" requirements`}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
