import React from 'react';
import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AppsIcon from '@mui/icons-material/Apps';
import { Mode } from '../../types/game';
import { GiTrumpetFlag } from 'react-icons/gi';

type PropTypes = {
  arenaSet?: number;
  handleShowStatsChange: (
    event: any,
    value: 'scorecard' | 'stats' | 'teams'
  ) => void;
  mode: Mode;
  toggleQuestionsStats: 'scorecard' | 'stats' | 'teams';
};

export const ToggleStats = ({
  arenaSet,
  handleShowStatsChange,
  mode,
  toggleQuestionsStats,
}: PropTypes) => {
  return (
    <Box sx={{ backgroundColor: 'rgba(32,33,35,1)', borderRadius: 1 }}>
      <ToggleButtonGroup
        color="primary"
        value={toggleQuestionsStats}
        exclusive
        onChange={handleShowStatsChange}
        size="small"
      >
        <ToggleButton
          value="scorecard"
          fullWidth
          sx={{
            width: 150,
          }}
        >
          <FormatListBulletedIcon style={{ marginRight: 8 }} />
          Scorecard
        </ToggleButton>
        {!arenaSet && (
          <ToggleButton
            value="stats"
            fullWidth
            sx={{
              width: 140,
            }}
          >
            <AppsIcon style={{ marginRight: 8 }} />
            {mode === 'sit-n-go' ? 'Top Scores' : 'Stats'}
          </ToggleButton>
        )}
        {arenaSet && (
          <ToggleButton
            value="teams"
            fullWidth
            sx={{
              width: 150,
            }}
          >
            <GiTrumpetFlag size={28} style={{ marginRight: '8px' }} />
            Teams
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </Box>
  );
};
