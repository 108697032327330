import { useCallback, useEffect, useState } from 'react';
import {
  useSocketContext,
  useSpectateSocketContext,
} from '../../../../contexts/SocketProvider';
import {
  ChatChannel,
  ChatMember,
  ChatMessage,
  UnreadChat,
} from '../../../../types/chat';
import { useGameContext } from '../../../../contexts/GameProvider';
import { SocketCallback } from '../../../../types';
import { CHAT_CHANNELS } from '../constants';
import { Spectator } from '../../../../types/spectate';
import { useArenaContext } from '../../../../contexts/ArenaProvider';

export const useChats = (
  focused: boolean,
  selectedChatChannel: ChatChannel
) => {
  const [chatGeneral, setChatGeneral] = useState<ChatMessage[]>(null);
  const [chatMembers, setChatMembers] = useState<ChatMember[]>(null);
  const [spectators, setSpectators] = useState<Spectator[]>(null);
  const [spectatorChat, setSpectatorChat] = useState<ChatMessage[]>();

  const { socket } = useSocketContext();
  const { spectateSocket } = useSpectateSocketContext();

  const { arenaNight } = useArenaContext();

  const {
    gameId,
    showArenaChat,
    showGameChat,
    showSpectatorChat,
    spectateGameId,
    gameDataFromServer,
    chatArena,
    chatGame,
    spectatePostGameId,
  } = useGameContext();

  const arenaChatChannel = `arena-chat-night-${arenaNight}`;

  const channelMap = {
    general: chatGeneral || [],
    game: chatGame || [],
    spectate: spectatorChat || [],
    arena: chatArena || [],
  };

  const [unreadChats, setUnreadChats] = useState<UnreadChat>({
    general: { unread: 0, lastSeen: Date.now() },
    game: { unread: 0, lastSeen: Date.now() },
    spectate: { unread: 0, lastSeen: Date.now() },
    arena: { unread: 0, lastSeen: Date.now() },
  });

  useEffect(() => {
    if (!socket) return;

    socket.on('chatGeneral', (messages) => setChatGeneral(messages.reverse()));

    return () => {
      socket.off('chatGeneral', setChatGeneral);
    };
  }, [socket, spectateSocket]);

  useEffect(() => {
    const updateUnreadChats = (channel: ChatChannel) => {
      if (!channel) return;
      setUnreadChats((prev) => ({
        ...prev,
        [channel]: {
          unread: 0,
          lastSeen: Date.now(),
        },
      }));
    };

    const incrementIfNecessary = (chatArray) => {
      if (!chatArray?.length) return;
      if (focused) {
        updateUnreadChats(selectedChatChannel);
      }
      incrementUnreadChats(!focused ? null : selectedChatChannel);
    };

    incrementIfNecessary(chatGeneral);
  }, [chatGeneral, focused]);

  const incrementUnreadChats = (selectedChannel: ChatChannel | null) => {
    CHAT_CHANNELS.forEach((channel) => {
      if (channel !== selectedChannel) {
        const unread = channelMap[channel].filter(
          (c) =>
            c.createdAt && c.createdAt > (unreadChats[channel].lastSeen || 0)
        ).length;
        setUnreadChats((prev) => ({
          ...prev,
          [channel]: {
            unread,
            lastSeen: prev[channel].lastSeen,
          },
        }));
      }
    });
  };

  const requestChatHistory = useCallback(() => {
    if (!socket) return;
    if (arenaChatChannel) {
      socket.emit('requestArenaChatHistory', { arenaNight });
      return;
    }
    socket.emit('requestChatHistory', { gameId });
  }, [socket, gameId, arenaNight]);

  const resetUnreadChats = (channel: string) => {
    setUnreadChats((prev) => ({
      ...prev,
      [channel]: {
        unread: 0,
        lastSeen: Date.now(),
      },
    }));
  };

  const totalUnread = unreadChats['general'].unread;

  const emitChatGeneral = useCallback(
    (text: string, username: string) => {
      if (!socket) return;
      socket.emit('chatGeneral', {
        username,
        text,
      });
    },
    [socket]
  );
  return {
    arenaNight,
    chatArena,
    chatGame,
    chatGeneral,
    chatMembers,
    emitChatGeneral,
    gameDataFromServer,
    gameId,
    incrementUnreadChats,
    requestChatHistory,
    resetUnreadChats,
    showArenaChat,
    showGameChat,
    showSpectatorChat,
    spectatePostGameId,
    spectateGameId,
    spectatorChat,
    spectators,
    totalUnread,
    unreadChats,
  };
};
