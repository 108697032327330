import {
  ARENA_TIME_LIMIT,
  LEAGUE_TIME_LIMIT,
  TIME_LIMIT,
} from '../../constants/constants';
import { LeagueGameType } from '../../types';

export const isTimedOut = (
  par: number,
  score: number,
  leagueGameType?: LeagueGameType,
  arenaSet?: number
) =>
  (leagueGameType === 'league'
    ? LEAGUE_TIME_LIMIT
    : !!arenaSet
    ? ARENA_TIME_LIMIT
    : TIME_LIMIT) -
    par ===
  score;
