import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGameContext } from '../../contexts/GameProvider';
import { useSpectateSocketContext } from '../../contexts/SocketProvider';
import { useProfileContext } from '../../contexts/ProfileProvider';

type LocationState = {
  fromDailies?: boolean;
  fromQuickPlay?: boolean;
  fromArena?: boolean;
  rejoin?: boolean;
};

/*

This component only exists as a hack for rerendering the <Room> component

When did this get added?

When the "quick join from scorescreen" was added.
Since if you are on scorescreen, you are still in the Room,
and when a new game starts, it needs to re-initialize a lot elements
that occur on Room mount.

Obviously this should've been refactored when that feature was added,
but a temporary "middleman" that leaves the room and reroutes back to the room
was the quick and dirty while staying fairly open-closed.

*/

export const GameLoading = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state as LocationState;

  const { profile } = useProfileContext();
  const {
    spectateGameId,
    setSpectateGameId,
    setSpectateGameDataFromServer,
    setSpectateLeagueGameType,
  } = useGameContext();
  const { spectateSocket } = useSpectateSocketContext();

  useEffect(() => {
    if (profile && spectateGameId && spectateSocket) {
      setSpectateGameDataFromServer(null);
      setSpectateGameId(null);
      setSpectateLeagueGameType(null);
      spectateSocket.emit('stopSpectating', {
        profileId: profile._id,
        gameId: spectateGameId,
      });
    }

    navigate(`/game-session`, {
      state: {
        fromDailies: locationState?.fromDailies,
        fromQuickPlay: locationState?.fromQuickPlay,
        fromArena: locationState?.fromArena,
        rejoin: locationState?.rejoin,
      },
    });

    setTimeout(() => {
      if (location.pathname === '/game-loading') {
        navigate(`/game-session`, {
          state: {
            fromDailies: locationState?.fromDailies,
            fromQuickPlay: locationState?.fromQuickPlay,
            fromArena: locationState?.fromArena,
            rejoin: locationState?.rejoin,
          },
        });
      }
    }, 2000);
  }, []);
  return <></>;
};
