import { useMutation } from '@tanstack/react-query';
import { updateArenaLadder } from '../../../../api/apiServices';

const useUpdateArenaLadder = () => {
  const { mutate } = useMutation({
    mutationFn: updateArenaLadder,
  });

  return {
    updateArenaLadder: mutate,
  };
};

export default useUpdateArenaLadder;
