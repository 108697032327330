import { useQuery } from '@tanstack/react-query';
import { fetchArenaSchedule } from '../../../../api/apiServices';

const useFetchArenaSchedule = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['arenaSchedule'],
    queryFn: fetchArenaSchedule,
  });

  return {
    refetch,
    isLoading,
    data,
  };
};

export default useFetchArenaSchedule;
