import { AVES } from '../../../constants/constants';
import { Player, DetailedPlayer } from '../../../types/player';
import { assignRanks } from '../../../utils/game';

export const getAveColor = (name: string, mode: string) =>
  AVES.find((a) => a.name === name)?.color[
    mode === 'dark' ? 'light' : 'dark'
  ] || '';

export const getAveShineColor = (name: string, mode: string) =>
  AVES.find((a) => a.name === name)?.shineColor[
    mode === 'dark' ? 'light' : 'dark'
  ] || '';

export const getAveName = (score: number) => {
  const found = AVES.find((a) => a.score === score)?.name;
  if (found) return found;
  if (score < -6) return 'pterodactyl'; // scores shouldn't be less than -6, but if achieved, still ptero
  return '';
};

export const sortPlayers = (
  players: Record<string, Player | DetailedPlayer>
) => {
  const sortedPlayers = Object.values(players).sort(
    (a, b) => a.parScore - b.parScore
  );
  return assignRanks(sortedPlayers);
};
