import React from 'react';
import {
  Card,
  CardActionArea,
  Stack,
  useTheme,
  Avatar,
  styled,
  TooltipProps,
  Tooltip,
  tooltipClasses,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Achievement } from '../../../types/achievement';

export const AchievementTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#111a19',
    color: 'white',
    fontSize: 16,
    borderRadius: 5,
    border: '1px solid',
    borderColor: '#a48672',
    maxWidth: '100%',
  },
}));

const achievementTooltipContent = (achievement: Achievement) => {
  return (
    <Stack direction={'row'} spacing={2} p={2}>
      <Avatar
        variant="rounded"
        src={achievement.imageUrl}
        alt="image"
        sx={{ width: 'auto', height: 100 }}
      />

      <Stack spacing={2}>
        <Typography
          variant="h6"
          textAlign={'center'}
          fontWeight={600}
          letterSpacing={2}
        >
          {achievement.displayName?.toUpperCase()}
        </Typography>

        <Typography variant="body2" textAlign={'center'}>
          {achievement.condition}
        </Typography>
      </Stack>
    </Stack>
  );
};

type Props = {
  achievement: Achievement;
  achieved: boolean;
};

const QuickPlayAchievementCard = ({ achievement, achieved }: Props) => {
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const smDownScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Card
      sx={{
        m: 1,
      }}
    >
      <CardActionArea>
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          position={'relative'}
        >
          <AchievementTooltip
            title={achievementTooltipContent(achievement)}
            placement="top"
          >
            <Avatar
              variant="rounded"
              src={achievement.imageUrl}
              alt="image"
              sx={{
                width: 95,
                height: 95,
                border: '1px solid',
                borderColor: 'secondary.main',
                bgcolor: 'black',
                filter: achieved ? '' : 'grayscale(100%)',
                opacity: achieved ? 1 : 0.5,
                '& img': {
                  objectFit: 'cover',
                },
              }}
            />
          </AchievementTooltip>
          {/* <Typography variant="body2" textAlign={'center'}>
            {achievement.title.toUpperCase()}
          </Typography> */}
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export default QuickPlayAchievementCard;
