import React, { useState } from 'react';
import { Alert, Grid, Stack } from '@mui/material';
import Announcement from './Announcement';
import MmrLeaderboard from './MmrLeaderboard/MmrLeaderboard';
import DailiesCard from './DailiesCard';
import { useProfileContext } from '../../contexts/ProfileProvider';
import useFetchCurrentDaily from '../dailies_old/hooks/useFetchCurrentDaily';
import useQuickPlayManager from '../quick-play/hooks/useQuickPlayManager';
import { PlayDailyConfirmDialog } from './PlayDailyConfirmDialog';
import { useLobbyGamesContext } from '../../contexts/LobbyGamesProvider';
import BasicLayout from '../../BasicLayout/BasicLayout';

export const Home = () => {
  const { profile, mutateProfile } = useProfileContext();

  const { maintenance } = useLobbyGamesContext();

  const { data: currentDailyData } = useFetchCurrentDaily();

  const [openPlayDailyConfirmDialog, setOpenPlayDailyConfirmDialog] =
    useState(false);

  const onCreateGameSuccess = () => {
    const newProfile = {
      ...profile,
      lastDailySetPlayed: currentDailyData.currentDailySet,
    };
    mutateProfile(newProfile);
  };

  const { createGame } = useQuickPlayManager(onCreateGameSuccess);

  const disableDaily =
    profile?.lastDailySetPlayed === currentDailyData?.currentDailySet;

  const handlePlayDailyConfirmClick = async () => {
    const createGameConfig = {
      dailySet: currentDailyData.currentDailySet,
      missedDaily: false,
    };

    await createGame(profile, createGameConfig);
  };

  const handlePlayDailyClick = (e) => {
    e.stopPropagation();
    setOpenPlayDailyConfirmDialog(true);
  };

  return (
    <>
      <BasicLayout>
        <Grid container spacing={3} className="CONTAINER">
          <Grid item xs={12} sm={8}>
            <Announcement />

            <Alert severity="warning" sx={{ mt: 2 }}>
              <strong>BETA:</strong> You'll notice bugs and missing features. We
              are working hard to address them. Thank you for your patience!
            </Alert>

            <Stack pt={8}>
              <DailiesCard
                disableDaily={disableDaily}
                handlePlayDailyClick={handlePlayDailyClick}
                nextDailySchedule={currentDailyData?.nextDailySchedule}
              />
            </Stack>
          </Grid>

          <Grid item xs={0} sm={4} display={{ xs: 'none', sm: 'block' }}>
            <MmrLeaderboard />
          </Grid>
        </Grid>
      </BasicLayout>

      {openPlayDailyConfirmDialog && currentDailyData && (
        <PlayDailyConfirmDialog
          handlePlayDailyClick={handlePlayDailyConfirmClick}
          isMissedDaily={false}
          maintenance={maintenance}
          onClose={() => setOpenPlayDailyConfirmDialog(false)}
          open={openPlayDailyConfirmDialog}
        />
      )}
    </>
  );
};
