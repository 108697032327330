import { useMutation } from '@tanstack/react-query';
import { updateUserLocation } from '../../../api/apiServices';

const useUpdateUserLocation = () => {
  const { mutate } = useMutation({
    mutationFn: updateUserLocation,
  });

  return {
    updateUserLocation: mutate,
  };
};

export default useUpdateUserLocation;
