import React from 'react';
import { Profile } from '../../../types/player';
import Summary from './Summary';
import { Aves } from './Aves';
import { Precision } from './Precision';
import { RecentGames } from './RecentGames';

type Props = {
  viewProfile: Profile;
};

const QuickPlayProfile = ({ viewProfile }: Props) => {
  return (
    <>
      <Summary viewProfile={viewProfile} />

      <Aves viewProfile={viewProfile} />

      <Precision viewProfile={viewProfile} />

      <RecentGames viewProfile={viewProfile} />
    </>
  );
};

export default QuickPlayProfile;
