import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';

interface ErrorDialogContextType {
  close: () => void;
  message: string;
  open: boolean;
  title: string;
  setMessage: (message: string) => void;
  setOpen: (open: boolean) => void;
  setTitle: (title: string) => void;
}

export const ErrorDialogContext = createContext<ErrorDialogContextType>({
  close: () => {},
  message: '',
  open: false,
  title: '',
  setMessage: () => {},
  setOpen: () => {},
  setTitle: () => {},
});

type ErrorDialogProviderProps = {
  children: ReactNode;
};

export const useErrorDialog = () => useContext(ErrorDialogContext);

export const ErrorDialogProvider: FC<ErrorDialogProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');

  const close = () => setOpen(false);

  return (
    <ErrorDialogContext.Provider
      value={{
        close,
        message,
        open,
        title,
        setMessage,
        setOpen,
        setTitle,
      }}
    >
      {children}
    </ErrorDialogContext.Provider>
  );
};
