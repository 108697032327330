import {
  List,
  ListItem,
  ListItemText,
  Avatar,
  Divider,
  ListItemButton,
  useTheme,
  Stack,
  Typography,
  Tooltip,
  InputBase,
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Box } from '@mui/system';
import { getAvatarSrc } from '../../../api/cloudinary';
import { Player } from '../../../types/player';
import { LINEAR_GRADIENTS_OPAQUE } from '../../profile/ArenaProfile/ArenaLadder/dialogs/constants';
import { TIERS_BOTTOM_UP } from './constants';
import { getFuzzyPenalty } from './utils';
import PeopleIcon from '@mui/icons-material/People';
import PuffLoader from 'react-spinners/PuffLoader';
import { useGameContext } from '../../../contexts/GameProvider';
import { SidebarChat } from '../../../components/game-sidebar/SidebarChat';
import { useSocketContext } from '../../../contexts/SocketProvider';
import { useProfileContext } from '../../../contexts/ProfileProvider';

type PropTypes = {
  arenaGame?: number;
  arenaProjection?: number;
  arenaPlayersProjectionAndTiers?: Record<
    string,
    {
      projection: number;
      currentTier: string;
    }
  >;
  arenaTeamId?: string;
  cutoff?: number;
  disableChat?: boolean;
  gameOver: boolean;
  handleTierClick?: (tier: string) => void;
  handleTierHover?: (tier: string) => void;
  hoveredTier?: string;
  modalView?: boolean;
  onUsernameClick?: (profileId: string) => void;
  sortedPlayers?: Player[];
  profileId: string;
  questionIndex?: number;
  selectedTier?: string;
  spectate?: boolean;
};

export const SitAndGoLeaderboard = ({
  arenaGame,
  arenaProjection,
  arenaPlayersProjectionAndTiers,
  arenaTeamId,
  cutoff,
  disableChat,
  gameOver,
  handleTierClick,
  handleTierHover,
  hoveredTier,
  modalView,
  onUsernameClick,
  sortedPlayers,
  profileId,
  questionIndex,
  selectedTier,
  spectate,
}: PropTypes) => {
  const teamPurple = '#ec9bff';
  const teamBlue = '#00e0ff';

  const { socket } = useSocketContext();
  const { profile } = useProfileContext();
  const { gameId, chatGame, chatMembers } = useGameContext();

  const [chatInputFocused, setChatInputFocused] = useState(false);
  const inputBaseRef = useRef<any>(null);
  const disabledChatRef = useRef<boolean>(false);

  const MAX_LEADERBOARD_COUNT = spectate ? 1000 : 200;
  // duration 1000 is causing a DOM exception error
  // because a new state is set before the duration can finish.
  // const animationParent = useAutoAnimate({}) as any;
  const [animationParent] = useAutoAnimate<any>({
    duration: 250,
  });
  const [cutoffIndex, setCutoffIndex] = useState<number>();
  const [cutoffPlayers, setCutoffPlayers] = useState<{
    start: number;
    end: number;
  }>();
  const [selectedView, setSelectedView] = useState<'top' | 'cutoff'>('top');
  const [playersViewSpread, setPlayersViewSpread] = useState(10);

  const theme = useTheme();

  useEffect(() => {
    if (!socket || !profile || !inputBaseRef || !gameId) return;

    const keyDownHandler = (event: any) => {
      if (disabledChatRef.current) return;
      if (event.key === 'Enter') {
        event.preventDefault();
        if (!inputBaseRef.current?.value) return;
        socket.emit('chatGame', {
          gameId,
          username: profile.username,
          text: inputBaseRef.current.value,
          leftChat: false,
        });
        inputBaseRef.current.value = '';
      }
    };

    socket.emit('requestChatHistory', {
      gameId,
      profileId: profile._id,
    });

    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [profile, socket, inputBaseRef, gameId]);

  useEffect(() => {
    disabledChatRef.current = disableChat;
    if (!disableChat) {
      inputBaseRef.current?.focus();
    }
  }, [disableChat]);

  const getPlayersRankedAbove = (
    sortedPlayers: Player[],
    profileId: string
  ) => {
    const foundIndex = sortedPlayers.findIndex(
      (p) => p.profileId === profileId
    );
    return foundIndex;
  };

  const nameColor = (player: Player, questionIndex?: number) => {
    if (questionIndex === undefined) return 'white';
    if (player.timerLimitReached) return theme.palette.error.main;
    if (player.currentScore !== null) return theme.palette.success.main;
    return 'white';
  };

  const calcCutoffLine = () => {
    if (!cutoff || !sortedPlayers) return;
    const cutoffLine = sortedPlayers?.filter(
      (p) => p.rank && p.rank <= cutoff
    ).length;
    return (cutoffLine || 0) - 1;
  };

  const handleViewToggle = (
    event: React.MouseEvent<HTMLElement>,
    selected: 'top' | 'cutoff'
  ) => {
    setSelectedView(selected);
  };

  const showCutoffLine = (index: number) => {
    if (!sortedPlayers) return;
    if (gameOver && index === cutoffIndex) return true;
    if (spectate && index === cutoffIndex) return true;
    if (
      sortedPlayers.length > MAX_LEADERBOARD_COUNT &&
      selectedView === 'cutoff'
    ) {
      const lineIndex = playersViewSpread === 10 ? 9 : 4;
      if (cutoffIndex && cutoffPlayers && index === lineIndex) return true;
    }
    if (sortedPlayers.length <= MAX_LEADERBOARD_COUNT && index === cutoffIndex)
      return true;
    return false;
  };

  const tierFilters = arenaGame
    ? spectate || gameOver
      ? TIERS_BOTTOM_UP
      : [
          'All',
          ...TIERS_BOTTOM_UP.filter(
            (t) =>
              t === arenaPlayersProjectionAndTiers?.[profileId]?.currentTier
          ),
        ]
    : [];

  const getChatMembersList = () => (
    <Stack>
      {chatMembers?.map((cm) => (
        <Typography>{cm}</Typography>
      ))}
    </Stack>
  );

  const handleChatBoxClick = () => {
    inputBaseRef.current.focus();
  };

  const showChat = !modalView;

  return (
    <Stack height={'100%'}>
      {sortedPlayers?.length > 2 && (
        <Stack maxHeight={'50%'} overflow={'auto'}>
          <Stack
            position="relative"
            justifyContent={'center'}
            alignItems={'center'}
            py={1}
          >
            <Typography fontWeight={600} textAlign={'center'}>
              PLAYERS ({sortedPlayers?.length})
            </Typography>
          </Stack>

          <>
            {/* {arenaGame && (
          <Stack direction={'row'} justifyContent={'center'} flexWrap={'wrap'}>
            {tierFilters.map((tier) => (
              <Box m={0.5} key={tier}>
                <Chip
                  avatar={
                    tier === 'All' ? null : (
                      <Avatar alt={tier} src={getTierBadge(tier)} />
                    )
                  }
                  label={tier === 'Grandmaster' ? 'GM' : tier}
                  variant={selectedTier === tier ? 'filled' : 'outlined'}
                  onClick={() => handleTierClick(tier)}
                  onMouseEnter={() => handleTierHover(tier)}
                  onMouseLeave={() => handleTierHover(null)}
                />
              </Box>
            ))}
          </Stack>
        )} */}

            <List dense={true} ref={animationParent}>
              {sortedPlayers?.map((p, i) => {
                const totalScore =
                  p?.currentScore + getFuzzyPenalty(p.fuzzyScore);
                return (
                  <Box key={p.profileId}>
                    <ListItem
                      disablePadding
                      sx={{
                        background:
                          hoveredTier &&
                          arenaPlayersProjectionAndTiers?.[p.profileId]
                            ?.currentTier === hoveredTier
                            ? LINEAR_GRADIENTS_OPAQUE[hoveredTier]
                            : 'none',
                        border:
                          p.profileId === profileId && !modalView
                            ? '1px solid'
                            : 'none',
                        borderColor:
                          p.profileId === profileId && !modalView
                            ? 'primary.dark'
                            : 'none',
                      }}
                    >
                      <ListItemButton
                        onClick={() =>
                          onUsernameClick && onUsernameClick(p.profileId)
                        }
                      >
                        {/* {p.arenaTeamId && (
                      <Box pr={1} display="flex" alignItems={'center'}>
                        <GiBasketballJersey
                          color={
                            p.arenaTeamId === arenaTeamId
                              ? teamPurple
                              : teamBlue
                          }
                          size="20px"
                        />
                      </Box>
                    )} */}

                        <ListItemText
                          primary={p.rank + '.'}
                          sx={{
                            marginRight: 1,
                          }}
                          primaryTypographyProps={{
                            style: {
                              fontWeight:
                                p.profileId === profileId ? 600 : 'none',
                            },
                          }}
                        />

                        <Avatar
                          src={getAvatarSrc(
                            p.avatarPublicId,
                            p.avatarUrl,
                            50,
                            50
                          )}
                          variant="rounded"
                          sx={{
                            height: 20,
                            width: 20,
                          }}
                        >
                          {!p.avatarUrl ? p.username.charAt(0) : null}
                        </Avatar>

                        <ListItemText
                          primary={p.username}
                          sx={{
                            marginLeft: 1,
                            marginRight: 2,
                            width: { sm: '150px' },
                          }}
                          primaryTypographyProps={{
                            style: {
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              fontWeight:
                                p.profileId === profileId ? 600 : 'none',
                              color: nameColor(p, questionIndex),
                            },
                          }}
                        />
                        {p.currentScore !== undefined &&
                        p.currentScore !== null ? (
                          <ListItemText
                            primary={`${
                              totalScore > 0 ? '+' : ''
                            }${totalScore}`}
                            sx={{ textAlign: 'right' }}
                            primaryTypographyProps={{
                              style: {
                                width: '40px',
                                textAlign: 'left',
                                color: nameColor(p, questionIndex),
                              },
                            }}
                          />
                        ) : null}
                        <ListItemText
                          primary={`${p.parScore > 0 ? '+' : ''}${p.parScore}`}
                          sx={{ textAlign: 'right' }}
                          primaryTypographyProps={{
                            style: {
                              fontWeight: 600,
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>

                    {showCutoffLine(i) ? (
                      <Divider
                        sx={{
                          fontSize: '12px',
                          color: theme.palette.success.main,
                          '&::before, &::after': {
                            borderColor: theme.palette.success.main,
                          },
                        }}
                      >
                        cutoff
                      </Divider>
                    ) : null}

                    {/* {selectedTier === 'All' &&
                arenaProjection &&
                i === arenaProjection - 1 ? (
                  <Divider
                    sx={{
                      fontSize: '12px',
                      color: theme.palette.dailies.main,
                      '&::before, &::after': {
                        borderColor: theme.palette.dailies.main,
                      },
                    }}
                  >
                    PROJECTION
                  </Divider>
                ) : null} */}
                  </Box>
                );
              })}
            </List>
          </>
        </Stack>
      )}

      {showChat && (
        <Stack height={'50%'} overflow={'auto'}>
          <Divider />

          <Stack
            direction={'row'}
            paddingY={1}
            paddingX={2}
            justifyContent={'space-between'}
          >
            <Typography fontWeight={600} textAlign={'center'}>
              CHAT
            </Typography>
            <Tooltip title={getChatMembersList()} placement="left">
              <Stack direction={'row'} spacing={1}>
                <Typography fontWeight={600}>{chatMembers?.length}</Typography>
                <PeopleIcon />
              </Stack>
            </Tooltip>
          </Stack>
          <SidebarChat
            chat={chatGame}
            handleChatBoxClick={handleChatBoxClick}
          />
          <Box
            sx={{
              height: 35,
              paddingY: 0.2,
              paddingX: 1.5,
              margin: '12px',
              border: chatInputFocused ? '2px solid' : '1px solid',
              borderColor: disableChat
                ? 'gray'
                : !chatInputFocused
                ? 'lightgray'
                : 'success.main',
              borderRadius: 1,
              '&:hover': {
                borderColor: disableChat
                  ? null
                  : !chatInputFocused
                  ? 'lightgray'
                  : null,
              },
            }}
          >
            <InputBase
              type="text"
              inputRef={inputBaseRef}
              onFocus={() => setChatInputFocused(true)}
              onBlur={() => setChatInputFocused(false)}
              disabled={disableChat}
              sx={{
                fontSize: 14,
                fontWeight: 600,
              }}
              fullWidth
              placeholder={
                disableChat
                  ? 'Chat disabled during question'
                  : 'Type something...'
              }
            />
          </Box>
        </Stack>
      )}
    </Stack>
  );
};
