import {
  Drawer,
  useTheme,
  Box,
  Stack,
  Typography,
  InputBase,
  Tooltip,
  IconButton,
  Button,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { SidebarChat } from './SidebarChat';
import PeopleIcon from '@mui/icons-material/People';
import { Socket } from 'socket.io-client';
import { SPECTATOR_CHAT_WIDTH } from './constants';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { SidebarLiveScores } from './SidebarLiveScores';
import { Spectator } from '../../../../types';
import { SpectatorIncomingChat } from '../../../../types/chat';
import { useBracketLiveScores } from '../../../../hooks/useBracketLiveScores';

type PropTypes = {
  chat?: SpectatorIncomingChat[];
  closeChat: () => void;
  gameId?: string;
  handleExitSpectate?: () => void;
  openChat: boolean;
  profileId: string;
  spectateSocket: Socket;
  spectatorList: Spectator[];
  username: string;
};

export const SpectateSidebar = ({
  chat,
  closeChat,
  gameId,
  handleExitSpectate,
  openChat,
  profileId,
  spectateSocket,
  spectatorList,
  username,
}: PropTypes) => {
  const theme = useTheme();
  const { bracketLiveScores, requestBracketLiveScores } =
    useBracketLiveScores();
  const [chatInputFocused, setChatInputFocused] = useState(false);
  const inputBaseRef = useRef<any>(null);

  useEffect(() => {
    requestBracketLiveScores();
  }, [requestBracketLiveScores]);

  useEffect(() => {
    if (!spectateSocket || !gameId) return;
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (!inputBaseRef.current?.value) return;
        spectateSocket.emit('spectatorChat', {
          gameId,
          text: inputBaseRef.current.value,
          username,
        });
        inputBaseRef.current.value = '';
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [spectateSocket, gameId]);

  const getChatMembersList = () => (
    <Stack>
      {spectatorList?.map((cm) => (
        <Typography>{cm.username}</Typography>
      ))}
    </Stack>
  );

  const handleChatBoxClick = () => {
    inputBaseRef.current.focus();
  };

  return (
    <Drawer
      sx={{
        width: SPECTATOR_CHAT_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: SPECTATOR_CHAT_WIDTH,
        },
      }}
      variant="persistent"
      anchor="right"
      open={openChat}
    >
      <>
        <Stack
          direction={'row'}
          minHeight={40}
          sx={{ position: 'relative', justifyContent: 'space-between' }}
        >
          <IconButton onClick={closeChat}>
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          <Button endIcon={<ExitToAppIcon />} onClick={handleExitSpectate}>
            Leave Spectate
          </Button>
        </Stack>

        <Box p={1}>
          <Typography
            fontWeight={600}
            letterSpacing={2}
            color={theme.palette.secondary.main}
          >
            OTHER LIVE GAMES
          </Typography>
        </Box>

        <Box p={1} height={'70%'} overflow={'auto'}>
          <SidebarLiveScores
            bracketLiveScores={bracketLiveScores}
            currentGameId={gameId}
          />
        </Box>

        <Stack
          direction={'row'}
          paddingY={1}
          paddingX={2}
          justifyContent={'space-between'}
        >
          <Typography fontWeight={600} letterSpacing={2}>
            SPECTATOR CHAT
          </Typography>
          <Tooltip title={getChatMembersList()} placement="left">
            <Stack direction={'row'} spacing={1}>
              <Typography fontWeight={600}>{spectatorList?.length}</Typography>
              <PeopleIcon />
            </Stack>
          </Tooltip>
        </Stack>
        <SidebarChat chat={chat} handleChatBoxClick={handleChatBoxClick} />
        <Box
          sx={{
            height: 35,
            paddingY: 0.2,
            paddingX: 1.5,
            margin: '12px',
            border: chatInputFocused ? '2px solid' : '1px solid',
            borderRadius: 1,
            '&:hover': {
              borderColor: !chatInputFocused ? 'lightgray' : null,
            },
          }}
        >
          <InputBase
            type="text"
            inputRef={inputBaseRef}
            onFocus={() => setChatInputFocused(true)}
            onBlur={() => setChatInputFocused(false)}
            sx={{
              fontSize: 14,
              fontWeight: 600,
            }}
            fullWidth
            placeholder="Type something..."
          />
        </Box>
      </>
    </Drawer>
  );
};
