import React, {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSocketContext } from './SocketProvider';
import { CreateGameConfig, LobbyGame, Teams } from '../types/game';
import { useProfileContext } from './ProfileProvider';
import { ResponseCurrentSeason } from '../types/league';
import { Maintenance } from '../types';
import { isMobile } from 'react-device-detect';
import { updatePlayMenuSettings } from '../api/apiServices';
import useQuickPlayManager from '../pages/quick-play/hooks/useQuickPlayManager';
import { gameInProgress } from '../utils/loggedInUser';
import { LoggedInUser } from '../types/user';
import useFetchLobbyGames from '../pages/lobby/hooks/useFetchLobbyGames';
import {
  uniqueNamesGenerator,
  adjectives,
  animals,
} from 'unique-names-generator';

const nameGenConfig = {
  dictionaries: [adjectives, animals],
  separator: ' ',
  length: 2,
};

interface LobbyGamesContextType {
  anyGamesInProgress: boolean;
  arenaTeamGameOpenForJoining: LobbyGame;
  arenaTeamGames: LobbyGame[];
  countdownTimer: number;
  createGameConfig: CreateGameConfig;
  setCreateGameConfig: (config: CreateGameConfig) => void;
  currentSeason: number;
  currentWeek: number;
  currentLeagueTournamentNumber: number;
  currentTournamentParticipantLimit: number;
  handlePlayClick: (loggedInUser?: LoggedInUser) => void;
  handlePlayClick2: (
    createGameConfig: CreateGameConfig,
    loggedInUser?: LoggedInUser
  ) => void;
  joinableLobbyGame: LobbyGame;
  lobbyGames: LobbyGame[] | null;
  openGames: LobbyGame[] | null;
  openPlayMenu: boolean;
  setOpenPlayMenu: (open: boolean) => void;
  openPlayMenu2: boolean;
  setOpenPlayMenu2: (open: boolean) => void;
  selectedSeason: string;
  setSelectedSeason: (season: string) => void;
  setCoundownTimer: (startTimer: number) => void;
  maintenance: Maintenance;
  setMaintenance: (maintenance: Maintenance) => void;
  arenaGame: LobbyGame;
  setArenaGame: (game: LobbyGame) => void;
}

export const LobbyGamesContext = createContext<LobbyGamesContextType>({
  anyGamesInProgress: false,
  arenaTeamGameOpenForJoining: null,
  arenaTeamGames: [],
  countdownTimer: null,
  createGameConfig: null,
  setCreateGameConfig: () => {},
  currentSeason: null,
  currentWeek: null,
  currentLeagueTournamentNumber: null,
  currentTournamentParticipantLimit: null,
  handlePlayClick: () => {},
  handlePlayClick2: () => {},
  joinableLobbyGame: null,
  lobbyGames: null,
  openGames: null,
  openPlayMenu: false,
  setOpenPlayMenu: () => {},
  openPlayMenu2: false,
  setOpenPlayMenu2: () => {},
  selectedSeason: null,
  setSelectedSeason: () => {},
  setCoundownTimer: () => {},
  maintenance: {
    scheduled: false,
    bannerMessage: '',
  },
  setMaintenance: () => {},
  arenaGame: null,
  setArenaGame: () => {},
});

type LobbyGamesProviderProps = {
  children: ReactNode;
};

export const useLobbyGamesContext = () => useContext(LobbyGamesContext);

export const LobbyGamesProvider: FC<LobbyGamesProviderProps> = ({
  children,
}) => {
  const { profile } = useProfileContext();
  const { socket } = useSocketContext();

  const onCreateGameSuccess = () => setOpenPlayMenu(false);

  const { createGame, handleRejoinGame } =
    useQuickPlayManager(onCreateGameSuccess);

  const { lobbyGames: lobbyGamesCompleted } = useFetchLobbyGames(
    !!profile,
    100
  );

  const [openPlayMenu, setOpenPlayMenu] = useState(false);
  const [openPlayMenu2, setOpenPlayMenu2] = useState(false);
  const [createGameConfig, setCreateGameConfig] = useState<CreateGameConfig>();
  // const [lobbyGames, setLobbyGames] = useState<LobbyGame[]>([]);
  const [arenaGame, setArenaGame] = useState<LobbyGame>(null);
  const [arenaTeamGames, setArenaTeamGames] = useState<LobbyGame[]>([]);
  const [arenaTeamGameOpenForJoining, setArenaTeamGameOpenForJoining] =
    useState<LobbyGame>(null);
  const [currentLobbyGames, setCurrentLobbyGames] = useState<LobbyGame[]>([]);
  // const [joinableLobbyGame, setJoinableLobbyGame] = useState<LobbyGame>(null);
  const [openGames, setOpenGames] = useState<LobbyGame[]>([]);
  const [currentSeason, setCurrentSeason] = useState<number>();
  const [currentWeek, setCurrentWeek] = useState<number>();
  const [currentLeagueTournamentNumber, setCurrentTournamentNumber] =
    useState<number>();
  const [
    currentTournamentParticipantLimit,
    setCurrentTournamentParticipantLimit,
  ] = useState<number>();
  const [selectedSeason, setSelectedSeason] = useState<string>('');
  const [countdownTimer, setCoundownTimer] = useState<number>();
  const [anyGamesInProgress, setAnyGamesInProgress] = useState(false);
  const [maintenance, setMaintenance] = useState<Maintenance>({
    scheduled: false,
    bannerMessage: '',
  });

  const replaceGame = (currentLobbyGames: LobbyGame[], gameId: string) =>
    currentLobbyGames
      ? currentLobbyGames.filter((g) => g.gameId !== gameId)
      : [];

  const deleteGame = (currentLobbyGames: LobbyGame[], gameId: string) =>
    currentLobbyGames?.filter((g) => g.gameId !== gameId);

  const leagueLobbyGameUpdate = useCallback(
    (update: {
      gameId: string;
      game: LobbyGame;
      action: 'create' | 'update' | 'delete';
    }) => {
      const { gameId, game, action } = update;
      if (action === 'create' || action === 'update') {
        if (game.arenaSet) {
          setArenaGame(game);
          return;
        }

        setCurrentLobbyGames((prev) =>
          replaceGame(prev, update.gameId)?.concat(game)
        );
        return;
      }
      if (action === 'delete') {
        setCurrentLobbyGames((prev) => deleteGame(prev, gameId));
        return;
      }
    },
    []
  );

  const arenaPlayerUpdate = (data: {
    gameId: string;
    player: any;
    action: string;
  }) => {
    if (data.action === 'addPlayer') {
      setArenaGame((prev) => addPlayerToArenaGame(data.player, prev));
      return;
    }
    if (data.action === 'removePlayer') {
      setArenaGame((prev) => removePlayerFromArenaGame(data.player, prev));
      return;
    }
  };

  const arenaTeamGamePlayerUpdate = (data: {
    gameId: string;
    player: any;
    action: string;
  }) => {
    if (data.action === 'addPlayer') {
      setArenaTeamGames((prev) => {
        const arenaTeamGames = prev;
        const foundGame = arenaTeamGames.find((g) => g.gameId === data.gameId);
        const updatedGame = addPlayerToArenaGame(data.player, foundGame);
        return arenaTeamGames
          .filter((g) => g.gameId !== data.gameId)
          .concat(updatedGame);
      });
      return;
    }
    if (data.action === 'removePlayer') {
      setArenaTeamGames((prev) => {
        const arenaTeamGames = prev;
        const foundGame = arenaTeamGames.find((g) => g.gameId === data.gameId);
        const updatedGame = removePlayerFromArenaGame(data.player, foundGame);
        return arenaTeamGames
          .filter((g) => g.gameId !== data.gameId)
          .concat(updatedGame);
      });
      return;
    }
  };

  const addPlayerToArenaGame = (player: any, arenaGame?: LobbyGame) => {
    if (!arenaGame) return null;

    // Make a shallow copy of arenaGame to avoid direct state mutation
    const updatedArenaGame = { ...arenaGame };

    // Make a shallow copy of activePlayers and players to avoid direct state mutation
    updatedArenaGame.activePlayers = [...updatedArenaGame.activePlayers];
    updatedArenaGame.players = { ...updatedArenaGame.players };

    // Add player to activePlayers and players
    updatedArenaGame.activePlayers.push(player.profileId);
    updatedArenaGame.players[player.profileId] = player;

    return updatedArenaGame;
  };

  const removePlayerFromArenaGame = (player: any, arenaGame?: LobbyGame) => {
    if (!arenaGame) return null;

    // Make a shallow copy of arenaGame to avoid direct state mutation
    const updatedArenaGame = { ...arenaGame };

    // Make a shallow copy of activePlayers and players to avoid direct state mutation
    updatedArenaGame.activePlayers = [...updatedArenaGame.activePlayers];
    updatedArenaGame.players = { ...updatedArenaGame.players };

    // Remove player from activePlayers and players
    updatedArenaGame.activePlayers = updatedArenaGame.activePlayers.filter(
      (p) => p !== player.profileId
    );
    delete updatedArenaGame.players[player.profileId];

    return updatedArenaGame;
  };

  const leagueLobbyPlayerUpdate = (data: {
    gameId: string;
    player: any;
    teams: Teams;
    action: 'addPlayer';
  }) => {
    if (data.action === 'addPlayer') {
      setCurrentLobbyGames((prev) => addPlayerToGame(data.player, prev));
      const addPlayerToGame = (player: any, lobbyGames?: LobbyGame[]) => {
        const found = lobbyGames?.find((g) => g.gameId === data.gameId);
        if (!found) return lobbyGames;
        found.activePlayers.push(player.profileId);
        found.teams = data.teams;
        found.players[player.profileId] = player;
        const updatedLobbyGames = lobbyGames
          ?.filter((g) => g.gameId !== data.gameId)
          .concat(found);
        return updatedLobbyGames;
      };
      return;
    }
  };

  const leagueLobbyGamePropertyUpdate = (update: {
    gameId: string;
    property: keyof LobbyGame;
    data: any;
  }) => {
    setCurrentLobbyGames((prev) => updateGameProperty(update, prev));
    const updateGameProperty = (
      update: {
        gameId: string;
        property: keyof LobbyGame;
        data: any;
      },
      lobbyGames?: LobbyGame[]
    ) => {
      const found: any = lobbyGames?.find((g) => g.gameId === update.gameId);
      if (!found) return lobbyGames;
      found[update.property] = update.data;
      const updatedLobbyGames = lobbyGames
        ?.filter((g) => g.gameId !== update.gameId)
        .concat(found);
      return updatedLobbyGames;
    };
  };

  const arenaTeamGamePropertyUpdate = (update: {
    gameId: string;
    property: keyof LobbyGame;
    data: any;
  }) => {
    setArenaTeamGames((prev) => updateGameProperty(update, prev));
    const updateGameProperty = (
      update: {
        gameId: string;
        property: keyof LobbyGame;
        data: any;
      },
      arenaTeamGames?: LobbyGame[]
    ) => {
      const found: any = arenaTeamGames?.find(
        (g) => g.gameId === update.gameId
      );
      if (!found) return arenaTeamGames;
      found[update.property] = update.data;
      const updatedArenaTeamGame = arenaTeamGames
        ?.filter((g) => g.gameId !== update.gameId)
        .concat(found);
      return updatedArenaTeamGame;
    };
  };

  // useEffect(() => {
  //   if (!lobbyGamesCompleted && !currentLobbyGames) return;
  //   console.log('currentLobbyGames', currentLobbyGames);
  //   const openGames = currentLobbyGames.filter(
  //     (g) =>
  //       !g.gameStarted &&
  //       !g.gameOver &&
  //       (g.mode === 'sit-n-go' || g.mode === 'match')
  //   );
  //   setOpenGames(openGames);

  //   const joinableLobbyGame = getJoinableLobbyGame(
  //     currentLobbyGames,
  //     profile?._id
  //   );
  //   setJoinableLobbyGame(joinableLobbyGame);

  //   // currentLobbyGames should only display when viewing the current season
  //   if (+selectedSeason !== currentSeason) {
  //     setLobbyGames(lobbyGamesCompleted as any);
  //     return;
  //   }

  //   const combinedGames = lobbyGamesCompleted
  //     ? (lobbyGamesCompleted as any).concat(currentLobbyGames)
  //     : currentLobbyGames;
  //   setLobbyGames(combinedGames?.sort((a, b) => b.createdAt - a.createdAt));
  //   console.log('combinedGames', combinedGames);
  // }, [lobbyGamesCompleted, currentLobbyGames]);

  // useEffect(() => {
  //   if (!lobbyGames) return;
  //   const gamesInProgress = lobbyGames.some(
  //     (g) => g.gameStarted && !g.gameOver
  //   );
  //   setAnyGamesInProgress(gamesInProgress);
  // }, [lobbyGames]);

  const getJoinableLobbyGame = (
    currentLobbyGames: LobbyGame[],
    profileId: string
  ) => {
    if (!currentLobbyGames) return null;
    const foundJoinableGame = currentLobbyGames.find(
      (g) =>
        canJoin(g, profileId) &&
        !g.gameOver &&
        (g.mode === 'sit-n-go' || g.mode === 'match')
    );
    if (foundJoinableGame) {
      if (
        foundJoinableGame.mode === 'match' &&
        foundJoinableGame.activePlayers.length < 2
      ) {
        return foundJoinableGame;
      }
      if (
        foundJoinableGame.mode === 'sit-n-go' &&
        foundJoinableGame.activePlayers.length < 10
      ) {
        return foundJoinableGame;
      }
      return null;
    }
    return null;
  };

  const canJoin = (game: LobbyGame, profileId: string) => {
    if (!game.whoCanJoin || game.whoCanJoin.length === 0) return true;
    if (game.whoCanJoin?.length > 0 && game.whoCanJoin?.includes(profileId))
      return true;
    return false;
  };

  const lobbyGames = useMemo(() => {
    return currentLobbyGames;
  }, [currentLobbyGames]);

  const joinableLobbyGame = useMemo(() => {
    return getJoinableLobbyGame(currentLobbyGames, profile?._id);
  }, [currentLobbyGames, profile?._id]);

  useEffect(() => {
    if (!socket) return;

    socket.on('lobby', setCurrentLobbyGames);
    socket.on('leagueLobbyGameUpdate', leagueLobbyGameUpdate);
    socket.on('leagueLobbyGamePropertyUpdate', leagueLobbyGamePropertyUpdate);
    socket.on('leagueLobbyPlayerUpdate', leagueLobbyPlayerUpdate);
    socket.on('startCountdownTimer', setCoundownTimer);
    socket.on('maintenance', setMaintenance);
    socket.on('arenaGame', setArenaGame);
    socket.on('arenaGamePropertyUpdate', arenaGamePropertyUpdate);
    socket.on('arenaPlayerUpdate', arenaPlayerUpdate);
    socket.on('arenaTeamGames', updateArenaTeamGames);
    socket.on('arenaTeamGamePropertyUpdate', arenaTeamGamePropertyUpdate);
    socket.on('arenaTeamGamePlayerUpdate', arenaTeamGamePlayerUpdate);

    // socket.emit('requestArenaGame', { profileId: profile._id });
    // socket.emit('requestArenaGames', { profileId: profile._id });

    return () => {
      socket.off('lobby', setCurrentLobbyGames);
      socket.off('leagueLobbyGameUpdate', leagueLobbyGameUpdate);
      socket.off(
        'leagueLobbyGamePropertyUpdate',
        leagueLobbyGamePropertyUpdate
      );
      socket.off('leagueLobbyPlayerUpdate', leagueLobbyPlayerUpdate);
      socket.off('startCountdownTimer', setCoundownTimer);
      socket.off('maintenance', setMaintenance);
      socket.off('arenaGame', setArenaGame);
      socket.off('arenaGamePropertyUpdate', arenaGamePropertyUpdate);
      socket.off('arenaPlayerUpdate', arenaPlayerUpdate);
      socket.off('arenaTeamGames', updateArenaTeamGames);
      socket.off('arenaTeamGamePropertyUpdate', arenaTeamGamePropertyUpdate);
      socket.off('arenaTeamGamePlayerUpdate', arenaTeamGamePlayerUpdate);
    };
  }, [socket, leagueLobbyGameUpdate]);

  const updateArenaTeamGames = (games: LobbyGame[]) => {
    setArenaTeamGames((prev) => prev.concat(games));
    const openForJoining = games.find(
      (g) =>
        !g.gameStarted &&
        !g.gameOver &&
        g.whoCanJoin?.includes(profile._id) &&
        !g.activePlayers?.includes(profile._id)
    );
    setArenaTeamGameOpenForJoining(openForJoining);
  };

  const arenaGamePropertyUpdate = (update: {
    gameId: string;
    property: keyof LobbyGame;
    data: any;
  }) => {
    setArenaGame((prev) => {
      const updated = { ...prev };
      updated[update.property as any] = update.data;
      return updated;
    });
  };

  const handlePlayClick = async (loggedInUser?: LoggedInUser) => {
    if (isMobile) {
      // setOpenMobileDisclaimer(true);
      return;
    }

    if (gameInProgress(loggedInUser)) {
      handleRejoinGame(profile._id, loggedInUser.currentGameId);
      return;
    }

    if (openPlayMenu) {
      const playMenuSettingsBody: any = {
        profileId: profile?._id,
        gameType: createGameConfig.gameType,
        categorySelections: createGameConfig.categorySelections,
        categorySet: createGameConfig.categorySet,
        mode: createGameConfig.mode,
        soloMultiAsync: createGameConfig.soloMultiAsync,
        allowSpectators: createGameConfig.allowSpectators,
      };

      updatePlayMenuSettings(playMenuSettingsBody);

      await createGame(profile, createGameConfig);
    } else {
      setOpenPlayMenu(true);
    }
  };

  const handlePlayClick2 = async (
    createGameConfig: CreateGameConfig,
    loggedInUser?: LoggedInUser
  ) => {
    if (isMobile) {
      // setOpenMobileDisclaimer(true);
      return;
    }

    const generatedcustomGameName = uniqueNamesGenerator({
      ...nameGenConfig,
      style: 'capital',
    });
    const updatedCreateGameConfig = {
      ...createGameConfig,
      customGameName: generatedcustomGameName,
    };
    await createGame(profile, updatedCreateGameConfig);
  };

  return (
    <LobbyGamesContext.Provider
      value={{
        anyGamesInProgress,
        arenaTeamGameOpenForJoining,
        arenaTeamGames,
        countdownTimer,
        createGameConfig,
        setCreateGameConfig,
        currentSeason,
        currentLeagueTournamentNumber,
        currentTournamentParticipantLimit,
        currentWeek,
        handlePlayClick,
        handlePlayClick2,
        joinableLobbyGame,
        lobbyGames,
        openGames,
        openPlayMenu,
        setOpenPlayMenu,
        openPlayMenu2,
        setOpenPlayMenu2,
        selectedSeason,
        setSelectedSeason,
        setCoundownTimer,
        maintenance,
        setMaintenance,
        arenaGame,
        setArenaGame,
      }}
    >
      {children}
    </LobbyGamesContext.Provider>
  );
};
