import { Box, Stack, Button, useTheme } from '@mui/material';
import React from 'react';

type PropTypes = {
  handleOpen: () => void;
};

export const SpectateSidebarOpenTab = ({ handleOpen }: PropTypes) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 9999,
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Stack
          direction={'row'}
          sx={{
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            border: `1px solid ${theme.palette.primary.dark}`,
            borderRight: 'none',
          }}
        >
          <Button
            onClick={handleOpen}
            sx={{
              minWidth: '45px',
              writingMode: 'vertical-lr',
              transform: 'rotate(180deg)',
              transformOrigin: 'center',
              letterSpacing: 2,
            }}
          >
            CHAT
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
