import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Avatar,
  Box,
  Stack,
  TextField,
  Typography,
  debounce,
} from '@mui/material';
import { getAvatarSrc } from '../../../api/cloudinary';
import { fetchGet } from '../../../api/common';
import { useNavigate } from 'react-router-dom';

type Props = {
  openSearchPlayers: boolean;
  setOpenSearchPlayers: (value: boolean) => void;
};

const SearchAutocomplete = ({
  openSearchPlayers,
  setOpenSearchPlayers,
}: Props) => {
  const navigate = useNavigate();

  const [options, setOptions] = React.useState<readonly any[]>([]);

  const searchUsers = async (searchTerm: string) => {
    if (!searchTerm.length) {
      setOptions([]);
      return;
    }
    const res = await fetchGet(`/users/search?term=${searchTerm}`);
    const sorted = res.sort((a, b) => a.username.localeCompare(b.username));
    setOptions(sorted);
  };

  const debouncedTyping = debounce((term: string) => {
    searchUsers(term);
  }, 300);

  const handlePlayerSelect = (user: any) => {
    if (!user) return;
    if (user.isTeam) {
      navigate(`/team/overview/${user._id}`);
      return;
    }
    navigate(`/profile/overview/${user._id}`);
  };

  return (
    <Autocomplete
      size="small"
      value={null}
      options={options}
      sx={{
        width: openSearchPlayers ? 400 : 35,
        transition: 'width ease-out 0.2s',
      }}
      disablePortal
      selectOnFocus
      clearOnBlur
      blurOnSelect
      onFocus={() => setOpenSearchPlayers(true)}
      onBlur={() => setOpenSearchPlayers(false)}
      onInputChange={(event, newInputValue) => {
        debouncedTyping(newInputValue);
      }}
      onChange={(event: any, newValue: any) => {
        handlePlayerSelect(newValue);
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.username}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search"
          sx={{
            '& .MuiInputBase-root': {
              cursor: 'pointer',
              flexWrap: 'nowrap !important',
              overflow: 'hidden !important',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: openSearchPlayers ? '' : 'none',
            },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchIcon />,
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <Avatar
            alt={option.username}
            src={getAvatarSrc(
              option?.avatarPublicId,
              option?.avatarUrl,
              100,
              100
            )}
            sx={{ marginRight: 1, width: 32, height: 32, borderRadius: 1 }}
          />
          <Stack direction={'column'}>
            {option.isTeam ? '[Team]' : ''} {option.username}
            <Typography variant="body2" color="gray">
              {option.fullName ? option.fullName : ''}
              {option.location ? ' from ' + option.location : ''}
            </Typography>
            {!option.fullName && !option.location ? (
              <Typography variant="body2" color="gray" fontStyle={'italic'}>
                No additional information
              </Typography>
            ) : null}
          </Stack>
        </Box>
      )}
    />
  );
};

export default SearchAutocomplete;
