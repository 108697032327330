export const GROUP_BACKGROUND_COLORS = [
  '#51516a9c',
  '#6a515ca8',
  '#516a5d9c',
  '#6a60519c',
];

export const RARITY_COLORS = {
  common: '#e7e7e7',
  uncommon: '#2196f3',
  rare: '#f3ed21',
  prestige: '#ff6000',
};
