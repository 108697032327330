import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  DialogActions,
  DialogTitle,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { ArenaPlayerGame } from '../../types';

import dayjs from 'dayjs';
import { useScorecardDialog } from '../../../../../contexts/ScorecardDialogProvider';
import { green } from '@mui/material/colors';
import { getOrdinalSuffix } from '../../../../../utils/utils';

type PropTypes = {
  arenaPlayerGames: ArenaPlayerGame[];
  onClose: () => void;
  open: boolean;
};

export const RecentGamesAllDialog = ({
  arenaPlayerGames,
  onClose,
  open,
}: PropTypes) => {
  const theme = useTheme();

  const { viewScorecardDialog } = useScorecardDialog();

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth>
      <DialogTitle>All Games</DialogTitle>
      <DialogContent>
        <Stack>
          <TableContainer component={Paper} sx={{ bgcolor: '#060615' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" fontWeight={500}>
                      Result
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" fontWeight={500}>
                      Score
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" fontWeight={500}>
                      Finish
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" fontWeight={500}>
                      Night
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" fontWeight={500}>
                      Round
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" fontWeight={500}>
                      Date / Time
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {arenaPlayerGames?.length ? (
                  arenaPlayerGames.map((g, index) => (
                    <TableRow hover key={index}>
                      <TableCell style={{ paddingRight: 0 }}>
                        <Typography
                          variant="h6"
                          textAlign={'center'}
                          fontWeight={600}
                          color={
                            g.teamResult === 'win'
                              ? green[400]
                              : g.teamResult === 'loss'
                              ? 'error'
                              : 'gray'
                          }
                        >
                          {g.teamResult === 'win'
                            ? 'W'
                            : g.teamResult === 'loss'
                            ? 'L'
                            : 'T'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{g.parScore}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {getOrdinalSuffix(g.placement)} / {g.totalPlayers}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{g.arenaNight}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{g.arenaRound}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {dayjs(g.createdAt).format('M/DD/YYYY hh:mm A')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Link
                          component={'button'}
                          variant="body2"
                          onClick={() => viewScorecardDialog(g.gameId)}
                        >
                          Scorecard
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      No games
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
