import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SocialMediaIconButton } from '../../custom-components/custom-icon-buttons';
import { FaDiscord, FaXTwitter } from 'react-icons/fa6';
import { NewPlayersDialog } from './NewPlayersDialog';

const Announcement = () => {
  const [openNewPlayersDialog, setOpenNewPlayersDialog] = useState(false);

  return (
    <>
      <Stack spacing={4}>
        <Typography variant="h5" fontWeight={600}>
          Welcome to PAR
        </Typography>

        <Stack spacing={2}>
          <Typography fontWeight={500}>
            If this is your first time here,
            <Button
              onClick={() => setOpenNewPlayersDialog(true)}
              color="primary"
            >
              click here
            </Button>
          </Typography>

          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Typography fontWeight={500}>
              Join us on Discord and Twitter!
            </Typography>

            <SocialMediaIconButton
              brandColor="#5865F2"
              link="https://discord.gg/upX48KPfHd"
              icon={<FaDiscord />}
            />
            <SocialMediaIconButton
              brandColor="#000000"
              link="https://twitter.com/ParTrivia"
              icon={<FaXTwitter />}
            />
          </Stack>
        </Stack>
      </Stack>

      <NewPlayersDialog
        open={openNewPlayersDialog}
        onClose={() => setOpenNewPlayersDialog(false)}
      />
    </>
  );
};

export default Announcement;
