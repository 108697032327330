import React from 'react';
import { Typography } from '@mui/material';
import { AuthType } from './types';

const AUTH_TYPE_TITLE_TEXT = {
  acceptInvite: 'Accept invitation',
  landing: 'Log in or create an account',
  login: 'Log in to your account',
  signUp: 'Create your account',
  forgotPassword: 'Reset your password',
  resetPassword: 'Enter a new password',
  resendVerification: 'Resend Verification Email',
};

type PropTypes = {
  authType: AuthType;
};
export const TitleAuth = ({ authType }: PropTypes) => {
  return (
    <Typography
      variant="h6"
      textAlign={'center'}
      // sx={{
      //   textShadow: `#ffffff 0px 0px 0px, #ffffff 0px 0px 0px, #ffffff 0px 0px 16px`,
      // }}
    >
      {AUTH_TYPE_TITLE_TEXT[authType]}
    </Typography>
  );
};
