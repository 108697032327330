import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  useTheme,
  Stack,
  Tooltip,
  Avatar,
  Badge,
  CircularProgress,
} from '@mui/material';
import React from 'react';
import { CgAsterisk } from 'react-icons/cg';
import { Question } from '../../../../../types/game';
import {
  formattedScore,
  PlayerTableCell,
} from '../../../../../components/scorecard';
import { PrecisionGraphicScorecardIcon } from '../../../../../components/scorecard/PrecisionGraphicScorecardIcons';
import { isTimedOut } from '../../../../../components/scorecard/utils';
import { AnswerAttempt, DetailedPlayer } from '../../../../../types/player';
import { ArenaCategoryStat } from '../../types';
import { QuestionStatsLean } from '../../../../../types/question-stats';
import { getBarChartForScorecard } from '../../../../../components/scorecard/QuestionStatsBarChart';

type Props = {
  category: string;
  questions: Question[];
  questionStats: QuestionStatsLean[];
  stats: ArenaCategoryStat[];
  username: string;
};

const CategoryScorecard = ({
  category,
  questions,
  questionStats,
  stats,
  username,
}: Props) => {
  const theme = useTheme();

  const underParScoreBallStyle = {
    bgcolor: `${theme.palette.success.main}40`,
    color: 'text.primary',
    textAlign: 'center',
    boxShadow: `0 0 5px 1px ${theme.palette.success.main}, 0 0 2px 3px ${theme.palette.success.main} inset`,
  };

  const timedoutScoreBallStyle = {
    bgcolor: `${theme.palette.error.main}40`,
    color: 'error.contrastText',
    display: 'inline-flex',
    boxShadow: `0 0 5px 1px ${theme.palette.error.main}, 0 0 2px 3px ${theme.palette.error.main} inset`,
  };

  const alternateAnswersExists = (alternateAnswers: string[]) =>
    alternateAnswers.filter((a) => !!a).length;

  const alternateAnswersTooltip = (alternateAnswers: string[]) => {
    const filtered = alternateAnswers.filter((a) => !!a);
    return (
      <Stack spacing={2}>
        <Typography textAlign="center">Alternate Answers</Typography>
        {!filtered.length ? (
          <Typography variant="body1" textAlign="center">
            none
          </Typography>
        ) : (
          filtered.map((a) => (
            <Typography
              key={a}
              variant="body1"
              textAlign="center"
              sx={{
                color: theme.palette.secondary.main,
              }}
            >
              {a}
            </Typography>
          ))
        )}
      </Stack>
    );
  };

  const answerAttemptTooltip = (answerAttempts: AnswerAttempt[]) => {
    return (
      <Stack spacing={2} sx={{ padding: 1 }}>
        <Typography textAlign="center">Answer Attempts</Typography>
        {!answerAttempts ? (
          <Typography variant="body1">None</Typography>
        ) : (
          answerAttempts.map((answer, i) => (
            <Stack key={i} direction="row" spacing={3} minWidth={'250px'}>
              <Typography
                variant="body1"
                textAlign={'right'}
                sx={{
                  width: '40%',
                  color:
                    theme.palette.mode === 'light'
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  textShadow:
                    theme.palette.mode === 'dark'
                      ? `0 0 1px ${theme.palette.primary.main}, 10px 0px 20px ${theme.palette.primary.main}, 0px 0px 16px ${theme.palette.primary.main}`
                      : 'none',
                }}
              >
                {answer.gameClock.toFixed(2)} sec
              </Typography>
              <Typography
                variant="body1"
                textAlign={'left'}
                sx={{ width: '60%' }}
              >
                "{answer.input}"
              </Typography>
            </Stack>
          ))
        )}
      </Stack>
    );
  };

  const findPlayerScore = (questionId: string) => {
    const found = stats.find((stat) => stat.questionId === questionId);
    return found ? found : null;
  };

  const formatPlayerScore = (
    par: number,
    precisionIconLocation: 'corner' | 'bottom',
    questionId: string
  ) => {
    const player = findPlayerScore(questionId);
    if (!player) return null;

    const answerAttempts = player.answerAttempts;
    const score = player.score;
    const penalty = player.penalty;
    const fuzzyPenalty = player.fuzzyPenalty;

    let scoreStyle: any = {
      bgcolor: theme.palette.mode === 'dark' ? '#434343' : 'undefined',
      color: 'error.contrastText',
    };
    let avatarShape: any = 'rounded';
    scoreStyle = underParScoreBallStyle;
    avatarShape = 'round';
    if (isTimedOut(par, score, undefined, 1)) {
      scoreStyle = timedoutScoreBallStyle;
      avatarShape = 'square';
    }

    return (
      <Stack
        spacing={precisionIconLocation === 'bottom' ? 2 : 0}
        sx={{
          position: precisionIconLocation === 'bottom' ? 'relative' : 'none',
          alignItems: 'center',
        }}
      >
        <Badge badgeContent={penalty} color="warning">
          <Tooltip placement="top" title={answerAttemptTooltip(answerAttempts)}>
            <Avatar sx={scoreStyle} variant={avatarShape}>
              {formattedScore(score)}
            </Avatar>
          </Tooltip>
        </Badge>
        {fuzzyPenalty ? (
          <PrecisionGraphicScorecardIcon
            fuzzyPenalty={fuzzyPenalty}
            position={
              precisionIconLocation === 'bottom' ? 'relative' : 'absolute'
            }
          />
        ) : null}
      </Stack>
    );
  };

  const getQuestionStats = (questionId: string) => {
    return questionStats?.find((q) => q.questionId === questionId);
  };

  return (
    <Box sx={{ padding: 1 }}>
      <Paper elevation={3} sx={{ borderRadius: 2 }}>
        <TableContainer component={Paper} sx={{ bgcolor: 'black' }}>
          <Table size="small" sx={{ minWidth: 1000 }}>
            <TableHead>
              <TableRow>
                <TableCell width="2%"></TableCell>
                <TableCell>
                  <Typography variant={'body1'} fontWeight={600}>
                    Question
                  </Typography>
                </TableCell>
                <TableCell width="20%">
                  <Typography
                    variant={'body1'}
                    fontWeight={600}
                    sx={{ color: theme.palette.answers.main }}
                  >
                    Answer
                  </Typography>
                </TableCell>
                <TableCell width="10%">
                  <Typography variant={'body1'} fontWeight={600}>
                    Category
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant={'body1'}
                    textAlign={'left'}
                    fontWeight={600}
                  >
                    PAR
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant={'subtitle2'}
                    fontWeight={600}
                    textAlign={'center'}
                    width={'60px'}
                    sx={{
                      color: theme.palette.primary.main,
                      wordBreak: 'break-all',
                    }}
                  >
                    {username}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questions.map((q, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box sx={{ height: 90, width: 180 }}>
                      {getBarChartForScorecard(getQuestionStats(q._id))}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography>{q.question}</Typography>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        alternateAnswersExists(q.alternateAnswers)
                          ? alternateAnswersTooltip(q.alternateAnswers)
                          : null
                      }
                      placement="top-start"
                    >
                      <Typography sx={{ color: theme.palette.answers.main }}>
                        {q.answer}
                        {alternateAnswersExists(q.alternateAnswers) ? (
                          <CgAsterisk
                            style={{
                              color: theme.palette.answers.main,
                            }}
                          />
                        ) : null}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Typography>{category}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{q.par}</Typography>
                  </TableCell>
                  <PlayerTableCell sx={{ position: 'relative' }}>
                    {formatPlayerScore(q.par, 'corner', q._id)}
                  </PlayerTableCell>
                </TableRow>
              ))}
              {/* <TableRow>{tableCellsTotals}</TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default CategoryScorecard;
