import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useFetchPlacesPredictions from './useFetchPlacesPredictions';
import { debounce } from 'lodash';
import { GooglePlacesPrediction } from '../../../api/response-types/responseTypes';

type Props = {
  handlePredictionSelect: (prediction: GooglePlacesPrediction) => void;
  value?: string;
};

const GooglePlaces = ({ handlePredictionSelect, value }: Props) => {
  const [inputText, setInputText] = useState('');
  const [open, setOpen] = useState(false);

  const { predictions, isLoading } = useFetchPlacesPredictions(inputText);

  const options: GooglePlacesPrediction[] = predictions || [];

  useEffect(() => {
    setOpen(inputText.length > 0 && !isLoading);
  }, [inputText, isLoading]);

  const loading = isLoading;

  const debounceInput = debounce((text: string) => {
    setInputText(text);
  }, 500);

  return (
    <Autocomplete
      defaultValue={{ description: value }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={(x) => x}
      onChange={(event: any, newValue: GooglePlacesPrediction) => {
        if (!newValue) return;
        debounceInput.cancel();
        handlePredictionSelect(newValue);
        setOpen(false);
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason !== 'input') return;
        debounceInput(newInputValue);
      }}
      isOptionEqualToValue={(option, value) =>
        option.description === value.description
      }
      getOptionLabel={(option) => option.description}
      noOptionsText="No locations"
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Location (Optional)"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default GooglePlaces;
