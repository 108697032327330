import { Stack, Typography, Box, Tooltip } from '@mui/material';
import React from 'react';
import { Socket } from 'socket.io-client';
import CircleIcon from '@mui/icons-material/Circle';
import { FrameRate } from './FrameRate';
import { Latency } from './Latency';
import { Mode } from '../../../types/game';
import { SIT_AND_GO_DRAWER_WIDTH } from './constants';

type PropTypes = {
  disconnectCounter: number;
  inputSocket: Socket;
  hasLeaderboard: boolean;
  profileId: string;
  socket: Socket;
};

export const ConnectionStats = ({
  disconnectCounter,
  inputSocket,
  hasLeaderboard,
  profileId,
  socket,
}: PropTypes) => {
  return (
    <Stack
      sx={{
        position: 'absolute',
        top: '10px',
        right: hasLeaderboard ? `${SIT_AND_GO_DRAWER_WIDTH + 10}px` : '10px',
      }}
    >
      <Stack direction={'row'} spacing={2}>
        {!socket?.connected || !inputSocket?.connected ? (
          <Typography variant={'caption'} color="warning.main">
            Reconnecting...
          </Typography>
        ) : (
          <Typography variant={'caption'} color="success.main">
            Connected:
          </Typography>
        )}
        <Tooltip
          title={`Connection status: ${
            inputSocket?.connected && socket?.connected
              ? 'connected'
              : 'disconnected'
          }`}
          placement="bottom-start"
        >
          <Box>
            <CircleIcon
              sx={{
                fontSize: '16px',
                color:
                  inputSocket?.connected && socket?.connected ? 'green' : 'red',
              }}
            />
          </Box>
        </Tooltip>
      </Stack>
      <FrameRate />
      <Latency socket={socket} profileId={profileId} />
      <Typography variant={'caption'} textAlign={'right'}>
        Disconnects: {disconnectCounter}
      </Typography>
    </Stack>
  );
};
