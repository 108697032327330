import { useMutation } from '@tanstack/react-query';
import { generateLeagueSchedule } from '../api/apiServices';

const useGenerateLeagueSchedule = () => {
  const { mutate } = useMutation({
    mutationFn: generateLeagueSchedule,
  });

  return {
    generateLeagueSchedule: mutate,
  };
};

export default useGenerateLeagueSchedule;
