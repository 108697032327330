import React, { useRef, useState } from 'react';
import {
  Box,
  Card,
  CardMedia,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import EditIcon from '@mui/icons-material/Edit';
import { useUpdateProfileAvatar } from '../../../hooks/useUpdateProfileAvatar';
import { utcToLocalDateMedium } from '../../../utils/date-time';
import { getAvatarSrc } from '../../../api/cloudinary';
import { useNavigate } from 'react-router-dom';
import { Profile } from '../../../types/player';

const JoinedLabel = ({
  createdAt,
  textAlign,
}: {
  createdAt: number;
  textAlign: 'left' | 'right';
}) => {
  return (
    <Typography variant="caption" textAlign={textAlign} color={'#b9b9b9'}>
      Joined: {utcToLocalDateMedium(createdAt)}
    </Typography>
  );
};
type PropTypes = {
  allowEdits: boolean;
  handleEditUsernameClick: () => void;
  viewProfile: Profile;
};

export const ProfileHeader = ({
  allowEdits,
  handleEditUsernameClick,
  viewProfile,
}: PropTypes) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const smDownScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { updateAvatarImage } = useUpdateProfileAvatar();
  const [dimAvatar, setDimAvatar] = useState(false);
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const imageUpload = async (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    updateAvatarImage(file);
  };

  const handleTeamnameClick = (teamId: string) => {
    navigate(`/team/overview/${teamId}`);
  };

  return (
    <Box
      pb={4}
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 10,
      }}
    >
      <Card
        sx={{
          padding: 0.5,
          bgcolor: '#082037f0',
        }}
      >
        <Stack direction="row" alignItems={'center'}>
          <Box width={'100px'} sx={{ position: 'relative' }}>
            <CardMedia
              component="img"
              image={getAvatarSrc(
                viewProfile?.avatarPublicId,
                viewProfile?.avatarUrl,
                100,
                100
              )}
              alt="viewProfile picture"
              sx={{
                height: 80,
                width: 80,
                opacity: dimAvatar ? 0.5 : 1,
                border: '1px solid',
                borderColor: theme.palette.primary.dark,
                borderRadius: 1,
              }}
            />
            {allowEdits ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  height: 80,
                  width: 80,
                  opacity: 0,
                  '&:hover': { opacity: 1 },
                }}
              >
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  sx={{ height: '100%', width: '100%' }}
                  onMouseEnter={() => setDimAvatar(true)}
                  onMouseLeave={() => setDimAvatar(false)}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    ref={fileUploadRef}
                    onChange={(e) => imageUpload(e)}
                  />
                  <PhotoCamera />
                </IconButton>
              </Box>
            ) : null}
          </Box>

          <Stack
            direction={'row'}
            spacing={2}
            p={1}
            px={2}
            width={'100%'}
            justifyContent={'space-between'}
          >
            <Stack flexDirection={'column'} justifyContent={'space-between'}>
              {/* Username + Edit Icon */}
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography
                  variant={viewProfile?.username.length > 10 ? 'h6' : 'h5'}
                  textAlign={'left'}
                  fontWeight={600}
                  letterSpacing={2}
                >
                  {viewProfile?.username?.toUpperCase()}
                </Typography>
                {allowEdits ? (
                  <IconButton onClick={handleEditUsernameClick}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                ) : null}
              </Stack>

              {/* Fullname + Gender */}
              <Stack direction={'row'} spacing={1}>
                {viewProfile?.fullName ? (
                  <Tooltip title={viewProfile.fullName} placement="top">
                    <Typography
                      variant="subtitle2"
                      textAlign={'left'}
                      pr={1}
                      maxWidth={140}
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {viewProfile.fullName}
                    </Typography>
                  </Tooltip>
                ) : null}
                {viewProfile?.genderPronoun ? (
                  <Typography variant="subtitle2" textAlign={'left'} pr={1}>
                    ({viewProfile.genderPronoun})
                  </Typography>
                ) : null}
              </Stack>

              {/* Location + Joined on small screens */}
              {smDownScreen ? (
                <Stack direction={'column'}>
                  {viewProfile?.location ? (
                    <Tooltip title={viewProfile.location} placement="top">
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        color={'primary'}
                        maxWidth={220}
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {viewProfile.location}
                      </Typography>
                    </Tooltip>
                  ) : null}
                  <JoinedLabel
                    createdAt={viewProfile?.createdAt}
                    textAlign={'left'}
                  />
                </Stack>
              ) : null}
            </Stack>

            {/* Location + Joined */}
            {!smDownScreen ? (
              <Stack
                spacing={1}
                flexDirection={'column'}
                justifyContent={'end'}
              >
                {viewProfile?.location ? (
                  <Tooltip title={viewProfile.location} placement="top">
                    <Typography
                      variant="subtitle2"
                      textAlign={'right'}
                      fontWeight={600}
                      color={'primary'}
                      maxWidth={220}
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {viewProfile.location}
                    </Typography>
                  </Tooltip>
                ) : null}

                <JoinedLabel
                  createdAt={viewProfile?.createdAt}
                  textAlign={'right'}
                />
              </Stack>
            ) : null}
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};
