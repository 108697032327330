import React from 'react';
import { Typography } from '@mui/material';
import { LoggedInUser } from '../../types/user';
import { timeSince } from '../../utils/date-time';

type Props = {
  player: LoggedInUser;
};

export const PlayerActivityStatus = ({ player }: Props) => {
  return player.online && player.isIdle ? (
    <Typography variant="caption" color="logoPink.main">
      Idle
    </Typography>
  ) : player.online && !player.isIdle && player.isPlayingLeague ? (
    <Typography variant="caption" color="secondary.main">
      League Match
    </Typography>
  ) : player.online && !player.isIdle && player.isPlayingDaily ? (
    <Typography variant="caption" color="success.main">
      Playing a Daily
    </Typography>
  ) : player.online && !player.isIdle && player.isPlayingArena ? (
    <Typography variant="caption" color="success.main">
      Pub Night
    </Typography>
  ) : player.online && !player.isIdle && player.isPlayingSolo ? (
    <Typography variant="caption" color="success.main">
      Playing a Solo
    </Typography>
  ) : player.online && !player.isIdle && player.isPlayingSnG ? (
    <Typography variant="caption" color="success.main">
      Playing a Sit & Go
    </Typography>
  ) : player.online && !player.isIdle && player.isPlayingTeams ? (
    <Typography variant="caption" color="success.main">
      Playing Teams
    </Typography>
  ) : player.online && !player.isIdle ? (
    <Typography variant="caption" color="success.main">
      Online
    </Typography>
  ) : (
    <Typography variant="caption">
      Last online: {timeSince(new Date(player.disconnectedAt))} ago
    </Typography>
  );
};
