import {
  Avatar,
  Badge,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  useTheme,
} from '@mui/material';
import React from 'react';
import { StyledPaperItem } from '../../../mui-theme/mui-components';
import { resizeImage } from '../../../api/cloudinary';
import { formattedScore } from '../../../components/scorecard';
import { PrecisionGraphicScorecardIcon } from '../../../components/scorecard/PrecisionGraphicScorecardIcons';
import { DetailedPlayer, Player } from '../../../types/player';
import { sortPlayers } from '../game/helpers';
import StarIcon from '@mui/icons-material/Star';
import { yellow } from '@mui/material/colors';

const TeamAnswerAttemptsTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
  },
});

type PropTypes = {
  detailedPlayers: Record<string, DetailedPlayer>;
};

export const ScoreCardStatsSnG = ({ detailedPlayers }: PropTypes) => {
  const theme = useTheme();

  const players = sortPlayers(detailedPlayers) as DetailedPlayer[];

  const playerDisplay = (player: Player) => {
    return (
      <StyledPaperItem>
        <Stack
          direction={'row'}
          spacing={1}
          width={'100%'}
          height={'40px'}
          alignItems={'center'}
        >
          <Avatar
            src={resizeImage(player.avatarPublicId, 80, 80)}
            variant="rounded"
            sx={{
              height: 40,
              width: 40,
            }}
          />
          <Typography
            fontWeight={600}
            textAlign={'left'}
            width="100%"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'pre',
            }}
          >
            {player.username}
          </Typography>
        </Stack>
      </StyledPaperItem>
    );
  };

  const getPlayerStats = (player: DetailedPlayer) => {
    const roundScores = Object.values(player.roundScores);
    const questions = [];
    const penalties = [];
    const timeouts = [];
    const fuzzy = [];
    const attempts = [];
    let assistsCount = 0;
    let gets = 0;
    let penaltyCount = 0;
    let score = 0;
    let saves = 0;
    for (let i = 0; i < roundScores.length; i++) {
      if (roundScores[i].score !== null) {
        const playerScore = roundScores[i].score + roundScores[i].fuzzyPenalty;
        gets = gets + 1;
        if (playerScore < 0) {
          score = score + playerScore;
        } else {
          saves = saves + 1;
        }
      }
      if (roundScores[i].penalty) {
        penaltyCount = penaltyCount + 1;
      }
      if (roundScores[i].assist) {
        assistsCount = assistsCount + 1;
      }

      questions.push(roundScores[i].score);
      penalties.push(roundScores[i].penalty);
      fuzzy.push(roundScores[i].fuzzyPenalty);
      timeouts.push(roundScores[i].timerLimitReached);
      attempts.push(roundScores[i].answerAttempts);
    }
    return {
      penalties,
      penaltyCount,
      questions,
      fuzzy,
      timeouts,
      attempts,
    };
  };

  const underParScoreBallStyle = {
    bgcolor: `${theme.palette.success.main}40`,
    color: 'text.primary',
    textAlign: 'center',
    boxShadow: `0 0 5px 1px ${theme.palette.success.main}, 0 0 2px 3px ${theme.palette.success.main} inset`,
  };

  const timedoutScoreBallStyle = {
    bgcolor: `${theme.palette.error.main}40`,
    color: 'error.contrastText',
    display: 'inline-flex',
    boxShadow: `0 0 5px 1px ${theme.palette.error.main}, 0 0 2px 3px ${theme.palette.error.main} inset`,
  };

  const formatPlayerScore = (
    questionScore: number,
    penalty: number,
    fuzzy: number,
    timerLimitReached: boolean,
    answerAttempts: { input: string; gameClock: number }[],
    topScore: number
  ) => {
    let scoreStyle: any = {
      color: 'error.contrastText',
    };
    let avatarShape: any = 'rounded';
    if (questionScore < 0) {
      scoreStyle = underParScoreBallStyle;
      avatarShape = 'round';
    }
    if (timerLimitReached) {
      scoreStyle = timedoutScoreBallStyle;
      avatarShape = 'square';
    }

    const totalScore = questionScore + (penalty || 0) + (fuzzy || 0);

    return (
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        width={'80px'}
        height={'100%'}
        position={'relative'}
        paddingX={5}
      >
        <Badge badgeContent={penalty} color="warning">
          <TeamAnswerAttemptsTooltip
            placement="top"
            title={answerAttemptTooltip(answerAttempts)}
          >
            <Avatar sx={scoreStyle} variant={avatarShape}>
              {formattedScore(questionScore)}
            </Avatar>
          </TeamAnswerAttemptsTooltip>
        </Badge>
        {fuzzy !== null ? (
          <PrecisionGraphicScorecardIcon
            fuzzyPenalty={fuzzy}
            position={'absolute'}
          />
        ) : null}
        {totalScore === topScore && !timerLimitReached && (
          <StarIcon
            sx={{
              position: 'absolute',
              top: 0,
              right: '50%',
              transform: 'translateX(50%)',
              color: yellow[500],
            }}
          />
        )}
      </Stack>
    );
  };

  const answerAttemptTooltip = (
    answerAttempts: { input: string; gameClock: number }[]
  ) => {
    return (
      <Stack spacing={2} sx={{ padding: 1 }}>
        <Typography textAlign="center">Answer Attempts</Typography>
        {answerAttempts.map((answer) =>
          answer.gameClock && answer.input ? (
            <Stack direction="row" spacing={3} minWidth={'350px'}>
              <Typography
                variant="body1"
                textAlign={'right'}
                sx={{
                  width: '40%',
                  color:
                    theme.palette.mode === 'light'
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  textShadow:
                    theme.palette.mode === 'dark'
                      ? `0 0 1px ${theme.palette.primary.main}, 10px 0px 20px ${theme.palette.primary.main}, 0px 0px 16px ${theme.palette.primary.main}`
                      : 'none',
                }}
              >
                {answer.gameClock.toFixed(2)} sec
              </Typography>
              <Typography
                variant="body1"
                textAlign={'left'}
                sx={{ width: '60%' }}
              >
                "{answer.input}"
              </Typography>
            </Stack>
          ) : (
            <Typography variant="body1">None</Typography>
          )
        )}
      </Stack>
    );
  };

  const getTopScore = (players: DetailedPlayer[], questionIndex: number) => {
    const scores = players.map(
      (p) =>
        p.roundScores[questionIndex].score +
        (p.roundScores[questionIndex].fuzzyPenalty || 0) +
        (p.roundScores[questionIndex].penalty || 0)
    );
    return Math.min(...scores);
  };

  const questionLabels = [
    'Q1',
    'Q2',
    'Q3',
    'Q4',
    'Q5',
    'Q6',
    'Q7',
    'Q8',
    'Q9',
    'Q10',
  ];

  return (
    <Stack direction="row" overflow={'auto'} pb={20}>
      <Stack spacing={8} width={'300px'}>
        <Stack height={4}></Stack>
        <Stack spacing={1}>{players?.map((p) => playerDisplay(p))}</Stack>
      </Stack>

      <Stack spacing={8} overflow={'auto'} ml={1}>
        <Stack spacing={1}>
          <Stack direction={'row'}>
            <Stack direction={'row'} alignItems={'end'} height={'60px'} ml={1}>
              {questionLabels.map((n) => (
                <Stack
                  justifyContent={'center'}
                  alignItems={'end'}
                  width={'84px'}
                >
                  <Typography variant="h6" textAlign={'center'} width="100%">
                    {n}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
          {players?.map((p) => {
            const { questions, penalties, fuzzy, timeouts, attempts } =
              getPlayerStats(p);
            return (
              <Stack direction={'row'}>
                <StyledPaperItem sx={{ height: 56, ml: 1, padding: 0 }}>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    height={'100%'}
                  >
                    {questions.map((q, i) =>
                      formatPlayerScore(
                        q,
                        penalties[i],
                        fuzzy[i],
                        timeouts[i],
                        attempts[i],
                        getTopScore(players, i)
                      )
                    )}
                  </Stack>
                </StyledPaperItem>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};
