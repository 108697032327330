import React from 'react';
import {
  Badge,
  BadgeProps,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import { Link } from 'react-router-dom';

export const LobbyBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    color: theme.palette.pro.main,
    fontSize: 16,
    border: `2px solid`,
    borderColor: theme.palette.pro.main,
    borderRadius: 4,
    padding: '5px 8px',
  },
}));

type PropTypes = {
  to: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  label: string;
  open: boolean;
};

const NavListItem = ({ to, startIcon, endIcon, label, open }: PropTypes) => {
  return (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={to}>
        {startIcon && <ListItemIcon>{startIcon}</ListItemIcon>}
        {open ? <ListItemText primary={label} /> : null}
        {endIcon && <ListItemIcon>{endIcon}</ListItemIcon>}
      </ListItemButton>
    </ListItem>
  );
};

export default NavListItem;
