import { VisibilityOff, Visibility } from '@mui/icons-material';
import React, { RefObject, useState } from 'react';
import { TextField, IconButton } from '@mui/material';

type PropTypes = {
  disabled?: boolean;
  inputRef: RefObject<HTMLInputElement>;
  passwordErrorMessage: string;
  password: string;
  setPassword: (val: string) => void;
  setPasswordErrorMessage: (val: string) => void;
};
export const InputPassword = ({
  disabled,
  inputRef,
  password,
  passwordErrorMessage,
  setPassword,
  setPasswordErrorMessage,
}: PropTypes) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordErrorMessage('');
    setPassword(event.target.value);
  };

  return (
    <>
      <TextField
        type={showPassword ? 'text' : 'password'}
        inputRef={inputRef}
        value={password}
        placeholder="Password"
        fullWidth
        variant="outlined"
        name="pass"
        disabled={disabled}
        error={!!passwordErrorMessage}
        helperText={passwordErrorMessage}
        onChange={handlePasswordChange}
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          ),
        }}
      />
    </>
  );
};
