import { useApi } from '../useApi';
import { Methods } from './constants';

export const useResendEmailVerification = () => {
  const { data, error, loading, sendRequest } = useApi();

  const resendEmailVerification = (email: string) => {
    sendRequest({
      url: '/authenticate/resend-email-verification',
      method: Methods.POST,
      body: { email },
    });
  };

  return { data, error, loading, resendEmailVerification };
};
