export const API_ROUTES = {
  logout: '/authenticate/logout',
  fetchAccountExists: '/authenticate/account-exists',
  fetchProfile: '/profile',
  fetchProfileById: '/profile/id',
  fetchPlacesPredictions: '/locations/places/predictions',
  fetchPlacesGeocode: '/locations/places/geocode',
  fetchSeasonSchedules: '/league-schedule/season',
  fetchWeekSchedules: '/league-schedule/week',
  fetchScheduleGames: '/league-schedule/games-played',
  generateLeagueSchedules: '/league-schedule/generate-schedule',
  fetchLeagueTeams: '/league/teams',
  createLeagueTeam: '/league/add-team',
  approveDenyLeagueTeam: '/league/approve-deny-team',
  approveDenyJoinTeamRequest: '/league/team/approve-deny-join-request',
  updateLeagueTeam: '/league/team/update',
  joinLeagueTeamRequest: '/league/team/join-request',
  fetchProfileAnsweredQuestions: '/league/profile/answered-questions',
  fetchTeamAnsweredQuestions: '/league/team/answered-questions',
  fetchLeaguePlayerProfile: '/league/player/id',
  fetchTeamProfile: '/league/team/id',
  fetchProfiles: '/profile/ids',
  sendEmailInvite: '/league/team/invite-member',
  updateUserLocation: '/profile/location',
  createCheckoutSession: '/billing/create-checkout-session',
  fetchLobbyGames: '/games/limit',
  updatePlayMenuSettings: '/profile/play-menu-settings',
  updateGameSettings: '/profile/settings',
  fetchNotifications: '/notifications/profile',
  fetchDailiesOverview: '/dailies-v2/overview',
  fetchDailiesSets: '/dailies-v2/dailies-sets',
  fetchCurrentDaily: '/dailies-v2/current-daily',
  fetchDailiesGames: '/dailies-v2/games',
  fetchDailySetsPlayed: '/dailies/daily-sets-played',
  fetchArenaCurrentSeason: '/arena/current-season',
  fetchArenaOverview: '/arena/overview',
  fetchArenaSchedule: '/arena/schedule',
  postArenaSchedule: '/arena/schedule',
  fetchArenaLadder: '/arena/ladder',
  fetchArenaNightlyAwards: '/arena/arena-nightly-awards',
  fetchArenaNightGames: '/arena/arena-night-games',
  fetchArenaGames: '/arena/games',
  fetchArenaAchievements: '/arena/achievements',
  fetchArenaTeams: '/arena/teams',
  fetchArenaTeamsWithProfiles: '/arena/teams-with-profiles',
  fetchUserArenaAchievements: '/arena/user-achievements',
  fetchArenaAdvancedStats: '/arena/advanced-stats',
  fetchArenaTeamAdvancedStats: '/arena/advanced-team-stats',
  updateArenaLadder: '/arena/update-ladder',
  fetchArenaCategoryQuestions: '/arena/category-questions',
  fetchArenaCurrentTiers: '/arena/player-tiers',
  updateArenaAchievementsShowcase: '/arena/user-achievements-showcase',
  updateJoinArenaTeam: '/arena/join-team',
  fetchArenaTeam: '/arena/team',
  fetchArenaMmrResults: '/arena/game-mmr-results',
  fetchMmrRankings: '/rankings/all/50',
  fetchPunchcardRankings: '/rankings/punchcard/50',
  fetchPunchcard: '/punchcard/player',
  fetchPunchcardGames: '/punchcard/games',
  postCategoryBans: '/profile/category-bans',
  getAllAchievements: '/achievements/all-achievements',
};
