import { Breadcrumbs, Link, useTheme } from '@mui/material';
import React from 'react';
import { ProfileTab } from './types';

type Props = {
  activeTab: ProfileTab;
  handleBreadcrumbClick: (tab: string) => void;
};

const ProfileTabs = ({ activeTab, handleBreadcrumbClick }: Props) => {
  const theme = useTheme();

  const BreadcrumbLink = ({ label, tabName }) => {
    return (
      <Link
        variant="h6"
        underline="none"
        onClick={() => handleBreadcrumbClick(tabName)}
        sx={{
          cursor: 'pointer',
          color: activeTab === tabName ? theme.palette.text.primary : 'inherit',
          textShadow:
            activeTab === tabName
              ? `0 0 1px ${theme.palette.dailies.main}, 10px 0px 20px ${theme.palette.dailies.main}, 0px 0px 16px ${theme.palette.dailies.main}`
              : 'none',
        }}
      >
        {label}
      </Link>
    );
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <BreadcrumbLink label="Overview" tabName="overview" />
      <BreadcrumbLink label="Dailies" tabName="dailies" />
      <BreadcrumbLink label="Pub Nights" tabName="arena" />
    </Breadcrumbs>
  );
};

export default ProfileTabs;
