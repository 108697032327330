export const USER_FORM_CATEGORIES = [
  'Art & Architecture',
  'Business',
  'Classical Music',
  'Culture',
  'Film',
  'Food & Drink',
  'Geography',
  'History',
  'Literature',
  'Math',
  'Niche',
  'Philosophy & Religion',
  'Politics & Civics',
  'Popular Music',
  'Science',
  'Sports',
  'Theater',
  'TV',
  'Video Games',
  'Vocab & Language',
];

export const PAR_VALUES = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

export const GAME_STAGES = {
  COUNT_DOWN: 'COUNT_DOWN',
  DELAY_BEFORE_QUESTION_REVEAL: 'DELAY_BEFORE_QUESTION_REVEAL',
  REVEAL_QUESTION: 'REVEAL_QUESTION',
  REVEAL_BOXES: 'REVEAL_BOXES',
  DELAY_BEFORE_LETTER_REVEAL: 'DELAY_BEFORE_LETTER_REVEAL',
  PAUSED: 'PAUSED',
  REVEAL_ANSWER: 'REVEAL_ANSWER',
  WAIT_UNTIL_TIME_LIMIT: 'WAIT_UNTIL_TIME_LIMIT',
  NEXT_QUESTION: 'NEXT_QUESTION',
};

export const DIFFICULTY = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];

export const PROPER_NAME_SHORT_ANSWERS = [
  'none',
  'last word',
  'last two words',
  'first word',
  'first two words',
];
