import {
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  DialogActions,
  Button,
  Avatar,
  Stack,
} from '@mui/material';
import React from 'react';
import { TrueSkill } from '../../../profile/ArenaProfile/types';
import { Player } from '../../../../types/player';
import { getAvatarSrc } from '../../../../api/cloudinary';
import { green } from '@mui/material/colors';

type Props = {
  arenaUpdateTrueSkill?: TrueSkill[];
  open: boolean;
  onClose: () => void;
  sortedPlayers?: Player[];
};

const MmrResultsDialog = ({
  arenaUpdateTrueSkill,
  open,
  onClose,
  sortedPlayers,
}: Props) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Pub Points +/-</DialogTitle>
      <DialogContent>
        {sortedPlayers && arenaUpdateTrueSkill ? (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                {sortedPlayers.map((p, i) => {
                  const trueSkill = arenaUpdateTrueSkill.find(
                    (ts) => ts.profileId === p.profileId
                  );
                  const mmrDiff = Math.round(trueSkill.mmrDiffFromPrev);
                  return (
                    <TableRow key={i}>
                      <TableCell>
                        <Typography
                          textAlign={'right'}
                          fontWeight={600}
                          color={
                            trueSkill.gamesPlayed > 5
                              ? mmrDiff >= 0
                                ? green[400]
                                : 'error'
                              : 'gray'
                          }
                        >
                          {trueSkill.gamesPlayed <= 5
                            ? 'cal'
                            : `${mmrDiff > 0 ? '+' : ''}${mmrDiff}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {p?.username && (
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems={'center'}
                          >
                            <Avatar
                              variant="rounded"
                              alt={p?.username}
                              src={getAvatarSrc(
                                p?.avatarPublicId,
                                undefined,
                                100,
                                100
                              )}
                              sx={{
                                width: 32,
                                height: 32,
                              }}
                            />
                            <Typography>{p?.username || 'N/A'}</Typography>
                          </Stack>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MmrResultsDialog;
