import { useEffect, useRef, useState } from 'react';
import { useGameContext } from '../../../contexts/GameProvider';
import {
  useGameloopSocketContext,
  useSocketContext,
} from '../../../contexts/SocketProvider';
import {
  LeagueGameType,
  ReportInaccuracy,
  Spectator,
  TournamentResult,
  ParticipantLosses,
  Achievement,
} from '../../../types';
import { Mode, Question } from '../../../types/game';
import {
  ArenaTeamId,
  ArenaTeamRoundScores,
  DetailedPlayer,
} from '../../../types/player';
import { ProperNameShortAnswer } from '../../../types/question';
import { TrueSkill } from '../../profile/ArenaProfile/types';
import { ArenaTeam } from '../../arena-team/types';
import dayjs from 'dayjs';

let retryDetailedPlayersEmit;

export const useGameStatesMainServer = (profileId: string, gameId: string) => {
  const { gameloopSocket: socket } = useGameloopSocketContext();
  const {
    arenaPlayersProjectionAndTiers,
    gameDataFromServer,
    setShowGameChat,
    setShowArenaChat,
    setArenaPlayersProjectionAndTiers,
  } = useGameContext();

  const [arenaGame, setArenaGame] = useState<number>();
  const [arenaSet, setArenaSet] = useState<number>();
  const [gameType, setGameType] = useState<'casual' | 'pro'>();
  const [categorySet, setCategorySet] = useState<string>();
  const [categorySelections, setCategorySelections] = useState<string[]>();
  const [leagueGameType, setLeagueGameType] = useState<LeagueGameType>();
  const [isPunchcard, setIsPunchcard] = useState<boolean>();
  const [isRanked, setIsRanked] = useState<boolean>();
  const [dailySet, setDailySet] = useState<number>();
  const [missedDaily, setMissedDaily] = useState<boolean>();
  const [mode, setMode] = useState<Mode>();
  const [leagueMatch, setLeagueMatch] = useState<number>();
  const [detailedPlayers, setDetailedPlayers] =
    useState<Record<string, DetailedPlayer>>();
  const [questionSet, setQuestionSet] = useState<Question[]>();
  const [gameStarted, setGameStarted] = useState(false);
  const [countdown, setCountdown] = useState<number>();
  const [questionIndex, setQuestionIndex] = useState<number>();
  const [gameClock, setGameClock] = useState(0);
  const [revealQuestion, setRevealQuestion] = useState('');
  const [revealFullQuestion, setRevealFullQuestion] = useState<string>();
  const [questionMetadata, setQuestionMetadata] =
    useState<Record<string, any>>();
  const [revealAnswer, setRevealAnswer] = useState<string[][]>();
  const [timerLimitReached, setTimerLimitReached] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [unrevealedIndex, setUnrevealedIndex] = useState<number[]>();
  const [properNameShortAnswer, setProperNameShortAnswer] =
    useState<ProperNameShortAnswer>();
  const [reportInaccuracy, setReportInaccuracy] =
    useState<ReportInaccuracy[]>();
  const [spectators, setSpectators] = useState<Spectator[]>([]);
  const [spectatorList, setSpectatorList] = useState<Spectator[]>();
  const [delayBeforeQuestionRevealCount, setDelayBeforeQuestionRevealCount] =
    useState(10);
  const [nextQuestionDelayCount, setNextQuestionDelayCount] = useState(10);
  const [paused, setPaused] = useState(false);
  const [willPause, setWillPause] = useState(false);
  const [unpauseCount, setUnpauseCount] = useState<number>();
  const [cutoff, setCutoff] = useState<number>();
  const [bannedCategories, setBannedCategories] = useState<string[]>();
  const [mathFormula, setMathFormula] = useState<string>();
  const [questionImgUrl, setQuestionImgUrl] = useState<string>();
  const [connectCounter, setConnectCounter] = useState(0);
  const [disconnectCounter, setDisconnectCounter] = useState(0);
  const [achievementNames, setAchievementNames] =
    useState<{ name: string; new: boolean }[]>();
  const [tournamentChampions, setTournamentChampions] =
    useState<TournamentResult>();
  const [participantLosses, setParticipantLosses] =
    useState<ParticipantLosses>();
  const [arenaUpdateTrueSkill, setArenaUpdateTrueSkill] =
    useState<TrueSkill[]>();
  const [arenaAchievements, setArenaAchievements] = useState<any>();
  const [arenaTeamRoundScores, setArenaTeamRoundScores] =
    useState<Record<ArenaTeamId, ArenaTeamRoundScores>>();
  const [arenaTeamsDetailed, setArenaTeamsDetailed] = useState<ArenaTeam[]>();
  const [arenaTeams, setArenaTeams] = useState<ArenaTeam[]>();

  const [test, setTest] = useState<any>([]);

  const retryIntervalRef = useRef(null);

  useEffect(() => {
    if (!socket) return;

    socket.on('detailedPlayers', setDetailedPlayers);
    socket.on('gameStarted', setGameStarted);
    socket.on('countdown', setCountdown);
    socket.on('questionIndex', setQuestionIndex);
    socket.on('questionMetadata', setQuestionMetadata);
    socket.on('gameClock', setGameClock);
    socket.on('revealAnswer', setRevealAnswer);
    socket.on('revealQuestion', updateQuestion);
    socket.on('revealFullQuestion', updateFullQuestionReveal);
    socket.on('timerLimitReached', setTimerLimitReached);
    socket.on('unrevealedIndex', setUnrevealedIndex);
    socket.on('gameOver', setGameOver);
    socket.on('questionSet', setQuestionSet);
    socket.on('properNameShortAnswer', setProperNameShortAnswer);
    socket.on('reportInaccuracy', setReportInaccuracy);
    socket.on('bannedCategories', setBannedCategories);
    socket.on(
      'delayBeforeQuestionRevealCount',
      setDelayBeforeQuestionRevealCount
    );
    socket.on('nextQuestionDelayCount', setNextQuestionDelayCount);
    socket.on('paused', setPaused);
    socket.on('willPause', setWillPause);
    socket.on('unpauseCount', setUnpauseCount);
    socket.on('cutoff', setCutoff);
    socket.on('spectators', setSpectators);
    socket.on('rejoinedData', handleRejoinedData);
    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);
    socket.on('achievements', setAchievementNames);
    socket.on('tournamentChampions', setTournamentChampions);
    socket.on('participantLosses', setParticipantLosses);
    socket.on('arenaUpdateTrueSkill', setArenaUpdateTrueSkill);
    socket.on('arenaAchievements', setArenaAchievements);
    socket.on('arenaTeamRoundScores', setArenaTeamRoundScores);
    socket.on('arenaTeamsDetailed', setArenaTeamsDetailed);
    socket.on('arenaTeams', setArenaTeams); // for live game

    return () => {
      socket.off('detailedPlayers', setDetailedPlayers);
      socket.off('gameStarted', setGameStarted);
      socket.off('countdown', setCountdown);
      socket.off('questionIndex', setQuestionIndex);
      socket.off('questionMetadata', setQuestionMetadata);
      socket.off('gameClock', setGameClock);
      socket.off('revealAnswer', setRevealAnswer);
      socket.off('revealQuestion', updateQuestion);
      socket.off('revealFullQuestion', updateFullQuestionReveal);
      socket.off('timerLimitReached', setTimerLimitReached);
      socket.off('unrevealedIndex', setUnrevealedIndex);
      socket.off('gameOver', setGameOver);
      socket.off('questionSet', setQuestionSet);
      socket.off('properNameShortAnswer', setProperNameShortAnswer);
      socket.off('reportInaccuracy', setReportInaccuracy);
      socket.off('bannedCategories', setBannedCategories);
      socket.off(
        'delayBeforeQuestionRevealCount',
        setDelayBeforeQuestionRevealCount
      );
      socket.off('nextQuestionDelayCount', setNextQuestionDelayCount);
      socket.off('paused', setPaused);
      socket.off('willPause', setWillPause);
      socket.off('unpauseCount', setUnpauseCount);
      socket.off('cutoff', setCutoff);
      socket.off('spectators', setSpectators);
      socket.off('rejoinedData', handleRejoinedData);
      socket.off('connect', handleConnect);
      socket.off('disconnect', handleDisconnect);
      socket.off('achievements', setAchievementNames);
      socket.off('tournamentChampions', setTournamentChampions);
      socket.off('participantLosses', setParticipantLosses);
      socket.off('arenaUpdateTrueSkill', setArenaUpdateTrueSkill);
      socket.off('arenaAchievements', setArenaAchievements);
      socket.off('arenaTeamRoundScores', setArenaTeamRoundScores);
      socket.off('arenaTeamsDetailed', setArenaTeamsDetailed);
      socket.off('arenaTeams', setArenaTeams); // for live game
    };
  }, [socket]);

  // pretty heavy retry logic to handle race condition issues with questionmetada, probably should be refactored
  // useEffect(() => {
  //   if (questionMetadata?.category) {
  //     if (retryIntervalRef.current) {
  //       clearInterval(retryIntervalRef.current);
  //       retryIntervalRef.current = null;
  //     }
  //     return;
  //   }

  //   if (!retryIntervalRef.current) {
  //     retryIntervalRef.current = setInterval(() => {
  //       requestQuestionMetadata();
  //     }, 1000);
  //   }

  //   return () => {
  //     if (retryIntervalRef.current) {
  //       clearInterval(retryIntervalRef.current);
  //     }
  //   };
  // }, [questionMetadata]);

  useEffect(() => {
    if (!gameDataFromServer) return;
    setLeagueGameType(gameDataFromServer.leagueGameType);
    setMode(gameDataFromServer.mode);
    setCategorySet(gameDataFromServer.categorySet);
    setCategorySelections(gameDataFromServer.categorySelections);
    setGameType(gameDataFromServer.gameType);
    setLeagueMatch(gameDataFromServer.leagueMatch);
    setDailySet(gameDataFromServer.dailySet);
    setMissedDaily(gameDataFromServer.missedDaily);
    setArenaGame(gameDataFromServer.arenaGame);
    setArenaSet(gameDataFromServer.arenaSet);
    setIsPunchcard(gameDataFromServer.isPunchcard);
    setIsRanked(gameDataFromServer.isRanked);
  }, [gameDataFromServer]);

  useEffect(() => {
    if (!spectators) return;
    setSpectatorList(spectators);
  }, [spectators]);

  useEffect(() => {
    if (gameOver && mode !== 'solo') {
      if (arenaSet) {
        setShowArenaChat(true);
      } else {
        setShowGameChat(true);
      }
    }
  }, [gameOver, mode, arenaSet]);

  const requestDetailedPlayers = (gameId: string, profileId: string) => {
    if (!socket) return;
    socket.emit('requestDetailedPlayers', {
      gameId,
      profileId: profileId,
      playerProfileIds: [],
    });
  };

  // const updateDetailedPlayers = (
  //   detailedPlayers: Record<string, DetailedPlayer>
  // ) => {
  //   clearTimeout(retryDetailedPlayersEmit);
  //   console.log('detailedPlayers', data);
  //   setDetailedPlayers(data.detailedPlayers);
  // };

  const updateQuestion = (data: {
    revealQuestion: string;
    mathFormula?: string;
    imgUrl?: string;
  }) => {
    // dayjs show milliseconds
    setTest((prev) => prev.concat(dayjs().valueOf()));

    setRevealQuestion((prev) => data.revealQuestion);
    setMathFormula((prev) => data.mathFormula);
    setQuestionImgUrl((prev) => data.imgUrl);
  };

  const updateFullQuestionReveal = (data: {
    revealQuestion: string;
    mathFormula?: string;
    imgUrl?: string;
  }) => {
    setRevealFullQuestion(data.revealQuestion);
    if (data.mathFormula) {
      setMathFormula(data.mathFormula);
    }
    if (data.imgUrl) {
      setQuestionImgUrl(data.imgUrl);
    }
  };

  const handleRejoinedData = (data: {
    arenaNight: any;
    arenaGame: any;
    arenaPlayersProjectionAndTiers: any;
    arenaSet: any;
    arenaTeams: any;
    mode: any;
    cutoff: any;
    countdown: any;
    questionMetadata: any;
    questionIndex: any;
    gameStarted: any;
    gameOver: any;
    league: any;
    revealQuestion: any;
    revealAnswer: any;
    unrevealedIndex: any;
    timerLimitReached: any;
    delayBeforeQuestionRevealCount: any;
    bannedCategories?: any;
    teams: any;
  }) => {
    console.log('rejoin', data);
    setArenaGame(data.arenaGame);
    setArenaSet(data.arenaSet);
    setArenaPlayersProjectionAndTiers(data.arenaPlayersProjectionAndTiers);
    setArenaTeams(data.arenaTeams);
    setMode(data.mode);
    setCutoff(data.cutoff);
    setCountdown(data.countdown);
    setQuestionMetadata(data.questionMetadata);
    setQuestionIndex(data.questionIndex);
    setGameStarted(data.gameStarted);
    setGameOver(data.gameOver);
    updateQuestion(data.revealQuestion);
    setRevealAnswer(data.revealAnswer);
    setUnrevealedIndex(data.unrevealedIndex);
    setTimerLimitReached(data.timerLimitReached);
    setDelayBeforeQuestionRevealCount(data.delayBeforeQuestionRevealCount);
    if (data.bannedCategories) {
      setBannedCategories(data.bannedCategories);
    }
    // setTeams(data.teams);
    // if (data.teams) {
    //   const myTeamId = data.teams.teamA.members.includes(profile._id)
    //     ? 'teamA'
    //     : 'teamB';
    //   setMyTeamId(myTeamId);
    // }
  };

  const handleConnect = () => {
    setConnectCounter((prev) => prev + 1);
  };

  const handleDisconnect = () => {
    setDisconnectCounter((prev) => prev + 1);
  };

  const resetStatesFromMainServer = () => {
    setRevealQuestion('');
    setRevealFullQuestion(undefined);
    setMathFormula(undefined);
    setQuestionImgUrl(undefined);
    setTimerLimitReached(false);
  };

  const resetDelayClocks = () => {
    // TODO: refactor this logic
    // these are reset to an arbitrary high number to prevent conditions from rendering when equal to 0
    // the server will determine the values of these in between questions
    setDelayBeforeQuestionRevealCount(10);
    setNextQuestionDelayCount(10);
  };

  const requestQuestionMetadata = () => {
    socket?.emit('requestQuestionMetadata', { profileId, gameId });
  };

  return {
    achievementNames,
    arenaGame,
    arenaPlayersProjectionAndTiers,
    arenaSet,
    arenaTeamRoundScores,
    arenaTeams,
    arenaTeamsDetailed,
    bannedCategories,
    categorySet,
    categorySelections,
    connectCounter,
    countdown,
    cutoff,
    dailySet,
    delayBeforeQuestionRevealCount,
    detailedPlayers,
    disconnectCounter,
    gameClock,
    gameOver,
    gameStarted,
    gameType,
    isPunchcard,
    isRanked,
    leagueGameType,
    leagueMatch,
    mathFormula,
    missedDaily,
    mode,
    nextQuestionDelayCount,
    participantLosses,
    paused,
    properNameShortAnswer,
    questionImgUrl,
    questionIndex,
    questionMetadata,
    questionSet,
    reportInaccuracy,
    requestDetailedPlayers,
    resetDelayClocks,
    resetStatesFromMainServer,
    revealAnswer,
    revealFullQuestion,
    revealQuestion,
    arenaAchievements,
    arenaUpdateTrueSkill,
    spectatorList,
    spectators,
    timerLimitReached,
    tournamentChampions,
    unpauseCount,
    unrevealedIndex,
    willPause,
    test,
  };
};
