import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { BracketLiveScores } from '../../../../types/league-tournament';

type PropTypes = {
  bracketLiveScores: BracketLiveScores;
  currentGameId: string;
};

export const SidebarLiveScores = ({
  bracketLiveScores,
  currentGameId,
}: PropTypes) => {
  const theme = useTheme();

  delete bracketLiveScores[currentGameId];
  const liveGames = Object.values(bracketLiveScores).filter(
    (g) => g.gameStarted && !g.gameOver
  );

  return (
    <>
      {liveGames.length ? (
        liveGames.map((g) => (
          <Stack
            mx={2}
            my={1}
            px={2}
            py={0.5}
            sx={{
              border: '1px solid',
              borderRadius: 1,
            }}
          >
            <Stack direction={'row'} width={'100%'}>
              <Typography
                variant="caption"
                width={'80%'}
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {g.teams.teamA.teamName}
              </Typography>
              <Typography
                variant="caption"
                width={'20%'}
                textAlign={'right'}
                sx={{
                  color:
                    g.teams.teamA.score < g.teams.teamB.score
                      ? theme.palette.success.main
                      : g.teams.teamA.score > g.teams.teamB.score
                      ? 'red'
                      : 'none',
                }}
              >
                {g.teams.teamA.score > 0 ? '+' : ''}
                {g.teams.teamA.score}
              </Typography>
            </Stack>
            <Stack direction={'row'} width={'100%'}>
              <Typography
                variant="caption"
                width={'80%'}
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {g.teams.teamB.teamName}
              </Typography>
              <Typography
                variant="caption"
                width={'20%'}
                textAlign={'right'}
                sx={{
                  color:
                    g.teams.teamB.score < g.teams.teamA.score
                      ? theme.palette.success.main
                      : g.teams.teamB.score > g.teams.teamA.score
                      ? 'red'
                      : 'none',
                }}
              >
                {g.teams.teamB.score > 0 ? '+' : ''}
                {g.teams.teamB.score}
              </Typography>
            </Stack>
          </Stack>
        ))
      ) : (
        <Typography mt={10} textAlign={'center'}>
          No Live Games
        </Typography>
      )}
    </>
  );
};
