import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import Countdown, { zeroPad } from 'react-countdown';
import { Maintenance } from '../../../types';
import { Profile } from '../../../types/player';
import { getPacificTime } from '../../../utils/date-time';
import { DailiesAuthor } from '../types';
import { DAILIES_THEMES } from '../constants';
import { isMobile } from 'react-device-detect';
import { countdownRendererHours } from '../../../utils/countdown';

type PropTypes = {
  dailiesAuthor?: DailiesAuthor;
  currentDailySet: number;
  nextDailySchedule: number;
  handleDailyCountdownStop: () => void;
  handlePlayDailyClick: (currentDailySet: number, dailyTheme?: string) => void;
  isMissedDaily: boolean;
  isPlayedDaily: boolean;
  maintenance: Maintenance;
  profile: Profile;
};

export const DailiesButton = ({
  dailiesAuthor,
  currentDailySet,
  nextDailySchedule,
  handleDailyCountdownStop,
  handlePlayDailyClick,
  isMissedDaily,
  isPlayedDaily,
  maintenance,
  profile,
}: PropTypes) => {
  const [dailyTheme, setDailyTheme] = useState<string>();
  const theme = useTheme();

  useEffect(() => {
    const d = new Date(nextDailySchedule);
    d.setDate(d.getDate() - 1);
    const dayOfWeekAbbr = getPacificTime().format('ddd');
    setDailyTheme(DAILIES_THEMES[dayOfWeekAbbr].theme);
  }, []);

  const disablePlayYourDaily = () =>
    isPlayedDaily ||
    (!isMissedDaily &&
      (profile.lastDailySetPlayed >= currentDailySet ||
        profile._id === dailiesAuthor?.authorProfileId));

  return (
    <Button
      variant="text"
      fullWidth
      sx={{
        fontSize: 20,
        letterSpacing: { xs: 2, sm: 4 },
        border: '.5px solid',
        borderColor: theme.palette.primary.main,
        boxShadow:
          disablePlayYourDaily() || maintenance.scheduled
            ? 'none'
            : `0px 1px 1px 0px ${theme.palette.dailies.light}, 1px 1px 3px 0px ${theme.palette.dailies.light}, 1px 1px 4px 0px ${theme.palette.dailies.light}, 3px 4px 7px 0px ${theme.palette.dailies.light}b8`,
      }}
      onClick={() => handlePlayDailyClick(currentDailySet, dailyTheme)}
      disabled={disablePlayYourDaily() || maintenance.scheduled}
    >
      {maintenance.scheduled ? (
        <Typography>MAINTENANCE</Typography>
      ) : disablePlayYourDaily() ? (
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h6">
            {nextDailySchedule && (
              <Countdown
                date={nextDailySchedule}
                renderer={countdownRendererHours}
                onComplete={handleDailyCountdownStop}
              />
            )}
          </Typography>
        </Stack>
      ) : (
        <Typography
          variant="h5"
          zIndex={1}
          sx={{
            left: { xs: 96, sm: 130, md: 168 },
            textShadow: `
                0px 0px 4px ${theme.palette.dailies.light},
                0px 0px 20px ${theme.palette.dailies.light},
                0px 0px 20px ${theme.palette.dailies.light}a1,
                0px 0px 20px`,
            color: 'white',
          }}
        >
          PLAY {isMissedDaily ? `MISSED` : `TODAY'S`} DAILY
          {/* P<span className="flicker-fast">L</span>AY TODAY'S{' '}
          <span className="flicker-slow">D</span>DAIL
          <span className="flicker-fast">Y</span> */}
        </Typography>
      )}
    </Button>
  );
};
