import { useApi } from '../useApi';
import { Methods } from './constants';

export const useForgotPassword = () => {
  const { data, error, loading, sendRequest } = useApi();

  const forgotPassword = (email: string) => {
    sendRequest({
      url: '/authenticate/forgot-password',
      method: Methods.POST,
      body: { email },
    });
  };

  return { data, error, loading, forgotPassword };
};
