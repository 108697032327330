import { useEffect } from 'react';
import { Methods } from './auth/constants';
import { useApi } from './useApi';
import { useProfileContext } from '../contexts/ProfileProvider';
import { uploadImageToCloudinary } from '../api/cloudinary';
import { Profile } from '../types/player';

export const useUpdateProfileAvatar = () => {
  const { refetch } = useProfileContext();
  const { data, error, loading, sendRequest } = useApi<Profile>();

  useEffect(() => {
    if (data && !error) {
      refetch();
    }
  }, [data, error, refetch]);

  const updateAvatarImage = async (file: any) => {
    const cloudinaryRes = await uploadImageToCloudinary(file);
    sendRequest({
      url: '/profile/avatar-url',
      method: Methods.POST,
      body: {
        avatarUrl: cloudinaryRes.url,
        avatarPublicId: cloudinaryRes.public_id,
      },
    });
  };

  return { data, error, loading, updateAvatarImage };
};
