import { SocketCallback } from '../../../types';
import { GameDataFromServer } from '../../../types/game';
import {
  useInputSocketContext,
  useSocketContext,
  useSpectateSocketContext,
} from '../../../contexts/SocketProvider';
import { useErrorDialog } from '../../../contexts/ErrorDialogProvider';
import { useGameContext } from '../../../contexts/GameProvider';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const useArenaGameManager = () => {
  const navigate = useNavigate();

  const { socket } = useSocketContext();
  const { spectateSocket } = useSpectateSocketContext();
  const { inputSocket } = useInputSocketContext();

  const {
    clearChat,
    clearGameData,
    setGameId,
    setGameDataFromServer,
    setLeagueGameType,
    setShowPregame,
    setShowArenaChat,
    setShowGameChat,
    setSpectateGameDataFromServer,
    spectateGameId,
    spectatePostGameId,
    setSpectateGameId,
    setSpectateLeagueGameType,
  } = useGameContext();

  const { setMessage, setTitle, setOpen } = useErrorDialog();

  const handleJoinArenaTeamGame = (profileId: string, gameId: string) => {
    if (!socket) return;
    socket.emit(
      'joinArenaTeamGame',
      { gameId, profileId },
      (res: SocketCallback) => {
        if (res.status === 'ok') {
          const gameDataFromServer: GameDataFromServer = res.data;
          console.log('handleJoinArenaTeamGame', gameDataFromServer);
          joinArenaTeamGameSuccess(profileId, gameDataFromServer);
        }
        if (res.status === 'failed') {
          requestFailed(res.message || '');
        }
      }
    );
  };

  const joinArenaTeamGameSuccess = (
    profileId: string,
    gameDataFromServer: GameDataFromServer
  ) => {
    setGameDataFromServer(gameDataFromServer);
    const { gameId, gameOver, gameStarted, leagueGameType, mode, arenaSet } =
      gameDataFromServer;
    setGameId(gameId);
    setLeagueGameType(leagueGameType);
    inputSocket.emit('joinArenaTeamGame', { gameId, profileId });
    if (gameStarted) {
      navigate('/game-loading', {
        state: {
          fromQuickPlay: false,
          rejoin: true,
        },
      });
      return;
    }

    // if (mode === 'sit-n-go') {
    //   if (gameDataFromServer.arenaNight) {
    //     setShowArenaChat(true);
    //   } else {
    //     setShowGameChat(true);
    //   }
    // setShowPregame(true);
    // }
  };

  const requestFailed = (message?: string) => {
    setOpen(true);
    if (message === 'Game in progress') {
      setTitle(message);
      setMessage(`You have a game in progress. Wait until it's over.`);
    } else if (message === 'Already in game') {
      setTitle(message);
      setMessage(
        `You already joined a game. Go to the lobby and leave that game to start a new one`
      );
    } else if (message === 'Player not found') {
      setTitle(message);
      setMessage(`Please refresh this page`);
    } else {
      setTitle(message);
    }
  };

  const handleSpectateClick = useCallback(
    (profileId: string, gameId: string) => {
      if (!spectateSocket) return;
      spectateSocket.emit(
        'canSpectateGame',
        profileId,
        gameId,
        (res: SocketCallback) => {
          if (res.status === 'ok') {
            if (spectatePostGameId) {
              leaveChat(profileId, spectatePostGameId);
              clearChat();
            }
            const gameData: GameDataFromServer = res.data;
            spectateGameSuccess(gameData);
          }
          if (res.status === 'failed') {
            requestFailed(res.message || '');
          }
        }
      );
    },
    [spectateSocket, spectatePostGameId]
  );

  const spectateGameSuccess = (gameDataFromServer: GameDataFromServer) => {
    const { gameId, leagueGameType } = gameDataFromServer;
    setSpectateGameId(gameId);
    setSpectateLeagueGameType(leagueGameType);
    setSpectateGameDataFromServer(gameDataFromServer);
    navigate('/spectate', {
      state: {
        fromQuickPlay: true,
      },
    });
  };

  const handleRejoinGame = (profileId: string, gameId: string) => {
    if (!socket) return;
    socket.emit('canRejoinGame', profileId, (res: SocketCallback) => {
      if (res.status === 'ok') {
        const gameData: GameDataFromServer = res.data;
        rejoinGameSuccess(profileId, gameData);
      }
      if (res.status === 'failed') {
        requestFailed(res.message || '');
      }
    });
  };

  const rejoinGameSuccess = (
    profileId: string,
    gameDataFromServer: GameDataFromServer
  ) => {
    const { gameId } = gameDataFromServer;
    setGameId(gameId);
    setGameDataFromServer(gameDataFromServer);
    inputSocket.emit('joinArenaTeamGame', { gameId, profileId });
    navigate('/game-loading', {
      state: {
        rejoin: true,
      },
    });
  };

  const handleLeaveGame = (profileId: string, gameId: string) => {
    if (!socket) return;
    socket.emit('leaveGame', {
      gameId,
      profileId,
    });
    clearGameData();
    setGameId('');
    setSpectateGameId('');
    setShowPregame(false);
    setShowGameChat(false);
  };

  const leaveChat = (profileId: string, gameId: string) => {
    socket.emit(
      'leaveChat',
      {
        gameId,
        profileId,
      },
      (res: SocketCallback) => {
        if (res.status === 'ok') {
          setShowGameChat(false);
        }
      }
    );
    return true;
  };

  return {
    handleJoinArenaTeamGame,
    handleSpectateClick,
    handleLeaveGame,
    handleRejoinGame,
  };
};

export default useArenaGameManager;
