import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import PunchcardPage from './PunchcardPage';
import useGetPunchcard from './useGetPunchcard';
import { useLobbyGamesContext } from '../../../contexts/LobbyGamesProvider';
import { useProfileContext } from '../../../contexts/ProfileProvider';
import { LABEL_COLORS } from './constants';
import { CustomTooltip } from '../../../components/custom-tooltip/CustomTooltip';
import BasicLayout from '../../../BasicLayout/BasicLayout';

const PunchcardMenu = () => {
  const { profile } = useProfileContext();
  const { maintenance, handlePlayClick2 } = useLobbyGamesContext();
  const playClicked = false;

  const { data: punchcard } = useGetPunchcard(profile?._id);

  return (
    <>
      <BasicLayout>
        <Stack
          spacing={4}
          justifyContent={'center'}
          alignItems={'center'}
          pb={10}
        >
          <Stack
            direction={'row'}
            pt={2}
            px={4}
            spacing={4}
            alignItems={'center'}
          >
            <Avatar
              variant="circular"
              sx={{
                border: 2,
                borderColor: 'secondary.main',
                height: 100,
                width: 100,
              }}
            >
              <Stack>
                <Typography
                  color="secondary"
                  fontWeight={600}
                  textAlign={'center'}
                >
                  Lvl
                </Typography>
                <Typography
                  variant="h6"
                  color="secondary"
                  fontWeight={600}
                  textAlign={'center'}
                >
                  {punchcard?.level?.toFixed(1) || 0}
                </Typography>
              </Stack>
            </Avatar>

            <Stack spacing={2}>
              <Typography>
                Fill out your Punchcard by earning trophies in each major
                category!
              </Typography>

              <Stack direction={'row'} spacing={1}>
                <Typography>Level up by improving your 10 game </Typography>
                <Typography color={LABEL_COLORS['average']}>
                  Average,
                </Typography>
                <Typography>your </Typography>
                <Typography color={LABEL_COLORS['best']}>Best</Typography>
                <Typography>game, and total </Typography>
                <Typography color={LABEL_COLORS['gamesPlayed']}>
                  Games Played
                </Typography>
              </Stack>

              <Box>
                <CustomTooltip
                  title="Your level goes up the better your Average, Best, and Games Played stats are. For Average, you must play at least 10 games and average better than a +40. For Best, you must have a score better than +20. Each game played adds 0.1 to your level."
                  placement="left"
                >
                  <Chip
                    label="How is level determined?"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    sx={{ cursor: 'pointer' }}
                  />
                </CustomTooltip>
              </Box>
            </Stack>
          </Stack>

          <PunchcardPage
            handlePlayClick={handlePlayClick2}
            maintenance={maintenance}
            playClicked={playClicked}
            profileId={profile._id}
            punchcard={punchcard}
          />
        </Stack>
      </BasicLayout>
    </>
  );
};

export default PunchcardMenu;
