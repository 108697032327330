import { Box, Grow } from '@mui/material';
import React from 'react';
import '../../../../../components/scorecard/SubmittedByStamp.scss';

type Props = {
  displayCategoryAndPar: boolean;
  submittedByUsername: string;
};

const CustomQuestionAuthorDisplay = ({
  displayCategoryAndPar,
  submittedByUsername,
}: Props) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: '15%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Grow in={displayCategoryAndPar} timeout={500}>
        <Box
          className="submitted-by-stamp submitted-by-stamp-username"
          textAlign={'center'}
        >
          Question by:
          <br />
          {submittedByUsername}
        </Box>
      </Grow>
    </Box>
  );
};

export default CustomQuestionAuthorDisplay;
