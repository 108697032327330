import React, { useEffect, useState } from 'react';
import { fetchGet, fetchPost } from '../../api/common';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Profile } from '../../types/player';
import { CustomQuestionsEditDialog } from './CustomQuestionsEditDialog';
import { useProfileContext } from '../../contexts/ProfileProvider';
import { Question } from '../../types/game';

const InaccuraciesReport = () => {
  const { profile } = useProfileContext();
  const { enqueueSnackbar } = useSnackbar();
  const [inaccuraciesRows, setInaccuraciesRows] = useState<
    {
      comment: string;
      isUserSubmittedQuestion?: boolean;
      question: Question;
      profile: Profile;
    }[]
  >();
  const [clickedQuestion, setClickedQuestion] = useState<Question>();
  const [openEditModal, setOpenEditModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchGet('/inaccuracy-report/all');
      if (res) {
        setInaccuraciesRows(res);
      }
    };
    fetchData();
  }, []);

  const handleAddressedClick = async (row: any) => {
    const updateData = {
      _id: row._id,
      addressed: true,
      addressedAt: Date.now(),
      ignored: null,
      ignoredAt: null,
    };
    try {
      await fetchPost('/inaccuracy-report/resolve', updateData);
      const res = await fetchGet('/inaccuracy-report/all');
      setInaccuraciesRows(res);
    } catch {
      throw Error('Inaccuracy report update failed');
    }
  };

  const handleIgnoreClick = async (row: any) => {
    const updateData = {
      _id: row._id,
      addressed: null,
      addressedAt: null,
      ignored: true,
      ignoredAt: Date.now(),
    };
    try {
      await fetchPost('/inaccuracy-report/resolve', updateData);
      const res = await fetchGet('/inaccuracy-report/all');
      setInaccuraciesRows(res);
    } catch {
      throw Error('Inaccuracy report update failed');
    }
  };

  const handleEditClick = (q: Question) => {
    setClickedQuestion(q);
    setOpenEditModal(true);
  };

  const handleSubmitEdit = async (questionId: string, editedQuestion: any) => {
    const body = {
      profileId: profile._id,
      questionId,
      editedQuestion,
    };
    const res = await fetchPost('/inaccuracy-report/edit-question', body);
    setOpenEditModal(false);
    enqueueSnackbar(`Question edited`, { variant: 'success' });
  };

  return (
    <>
      {clickedQuestion ? (
        <CustomQuestionsEditDialog
          close={() => setOpenEditModal(false)}
          customQuestion={clickedQuestion}
          formType="edit"
          handleSubmitEdit={handleSubmitEdit}
          open={openEditModal}
          questionId={clickedQuestion._id}
        />
      ) : null}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width={'40%'}>Question</TableCell>
              <TableCell width={'15%'}>Answer</TableCell>
              <TableCell width={'30%'}>Comment</TableCell>
              <TableCell>Reported By</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Addressed</TableCell>
              <TableCell>Ignore</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inaccuraciesRows?.map((row) =>
              row.question ? (
                <TableRow key={row?.question._id}>
                  <TableCell>{row.question.question}</TableCell>
                  <TableCell>{row.question.answer}</TableCell>
                  <TableCell>{row.comment}</TableCell>
                  <TableCell>{row.profile.username}</TableCell>
                  <TableCell>
                    <Button
                      variant={
                        row.isUserSubmittedQuestion ? 'outlined' : 'contained'
                      }
                      onClick={() => handleEditClick(row.question)}
                      color={
                        row.isUserSubmittedQuestion ? 'success' : 'primary'
                      }
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => handleAddressedClick(row)}
                    >
                      Addressed
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => handleIgnoreClick(row)}
                    >
                      Ignore
                    </Button>
                  </TableCell>
                </TableRow>
              ) : null
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default InaccuraciesReport;
