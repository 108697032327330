import React from 'react';
import { Button } from '@mui/material';
import { FcGoogle } from 'react-icons/fc';

type PropTypes = {
  text: string;
  handleButtonClick: () => void;
};

export const ButtonGoogleAuth = ({ text, handleButtonClick }: PropTypes) => {
  return (
    <Button
      variant="outlined"
      onClick={handleButtonClick}
      fullWidth
      startIcon={<FcGoogle />}
    >
      {text}
    </Button>
  );
};
