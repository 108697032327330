import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { UserArenaAchievement } from '../../../profile/ArenaProfile/types';
import { useGameContext } from '../../../../contexts/GameProvider';
import useFetchArenaAchievements from '../../../profile/ArenaProfile/hooks/useFetchArenaAchievements';
import { getRarityIcon } from '../../../profile/ArenaProfile/ArenaAchievements/AchievementCard';

type Props = {
  arenaAchievementsThisGame: UserArenaAchievement[];
  open: boolean;
  onClose: () => void;
};

const ArenaAchievementsDialog = ({
  arenaAchievementsThisGame,
  open,
  onClose,
}: Props) => {
  const { arenaAchievements } = useGameContext();

  const { data: arenaAchievementsData } = useFetchArenaAchievements(
    !!arenaAchievements
  );

  const arenaAchievementsList = arenaAchievements || arenaAchievementsData;

  const getAchievementDetails = (achievement: string) => {
    return arenaAchievementsList?.find(
      (arenaAchievement) => arenaAchievement.achievement === achievement
    );
  };

  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>You Earned Achievements!</DialogTitle>
      <DialogContent>
        {arenaAchievementsList ? (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                {arenaAchievementsThisGame.map((achievement) => {
                  const achievementDetails = getAchievementDetails(
                    achievement.achievement
                  );

                  return (
                    <TableRow>
                      <TableCell style={{ padding: 0 }}>
                        <Stack
                          direction={'row'}
                          spacing={2}
                          position={'relative'}
                        >
                          <Box
                            component="img"
                            src={achievementDetails?.imageUrl}
                            alt="image"
                            sx={{
                              width: 80,
                              height: 80,
                              objectFit: 'contain',
                            }}
                          />

                          <Stack spacing={2} p={1}>
                            <Typography
                              variant="h6"
                              color="secondary"
                              fontWeight={600}
                            >
                              {achievementDetails?.title}
                            </Typography>
                            <Typography variant="body2">
                              {achievementDetails?.condition}
                            </Typography>
                          </Stack>

                          <Tooltip
                            title={achievementDetails?.rarity.toUpperCase()}
                          >
                            <Box
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                right: 20,
                                transform: 'translateY(-50%)',
                              }}
                            >
                              {getRarityIcon(achievementDetails.rarity, true)}
                            </Box>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArenaAchievementsDialog;
