import { useQuery } from '@tanstack/react-query';
import { getAllAchievements } from '../../../api/apiServices';

const useGetAllAchievements = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['allAchievements'],
    queryFn: getAllAchievements,
  });

  return {
    refetch,
    isLoading,
    data,
  };
};

export default useGetAllAchievements;
