import React from 'react';
import './RevealAnswerAnimation.scss';

export const RevealAnswerAnimation = () => {
  return (
    <div className="reveal-text">
      {/* <span className="reveal-text-words">C</span>
      <span className="reveal-text-words">L</span>
      <span className="reveal-text-words">I</span>
      <span className="reveal-text-words">C</span>
      <span className="reveal-text-words">K</span>
      <span> </span>
      <span className="reveal-text-words">T</span>
      <span className="reveal-text-words">O</span>
      <span> </span> */}
      <span className="reveal-text-words">R</span>
      <span className="reveal-text-words">E</span>
      <span className="reveal-text-words">V</span>
      <span className="reveal-text-words">E</span>
      <span className="reveal-text-words">A</span>
      <span className="reveal-text-words">L</span>
    </div>
  );
};
