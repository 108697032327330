import React, { useEffect, useState } from 'react';
import { Word } from './Word';
import './styles.css';
import {
  Box,
  Fade,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { ProperNameShortAnswer } from '../../../../types/question';

type PropTypes = {
  answer?: string[][];
  correctAnswer: boolean;
  displaySize: 'sm' | 'md' | 'lg';
  fullQuestion?: string;
  mathFormula?: string;
  properNameShortAnswer?: ProperNameShortAnswer;
  question: string;
  questionImgUrl?: string;
  questionIndex: number;
  spectate?: boolean;
  timerLimitReached: boolean;
  unrevealedIndex?: number[];
};

export const QuestionAnswer = ({
  answer,
  correctAnswer,
  displaySize,
  fullQuestion,
  mathFormula,
  properNameShortAnswer,
  question,
  questionImgUrl,
  questionIndex,
  spectate,
  timerLimitReached,
  unrevealedIndex,
}: PropTypes) => {
  const [entireAnswerDisplayed, setEntireAnswerDisplayed] = useState(false);
  const [hideImage, setHideImage] = useState(false);

  useEffect(() => {
    if (!answer) return;
    for (let i = 0; i < answer.length; i++) {
      if (answer[i].includes('')) {
        setEntireAnswerDisplayed(false);
        return;
      }
    }
    setEntireAnswerDisplayed(true);
  }, [answer]);

  useEffect(() => {
    if (entireAnswerDisplayed) {
      setTimeout(() => {
        setHideImage(true);
      }, 4000);
    }
  }, [entireAnswerDisplayed]);

  useEffect(() => {
    setHideImage(false);
  }, [questionIndex]);

  const theme = useTheme();
  const xsBreakpoint = useMediaQuery(theme.breakpoints.only('xs'));

  const underline = (wordIndex: number) => {
    if (!properNameShortAnswer || !answer) return false;
    if (properNameShortAnswer === 'last word') {
      if (wordIndex === answer.length - 1) return true;
    }
    if (properNameShortAnswer === 'last two words') {
      if (wordIndex === answer.length - 1 || wordIndex === answer.length - 2)
        return true;
    }
    if (properNameShortAnswer === 'first word') {
      if (wordIndex === 0) return true;
    }
    return false;
  };

  const displayQuestion = (question: string, fullQuestion?: string) => {
    if (!question && !fullQuestion) return <></>;
    const questionWords = fullQuestion
      ? fullQuestion.split(' ')
      : question.split(' ');

    let words = '';
    const elements = [];
    let isBold = false;

    for (const word of questionWords) {
      if (word === '<math-formula>') {
        if (words) {
          elements.push({
            type: isBold ? 'bold' : 'text',
            content: words.trim(),
          });
          words = '';
        }
        elements.push({ type: 'math-formula' });
      } else if (word === '<image>') {
        if (words) {
          elements.push({
            type: isBold ? 'bold' : 'text',
            content: words.trim(),
          });
          words = '';
        }
        elements.push({ type: 'image' });
      } else if (word.startsWith('|') && word.endsWith('|')) {
        if (words) {
          elements.push({
            type: isBold ? 'bold' : 'text',
            content: words.trim(),
          });
          words = '';
        }
        elements.push({ type: 'bold', content: word.slice(1, -1).trim() });
        isBold = false;
      } else if (word.startsWith('|')) {
        if (words) {
          elements.push({
            type: isBold ? 'bold' : 'text',
            content: words.trim(),
          });
          words = '';
        }
        words += ' ' + word.slice(1);
        isBold = true;
      } else if (word.endsWith('|')) {
        words += ' ' + word.slice(0, -1);
        elements.push({ type: 'bold', content: words.trim() });
        words = '';
        isBold = false;
      } else {
        words += ' ' + word;
      }
    }

    if (words) {
      elements.push({ type: isBold ? 'bold' : 'text', content: words.trim() });
    }

    return (
      <>
        {elements.map((e, i) => {
          if (e.type === 'math-formula' && mathFormula) {
            return (
              <MathJax key={i} style={{ display: 'inline', fontSize: 28 }}>
                {`\\(${mathFormula}\\)`}
              </MathJax>
            );
          } else if (e.type === 'image' && questionImgUrl) {
            return (
              <Box
                key={i}
                component="img"
                src={questionImgUrl}
                alt="Question image"
              />
            );
          } else if (e.type === 'bold') {
            return (
              <Box
                key={i}
                component="span"
                style={{
                  fontWeight: 'bold',
                  color: theme.palette.dailies.main,
                }}
              >
                {' '}
                {e.content}{' '}
              </Box>
            );
          } else {
            return (
              <Box key={i} component="span">
                {e.content}
              </Box>
            );
          }
        })}
      </>
    );
  };

  return (
    <MathJaxContext>
      <Stack
        direction="row"
        pt={4}
        spacing={questionImgUrl ? 2 : 0}
        justifyContent={questionImgUrl ? 'left' : 'center'}
      >
        {questionImgUrl ? (
          <Box>
            <Fade in={!hideImage} timeout={{ enter: 1500, exit: 500 }}>
              <Box
                component="img"
                src={questionImgUrl}
                alt="image"
                sx={{
                  height: '250px',
                  width: '250px',
                  objectFit: 'cover',
                  filter: 'grayscale(100%)',
                  borderRadius: '5px',
                }}
              />
            </Fade>
          </Box>
        ) : null}
        <Stack spacing={4}>
          {xsBreakpoint ? (
            /* Question on Mobile */
            <Typography textAlign="center" marginY="20px" paddingX={1}>
              {displayQuestion(question, fullQuestion)}
            </Typography>
          ) : (
            /* Question on Desktop */
            <Typography
              textAlign="center"
              variant={
                displaySize === 'sm'
                  ? 'body1'
                  : displaySize === 'md'
                  ? 'h6'
                  : 'h5'
              }
              marginY="20px"
              lineHeight={2}
            >
              {displayQuestion(question, fullQuestion)}
            </Typography>
          )}
          <Stack
            direction="row"
            justifyContent="center"
            spacing={3}
            sx={{ flexWrap: 'wrap', zIndex: 10 }}
          >
            {answer
              ? answer.map((word, wordIndex) => (
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="center"
                    flexWrap={{ xs: 'wrap', sm: 'initial' }}
                    className={`answer-fade-in ${
                      underline(wordIndex) ? '' : ''
                    }`}
                    key={`word-${wordIndex}`}
                  >
                    <Word
                      correctAnswer={correctAnswer}
                      displaySize={displaySize}
                      entireAnswerDisplayed={entireAnswerDisplayed}
                      spectate={spectate}
                      timerLimitReached={timerLimitReached}
                      underline={underline(wordIndex)}
                      unrevealedIndex={unrevealedIndex}
                      word={word}
                      wordIndex={wordIndex}
                    />
                  </Stack>
                ))
              : null}
          </Stack>
        </Stack>
      </Stack>
    </MathJaxContext>
  );
};
