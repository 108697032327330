import React from 'react';
import { useLocation } from 'react-router-dom';
import { ProfileSubNav } from './ProfileSubNav';
import { Container } from '@mui/material';
import { LeagueSubNav } from './LeagueSubNav';

type PropTypes = {
  isAdmin: boolean;
  profileId: string;
};

export const SubNav = ({ isAdmin, profileId }: PropTypes) => {
  const location = useLocation();

  return (
    <Container>
      {location.pathname.includes('/stats') ? (
        <LeagueSubNav isAdmin={isAdmin} />
      ) : // ) : location.pathname.includes('/profile') ? (
      //   <ProfileSubNav profileId={profileId} />
      null}
    </Container>
  );
};
