import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useGameloopSocketContext,
  useInputSocketContext,
  useSocketContext,
  useSpectateSocketContext,
} from './SocketProvider';
import { LoggedInUser } from '../types/user';
import { useProfileContext } from './ProfileProvider';
import { createContext, useContextSelector } from 'use-context-selector';
import { gameInProgress } from '../utils/loggedInUser';
import { sortUsers } from '../components/bottom-nav/utils';

interface LoggedInUserContextType {
  loggedInUser: LoggedInUser | null;
  loggedInUsers: LoggedInUser[] | null;
  refetchLoggedInUser: (profileId: string) => void;
  refreshBrowser: boolean;
}

export const LoggedInUserContext = createContext<LoggedInUserContextType>(null);

type LoggedInUserProviderProps = {
  children: ReactNode;
};

export const LoggedInUserProvider: FC<LoggedInUserProviderProps> = ({
  children,
}) => {
  const { disconnectSocket: disconnectManagerSocket, socket: managerSocket } =
    useSocketContext();
  const { disconnectInputSocket, inputSocket } = useInputSocketContext();
  const { disconnectGameloopSocket, gameloopSocket } =
    useGameloopSocketContext();
  const { disconnectSpectateSocket, spectateSocket } =
    useSpectateSocketContext();
  const { profile } = useProfileContext();
  const [loggedInUser, setLoggedInUser] = useState<LoggedInUser>(null); // this is yourself
  const [loggedInUsers, setLoggedInUsers] = useState<LoggedInUser[]>([]);
  const [refreshBrowser, setRefreshBrowser] = useState(false);
  const loggedInUserRef = useRef<LoggedInUser>(); // this is used only for the disconnect logic

  const updateLoggedInUsers = useCallback(
    (updatedUser: LoggedInUser) => {
      if (!updatedUser || !loggedInUser) return;

      // Disconnect socket if the updated user is the current user and is offline
      if (
        updatedUser.profileId === loggedInUser.profileId &&
        !updatedUser.online
      ) {
        disconnectManagerSocket();
        disconnectInputSocket();
        disconnectGameloopSocket();
        disconnectSpectateSocket();
      }

      setLoggedInUsers((prevUsers) => {
        // Filter out the updated user and add them back in
        return sortUsers(
          prevUsers
            .filter((user) => user.profileId !== updatedUser.profileId)
            .concat(updatedUser)
        );
      });

      if (updatedUser.profileId === loggedInUser?.profileId) {
        setLoggedInUser(updatedUser);
      }
    },
    [
      disconnectGameloopSocket,
      disconnectInputSocket,
      disconnectManagerSocket,
      disconnectSpectateSocket,
      loggedInUser,
    ]
  );

  useEffect(() => {
    if (profile) {
      setRefreshBrowser(false);
    }
  }, [profile]);

  useEffect(() => {
    if (!loggedInUser) return;
    loggedInUserRef.current = loggedInUser;
  }, [loggedInUser]);

  useEffect(() => {
    if (!managerSocket) return;

    managerSocket.on('loggedInUser', setLoggedInUser);
    managerSocket.on('loggedInUsers', setLoggedInUsers);
    managerSocket.on('loggedInUserUpdate', updateLoggedInUsers);
    managerSocket.on('disconnect', updateDisconnect);

    return () => {
      managerSocket.off('loggedInUser', setLoggedInUser);
      managerSocket.off('loggedInUsers', setLoggedInUsers);
      managerSocket.off('loggedInUserUpdate', updateLoggedInUsers);
      managerSocket.off('disconnect', updateDisconnect);
    };
  }, [managerSocket, updateLoggedInUsers]);

  const refetchLoggedInUser = (profileId: string) => {
    managerSocket.emit('getLoggedInUser', { profileId });
  };

  const updateDisconnect = () => {
    if (!loggedInUserRef?.current) return;
    // sockets are set to reconnect automatically but there are a
    // few cases where we do not want that to happen because a lot of development is occurring
    // and its convenient to have the user just refresh the app when they inevitably disconnect
    // - if a user is idle, do not reconnect them since they are not active and wont mind refreshing
    // - if a user is in an offline state but the socket is connected for some reason, disconnect fully
    if (
      loggedInUserRef?.current?.isIdle ||
      !loggedInUserRef?.current?.online ||
      (!gameInProgress(loggedInUser) &&
        !loggedInUserRef?.current?.spectateInProgress)
    ) {
      managerSocket.disconnect();
      inputSocket.disconnect();
      gameloopSocket.disconnect();
      spectateSocket.disconnect();

      if (!profile) return;

      setRefreshBrowser(true);
    }
  };

  return (
    <LoggedInUserContext.Provider
      value={{
        loggedInUser,
        loggedInUsers,
        refreshBrowser,
        refetchLoggedInUser,
      }}
    >
      {children}
    </LoggedInUserContext.Provider>
  );
};

export const useLoggedInUserContext = () => {
  const loggedInUser = useContextSelector(
    LoggedInUserContext,
    (context) => context?.loggedInUser
  );

  const loggedInUsers = useContextSelector(
    LoggedInUserContext,
    (context) => context?.loggedInUsers
  );

  const refreshBrowser = useContextSelector(
    LoggedInUserContext,
    (context) => context?.refreshBrowser
  );

  const refetchLoggedInUser = useContextSelector(
    LoggedInUserContext,
    (context) => context?.refetchLoggedInUser
  );

  return {
    loggedInUser,
    loggedInUsers,
    refreshBrowser,
    refetchLoggedInUser,
  };
};
