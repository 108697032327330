import { useMutation } from '@tanstack/react-query';
import { createCheckoutSession } from '../../api/apiServices';

const useCreateCheckoutSession = (handleOnSuccess: (data) => void) => {
  const { mutate, error } = useMutation({
    mutationFn: createCheckoutSession,
    onSuccess: handleOnSuccess,
  });

  return {
    error,
    createCheckoutSession: mutate,
  };
};

export default useCreateCheckoutSession;
