import { useMutation } from '@tanstack/react-query';
import { updateJoinArenaTeam } from '../../../../api/apiServices';

const useUpdateJoinArenaTeam = (handleOnSuccess: () => void) => {
  const { mutate } = useMutation({
    mutationFn: updateJoinArenaTeam,
    onSuccess: handleOnSuccess,
  });

  return {
    updateJoinArenaTeam: mutate,
  };
};

export default useUpdateJoinArenaTeam;
