import React, { useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import './styles.css';
import { Inputter } from './Inputter';
import { Typography, useTheme } from '@mui/material';

import { Mode } from '../../../../types/game';
import { Team, Penalty, Teams } from '../../../../types/game';
import { Player } from '../../../../types/player';
import { collapseWords } from './utils';

const EXCLUDED_ARTICLES = ['THE', 'AN', 'A'];

let playerLockTimeout: any;
let preventInputFailsafeTimeout: any;

type PropTypes = {
  answer?: string[][];
  category: string;
  clearAfterPenalty?: boolean;
  correctAnswer: boolean;
  focusInput?: boolean;
  forceReconnectSocketLiveInput: () => void;
  fuzzy?: Record<string, number>;
  gameId: string;
  mode: Mode;
  myTeam?: Team;
  onPause?: () => void;
  penalty?: Penalty;
  playerSelf: Player;
  questionIndex: number;
  inputSocket?: Socket;
  spectateSocket?: Socket;
  teams?: Teams;
  timerLimitReached: boolean;
  spectate: boolean;
};

export const PlayerInput = ({
  answer,
  category,
  clearAfterPenalty,
  correctAnswer,
  focusInput,
  forceReconnectSocketLiveInput,
  fuzzy,
  gameId,
  mode,
  myTeam,
  onPause,
  penalty,
  playerSelf,
  questionIndex,
  inputSocket,
  spectateSocket,
  teams,
  timerLimitReached,
  spectate,
}: PropTypes) => {
  const theme = useTheme();
  const [playerLocked, setPlayerLocked] = useState(false);

  const currentCategory = useRef<string>();
  // span that is used as the answer input
  const inputRef = useRef<HTMLSpanElement>(null);
  // cache the answer for displaying
  const inputTextRef = useRef('');
  const preventInputRef = useRef(false);

  const inputPlaceholder = 'Type your answer here';

  useEffect(() => {
    currentCategory.current = category;
  }, [category]);

  // At the beginning of a new question, clear the input
  // NOTE: clearing the input based on the quesitonIndex is the most reliable dependency
  useEffect(() => {
    preventInputRef.current = false;
    if (inputRef.current) {
      inputRef.current.textContent = '';
    }
    inputTextRef.current = '';
    if (!inputRef.current) return;
    inputRef.current.spellcheck = false;
  }, [questionIndex]);

  useEffect(() => {
    if (!penalty || penalty?.profileId !== playerSelf.profileId) return;
    setPlayerLocked(true);
    if (clearAfterPenalty && inputRef.current) {
      inputRef.current.textContent = '';
    }
    playerLockTimeout = setTimeout(() => {
      setPlayerLocked(false);
    }, 1000);
  }, [penalty]);

  useEffect(() => {
    if (!playerLocked || !answer || !answer.length) return;
    clearTimeout(playerLockTimeout);
    setPlayerLocked(false);
  }, [answer]);

  useEffect(() => {
    if (playerLocked || !inputRef.current) return;
    inputRef.current.focus();
  }, [playerLocked]);

  useEffect(() => {
    preventInputRef.current = false;
    clearTimeout(preventInputFailsafeTimeout);
  }, [playerSelf]);

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, [focusInput]);

  const onEnterKeyPress = () => {
    // preventInputFailsafe();
    // use the inputRef here, suspect that inputText is not as performant
    submitInput(inputRef?.current?.innerText);
  };

  // REVIEW: Add this failsafe for now until we can figure out why
  //  some users are not able to type after being locked out
  const preventInputFailsafe = () => {
    preventInputFailsafeTimeout = setTimeout(() => {
      preventInputRef.current = false;
    }, 100);
  };

  const removeFirstWordArticles = (words: string) => {
    const firstWord = words.substring(0, words.indexOf(' '));
    if (EXCLUDED_ARTICLES.includes(firstWord.toUpperCase())) {
      const withoutFirstWord = words.substring(words.indexOf(' ') + 1);
      return withoutFirstWord;
    }
    return words;
  };

  const submitInput = (input?: string) => {
    if (!input) return;
    const regex = currentCategory.current === 'Math' ? /[^\w\-.]+/g : /[\W_]+/g;
    const cleanedInput = input.replace(regex, '');
    if (!cleanedInput) return;
    preventInputRef.current = true;
    const collapsedInput = collapseWords(
      input,
      currentCategory.current === 'Math'
    );
    sendPlayerInput(playerSelf.profileId, input, collapsedInput);
  };

  const sendPlayerInput = (
    profileId: string,
    input: string,
    collapsedInput: string
  ) => {
    if (!inputSocket || !inputSocket.connected) {
      forceReconnectSocketLiveInput();
      preventInputRef.current = false;
      return;
    }
    if (inputRef.current.textContent !== input) {
      inputRef.current.textContent = input;
      moveCursorToEnd();
    }
    inputTextRef.current = input;
    inputSocket.emit('playerInput', {
      gameId,
      profileId,
      input,
      collapsedInput,
    });
    preventInputRef.current = false;
  };

  const onInputChange = (input: string) => {
    if (!spectateSocket) return;
    if (preventInputRef.current) return;
    if (input.length > 70) return;
    // Set the inputText here to cache the answer for displaying
    spectateSocket.emit('playerInputLive', {
      profileId: playerSelf.profileId,
      gameId,
      input: input,
      team: myTeam ? myTeam.id : undefined,
    });
  };

  const moveCursorToEnd = () => {
    var el: any = inputRef.current;
    const range: any = document.createRange();
    const sel: any = window.getSelection();
    let count_element: number = el.childNodes.length - 1;
    range.setStart(
      el.childNodes[count_element],
      el.childNodes[count_element].length || 1
    );
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
  };

  return (
    <Inputter
      answer={answer}
      fuzzyScore={fuzzy?.[playerSelf.profileId]}
      inputPlaceholder={inputPlaceholder}
      inputRef={inputRef}
      inputText={inputTextRef.current}
      locked={playerLocked}
      mode={mode}
      myTeam={myTeam}
      onEnterKeyPress={onEnterKeyPress}
      onPause={onPause}
      onInputChange={onInputChange}
      player={playerSelf}
      preventInput={preventInputRef.current}
      questionIndex={questionIndex}
      spectateSocket={spectateSocket}
      spectate={spectate}
      teams={teams}
      timerLimitReached={timerLimitReached}
    />
  );
};
