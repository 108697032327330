import { useQuery } from '@tanstack/react-query';
import { fetchPlacesPredictions } from '../../../api/apiServices';

const useFetchPlacesPredictions = (input: string) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['predictions', { input }],
    queryFn: fetchPlacesPredictions,
    enabled: !!input,
  });

  return {
    refetch,
    isLoading,
    predictions: data?.predictions,
  };
};

export default useFetchPlacesPredictions;
