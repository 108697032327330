import { useApi } from '../../../hooks/useApi';

export const useSeedDetails = <T>() => {
  const { data, error, loading, sendRequest } = useApi<T>();

  const fetchSeedDetails = (gameId: string) => {
    sendRequest({
      url: `/match-making/seed-details/${gameId}`,
    });
  };

  return { data, error, loading, fetchSeedDetails };
};
