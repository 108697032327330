import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Dialog,
  DialogContent,
} from '@mui/material';
import React from 'react';
import { TbAtom } from 'react-icons/tb';
import { LABEL_COLORS, MEDAL_COLORS } from '../constants';
import {
  ShinyTypography,
  StyledCenteredTableCell,
  StyledTableCell,
} from './styled';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FlareIcon from '@mui/icons-material/Flare';
import DiamondIcon from '@mui/icons-material/Diamond';
import RocketIcon from '@mui/icons-material/Rocket';

type Props = {
  open: boolean;
  close: () => void;
};

const PunchcardTrophiesDialog = ({ open, close }: Props) => {
  return (
    <Dialog open={open} onClose={close} maxWidth="md">
      <DialogContent>
        <Box>
          <Typography variant="h6" textAlign="center" letterSpacing={2}>
            Trophy Requirements
          </Typography>
          <TableContainer sx={{ maxHeight: 300 }}>
            <Table stickyHeader size="small" aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledCenteredTableCell></StyledCenteredTableCell>
                  <StyledCenteredTableCell>
                    <EmojiEventsIcon
                      sx={{
                        color: MEDAL_COLORS['bronze'],
                        marginBottom: '5px',
                      }}
                    />
                    <ShinyTypography
                      variant="body1"
                      color={MEDAL_COLORS['bronze']}
                    >
                      Bronze
                    </ShinyTypography>
                  </StyledCenteredTableCell>
                  <StyledCenteredTableCell>
                    <EmojiEventsIcon
                      sx={{
                        color: MEDAL_COLORS['silver'],
                        marginBottom: '5px',
                      }}
                    />
                    <ShinyTypography
                      variant="body1"
                      color={MEDAL_COLORS['silver']}
                    >
                      Silver
                    </ShinyTypography>
                  </StyledCenteredTableCell>
                  <StyledCenteredTableCell>
                    <EmojiEventsIcon
                      sx={{
                        color: MEDAL_COLORS['gold'],
                        marginBottom: '5px',
                      }}
                    />
                    <ShinyTypography
                      variant="body1"
                      color={MEDAL_COLORS['gold']}
                    >
                      Gold
                    </ShinyTypography>
                  </StyledCenteredTableCell>
                  <StyledCenteredTableCell>
                    <FlareIcon
                      sx={{
                        color: MEDAL_COLORS['platinum'],
                        marginBottom: '5px',
                      }}
                    />
                    <ShinyTypography
                      variant="body1"
                      color={MEDAL_COLORS['platinum']}
                    >
                      Platinum
                    </ShinyTypography>
                  </StyledCenteredTableCell>
                  <StyledCenteredTableCell>
                    <DiamondIcon
                      sx={{
                        color: MEDAL_COLORS['diamond'],
                        marginBottom: '5px',
                      }}
                    />
                    <ShinyTypography
                      variant="body1"
                      color={MEDAL_COLORS['diamond']}
                    >
                      Diamond
                    </ShinyTypography>
                  </StyledCenteredTableCell>
                  <StyledCenteredTableCell>
                    <RocketIcon sx={{ color: 'red', marginBottom: '5px' }} />
                    <ShinyTypography
                      variant="body1"
                      color={MEDAL_COLORS['unobtainium']}
                    >
                      Unobtainium
                    </ShinyTypography>
                  </StyledCenteredTableCell>
                  <StyledCenteredTableCell>
                    <TbAtom
                      size={'1.5rem'}
                      color={'#cbcbcb'}
                      style={{
                        boxShadow: '1px 1px 15px #FFF, 1px 1px 5px #fffefe',
                        marginBottom: '5px',
                      }}
                    />
                    <ShinyTypography
                      variant="body1"
                      color={MEDAL_COLORS['antimatter']}
                      sx={{
                        fontWeight: 600,
                        textShadow: `1px 1px 10px #fff, 1px 1px 10px #fffefe`,
                      }}
                    >
                      Antimatter
                    </ShinyTypography>
                  </StyledCenteredTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell sx={{ color: LABEL_COLORS['average'] }}>
                    Average
                  </StyledTableCell>
                  <StyledTableCell>+40</StyledTableCell>
                  <StyledTableCell>+30</StyledTableCell>
                  <StyledTableCell>+20</StyledTableCell>
                  <StyledTableCell>0</StyledTableCell>
                  <StyledTableCell>-20</StyledTableCell>
                  <StyledTableCell>-40</StyledTableCell>
                  <StyledTableCell>-50</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell sx={{ color: LABEL_COLORS['best'] }}>
                    Best
                  </StyledTableCell>
                  <StyledTableCell>+20</StyledTableCell>
                  <StyledTableCell>+10</StyledTableCell>
                  <StyledTableCell>0</StyledTableCell>
                  <StyledTableCell>-20</StyledTableCell>
                  <StyledTableCell>-40</StyledTableCell>
                  <StyledTableCell>-60</StyledTableCell>
                  <StyledTableCell>-70</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell sx={{ color: LABEL_COLORS['gamesPlayed'] }}>
                    Games Played
                  </StyledTableCell>
                  <StyledTableCell>25</StyledTableCell>
                  <StyledTableCell>50</StyledTableCell>
                  <StyledTableCell>100</StyledTableCell>
                  <StyledTableCell>150</StyledTableCell>
                  <StyledTableCell>250</StyledTableCell>
                  <StyledTableCell>500</StyledTableCell>
                  <StyledTableCell>1000</StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography fontSize={10} textAlign="right" mt={1}>
            * Avg requires min. 10 games and is a rolling average of past 10
            games
          </Typography>
          <Typography fontSize={10} textAlign="right" mt={1}>
            * Avg trophy will display your historical best 10 game average.
            Hover the trophy.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PunchcardTrophiesDialog;
