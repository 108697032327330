const apiHost = process.env.REACT_APP_API;

export async function fetchGet(url = '') {
  const urlWithApiHost = apiHost + url;

  const response = await fetch(urlWithApiHost, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'include', // include, *same-origin, omit
    // headers: {
    //   'Content-Type': 'application/json',
    //   // 'Content-Type': 'application/x-www-form-urlencoded',
    // },
  });
  if (response.status !== 200) throw response;
  return response.json();
}

export async function fetchPost(url: string, data = {}) {
  const urlWithApiHost = apiHost + url;
  const response = await fetch(urlWithApiHost, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'include', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (response.status !== 200) throw response;
  return response.json();
}

export async function fetchExternalGet(url: string, headers?: any) {
  const response = await fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    credentials: 'include', // include, *same-origin, omit
    headers: headers || {},
  });
  if (response.status !== 200) throw response;
  return response.json();
}
