import React from 'react';
import {
  Avatar,
  Box,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Player } from '../../../../../types/player';
import { getAvatarSrc } from '../../../../../api/cloudinary';
import { addPlusToPositiveScores } from '../../../../../utils/game';

const AVATAR_HEIGHT = 64;
const AVATAR_WIDTH = 64;
const AVATAR_DIVIDER_SPACING = 2;

type Props = {
  player: Player;
  playerInfoDisplayPosition: 'left' | 'right';
  timerLimitReached: boolean;
};

export const PlayerScoreHeader = ({
  player,
  playerInfoDisplayPosition,
  timerLimitReached,
}: Props) => {
  const { palette } = useTheme();

  if (!player) {
    return <Box></Box>;
  }

  const {
    avatarPublicId,
    avatarUrl,
    correctAnswer,
    currentScore,
    totalPenalty,
  } = player;

  const boxShadow = () => {
    // if (penalty?.penaltyTimestamp) return 'warning.main';

    if (!correctAnswer && !timerLimitReached) return 'none';

    return correctAnswer
      ? `${palette.success.main} 0px 0px 20px 5px, ${palette.success.main} 0px 0px 5px 0px, ${palette.success.main} 0px 0px 5px 0px`
      : `${palette.error.light} 0px 0px 10px 0px, ${palette.error.light} 0px 0px 0px 0px, ${palette.error.light} 0px 0px 0px 0px`;
  };

  const opaqueBackground = () => {
    // if (penalty?.penaltyTimestamp) return palette.warning.main;
    if (correctAnswer) return `${palette.success.main}e0`;
    return `${palette.error.main}e0`;
  };

  const showScore = () =>
    (correctAnswer && currentScore !== undefined) ||
    (!correctAnswer && timerLimitReached);

  return (
    <Box
      className="PlayerScoreHeader"
      height={'100%'}
      mt={AVATAR_DIVIDER_SPACING}
    >
      <Box
        className="PlayerAvatarBox"
        display={'flex'}
        justifyContent={'center'}
        width={'100%'}
      >
        <Box height={AVATAR_HEIGHT}>
          <Avatar
            src={getAvatarSrc(avatarPublicId, avatarUrl, 200, 200)}
            variant={'circular'}
            sx={{
              height: AVATAR_HEIGHT,
              width: AVATAR_WIDTH,
              objectFit: 'cover',
              zIndex: 1,
              boxShadow: boxShadow,
            }}
          />

          {showScore() ? (
            // <ScoreAvatar
            <Avatar
              variant={'circular'}
              sx={{
                height: AVATAR_HEIGHT,
                width: AVATAR_WIDTH,
                zIndex: 1,
                position: 'relative',
                right: 0,
                bottom: AVATAR_HEIGHT,
                backgroundColor: opaqueBackground,
              }}
            >
              <Typography variant="h4">
                {addPlusToPositiveScores(currentScore)}
              </Typography>
            </Avatar>
          ) : null}
        </Box>
      </Box>

      <Divider
        sx={{
          mt: AVATAR_DIVIDER_SPACING,
          '&::before': { borderTop: 'thin solid rgb(248 238 149 / 40%)' },
          '&::after': { borderTop: 'thin solid rgb(248 238 149 / 40%)' },
        }}
      >
        <Typography color={'secondary.main'}>{player.username}</Typography>
      </Divider>

      {/* Score & Penalty */}
      <Stack
        className="ScorePenaltyStack"
        direction={'row'}
        width={'100%'}
        alignItems={'flex-start'}
        justifyContent={'center'}
        spacing={8}
      >
        {/* Score */}
        <Stack className="ScoreStack" alignItems={'center'}>
          <Typography variant="overline">Score</Typography>
          <Typography
            fontSize={24}
            sx={{
              textShadow: '0px 0px 12px #ffffff, 0px 0px 12px #f8ee95',
            }}
          >
            {addPlusToPositiveScores(player.parScore)}
          </Typography>
        </Stack>

        {/* Penalty */}
        <Stack className="PenaltyStack" alignItems={'center'}>
          <Typography variant="overline">Penalty</Typography>
          {!totalPenalty ? (
            <Typography fontSize={24} color={'gray'}>
              0
            </Typography>
          ) : (
            <Typography
              fontSize={21}
              color={'warning.contrastText'}
              sx={{
                backgroundColor: palette.warning.main,
                borderRadius: 16,
                height: 32,
                width: 32,
                textAlign: 'center',
              }}
            >
              {totalPenalty}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
