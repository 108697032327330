import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useLogin } from '../../../hooks/auth/useLogin';
import { useGoogleLogin } from '../../../hooks/auth/useGoogleLogin';
import { useSignup } from '../../../hooks/auth/useSignup';
import { useForgotPassword } from '../../../hooks/auth/useForgotPassword';
import { LoginPresenter } from './LoginPresenter';
import { useLocation } from 'react-router-dom';
import { useResendEmailVerification } from '../../../hooks/auth/useResendEmailVerification';

type LocationState = {
  resendVerficationEmail: string;
  showResendVerification: boolean;
};

export const Login = () => {
  const location = useLocation();
  const locationState = location.state as LocationState;

  const { error: loginError, login } = useLogin();
  const { googleLogin, error: googleError } = useGoogleLogin();
  const { data: signupData, error: signupError, signup } = useSignup();
  const {
    data: forgotPasswordData,
    error: forgotPasswordError,
    forgotPassword,
  } = useForgotPassword();
  const {
    data: resendVerificationEmailData,
    error: resendVerificationEmailError,
    resendEmailVerification,
  } = useResendEmailVerification();
  const [authType, setAuthType] = useState<
    'login' | 'signUp' | 'forgotPassword' | 'resendVerification'
  >('login');
  const [email, setEmail] = useState('');
  const emailRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState('');
  const passwordRef = useRef<HTMLInputElement>(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [showVerifyEmailMessage, setShowVerifyEmailMessage] = useState(false);
  const [showResetPasswordMessage, setShowResetPasswordMessage] =
    useState(false);
  const [showResendVerificationMessage, setShowResendVerificationMessage] =
    useState(false);

  useEffect(() => {
    if (locationState?.showResendVerification) {
      setEmail(locationState.resendVerficationEmail);
      setAuthType('resendVerification');
      setShowResendVerificationMessage(true);
    }
  }, [locationState]);

  useEffect(() => {
    if (authType === 'login' && loginError) {
      setPasswordErrorMessage(loginError.message);
      return;
    }
    if (authType === 'signUp') {
      setPassword('');
      setTimeout(() => {
        emailRef.current.focus();
      }, 0);
    }
  }, [authType, loginError]);

  useEffect(() => {
    if (signupData && !signupError) {
      setShowVerifyEmailMessage(true);
    } else if (signupError) {
      setEmailErrorMessage(signupError.message);
    }
  }, [signupData, signupError]);

  useEffect(() => {
    if (forgotPasswordData && !forgotPasswordError) {
      setShowResetPasswordMessage(true);
    }
  }, [forgotPasswordData, forgotPasswordError]);

  useEffect(() => {
    if (resendVerificationEmailData && !resendVerificationEmailError) {
      setShowResendVerificationMessage(false);
      setShowVerifyEmailMessage(true);
    }
  }, [resendVerificationEmailData, resendVerificationEmailError]);

  const handleGoogleLogin = googleLogin;

  const handleLoginClick = async () => {
    if (!password) {
      setPasswordErrorMessage('Please enter a password.');
    } else {
      setPasswordErrorMessage('');
      login(email, password);
    }
  };

  const handleSignUpClick = async () => {
    if (!password) {
      setPasswordErrorMessage('Please enter a password.');
    } else if (password.length < 8) {
      setPasswordErrorMessage(
        'Please provide a password that is at least 8 characters.'
      );
    } else {
      setPasswordErrorMessage('');
      signup(email, password);
    }
  };

  const handleResetPasswordClick = async () => {
    if (!email) {
      setEmailErrorMessage('Please enter your email address.');
      return;
    }
    forgotPassword(email);
  };

  const handleResendVerificationEmailClick = async () => {
    if (!email) {
      setEmailErrorMessage('Email required');
      return;
    }
    resendEmailVerification(email);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (authType === 'login') {
      handleLoginClick();
    } else if (authType === 'forgotPassword') {
      handleResetPasswordClick();
    } else if (authType === 'resendVerification') {
      handleResendVerificationEmailClick();
    } else if (authType === 'signUp') {
      handleSignUpClick();
    }
  };

  return (
    <LoginPresenter
      authType={authType}
      email={email}
      emailRef={emailRef}
      password={password}
      passwordRef={passwordRef}
      emailErrorMessage={emailErrorMessage}
      passwordErrorMessage={passwordErrorMessage}
      showVerifyEmailMessage={showVerifyEmailMessage}
      showResetPasswordMessage={showResetPasswordMessage}
      showResendVerificationMessage={showResendVerificationMessage}
      handleGoogleLogin={handleGoogleLogin}
      handleSignUpClick={handleSignUpClick}
      handleSubmit={handleSubmit}
      setAuthType={setAuthType}
      setEmail={setEmail}
      setPassword={setPassword}
      setEmailErrorMessage={setEmailErrorMessage}
      setPasswordErrorMessage={setPasswordErrorMessage}
      googleError={googleError?.message}
    />
  );
};
