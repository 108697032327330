import { useCallback, useEffect, useRef, useState } from 'react';
import { cloneDeep, mean, round, set } from 'lodash';
import { useProfileContext } from '../../../contexts/ProfileProvider';
import { useInputSocketContext } from '../../../contexts/SocketProvider';
import { Teams, Team, Penalty, Assists } from '../../../types/game';
import {
  ArenaTeamLiveRoundScores,
  DetailedPlayer,
  Player,
  RoundScore,
} from '../../../types/player';
import { getFuzzyPenalty } from '../game/utils';

const TIME_LIMIT = 20;

export const useGameStatesInputServer = (
  gameId: string,
  questionIndex?: number
) => {
  const questionIndexRef = useRef<number>();
  questionIndexRef.current = questionIndex;

  const { profile } = useProfileContext();
  const { inputSocket } = useInputSocketContext();
  const [players, setPlayers] = useState<Record<string, Player>>();
  const [playerSelf, setPlayerSelf] = useState<Player>();
  const [playerThem, setPlayerThem] = useState<Player>();
  const [teams, setTeams] = useState<Teams>();
  const [myTeamId, setMyTeamId] = useState<string>();
  const [myTeam, setMyTeam] = useState<Team>();
  const [opponentTeam, setOpponentTeam] = useState<Team>();
  const [fuzzy, setFuzzy] = useState<Record<string, number>>();
  const [penalty, setPenalty] = useState<Penalty>();
  const [assists, setAssists] = useState<Assists>();
  const [freezeGameClock, setFreezeGameClock] = useState(false);
  const [roundScores, setRoundScores] = useState<Record<number, RoundScore>>();
  // const [topScore, setTopScore] = useState<number>();
  // const [averageScore, setAverageScore] = useState<number>();
  const [arenaTeamLiveRoundScores, setArenaTeamLiveRoundScores] =
    useState<ArenaTeamLiveRoundScores>();
  const [teamBestBalls, setTeamBestBalls] = useState<Record<string, Player[]>>(
    {}
  );

  const retryIntervalRef = useRef(null);

  useEffect(() => {
    if (!profile || !players) return;
    setPlayerSelf(players[profile._id]);

    if (Object.keys(players).length === 2) {
      setPlayerThem(
        Object.values(players).find((p) => p.profileId !== profile._id)
      );
    }
  }, [profile, players]);

  useEffect(() => {
    if (!teams || !profile) return;
    const myTeamId = teams.teamA.members.includes(profile._id)
      ? 'teamA'
      : 'teamB';
    setMyTeamId(myTeamId);
    setMyTeam(teams[myTeamId]);
    const otherTeamId = myTeamId === 'teamA' ? 'teamB' : 'teamA';
    setOpponentTeam(teams[otherTeamId]);
  }, [teams, profile]);

  useEffect(() => {
    if (!inputSocket) return;

    inputSocket.on('teams', setTeams);
    inputSocket.on('players', setPlayers);
    inputSocket.on('playerUpdate', updatePlayer);
    inputSocket.on('fuzzy', updateFuzzy);
    inputSocket.on('penalty', setPenalty);
    inputSocket.on('assists', setAssists);
    inputSocket.on('arenaTeamsLiveRoundScores', setArenaTeamLiveRoundScores);
    inputSocket.on(
      'arenaTeamsLiveRoundScoresUpdate',
      updateArenaTeamRoundScores
    );

    return () => {
      inputSocket.off('teams', setTeams);
      inputSocket.off('players', setPlayers);
      inputSocket.off('playerUpdate', updatePlayer);
      inputSocket.off('fuzzy', updateFuzzy);
      inputSocket.off('penalty', setPenalty);
      inputSocket.off('assists', setAssists);
      inputSocket.off('arenaTeamsLiveRoundScores', setArenaTeamLiveRoundScores);
      inputSocket.off(
        'arenaTeamsLiveRoundScoresUpdate',
        updateArenaTeamRoundScores
      );
    };
  }, [inputSocket]);

  // pretty heavy retry logic to handle race condition issues with questionmetada, probably should be refactored
  useEffect(() => {
    if (players?.[profile?._id]) {
      if (retryIntervalRef.current) {
        clearInterval(retryIntervalRef.current);
        retryIntervalRef.current = null;
      }
      return;
    }

    if (!retryIntervalRef.current) {
      retryIntervalRef.current = setInterval(() => {
        requestPlayers();
      }, 1000);
    }

    return () => {
      if (retryIntervalRef.current) {
        clearInterval(retryIntervalRef.current);
      }
    };
  }, [players]);

  useEffect(() => {
    if (!roundScores) {
      // on load, input server send roundscores
      const detailedPlayers = players as Record<string, DetailedPlayer>;
      const roundScores = detailedPlayers?.[profile?._id]?.roundScores;
      setRoundScores(roundScores);
    }

    // updateAverageScore(players);

    if (players) {
      clearInterval(retryIntervalRef.current);
    }
  }, [players, profile?._id, roundScores]);

  useEffect(() => {
    setFreezeGameClock(playerSelf?.correctAnswer || myTeam?.correctAnswer);
  }, [playerSelf, myTeam]);

  const updatePlayer = (player: Player) => {
    if (!player) return;
    setPlayers((prev) => ({
      ...prev,
      [player.profileId]: player,
    }));

    if (player.profileId === profile._id) {
      // needed for displaying My Stats mini scorecard
      setRoundScores((prev: any) => ({
        ...prev,
        [questionIndexRef.current]: {
          score: player.currentScore,
          timerLimitReached: player.timerLimitReached,
        },
      }));
    }

    // // needed for displaying Top and Avg score in leaderboard
    // setTopScore((prev) => {
    //   const totalScore =
    //     player.currentScore + getFuzzyPenalty(player.fuzzyScore);

    //   if (prev === undefined || totalScore < prev) {
    //     return totalScore;
    //   }
    //   return prev;
    // });

    if (!player.arenaTeamId || player.currentScore === null) return;
    updateTeamBestBallPlayer(player);
  };

  const updateTeamBestBallPlayer = (player: Player) => {
    const totalScore = player.currentScore + getFuzzyPenalty(player.fuzzyScore);

    setTeamBestBalls((prev) => {
      const arenaTeamId = player.arenaTeamId;
      const currentBestBallPlayer = prev[arenaTeamId];
      if (!currentBestBallPlayer) {
        return {
          ...prev,
          [arenaTeamId]: [player],
        };
      }
      const currentTotalScore =
        currentBestBallPlayer[0].currentScore +
        getFuzzyPenalty(currentBestBallPlayer[0].fuzzyScore);
      if (totalScore === currentTotalScore) {
        return {
          ...prev,
          [arenaTeamId]: prev[arenaTeamId].concat(player),
        };
      }
      return prev;
    });
  };

  const updateArenaTeamRoundScores = (teamTopScore: {
    arenaTeamId: string;
    questionIndex: number;
    totalScore: number;
  }) => {
    if (!teamTopScore) return;
    const { arenaTeamId, questionIndex, totalScore } = teamTopScore;
    console.log('updateArenaTeamRoundScores', teamTopScore);
    setArenaTeamLiveRoundScores((prev) => ({
      ...prev,
      [arenaTeamId]: {
        ...prev?.[arenaTeamId],
        [questionIndex]: totalScore,
      },
    }));
  };

  // const updateAverageScore = (players: Record<string, Player>) => {
  //   if (!players) return;
  //   const filteredPlayers = Object.values(players).filter(
  //     (p) => p.currentScore !== null || p.timerLimitReached
  //   );
  //   if (filteredPlayers.length === 0) return;
  //   const scores = filteredPlayers.map(
  //     (p) => p.currentScore + getFuzzyPenalty(p.fuzzyScore)
  //   );
  //   const average = mean(scores);
  //   setAverageScore(round(average, 1));
  // };

  const updateFuzzy = (fuzzy: Record<string, number>) => {
    setFuzzy((prev: any) => ({
      ...prev,
      ...fuzzy,
    }));
  };

  const resetStatesFromInputServer = () => {
    setAssists(undefined);
    setFuzzy(undefined);
    // setTopScore(undefined);
    // setAverageScore(undefined);
    setTeamBestBalls({});
    if (players) {
      const resetPlayers = cloneDeep(players);
      for (const profileId of Object.keys(players)) {
        resetPlayers[profileId].correctAnswer = false;
        resetPlayers[profileId].incorrectAnswer = false;
        resetPlayers[profileId].isPromptAnswer = false;
        resetPlayers[profileId].timerLimitReached = false;
        resetPlayers[profileId].fuzzyScore = null;
        resetPlayers[profileId].currentScore = null;
      }
      setPlayers(resetPlayers);
    }
    if (teams) {
      const resetTeams = cloneDeep(teams);
      for (const teamId of Object.keys(resetTeams)) {
        resetTeams[teamId].correctAnswer = false;
        resetTeams[teamId].correctAnswerPlayerUsername = null;
        resetTeams[teamId].incorrectAnswer = false;
        resetTeams[teamId].timerLimitReached = false;
        resetTeams[teamId].fuzzyScore = null;
        resetTeams[teamId].currentScore = null;
      }
      setTeams(resetTeams);
    }
  };

  const updateScoresOnTimeout = (par: number, timeLimit?: number) => {
    const updateTimedOutPlayers = cloneDeep(players);
    for (const profileId of Object.keys(players)) {
      const p = updateTimedOutPlayers[profileId];
      if (p.currentScore === null && !p.timerLimitReached) {
        p.parScore = p.parScore + (timeLimit || TIME_LIMIT) - par;
        p.currentScore = (timeLimit || TIME_LIMIT) - par;
        p.timerLimitReached = p.correctAnswer ? false : true;
        if (profileId === profile._id) {
          setRoundScores((prev: any) => ({
            ...prev,
            [questionIndexRef.current]: {
              score: (timeLimit || TIME_LIMIT) - par,
              timerLimitReached: p.correctAnswer ? false : true,
            },
          }));
        }
      }
    }
    setPlayers(updateTimedOutPlayers);
  };

  const requestPlayers = () => {
    inputSocket?.emit('requestPlayers', { gameId });
  };

  return {
    teams,
    myTeamId,
    myTeam,
    opponentTeam,
    players,
    playerSelf,
    playerThem,
    fuzzy,
    penalty,
    assists,
    freezeGameClock,
    roundScores,
    arenaTeamLiveRoundScores,
    teamBestBalls,
    resetStatesFromInputServer,
    updateScoresOnTimeout,
  };
};
