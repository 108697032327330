import {
  Stack,
  Typography,
  useTheme,
  IconButton,
  CardActionArea,
  Card,
  Avatar,
} from '@mui/material';
import React, { useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ArenaTeam } from '../../arena-team/types';
import useArenaGameManager from '../../arena/hooks/useArenaGameManager';
import { useProfileContext } from '../../../contexts/ProfileProvider';
import { getAvatarSrc } from '../../../api/cloudinary';

type Props = {
  arenaRoundGamesScores: Record<
    string,
    {
      [key: string]: number;
    }
  >;
  otherArenaTeams: ArenaTeam[];
};

const OtherGamesScores = ({
  arenaRoundGamesScores,
  otherArenaTeams,
}: Props) => {
  const theme = useTheme();

  const { profile } = useProfileContext();

  const { handleSpectateClick } = useArenaGameManager();

  const [show, setShow] = useState(false);

  const gameIds = Object.keys(arenaRoundGamesScores);
  const games = gameIds
    .filter((gameId) => {
      const teamIds = Object.keys(arenaRoundGamesScores[gameId]);
      return otherArenaTeams?.some((team) => team._id === teamIds[0]);
    })
    .map((gameId) => {
      const teamIds = Object.keys(arenaRoundGamesScores[gameId]);
      const team1 = otherArenaTeams?.find((team) => team._id === teamIds[0]);
      const team2 = otherArenaTeams?.find((team) => team._id === teamIds[1]);

      return {
        gameId,
        team1: {
          teamNameShort: team1?.teamNameShort,
          avatarUrl: team1?.avatarUrl,
          score: arenaRoundGamesScores[gameId][teamIds[0]],
        },
        team2: {
          teamNameShort: team2?.teamNameShort,
          avatarUrl: team2?.avatarUrl,
          score: arenaRoundGamesScores[gameId][teamIds[1]],
        },
      };
    });

  console.log('games', games);

  return (
    <Stack
      sx={{
        bgcolor: '#1f314c8c',
      }}
    >
      <Stack
        position="relative"
        justifyContent={'center'}
        alignItems={'center'}
        py={1}
      >
        <IconButton
          sx={{ position: 'absolute', left: 2 }}
          onClick={() => setShow((prev) => !prev)}
        >
          {show ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>

        <Typography
          variant="body2"
          fontWeight={600}
          color="primary"
          textAlign={'center'}
        >
          OTHER GAMES
        </Typography>
      </Stack>

      {show && (
        <Stack direction={'row'} flexWrap={'wrap'} justifyContent={'center'}>
          {games?.map((game, index) => (
            <Card
              key={index}
              sx={{
                m: 1,
                width: '40%',
                bgcolor: '#005e68fa',
              }}
            >
              <CardActionArea
                sx={{
                  p: 1,
                }}
                onClick={() => handleSpectateClick(profile._id, game.gameId)}
              >
                <Stack spacing={1}>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      <Avatar
                        variant={'rounded'}
                        src={getAvatarSrc(
                          undefined,
                          game.team1.avatarUrl,
                          50,
                          50
                        )}
                        sx={{ height: 22, width: 22 }}
                      />
                      <Typography variant="body2" textAlign={'center'}>
                        {game.team1.teamNameShort}
                      </Typography>
                    </Stack>
                    <Typography
                      variant="body2"
                      textAlign={'right'}
                      fontWeight={600}
                    >
                      {game.team1.score}
                    </Typography>
                  </Stack>

                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      <Avatar
                        variant={'rounded'}
                        src={getAvatarSrc(
                          undefined,
                          game.team2.avatarUrl,
                          50,
                          50
                        )}
                        sx={{ height: 22, width: 22 }}
                      />
                      <Typography variant="body2">
                        {game.team2.teamNameShort}
                      </Typography>
                    </Stack>
                    <Typography
                      variant="body2"
                      textAlign={'right'}
                      fontWeight={600}
                    >
                      {game.team2.score}
                    </Typography>
                  </Stack>
                </Stack>
              </CardActionArea>
            </Card>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default OtherGamesScores;
