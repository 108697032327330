import { Box, useTheme } from '@mui/material';
import React, { memo } from 'react';
import { Radar } from 'react-chartjs-2';

type Props = {
  radarChartData: number[];
};

const DailiesRadarChart = ({ radarChartData }: Props) => {
  const theme = useTheme();

  const data = {
    labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    datasets: [
      {
        data: radarChartData,
        fill: 'start',
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: theme.palette.logoPink.main,
        borderWidth: 3,
        pointBackgroundColor: 'rgb(255, 99, 132)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 99, 132)',
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        reverse: true,
        min: -50,
        max: 50,
        ticks: {
          stepSize: 10,
          backdropColor: 'transparent', // Set backdrop color to transparent
          color: 'gray',
        },
        pointLabels: {
          color: 'white', // Set the color of the labels, if needed
          font: {
            size: 14, // Adjust font size, if desired
          },
        },
        angleLines: {
          display: true, // Ensure radial lines are displayed
          color: 'white', // Set color and opacity for radial lines
          lineWidth: 3, // Set line width for radial lines
        },
        grid: {
          circular: true,
        },
      },
    },
  };

  return (
    <Box
      display={'flex'}
      height={260}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Radar data={data} options={options} />
    </Box>
  );
};

export default memo(DailiesRadarChart);
