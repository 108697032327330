import { useCallback } from 'react';
import { useApi } from './useApi';
import { Profile } from '../types/player';

export const useGetPlayerProfile = () => {
  const { data, error, loading, sendRequest } = useApi<Profile>();

  const fetchPlayerProfile = useCallback(
    (profileId: string) => {
      sendRequest({
        url: `/profile/id/${profileId}`,
      });
    },
    [sendRequest]
  );

  return { data, error, loading, fetchPlayerProfile };
};
