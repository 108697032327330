import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import {
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

type PropTypes = {
  close: () => void;
  handleGenerateScheduleClick: (league: string, division: string) => void;
  open: boolean;
};

export const GenerateScheduleDialog = ({
  close,
  handleGenerateScheduleClick,
  open,
}: PropTypes) => {
  const [league, setLeague] = useState<'diamond' | 'gold'>('diamond');
  const [division, setDivision] = useState<string>('');

  const handleLeagueChange = (value: string) => {
    setLeague(value as 'diamond' | 'gold');
  };

  const handleDivisionChange = (value: string) => {
    setDivision(value);
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="sm">
      <DialogTitle>
        <Typography>Generate League Division Schedule</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FormControl>
            <RadioGroup
              row
              value={league}
              onChange={(e) => handleLeagueChange(e.target.value)}
            >
              <FormControlLabel
                value="diamond"
                control={<Radio />}
                label="Diamond"
              />
              <FormControlLabel value="gold" control={<Radio />} label="Gold" />
            </RadioGroup>
          </FormControl>

          <FormControl>
            <RadioGroup
              row
              value={division}
              onChange={(e) => handleDivisionChange(e.target.value)}
            >
              <FormControlLabel value="m9" control={<Radio />} label="M9" />
              <FormControlLabel value="w8" control={<Radio />} label="W8" />
            </RadioGroup>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} fullWidth>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => handleGenerateScheduleClick(league, division)}
          fullWidth
        >
          Generate Schedule
        </Button>
      </DialogActions>
    </Dialog>
  );
};
