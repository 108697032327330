import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useSocketContext } from './SocketProvider';
import { useProfileContext } from './ProfileProvider';
import { useLoggedInUserContext } from './LoggedInUserProvider';
import { createContext, useContextSelector } from 'use-context-selector';
import { ArenaRound } from '../pages/arena/types';
import useFetchArenaCurrentSeason from '../pages/profile/ArenaProfile/hooks/useFetchArenaCurrentSeason';

interface ArenaContextType {
  arenaNight: number;
  arenaSeason: number;
  arenaRounds: ArenaRound[][];
  showArena: boolean;
}

const ArenaContext = createContext<ArenaContextType>(null);

type ArenaProviderProps = {
  children: ReactNode;
};

export const ArenaProvider: FC<ArenaProviderProps> = ({ children }) => {
  const { socket } = useSocketContext();
  const { profile } = useProfileContext();
  const [showArena, setShowArena] = useState(false);
  const [arenaRounds, setArenaRounds] = useState<ArenaRound[][]>([]);

  const { data: arenaCurrentSeasonData } = useFetchArenaCurrentSeason();

  const arenaSeason = arenaCurrentSeasonData?.arenaSeason;
  const arenaNight = arenaCurrentSeasonData?.arenaNight;

  useEffect(() => {
    if (!socket || !arenaNight) return;

    socket.on('showArena', handleShowArena);
    socket.on('arenaRounds', setArenaRounds);

    // socket.emit('requestArenaRounds', { arenaNight });

    return () => {
      socket.off('showArena', handleShowArena);
      socket.off('arenaRounds', setArenaRounds);
    };
  }, [socket, arenaNight]);

  const handleShowArena = (data) => {
    setShowArena(data);
    if (!data) return;
    socket.emit('joinArenaChat', { arenaNight: data, profileId: profile._id });
  };

  return (
    <ArenaContext.Provider
      value={{
        arenaNight,
        arenaSeason,
        arenaRounds,
        showArena,
      }}
    >
      {children}
    </ArenaContext.Provider>
  );
};

export const useArenaContext = () => {
  const arenaNight = useContextSelector(
    ArenaContext,
    (context) => context?.arenaNight
  );

  const arenaSeason = useContextSelector(
    ArenaContext,
    (context) => context?.arenaSeason
  );

  const arenaRounds = useContextSelector(
    ArenaContext,
    (context) => context?.arenaRounds
  );

  const showArena = useContextSelector(
    ArenaContext,
    (context) => context?.showArena
  );

  return {
    arenaNight,
    arenaSeason,
    arenaRounds,
    showArena,
  };
};
