import {
  keyframes,
  styled,
  TableCell,
  tableCellClasses,
  Typography,
  typographyClasses,
} from '@mui/material';

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    border: 'none',
    textAlign: 'right',
  },
}));

export const StyledCenteredTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    border: 'none',
    textAlign: 'center',
  },
}));

const shine = keyframes`
  0% {
    background-position: -100px;
  }
  100% {
    background-position: 100px;
  }
`;

export const ShinyTypography = styled(Typography)(() => ({
  [`&.${typographyClasses.root}`]: {
    animation: `${shine} 4s infinite`,
    backgroundClip: 'text',
    backgroundImage:
      'linear-gradient(-40deg, transparent 0%, transparent 40%, #fff 50%, transparent 60%, transparent 100%)',
    backgroundRepeat: 'no-repeat',
  },
}));
