import { Socket } from 'socket.io-client';
import { SocketCallback } from '../types';
import { Profile } from '../types/player';

export const connectProfileToSocket = (
  socket: Socket,
  profile: Profile,
  isMobile: boolean
) => {
  socket.emit(
    'connectProfile',
    {
      profile: profile,
      clientSocketId: socket.id,
      isMobile: isMobile,
    },
    (res: SocketCallback) => {
      console.log('connectProfile', res.data);
      socket.emit('getLoggedInUser', { profileId: profile._id });
      socket.emit('requestLeagueGames', { profileId: profile._id });
    }
  );
};

export const connectProfileToSocketLiveInput = (
  socketLiveInput: Socket,
  profile: Profile
) => {
  socketLiveInput.emit('connectProfile', {
    profileId: profile._id,
    username: profile.username,
  });
};

export const connectProfileToSocketGameloop = (
  socketGameloop: Socket,
  profile: Profile
) => {
  socketGameloop.emit('connectProfile', {
    profileId: profile._id,
    username: profile.username,
  });
};

export const connectProfileToSocketSpectate = (
  socketSpectate: Socket,
  profile: Profile
) => {
  socketSpectate.emit('connectProfile', {
    profileId: profile._id,
    username: profile.username,
  });
};
