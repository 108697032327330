import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

type Props = {
  countdown: number;
};
export const CountdownGameStart = ({ countdown }: Props) => {
  return (
    <Box
      position={'absolute'}
      left={'50%'}
      top={'20%'}
      width={'fit-content'}
      sx={{ transform: 'translate(-50%, 0%)' }}
    >
      <CircularProgress
        size={80}
        variant="determinate"
        value={(100 / 3) * (4 - countdown)}
      />
      <Typography fontSize={40} sx={{ position: 'absolute', top: 8, left: 28 }}>
        {countdown}
      </Typography>
    </Box>
  );
};
