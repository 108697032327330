import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Alert, Box, DialogTitle, Stack, Typography } from '@mui/material';
import { getPacificTime } from '../../../utils/date-time';
import { DAILIES_THEMES } from '../constants';
import { DailiesSet } from '../types';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import { Maintenance } from '../../../types';

type PropTypes = {
  handlePlayDailyClick: () => void;
  isMissedDaily: boolean;
  maintenance: Maintenance;
  onClose: () => void;
  open: boolean;
  selectedDailySet?: DailiesSet;
};

export const PlayDailyConfirmDialog = ({
  handlePlayDailyClick,
  isMissedDaily,
  maintenance,
  onClose,
  open,
  selectedDailySet,
}: PropTypes) => {
  const dayOfWeekAbbr = isMissedDaily
    ? dayjs(selectedDailySet.date).format('ddd')
    : getPacificTime().format('ddd');

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>
        <Typography variant="h5" letterSpacing={2} textAlign={'center'}>
          PLAY {!isMissedDaily ? `TODAY'S` : 'MISSED'} DAILY
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack py={4} spacing={4}>
          <Typography
            fontFamily={'noto-sans'}
            textAlign={'center'}
            color="secondary"
          >
            {DAILIES_THEMES[dayOfWeekAbbr].theme}
          </Typography>

          <Typography
            fontFamily={'noto-sans'}
            textAlign={'center'}
            color="secondary"
          >
            "{DAILIES_THEMES[dayOfWeekAbbr].description}"
          </Typography>

          {maintenance.scheduled && (
            <Alert severity="error">
              PAR is under maintenance and games cannot be played right now. We
              apologize for the inconvenience.
            </Alert>
          )}

          <Box width={'100%'} display="flex" justifyContent={'center'}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handlePlayDailyClick}
              disabled={maintenance.scheduled}
            >
              PLAY {isMissedDaily ? 'MISSED' : ''} DAILY
            </Button>
          </Box>

          {isMobile && (
            <Typography
              variant="caption"
              color="warning.main"
              textAlign={'center'}
            >
              This Daily is now playable on mobile devices. Note: this version
              does not use a buzzer
            </Typography>
          )}

          {isMissedDaily && (
            <Typography
              variant="caption"
              fontFamily={'noto-sans'}
              textAlign={'center'}
            >
              Missed dailies won't be included in the community stats and
              leaderboards
            </Typography>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
