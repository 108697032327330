import { Cloudinary } from '@cloudinary/url-gen';
import { limitFit } from '@cloudinary/url-gen/actions/resize';

const CLOUDINARY_PRESET = 'zeybpe0h';
const CLOUDINARY_CLOUD_NAME = 'dqlqwdqs9';

export const uploadImageToCloudinary = async (file: any) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_preset', CLOUDINARY_PRESET);
  const response = await fetch(
    `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/upload`,
    {
      method: 'POST',
      body: formData,
    }
  );
  return response.json();
};

// Create and configure your Cloudinary instance.
export const cloudinary = new Cloudinary({
  cloud: {
    cloudName: CLOUDINARY_CLOUD_NAME,
  },
});

export const resizeImage = (imageId: string, width: number, height: number) =>
  cloudinary
    .image(imageId)
    .resize(limitFit().width(width).height(height))
    .toURL();

export const isGif = (src: string) => {
  if (!src) return false;
  return src.toLowerCase().includes('.gif');
};

export const transformCloudinaryGifToStill = (src: string) => {
  const lastIndex = src.lastIndexOf('upload/') + 7;
  const newSrc = src.slice(0, lastIndex) + 'pg_1/' + src.slice(lastIndex);
  return newSrc;
};

export const getAvatarSrc = (
  avatarPublicId: string,
  avatarUrl?: string,
  w?: number,
  h?: number
) => {
  if (avatarUrl && isGif(avatarUrl)) {
    return transformCloudinaryGifToStill(avatarUrl);
  }

  if (avatarPublicId) {
    return resizeImage(avatarPublicId, w || 100, h || 100);
  }

  if (avatarUrl) {
    return avatarUrl;
  }

  return null;
};
