import { useApi } from '../../../hooks/useApi';
import { Methods } from '../../../hooks/auth/constants';

export const useQuestionLikeDislike = () => {
  const { data, error, loading, sendRequest } = useApi();

  const dislikeQuestion = (questionId: string, profileId: string) => {
    sendRequest({
      url: '/stats/dislike',
      method: Methods.POST,
      body: {
        questionId: questionId,
        profileId: profileId,
      },
    });
  };

  const likeQuestion = (questionId: string, profileId: string) => {
    sendRequest({
      url: '/stats/like',
      method: Methods.POST,
      body: {
        questionId: questionId,
        profileId: profileId,
      },
    });
  };

  return { data, error, loading, dislikeQuestion, likeQuestion };
};
