import { useQuery } from '@tanstack/react-query';
import { fetchDailiesSets } from '../../../api/apiServices';

const useFetchDailesSets = () => {
  const { data } = useQuery({
    queryKey: ['dailiesSets'],
    queryFn: fetchDailiesSets,
  });

  return {
    data,
  };
};

export default useFetchDailesSets;
