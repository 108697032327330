import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { Chart } from 'react-google-charts';
import { Mode, Question, Team } from '../types/game';
import { DetailedPlayer } from '../types/player';

type PropTypes = {
  closeScoreChart: () => void;
  openScoreChart: boolean;
  mode: Mode;
  myTeam?: Team;
  opponentTeam?: Team;
  players?: Record<string, DetailedPlayer>;
  questionSet: Question[];
};

export const GameflowChartDialog = ({
  closeScoreChart,
  openScoreChart,
  mode,
  players,
  myTeam,
  opponentTeam,
  questionSet,
}: PropTypes) => {
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('xl');
  const [data, setData] = useState<any>();

  const theme = useTheme();

  useEffect(() => {
    if (!players) return;
    if (mode === 'teams') return;
    const chartData: any[][] = [['Question']];
    const totalQuestions = questionSet.length;
    questionSet.forEach((q, i) => {
      const newArr = [i + 1];
      chartData.push(newArr);
    });
    Object.values(players).forEach((p) => {
      chartData[0].push(p.username);
      let sum = 0;
      Object.values(p.roundScores).forEach((rs, j) => {
        if (j < totalQuestions) {
          sum = sum + rs.score + (rs.penalty || 0) + (rs.fuzzyPenalty || 0);
          chartData[j + 1].push(sum);
        }
      });
    });
    setData(chartData);
  }, [players]);

  useEffect(() => {
    if (!myTeam || !opponentTeam) return;
    if (mode !== 'teams') return;
    const chartData: any[][] = [['Question']];
    const totalQuestions = questionSet.length;
    questionSet.forEach((q, i) => {
      const newArr = [i + 1];
      chartData.push(newArr);
    });
    [myTeam, opponentTeam].forEach((p) => {
      chartData[0].push(p.username);
      let sum = 0;
      Object.values(p.roundScores).forEach((rs, j) => {
        if (j < totalQuestions) {
          sum = sum + rs.score + (rs.penalty || 0) + (rs.fuzzyPenalty || 0);
          chartData[j + 1].push(sum);
        }
      });
    });
    setData(chartData);
  }, [myTeam, opponentTeam]);

  return (
    <Dialog
      open={openScoreChart}
      onClose={closeScoreChart}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogContent>
        <Box>
          <Chart
            chartType="LineChart"
            data={data}
            width="100%"
            height="400px"
            legendToggle
            options={{
              colors: [
                '#cda68c',
                '#AA4586',
                '#b0c6b7',
                '#ED0003',
                '#64FF00',
                '#FF7300',
                '#00bfff',
                '#f48fb1',
                '#E7FF00',
                '#0db586',
              ],
              titleTextStyle: {
                color: '#ffffff',
                fontName: 'IBM Plex Mono, monospace',
                fontSize: 24,
              },
              title: 'Race to the bottom!',
              legend: { position: 'bottom' },
              legendTextStyle: {
                color: '#ffffff',
              },
              backgroundColor: '#212121',
              hAxis: {
                textStyle: {
                  color: theme.palette.primary.main,
                },
                title: 'Question Num',
                titleTextStyle: {
                  color: theme.palette.primary.main,
                },
                baselineColor: '#3e2d4c',
                gridlineColor: '#3e2d4c',
              },
              vAxis: {
                textStyle: {
                  color: theme.palette.primary.main,
                },
                title: 'Score',
                titleTextStyle: {
                  color: theme.palette.primary.main,
                },
                baselineColor: '#3e2d4c',
                gridlineColor: '#3e2d4c',
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeScoreChart} fullWidth>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
