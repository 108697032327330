import { cloneDeep } from 'lodash';
import { Player } from '../types/player';
import { ArenaTeam, SortedArenaTeam } from '../pages/arena-team/types';

// handles ties like 1, 2, 2, 4
export const assignRanks = (players: Player[]) => {
  const clonedPlayers = cloneDeep(players);
  let prevScore: number;
  let currentRank: number;
  clonedPlayers.forEach((player, index) => {
    if (player.parScore !== prevScore) {
      player.rank = index + 1;
      currentRank = index + 1;
    } else {
      player.rank = currentRank;
    }
    prevScore = player.parScore;
  });
  return clonedPlayers;
};

// handles ties like 1, 2, 2, 4
export const assignArenaTeamRanks = (arenaTeam: SortedArenaTeam[]) => {
  const clonedArenaTeams = cloneDeep(arenaTeam);
  let prevScore: number;
  let currentRank: number;
  clonedArenaTeams.forEach((team, index) => {
    if (team.parScore !== prevScore) {
      team.rank = index + 1;
      currentRank = index + 1;
    } else {
      team.rank = currentRank;
    }
    prevScore = team.parScore;
  });
  return clonedArenaTeams;
};

// handles ties like 1, 2, 2, 4
export const assignArenaTeamRanksByPoints = (arenaTeams: ArenaTeam[]) => {
  const clonedArenaTeams = cloneDeep(
    arenaTeams.sort((a, b) => b.points - a.points)
  );
  let prevScore: number;
  let currentRank: number;
  clonedArenaTeams.forEach((team, index) => {
    if (team.points !== prevScore) {
      team.rank = index + 1;
      currentRank = index + 1;
    } else {
      team.rank = currentRank;
    }
    prevScore = team.points;
  });
  return clonedArenaTeams;
};

export const addPlusToPositiveScores = (score: number) => {
  return score > 0 ? `+${score}` : score;
};
