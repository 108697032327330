import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { TrueSkill } from '../../profile/ArenaProfile/types';
import { getTierBadge } from '../../profile/ArenaProfile/ArenaSummary/utils';

type Props = {
  arenaTrueSkill: TrueSkill;
  open: boolean;
  onClose: () => void;
};

const ArenaTrueSkillDialog = ({ arenaTrueSkill, open, onClose }: Props) => {
  const theme = useTheme();

  const currentTier = arenaTrueSkill.tier;
  const wasCalibrated = arenaTrueSkill.wasCalibrated;
  const wasPromoted = arenaTrueSkill.wasPromoted;

  const calibrationMessage = (
    <>
      <Typography color="primary">
        Well done completing your calibration games. You've been placed in the{' '}
        {currentTier?.toUpperCase()} tier.
      </Typography>

      <Typography color="primary">
        Keep playing to improve your rank!
      </Typography>
    </>
  );

  const promotionMessage = (
    <>
      <Typography color="secondary" fontWeight={600}>
        You've Been Promoted!
      </Typography>

      <Typography color="primary">
        Congratulations. Your performance has qualified you for placement in the{' '}
        {currentTier?.toUpperCase()} tier.
      </Typography>
    </>
  );

  return (
    <Dialog open={open}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Stack direction={'row'} spacing={4}>
          <Stack spacing={2}>
            <Box
              component="img"
              src={getTierBadge(currentTier)}
              alt="image"
              sx={{ width: 100, height: 'auto' }}
            />
            <Typography
              variant="h6"
              textAlign={'center'}
              fontWeight={600}
              letterSpacing={2}
            >
              {currentTier?.toUpperCase()}
            </Typography>
          </Stack>

          <Stack spacing={4}>
            {wasCalibrated
              ? calibrationMessage
              : wasPromoted
              ? promotionMessage
              : null}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArenaTrueSkillDialog;
