import {
  List,
  Box,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  Badge,
  Avatar,
  ListItemText,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { PlayerActivityStatus } from './PlayerActivityStatus';
import { lowerCase } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { FilterBadge } from './FilterBadge';
import { SearchDrawer } from './SearchDrawer';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { Profile } from '../../types/player';
import { LoggedInUser } from '../../types/user';
import { getAvatarSrc } from '../../api/cloudinary';
import { sortUsers } from './utils';

export type MenuItemProps = {
  label: string;
  filterOn: string;
  filterValue: string | boolean;
};

type Props = {
  profile: Profile;
  loggedInUsers: LoggedInUser[];
};

const PlayersOnline = ({ profile, loggedInUsers }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [selectedMenuItem, setSelectedMenuItem] =
    useState<MenuItemProps | null>(null);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const handlePlayerClick = (profileId: string) => {
    navigate(`/profile/overview/${profileId}`);
  };

  const filterSearch = (loggedInUsers: LoggedInUser[]) => {
    if (!searchTerm) return loggedInUsers;
    return loggedInUsers.filter((el) =>
      lowerCase(el.username).includes(lowerCase(searchTerm))
    );
  };

  const filteredUsers = (loggedInUsers?: LoggedInUser[]) => {
    if (!selectedMenuItem) return sortUsers(loggedInUsers);
    return sortUsers(loggedInUsers).filter(
      (el) => el[selectedMenuItem.filterOn] === selectedMenuItem.filterValue
    );
  };

  return (
    <>
      <SearchDrawer
        setSearchTerm={setSearchTerm}
        setSelectedMenuItem={setSelectedMenuItem}
        selectedMenuItem={selectedMenuItem}
      />

      <FilterBadge
        selectedMenuItem={selectedMenuItem}
        setSelectedMenuItem={setSelectedMenuItem}
      />

      <List sx={{ overflow: 'auto' }}>
        {filterSearch(filteredUsers(loggedInUsers))
          .filter((p) => p.profileId !== profile?._id)
          .map((player, index) => (
            <Box key={player.profileId}>
              <ListItem disablePadding disableGutters>
                <ListItemButton
                  onClick={() => handlePlayerClick(player?.profileId)}
                  key={index}
                  sx={{ paddingRight: '16px !important', paddingLeft: 1 }}
                >
                  <ListItemAvatar sx={{ minWidth: 40, marginRight: 1 }}>
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      badgeContent={
                        player.isMobile && (
                          <PhoneAndroidIcon
                            sx={{
                              background: theme.palette.background.default,
                              padding: '3px',
                              borderRadius: '16px',
                            }}
                            fontSize="small"
                          />
                        )
                      }
                    >
                      <Avatar
                        alt={player?.username}
                        src={getAvatarSrc(
                          player?.avatarPublicId,
                          player?.avatarUrl,
                          100,
                          100
                        )}
                        sx={{
                          filter: player.online ? '' : 'grayscale(100%)',
                        }}
                      />
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText
                    primary={player?.username}
                    primaryTypographyProps={{ fontSize: 14 }}
                    secondary={<PlayerActivityStatus player={player} />}
                    sx={{ margin: 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <Divider variant="inset" component="li" sx={{ marginLeft: 7 }} />
            </Box>
          ))}
      </List>
    </>
  );
};

export default PlayersOnline;
