import {
  DetailedPlayer,
  Player,
  Profile,
  RoundScore,
} from '../../../types/player';

const useDailiesStreakAchievements = (
  profile: Profile,
  dailySet: number,
  player: DetailedPlayer,
  missedDaily: boolean
) => {
  if (!dailySet || missedDaily) {
    return {
      dailiesStreakAchievements: null,
    };
  }

  const { dailiesStats, lastDailySetPlayed } = profile;

  const {
    consecutiveDailiesPlayedCurrent,
    consecutiveDailiesPlayedAllTime,
    consecutiveDailiesNoTimeOutsCurrent,
    consecutiveDailiesNoTimeOutsAllTime,
    consecutiveDailiesParOrBetterCurrent,
    consecutiveDailiesParOrBetterAllTime,
    consecutiveDailies012Current,
    consecutiveDailies012AllTime,
  } = dailiesStats;

  const missedLastDaily = dailySet - lastDailySetPlayed > 1;

  const playedCurrent = (consecutiveDailiesPlayedCurrent || 0) + 1;

  const isNoTimeouts = dFence(player.roundScores);
  const noTimeoutsCurrent = isNoTimeouts
    ? (consecutiveDailiesNoTimeOutsCurrent || 0) + 1
    : 0;

  const isScratch = scratch(player);
  const scratchCurrent = isScratch
    ? (consecutiveDailiesParOrBetterCurrent || 0) + 1
    : 0;

  const isParBirdieEagle = parBirdieEagleAchieved(player.roundScores);
  const parBirdieEagleCurrent = isParBirdieEagle
    ? (consecutiveDailies012Current || 0) + 1
    : 0;

  const dailiesStreakAchievements = {
    missedLastDaily,
    playedCurrent,
    playedAllTime:
      playedCurrent > consecutiveDailiesPlayedAllTime
        ? playedCurrent
        : consecutiveDailiesPlayedAllTime,
    isNoTimeouts,
    noTimeoutsCurrent,
    noTimeoutsAllTime:
      noTimeoutsCurrent > consecutiveDailiesNoTimeOutsAllTime
        ? noTimeoutsCurrent
        : consecutiveDailiesNoTimeOutsAllTime,
    isScratch,
    scratchCurrent,
    scratchAllTime:
      scratchCurrent > consecutiveDailiesParOrBetterAllTime
        ? scratchCurrent
        : consecutiveDailiesParOrBetterAllTime,
    isParBirdieEagle,
    parBirdieEagleCurrent,
    parBirdieEagleAllTime:
      parBirdieEagleCurrent > consecutiveDailies012AllTime
        ? parBirdieEagleCurrent
        : consecutiveDailies012AllTime,
  };

  if (missedLastDaily) {
    dailiesStreakAchievements.playedCurrent = 1;
    dailiesStreakAchievements.noTimeoutsAllTime = isNoTimeouts ? 1 : 0;
    dailiesStreakAchievements.scratchCurrent = isScratch ? 1 : 0;
    dailiesStreakAchievements.parBirdieEagleCurrent = isParBirdieEagle ? 1 : 0;
  }

  return {
    dailiesStreakAchievements,
  };
};

const scratch = (player: Player) => {
  return player.parScore <= 0;
};

const dFence = (roundScores: Record<number, RoundScore>) => {
  for (const key of Object.keys(roundScores)) {
    if (roundScores[+key].timerLimitReached) return false;
  }
  return true;
};

const parBirdieEagleAchieved = (roundScores: Record<number, RoundScore>) => {
  let par = false;
  let birdie = false;
  let eagle = false;

  for (const key of Object.keys(roundScores)) {
    const score = roundScores[+key].score;

    if (score === null) continue;

    if (roundScores[+key].score <= -2 && !eagle) {
      eagle = true;
    } else if (roundScores[+key].score <= -1 && !birdie) {
      birdie = true;
    } else if (roundScores[+key].score <= 0 && !par) {
      par = true;
    }
  }

  return par && birdie && eagle;
};

export default useDailiesStreakAchievements;
