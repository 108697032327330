import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import React from 'react';

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#111a19',
    color: 'white',
    maxWidth: 360,
    fontSize: 16,
    borderRadius: 5,
    border: '1px solid',
    borderColor: '#a48672',
  },
}));
