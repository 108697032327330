import { Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import { randomIntFromInterval } from '../../../utils/math';

type PropTypes = {
  profileId: string;
  socket: Socket;
};

export const Latency = ({ profileId, socket }: PropTypes) => {
  const [latency, setLatency] = useState(0);
  const startTimeRef = useRef(Date.now());
  const timeoutRef = useRef<any>();

  useEffect(() => {
    if (!socket) return;
    socket.on('pong', measureLatency);

    const startPingInterval = () => {
      sendPing();
      const random = randomIntFromInterval(500, 1500);
      timeoutRef.current = setTimeout(() => {
        startPingInterval();
      }, random);
    };
    startPingInterval();

    return () => {
      clearTimeout(timeoutRef.current);
      socket.off('pong');
    };
  }, []);

  const sendPing = () => {
    startTimeRef.current = Date.now();
    socket.emit('ping', profileId);
  };

  const measureLatency = () => {
    const endTime = Date.now();
    const roundTripTime = endTime - startTimeRef.current;
    setLatency(roundTripTime);
  };

  return (
    <Typography variant={'caption'} textAlign={'right'}>
      Ping: {latency.toFixed(1)}
    </Typography>
  );
};
