import { useQuery } from '@tanstack/react-query';
import { fetchArenaAdvancedStats } from '../../../../api/apiServices';

const useFetchArenaAdvancedStats = (profileId: string, season?: number) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'arenaAdvancedStats',
      {
        profileId,
        season,
      },
    ],
    queryFn: fetchArenaAdvancedStats,
    enabled: !!profileId && !!season,
  });

  return {
    refetch,
    isLoading,
    data,
  };
};

export default useFetchArenaAdvancedStats;
