import { GameType } from '../../../components/play-menu';
import { AVES } from '../../../constants/constants';
import { Profile } from '../../../types/player';
import { roundToOneDecimal } from '../../../utils/math';

export const calcPer100 = (aveCount: number, questionsSeenCount: number) =>
  (aveCount / questionsSeenCount) * 100;

export const calcPOB = (
  aves: Record<string, number>,
  questionsSeenCount: number
) => {
  if (!aves) return null;
  let sum = 0;
  for (const key of Object.keys(aves)) {
    if (key !== 'bogey') {
      sum = sum + calcPer100(aves[key], questionsSeenCount);
    }
  }
  return Math.round((sum / 100) * 100) + '%';
};

export const calcAvesPer100 = (
  aveCount: number,
  questionsSeenCount: number
) => {
  if (!aveCount) return '';
  const val = (aveCount / questionsSeenCount) * 100;
  return val.toFixed(1);
};

export const avesData = (viewProfile: Profile) => {
  const { aves, questionsSeenCount } = viewProfile;
  if (!aves) return [];
  return [
    {
      name: 'PAR',
      lifeTime: aves['par'],
      per100Qs: calcAvesPer100(aves['par'], questionsSeenCount),
      color: aveColor('par'),
    },
    {
      name: 'Birdie',
      lifeTime: aves['birdie'],
      per100Qs: calcAvesPer100(aves['birdie'], questionsSeenCount),
      color: aveColor('birdie'),
    },
    {
      name: 'Eagle',
      lifeTime: aves['eagle'],
      per100Qs: calcAvesPer100(aves['eagle'], questionsSeenCount),
      color: aveColor('eagle'),
    },
    {
      name: 'Albatross',
      lifeTime: aves['albatross'],
      per100Qs: calcAvesPer100(aves['albatross'], questionsSeenCount),
      color: aveColor('albatross'),
    },
    {
      name: 'Condor',
      lifeTime: aves['condor'],
      per100Qs: calcAvesPer100(aves['condor'], questionsSeenCount),
      color: aveColor('condor'),
    },
    {
      name: 'Ostrich',
      lifeTime: aves['ostrich'],
      per100Qs: calcAvesPer100(aves['ostrich'], questionsSeenCount),
      color: aveColor('ostrich'),
    },
    {
      name: 'Pterodactyl',
      lifeTime: aves['pterodactyl'],
      per100Qs: calcAvesPer100(aves['pterodactyl'], questionsSeenCount),
      color: aveColor('pterodactyl'),
    },
  ];
};

export const aveColor = (name: string) =>
  AVES.find((a) => a.name === name)?.color.dark || '';

export const aveShineColor = (name: string) =>
  AVES.find((a) => a.name === name)?.shineColor.dark || '';

export const aveName = (score: number) => {
  const found = AVES.find((a) => a.score === score)?.name;
  if (found) return found;
  if (score < -6) return 'pterodactyl'; // scores shouldn't be less than -6, but if achieved, still ptero
  return '';
};
