import { useQuery } from '@tanstack/react-query';
import { getMmrRankings } from '../../../api/apiServices';

const useGetRankings = (fetch?: boolean) => {
  const { data, isPending, refetch } = useQuery({
    queryKey: ['getRankings'],
    queryFn: getMmrRankings,
    enabled: fetch,
  });

  return {
    refetch,
    data,
    isPending,
  };
};

export default useGetRankings;
