import { Card, Stack, Box, Typography, Divider, useTheme } from '@mui/material';
import React from 'react';
import { Profile } from '../../../types/player';
import useFetchDailySetsPlayed from '../../dailies_old/hooks/useFetchDailySetsPlayed';
import { getRecent4GameScoresPerDay } from '../../dailies_old/DailiesHeader/utils';
import DailiesRadarChart from '../../dailies_old/DailiesHeader/DailiesRadarChart';

type Props = {
  viewProfile: Profile;
};

const Summary = ({ viewProfile }: Props) => {
  const theme = useTheme();

  const { totalDailyGamesPlayed } = viewProfile;

  const { data: dailySetsPlayed } = useFetchDailySetsPlayed(viewProfile._id);

  const radarChartData = getRecent4GameScoresPerDay(dailySetsPlayed);

  return (
    <Box pt={2}>
      <Card
        sx={{
          bgcolor: '#383d42f0',
        }}
      >
        <Stack direction={'row'} spacing={2} p={2} alignContent={'center'}>
          <Stack spacing={2} px={4}>
            <Typography textAlign={'center'}>Daily 4 Game Average</Typography>
            <DailiesRadarChart radarChartData={radarChartData} />
          </Stack>

          <Divider flexItem orientation="vertical" />

          <Stack spacing={2} width={'100%'} pt={10}>
            <Typography textAlign={'center'}>Total Dailies Played</Typography>
            <Typography
              variant="h6"
              fontWeight={600}
              pr={1}
              textAlign={'center'}
            >
              {totalDailyGamesPlayed || 0}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default Summary;
