import { useQuery } from '@tanstack/react-query';
import { fetchUserArenaAchievements } from '../../../../api/apiServices';

const useFetchUserArenaAchievements = (profileId: string, season?: number) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'userArenaAchievements',
      {
        profileId,
        season,
      },
    ],
    queryFn: fetchUserArenaAchievements,
    enabled: !!profileId && !!season,
  });

  return {
    refetch,
    isLoading,
    data,
  };
};

export default useFetchUserArenaAchievements;
