import { DetailedPlayer, RoundScore } from '../../../../types/player';
import { ArenaTeamsBestBallRoundScores } from './types';

export const getBestBallRoundScores = (
  teamDetailedPlayers: DetailedPlayer[]
) => {
  const bestBallRoundScores: ArenaTeamsBestBallRoundScores = {};

  for (let i = 0; i < 10; i++) {
    let bestScore = Infinity;

    for (const player of teamDetailedPlayers) {
      const totalScore = player.roundScores[i].totalScore;
      const roundScoreObj = {
        ...player.roundScores[i],
        profileId: player.profileId,
      };

      if (totalScore < bestScore) {
        bestScore = totalScore;
        bestBallRoundScores[i] = [].concat(roundScoreObj);
      } else if (totalScore === bestScore) {
        bestBallRoundScores[i] = (bestBallRoundScores[i] || []).concat(
          roundScoreObj
        );
      }
    }
  }

  return bestBallRoundScores;
};

export const getPlayerBestBallRoundScores = (
  teamRoundScores: ArenaTeamsBestBallRoundScores,
  profileId?: string
) => {
  const playerBestBallRoundScores: Record<number, RoundScore> = {};

  for (const key of Object.keys(teamRoundScores)) {
    const roundScores = teamRoundScores[key];

    // if profileId is provided, only get the round score for that player
    if (profileId) {
      const gotBestBall = roundScores.find(
        (roundScore) => roundScore.profileId === profileId
      );

      if (gotBestBall) {
        playerBestBallRoundScores[Number(key)] = gotBestBall;
      } else {
        playerBestBallRoundScores[Number(key)] = null;
      }

      // if profileId is not provided, get the first round score since we dont care who got it
    } else {
      playerBestBallRoundScores[Number(key)] = roundScores[0];
    }
  }

  return playerBestBallRoundScores;
};
