import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import '../../../../components/scorecard/SubmittedByStamp.scss';

import { Mode } from '../../../../types/game';
import { Team } from '../../../../types/game';
import { ArenaTeamLiveRoundScores, Player } from '../../../../types/player';
import '../styles.css';
import CenterGameHeader from './CenterGameHeader/CenterGameHeader';
import { getGameHeaderLabel } from './utils';
import TeamScoreBox from './TeamScoreBox/TeamScoreBox';
import { PlayerScoreHeader } from './PlayerScoreHeader';
import { ArenaTeam } from '../../../arena-team/types';
import { ArenaTeamScoreHeader } from './ArenaTeamScoreHeader';

export const SCORE_BOX_WIDTH = 304;
export const SCORE_BOX_HEIGHT = 112; // height and width of the avatar

type PropTypes = {
  arenaGame?: number;
  arenaTeamLiveRoundScores?: ArenaTeamLiveRoundScores;
  category: string;
  categorySelections: string[];
  categorySet: string;
  dailySet?: number;
  delayBeforeQuestionRevealCount: number;
  displayCategoryAndPar: boolean;
  displayGameClock: boolean;
  freezeGameClock: boolean;
  gameClock: number;
  goToNextQuestionEarly?: boolean;
  leagueMatch?: number;
  missedDaily?: boolean;
  mode: Mode;
  myArenaTeam: ArenaTeam;
  myArenaTeamScore: number;
  otherArenaTeam: ArenaTeam;
  otherArenaTeamScore: number;
  myTeam?: Team;
  nextQuestionDelayCount: number;
  otherTeam?: Team;
  par: number;
  players?: Record<string, Player>;
  playerSelf: Player;
  playerThem?: Player;
  questionIndex: number;
  spectate: boolean;
  submittedByUsername?: string;
  teamBestBalls?: Record<string, Player[]>;
  timerLimitReached: boolean;
  totalNumQuestions: number | undefined;
};

export const GameHeader = ({
  arenaGame,
  myArenaTeam,
  myArenaTeamScore,
  otherArenaTeam,
  otherArenaTeamScore,
  arenaTeamLiveRoundScores,
  category,
  categorySelections,
  categorySet,
  dailySet,
  delayBeforeQuestionRevealCount,
  displayCategoryAndPar,
  displayGameClock,
  freezeGameClock,
  gameClock,
  goToNextQuestionEarly,
  leagueMatch,
  missedDaily,
  mode,
  myTeam,
  nextQuestionDelayCount,
  otherTeam,
  par,
  players,
  playerSelf,
  playerThem,
  questionIndex,
  spectate,
  submittedByUsername,
  teamBestBalls,
  timerLimitReached,
  totalNumQuestions,
}: PropTypes) => {
  const [timerBeforeQuestionReveal, setTimerBeforeQuestionReveal] = useState(0);

  useEffect(() => {
    if (nextQuestionDelayCount === 2) setTimerBeforeQuestionReveal(25);
    if (nextQuestionDelayCount === 1) setTimerBeforeQuestionReveal(50);
    if (nextQuestionDelayCount === 0) setTimerBeforeQuestionReveal(75);
  }, [nextQuestionDelayCount]);

  useEffect(() => {
    if (delayBeforeQuestionRevealCount === 1) setTimerBeforeQuestionReveal(100);
    if (delayBeforeQuestionRevealCount === 0) setTimerBeforeQuestionReveal(0);
  }, [delayBeforeQuestionRevealCount]);

  const gameHeaderLabel = getGameHeaderLabel(
    mode,
    arenaGame,
    leagueMatch,
    categorySet,
    categorySelections,
    dailySet,
    missedDaily
  );

  const spectateSng =
    spectate && mode === 'sit-n-go' && Object.keys(players).length > 2;

  return (
    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
      <Stack
        className="GameHeaderStack"
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
        width={'100%'}
      >
        {/* Left Side Player Info */}
        <Box flex={1} height={'100%'}>
          {arenaGame ? (
            <ArenaTeamScoreHeader
              arenaTeamId={myArenaTeam?._id}
              arenaTeam={myArenaTeam}
              arenaTeamLiveRoundScores={arenaTeamLiveRoundScores}
              arenaTeamScore={myArenaTeamScore}
              player={playerSelf}
              playerInfoDisplayPosition="left"
              questionIndex={questionIndex}
              spectate={spectate}
              teamBestBalls={teamBestBalls}
              timerLimitReached={timerLimitReached}
            />
          ) : spectateSng ? null : mode === 'teams' && myTeam ? (
            <TeamScoreBox
              players={players}
              playerInfoDisplayPosition="left"
              team={myTeam}
              timerLimitReached={timerLimitReached}
            />
          ) : (
            <PlayerScoreHeader
              player={playerSelf}
              playerInfoDisplayPosition="left"
              timerLimitReached={timerLimitReached}
            />
            // <PlayerScoreBox
            //   player={playerSelf}
            //   playerInfoDisplayPosition="left"
            //   timerLimitReached={timerLimitReached}
            // />
          )}
        </Box>

        {/* Center Game Header Area */}
        <CenterGameHeader
          category={category}
          displayCategoryAndPar={displayCategoryAndPar}
          displayGameClock={displayGameClock}
          freezeGameClock={freezeGameClock}
          gameClock={gameClock}
          gameHeaderLabel={gameHeaderLabel}
          goToNextQuestionEarly={goToNextQuestionEarly}
          leagueMatch={leagueMatch}
          mode={mode}
          par={par}
          questionIndex={questionIndex}
          spectate={spectate}
          submittedByUsername={submittedByUsername}
          timerBeforeQuestionReveal={timerBeforeQuestionReveal}
          totalNumQuestions={totalNumQuestions}
        />

        {/* Right Side Player Info */}
        <Box flex={1}>
          {arenaGame ? (
            <ArenaTeamScoreHeader
              arenaTeamId={otherArenaTeam?._id}
              arenaTeam={otherArenaTeam}
              arenaTeamLiveRoundScores={arenaTeamLiveRoundScores}
              arenaTeamScore={otherArenaTeamScore}
              playerInfoDisplayPosition="right"
              questionIndex={questionIndex}
              spectate={spectate}
              teamBestBalls={teamBestBalls}
              timerLimitReached={timerLimitReached}
            />
          ) : mode === 'teams' && otherTeam ? (
            <TeamScoreBox
              players={players}
              playerInfoDisplayPosition="right"
              team={otherTeam}
              timerLimitReached={timerLimitReached}
            />
          ) : (
            <PlayerScoreHeader
              player={playerThem}
              playerInfoDisplayPosition="right"
              timerLimitReached={timerLimitReached}
            />
            // <PlayerScoreBox
            //   player={playerThem}
            //   playerInfoDisplayPosition="right"
            //   timerLimitReached={timerLimitReached}
            // />
          )}
        </Box>
      </Stack>
    </Box>
  );
};
