import React from 'react';
import {
  Avatar,
  Box,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Player } from '../../../../../types/player';
import { getAvatarSrc } from '../../../../../api/cloudinary';
import { addPlusToPositiveScores } from '../../../../../utils/game';

const AVATAR_HEIGHT = 40;
const AVATAR_WIDTH = 40;

type Props = {
  player: Player;
  timerLimitReached: boolean;
};

export const SmallPlayerScoreBox = ({ player, timerLimitReached }: Props) => {
  const { palette } = useTheme();

  if (!player) {
    return <Box></Box>;
  }

  const {
    avatarPublicId,
    avatarUrl,
    correctAnswer,
    currentScore,
    totalPenalty,
  } = player;

  const boxShadow = () => {
    // if (penalty?.penaltyTimestamp) return 'warning.main';

    if (!correctAnswer && !timerLimitReached) return 'none';

    return correctAnswer
      ? `${palette.success.main} 0px 0px 20px 5px, ${palette.success.main} 0px 0px 5px 0px, ${palette.success.main} 0px 0px 5px 0px`
      : `${palette.error.light} 0px 0px 10px 0px, ${palette.error.light} 0px 0px 0px 0px, ${palette.error.light} 0px 0px 0px 0px`;
  };

  const opaqueBackground = () => {
    // if (penalty?.penaltyTimestamp) return palette.warning.main;
    if (correctAnswer) return `${palette.success.main}e0`;
    return `${palette.error.main}e0`;
  };

  const showScore = () =>
    (correctAnswer && currentScore !== undefined) ||
    (!correctAnswer && timerLimitReached);

  return (
    <Stack spacing={0.5}>
      <Stack direction={'row'} spacing={1} height={'100%'}>
        <Box height={AVATAR_HEIGHT}>
          <Avatar
            src={getAvatarSrc(avatarPublicId, avatarUrl, 200, 200)}
            variant={'circular'}
            sx={{
              height: AVATAR_HEIGHT,
              width: AVATAR_WIDTH,
              objectFit: 'cover',
              zIndex: 1,
              boxShadow: boxShadow,
            }}
          />

          {showScore() ? (
            <Avatar
              variant={'circular'}
              sx={{
                height: AVATAR_HEIGHT,
                width: AVATAR_WIDTH,
                zIndex: 1,
                position: 'relative',
                right: 0,
                bottom: AVATAR_HEIGHT,
                backgroundColor: opaqueBackground,
              }}
            >
              <Typography variant="h6">
                {addPlusToPositiveScores(currentScore)}
              </Typography>
            </Avatar>
          ) : null}
        </Box>

        {/* Score & Penalty */}
        <Stack
          className="ScorePenaltyStack"
          justifyContent={'right'}
          alignItems={'end'}
        >
          <Typography
            fontSize={16}
            textAlign={'right'}
            sx={{
              textShadow: '0px 0px 12px #ffffff, 0px 0px 12px #f8ee95',
            }}
          >
            {addPlusToPositiveScores(player.parScore)}
          </Typography>

          {!totalPenalty ? (
            <></>
          ) : (
            <Typography
              fontSize={16}
              textAlign={'right'}
              color={'warning.main'}
              fontWeight={600}
              sx={{
                // backgroundColor: palette.warning.main,
                borderRadius: 16,
                height: 24,
                width: 24,
                textAlign: 'center',
              }}
            >
              {totalPenalty}
            </Typography>
          )}
        </Stack>
      </Stack>

      <Typography
        variant="caption"
        color={'secondary.main'}
        sx={{
          width: 90,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {player.username + 'awefuhi wekj weflhawfe '}
      </Typography>
    </Stack>
  );
};
