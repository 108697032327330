import { useQuery } from '@tanstack/react-query';
import { fetchProfileById } from '../api/apiServices';

const useFetchProfileById = (profileId: string) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['profileById', { profileId }],
    queryFn: fetchProfileById,
  });

  return {
    isLoading,
    profile: data,
    refetch,
  };
};

export default useFetchProfileById;
