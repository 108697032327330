import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Checkbox,
  Container,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Stack } from '@mui/system';
import { CustomQuestion } from '../../types/custom-questions';
import {
  DIFFICULTY,
  PAR_VALUES,
  PROPER_NAME_SHORT_ANSWERS,
  USER_FORM_CATEGORIES,
} from '../custom-questions/constants';
import { Question } from '../../types/game';

type PropTypes = {
  close: () => void;
  customQuestion: CustomQuestion | Question;
  formType: 'edit' | 'review';
  handleApprove?: (questionId: string, editedQuestion: any) => void;
  handleReject?: (questionId: string, rejectionComment: string) => void;
  handleSubmitEdit?: (questionId: string, editedQuestion: any) => void;
  open: boolean;
  questionId: string;
};

export const CustomQuestionsEditDialog = ({
  close,
  customQuestion,
  formType,
  handleApprove,
  handleReject,
  handleSubmitEdit,
  questionId,
  open,
}: PropTypes) => {
  const theme = useTheme();
  const [rejectionComment, setRejectionComment] = useState('');
  const [editedQuestion, setEditedQuestion] = useState<any>();
  const [showQuestionEdit, setShowQuestionEdit] = useState(false);
  const [showAnswerEdit, setShowAnswerEdit] = useState(false);
  const [showCategoryEdit, setShowCategoryEdit] = useState(false);
  const [showParEdit, setShowParEdit] = useState(false);
  const [showProperNameEdit, setShowProperNameEdit] = useState(false);
  const [showProperNameExceptionsEdit, setShowProperNameExceptionsEdit] =
    useState(false);
  const [showAltAnswersEdit, setShowAltAnswersEdit] = useState(false);
  const [showPromptsEdit, setShowPromptsEdit] = useState(false);
  const [showCommentEdit, setShowCommentEdit] = useState(false);
  const [showRefLinkEdit, setShowRefLinkEdit] = useState(false);

  useEffect(() => {
    if (!open) return;
    setEditedQuestion({ difficulty: 3 });
    setShowQuestionEdit(false);
    setShowAnswerEdit(false);
    setShowCategoryEdit(false);
    setShowParEdit(false);
    setShowProperNameEdit(false);
    setShowProperNameExceptionsEdit(false);
    setShowAltAnswersEdit(false);
    setShowPromptsEdit(false);
    setShowCommentEdit(false);
    setShowRefLinkEdit(false);
  }, [open]);

  const handleClose = () => {
    setEditedQuestion(undefined);
    close();
  };

  const handleRejectionComment = (comment: string) => {
    setRejectionComment(comment);
  };

  const handleRejectClick = () => {
    handleReject(customQuestion._id, rejectionComment);
  };

  const handleApproveClick = () => {
    if (
      editedQuestion?.difficulty === undefined ||
      typeof editedQuestion?.difficulty !== 'number'
    )
      return;
    handleApprove(customQuestion._id, editedQuestion);
  };

  const handleSubmitEditClick = () => {
    handleSubmitEdit(questionId, editedQuestion);
  };

  const handleEdit = (property: string, value: string | number | boolean) => {
    if (property === 'properNameShortAnswer' && value === 'last word') {
      setEditedQuestion((prev) => ({
        ...prev,
        properName: true,
      }));
    }
    setEditedQuestion((prev) => ({
      ...prev,
      [property]: value,
    }));
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h5" textAlign={'center'}>
          {formType === 'edit'
            ? 'Edit Question (if question is in review)'
            : 'Review User Submitted Question'}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ width: '100%', height: '100%' }}>
        <Container>
          <Stack spacing={2}>
            <Stack
              spacing={1}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Typography textAlign={'center'} fontWeight={600}>
                Question:
                <Button
                  color="warning"
                  onClick={() => setShowQuestionEdit((prev) => !prev)}
                >
                  {showQuestionEdit ? 'Done' : 'Edit'}
                </Button>
              </Typography>
              {!showQuestionEdit ? (
                <Typography variant="h6" textAlign={'center'} color="primary">
                  {editedQuestion?.question || customQuestion.question}
                </Typography>
              ) : (
                <TextField
                  id="question"
                  label="Question"
                  variant="standard"
                  value={editedQuestion?.question || customQuestion.question}
                  onChange={(e) => handleEdit('question', e.target.value)}
                />
              )}
              <Typography textAlign={'center'} color="secondary">
                Word count: {customQuestion.question.split(' ').length}
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              spacing={5}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Stack spacing={1}>
                <Typography textAlign={'center'} fontWeight={600}>
                  Answer:
                  <Button
                    color="warning"
                    onClick={() => setShowAnswerEdit((prev) => !prev)}
                  >
                    {showAnswerEdit ? 'Done' : 'Edit'}
                  </Button>
                </Typography>
                {!showAnswerEdit ? (
                  <Typography variant="h6" textAlign={'center'} color="primary">
                    {editedQuestion?.answer || customQuestion.answer}
                  </Typography>
                ) : (
                  <TextField
                    id="answer"
                    label="Answer"
                    variant="standard"
                    value={editedQuestion?.answer || customQuestion.answer}
                    onChange={(e) => handleEdit('answer', e.target.value)}
                  />
                )}
              </Stack>
              <Stack spacing={1}>
                <Typography>
                  Category:
                  <Button
                    color="warning"
                    onClick={() => setShowCategoryEdit((prev) => !prev)}
                  >
                    {showCategoryEdit ? 'Done' : 'Edit'}
                  </Button>
                </Typography>
                {!showCategoryEdit ? (
                  <Typography variant="h6" textAlign={'center'} color="primary">
                    {editedQuestion?.category || customQuestion.category}
                  </Typography>
                ) : (
                  <FormControl sx={{ minWidth: 200 }} size="small">
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={
                        editedQuestion?.category || customQuestion.category
                      }
                      label="Category"
                      onChange={(e) => handleEdit('category', e.target.value)}
                    >
                      {USER_FORM_CATEGORIES.map((c) => (
                        <MenuItem value={c}>{c}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Stack>
            </Stack>
            <Stack
              direction={'row'}
              spacing={5}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Stack spacing={1}>
                <Typography>
                  PAR:
                  <Button
                    color="warning"
                    onClick={() => setShowParEdit((prev) => !prev)}
                  >
                    {showParEdit ? 'Done' : 'Edit'}
                  </Button>
                </Typography>
                {!showParEdit ? (
                  <Typography variant="h6" textAlign={'center'} color="primary">
                    {editedQuestion?.par || customQuestion.par}
                  </Typography>
                ) : (
                  <FormControl sx={{ minWidth: 100 }} size="small">
                    <InputLabel>PAR</InputLabel>
                    <Select
                      value={editedQuestion?.par || customQuestion.par}
                      label="PAR"
                      onChange={(e) => handleEdit('par', +e.target.value)}
                    >
                      {PAR_VALUES.map((c) => (
                        <MenuItem value={c}>{c}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Stack>
              {formType === 'review' ? (
                <Stack spacing={1}>
                  <Typography>
                    Is proper name:
                    <Button
                      color="warning"
                      onClick={() => setShowProperNameEdit((prev) => !prev)}
                    >
                      {showProperNameEdit ? 'Done' : 'Edit'}
                    </Button>
                  </Typography>
                  {!showProperNameEdit ? (
                    <Typography
                      variant="h6"
                      textAlign={'center'}
                      color="primary"
                    >
                      {editedQuestion?.properName !== undefined
                        ? editedQuestion.properName
                          ? 'Yes'
                          : 'No'
                        : customQuestion.properName
                        ? 'Yes'
                        : 'No'}
                    </Typography>
                  ) : (
                    <Checkbox
                      checked={
                        editedQuestion?.properName !== undefined
                          ? editedQuestion.properName
                          : customQuestion.properName
                      }
                      onChange={(e) =>
                        handleEdit('properName', e.target.checked)
                      }
                    />
                  )}
                </Stack>
              ) : null}
              <Stack spacing={1}>
                <Typography>
                  Special answer allowance:
                  <Button
                    color="warning"
                    onClick={() =>
                      setShowProperNameExceptionsEdit((prev) => !prev)
                    }
                  >
                    {showProperNameExceptionsEdit ? 'Done' : 'Edit'}
                  </Button>
                </Typography>
                {!showProperNameExceptionsEdit ? (
                  <Typography variant="h6" textAlign={'center'} color="primary">
                    {editedQuestion?.properNameShortAnswer ||
                      customQuestion.properNameShortAnswer}
                  </Typography>
                ) : (
                  <FormControl sx={{ minWidth: 200 }} size="small">
                    <InputLabel>Special answer allowance</InputLabel>
                    <Select
                      value={
                        editedQuestion?.properNameShortAnswer ||
                        customQuestion.properNameShortAnswer
                      }
                      label="Special answer allowance"
                      onChange={(e) =>
                        handleEdit('properNameShortAnswer', e.target.value)
                      }
                    >
                      {PROPER_NAME_SHORT_ANSWERS.map((c) => (
                        <MenuItem value={c}>{c}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Stack>
            </Stack>
            <Stack
              direction={'row'}
              spacing={5}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Stack spacing={1}>
                <Typography>
                  Alternate Answers:
                  <Button
                    color="warning"
                    onClick={() => setShowAltAnswersEdit((prev) => !prev)}
                  >
                    {showAltAnswersEdit ? 'Done' : 'Edit'}
                  </Button>
                </Typography>
                {!showAltAnswersEdit ? (
                  <Typography variant="h6" textAlign={'center'} color="primary">
                    {editedQuestion?.alternateAnswers ||
                      customQuestion.alternateAnswers?.join(',')}
                  </Typography>
                ) : (
                  <TextField
                    id="alternate-answers"
                    label="(separate with commas)"
                    variant="standard"
                    value={
                      editedQuestion?.alternateAnswers
                        ? editedQuestion?.alternateAnswers
                        : customQuestion.alternateAnswers
                    }
                    onChange={(e) =>
                      handleEdit('alternateAnswers', e.target.value)
                    }
                  />
                )}
              </Stack>
              <Stack spacing={1}>
                <Typography>
                  Prompts:
                  <Button
                    color="warning"
                    onClick={() => setShowPromptsEdit((prev) => !prev)}
                  >
                    {showPromptsEdit ? 'Done' : 'Edit'}
                  </Button>
                </Typography>
                {!showPromptsEdit ? (
                  <Typography variant="h6" textAlign={'center'} color="primary">
                    {editedQuestion?.promptAnswer ||
                      customQuestion.promptAnswer.join(',')}
                  </Typography>
                ) : (
                  <TextField
                    id="prompt-answers"
                    label="(separate with commas)"
                    variant="standard"
                    value={
                      editedQuestion?.promptAnswer
                        ? editedQuestion?.promptAnswer
                        : customQuestion.promptAnswer
                    }
                    onChange={(e) => handleEdit('promptAnswer', e.target.value)}
                  />
                )}
              </Stack>
            </Stack>
            <Stack
              direction={'row'}
              spacing={5}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Stack>
                <Typography textAlign={'center'} fontWeight={600}>
                  Author's comment:
                  <Button
                    color="warning"
                    onClick={() => setShowCommentEdit((prev) => !prev)}
                  >
                    {showCommentEdit ? 'Done' : 'Edit'}
                  </Button>
                </Typography>
                {!showCommentEdit ? (
                  <Typography variant="h6" textAlign={'center'} color="primary">
                    {editedQuestion?.authorComment ||
                      (customQuestion as CustomQuestion).authorComment}
                  </Typography>
                ) : (
                  <TextField
                    id="authorComment"
                    label="Author's comment"
                    variant="standard"
                    value={
                      editedQuestion?.authorComment ||
                      (customQuestion as CustomQuestion).authorComment
                    }
                    onChange={(e) =>
                      handleEdit('authorComment', e.target.value)
                    }
                  />
                )}
              </Stack>
              <Stack>
                <Typography textAlign={'center'} fontWeight={600}>
                  Reference link:
                  <Button
                    color="warning"
                    onClick={() => setShowRefLinkEdit((prev) => !prev)}
                  >
                    {showRefLinkEdit ? 'Done' : 'Edit'}
                  </Button>
                </Typography>
                {!showRefLinkEdit ? (
                  <Typography variant="h6" textAlign={'center'} color="primary">
                    {editedQuestion?.referenceLink ||
                      (customQuestion as CustomQuestion).referenceLink}
                  </Typography>
                ) : (
                  <TextField
                    id="referenceLink"
                    label="Reference Link"
                    variant="standard"
                    value={
                      editedQuestion?.referenceLink ||
                      (customQuestion as CustomQuestion).referenceLink
                    }
                    onChange={(e) =>
                      handleEdit('referenceLink', e.target.value)
                    }
                  />
                )}
              </Stack>
            </Stack>
            {formType === 'review' ? (
              <Stack spacing={1}>
                <FormControl sx={{ minWidth: 100 }} size="small">
                  <InputLabel>Difficulty (required to approve)</InputLabel>
                  <Select
                    defaultValue={3}
                    value={editedQuestion?.difficulty}
                    label="Difficulty (required to approve)"
                    onChange={(e) => handleEdit('difficulty', +e.target.value)}
                  >
                    {DIFFICULTY.map((c) => (
                      <MenuItem value={c}>{c}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            ) : null}
          </Stack>
          <Stack spacing={2} mt={4}>
            {formType === 'review' ? (
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                <TextField
                  id="rejection-comment"
                  label="Rejections must have a reason"
                  variant="standard"
                  fullWidth
                  onChange={(e) => handleRejectionComment(e.target.value)}
                />
                <Button
                  variant="outlined"
                  color="warning"
                  sx={{ width: '150px' }}
                  disabled={!rejectionComment}
                  onClick={handleRejectClick}
                >
                  Reject
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ width: '150px' }}
                  onClick={handleApproveClick}
                >
                  Approve
                </Button>
              </Stack>
            ) : (
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ width: '150px' }}
                  onClick={handleSubmitEditClick}
                >
                  Submit Edit
                </Button>
              </Stack>
            )}
          </Stack>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button variant="text" size="large" onClick={handleClose} fullWidth>
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
};
