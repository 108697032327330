export type ProfileSubNavTabs =
  | 'overview'
  | 'achievements'
  | 'punchcard'
  | 'advanced-stats'
  | 'custom-questions'
  | 'game-settings'
  | 'account';

export const PROFILE_ROUTE = {
  overview: '/profile/overview',
  achievements: '/profile/achievements',
  punchcard: '/profile/punchcard',
  advancedStats: '/profile/advanced-stats',
  customQuestions: '/profile/custom-questions',
  gameSettings: '/profile/game-settings',
  account: '/profile/account',
};

export const PROFILE_TAB_MAP: Record<string, ProfileSubNavTabs> = {
  [PROFILE_ROUTE.overview]: 'overview',
  [PROFILE_ROUTE.achievements]: 'achievements',
  [PROFILE_ROUTE.punchcard]: 'punchcard',
  [PROFILE_ROUTE.advancedStats]: 'advanced-stats',
  [PROFILE_ROUTE.customQuestions]: 'custom-questions',
  [PROFILE_ROUTE.gameSettings]: 'game-settings',
  [PROFILE_ROUTE.account]: 'account',
};

export type LeagueSubNavTabs =
  | 'overview'
  | 'teams'
  | 'standings'
  | 'players'
  | 'rules'
  | 'join'
  | 'manage';

export const LEAGUE_ROUTE = {
  overview: '/league/overview',
  standings: '/league/standings',
  teams: '/league/teams',
  players: '/league/players',
  rules: '/league/rules',
  join: '/league/join',
  manage: '/league/manage',
};

export const LEAGUE_TAB_MAP: Record<string, LeagueSubNavTabs> = {
  [LEAGUE_ROUTE.overview]: 'overview',
  [LEAGUE_ROUTE.standings]: 'standings',
  [LEAGUE_ROUTE.teams]: 'teams',
  [LEAGUE_ROUTE.players]: 'players',
  [LEAGUE_ROUTE.rules]: 'rules',
  [LEAGUE_ROUTE.join]: 'join',
  [LEAGUE_ROUTE.manage]: 'manage',
};
