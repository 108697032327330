import { Card, Stack, Box, Typography, Divider, useTheme } from '@mui/material';
import React from 'react';
import { Profile } from '../../../types/player';

type Props = {
  viewProfile: Profile;
};

const DailiesStreaksProfile = ({ viewProfile }: Props) => {
  const theme = useTheme();

  const { dailiesStats = null } = viewProfile;

  return (
    <Box pt={2}>
      <Typography py={1} fontWeight={500}>
        Streaks
      </Typography>

      <Card
        sx={{
          bgcolor: '#383d42f0',
        }}
      >
        <Stack direction={'row'} spacing={2} p={2} alignContent={'center'}>
          <Stack spacing={2} width={'100%'} height={'100%'}>
            <Typography
              textAlign={'center'}
              fontWeight={500}
              mb={1}
              sx={{
                textShadow: `0 0 0px ${theme.palette.dailies.main}, 0 0 0px ${theme.palette.dailies.main}, 0px 0px 16px ${theme.palette.dailies.main}`,
              }}
            >
              Played
            </Typography>

            <Typography variant="body2" textAlign={'center'}>
              Current
            </Typography>
            <Typography variant="h6" textAlign={'center'} fontWeight={600}>
              {dailiesStats?.consecutiveDailiesPlayedCurrent || 0}
            </Typography>

            <Typography variant="body2" textAlign={'center'}>
              All Time
            </Typography>
            <Typography variant="h6" textAlign={'center'} fontWeight={600}>
              {dailiesStats?.consecutiveDailiesPlayedAllTime || 0}
            </Typography>
          </Stack>

          <Divider flexItem orientation="vertical" />

          <Stack spacing={2} width={'100%'} height={'100%'}>
            <Typography
              textAlign={'center'}
              fontWeight={500}
              mb={1}
              sx={{
                textShadow: `0 0 0px ${theme.palette.dailies.main}, 0 0 0px ${theme.palette.dailies.main}, 0px 0px 16px ${theme.palette.dailies.main}`,
              }}
            >
              No Timeouts
            </Typography>

            <Typography variant="body2" textAlign={'center'}>
              Current
            </Typography>
            <Typography variant="h6" textAlign={'center'} fontWeight={600}>
              {dailiesStats?.consecutiveDailiesNoTimeOutsCurrent || 0}
            </Typography>

            <Typography variant="body2" textAlign={'center'}>
              All Time
            </Typography>
            <Typography variant="h6" textAlign={'center'} fontWeight={600}>
              {dailiesStats?.consecutiveDailiesNoTimeOutsAllTime || 0}
            </Typography>
          </Stack>

          <Divider flexItem orientation="vertical" />

          <Stack spacing={2} width={'100%'} height={'100%'}>
            <Typography
              textAlign={'center'}
              fontWeight={500}
              mb={1}
              sx={{
                textShadow: `0 0 0px ${theme.palette.dailies.main}, 0 0 0px ${theme.palette.dailies.main}, 0px 0px 16px ${theme.palette.dailies.main}`,
              }}
            >
              Scratches
            </Typography>

            <Typography variant="body2" textAlign={'center'}>
              Current
            </Typography>
            <Typography variant="h6" textAlign={'center'} fontWeight={600}>
              {dailiesStats?.consecutiveDailiesParOrBetterCurrent || 0}
            </Typography>

            <Typography variant="body2" textAlign={'center'}>
              All Time
            </Typography>
            <Typography variant="h6" textAlign={'center'} fontWeight={600}>
              {dailiesStats?.consecutiveDailiesParOrBetterAllTime || 0}
            </Typography>
          </Stack>

          <Divider flexItem orientation="vertical" />

          <Stack spacing={2} width={'100%'} height={'100%'}>
            <Typography
              textAlign={'center'}
              fontWeight={500}
              mb={1}
              sx={{
                textShadow: `0 0 0px ${theme.palette.dailies.main}, 0 0 0px ${theme.palette.dailies.main}, 0px 0px 16px ${theme.palette.dailies.main}`,
              }}
            >
              0-1-2
            </Typography>

            <Typography variant="body2" textAlign={'center'}>
              Current
            </Typography>
            <Typography variant="h6" textAlign={'center'} fontWeight={600}>
              {dailiesStats?.consecutiveDailies012Current || 0}
            </Typography>

            <Typography variant="body2" textAlign={'center'}>
              All Time
            </Typography>
            <Typography variant="h6" textAlign={'center'} fontWeight={600}>
              {dailiesStats?.consecutiveDailies012AllTime || 0}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default DailiesStreaksProfile;
