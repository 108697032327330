import React from 'react';
import RecentGames from './RecentGames/RecentGames';
import useFetchArenaOverview from './hooks/useFetchArenaOverview';
import useFetchArenaSchedule from './hooks/useFetchArenaSchedule';
import ArenaSummary from './ArenaSummary/ArenaSummary';
import ArenaLadder from './ArenaLadder/ArenaLadder';
import useFetchArenaLadder from './hooks/useFetchArenaLadder';
import ArenaAchievements from './ArenaAchievements/ArenaAchievements';
import useFetchUserArenaAchievements from './hooks/useFetchUserArenaAchievements';
import ArenaStats from './ArenaStats/ArenaStats';
import useFetchArenaAdvancedStats from './hooks/useFetchArenaAdvancedStats';
import { Profile } from '../../../types/player';
import ArenaTeamSummary from './ArenaTeamSummary/ArenaTeamSummary';

type Props = {
  isAdmin: boolean;
  arenaSeason: number;
  arenaSeasonEnd: number;
  profileId: string;
  viewProfile: Profile;
};

const ArenaProfile = ({
  isAdmin,
  arenaSeason,
  arenaSeasonEnd,
  profileId,
  viewProfile,
}: Props) => {
  const {
    data: arenaOverviewData,
    isLoading: arenaOverviewIsLoading,
    refetch: arenaOverviewRefetch,
  } = useFetchArenaOverview(viewProfile._id, arenaSeason);

  const arenaTeam = arenaOverviewData?.arenaTeam;
  const arenaPlayer = arenaOverviewData?.arenaPlayer;

  const { data: arenaLadderData } = useFetchArenaLadder(arenaSeason);

  const { data: arenaAchievementsData } = useFetchUserArenaAchievements(
    viewProfile._id,
    arenaSeason
  );

  const { data: arenaAdvancedStatsData } = useFetchArenaAdvancedStats(
    viewProfile._id,
    arenaSeason
  );

  return (
    <>
      <ArenaSummary
        arenaLadderData={arenaLadderData}
        arenaOverviewIsLoading={arenaOverviewIsLoading}
        arenaPlayer={arenaPlayer}
        arenaTeam={arenaTeam}
        canJoinTeam={viewProfile._id === profileId}
        onJoinSuccess={arenaOverviewRefetch}
        profileId={viewProfile._id}
        season={arenaSeason}
        seasonEnd={arenaSeasonEnd}
      />

      <RecentGames
        arenaPlayerGames={arenaPlayer?.games}
        profileId={viewProfile._id}
        season={arenaSeason}
      />

      <ArenaAchievements
        arenaAchievementsData={arenaAchievementsData}
        canUpdateShowcase={viewProfile._id === profileId}
        profileId={viewProfile._id}
      />

      <ArenaStats
        arenaAdvancedStatsData={arenaAdvancedStatsData}
        username={viewProfile?.username}
      />
    </>
  );
};

export default ArenaProfile;
