import React, { useEffect } from 'react';
import { Login as SharedLogin } from './login/';
import { useNavigate } from 'react-router-dom';
import { useProfileContext } from '../../contexts/ProfileProvider';

export const Login = () => {
  const navigate = useNavigate();
  const { profile } = useProfileContext();

  useEffect(() => {
    if (profile) {
      navigate('/home');
    }
  }, [navigate, profile]);

  return <SharedLogin />;
};
