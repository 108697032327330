import React, {
  FormEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, Button, Container, Stack } from '@mui/material';
import { InputPassword } from './InputPassword';
import { TitleAuth } from './TitleAuth';
import { AUTH_TYPE_BUTTON_LABEL } from './constants';
import { useAcceptInvite } from '../../../../hooks/auth/useAcceptInvite';

type PropTypes = {
  token: string;
};

export const VerifyInvite = ({ token }: PropTypes) => {
  const {
    data: acceptInviteData,
    error,
    loading,
    acceptInvite,
  } = useAcceptInvite();
  const [password, setPassword] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const passwordRef = useRef<HTMLInputElement>(null);

  console.log('verifyData', acceptInviteData);

  const handleSignUpClick = async () => {
    if (!password) {
      setPasswordErrorMessage('Please enter a password.');
    } else if (password.length < 8) {
      setPasswordErrorMessage(
        'Please provide a password that is at least 8 characters.'
      );
    } else {
      setPasswordErrorMessage('');
      acceptInvite(token, password);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    handleSignUpClick();
  };

  return (
    <Box
      sx={{
        p: 4,
        backgroundImage: 'url("/gradient-mountains.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: '100vh',
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          mt: 5,
        }}
      >
        <Box display={'flex'} justifyContent={'center'} mt={4}>
          <img
            alt="PAR"
            src="/pink_logo_long.png"
            style={{ maxWidth: '396px' }}
          ></img>
        </Box>

        <form onSubmit={handleSubmit}>
          <Stack spacing={2} padding={2}>
            <TitleAuth authType={'acceptInvite'} />

            <InputPassword
              inputRef={passwordRef as RefObject<HTMLInputElement>}
              passwordErrorMessage={passwordErrorMessage}
              password={password}
              setPassword={setPassword}
              setPasswordErrorMessage={setPasswordErrorMessage}
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              color={'secondary'}
            >
              {AUTH_TYPE_BUTTON_LABEL['signUp']}
            </Button>
          </Stack>
        </form>
      </Container>
    </Box>
  );
};
