import {
  Card,
  Stack,
  Box,
  Typography,
  Divider,
  useTheme,
  CircularProgress,
  CardActionArea,
  Link,
  Button,
} from '@mui/material';
import React, { useState } from 'react';
import TierPopup, { TierTooltip } from './TierPopup';
import { getTierBadge } from './utils';
import dayjs from 'dayjs';
import { ArenaLadderPlayer, ArenaPlayer } from '../types';
import { ArenaTeam } from '../../../arena-team/types';
import { useNavigate } from 'react-router-dom';
import { GiTrumpetFlag } from 'react-icons/gi';
import ArenaJoinTeamsDialog from '../../../arena/dialogs/ArenaJoinTeamsDialog';

type Props = {
  arenaLadderData: ArenaLadderPlayer[];
  arenaOverviewIsLoading: boolean;
  arenaPlayer: ArenaPlayer;
  arenaTeam: ArenaTeam;
  canJoinTeam: boolean;
  onJoinSuccess: () => void;
  profileId: string;
  season: number;
  seasonEnd?: number;
};

const ArenaSummary = ({
  arenaLadderData,
  arenaOverviewIsLoading,
  arenaPlayer,
  arenaTeam,
  canJoinTeam,
  onJoinSuccess,
  profileId,
  season,
  seasonEnd,
}: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [openArenaJointeamsDialog, setOpenArenaJointeamsDialog] =
    useState(false);

  const self = arenaLadderData?.find(
    (player) => player.profileId === profileId
  );

  const mmr = self?.mmr;

  const myTier = self?.tier;

  const everyoneInMyTier = arenaLadderData?.filter(
    (player) => player.tier === myTier && myTier !== 'Unranked'
  );

  const myRankIndex = everyoneInMyTier?.findIndex(
    (player) => player.profileId === profileId
  );

  const myRank = myRankIndex !== -1 ? myRankIndex + 1 : null;

  const handleViewTeam = () => {
    navigate(`/team/overview/${arenaTeam._id}`);
  };

  return (
    <>
      <Box pt={2}>
        <Stack
          direction={'row'}
          spacing={2}
          sx={{
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.border,
            borderRadius: 1,
          }}
        >
          {arenaTeam ? (
            <Stack
              spacing={1}
              justifyContent={'center'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <Card sx={{ width: 100, height: 100 }}>
                <CardActionArea onClick={handleViewTeam}>
                  <Box
                    component="img"
                    src={arenaTeam?.avatarUrl}
                    alt="image"
                    sx={{ width: 100, height: 100 }}
                  />
                </CardActionArea>
              </Card>

              <Link component={'button'} onClick={handleViewTeam}>
                <Typography
                  textAlign={'center'}
                  pr={1}
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                >
                  {arenaTeam?.teamName}
                </Typography>
              </Link>
            </Stack>
          ) : (
            <Stack
              spacing={1}
              justifyContent={'center'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <GiTrumpetFlag size={60} color="#a29792" />
              <Typography variant="body2" textAlign={'center'}>
                Free Agent
              </Typography>

              {canJoinTeam && (
                <Button
                  variant={'outlined'}
                  color="creamOrange"
                  onClick={() => setOpenArenaJointeamsDialog(true)}
                >
                  Join a team
                </Button>
              )}
            </Stack>
          )}

          <Divider flexItem orientation="vertical" />

          <Stack spacing={1} width={'100%'} height={'100%'}>
            <Typography
              variant="h5"
              sx={{
                color: theme.palette.primary.light,
                textShadow: `0 0 1px ${theme.palette.primary.main}, 10px 0px 20px ${theme.palette.primary.main}, 0px 0px 16px ${theme.palette.primary.main}`,
              }}
            >
              {/* 2024 SEASON {season} */}
              2024 PRESEASON
            </Typography>

            <Divider />

            <Stack
              direction={'row'}
              spacing={2}
              width={'100%'}
              justifyContent={'space-between'}
            >
              <Typography fontWeight={600}>Games Played</Typography>
              <Typography
                fontWeight={600}
                pr={1}
                sx={{
                  color: theme.palette.primary.main,
                }}
              >
                {arenaPlayer?.gamesPlayed || 0}
              </Typography>
            </Stack>

            <Divider />

            <Stack
              direction={'row'}
              spacing={2}
              width={'100%'}
              justifyContent={'space-between'}
            >
              <Typography fontWeight={600}>Best Balls</Typography>
              <Typography
                fontWeight={600}
                pr={1}
                sx={{
                  color: theme.palette.primary.main,
                }}
              >
                {arenaPlayer?.totalBestBalls || 0}
              </Typography>
            </Stack>

            <Divider />

            <Typography variant="caption" color="lightgray" fontWeight={600}>
              Season Ends:{' '}
              {seasonEnd ? dayjs(seasonEnd).format('MMMM D, YYYY') : 'TBA'}
            </Typography>
          </Stack>
        </Stack>
      </Box>

      {openArenaJointeamsDialog && (
        <ArenaJoinTeamsDialog
          open={openArenaJointeamsDialog}
          close={() => setOpenArenaJointeamsDialog(false)}
          onJoinSuccess={onJoinSuccess}
          profileId={profileId}
          season={season}
        />
      )}
    </>
  );
};

export default ArenaSummary;
