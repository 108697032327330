import {
  Avatar,
  Chip,
  IconButton,
  Link,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import useGetRankings from './useGetRankings';
import { resizeImage } from '../../../api/cloudinary';
import { useNavigate } from 'react-router-dom';
import { RankedUser } from './types';
import { round } from 'lodash';
import useGetPunchcardRankings from './useGetPunchcardRankings';
import { HEADER_HEIGHT } from '../../../components/header';
import PunchcardView from './PunchcardView';

const MmrLeaderboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [selectedLeaderboard, setSelectedLeaderboard] = useState<
    'mmr' | 'punchcard'
  >('mmr');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [hoveredPunchcardProfileId, setHoveredPunchcardProfileId] = useState<
    string | null
  >(null);
  const openPunchcardPopover = Boolean(anchorEl);

  const { data: mmrRankings } = useGetRankings(selectedLeaderboard === 'mmr');
  const { data: punchcardRankings } = useGetPunchcardRankings(
    selectedLeaderboard === 'punchcard'
  );

  const data = selectedLeaderboard === 'mmr' ? mmrRankings : punchcardRankings;

  const handleUsernameClick = async (e: any, player: RankedUser) => {
    e.stopPropagation();
    navigate(`/profile/overview/${player.profileId}`);
  };

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    profileId: string
  ) => {
    if (selectedLeaderboard === 'mmr') return;
    setAnchorEl(event.currentTarget);
    setHoveredPunchcardProfileId(profileId);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack
        sx={{
          border: '1px solid',
          borderColor: theme.palette.border,
          borderRadius: 1,
        }}
      >
        <Typography
          variant="h6"
          py={1}
          fontWeight={600}
          letterSpacing={2}
          textAlign={'center'}
        >
          LEADERBOARD
        </Typography>

        <Stack
          direction={'row'}
          spacing={2}
          px={1}
          pb={1}
          justifyContent={'center'}
        >
          <Chip
            variant={selectedLeaderboard === 'mmr' ? 'filled' : 'outlined'}
            label="MMR"
            onClick={() => setSelectedLeaderboard('mmr')}
          />
          <Chip
            variant={
              selectedLeaderboard === 'punchcard' ? 'filled' : 'outlined'
            }
            label="PUNCHCARD"
            onClick={() => setSelectedLeaderboard('punchcard')}
          />
        </Stack>

        <TableContainer sx={{ height: `calc(100vh - ${HEADER_HEIGHT}px)` }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow
                sx={{
                  '& td, & th': {
                    border: 'none',
                  },
                }}
              >
                <TableCell width={'5%'}>Rank</TableCell>
                <TableCell width={'50%'}>Player</TableCell>
                <TableCell
                  sx={{
                    textAlign: 'right',
                    color:
                      selectedLeaderboard === 'punchcard'
                        ? theme.palette.secondary.main
                        : theme.palette.ranked.main,
                  }}
                >
                  {selectedLeaderboard === 'mmr' ? 'MMR' : 'Level'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row: any, index: any) => (
                <TableRow
                  key={row.profileId}
                  hover
                  onMouseEnter={(e) => handlePopoverOpen(e, row.profileId)}
                  onMouseLeave={handlePopoverClose}
                  sx={{
                    '& td, & th': {
                      border: 'none',
                    },
                  }}
                >
                  <TableCell sx={{ textAlign: 'center' }}>
                    {index + 1}
                  </TableCell>
                  <TableCell>
                    <Stack direction={'row'} spacing={2}>
                      <Avatar
                        src={resizeImage(row.avatarPublicId, 50, 50)}
                        variant="rounded"
                        sx={{
                          height: 25,
                          width: 25,
                        }}
                      >
                        {!row.avatarUrl ? row.username.charAt(0) : null}
                      </Avatar>
                      <Link
                        component="button"
                        variant="body2"
                        onClick={(e) => handleUsernameClick(e, row)}
                        textAlign={'left'}
                      >
                        <Typography
                          sx={{
                            maxWidth: 150,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        >
                          {row.username}
                        </Typography>
                      </Link>
                    </Stack>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'right',
                      color:
                        selectedLeaderboard === 'punchcard'
                          ? theme.palette.secondary.main
                          : theme.palette.ranked.main,
                      fontWeight: 600,
                    }}
                  >
                    {selectedLeaderboard === 'punchcard'
                      ? round(row.punchcardLevel, 1)
                      : row.trueSkill.mmr
                      ? round(row.trueSkill.mmr)
                      : ''}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Popover
        sx={{
          pointerEvents: 'none',
          '& .MuiPaper-root': {
            bgcolor: 'black',
            border: '1px solid',
            borderColor: '#ff1887',
          },
        }}
        open={openPunchcardPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <PunchcardView profileId={hoveredPunchcardProfileId} />
      </Popover>
    </>
  );
};

export default MmrLeaderboard;
