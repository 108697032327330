import {
  Box,
  Button,
  Paper,
  Stack,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { LABEL_COLORS } from './constants';
import { getIcon, getPunchcardValue } from './utils';
import { SoloPunchcardInfoDialog } from './dialogs/SoloPunchcardInfoDialog';
import { Punchcard } from './types';
import { CreateGameConfig } from '../../../types/game';
import { LoggedInUser } from '../../../types/user';
import CategoryDetails from './CategoryDetails';
import { ValorantButton } from '../../quick-play/ValorantButton/ValorantButton';
import { Maintenance } from '../../../types';
import PunchcardTrophiesDialog from './dialogs/PunchcardTrophiesDialog';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(1),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.border,
  borderRadius: theme.shape.borderRadius,
  height: '100%',
  width: '100%',
  padding: theme.spacing(2),
  whiteSpace: 'nowrap',
  '&:hover': {
    outline: '2px solid',
    outlineColor: '#62163a',
    backgroundColor: 'transparent',
  },
  '&.Mui-selected': {
    outline: '2px solid',
    outlineColor: '#ff1887',
    backgroundColor: 'transparent',
  },
  '&.Mui-selected:hover': {
    outline: '2px solid',
    outlineColor: '#ff1887',
    backgroundColor: 'transparent',
  },
}));

type Props = {
  handlePlayClick: (
    opts: CreateGameConfig,
    loggedInUser?: LoggedInUser
  ) => void;
  maintenance: Maintenance;
  playClicked: boolean;
  profileId: string;
  punchcard?: Punchcard;
};

const PunchcardPage = ({
  handlePlayClick,
  maintenance,
  playClicked,
  profileId,
  punchcard,
}: Props) => {
  const theme = useTheme();

  const [openSoloPunchcardInfo, setOpenSoloPunchcardInfo] = useState(false);
  const [openTrophiesDialog, setOpenTrophiesDialog] = useState(false);
  const [openPlaySoloModal, setOpenPlaySoloModal] = useState(false);
  const [categorySet, setCategorySet] = useState<string>('all');

  const handlePlayPunchcard = () => {
    const createGameConfigs: CreateGameConfig = {
      allowSpectators: false,
      categorySet,
      mode: 'solo',
      isPunchcard: true,
    };
    console.log('createGameConfigs', createGameConfigs);
    handlePlayClick(createGameConfigs);
  };

  const handleCategoryChange = (
    event: React.MouseEvent<HTMLElement>,
    newCategory: string
  ) => {
    if (newCategory !== null) {
      setCategorySet(newCategory);
    }
  };
  return (
    <>
      <Stack>
        <StyledToggleButtonGroup
          value={categorySet}
          exclusive
          onChange={handleCategoryChange}
        >
          <StyledToggleButton value={'all'}>
            <Stack spacing={2} minWidth={'160px'}>
              <Typography variant="body2" fontWeight={600}>
                All
              </Typography>
              <Stack
                direction={'row'}
                spacing={3}
                height={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  py: 2,
                  px: 4,
                  border: '1px solid',
                  borderColor: 'border',
                  borderRadius: 1,
                }}
              >
                <Stack spacing={0.5}>
                  <Typography
                    textAlign={'right'}
                    color={LABEL_COLORS['average']}
                  >
                    {getPunchcardValue(punchcard, 'all', 'bestAverage')}
                  </Typography>
                  <Typography textAlign={'right'} color={LABEL_COLORS['best']}>
                    {getPunchcardValue(punchcard, 'all', 'best')}
                  </Typography>
                  <Typography
                    textAlign={'right'}
                    color={LABEL_COLORS['gamesPlayed']}
                  >
                    {getPunchcardValue(punchcard, 'all', 'gamesPlayed')}
                  </Typography>
                </Stack>
                <Stack spacing={0.5}>
                  {getIcon(
                    punchcard,
                    'all',
                    'bestAverage',
                    getPunchcardValue(punchcard, 'all', 'bestAverage')
                  )}
                  {getIcon(
                    punchcard,
                    'all',
                    'best',
                    getPunchcardValue(punchcard, 'all', 'best')
                  )}
                  {getIcon(
                    punchcard,
                    'all',
                    'gamesPlayed',
                    getPunchcardValue(punchcard, 'all', 'gamesPlayed')
                  )}
                </Stack>
              </Stack>
            </Stack>
          </StyledToggleButton>

          <StyledToggleButton value={'popCulture'}>
            <Stack spacing={2} minWidth={'160px'}>
              <Typography variant="body2" fontWeight={600}>
                Pop Culture
              </Typography>
              <Stack
                direction={'row'}
                spacing={3}
                height={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  py: 2,
                  px: 4,
                  border: '1px solid',
                  borderColor: 'border',
                  borderRadius: 1,
                }}
              >
                <Stack spacing={0.5}>
                  <Typography
                    textAlign={'right'}
                    color={LABEL_COLORS['average']}
                  >
                    {getPunchcardValue(punchcard, 'popCulture', 'bestAverage')}
                  </Typography>
                  <Typography textAlign={'right'} color={LABEL_COLORS['best']}>
                    {getPunchcardValue(punchcard, 'popCulture', 'best')}
                  </Typography>
                  <Typography
                    textAlign={'right'}
                    color={LABEL_COLORS['gamesPlayed']}
                  >
                    {getPunchcardValue(punchcard, 'popCulture', 'gamesPlayed')}
                  </Typography>
                </Stack>
                <Stack spacing={0.5}>
                  {getIcon(
                    punchcard,
                    'popCulture',
                    'bestAverage',
                    getPunchcardValue(punchcard, 'popCulture', 'bestAverage')
                  )}
                  {getIcon(
                    punchcard,
                    'popCulture',
                    'best',
                    getPunchcardValue(punchcard, 'popCulture', 'best')
                  )}
                  {getIcon(
                    punchcard,
                    'popCulture',
                    'gamesPlayed',
                    getPunchcardValue(punchcard, 'popCulture', 'gamesPlayed')
                  )}
                </Stack>
              </Stack>
            </Stack>
          </StyledToggleButton>

          <StyledToggleButton value={'humanities'}>
            <Stack spacing={2} minWidth={'160px'}>
              <Typography variant="body2" fontWeight={600}>
                Humanities
              </Typography>
              <Stack
                direction={'row'}
                spacing={3}
                height={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  py: 2,
                  px: 4,
                  border: '1px solid',
                  borderColor: 'border',
                  borderRadius: 1,
                }}
              >
                <Stack spacing={0.5}>
                  <Typography
                    textAlign={'right'}
                    color={LABEL_COLORS['average']}
                  >
                    {getPunchcardValue(punchcard, 'humanities', 'bestAverage')}
                  </Typography>
                  <Typography textAlign={'right'} color={LABEL_COLORS['best']}>
                    {getPunchcardValue(punchcard, 'humanities', 'best')}
                  </Typography>
                  <Typography
                    textAlign={'right'}
                    color={LABEL_COLORS['gamesPlayed']}
                  >
                    {getPunchcardValue(punchcard, 'humanities', 'gamesPlayed')}
                  </Typography>
                </Stack>
                <Stack spacing={0.5}>
                  {getIcon(
                    punchcard,
                    'humanities',
                    'bestAverage',
                    getPunchcardValue(punchcard, 'humanities', 'bestAverage')
                  )}
                  {getIcon(
                    punchcard,
                    'humanities',
                    'best',
                    getPunchcardValue(punchcard, 'humanities', 'best')
                  )}
                  {getIcon(
                    punchcard,
                    'humanities',
                    'gamesPlayed',
                    getPunchcardValue(punchcard, 'humanities', 'gamesPlayed')
                  )}
                </Stack>
              </Stack>
            </Stack>
          </StyledToggleButton>

          <StyledToggleButton value={'hardSoftSciences'}>
            <Stack spacing={2} minWidth={'160px'}>
              <Typography variant="body2" fontWeight={600}>
                Hard & Soft Sciences
              </Typography>
              <Stack
                direction={'row'}
                spacing={3}
                height={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  py: 2,
                  px: 4,
                  border: '1px solid',
                  borderColor: 'border',
                  borderRadius: 1,
                }}
              >
                <Stack spacing={0.5}>
                  <Typography
                    textAlign={'right'}
                    color={LABEL_COLORS['average']}
                  >
                    {getPunchcardValue(
                      punchcard,
                      'hardSoftSciences',
                      'bestAverage'
                    )}
                  </Typography>
                  <Typography textAlign={'right'} color={LABEL_COLORS['best']}>
                    {getPunchcardValue(punchcard, 'hardSoftSciences', 'best')}
                  </Typography>
                  <Typography
                    textAlign={'right'}
                    color={LABEL_COLORS['gamesPlayed']}
                  >
                    {getPunchcardValue(
                      punchcard,
                      'hardSoftSciences',
                      'gamesPlayed'
                    )}
                  </Typography>
                </Stack>
                <Stack spacing={0.5}>
                  {getIcon(
                    punchcard,
                    'hardSoftSciences',
                    'bestAverage',
                    getPunchcardValue(
                      punchcard,
                      'hardSoftSciences',
                      'bestAverage'
                    )
                  )}
                  {getIcon(
                    punchcard,
                    'hardSoftSciences',
                    'best',
                    getPunchcardValue(punchcard, 'hardSoftSciences', 'best')
                  )}
                  {getIcon(
                    punchcard,
                    'hardSoftSciences',
                    'gamesPlayed',
                    getPunchcardValue(
                      punchcard,
                      'hardSoftSciences',
                      'gamesPlayed'
                    )
                  )}
                </Stack>
              </Stack>
            </Stack>
          </StyledToggleButton>

          <StyledToggleButton value={'fineArts'}>
            <Stack spacing={2} minWidth={'160px'}>
              <Typography variant="body2" fontWeight={600}>
                Fine Arts
              </Typography>
              <Stack
                direction={'row'}
                spacing={3}
                height={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  py: 2,
                  px: 4,
                  border: '1px solid',
                  borderColor: 'border',
                  borderRadius: 1,
                }}
              >
                <Stack spacing={0.5}>
                  <Typography
                    textAlign={'right'}
                    color={LABEL_COLORS['average']}
                  >
                    {getPunchcardValue(punchcard, 'fineArts', 'bestAverage')}
                  </Typography>
                  <Typography textAlign={'right'} color={LABEL_COLORS['best']}>
                    {getPunchcardValue(punchcard, 'fineArts', 'best')}
                  </Typography>
                  <Typography
                    textAlign={'right'}
                    color={LABEL_COLORS['gamesPlayed']}
                  >
                    {getPunchcardValue(punchcard, 'fineArts', 'gamesPlayed')}
                  </Typography>
                </Stack>
                <Stack spacing={0.5}>
                  {getIcon(
                    punchcard,
                    'fineArts',
                    'bestAverage',
                    getPunchcardValue(punchcard, 'fineArts', 'bestAverage')
                  )}
                  {getIcon(
                    punchcard,
                    'fineArts',
                    'best',
                    getPunchcardValue(punchcard, 'fineArts', 'best')
                  )}
                  {getIcon(
                    punchcard,
                    'fineArts',
                    'gamesPlayed',
                    getPunchcardValue(punchcard, 'fineArts', 'gamesPlayed')
                  )}
                </Stack>
              </Stack>
            </Stack>
          </StyledToggleButton>
        </StyledToggleButtonGroup>

        <Box display="flex" justifyContent={'right'}>
          <Button onClick={() => setOpenTrophiesDialog(true)}>
            Trophy Definitions
          </Button>
        </Box>

        <Box pt={2} pb={4} display="flex" justifyContent={'center'}>
          <ValorantButton
            disabled={playClicked || maintenance?.scheduled}
            label={`PLAY ${
              categorySet === 'hardSoftSciences'
                ? 'H&S SCIENCES'
                : categorySet?.toUpperCase()
            }`}
            loading={playClicked}
            loadingLabel={'CREATING'}
            onClick={handlePlayPunchcard}
            size="xl"
          />
        </Box>

        {punchcard && punchcard[categorySet] && (
          <CategoryDetails
            profileId={profileId}
            punchcard={punchcard}
            categorySet={categorySet}
          />
        )}
      </Stack>

      <SoloPunchcardInfoDialog
        close={() => setOpenSoloPunchcardInfo(false)}
        open={openSoloPunchcardInfo}
      />

      {/* {categorySet && (
        <SoloPunchcardDetailsDialog
          categorySet={categorySet}
          close={() => setOpenPlaySoloModal(false)}
          handlePlayPunchcard={handlePlayPunchcard}
          open={openPlaySoloModal}
          profileId={profileId}
          punchcard={punchcard}
        />
      )} */}

      <PunchcardTrophiesDialog
        open={openTrophiesDialog}
        close={() => setOpenTrophiesDialog(false)}
      />
    </>
  );
};

export default PunchcardPage;
