import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGameStatesMainServer } from './hooks/useGameStatesMainServer';
import { Countdown } from './countdown';
import { Game } from './game';
import { useGameStatesInputServer } from './hooks/useGameStatesInputServer';
import { ScoreScreenLoading } from './ScoreScreenLoading';
import { ScoreScreen } from './score-screen/ScoreScreen';
import { Container, Typography } from '@mui/material';
import { useGameContext } from '../../contexts/GameProvider';
import { useProfileContext } from '../../contexts/ProfileProvider';
import {
  useInputSocketContext,
  useSocketContext,
  useSpectateSocketContext,
} from '../../contexts/SocketProvider';
import { FetchUpdatePlayMenuSettingsResponse } from '../../api/response-types/responseTypes';
import useUpdateGameSettings from '../game-settings/useUpdateGameSettings';
import { isMobile } from 'react-device-detect';
import MobileGame from './mobile/MobileGame';
import { Scorecard } from './mobile/Scorecard';
import { CountdownGameStart } from './mobile/CountdownGameStart';
import { useQuickPlayGamesContext } from '../../contexts/QuickPlayGamesProvider';
import useQuickPlayManager from '../lobby/hooks/useQuickPlayManager';

type LocationState = {
  fromQuickPlay: boolean;
  fromDailies: boolean;
  fromArena: boolean;
  rejoin: boolean;
};

export const GameSession = () => {
  const location = useLocation();
  const locationState = location.state as LocationState;
  const navigate = useNavigate();

  const { profile, mutateProfile } = useProfileContext();
  const { socket } = useSocketContext();
  const { inputSocket } = useInputSocketContext();
  const { spectateSocket } = useSpectateSocketContext();
  const { gameId, teamPickingStatus } = useGameContext();
  const { allAchievements } = useQuickPlayGamesContext();
  const { leaveChat } = useQuickPlayManager();
  const {
    achievementNames,
    arenaGame,
    arenaPlayersProjectionAndTiers,
    arenaSet,
    arenaTeamRoundScores,
    arenaTeams,
    arenaTeamsDetailed,
    categorySet,
    categorySelections,
    gameType,
    countdown,
    dailySet,
    delayBeforeQuestionRevealCount,
    detailedPlayers,
    gameClock,
    gameOver,
    isPunchcard,
    isRanked,
    leagueGameType,
    leagueMatch,
    mathFormula,
    missedDaily,
    mode,
    nextQuestionDelayCount,
    participantLosses,
    paused,
    properNameShortAnswer,
    questionImgUrl,
    questionIndex,
    questionMetadata,
    questionSet,
    requestDetailedPlayers,
    resetDelayClocks,
    revealAnswer,
    revealFullQuestion,
    revealQuestion,
    arenaAchievements,
    arenaUpdateTrueSkill,
    spectatorList,
    timerLimitReached,
    tournamentChampions,
    unrevealedIndex,
    willPause,
    resetStatesFromMainServer,
    test,
  } = useGameStatesMainServer(profile?._id, gameId);

  const {
    arenaTeamLiveRoundScores,
    assists,
    fuzzy,
    players,
    playerSelf,
    playerThem,
    freezeGameClock,
    teams,
    myTeam,
    opponentTeam,
    penalty,
    roundScores,
    teamBestBalls,
    resetStatesFromInputServer,
    updateScoresOnTimeout,
  } = useGameStatesInputServer(gameId, questionIndex);

  const arenaProjection =
    arenaPlayersProjectionAndTiers?.[profile?._id]?.projection;

  const handleOnSuccess = (data: FetchUpdatePlayMenuSettingsResponse) => {
    const profile = data.data.profile;
    mutateProfile(profile);
  };

  const { updateGameSettings } = useUpdateGameSettings(handleOnSuccess);

  const handleDisplaySizeChange = (_e: any, size: 'sm' | 'md' | 'lg') => {
    const updatedSettings = {
      ...profile.settings,
      displaySize: size,
    };

    updateGameSettings(updatedSettings);
  };

  useEffect(() => {
    if (!gameId) {
      navigate('/home');
    }
  }, [gameId]);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const countdownRef = useRef<number>();
  const questionIndexRef = useRef<number>();

  const requestRejoinData = () => {
    if (isOnline && socket && inputSocket) {
      try {
        socket.emit('rejoinGameRequest', {
          profileId: profile._id,
          gameId: gameId,
        });
        inputSocket.emit('rejoinGameRequest', {
          profileId: profile._id,
          gameId: gameId,
        });
      } catch (error) {
        console.error('Rejoin data fetch failed', error);
      }
    } else {
      // console.log('Browser is offline, retrying...');
      setTimeout(requestRejoinData, 3000); // Retry after 3 seconds
    }
  };

  useEffect(() => {
    const goOnline = () => {
      console.log('GO ONLINE');
      setIsOnline(true);
      requestRejoinData();
    };

    const goOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  useEffect(() => {
    if (!locationState || !locationState.rejoin) return;
    requestRejoinData();
  }, [locationState]);

  countdownRef.current = countdown;
  questionIndexRef.current = questionIndex;

  // console.log('countdown', countdown);
  // console.log('players', players);
  // console.log('questionMetadata', questionMetadata);
  // console.log('questionIndex', questionIndex);

  useEffect(() => {
    // reset states after each question.
    // first question is set from init, so no need to reset it
    if (questionIndex === undefined || questionIndex < 1) return;
    resetStatesFromMainServer();
    resetStatesFromInputServer();
  }, [questionIndex]);

  useEffect(() => {
    if (!timerLimitReached || !questionMetadata) return;
    updateScoresOnTimeout(questionMetadata.par, questionMetadata.timeLimit);
  }, [timerLimitReached]);

  const handleLeaveScoreScreen = () => {
    // setGameId(null);
    // setGameDataFromServer(null);
    // setLeagueGameType(null);
    leaveChat(profile._id, gameId);
  };

  const showGameStarting = countdown === undefined && !gameOver;
  const gameInProgress =
    gameId &&
    questionMetadata &&
    questionIndex !== undefined &&
    countdown <= 0 &&
    profile &&
    players &&
    !gameOver;
  const showScoreScreenLoading =
    gameId && questionSet && !gameOver && !detailedPlayers;
  const showScorescreen =
    gameId && !!questionSet && gameOver && !!detailedPlayers;

  const isTeamSpectator =
    !!teamPickingStatus?.spectators &&
    !!teamPickingStatus?.spectators[profile?._id];

  useEffect(() => {
    // console.log(
    //   'gameInProgress',
    //   gameInProgress,
    //   'gameId',
    //   gameId,
    //   'questionMetadata',
    //   questionMetadata,
    //   'questionIndex',
    //   questionIndex,
    //   'countdown',
    //   countdown,
    //   'profile',
    //   !!profile,
    //   'gameOver',
    //   gameOver
    // );
  }, [
    countdown,
    gameId,
    gameInProgress,
    gameOver,
    profile,
    questionIndex,
    questionMetadata,
  ]);

  const achievements = useMemo(
    () =>
      achievementNames && allAchievements
        ? achievementNames.map((an) => {
            const achievement = allAchievements.find((a) => a.name === an.name);
            return {
              ...achievement,
              new: an.new,
            };
          })
        : [],
    [achievementNames, allAchievements]
  );

  return (
    <>
      {isMobile ? (
        <Container maxWidth="sm">
          {showScorescreen ? (
            <Scorecard
              dailySet={dailySet}
              missedDaily={missedDaily}
              players={detailedPlayers}
              profileId={profile._id}
              profile={profile}
              questions={questionSet}
            />
          ) : (
            <>
              {showGameStarting ? (
                <Typography
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  Game starting...
                </Typography>
              ) : null}
              {countdown > 0 ? (
                <CountdownGameStart countdown={countdown} />
              ) : null}
              {gameInProgress && (
                <>
                  <Typography variant="caption">
                    {Date.now() - test[test.length - 2]}
                  </Typography>
                  {/* <MobileGame
                    countdown={countdown}
                    delayBeforeQuestionRevealCount={
                      delayBeforeQuestionRevealCount
                    }
                    gameClock={gameClock}
                    gameId={gameId}
                    inputSocket={inputSocket}
                    penalty={penalty}
                    player={playerSelf}
                    profileId={profile._id}
                    properNameShortAnswer={properNameShortAnswer}
                    questionIndex={questionIndex}
                    questionMetadata={questionMetadata}
                    revealAnswer={revealAnswer}
                    revealFullQuestion={revealFullQuestion}
                    revealQuestion={revealQuestion}
                    socket={socket}
                    timerLimitReached={timerLimitReached}
                    totalNumQuestions={questionMetadata?.questionSetCount}
                    unrevealedIndex={unrevealedIndex}
                  /> */}
                </>
              )}
            </>
          )}
        </Container>
      ) : (
        <>
          {showGameStarting ? (
            <Typography
              sx={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              Game starting...
            </Typography>
          ) : null}
          {countdown > 0 ? <Countdown countdown={countdown} /> : null}
          {gameInProgress ? (
            <Game
              arenaProjection={arenaProjection}
              arenaPlayersProjectionAndTiers={arenaPlayersProjectionAndTiers}
              answer={revealAnswer}
              arenaGame={arenaGame}
              arenaTeams={arenaTeams}
              arenaTeamLiveRoundScores={arenaTeamLiveRoundScores}
              assists={assists}
              category={questionMetadata.category}
              categorySet={categorySet}
              categorySelections={categorySelections}
              clearAfterPenalty={profile.settings?.clearAfterPenalty}
              dailySet={dailySet}
              delayBeforeQuestionRevealCount={delayBeforeQuestionRevealCount}
              displaySize={profile.settings?.displaySize}
              forceReconnectSocketLiveInput={() => {}}
              freezeGameClock={freezeGameClock}
              fullQuestion={revealFullQuestion}
              fuzzy={fuzzy}
              gameClock={gameClock}
              gameId={gameId}
              gameOver={gameOver}
              gameType={gameType}
              handleDisplaySizeChange={handleDisplaySizeChange}
              handlePause={() => {}}
              isAdmin={profile.admin}
              isTeamSpectator={isTeamSpectator}
              leagueMatch={leagueMatch}
              mathFormula={mathFormula}
              missedDaily={missedDaily}
              mode={mode}
              myTeam={myTeam}
              nextQuestionDelayCount={nextQuestionDelayCount}
              opponentTeam={opponentTeam}
              par={questionMetadata.par}
              paused={paused}
              penalty={penalty}
              playerSelf={playerSelf}
              players={players}
              playerThem={playerThem}
              profileId={profile._id}
              properNameShortAnswer={properNameShortAnswer}
              question={revealQuestion}
              questionImgUrl={questionImgUrl}
              questionIndex={questionIndex}
              rejoin={false}
              resetDelayClocks={resetDelayClocks}
              roundScores={roundScores}
              showConnectionStats={profile.settings?.connectionStats}
              socket={socket}
              inputSocket={inputSocket}
              spectateSocket={spectateSocket}
              spectate={false}
              spectateGameId={null}
              spectatorList={spectatorList}
              submittedByUsername={questionMetadata.submittedByUsername}
              teamBestBalls={teamBestBalls}
              teams={teams}
              timerLimitReached={timerLimitReached}
              totalNumQuestions={questionMetadata.questionSetCount}
              unrevealedIndex={unrevealedIndex}
              username={profile.username}
              willPause={willPause}
            />
          ) : null}
          {showScoreScreenLoading ? <ScoreScreenLoading /> : null}
          {showScorescreen ? (
            <ScoreScreen
              achievements={achievements}
              arenaGame={arenaGame}
              arenaProjection={arenaProjection}
              arenaPlayersProjectionAndTiers={arenaPlayersProjectionAndTiers}
              arenaSet={arenaSet}
              arenaTeamRoundScores={arenaTeamRoundScores}
              arenaTeamsDetailed={arenaTeamsDetailed}
              arenaTeams={arenaTeams}
              arenaTeamLiveRoundScores={arenaTeamLiveRoundScores}
              categorySet={categorySet}
              cutoff={null}
              dailySet={dailySet}
              detailedPlayers={detailedPlayers}
              fromDailies={locationState?.fromDailies}
              fromArena={locationState?.fromArena}
              fromQuickPlay={locationState?.fromQuickPlay}
              gameId={gameId}
              gameOver={gameOver}
              gameType={gameType}
              handleLeaveScoreScreen={handleLeaveScoreScreen}
              isPunchcard={isPunchcard}
              isRanked={isRanked}
              leagueGameType={leagueGameType}
              missedDaily={missedDaily}
              mode={mode}
              myTeam={myTeam}
              opponentTeam={opponentTeam}
              participantLosses={participantLosses}
              profile={profile}
              questionSet={questionSet}
              arenaAchievements={arenaAchievements}
              arenaUpdateTrueSkill={arenaUpdateTrueSkill}
              socket={socket}
              spectateSocket={spectateSocket}
              spectate={isTeamSpectator}
              spectatorList={spectatorList}
              tournamentChampions={tournamentChampions}
            />
          ) : null}
        </>
      )}
    </>
  );
};
