import React from 'react';
import { Badge, BadgeProps, Grid, Typography, styled } from '@mui/material';
import { addPlusToPositiveScores } from '../../../../../utils/game';
import {
  TOTAL_WIDTH,
  SLANTED_LINE_WIDTH,
  MIRROR_OFFSET,
} from '../ActionAvatar/PlayerSlantedLineTitle';

export const StyledPenaltyBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 32,
    right: 24,
    height: '24px',
    width: '24px',
    borderRadius: '100%',
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.common.white,
  },
}));

type Props = {
  penalty?: number;
  playerInfoDisplayPosition: 'left' | 'right';
  score: number;
};
export const ScorePenaltyColumnsDisplay = ({
  penalty,
  playerInfoDisplayPosition,
  score,
}: Props) => {
  return (
    <Grid
      container
      direction={playerInfoDisplayPosition === 'left' ? 'row' : 'row-reverse'}
      className="ScorePenaltyColumnsGrid"
      width={TOTAL_WIDTH - SLANTED_LINE_WIDTH - MIRROR_OFFSET}
    >
      <Grid item xs={6} textAlign={'center'}>
        <Typography variant="overline">Score</Typography>
        <Typography
          fontSize={21}
          sx={{
            textShadow: '0px 0px 12px #ffffff, 0px 0px 12px #f8ee95',
          }}
        >
          {addPlusToPositiveScores(score)}
        </Typography>
      </Grid>
      <Grid item xs={6} textAlign={'center'}>
        <Typography variant="overline">Penalty</Typography>
        {!penalty ? (
          <Typography fontSize={21} color={'gray'}>
            0
          </Typography>
        ) : (
          <StyledPenaltyBadge badgeContent={penalty} color="warning" />
        )}
      </Grid>
    </Grid>
  );
};
