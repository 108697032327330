import React, { useEffect, useState } from 'react';
import { fetchGet, fetchPost } from '../../api/common';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Typography, useTheme } from '@mui/material';

import { useSnackbar } from 'notistack';
import { useProfileContext } from '../../contexts/ProfileProvider';
import { CustomQuestion } from '../../types/custom-questions';
import { utcToLocalDateTime } from '../../utils/date-time';
import { CustomQuestionsEditDialog } from './CustomQuestionsEditDialog';

const ReviewQuestions = () => {
  const theme = useTheme();

  const { profile } = useProfileContext();

  const { enqueueSnackbar } = useSnackbar();
  const [customQuestions, setCustomQuestions] = useState<CustomQuestion[]>();
  const [clickedQuestion, setClickedQuestion] = useState<CustomQuestion>();
  const [openEditModal, setOpenEditModal] = useState(false);

  // useEffect(() => {
  //   fetchCustomQuestions();
  // }, []);

  const fetchCustomQuestions = async () => {
    const res = await fetchGet(`/custom-questions/all/${profile._id}`);
    setCustomQuestions(res);
  };

  const handleReviewClick = async (question: CustomQuestion) => {
    setClickedQuestion(question);
    setOpenEditModal(true);
  };

  const handleReject = async (questionId: string, rejectionComment: string) => {
    const body = {
      profileId: profile._id,
      questionId,
      rejectionComment,
    };
    const res = await fetchPost('/custom-questions/reject-question', body);
    fetchCustomQuestions();
    setOpenEditModal(false);
    enqueueSnackbar(`Question rejected`, { variant: 'warning' });
    console.log('handleRejectClick', res);
  };

  const handleApprove = async (questionId: string, editedQuestion: any) => {
    console.log('editedQuestion', editedQuestion);
    const body = {
      profileId: profile._id,
      questionId,
      editedQuestion,
    };
    const res = await fetchPost('/custom-questions/approve-question', body);
    fetchCustomQuestions();
    setOpenEditModal(false);
    enqueueSnackbar(`Question approved`, { variant: 'success' });
    console.log('handleApprove', res);
  };

  const handleEditClick = (q: CustomQuestion) => {
    setClickedQuestion(q);
    setOpenEditModal(true);
  };

  const handleSubmitEdit = async (questionId: string, editedQuestion: any) => {
    console.log('qID', questionId);
    const body = {
      profileId: profile._id,
      questionId,
      editedQuestion,
    };
    const res = await fetchPost('/inaccuracy-report/edit-question', body);
    setOpenEditModal(false);
    enqueueSnackbar(`Question edited`, { variant: 'success' });
  };

  return (
    <>
      {clickedQuestion ? (
        <CustomQuestionsEditDialog
          close={() => setOpenEditModal(false)}
          customQuestion={clickedQuestion}
          formType={clickedQuestion.approved === null ? 'review' : 'edit'}
          handleApprove={handleApprove}
          handleReject={handleReject}
          handleSubmitEdit={handleSubmitEdit}
          open={openEditModal}
          questionId={
            clickedQuestion.approved === null
              ? clickedQuestion._id
              : clickedQuestion.questionId
          }
        />
      ) : null}
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell width={'30%'}>Question</TableCell>
              <TableCell width={'20%'}>Answer</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>PAR</TableCell>
              <TableCell>Submitted By</TableCell>
              <TableCell>Submitted At</TableCell>
              <TableCell>Approved/Rejected</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customQuestions?.map((q) => (
              <TableRow key={q._id}>
                <TableCell>{q.question}</TableCell>
                <TableCell>{q.answer}</TableCell>
                <TableCell>{q.category}</TableCell>
                <TableCell>{q.par}</TableCell>
                <TableCell>{q.username}</TableCell>
                <TableCell>{utcToLocalDateTime(q.createdAt)}</TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      color:
                        q.approved === null
                          ? theme.palette.primary.main
                          : q.approved
                          ? theme.palette.success.main
                          : theme.palette.warning.main,
                    }}
                  >
                    {q.approved === null
                      ? 'TBD'
                      : q.approved
                      ? 'Approved'
                      : 'Rejected'}
                  </Typography>
                </TableCell>
                <TableCell>
                  {q.approved === null ? (
                    <Button
                      variant="contained"
                      onClick={() => handleReviewClick(q)}
                      disabled={q.approved}
                    >
                      Review
                    </Button>
                  ) : q.approved ? (
                    <Button
                      variant="contained"
                      onClick={() => handleEditClick(q)}
                    >
                      Edit
                    </Button>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReviewQuestions;
