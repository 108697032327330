import React, { useEffect, useState } from 'react';
import {
  useTheme,
  Box,
  AppBar,
  Toolbar,
  useMediaQuery,
  Stack,
  Paper,
  IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLobbyGamesContext } from '../../contexts/LobbyGamesProvider';
import { LobbyGame } from '../../types/game';
import { Profile } from '../../types/player';
import { MAX_PLAYERS_IN_TEAMS } from '../../constants/constants';
import { TopNav } from './top-nav';
import { Logo } from './logo/Logo';
import { SubNav } from './sub-nav';
import { HeaderDrawerMenu } from './drawer-nav';
import './styles.css';
import { ProfileMenu } from './profile-menu';
import { ActionHeader } from '../action-header';
import { useBlinkingState } from '../../hooks/useBlinkingState';
import { useLoggedInUserContext } from '../../contexts/LoggedInUserProvider';
import { gameInProgress } from '../../utils/loggedInUser';
import MaintenanceBanner from './maintenance-banner';
import HeaderPlayButton from './HeaderPlayButton';
import { isMobile } from 'react-device-detect';

export const HEADER_HEIGHT = 200;

type PropTypes = {
  profile: Profile;
};

export const Header = ({ profile }: PropTypes) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const { loggedInUser } = useLoggedInUserContext();
  const {
    lobbyGames,
    anyGamesInProgress,
    joinableLobbyGame,
    maintenance,
    handlePlayClick,
    openPlayMenu,
    createGameConfig,
  } = useLobbyGamesContext();

  const { isBlinking, startBlinking, stopBlinking } = useBlinkingState();

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const opacity = Math.max(0, 1 - scrollPosition / 100);

  const [openLeagueGames, setOpenLeagueGames] = useState<LobbyGame[]>();
  const [mobileOpen, setMobileOpen] = useState(false);
  const isLoggedIn = !!profile;

  useEffect(() => {
    if (!lobbyGames) return;
    setOpenLeagueGames(lobbyGames.filter(isOpenGame));
  }, [lobbyGames]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const showActionHeaderJoin =
    !!profile && !gameInProgress(loggedInUser) && !!joinableLobbyGame;
  const showActionHeaderRejoin = !!profile && gameInProgress(loggedInUser);

  const showActionHeader = showActionHeaderRejoin;

  useEffect(() => {
    if (
      showActionHeaderRejoin &&
      !joinableLobbyGame?.activePlayers.includes(profile._id)
    ) {
      startBlinking();
    } else {
      stopBlinking();
    }
  }, [showActionHeaderRejoin]);

  const handleLogoClick = async () => {
    if (location.pathname.includes('verify-invite')) return;
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    navigate('/home');
  };

  const headerMarginBottom = showActionHeader ? '64px' : '24px';

  const diabledPlayButton =
    openPlayMenu &&
    createGameConfig?.categorySet === 'none' &&
    createGameConfig?.categorySelections.length === 0;

  const isVisible = scrollPosition < 30;

  return (
    <header style={{ marginBottom: headerMarginBottom, height: '20px' }}>
      <AppBar
        position="fixed"
        component="nav"
        color="transparent"
        sx={{
          zIndex: 998,
          opacity: isVisible ? 1 : 0,
          pointerEvents: isVisible ? 'auto' : 'none',
          transition: 'opacity 0.3s',
        }}
      >
        <Paper
          elevation={0}
          square
          sx={{
            bgcolor: '#080808fa',
          }}
        >
          {maintenance.scheduled && (
            <MaintenanceBanner message={maintenance.bannerMessage} />
          )}

          {showActionHeader && (
            <ActionHeader
              anyGamesInProgress={anyGamesInProgress}
              joinableLeagueGame={joinableLobbyGame}
              showJoin={showActionHeaderJoin}
              showRejoin={showActionHeaderRejoin}
            />
          )}

          <Toolbar variant="dense">
            {lgScreen ? (
              <>
                {/* <Box pr={4} mb={-1}>
                  <Logo
                    glow={location.pathname.includes('/home')}
                    handleLogoClick={handleLogoClick}
                  />
                </Box> */}
                {!location.pathname.includes('verify-invite') && (
                  <TopNav openLeagueGames={openLeagueGames} profile={profile} />
                )}
                <ProfileMenu profile={profile} />
              </>
            ) : (
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  size="small"
                >
                  <MenuIcon />
                </IconButton>
                <Logo handleLogoClick={handleLogoClick} />
                <ProfileMenu profile={profile} />
              </Stack>
            )}
          </Toolbar>
        </Paper>
      </AppBar>

      {/* {!isMobile && (
        <Box position={'fixed'} top={5} right={5} zIndex={999999}>
          <HeaderPlayButton
            disablePlayButton={diabledPlayButton || maintenance.scheduled}
            onlinePlayer={loggedInUser}
            openPlayMenu={openPlayMenu}
            onPlayClick={handlePlayClick}
            size="medium"
            seedMatch={1}
            soloMultiAsync={createGameConfig?.soloMultiAsync}
            voluntarySeedMatch={false}
          />
        </Box>
      )} */}

      <HeaderDrawerMenu
        closeDrawer={() => setMobileOpen(false)}
        profile={profile}
        openLeagueGames={openLeagueGames}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        setMobileOpen={setMobileOpen}
      />
      <SubNav isAdmin={profile?.admin} profileId={profile?._id} />
    </header>
  );
};

const isOpenGame = (g: LobbyGame) => {
  if (
    g.leagueGameType !== 'swiss-tournament' &&
    g.leagueGameType !== 'tournament' &&
    g.leagueGameType !== 'tournament-tiebreaker' &&
    g.activePlayers?.length < MAX_PLAYERS_IN_TEAMS &&
    gameIsOpenForJoining(g)
  ) {
    return true;
  }
  return false;
};

const gameIsOpenForJoining = (g: LobbyGame) => !g.gameOver && !g.gameStarted;
