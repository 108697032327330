import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FlareIcon from '@mui/icons-material/Flare';
import DiamondIcon from '@mui/icons-material/Diamond';
import { TbAtom } from 'react-icons/tb';
import RocketIcon from '@mui/icons-material/Rocket';
import {
  CategorySet,
  NextTrophyReqs,
  Punchcard,
  PunchcardCategory,
} from './types';
import { sum } from 'lodash';
import { fetchGet } from '../../../api/common';
import { GameDb, Question } from '../../../types/game';
import { MEDAL_REQUIREMENTS, MEDAL_COLORS } from './constants';
import useGetPunchcardGames from './dialogs/useGetPunchcardGames';
import { ScorecardDialog } from '../../../dialogs/ScorecardDialog';
import { DetailedPlayer } from '../../../types/player';
import { utcToLocalDateTime } from '../../../utils/date-time';

type Props = {
  punchcard: Punchcard;
  categorySet: string;
  profileId: string;
};

const CategoryDetails = ({ punchcard, categorySet, profileId }: Props) => {
  const stats = punchcard[categorySet];

  const theme = useTheme();
  const [gameRow, setGameRow] = useState<GameDb>();
  const [questionSet, setQuestionSet] = useState<Question[]>();
  const [openScorecardModal, setOpenScorecardModal] = useState(false);

  const { data: punchcardGames } = useGetPunchcardGames(profileId, categorySet);

  const nextBestAvgReqs = (currentAvg: number) => {
    const medalKeys = Object.keys(MEDAL_REQUIREMENTS.average);

    const remainingMedals = [];

    for (let i = medalKeys.length - 1; i > 0; i--) {
      if (currentAvg < MEDAL_REQUIREMENTS.average[medalKeys[i]]) {
        break;
      } else {
        remainingMedals.push({
          medal: medalKeys[i],
          value: MEDAL_REQUIREMENTS.average[medalKeys[i]],
        });
      }
    }

    const lastNineScores = stats?.lastTenScores.slice(
      1,
      stats?.lastTenScores.length
    );

    for (const rm of remainingMedals) {
      const scoreNeededToAchieve = rm.value * 10 - sum(lastNineScores);
      rm.scoreNeededToAchieve = scoreNeededToAchieve;
    }

    return remainingMedals.reverse();
  };

  const getMedal = (punchcardCategory: PunchcardCategory, value: number) => {
    const medalCategory = MEDAL_REQUIREMENTS[punchcardCategory];

    if (
      (punchcardCategory === 'bestAverage' ||
        punchcardCategory === 'average') &&
      !punchcardGames?.bestAverageGames?.length
    ) {
      return (
        <EmojiEventsIcon
          sx={{
            color: 'black',
            fontSize: 20,
          }}
        />
      );
    }

    if (
      punchcardCategory === 'gamesPlayed'
        ? value >= medalCategory.antimatter
        : value <= medalCategory.antimatter
    ) {
      return (
        <TbAtom
          size={40}
          color={MEDAL_COLORS.antimatter}
          style={{ boxShadow: '1px 1px 15px #FFF, 1px 1px 5px #fffefe' }}
        />
      );
    }
    if (
      punchcardCategory === 'gamesPlayed'
        ? value >= medalCategory.unobtainium
        : value <= medalCategory.unobtainium
    ) {
      return (
        <RocketIcon sx={{ color: MEDAL_COLORS.unobtainium, fontSize: 20 }} />
      );
    }
    if (
      punchcardCategory === 'gamesPlayed'
        ? value >= medalCategory.diamond
        : value <= medalCategory.diamond
    ) {
      return (
        <DiamondIcon
          sx={{
            color: MEDAL_COLORS.diamond,
            fontSize: 20,
          }}
        />
      );
    }
    if (
      punchcardCategory === 'gamesPlayed'
        ? value >= medalCategory.platinum
        : value <= medalCategory.platinum
    ) {
      return (
        <FlareIcon
          sx={{
            color: MEDAL_COLORS.platinum,
            fontSize: 20,
          }}
        />
      );
    }
    if (
      punchcardCategory === 'gamesPlayed'
        ? value >= medalCategory.gold
        : value <= medalCategory.gold
    ) {
      return (
        <EmojiEventsIcon
          sx={{
            color: MEDAL_COLORS.gold,
            fontSize: 20,
          }}
        />
      );
    }
    if (
      punchcardCategory === 'gamesPlayed'
        ? value >= medalCategory.silver
        : value <= medalCategory.silver
    ) {
      return (
        <EmojiEventsIcon
          sx={{
            color: MEDAL_COLORS.silver,
            fontSize: 20,
          }}
        />
      );
    }
    if (
      punchcardCategory === 'gamesPlayed'
        ? value >= medalCategory.bronze
        : value <= medalCategory.bronze
    ) {
      return (
        <EmojiEventsIcon
          sx={{
            color: MEDAL_COLORS.bronze,
            fontSize: 20,
          }}
        />
      );
    }
    return (
      <EmojiEventsIcon
        sx={{
          color: 'black',
          fontSize: 20,
        }}
      />
    );
  };

  const handleViewResultsClick = async (game: GameDb) => {
    const res = await fetchGet(`/games/result/${game.gameId}`);
    setGameRow(res.game);
    setQuestionSet(res.questionSet);
    setOpenScorecardModal(true);
  };

  return (
    <>
      {gameRow && questionSet ? (
        <ScorecardDialog
          onClose={() => setOpenScorecardModal(false)}
          categorySet={gameRow.categorySet}
          gameId={gameRow.gameId}
          gameType={gameRow.gameType as 'casual' | 'pro'}
          isDaily={gameRow.daily}
          open={openScorecardModal}
          mode={gameRow.mode}
          players={gameRow.players as Record<string, DetailedPlayer>}
          profileId={profileId}
          questionSet={questionSet}
          ranked={gameRow.ranked}
        />
      ) : null}

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Stat</TableCell>
              <TableCell>Record</TableCell>
              <TableCell>History (newest on the left)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{ color: '#B0E0E6' }}>
                Current Average
              </TableCell>
              <TableCell style={{ color: '#B0E0E6' }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  {getMedal('average', stats?.average)}
                  <Typography>
                    {stats?.average > 0 ? '+' : ''}
                    {stats?.average || '--'}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>
                {punchcardGames?.recentAverageGames?.map((g) => (
                  <Tooltip
                    key={g.gameId}
                    title={utcToLocalDateTime(g.createdAt)}
                    placement="top"
                  >
                    <IconButton
                      onClick={() => handleViewResultsClick(g)}
                      size="small"
                    >
                      <Typography>
                        {g.players[profileId]?.parScore > 0 ? '+' : ''}
                        {g.players[profileId]?.parScore}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ color: '#53ecff' }}>Best Average</TableCell>
              <TableCell style={{ color: '#53ecff' }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  {getMedal('bestAverage', stats?.bestAverage)}
                  <Typography>
                    {stats?.bestAverage > 0 ? '+' : ''}
                    {stats?.bestAverage || '--'}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>
                {punchcardGames?.bestAverageGames?.map((g) => (
                  <Tooltip
                    key={g.gameId}
                    title={utcToLocalDateTime(g.createdAt)}
                    placement="top"
                  >
                    <IconButton
                      onClick={() => handleViewResultsClick(g)}
                      size="small"
                    >
                      <Typography>
                        {g.players[profileId]?.parScore > 0 ? '+' : ''}
                        {g.players[profileId]?.parScore}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ color: '#00FF00' }}>Best Game</TableCell>
              <TableCell style={{ color: '#00FF00' }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  {getMedal('best', stats?.best)}
                  <Typography>
                    {stats?.best > 0 ? '+' : ''}
                    {stats?.best || '--'}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={utcToLocalDateTime(punchcardGames?.bestGame.createdAt)}
                  placement="top"
                >
                  <IconButton
                    onClick={() =>
                      handleViewResultsClick(punchcardGames?.bestGame)
                    }
                    size="small"
                  >
                    <Typography>
                      {punchcardGames?.bestGame.players[profileId]?.parScore > 0
                        ? '+'
                        : ''}
                      {punchcardGames?.bestGame.players[profileId]?.parScore}
                    </Typography>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Stack direction="row" spacing={1} pt={2}>
        <Typography variant="caption">
          Score needed next game to obtain a Current Average medal:
        </Typography>
        <Stack direction="row" spacing={1}>
          {stats.lastTenScores.length > 9 &&
            nextBestAvgReqs(stats.average).map((req) => (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="caption" fontWeight={600}>
                  {req.medal} {req.scoreNeededToAchieve} |
                </Typography>
              </Stack>
            ))}
        </Stack>
      </Stack>
    </>
  );
};

export default CategoryDetails;
