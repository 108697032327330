import { useMutation } from '@tanstack/react-query';
import { postJoinLeagueTeamRequest } from '../../../api/apiServices';

const usePostJoinRequestLeagueTeam = (handleOnSuccess: () => void) => {
  const { mutate } = useMutation({
    mutationFn: postJoinLeagueTeamRequest,
    onSuccess: handleOnSuccess,
  });

  return {
    postJoinLeagueTeamRequest: mutate,
  };
};

export default usePostJoinRequestLeagueTeam;
