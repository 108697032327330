import React from 'react';
import { Letter } from './Letter';

type PropTypes = {
  correctAnswer: boolean;
  displaySize: 'sm' | 'md' | 'lg';
  entireAnswerDisplayed: boolean;
  spectate?: boolean;
  timerLimitReached: boolean;
  underline: boolean;
  unrevealedIndex?: number[];
  word: string[];
  wordIndex: number;
};

export const Word = ({
  correctAnswer,
  displaySize,
  entireAnswerDisplayed,
  spectate,
  timerLimitReached,
  underline,
  unrevealedIndex,
  word,
  wordIndex,
}: PropTypes) => {
  return (
    <>
      {word.map((letter, letterIndex) =>
        letter === ' ' ? (
          <div key={`letter-${letterIndex}`}></div>
        ) : (
          <div key={`letter-${letterIndex}`}>
            <Letter
              correctAnswer={correctAnswer}
              displaySize={displaySize}
              entireAnswerDisplayed={entireAnswerDisplayed}
              letter={letter}
              letterIndex={letterIndex}
              spectate={spectate}
              timerLimitReached={timerLimitReached}
              underline={underline}
              unrevealedIndex={unrevealedIndex}
              wordIndex={wordIndex}
            />
          </div>
        )
      )}
    </>
  );
};
