import { Card, Stack, Box, Typography, Divider, useTheme } from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import { Profile } from '../../../types/player';
import { calcPOB } from './utils';
import { GiRank3 } from 'react-icons/gi';
import { round } from 'lodash';

type Props = {
  viewProfile: Profile;
};

const Summary = ({ viewProfile }: Props) => {
  const theme = useTheme();

  const {
    aves,
    totalMatchGamesPlayed,
    totalTeamGamesPlayed,
    totalSitAndGoGamesPlayed,
    totalSoloGamesPlayed,
    totalDailyGamesPlayed,
    questionsSeenCount,
    trueSkill,
    trueSkillGameRecords,
  } = viewProfile;

  return (
    <Box pt={2}>
      <Stack
        direction={'row'}
        spacing={2}
        p={2}
        alignContent={'center'}
        sx={{
          border: '1px solid',
          borderColor: theme.palette.border,
          borderRadius: 1,
        }}
      >
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          width={'180px'}
          sx={{
            py: 2,
            px: 6,
          }}
        >
          <GiRank3 size={50} color={theme.palette.ranked.main} />
          <Typography fontWeight={600} color={'ranked.main'}>
            MMR
          </Typography>
          <Typography
            variant="h4"
            pt={2}
            fontWeight={600}
            color={'ranked.main'}
          >
            {trueSkillGameRecords?.sitAndGoGamesPlayed < 10
              ? 'CAL'
              : trueSkill?.mmr
              ? round(trueSkill?.mmr, 0)
              : 'N/A'}
          </Typography>
        </Stack>

        <Divider flexItem orientation="vertical" />

        <Stack spacing={1} width={'100%'} height={'100%'}>
          <Typography fontWeight={600}>Games Played</Typography>

          <Divider />

          <Stack
            direction={'row'}
            spacing={2}
            width={'100%'}
            justifyContent={'space-between'}
          >
            <Typography>Solo</Typography>
            <Typography fontWeight={600} pr={1}>
              {trueSkillGameRecords?.soloGamesPlayed || 0}
            </Typography>
          </Stack>

          <Divider />

          <Stack
            direction={'row'}
            spacing={2}
            width={'100%'}
            justifyContent={'space-between'}
          >
            <Typography>Sit & Go</Typography>
            <Typography fontWeight={600} pr={1}>
              {trueSkillGameRecords?.sitAndGoGamesPlayed || 0}
            </Typography>
          </Stack>
          {/* 
          <Divider />

          <Stack
            direction={'row'}
            spacing={2}
            width={'100%'}
            justifyContent={'space-between'}
          >
            <Typography>Teams</Typography>
            <Typography fontWeight={600} pr={1}>
              {totalTeamGamesPlayed || 0}
            </Typography>
          </Stack> */}

          <Divider />

          <Stack
            direction={'row'}
            spacing={2}
            width={'100%'}
            justifyContent={'space-between'}
          >
            <Typography>POB%</Typography>
            <Typography fontWeight={600} pr={1}>
              {aves && questionsSeenCount
                ? calcPOB(aves, questionsSeenCount)
                : 'N/A'}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Summary;
