import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import useFetchArenaTeams from '../../profile/ArenaProfile/hooks/useFetchArenaTeams';
import useUpdateJoinArenaTeam from '../../profile/ArenaProfile/hooks/useUpdateJoinArenaTeam';
import { ArenaTeam } from '../../arena-team/types';
import { useLoggedInUserContext } from '../../../contexts/LoggedInUserProvider';
import { useSocketContext } from '../../../contexts/SocketProvider';

type Props = {
  open: boolean;
  close: () => void;
  onJoinSuccess: () => void;
  profileId: string;
  season: number;
};

const ArenaJoinTeamsDialog = ({
  open,
  close,
  onJoinSuccess,
  profileId,
  season,
}: Props) => {
  const { loggedInUser } = useLoggedInUserContext();
  const { socket } = useSocketContext();

  const [isJoiningTeam, setIsJoiningTeam] = useState(false);

  const { data: arenaTeamsData } = useFetchArenaTeams(season);

  const handleJoinSuccess = () => {
    onJoinSuccess();
    close();
  };

  const { updateJoinArenaTeam } = useUpdateJoinArenaTeam(handleJoinSuccess);

  const [step, setStep] = useState(0);

  const [selectedTeam, setSelectedTeam] = useState<ArenaTeam>(null);

  const handleSelectTeam = (team: ArenaTeam) => {
    setSelectedTeam(team);
    setStep(1);
  };

  const handleJoinTeamClick = async () => {
    setIsJoiningTeam(true);

    updateJoinArenaTeam({
      profileId,
      teamId: selectedTeam._id,
      teamName: selectedTeam.teamName,
      season,
    });

    // attempt to inject the team if user joins a team after joining a game
    const canUpdateTeam =
      loggedInUser?.currentArenaSet && !loggedInUser?.currentGameStarted;
    if (canUpdateTeam) {
      if (!socket) return;
      socket.emit('updatePlayerArenaTeamBeforeGame', {
        arenaTeamId: selectedTeam._id,
        profileId,
        gameId: loggedInUser.currentGameId,
      });
    }
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
      <DialogContent>
        {step === 0 ? (
          <Stack>
            <Typography textAlign={'center'}>JOIN A PUB TEAM</Typography>

            <Typography pt={2} fontFamily={'noto-sans'}>
              When you are on a Pub team, you have the chance to contribute to
              the team's success in every Pub Game you play. The top scores for
              each question are combined to create the team scorecard. You can
              also earn special team achievements. Joining a team does not mean
              you have to show up to every Pub Night.
            </Typography>

            <Typography pt={2} fontFamily={'noto-sans'}>
              Pick a team based on the members, the city it represents, or
              simply because you like the logo, it doesn't matter, let's have
              some fun!
            </Typography>

            <Typography textAlign={'center'} pt={4}>
              SELECT A TEAM
            </Typography>

            <Stack
              direction={'row'}
              pt={4}
              spacing={4}
              justifyContent={'center'}
            >
              {arenaTeamsData?.map((team, i) => (
                <Card key={i}>
                  <CardActionArea onClick={() => handleSelectTeam(team)}>
                    <CardMedia
                      component="img"
                      height={240}
                      image={team.avatarUrl}
                      alt={team.teamName}
                      sx={{
                        objectFit: 'contain',
                      }}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        textAlign={'center'}
                      >
                        {team.teamName}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Stack>
          </Stack>
        ) : step === 1 ? (
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Typography textAlign={'center'} pt={4}>
              CONFIRM YOUR SELECTION
            </Typography>
            <Typography variant="caption" textAlign={'center'} pt={2}>
              You cannot switch teams within the season.
            </Typography>

            <Card sx={{ my: 4, width: 'fit-content' }}>
              <CardMedia
                component="img"
                height={240}
                image={selectedTeam.avatarUrl}
                alt={selectedTeam.teamName}
                sx={{
                  objectFit: 'contain',
                }}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  textAlign={'center'}
                >
                  {selectedTeam.teamName}
                </Typography>
              </CardContent>
            </Card>

            <Button
              variant={'contained'}
              onClick={handleJoinTeamClick}
              endIcon={isJoiningTeam ? <CircularProgress size={18} /> : null}
              disabled={isJoiningTeam}
              color="secondary"
            >
              JOIN THIS TEAM
            </Button>
          </Stack>
        ) : null}
      </DialogContent>
      <DialogActions>
        {isJoiningTeam ? null : step === 0 ? (
          <Button onClick={close}>Close</Button>
        ) : (
          <Button onClick={() => setStep(0)}>Back</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ArenaJoinTeamsDialog;
