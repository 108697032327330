import { useApi } from '../useApi';
import { Methods } from './constants';
import { useEffect } from 'react';
import { useProfileContext } from '../../contexts/ProfileProvider';
import { Profile } from '../../types/player';

export const useLogin = () => {
  const { refetch } = useProfileContext();
  const { data, error, loading, sendRequest } = useApi();

  useEffect(() => {
    if (data && !error) {
      refetch();
    }
  }, [data, error, refetch]);

  const login = (email: string, password: string) => {
    sendRequest({
      url: '/authenticate/login',
      method: Methods.POST,
      body: { email, password },
    });
  };

  return { data, error, loading, login };
};
