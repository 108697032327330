import { CATEGORY_SETS_LABEL } from '../../../../constants/constants';
import { Mode } from '../../../../types/game';
import { getPacificTime } from '../../../../utils/date-time';
import { DAILIES_THEMES } from '../../../dailies_old/constants';

export const getGameHeaderLabel = (
  mode: Mode,
  arenaGame?: number,
  leagueMatch?: number,
  categorySet?: string,
  categorySelections?: string[],
  dailySet?: number,
  missedDaily?: boolean
) => {
  let label = 'Game';

  const dayOfWeekAbbr = getPacificTime().format('ddd');

  if (arenaGame) {
    return `Pub Game #${arenaGame}`;
  }

  if (dailySet) {
    if (missedDaily) {
      return `Missed Daily #${dailySet}`;
    }
    return `DAILY - ${DAILIES_THEMES[dayOfWeekAbbr].theme}`;
  }

  if (leagueMatch) {
    label = `League Match ${leagueMatch}`;
  } else if (mode === 'solo') {
    if (categorySet === 'none') {
      if (categorySelections?.length === 1) {
        label = `Solo - ${categorySelections[0]}`;
      } else if (categorySelections?.length > 1) {
        label = `Solo - ${categorySelections[0]} (+${
          categorySelections?.length - 1
        } more)`;
      }
    } else {
      label = `Solo - ${CATEGORY_SETS_LABEL[categorySet]}`;
    }
  } else if (mode === 'sit-n-go') {
    if (categorySet === 'none') {
      label = `Sit & Go - Custom Categories (+${categorySelections?.length})`;
    } else {
      label = `Sit & Go - ${CATEGORY_SETS_LABEL[categorySet]}`;
    }
  }

  return label;
};
