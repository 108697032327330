import React, { useEffect, useState } from 'react';
import {
  Stack,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Paper,
} from '@mui/material';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';

const initTopChecked = {
  all: false,
  humanities: false,
  hardSoftSciences: false,
  fineArts: false,
  popCulture: false,
};

const initChecked = {
  'Philosophy & Religion': false,
  History: false,
  Geography: false,
  Literature: false,
  'Vocab & Language': false,
  Science: false,
  'Politics & Civics': false,
  Business: false,
  Culture: false,
  Math: false,
  Theater: false,
  'Art & Architecture': false,
  'Classical Music': false,
  'Food & Drink': false,
  Sports: false,
  TV: false,
  'Popular Music': false,
  Film: false,
  'Video Games': false,
  Niche: false,
};

const categoryGroups = {
  all: [
    'Philosophy & Religion',
    'History',
    'Geography',
    'Literature',
    'Vocab & Language',
    'Science',
    'Politics & Civics',
    'Business',
    'Culture',
    'Theater',
    'Art & Architecture',
    'Classical Music',
    'Food & Drink',
    'Sports',
    'TV',
    'Popular Music',
    'Film',
    'Video Games',
    'Niche',
    'Math',
  ],
  humanities: [
    'Philosophy & Religion',
    'History',
    'Geography',
    'Literature',
    'Vocab & Language',
  ],
  hardSoftSciences: [
    'Science',
    'Politics & Civics',
    'Business',
    'Culture',
    'Math',
  ],
  fineArts: [
    'Theater',
    'Art & Architecture',
    'Classical Music',
    'Food & Drink',
  ],
  popCulture: ['Sports', 'TV', 'Popular Music', 'Film', 'Video Games', 'Niche'],
};

type PropTypes = {
  initCategorySelections: string[];
  updatedCheckedCategories: (categories: any) => void;
};

export const CategorySelect = ({
  initCategorySelections,
  updatedCheckedCategories,
}: PropTypes) => {
  const [checkedTopCategories, setCheckedTopCategories] =
    useState(initTopChecked);
  const [checkedCategories, setCheckedCategories] = useState(initChecked);

  useEffect(() => {
    if (!initCategorySelections || !initCategorySelections.length) {
      setCheckedTopCategories((prev) => ({
        ...prev,
        all: true,
      }));
      return;
    }
    const selectedCats = {};
    for (const cat of initCategorySelections) {
      selectedCats[cat] = true;
    }
    setCheckedCategories((prev) => ({
      ...prev,
      ...selectedCats,
    }));
  }, [initCategorySelections]);

  useEffect(() => {
    updatedCheckedCategories(checkedCategories);
    for (const top of Object.keys(categoryGroups)) {
      let shouldCheckTop = true;
      for (const cat of categoryGroups[top]) {
        if (!checkedCategories[cat]) {
          shouldCheckTop = false;
        }
      }
      setCheckedTopCategories((prev) => ({
        ...prev,
        [top]: shouldCheckTop,
      }));
    }
  }, [checkedCategories]);

  const handleCheckTopCategory = (checked: boolean, topCategory: string) => {
    setCheckedTopCategories((prev) => ({
      ...prev,
      [topCategory]: checked,
    }));
    if (topCategory === 'all') {
      setCheckedCategories((prev) => ({
        ...prev,
        'Philosophy & Religion': checked,
        History: checked,
        Geography: checked,
        Literature: checked,
        'Vocab & Language': checked,
        Science: checked,
        'Politics & Civics': checked,
        Business: checked,
        Culture: checked,
        Math: checked,
        Theater: checked,
        'Art & Architecture': checked,
        'Classical Music': checked,
        'Food & Drink': checked,
        Sports: checked,
        TV: checked,
        'Popular Music': checked,
        Film: checked,
        'Video Games': checked,
        Niche: checked,
      }));
    }
    if (topCategory === 'humanities') {
      setCheckedCategories((prev) => ({
        ...prev,
        'Philosophy & Religion': checked,
        History: checked,
        Geography: checked,
        Literature: checked,
        'Vocab & Language': checked,
      }));
    }
    if (topCategory === 'hardSoftSciences') {
      setCheckedCategories((prev) => ({
        ...prev,
        Science: checked,
        'Politics & Civics': checked,
        Business: checked,
        Culture: checked,
        Math: checked,
      }));
    }
    if (topCategory === 'fineArts') {
      setCheckedCategories((prev) => ({
        ...prev,
        Theater: checked,
        'Art & Architecture': checked,
        'Classical Music': checked,
        'Food & Drink': checked,
      }));
    }
    if (topCategory === 'popCulture') {
      setCheckedCategories((prev) => ({
        ...prev,
        Sports: checked,
        TV: checked,
        'Popular Music': checked,
        Film: checked,
        'Video Games': checked,
        Niche: checked,
      }));
    }
  };

  const handleCheckCategory = (checked: boolean, category: string) => {
    setCheckedCategories((prev) => ({
      ...prev,
      [category]: checked,
    }));
  };

  return (
    <Stack mt={2}>
      <Stack direction="row" alignItems={'center'}>
        <Box width={'20%'}></Box>{' '}
        <Box width={'60%'}>
          <Typography textAlign={'center'} letterSpacing={5}>
            SELECT CATEGORIES
          </Typography>
        </Box>
        <Box width={'20%'} display="flex" justifyContent={'right'}>
          <FormGroup>
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  checked={checkedTopCategories['all']}
                  onChange={(e) =>
                    handleCheckTopCategory(e.target.checked, 'all')
                  }
                  size="small"
                />
              }
              label={'All'}
              labelPlacement="end"
            />
          </FormGroup>
        </Box>
      </Stack>
      <Stack direction={'row'} spacing={2} justifyContent={'center'}>
        <Paper sx={{ bgcolor: '#3049654a' }}>
          <FormControl component="fieldset" sx={{ paddingLeft: 1 }}>
            <FormGroup>
              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    checked={checkedTopCategories['humanities']}
                    onChange={(e) =>
                      handleCheckTopCategory(e.target.checked, 'humanities')
                    }
                    size="small"
                  />
                }
                label={'Humanities'}
                labelPlacement="end"
              />
            </FormGroup>
            <Divider />
            <FormGroup>
              {Object.keys(checkedCategories)
                .slice(0, 5)
                .map((cat) => (
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox
                        checked={checkedCategories[cat]}
                        onChange={(e) =>
                          handleCheckCategory(e.target.checked, cat)
                        }
                        size="small"
                      />
                    }
                    label={cat}
                    labelPlacement="end"
                    sx={{
                      paddingLeft: 1,
                    }}
                  />
                ))}
            </FormGroup>
          </FormControl>
        </Paper>
        <Paper sx={{ bgcolor: '#30653a4a' }}>
          <FormControl component="fieldset" sx={{ paddingLeft: 1 }}>
            <FormGroup>
              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    checked={checkedTopCategories['hardSoftSciences']}
                    onChange={(e) =>
                      handleCheckTopCategory(
                        e.target.checked,
                        'hardSoftSciences'
                      )
                    }
                    size="small"
                  />
                }
                label={'Hard & Soft Sciences'}
                labelPlacement="end"
              />
            </FormGroup>
            <Divider />
            <FormGroup>
              {Object.keys(checkedCategories)
                .slice(5, 10)
                .map((cat) => (
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox
                        checked={checkedCategories[cat]}
                        onChange={(e) =>
                          handleCheckCategory(e.target.checked, cat)
                        }
                        size="small"
                      />
                    }
                    label={cat}
                    labelPlacement="end"
                    sx={{
                      paddingLeft: 1,
                    }}
                  />
                ))}
            </FormGroup>
          </FormControl>
        </Paper>
        <Paper sx={{ bgcolor: '#65304d4a' }}>
          <FormControl component="fieldset" sx={{ paddingLeft: 1 }}>
            <FormGroup>
              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    checked={checkedTopCategories['fineArts']}
                    onChange={(e) =>
                      handleCheckTopCategory(e.target.checked, 'fineArts')
                    }
                    size="small"
                  />
                }
                label={'Fine Arts'}
                labelPlacement="end"
              />
            </FormGroup>
            <Divider />
            <FormGroup>
              {Object.keys(checkedCategories)
                .slice(10, 14)
                .map((cat) => (
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox
                        checked={checkedCategories[cat]}
                        onChange={(e) =>
                          handleCheckCategory(e.target.checked, cat)
                        }
                        size="small"
                      />
                    }
                    label={cat}
                    labelPlacement="end"
                    sx={{
                      paddingLeft: 1,
                    }}
                  />
                ))}
            </FormGroup>
          </FormControl>
        </Paper>
        <Paper sx={{ bgcolor: '#60653054' }}>
          <FormControl component="fieldset" sx={{ paddingLeft: 1 }}>
            <FormGroup>
              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    checked={checkedTopCategories['popCulture']}
                    onChange={(e) =>
                      handleCheckTopCategory(e.target.checked, 'popCulture')
                    }
                    size="small"
                  />
                }
                label={'Pop Culture'}
                labelPlacement="end"
              />
            </FormGroup>
            <Divider />
            <FormGroup>
              {Object.keys(checkedCategories)
                .slice(14, 20)
                .map((cat) => (
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox
                        checked={checkedCategories[cat]}
                        onChange={(e) =>
                          handleCheckCategory(e.target.checked, cat)
                        }
                        size="small"
                      />
                    }
                    label={cat}
                    labelPlacement="end"
                    sx={{
                      paddingLeft: 1,
                    }}
                  />
                ))}
            </FormGroup>
          </FormControl>
        </Paper>
      </Stack>
    </Stack>
  );
};
