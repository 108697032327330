// Import necessary hooks and functions
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Profile } from '../types/player';
import { createContext, useContextSelector } from 'use-context-selector'; // Import from use-context-selector
import useFetchProfile from '../hooks/useFetchProfile';

// Define your context type as before
interface ProfileContextType {
  clearProfile: () => void;
  refetch: () => void;
  mutateProfile: (newProfile: Profile) => void;
  profile: Profile | null;
  profileIsLoading: boolean;
}

export const ProfileContext = createContext<ProfileContextType>({
  clearProfile: () => {},
  refetch: () => {},
  mutateProfile: () => {},
  profile: null,
  profileIsLoading: true,
});

type ProfileProviderProps = {
  children: ReactNode;
};

export const ProfileProvider: FC<ProfileProviderProps> = ({ children }) => {
  const [profile, setProfile] = useState<Profile>();
  const [profileIsLoading, setProfileIsLoading] = useState(true);

  const { profile: fetchedProfile, isLoading, refetch } = useFetchProfile();

  useEffect(() => {
    if (fetchedProfile && !isLoading) {
      setProfile(fetchedProfile);
      setProfileIsLoading(false);
    } else if (!fetchedProfile && !isLoading) {
      setProfile(null);
      setProfileIsLoading(false);
    }
  }, [fetchedProfile, isLoading]);

  const mutateProfile = useCallback((newProfile: Profile) => {
    // note: this does not trigger rerenders on components that use profile from this context
    setProfile(newProfile);
  }, []);

  const clearProfile = useCallback(() => {
    setProfile(null);
  }, []);

  return (
    <ProfileContext.Provider
      value={{
        clearProfile,
        refetch,
        mutateProfile,
        profile,
        profileIsLoading,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfileContext = () => {
  const clearProfile = useContextSelector(
    ProfileContext,
    (context) => context.clearProfile
  );

  const profile = useContextSelector(
    ProfileContext,
    (context) => context.profile
  );
  const refetch = useContextSelector(
    ProfileContext,
    (context) => context.refetch
  );
  const mutateProfile = useContextSelector(
    ProfileContext,
    (context) => context.mutateProfile
  );
  const profileIsLoading = useContextSelector(
    ProfileContext,
    (context) => context.profileIsLoading
  );

  return {
    clearProfile,
    profile,
    refetch,
    mutateProfile,
    profileIsLoading,
  };
};
