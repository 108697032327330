import {
  Tabs,
  Tab,
  Divider,
  styled,
  BadgeProps,
  Badge,
  Typography,
  TabsProps,
  Stack,
  Menu,
  MenuItem,
  Avatar,
  Chip,
} from '@mui/material';
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LobbyGame } from '../../../types/game';
import { Profile } from '../../../types/player';
import { useLobbyGamesContext } from '../../../contexts/LobbyGamesProvider';
import useGenerateLeagueSchedule from '../../../hooks/useGenerateLeagueSchedule';
import { useQuickPlayGamesContext } from '../../../contexts/QuickPlayGamesProvider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { fetchGet } from '../../../api/common';

export const LobbyBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    color: theme.palette.pro.main,
    fontSize: 16,
    border: `2px solid`,
    borderColor: theme.palette.pro.main,
    borderRadius: 4,
    padding: '5px 8px',
  },
}));

export type ActiveTabs =
  | 'profile'
  | 'team'
  | 'pub'
  | 'dailies'
  | 'tournament'
  | 'registration'
  | 'dummy' // dummy tab used for "more" to allow dropdown
  | 'dummy2' // dummy tab used for "admin" to allow dropdown
  | 'quick-play'
  | 'more'
  | 'admin';

const HeaderNavigationTabs = styled((props: TabsProps) => <Tabs {...props} />)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '& .MuiTab-root:hover': {
    boxShadow: 'inset 0 0 20px #F8EE9580, 0 0 20px #F8EE9533',
  },
});

const HeaderTabLabel = (text: string) => (
  <Typography
    letterSpacing={4}
    fontWeight={'600'}
    fontSize={'14px'}
    textAlign={'center'}
    width={'100%'}
  >
    {text}
  </Typography>
);

const HeaderTabTeamLabel = (text: string, joinRequests: number) => (
  <Badge badgeContent={joinRequests} color="error">
    <Typography letterSpacing={4} fontWeight={'600'} fontSize={'14px'}>
      {text}
    </Typography>
  </Badge>
);

const QuickPlayHeaderTab = (text: string, openGames: number) => (
  <LobbyBadge badgeContent={openGames}>
    <Typography letterSpacing={4} fontWeight={'600'} fontSize={'14px'}>
      {text}
    </Typography>
  </LobbyBadge>
);

const DropdownHeaderTab = (text: string, notifications?: number) => (
  <Badge badgeContent={notifications} color="error">
    <Stack direction="row" alignItems="center">
      <Typography letterSpacing={4} fontWeight={'600'} fontSize={'14px'}>
        {text}
      </Typography>
      <KeyboardArrowDownIcon />
    </Stack>
  </Badge>
);

type PropTypes = {
  profile: Profile;
  orientation: 'horizontal' | 'vertical';
  onNavigated?: () => void;
};

export const HeaderTabs = ({
  profile,
  orientation,
  onNavigated,
}: PropTypes) => {
  const { currentSeason } = useLobbyGamesContext();
  const { quickPlayGamesOpen } = useQuickPlayGamesContext();

  const location = useLocation();
  const navigate = useNavigate();

  const moreRef = useRef<HTMLDivElement>(null);
  const adminRef = useRef<HTMLDivElement>(null);

  const [anchorMoreEl, setAnchorMoreEl] = useState<null | HTMLElement>(null);
  const [anchorAdminEl, setAnchorAdminEl] = useState<null | HTMLElement>(null);
  const [openMoreMenu, setOpenMoreMenu] = useState(false);
  const [openAdminMenu, setOpenAdminMenu] = useState(false);

  const [reportInaccuraciesCount, setReportInaccuraciesCount] = useState(0);
  const [unreviewedQuestions, setUnreviewedQuestions] = useState(0);

  useEffect(() => {
    if (moreRef?.current) {
      const moreMenu = moreRef.current;
      setAnchorMoreEl(moreMenu);
    }
    if (adminRef?.current) {
      const adminMenu = adminRef.current;
      setAnchorAdminEl(adminMenu);
    }
  }, []);

  useEffect(() => {
    if (profile?.admin) {
      fetchInaccuraciesReport();
      // fetchUserSubmittedQuestions();
    }
  }, [profile]);

  const fetchInaccuraciesReport = async () => {
    const res = await fetchGet('/inaccuracy-report/all');
    setReportInaccuraciesCount(res.length);
  };

  const fetchUserSubmittedQuestions = async () => {
    const res = await fetchGet(`/custom-questions/all/${profile._id}/true`);
    setUnreviewedQuestions(res.length);
  };

  const getActiveTab = () => {
    if (location.pathname.includes('profile')) return 'profile';
    if (location.pathname.includes('pub')) return 'pub';
    if (location.pathname.includes('dailies')) return 'dailies';
    if (location.pathname.includes('lobby')) return 'quick-play';
    if (location.pathname.includes('league-schedules')) return 'dummy';
    if (location.pathname.includes('league-faq')) return 'dummy';
    if (location.pathname.includes('tournament')) return 'tournament';
    if (location.pathname.includes('registration')) return 'registration';
    if (location.pathname.includes('admin')) return 'dummy2';
    return false;
  };

  const [activeTab, setActiveTab] = useState<ActiveTabs | boolean>(
    getActiveTab
  );

  useEffect(() => {
    setActiveTab(getActiveTab());
  }, [location]);

  const handleProfileClick = () => {
    if (!profile?._id) return;
    navigate(`/profile/overview/${profile?._id}`);
    onNavigated && onNavigated();
  };

  const handleTeamClick = () => {
    if (!profile?.currentLeague?.teamId) return;
    navigate(`/team/overview/${profile?.currentLeague?.teamId}`);
    onNavigated && onNavigated();
  };

  const handleMainClick = () => navigate('/home');
  const handleArenaClick = () => {
    navigate('/pub');
    onNavigated && onNavigated();
  };
  const handleDailiesClick = () => {
    navigate('/dailies');
    onNavigated && onNavigated();
  };
  const handleQuickPlayClick = () => {
    navigate('/lobby');
    onNavigated && onNavigated();
  };
  const handleLeagueScheduleClick = () => {
    navigate('/league-schedules');
    setOpenMoreMenu(false);
    setActiveTab('dummy');
    onNavigated && onNavigated();
  };
  const handleLeagueFaqClick = () => {
    navigate('/league-faq');
    setOpenMoreMenu(false);
    setActiveTab('dummy');
    onNavigated && onNavigated();
  };
  const handleBuyLeaguePassClick = () => {
    navigate('/pricing');
    setOpenMoreMenu(false);
    setActiveTab('dummy');
    onNavigated && onNavigated();
  };
  const handleTournamentClick = () => navigate('/tournament');
  const handleAdminConfigClick = () => {
    navigate('/admin/config');
    setOpenAdminMenu(false);
    setActiveTab('dummy2');
    onNavigated && onNavigated();
  };
  const handleAdminQuestionsSetupClick = () => {
    navigate('/admin/questions-setup');
    setOpenAdminMenu(false);
    setActiveTab('dummy2');
    onNavigated && onNavigated();
  };
  const handleAdminInaccuraciesReportClick = () => {
    navigate('/admin/inaccuracies-report');
    setOpenAdminMenu(false);
    setActiveTab('dummy2');
    onNavigated && onNavigated();
  };
  const handleAdminReviewQuestionsClick = () => {
    navigate('/admin/review-questions');
    setOpenAdminMenu(false);
    setActiveTab('dummy2');
    onNavigated && onNavigated();
  };
  const handleAdminArenaScheduleClick = () => {
    navigate('/admin/pub-night-schedule');
    setOpenAdminMenu(false);
    setActiveTab('dummy2');
    onNavigated && onNavigated();
  };

  const handleMoreClick = (event?: React.MouseEvent<HTMLElement>) => {
    if (event) {
      setAnchorMoreEl(event.currentTarget);
    }
    setOpenMoreMenu(true);
  };

  const handleAdminClick = (event?: React.MouseEvent<HTMLElement>) => {
    setOpenAdminMenu(true);
  };

  const handleTopNavChange = (event: any, tab: ActiveTabs) => {
    if (tab === 'team') handleTeamClick();
    if (tab === 'pub') handleArenaClick();
    if (tab === 'dailies') handleDailiesClick();
    if (tab === 'quick-play') handleQuickPlayClick();
    if (tab === 'tournament') handleTournamentClick();

    if (tab === 'admin') {
      handleAdminClick(event);
      return;
    }

    if (tab === 'more') {
      handleMoreClick(event);
      return;
    }

    setActiveTab(tab);
  };

  return (
    <>
      <HeaderNavigationTabs
        orientation={orientation}
        value={activeTab}
        onChange={handleTopNavChange}
        textColor="secondary"
      >
        {/* <Tab
          label={HeaderTabLabel('HOME')}
          value="home"
          sx={{
            pr: { sm: 0, md: 3 },
          }}
        /> */}
        <Tab
          label={HeaderTabLabel('PROFILE')}
          value="profile"
          sx={{ paddingX: { sm: 0, md: 2 } }}
          onClick={handleProfileClick}
        />
        {/* <Tab
          label={HeaderTabTeamLabel('TEAM', getJoinRequests())}
          value="team"
          sx={{
            paddingX: { sm: 0, md: 2 },
            display: userHasTeam(profile) ? 'inherit' : 'none',
          }}
          disabled={!userHasTeam(profile)}
        /> */}
        <Tab
          label={HeaderTabLabel('PUB NIGHT')}
          value="pub"
          sx={{ paddingX: { sm: 0, md: 2 }, height: '64px' }}
        />
        <Tab
          label={HeaderTabLabel('DAILIES')}
          value="dailies"
          sx={{ paddingX: { sm: 0, md: 2 }, height: '64px' }}
        />
        <Tab
          label={QuickPlayHeaderTab('LOBBY', quickPlayGamesOpen?.length)}
          value="quick-play"
          sx={{ paddingX: { sm: 0, md: 2 }, height: '64px' }}
        />
        {/* <Tab
          ref={moreRef}
          label={DropdownHeaderTab('MORE')}
          value="more"
          sx={{
            paddingX: { sm: 0, md: 2 },
            height: '64px',
            color: activeTab === 'dummy' ? 'secondary.main' : '', // this override is needed to allow the dropdown to be the active color
          }}
        /> */}
        {/* <Tab
        label={HeaderTab(activeTab === 'tournament', 'MUSIC TOURNEY')}
        value="tournament"
        sx={{ paddingX: { sm: 1, md: 3 } }}
      /> */}
        {profile?.admin ? (
          <Tab
            ref={adminRef}
            label={DropdownHeaderTab(
              'ADMIN',
              reportInaccuraciesCount + unreviewedQuestions
            )}
            value="admin"
            sx={{
              paddingX: { sm: 0, md: 2 },
              height: '64px',
              color: activeTab === 'dummy2' ? 'secondary.main' : '', // this override is needed to allow the dropdown to be the active color
            }}
          />
        ) : null}
      </HeaderNavigationTabs>

      <Menu
        anchorEl={anchorMoreEl}
        open={openMoreMenu}
        onClose={() => setOpenMoreMenu(false)}
      >
        {/* <MenuItem onClick={handleLeagueScheduleClick}>League Schedule</MenuItem> */}
        <MenuItem onClick={handleLeagueFaqClick}>League F.A.Q.</MenuItem>
        {/* <MenuItem onClick={handleBuyLeaguePassClick}>
          Buy a League Pass
        </MenuItem> */}
      </Menu>

      <Menu
        anchorEl={anchorAdminEl}
        open={openAdminMenu}
        onClose={() => setOpenAdminMenu(false)}
      >
        <MenuItem onClick={handleAdminConfigClick}>Config</MenuItem>
        <MenuItem onClick={handleAdminQuestionsSetupClick}>
          Questions Setup
        </MenuItem>
        <MenuItem onClick={handleAdminInaccuraciesReportClick}>
          Inaccuracies Report
          <Chip
            sx={{ ml: 1 }}
            color="error"
            label={reportInaccuraciesCount}
            size="small"
          />
        </MenuItem>
        <MenuItem onClick={handleAdminReviewQuestionsClick}>
          Review Questions
          <Chip
            sx={{ ml: 1 }}
            color="success"
            label={unreviewedQuestions}
            size="small"
          />
        </MenuItem>
        <MenuItem onClick={handleAdminArenaScheduleClick}>
          Pub Night Schedule
        </MenuItem>
      </Menu>
    </>
  );
};
