import { useQuery } from '@tanstack/react-query';
import { fetchArenaLadder } from '../../../../api/apiServices';

const useFetchArenaLadder = (season: number) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['arenaLadder', { season }],
    queryFn: fetchArenaLadder,
    enabled: !!season,
  });

  return {
    refetch,
    isLoading,
    data,
  };
};

export default useFetchArenaLadder;
