import React, { useState } from 'react';
import { CategorySelect } from './CategorySelect';
import { Box, Stack, Typography } from '@mui/material';
import { CreateGameConfig } from '../../../types/game';
import { ValorantButton } from '../../quick-play/ValorantButton/ValorantButton';
import { useLobbyGamesContext } from '../../../contexts/LobbyGamesProvider';
import { useProfileContext } from '../../../contexts/ProfileProvider';
import BasicLayout from '../../../BasicLayout/BasicLayout';

const NormalMenu = () => {
  const { profile } = useProfileContext();
  const { maintenance, handlePlayClick2 } = useLobbyGamesContext();
  const playClicked = false;

  const [categorySelections, setCategorySelections] = useState(
    profile?.recentPlayMenuSettings?.categorySelections || []
  );

  const disablePlayButton = categorySelections.length === 0;

  const updatedCheckedCategories = (categories: any) => {
    // console.log('updatedCheckedCategories', categories);
    const catSelections = Object.keys(categories).filter(
      (key) => categories[key]
    );
    setCategorySelections(catSelections);
  };

  const handlePlayNormal = () => {
    const createGameConfigs: CreateGameConfig = {
      allowSpectators: false,
      categorySelections,
      mode: 'solo',
    };
    handlePlayClick2(createGameConfigs);
  };

  return (
    <BasicLayout>
      <Stack pt={4}>
        <Typography pb={2} textAlign={'center'}>
          The standard PAR sets. Solo play. 10 questions. 20 seconds per
          question.
        </Typography>

        <CategorySelect
          initCategorySelections={
            profile?.recentPlayMenuSettings?.categorySelections || []
          }
          updatedCheckedCategories={updatedCheckedCategories}
        />

        <Box pt={2} display="flex" justifyContent={'center'}>
          <ValorantButton
            disabled={
              disablePlayButton || playClicked || maintenance?.scheduled
            }
            label={'START SOLO'}
            loading={playClicked}
            loadingLabel={'STARTING'}
            onClick={handlePlayNormal}
            size="large"
          />
        </Box>
      </Stack>
    </BasicLayout>
  );
};

export default NormalMenu;
