import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';

export const ScoreScreenLoading = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        zIndex: 9999,
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Stack
        spacing={5}
        height={220}
        width={500}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          boxShadow: `${theme.palette.primary.main} 0px 0px 4px -1px, ${theme.palette.primary.main} 0px 0px 5px 0px, ${theme.palette.primary.main} 0px 0px 10px 0px;`,
          border: '1px solid',
          borderColor: 'black',
          borderRadius: 2,
          backgroundColor: 'rgb(20 31 38 / 90%)',
        }}
      >
        <Typography
          variant="h5"
          fontWeight={600}
          letterSpacing={5}
          sx={{
            textShadow: `0 0 1px ${theme.palette.primary.main}, 10px 0px 20px ${theme.palette.primary.main}, 0px 0px 16px ${theme.palette.primary.main}`,
          }}
        >
          LOADING SCORECARD
        </Typography>
        <CircularProgress />
      </Stack>
    </Box>
  );
};
