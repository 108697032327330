import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  Fade,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DrawerButtonWhosOnline } from './DrawerButtonWhosOnline';
import { MessagesDrawer } from './MessagesDrawer';
import { DrawerButtonChats } from './DrawerButtonChats';

import { useLocation } from 'react-router-dom';
import { useLoggedInUserContext } from '../../contexts/LoggedInUserProvider';
import { useProfileContext } from '../../contexts/ProfileProvider';
import { useSocketContext } from '../../contexts/SocketProvider';
import { LoggedInUser } from '../../types/user';
import { useChatGeneral } from '../../hooks/useChatGeneral';
import { DrawerButtonPregameBar } from '../footer/PregameBar/DrawerButtonPregameBar';
import { useGameContext } from '../../contexts/GameProvider';
import { PregameDrawer } from '../footer/PregameBar/PregameDrawer';
import { useChats } from '../footer/Chats/hooks/useChats';

export const PREGAME_WIDTH = 440;

export const DrawerButton = styled(Button)(({ theme }) => ({
  width: 288,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
}));

export const ExpandIcon = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  backgroundColor: 'transparent',
  outline: 0,
  border: 0,
  margin: 0,
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  appearance: 'none',
  textDecoration: 'none',
  textAlign: 'center',
  flex: '0 0 auto',
  borderRadius: '50%',
  overflow: 'visible',
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  padding: '5px',
  fontSize: '1.125rem',
  float: 'right',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
}));

export const getWhosOnlineCount = (
  loggedInUsers: LoggedInUser[],
  profileId: string
) =>
  loggedInUsers?.filter(
    (u) => u.online && !u.isIdle && u.profileId !== profileId
  ).length;

export const BottomNav = () => {
  const theme = useTheme();
  const location = useLocation();

  const { profile } = useProfileContext();
  const { showPregame } = useGameContext();

  const { loggedInUsers } = useLoggedInUserContext();
  const [openChatDrawer, setOpenChatDrawer] = useState(false);
  const [openMessagesDrawer, setOpenMessagesDrawer] = useState(false);
  const [openPregameBar, setOpenPregameBar] = useState(false);
  const [totalUnread, setTotalUnread] = useState(0);

  const xsBreakpoint = useMediaQuery(theme.breakpoints.only('xs'));
  const HEIGHT_FROM_BOTTOM = 'calc(100vh - 192px)';

  useEffect(() => {
    console.log('showPregame: ', showPregame);
    if (showPregame) {
      setOpenPregameBar(true);
      setOpenChatDrawer(true);
    }
  }, [showPregame]);

  useEffect(() => {
    if (location.pathname === '/home') {
      setOpenMessagesDrawer(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (openMessagesDrawer && xsBreakpoint) {
      setOpenChatDrawer(false);
    }
  }, [openMessagesDrawer]);

  const onUpdateTotalUnread = (unread: number) => setTotalUnread(unread);

  return (
    <Box
      position={'fixed'}
      bottom={0}
      width={'100vw'}
      height={48}
      zIndex={1200}
    ></Box>
  );
};
