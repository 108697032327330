import React, { useEffect, useState } from 'react';
import { Box, Divider, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { LeagueSubNavTabs, LEAGUE_TAB_MAP } from './constants';
import { useNavigate, useLocation } from 'react-router-dom';

type PropTypes = {
  isAdmin: boolean;
};

export const LeagueSubNav = ({ isAdmin }: PropTypes) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<LeagueSubNavTabs>('overview');

  useEffect(() => {
    const activeRoute = Object.keys(LEAGUE_TAB_MAP).find((route) =>
      location.pathname.includes(route)
    );
    if (activeRoute) {
      setActiveTab(LEAGUE_TAB_MAP[activeRoute]);
    }
  }, [location]);

  const handleLeagueOverviewClick = () => navigate('/stats/overview');
  const handleLeagueTeamsClick = () => navigate('/stats/teams');
  const handleLeagueStandingsClick = () => navigate('/stats/standings');
  const handleLeaguePlayersClick = () => navigate('/stats/players');
  const handleLeagueRulesClick = () => navigate('/stats/rules');
  const handleLeagueJoinClick = () => navigate('/stats/join');
  const handleLeagueManageClick = () => navigate('/stats/manage');

  const handleSubNavChange = (event: any, tab: LeagueSubNavTabs) => {
    setActiveTab(tab);
    if (tab === 'overview') handleLeagueOverviewClick();
    if (tab === 'teams') handleLeagueTeamsClick();
    if (tab === 'standings') handleLeagueStandingsClick();
    if (tab === 'players') handleLeaguePlayersClick();
    if (tab === 'rules') handleLeagueRulesClick();
    if (tab === 'join') handleLeagueJoinClick();
    if (tab === 'manage') handleLeagueManageClick();
  };

  const SubHeaderTab = (isActiveTab: boolean, text: string) => (
    <Typography
      variant={isActiveTab ? 'subtitle2' : 'subtitle2'}
      letterSpacing={2}
    >
      {text}
    </Typography>
  );

  return (
    <Box
      display={'flex'}
      justifyContent={'left'}
      mt={2}
      sx={{
        borderRadius: '10px',
      }}
    >
      <Tabs
        variant="scrollable"
        value={false}
        onChange={handleSubNavChange}
        indicatorColor="secondary"
        sx={{
          width: '100%',
          height: 0,
          borderRadius: '10px',
          backgroundColor: 'rgb(0 0 0 / 35%)',
          '& .MuiTabs-flexContainer': { height: '100%' },
        }}
      >
        {/* <Tab
          label={SubHeaderTab(activeTab === 'overview', 'Overview')}
          value="overview"
        />
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            height: '40%',
            alignSelf: 'center',
            borderRightWidth: 2,
          }}
        /> */}
        <Tab
          label={SubHeaderTab(activeTab === 'standings', 'Standings')}
          value="standings"
        />
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            height: '40%',
            alignSelf: 'center',
            borderRightWidth: 2,
          }}
        />
        <Tab
          label={SubHeaderTab(activeTab === 'players', 'Players')}
          value="players"
        />
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            height: '40%',
            alignSelf: 'center',
            borderRightWidth: 2,
          }}
        />
        <Tab
          label={SubHeaderTab(activeTab === 'teams', 'Teams')}
          value="teams"
        />
        {/* <Divider
          flexItem
          orientation="vertical"
          sx={{
            height: '40%',
            alignSelf: 'center',
            borderRightWidth: 2,
          }}
        />
        <Tab
          label={SubHeaderTab(activeTab === 'rules', 'Rules')}
          value="rules"
        />
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            height: '40%',
            alignSelf: 'center',
            borderRightWidth: 2,
          }}
        />
        <Tab
          label={SubHeaderTab(activeTab === 'join', 'How to join')}
          value="join"
        /> */}
        {isAdmin ? (
          <Divider
            flexItem
            orientation="vertical"
            sx={{
              height: '40%',
              alignSelf: 'center',
              borderRightWidth: 2,
            }}
          />
        ) : null}
        {isAdmin ? (
          <Tab
            label={SubHeaderTab(activeTab === 'manage', 'Manage')}
            value="manage"
          />
        ) : null}
      </Tabs>
    </Box>
  );
};
