import {
  PASSABLE_FUZZY_SCORE,
  POISED_FUZZY_SCORE,
} from '../../../constants/constants';

export const getFuzzyPenalty = (fuzzyScore: number) => {
  if (!fuzzyScore) return 0;
  if (fuzzyScore === 1) return -1;
  if (fuzzyScore >= POISED_FUZZY_SCORE && fuzzyScore < 1) return 0;
  if (fuzzyScore >= PASSABLE_FUZZY_SCORE && fuzzyScore < POISED_FUZZY_SCORE)
    return 1;
  if (fuzzyScore < PASSABLE_FUZZY_SCORE) return 2;
};
