import { useCallback, useEffect, useRef, useState } from 'react';
import { useLobbyGamesContext } from '../contexts/LobbyGamesProvider';
import { useProfileContext } from '../contexts/ProfileProvider';
import {
  useInputSocketContext,
  useSocketContext,
} from '../contexts/SocketProvider';
import {
  BracketLiveScores,
  BracketLiveScore,
} from '../types/league-tournament';

const bracketLiveScoreQueue: {
  gameId: string;
  action: 'addJoinedPlayer' | 'removeJoinedPlayer' | 'updateScore';
  data: Record<string, any>;
}[] = [];
let bracketQueueIsProcessing = false;

export const useBracketLiveScores = () => {
  const { profile } = useProfileContext();
  const { currentLeagueTournamentNumber } = useLobbyGamesContext();

  const { socket } = useSocketContext();
  const { inputSocket } = useInputSocketContext();

  const [bracketLiveScores, setBracketLiveScores] = useState<BracketLiveScores>(
    {}
  );
  const bracketLiveScoresRef = useRef<BracketLiveScores>({});

  useEffect(() => {
    if (!socket) return;
    console.log('BRACKET LIVE SCORE SOCKET');
    socket.on('bracketLiveScoreUpdate', enqueueUpdateBracketLiveScore);

    requestBracketLiveScores();
    return () => {
      socket.off('bracketLiveScoreUpdate', enqueueUpdateBracketLiveScore);
    };
  }, [socket]);

  useEffect(() => {
    if (!inputSocket) return;

    inputSocket.on('bracketLiveScoreUpdate', enqueueUpdateBracketLiveScore);

    return () => {
      inputSocket.off('bracketLiveScoreUpdate', enqueueUpdateBracketLiveScore);
    };
  }, [inputSocket]);

  useEffect(() => {
    if (!bracketLiveScores) return;
    bracketLiveScoresRef.current = bracketLiveScores;
  }, [bracketLiveScores]);

  const enqueueUpdateBracketLiveScore = async (data: {
    gameId: string;
    action: 'addJoinedPlayer' | 'removeJoinedPlayer' | 'updateScore';
    data: Record<string, any>;
  }) => {
    bracketLiveScoreQueue.push(data);
    console.log('enqueueUpdateBracketLiveScore', data);
    await processUpdateBracketLiveScore();
  };

  const processUpdateBracketLiveScore = async () => {
    if (bracketQueueIsProcessing || bracketLiveScoreQueue.length === 0) {
      return;
    }

    bracketQueueIsProcessing = true;
    const data = bracketLiveScoreQueue.shift();

    updateBracketLiveScore(data);

    setTimeout(async () => {
      bracketQueueIsProcessing = false;
      await processUpdateBracketLiveScore();
    }, 50);
  };

  const updateBracketLiveScore = (data: {
    gameId: string;
    action:
      | 'addGame'
      | 'removeGame'
      | 'addJoinedPlayer'
      | 'removeJoinedPlayer'
      | 'updateScore'
      | 'updateQuestionIndex'
      | 'gameOver'
      | 'gameStarted';
    data: Record<string, any>;
  }) => {
    const bracketLiveScore = bracketLiveScoresRef.current[data.gameId];
    console.log('updateBracketLiveScore', bracketLiveScore);
    if (!bracketLiveScore) return;
    let updatedBracketLiveScores: BracketLiveScores;
    if (data.action === 'addGame') {
      updatedBracketLiveScores = {
        ...bracketLiveScoresRef.current,
        [data.gameId]: data.data as BracketLiveScore,
      };
    }
    if (data.action === 'removeGame') {
      delete bracketLiveScoresRef.current[data.gameId];
      updatedBracketLiveScores = bracketLiveScoresRef.current;
    }
    if (data.action === 'addJoinedPlayer') {
      updatedBracketLiveScores = {
        ...bracketLiveScoresRef.current,
        [data.gameId]: {
          ...bracketLiveScore,
          joinedPlayers: bracketLiveScore.joinedPlayers.concat(
            data.data.profileId
          ),
        },
      };
    }
    if (data.action === 'removeJoinedPlayer') {
      updatedBracketLiveScores = {
        ...bracketLiveScoresRef.current,
        [data.gameId]: {
          ...bracketLiveScore,
          joinedPlayers: bracketLiveScore.joinedPlayers.filter(
            (p) => p !== data.data.profileId
          ),
        },
      };
    }
    if (data.action === 'updateScore') {
      updatedBracketLiveScores = {
        ...bracketLiveScoresRef.current,
        [data.gameId]: {
          ...bracketLiveScore,
          teams: {
            ...bracketLiveScore.teams,
            [data.data.teamId]: {
              ...bracketLiveScore.teams[data.data.teamId],
              score: data.data.score,
            },
          },
        },
      };
    }
    if (data.action === 'gameOver') {
      updatedBracketLiveScores = {
        ...bracketLiveScoresRef.current,
        [data.gameId]: {
          ...bracketLiveScore,
          gameOver: true,
        },
      };
    }
    if (data.action === 'gameStarted') {
      updatedBracketLiveScores = {
        ...bracketLiveScoresRef.current,
        [data.gameId]: {
          ...bracketLiveScore,
          gameStarted: true,
        },
      };
    }
    if (data.action === 'updateQuestionIndex') {
      updatedBracketLiveScores = {
        ...bracketLiveScoresRef.current,
        [data.gameId]: {
          ...bracketLiveScore,
          questionIndex: data.data.questionIndex,
        },
      };
    }
    setBracketLiveScores(updatedBracketLiveScores);
  };

  const requestBracketLiveScores = useCallback(() => {
    if (!profile || !socket) return;
    socket.emit(
      'requestBracketLiveScores',
      {
        profileId: profile._id,
        leagueTournamentNumber: currentLeagueTournamentNumber,
      },
      (res) => {
        bracketLiveScoresRef.current = res.bracketLiveScores;
        console.log('res.bracketLiveScores;', res.bracketLiveScores);
        setBracketLiveScores(res.bracketLiveScores);
      }
    );
  }, [profile, socket]);

  return {
    bracketLiveScores,
    requestBracketLiveScores,
  };
};
