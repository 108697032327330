import { styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import {
  POISED_FUZZY_SCORE,
  PASSABLE_FUZZY_SCORE,
} from '../../../../constants/constants';

type BoxProps = {
  bottom: string;
  top: string;
  left: string;
  right: string;
  transform: string;
};

export const PristineBox = styled(Box)<BoxProps>(
  ({ bottom, top, left, right, transform }) => ({
    position: 'absolute',
    bottom: bottom,
    top: top,
    right: right,
    left: left,
    transform: transform,
    backgroundColor: 'lightgray',
    borderRadius: 4,
    boxShadow: `
          inset 0 0 50px #e0e0e0,      /* inner white */
          inset 20px 0 80px #f0f,   /* inner left magenta short */
          inset -20px 0 80px #0ff,  /* inner right cyan short */
          inset 20px 0 300px #f0f,  /* inner left magenta broad */
          inset -20px 0 300px #0ff, /* inner right cyan broad */
          0 0 10px #e0e0e0,            /* outer white */
          -5px 0 40px #f0f,        /* outer left magenta */
          5px 0 40px #0ff;         /* outer right cyan */`,
  })
);

export const PristineTypography = styled(Typography)(() => ({
  fontFamily: 'blacksword',
  textAlign: 'center',
  letterSpacing: 8,
  color: 'darkgreen',
  fontWeight: 600,
}));

const PristineTypographyPenalty = styled(Typography)(() => ({
  fontFamily: 'round-pixel',
  textAlign: 'center',
  position: 'absolute',
  top: '-11px',
  left: '160px',
  color: 'darkgreen',
  backgroundColor: '#fcd4fd',
  borderRadius: 4,
}));

export const PoisedBox = styled(Box)<BoxProps>(
  ({ bottom, top, left, right, transform }) => ({
    position: 'absolute',
    bottom: bottom,
    top: top,
    right: right,
    left: left,
    transform: transform,
  })
);

export const PoisedTypography = styled(Typography)(() => ({
  fontFamily: 'barbatrick',
  textAlign: 'center',
  color: 'red',
}));

const PoisedTypographyPenalty = styled(Typography)(() => ({
  fontFamily: 'round-pixel',
  textAlign: 'center',
  position: 'absolute',
  top: '-10px',
  left: '170px',
  color: 'red',
  fontStyle: 'italic',
}));

export const PassableBox = styled(Box)<BoxProps>(
  ({ bottom, top, left, right, transform }) => ({
    position: 'absolute',
    bottom: bottom,
    top: top,
    right: right,
    left: left,
    transform: transform,
    paddingLeft: '1px',
    paddingRight: '1px',
    border: '1px solid',
    borderColor: 'cadetblue',
  })
);

export const PassableTypography = styled(Typography)(() => ({
  fontFamily: 'round-pixel',
  textAlign: 'center',
  paddingLeft: '1px',
  border: '1px solid',
  borderColor: 'cadetblue',
  color: 'cadetblue',
}));

const PassableTypographyPenalty = styled(Typography)(() => ({
  fontFamily: 'round-pixel',
  textAlign: 'center',
  position: 'absolute',
  top: '-11px',
  left: '180px',
  backgroundColor: 'cadetblue',
  borderRadius: 4,
}));

export const PanickyBox = styled(Box)<BoxProps>(
  ({ bottom, top, left, right, transform }) => ({
    position: 'absolute',
    bottom: bottom,
    top: top,
    right: right,
    left: left,
    transform: transform,
    paddingLeft: '7px',
    paddingRight: '7px',
    border: '1px solid orange',
    borderRadius: 4,
  })
);

export const PanickyTypography = styled(Typography)(() => ({
  fontFamily: 'schoolbell',
  textAlign: 'center',
}));

const PanickyTypographyPenalty = styled(Typography)(() => ({
  fontFamily: 'round-pixel',
  textAlign: 'center',
  position: 'absolute',
  top: '-9px',
  left: '145px',
  backgroundColor: 'orange',
  borderRadius: 4,
}));

const boxPositionStyleProps = {
  pristine: {
    bottom: '',
    top: '50%',
    left: '',
    right: '-8px',
    transform: 'translate(0, -50%)',
  },
  poised: {
    bottom: '',
    top: '50%',
    left: '',
    right: '-2px',
    transform: 'translate(0, -50%)',
  },
  passable: {
    bottom: '',
    top: '50%',
    left: '',
    right: '-8px',
    transform: 'translate(0, -50%)',
  },
  panicky: {
    bottom: '',
    top: '50%',
    left: '',
    right: '-8px',
    transform: 'translate(0, -50%)',
  },
};

type PrecisionLabels = 'Pristine' | 'Poised' | 'Passable' | 'Panicky';

type PropTypes = {
  fuzzyScore: number;
  forScoreCard?: boolean;
};

export const PrecisionGraphicIcon = ({
  fuzzyScore,
  forScoreCard,
}: PropTypes) => {
  const [precisionLabel, setPrecisionLabel] = useState<PrecisionLabels>();
  const [stylePositionProps, setStylePositionProps] = useState<
    Record<string, BoxProps>
  >(boxPositionStyleProps);

  useEffect(() => {
    if (forScoreCard) {
      const scoreCardBoxPositionStyleProps = {
        pristine: {
          bottom: '',
          top: '5px',
          left: '5px',
          right: '',
          transform: '',
        },
        poised: {
          bottom: '',
          top: '5px',
          left: '5px',
          right: '',
          transform: '',
        },
        passable: {
          bottom: '',
          top: '5px',
          left: '5px',
          right: '',
          transform: '',
        },
        panicky: {
          bottom: '',
          top: '5px',
          left: '5px',
          right: '',
          transform: '',
        },
      };
      setStylePositionProps(scoreCardBoxPositionStyleProps);
    }
  }, [forScoreCard]);

  useEffect(() => {
    if (fuzzyScore === 1) {
      setPrecisionLabel('Pristine');
    }
    if (fuzzyScore >= POISED_FUZZY_SCORE && fuzzyScore < 1) {
      setPrecisionLabel('Poised');
    }
    if (fuzzyScore >= PASSABLE_FUZZY_SCORE && fuzzyScore < POISED_FUZZY_SCORE) {
      setPrecisionLabel('Passable');
    }
    if (fuzzyScore < PASSABLE_FUZZY_SCORE) {
      setPrecisionLabel('Panicky');
    }
  }, [fuzzyScore]);

  return (
    <>
      {precisionLabel === 'Pristine' ? (
        <PristineBox
          top={stylePositionProps?.pristine.top}
          bottom={stylePositionProps?.pristine.bottom}
          left={stylePositionProps?.pristine.left}
          right={stylePositionProps?.pristine.right}
          transform={stylePositionProps?.pristine.transform}
        >
          <PristineTypography variant={'caption'}>P</PristineTypography>
        </PristineBox>
      ) : precisionLabel === 'Poised' ? (
        <PoisedBox
          top={stylePositionProps?.poised.top}
          bottom={stylePositionProps?.poised.bottom}
          left={stylePositionProps?.poised.left}
          right={stylePositionProps?.poised.right}
          transform={stylePositionProps?.poised.transform}
        >
          <PoisedTypography variant={'h6'}>P</PoisedTypography>
        </PoisedBox>
      ) : precisionLabel === 'Passable' ? (
        <PassableBox
          top={stylePositionProps?.passable.top}
          bottom={stylePositionProps?.passable.bottom}
          left={stylePositionProps?.passable.left}
          right={stylePositionProps?.passable.right}
          transform={stylePositionProps?.passable.transform}
        >
          <PassableTypography variant={'caption'}>P </PassableTypography>
        </PassableBox>
      ) : precisionLabel === 'Panicky' ? (
        <PanickyBox
          top={stylePositionProps?.panicky.top}
          bottom={stylePositionProps?.panicky.bottom}
          left={stylePositionProps?.panicky.left}
          right={stylePositionProps?.panicky.right}
          transform={stylePositionProps?.panicky.transform}
        >
          <PanickyTypography variant={'subtitle2'}>P</PanickyTypography>
        </PanickyBox>
      ) : null}
    </>
  );
};
