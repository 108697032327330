import {
  Box,
  Card,
  Divider,
  Grid,
  Link,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { useProfileContext } from '../../contexts/ProfileProvider';
import BasicLayout from '../../BasicLayout/BasicLayout';

const HelpPage = () => {
  const { profile } = useProfileContext();

  return (
    <BasicLayout>
      <Grid container spacing={3}>
        <Grid item xs={3} md={3}></Grid>
        <Grid item xs={6} md={6}>
          <Card
            sx={{
              padding: 2,
            }}
          >
            <Typography
              variant="h5"
              mb={2}
              textAlign={'center'}
              letterSpacing={2}
            >
              HELP
            </Typography>

            <Divider />

            <Stack spacing={2} mt={2}>
              {/* <Typography fontWeight={600} fontFamily={'noto-sans'}>
                General support (account, billing, game, etc.)
              </Typography> */}

              <Typography fontFamily={'noto-sans'}>
                Please reach out to the admins on the
                <Link
                  sx={{ cursor: 'pointer', marginX: 1 }}
                  href="https://discord.gg/upX48KPfHd"
                  target="_blank"
                >
                  PAR Discord server
                </Link>
                for any questions or issues you may have.
              </Typography>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={3} md={3}></Grid>
      </Grid>
    </BasicLayout>
  );
};

export default HelpPage;
