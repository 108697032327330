import { Paper, IconButton } from '@mui/material';
import React from 'react';

type SocialMediaIconButtonPropTypes = {
  brandColor: string;
  icon: React.ReactNode;
  link: string;
};
export const SocialMediaIconButton = ({
  brandColor,
  icon,
  link,
}: SocialMediaIconButtonPropTypes) => {
  return (
    <Paper
      elevation={12}
      sx={{
        backgroundColor: brandColor,
        backgroundImage: 'none',
        borderRadius: 1,
      }}
    >
      <IconButton
        href={link}
        target="_blank"
        sx={{
          fontSize: 20,
          borderRadius: 1,
        }}
      >
        {icon}
      </IconButton>
    </Paper>
  );
};
