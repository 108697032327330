import { styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import {
  POISED_FUZZY_SCORE,
  PASSABLE_FUZZY_SCORE,
} from '../../../../constants/constants';

type BoxProps = { reverse?: boolean };

const PristineBox = styled(Box)<BoxProps>(({ reverse }) => ({
  rotate: reverse ? '11deg' : '-11deg',
  position: 'absolute',
  top: '-30px',
  left: reverse ? '320px' : '-200px',
  padding: '7px',
  backgroundColor: 'lightgray',
  borderRadius: 4,
  boxShadow: `
          inset 0 0 120px #e0e0e0,      /* inner white */
          inset 20px 0 80px #f0f,   /* inner left magenta short */
          inset -20px 0 80px #0ff,  /* inner right cyan short */
          inset 20px 0 300px #f0f,  /* inner left magenta broad */
          inset -20px 0 300px #0ff, /* inner right cyan broad */
          0 0 25px #e0e0e0,            /* outer white */
          -5px 0 40px #f0f,        /* outer left magenta */
          5px 0 40px #0ff;         /* outer right cyan */`,
}));

export const PristineTypography = styled(Typography)(() => ({
  fontFamily: 'blacksword',
  textAlign: 'center',
  letterSpacing: 8,
  color: 'darkgreen',
  fontWeight: 600,
}));

const PristineTypographyPenalty = styled(Typography)(() => ({
  fontFamily: 'round-pixel',
  textAlign: 'center',
  position: 'absolute',
  top: '-11px',
  left: '135px',
  color: 'darkgreen',
  backgroundColor: '#fcd4fd',
  borderRadius: 4,
}));

const PoisedBox = styled(Box)<BoxProps>(({ reverse }) => ({
  rotate: reverse ? '11deg' : '-11deg',
  position: 'absolute',
  top: '-30px',
  left: reverse ? '260px' : '-140px',
}));

export const PoisedTypography = styled(Typography)(() => ({
  fontFamily: 'barbatrick',
  textAlign: 'center',
  letterSpacing: 8,
  color: 'red',
}));

const PoisedTypographyPenalty = styled(Typography)(() => ({
  fontFamily: 'round-pixel',
  textAlign: 'center',
  position: 'absolute',
  top: '-10px',
  left: '135px',
  color: 'red',
  fontStyle: 'italic',
}));

const PassableBox = styled(Box)<BoxProps>(({ reverse }) => ({
  rotate: reverse ? '11deg' : '-11deg',
  position: 'absolute',
  top: '-30px',
  left: reverse ? '305px' : '-185px',
  padding: '3px',
  border: '1px solid',
  borderColor: 'cadetblue',
}));

export const PassableTypography = styled(Typography)(() => ({
  fontFamily: 'round-pixel',
  textAlign: 'center',
  padding: '5px',
  border: '1px solid',
  borderColor: 'cadetblue',
  color: 'cadetblue',
}));

const PassableTypographyPenalty = styled(Typography)(() => ({
  fontFamily: 'round-pixel',
  textAlign: 'center',
  position: 'absolute',
  top: '-11px',
  left: '150px',
  backgroundColor: 'cadetblue',
  borderRadius: 4,
}));

const PanickyBox = styled(Box)<BoxProps>(({ reverse }) => ({
  rotate: reverse ? '11deg' : '-11deg',
  position: 'absolute',
  top: '-30px',
  left: reverse ? '295px' : '-175px',
  padding: '7px',
  border: '1px solid orange',
  borderRadius: 4,
}));

export const PanickyTypography = styled(Typography)(() => ({
  fontFamily: 'schoolbell',
  textAlign: 'center',
  letterSpacing: 8,
}));

const PanickyTypographyPenalty = styled(Typography)(() => ({
  fontFamily: 'round-pixel',
  textAlign: 'center',
  position: 'absolute',
  top: '-9px',
  left: '120px',
  backgroundColor: 'orange',
  borderRadius: 4,
}));

type PrecisionLabels = 'Pristine' | 'Poised' | 'Passable' | 'Panicky';

type PropTypes = {
  fuzzyScore: number;
  reverse?: boolean;
};

export const PrecisionGraphic = ({ fuzzyScore, reverse }: PropTypes) => {
  const [precisionLabel, setPrecisionLabel] = useState<PrecisionLabels>();

  useEffect(() => {
    if (fuzzyScore === 1) {
      setPrecisionLabel('Pristine');
    }
    if (fuzzyScore >= POISED_FUZZY_SCORE && fuzzyScore < 1) {
      setPrecisionLabel('Poised');
    }
    if (fuzzyScore >= PASSABLE_FUZZY_SCORE && fuzzyScore < POISED_FUZZY_SCORE) {
      setPrecisionLabel('Passable');
    }
    if (fuzzyScore < PASSABLE_FUZZY_SCORE) {
      setPrecisionLabel('Panicky');
    }
  }, [fuzzyScore]);

  return (
    <>
      {precisionLabel === 'Pristine' ? (
        <PristineBox reverse={reverse}>
          <PristineTypography variant={'h5'}>
            {precisionLabel}
          </PristineTypography>
          <PristineTypographyPenalty>-1</PristineTypographyPenalty>
        </PristineBox>
      ) : precisionLabel === 'Poised' ? (
        <PoisedBox reverse={reverse}>
          <PoisedTypography variant={'h5'}>{precisionLabel}</PoisedTypography>
          <PoisedTypographyPenalty>0</PoisedTypographyPenalty>
        </PoisedBox>
      ) : precisionLabel === 'Passable' ? (
        <PassableBox reverse={reverse}>
          <PassableTypography variant={'body1'}>
            {precisionLabel}
          </PassableTypography>
          <PassableTypographyPenalty>+1</PassableTypographyPenalty>
        </PassableBox>
      ) : precisionLabel === 'Panicky' ? (
        <PanickyBox reverse={reverse}>
          <PanickyTypography variant={'h5'}>{precisionLabel}</PanickyTypography>
          <PanickyTypographyPenalty>+2</PanickyTypographyPenalty>
        </PanickyBox>
      ) : null}
    </>
  );
};
