import { Box, Drawer, Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import Chats from './Chats/Chats';
import { useProfileContext } from '../../contexts/ProfileProvider';
import { useLocation } from 'react-router-dom';
import { DrawerButtonWhosOnline } from '../bottom-nav/DrawerButtonWhosOnline';
import { useLoggedInUserContext } from '../../contexts/LoggedInUserProvider';
import { MessagesDrawer } from '../bottom-nav/MessagesDrawer';
import { gameInProgress } from '../../utils/loggedInUser';

export const PREGAME_WIDTH = 440;
const HEIGHT_FROM_BOTTOM = 'calc(100vh - 152px)';

export const Footer = () => {
  const theme = useTheme();
  const location = useLocation();
  const mdDownBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const CHATS_WIDTH = 500;

  const { profile } = useProfileContext();

  const { loggedInUser, loggedInUsers } = useLoggedInUserContext();

  const [inputFocused, setInputFocused] = useState(false);
  const [openMessagesDrawer, setOpenMessagesDrawer] = useState(false);

  const handleOnInputFocus = (focused: boolean) => {
    setInputFocused(focused);
  };

  if (gameInProgress(loggedInUser)) {
    return null;
  }

  return (
    <Stack
      direction={'row'}
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: '48px',
        zIndex: 1300,
      }}
    >
      {/* Whos Online */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          bgcolor: 'black',
        }}
      >
        <DrawerButtonWhosOnline
          loggedInUsers={loggedInUsers}
          openMessagesDrawer={openMessagesDrawer}
          profile={profile}
          setOpenMessagesDrawer={setOpenMessagesDrawer}
        />

        {/* Who's Online */}
        <Drawer
          anchor={'bottom'}
          open={openMessagesDrawer}
          hideBackdrop
          variant="persistent"
          onClose={() => setOpenMessagesDrawer(!openMessagesDrawer)}
          PaperProps={{
            sx: {
              position: 'fixed',
              width: 288,
              bottom: openMessagesDrawer ? 0 : 40,
              left: 'auto',
              height: HEIGHT_FROM_BOTTOM,
              bgcolor: 'black',
            },
          }}
        >
          <MessagesDrawer
            profile={profile}
            loggedInUsers={loggedInUsers}
            openDrawer={openMessagesDrawer}
            setOpenDrawer={setOpenMessagesDrawer}
          />
        </Drawer>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          right: mdDownBreakpoint ? 0 : `calc(50% - ${CHATS_WIDTH / 2}px)`,
          width: CHATS_WIDTH,
          transition: 'height 0.2s ease',
          overflow: 'hidden',
          WebkitMaskImage: !inputFocused
            ? `-webkit-gradient(linear, left top, left bottom, 
            from(rgba(0,0,0,0)), color-stop(30%, rgba(0, 0, 0, 1)), to(rgba(0,0,0,1)))`
            : null,
          height: inputFocused ? '100%' : '50px',
        }}
      >
        <Chats
          inputFocused={inputFocused}
          loggedInUser={loggedInUser}
          onInputFocus={handleOnInputFocus}
          profileId={profile?._id}
          username={profile?.username}
        />
      </Box>
    </Stack>
  );
};
