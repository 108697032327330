import { useState } from 'react';

let interval;

export const useBlinkingState = () => {
  const [isBlinking, setIsBlinking] = useState(false);

  const startBlinking = () => {
    let currentState = false;
    setIsBlinking(true);
    const startAnimation = () => {
      if (currentState) {
        setIsBlinking(true);
      } else {
        setIsBlinking(false);
      }
      currentState = !currentState;
    };
    interval = setInterval(startAnimation, 1000);
  };

  const stopBlinking = () => {
    setIsBlinking(false);
    clearInterval(interval);
  };

  return { isBlinking, startBlinking, stopBlinking };
};
