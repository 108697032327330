import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import './PenaltyLockBar.css';

type PropTypes = {
  hideText?: boolean;
  opacity?: number;
};

export const PenaltyLockBar = ({ hideText, opacity }: PropTypes) => {
  const [lockBarWidth, setLockBarWidth] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setLockBarWidth(100);
    }, 0);
  }, []);

  return (
    <div className="lock-bar" style={{ opacity: opacity || 1 }}>
      <div className="lock-bar-right">
        <div
          className="lock-bar-right-fill"
          style={{ width: `${lockBarWidth}%` }}
        >
          {!hideText ? (
            <Typography
              variant="subtitle1"
              textAlign="center"
              color="warning.contrastText"
            >
              LOCKED
            </Typography>
          ) : null}
        </div>
      </div>
    </div>
  );
};
