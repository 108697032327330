import { Box, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ChatMessage } from '../../types/chat';

const colors = [
  '#FF6B6B', // Bright Red
  '#FFD93D', // Bright Yellow
  '#32FF6A', // Neon Green
  '#4D96FF', // Sky Blue
  '#C355F5', // Purple
  '#FF926B', // Orange
  '#6BCB77', // Mint Green
  '#FF61FF', // Pink
  '#00F5D4', // Turquoise
  '#F5F548', // Lemon Yellow
];

type PropTypes = {
  chat: ChatMessage[];
  handleChatBoxClick: () => void;
};

export const SidebarChat = ({ chat, handleChatBoxClick }: PropTypes) => {
  const theme = useTheme();
  const uniqueUsernames = new Set<string>();

  // const [uniqueUsernames, setUniqueUsernames] = useState<Set<string>>()
  const [colorAssignment, setColorAssignment] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    if (!chat) return;
    assignColorToUsernames(chat);
  }, [chat]);

  const assignColorToUsernames = (chats: ChatMessage[]) => {
    chats.forEach((chat) => uniqueUsernames.add(chat.username));

    const colorAssignment: Record<string, string> = {};
    let colorIndex = 0;

    uniqueUsernames.forEach((username) => {
      colorAssignment[username] = colors[colorIndex % colors.length];
      colorIndex++;
    });

    setColorAssignment(colorAssignment);
  };

  return (
    <Box
      onClick={handleChatBoxClick}
      sx={{
        minHeight: '100px',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column-reverse',
      }}
    >
      <Stack spacing={1}>
        {chat.map((c, i) => (
          <Stack
            key={i}
            direction={'row'}
            alignItems={'center'}
            sx={{ position: 'relative' }}
          >
            <Typography
              fontSize={14}
              fontFamily={
                'Inter,Roobert,Helvetica Neue,Helvetica,Arial,sans-serif'
              }
              fontStyle={c.leftChat ? 'italic' : 'normal'}
              color={
                c.leftChat
                  ? theme.palette.text.disabled
                  : theme.palette.text.primary
              }
              fontWeight={400}
              ml={2}
              textAlign={'left'}
              width={'100%'}
              lineHeight={1.5}
            >
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  marginRight: 10,
                  textAlign: 'left',
                  color: colorAssignment[c.username],
                  whiteSpace: 'pre',
                }}
              >
                {c.username.length > 12
                  ? c.username.slice(0, 10) + '..'
                  : c.username}
                :
              </span>
              {c.text}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
