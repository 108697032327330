import React, { useEffect, useState } from 'react';
import {
  styled,
  TableCell,
  tableCellClasses,
  useTheme,
  Typography,
  Stack,
  Box,
  Divider,
  AccordionSummary,
  Fade,
} from '@mui/material';
import { ScorecardRows } from './ScorecardRows';
import { DetailedPlayer, Profile } from '../../../../types/player';
import { Question } from '../../../../types/game';

export const PlayerTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    width: '100px',
    height: '50px',
    borderLeft: '1px solid #333333',
    borderRight: '1px solid #333333',
  },
}));

export const formattedScore = (score: number) =>
  score > 0 ? `+${score}` : score;

type PropTypes = {
  players?: Record<string, DetailedPlayer>;
  profile?: Profile;
  profileId: string;
  questions: Question[];
};

export const ScorecardTable = ({
  players,
  profile,
  profileId,
  questions,
}: PropTypes) => {
  const theme = useTheme();
  const playersCount = players ? Object.keys(players).length : 0;
  const [opponentProfileId, setOpponentProfileId] = useState<string>();

  useEffect(() => {
    if (!players || !profileId) return;
    const opponentProfileId = getOpponentProfileId();
    if (opponentProfileId) {
      setOpponentProfileId(opponentProfileId);
    }
  }, [players, profileId]);

  const getOpponentProfileId = () => {
    const playerProfileIds = Object.keys(players);
    if (playerProfileIds.length < 2) return null;
    const opponentProfileId = playerProfileIds.filter(
      (id) => id !== profileId
    )[0];
    return opponentProfileId;
  };

  const showPlaceholderRow = () =>
    !players || (!players[profile?._id] && !players[profileId]);

  return (
    <Fade in={true} timeout={{ enter: 500, exit: 500 }}>
      <Box>
        <Box>
          <AccordionSummary
            sx={{
              bgcolor: '#242b28',
              borderBottom: '1px solid',
              borderColor: theme.palette.primary.dark,
              pl: playersCount >= 2 ? 1 : null,
              pr: playersCount >= 2 ? 0 : null,
              '&.MuiButtonBase-root': { minHeight: 35 },
              '.MuiAccordionSummary-content': { margin: 0 },
            }}
          >
            <Stack direction={'row'} width={'100%'} pl={2}>
              <Box width={'5%'}>
                <Typography variant="subtitle2">#</Typography>
              </Box>
              <Box width={'80%'}>
                <Typography
                  variant="subtitle2"
                  textAlign={'center'}
                  sx={{ color: theme.palette.dailies.main }}
                  fontWeight={600}
                >
                  Answer
                </Typography>
              </Box>
              <Box width={'15%'}>
                <Typography
                  variant="subtitle2"
                  textAlign={'center'}
                  sx={{ color: theme.palette.primary.main }}
                  fontWeight={600}
                >
                  PAR
                </Typography>
              </Box>
              <Divider orientation="vertical" />
              <Box
                width={playersCount >= 2 ? '80px' : '50px'}
                display={'flex'}
                justifyContent={playersCount >= 2 ? 'center' : 'right'}
              >
                <Typography
                  variant="subtitle2"
                  textAlign={'center'}
                  fontWeight={600}
                >
                  You
                </Typography>
              </Box>
              {opponentProfileId ? (
                <>
                  <Divider orientation="vertical" />
                  <Box
                    width={'80px'}
                    display={'flex'}
                    justifyContent={'center'}
                  >
                    <Typography
                      maxWidth={'50px'}
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                      whiteSpace={'pre'}
                    >
                      {players[opponentProfileId].username}
                    </Typography>
                  </Box>
                </>
              ) : null}
            </Stack>
          </AccordionSummary>
        </Box>
        {showPlaceholderRow() ? (
          <Box bgcolor={'#242b28'}>
            <Typography
              variant="subtitle2"
              color="warning.light"
              padding={1}
              textAlign={'center'}
            >
              Play the quiz to view your scorecard
            </Typography>
          </Box>
        ) : (
          <ScorecardRows
            profileId={profileId}
            questions={questions}
            players={players}
          />
        )}
      </Box>
    </Fade>
  );
};
