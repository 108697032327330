export const TIERS = [
  'Grandmaster',
  'Master',
  'Arcanus',
  'Virtus',
  'Stella',
  'Lumen',
];

// export const LINEAR_GRADIENTS = [
//   'linear-gradient(112deg, rgba(50,19,3,1) 0%, rgba(93,43,17,1) 69%, rgba(180,92,44,1) 100%)',
//   'linear-gradient(112deg, rgba(5,21,37,1) 0%, rgba(15,51,88,1) 69%, rgba(41,112,184,1) 100%)',
//   'linear-gradient(112deg, rgba(58,57,31,1) 0%, rgba(102,99,57,1) 69%, rgba(144,140,83,1) 100%)',
//   'linear-gradient(112deg, rgba(50,53,55,1) 0%, rgba(91,94,97,1) 69%, rgba(155,157,159,1) 100%)',
//   'linear-gradient(112deg, rgba(8,15,32,1) 0%, rgba(20,33,65,1) 69%, rgba(35,58,114,1) 100%)',
//   'linear-gradient(90deg, rgba(242, 235, 220, 1) 35%, rgba(255, 250, 240, 1) 80%, rgba(255, 255, 255, 1) 90%)',
// ];

export const LINEAR_GRADIENTS = {
  Grandmaster:
    'linear-gradient(112deg, rgba(50,19,3,1) 0%, rgba(93,43,17,1) 69%, rgba(180,92,44,1) 100%)',
  Master:
    'linear-gradient(112deg, rgba(5,21,37,1) 0%, rgba(15,51,88,1) 69%, rgba(41,112,184,1) 100%)',
  Arcanus:
    'linear-gradient(112deg, rgba(58,57,31,1) 0%, rgba(102,99,57,1) 69%, rgba(144,140,83,1) 100%)',
  Virtus:
    'linear-gradient(112deg, rgba(50,53,55,1) 0%, rgba(91,94,97,1) 69%, rgba(155,157,159,1) 100%)',
  Stella:
    'linear-gradient(112deg, rgba(8,15,32,1) 0%, rgba(20,33,65,1) 69%, rgba(35,58,114,1) 100%)',
  Lumen:
    'linear-gradient(90deg, rgba(242, 235, 220, 1) 35%, rgba(255, 250, 240, 1) 80%, rgba(255, 255, 255, 1) 90%)',
};

export const LINEAR_GRADIENTS_OPAQUE = {
  Grandmaster:
    'linear-gradient(112deg, rgba(50,19,3,0.6) 0%, rgba(93,43,17,0.6) 69%, rgba(180,92,44,0.6) 100%)',
  Master:
    'linear-gradient(112deg, rgba(5,21,37,0.6) 0%, rgba(15,51,88,0.6) 69%, rgba(41,112,184,0.6) 100%)',
  Arcanus:
    'linear-gradient(112deg, rgba(58,57,31,0.6) 0%, rgba(102,99,57,0.6) 69%, rgba(144,140,83,0.6) 100%)',
  Virtus:
    'linear-gradient(112deg, rgba(50,53,55,0.6) 0%, rgba(91,94,97,0.6) 69%, rgba(155,157,159,0.6) 100%)',
  Stella:
    'linear-gradient(112deg, rgba(8,15,32,0.6) 0%, rgba(20,33,65,0.6) 69%, rgba(35,58,114,0.6) 100%)',
  Lumen:
    'linear-gradient(90deg, rgba(242, 235, 220, 0.2) 35%, rgba(255, 250, 240, 0.2) 80%, rgba(255, 255, 255, 0.2) 90%)',
};
