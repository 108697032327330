import { Ave } from '../types';
import { Mode } from '../types/game';

export const LEAGUE_TIME_LIMIT = 30;
export const ARENA_TIME_LIMIT = 24;
export const TIME_LIMIT = 20;

export const PANICKY_FUZZY_SCORE = 0.76;
export const PASSABLE_FUZZY_SCORE = 0.84;
export const POISED_FUZZY_SCORE = 0.92;

export const MAX_PLAYERS_IN_TEAMS = 8;
export const MAX_LEAGUE_TEAM_SIZE = 5;
export const MAX_SOLO_GAMES_PER_DAY = 3;

export const LEAGUE_NAMES = {
  pro: 'Pro',
  diamond: 'Diamond',
  gold: 'Gold',
  'film-tv': 'Film & TV',
  sports: 'Sports',
};

export const AVES: Ave[] = [
  {
    name: 'bogey',
    score: 1,
    color: { dark: '', light: '' },
    shineColor: { dark: '', light: '' },
  },
  {
    name: 'par',
    score: 0,
    color: { dark: '#AC724C', light: '#cda68c' },
    shineColor: { dark: '#AC724CB3', light: '#cda68cb3' }, //'rgba(205, 166, 140, 0.7)',
  },
  {
    name: 'birdie',
    score: -1,
    color: { dark: '#00B600', light: '#00FF00' },
    shineColor: { dark: '#00B600B3', light: '#00FF00B3' }, //'rgba(0, 255, 0, 0.7)',
  },
  {
    name: 'eagle',
    score: -2,
    color: { dark: '#43B5C4', light: '#B0E0E6' },
    shineColor: { dark: '#43B5C4B3', light: '#B0E0E6B3' }, //'rgba(176, 224, 230, 0.7)',
  },
  {
    name: 'albatross',
    score: -3,
    color: { dark: '#D80073', light: '#FF1493' },
    shineColor: { dark: '#D80073B3', light: '#FF1493B3' }, //'rgba(255, 20, 147, 0.7)',
  },
  {
    name: 'condor',
    score: -4,
    color: { dark: '#C86E00', light: '#ff8c00' },
    shineColor: { dark: '#C86E00B3', light: '#ff8c00B3' }, //'rgba(255, 140, 0, 0.7)',
  },
  {
    name: 'ostrich',
    score: -5,
    color: { dark: '#E43CE4', light: '#EE82EE' },
    shineColor: { dark: '#E43CE4B3', light: '#EE82EEB3' }, //'rgba(238, 130, 238, 0.7)',
  },
  {
    name: 'pterodactyl',
    score: -6,
    color: { dark: '#AFA429', light: '#E6DF93' },
    shineColor: { dark: '#AFA429B3', light: '#E6DF93B3' }, // 'rgba(230, 223, 147, 0.7)',
  },
];

export const MODE_DISPLAY_NAME: Partial<Record<Mode, string>> = {
  solo: 'Solo',
  match: 'Match',
  teams: 'Teams',
  'sit-n-go': 'Sit & Go',
};

export const CATEGORY_SETS_LABEL: Record<string, string> = {
  all: 'All',
  fineArts: 'Fine Arts',
  hardSoftSciences: 'Hard & Soft Sciences',
  humanities: 'Humanities',
  popCulture: 'Pop Culture',
};

export const MODE_ABBR: Partial<Record<Mode, string>> = {
  solo: 'Solo',
  match: 'Match',
  'sit-n-go': 'SnG',
  teams: 'Teams',
};

export const PRO_CASUAL_DISPLAY: Record<string, string> = {
  pro: 'Pro',
  casual: 'Casual',
};

export const CHAT_COLORS = [
  '#FF6B6B', // Bright Red
  '#FFD93D', // Bright Yellow
  '#32FF6A', // Neon Green
  '#4D96FF', // Sky Blue
  '#C355F5', // Purple
  '#FF926B', // Orange
  '#6BCB77', // Mint Green
  '#FF61FF', // Pink
  '#00F5D4', // Turquoise
  '#F5F548', // Lemon Yellow
];

export const ABBREVIATED_CATEGORIES = {
  'Art & Architecture': 'A&A',
  Business: 'Biz',
  'Classical Music': 'ClsM',
  Culture: 'Cult',
  'Food & Drink': 'F&D',
  Film: 'Film',
  Geography: 'Geo',
  History: 'His',
  Literature: 'Lit',
  Math: 'Math',
  Niche: 'Nich',
  'Politics & Civics': 'P&C',
  'Philosophy & Religion': 'P&R',
  'Popular Music': 'PopM',
  Science: 'Sci',
  Sports: 'Sprt',
  Theater: 'Thtr',
  TV: 'TV',
  'Vocab & Language': 'V&L',
  'Video Games': 'VG',
};
