import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  DialogActions,
  DialogTitle,
  List,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import useMutateArenaSchedule from '../hooks/useMutateArenaSchedule';

type PropTypes = {
  onClose: () => void;
  onCreatedNewSchedule: () => void;
  open: boolean;
};

export const CreateNewArenaScheduleDialog = ({
  onClose,
  onCreatedNewSchedule,
  open,
}: PropTypes) => {
  const handleOnSuccess = () => {
    onCreatedNewSchedule();
    onClose();
  };

  const { postArenaSchedule } = useMutateArenaSchedule(handleOnSuccess);

  const [startingAt, setStartingAt] = useState(dayjs());
  const [arenaNight, setArenaNight] = useState<number>();
  const [timeLimit, setTimeLimit] = useState(24);
  const [arenaGames, setArenaGames] = useState<number[]>([
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  const handleCreateClick = () => {
    postArenaSchedule({
      arenaNight,
      startingAt: startingAt.valueOf(),
      timeLimit,
      arenaGames,
    });
  };

  const handleArenaNightChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setArenaNight(Number(e.target.value));
  };

  const handleTimeLimitChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTimeLimit(Number(e.target.value));
  };

  const handleSetChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newArenaGames = [...arenaGames];
    newArenaGames[index] = Number(e.target.value);
    setArenaGames(newArenaGames);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Arena Schedule</DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={4}>
          <Stack spacing={4}>
            <TextField
              value={arenaNight}
              label="Pub Night"
              placeholder="Enter Pub Night #"
              onChange={handleArenaNightChange}
              sx={{ width: 200 }}
            />

            <TextField
              value={timeLimit}
              label="Time Limit"
              placeholder="Enter time limit"
              onChange={handleTimeLimitChange}
              sx={{ width: 200 }}
            />

            <DateTimePicker
              value={startingAt}
              onChange={(date) => setStartingAt(date)}
              sx={{ my: 2 }}
            />
          </Stack>

          <Stack spacing={1}>
            {arenaGames.map((set, index) => (
              <TextField
                label={`Game ${index + 1}`}
                placeholder="Set #"
                onChange={(e) => handleSetChange(e, index)}
                sx={{ width: 100 }}
                size="small"
              />
            ))}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleCreateClick}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
