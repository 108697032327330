import { useQuery } from '@tanstack/react-query';
import { fetchArenaAchievements } from '../../../../api/apiServices';

const useFetchArenaAchievements = (dontFetch?: boolean) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['arenaAchievements'],
    queryFn: fetchArenaAchievements,
    enabled: !dontFetch,
  });

  return {
    refetch,
    isLoading,
    data,
  };
};

export default useFetchArenaAchievements;
