import { Drawer, IconButton, Divider, styled, Chip, List } from '@mui/material';
import React, { useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GameModesToggleGroup from './GameModesToggleGroup';
import { GameModeToggleOptions } from './constants';
import NavListItem from './NavListItem';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 160;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

type Props = {
  open: boolean;
  handleClose: () => void;
};

const PlayNav = ({ open, handleClose }: Props) => {
  const navigate = useNavigate();

  const [gameMode, setGameMode] = useState<GameModeToggleOptions>(null);

  const handleGameModeChange = (
    event: React.MouseEvent<HTMLElement>,
    gameMode: GameModeToggleOptions
  ) => {
    if (!gameMode) return;

    setGameMode(gameMode);

    if (gameMode === GameModeToggleOptions.NORMAL) {
      navigate('/play/normal');
    } else if (gameMode === GameModeToggleOptions.RANKED) {
      navigate('/play/ranked');
    } else if (gameMode === GameModeToggleOptions.PUNCHCARD) {
      navigate('/play/punchcard');
    }
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          ml: '200px',
          bgcolor: '#091015fa',
          width: drawerWidth,
          boxSizing: 'border-box',
        },
        '& .MuiPaper-root': {
          zIndex: 999,
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={handleClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <GameModesToggleGroup
        gameMode={gameMode}
        handleGameModeChange={handleGameModeChange}
      />
    </Drawer>
  );
};

export default PlayNav;
