export const SUB_CATEGORIES = [
  'Art & Architecture',
  'Business',
  'Classical Music',
  'Culture',
  'Film',
  'Food & Drink',
  'Geography',
  'History',
  'Literature',
  'Math',
  'Niche',
  'Philosophy & Religion',
  'Politics & Civics',
  'Popular Music',
  'Science',
  'Sports',
  'Theater',
  'TV',
  'Video Games',
  'Vocab & Language',
];
