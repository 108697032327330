import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Stack } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import Countdown, { zeroPad } from 'react-countdown';
import { getAvatarSrc } from '../api/cloudinary';
import { ConfirmReady } from '../types/game';

let timeout: any;

type PropTypes = {
  close: () => void;
  confirmReady: ConfirmReady;
  handleNotReadyClick: () => void;
  handleReadyClick: () => void;
  open: boolean;
};

export const ConfirmReadyDialog = ({
  close,
  confirmReady,
  handleNotReadyClick,
  handleReadyClick,
  open,
}: PropTypes) => {
  const { bannedCategories, confirmReadyExpiresAt, players } = confirmReady;
  const [disableReady, setDisableReady] = useState(false);
  const [disableNotReady, setDisableNotReady] = useState(false);

  useEffect(() => {
    if (open) {
      setDisableReady(false);
      setDisableNotReady(false);
    }
  }, [open]);

  useEffect(() => {
    if (!players) return;

    const notReadyPlayers = Object.values(players).filter(
      (p) => p.confirmedNotReady
    );

    if (notReadyPlayers.length > 0) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        close();
      }, 2000);
    }
  }, [players]);

  const theme = useTheme();

  const countdownRenderer = ({ minutes, seconds }: any) => {
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  const handleConfirmReadyExpired = () => {
    if (disableReady) return;
    onNotReadyClick();
  };

  const onReadyClick = () => {
    handleReadyClick();
    setDisableReady(true);
  };

  const onNotReadyClick = () => {
    handleNotReadyClick();
    setDisableReady(true);
    setDisableNotReady(true);
  };

  return (
    <Dialog
      open={open}
      maxWidth={'sm'}
      fullWidth
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <DialogTitle>
        <Typography variant="h5" textAlign={'center'}>
          READY UP
        </Typography>
        {confirmReadyExpiresAt ? (
          <Typography variant="h6" textAlign={'center'}>
            <Countdown
              date={confirmReadyExpiresAt}
              renderer={countdownRenderer}
              onComplete={handleConfirmReadyExpired}
            />
          </Typography>
        ) : null}
        <Stack
          direction="row"
          spacing={2}
          sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}
        >
          <Tooltip
            title={
              'A category will be banned if banned by at least 50% of the players in the current game'
            }
            placement="top"
          >
            <InfoIcon />
          </Tooltip>
          <Typography>Banned Categories:</Typography>
          {bannedCategories && bannedCategories?.length > 0 ? (
            bannedCategories?.map((c, i) => (
              <Typography sx={{ color: theme.palette.warning.main }}>
                {c}
                {bannedCategories.length > 1 && i === 0 ? ',' : ''}
              </Typography>
            ))
          ) : (
            <Typography>none</Typography>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent>
        {players &&
          Object.values(players).map((p) => (
            <Stack direction={'row'} width="100%">
              <Stack width="70%" spacing={2}>
                <Stack direction={'row'} width="100%" spacing={2}>
                  <Avatar
                    src={getAvatarSrc(p.avatarPublicId, p.avatarUrl, 50, 50)}
                    variant="rounded"
                    sx={{
                      height: 40,
                      width: 40,
                    }}
                  >
                    {!p.avatarPublicId ? p.username.charAt(0) : null}
                  </Avatar>
                  <Typography
                    sx={{
                      lineHeight: '40px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {p.username}
                  </Typography>
                </Stack>
              </Stack>
              <Stack width="30%" spacing={2} textAlign={'right'}>
                {p.confirmedNotReady ? (
                  <Typography
                    fontWeight={600}
                    sx={{
                      color: 'error.main',
                      lineHeight: '40px',
                    }}
                  >
                    Not Ready
                  </Typography>
                ) : (
                  <Typography
                    fontWeight={600}
                    sx={{
                      color: p.confirmedReady
                        ? 'success.main'
                        : 'secondary.main',
                      lineHeight: '40px',
                    }}
                  >
                    {p.confirmedReady ? 'Ready' : 'Pending'}
                  </Typography>
                )}
              </Stack>
            </Stack>
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={onNotReadyClick}
          fullWidth
          color="error"
          disabled={disableNotReady}
        >
          NOT READY
        </Button>
        <Button
          variant="contained"
          onClick={onReadyClick}
          fullWidth
          color="success"
          disabled={disableReady}
        >
          READY
        </Button>
      </DialogActions>
    </Dialog>
  );
};
