import { useNavigate } from 'react-router-dom';
import { useErrorDialog } from '../contexts/ErrorDialogProvider';
import { useGameContext } from '../contexts/GameProvider';
import {
  useInputSocketContext,
  useSocketContext,
  useSpectateSocketContext,
} from '../contexts/SocketProvider';
import { SocketCallback, LeagueGameType } from '../types';
import { GameDataFromServer } from '../types/game';

export const useGameManager = () => {
  const navigate = useNavigate();
  const { socket } = useSocketContext();
  const { inputSocket } = useInputSocketContext();
  const { spectateSocket } = useSpectateSocketContext();
  const { setMessage, setTitle, setOpen } = useErrorDialog();
  const {
    clearGameData,
    setGameId,
    setLeagueGameType,
    setGameDataFromServer,
    setSpectateGameId,
    setSpectateLeagueGameType,
    setSpectateGameDataFromServer,
    setShowGameChat,
    setShowPregame,
  } = useGameContext();

  const joinGameSuccess = (
    profileId: string,
    gameDataFromServer: GameDataFromServer
  ) => {
    setGameDataFromServer(gameDataFromServer);
    console.log('joinGameSuccess', gameDataFromServer);
    const { gameId, gameOver, gameStarted, leagueGameType, mode } =
      gameDataFromServer;
    setGameId(gameId);
    setLeagueGameType(leagueGameType);

    if (!gameDataFromServer.arenaSet) {
      setShowGameChat(true);
    }

    inputSocket.emit('joinLeagueGame', { gameId, profileId });
    if (gameStarted) {
      navigate('/game-loading', {
        state: {
          rejoin: true,
        },
      });
      return;
    }

    if (mode === 'teams') {
      setShowPregame(true);
    }
  };

  const handleJoinGameAsSpectator = (profileId: string, gameId: string) => {
    if (!socket) return;
    socket.emit(
      'joinGameAsSpectator',
      { gameId, profileId },
      (res: SocketCallback) => {
        if (res.status === 'ok') {
          const gameDataFromServer = res.data as GameDataFromServer;
          joinGameSuccess(profileId, gameDataFromServer);
        }
        if (res.status === 'failed') {
          requestFailed(res.message || '');
        }
      }
    );
  };

  const handleJoinLeagueGame = (profileId: string, gameId: string) => {
    if (!socket) return;
    socket.emit('joinGame', { gameId, profileId }, (res: SocketCallback) => {
      if (res.status === 'ok') {
        const gameDataFromServer = res.data as GameDataFromServer;
        joinGameSuccess(profileId, gameDataFromServer);
      }
      if (res.status === 'failed') {
        requestFailed(res.message || '');
      }
    });
  };

  const handleRejoinGame = (profileId: string, gameId: string) => {
    if (!socket) return;
    socket.emit('canRejoinGame', profileId, (res: SocketCallback) => {
      if (res.status === 'ok') {
        const gameData: GameDataFromServer = res.data;
        rejoinGameSuccess(profileId, gameData);
      }
      if (res.status === 'failed') {
        requestFailed(res.message || '');
      }
    });
  };

  const handleLeaveGame = (profileId: string, gameId: string) => {
    if (!socket) return;
    socket.emit('leaveGame', {
      gameId,
      profileId,
    });
    clearGameData();
    setShowPregame(false);
  };

  const handleRemoveGameClick = (profileId: string, gameId: string) => {
    if (!socket) return;
    socket.emit('deleteGame', {
      profileId,
      gameId,
    });
  };

  const handleSpectateClick = (profileId: string, gameId: string) => {
    if (!spectateSocket) return;
    spectateSocket.emit(
      'canSpectateGame',
      profileId,
      gameId,
      (res: SocketCallback) => {
        if (res.status === 'ok') {
          const gameData: GameDataFromServer = res.data;
          spectateGameSuccess(gameData);
        }
        if (res.status === 'failed') {
          requestFailed(res.message || '');
        }
      }
    );
  };

  const spectateGameSuccess = (gameDataFromServer: GameDataFromServer) => {
    const { gameId, leagueGameType } = gameDataFromServer;
    setSpectateGameId(gameId);
    setSpectateLeagueGameType(leagueGameType);
    setSpectateGameDataFromServer(gameDataFromServer);
    navigate('/spectate');
  };

  const handleCreateLeagueGame = (
    profileId: string,
    teamsInPlay: string[],
    season: number,
    week: number,
    gameNumber: number,
    leagueGameType: LeagueGameType
  ) => {
    if (!socket) return;
    socket.emit('createLeagueGame', {
      profileId: profileId,
      teamsInPlay,
      season,
      week,
      gameNumber,
      leagueGameType,
    });
  };

  const requestFailed = (message?: string) => {
    setOpen(true);
    if (message === 'Game in progress') {
      setTitle(message);
      setMessage(`You have a game in progress. Wait until it's over.`);
    } else if (message === 'Already in game') {
      setTitle(message);
      setMessage(
        `You already joined a game. Go to the lobby and leave that game to start a new one`
      );
    } else if (message === 'Player not found') {
      setTitle(message);
      setMessage(`Please refresh this page`);
    } else {
      setTitle(message);
    }
  };

  const rejoinGameSuccess = (
    profileId: string,
    gameDataFromServer: GameDataFromServer
  ) => {
    const { gameId } = gameDataFromServer;
    setGameId(gameId);
    setGameDataFromServer(gameDataFromServer);
    inputSocket.emit('joinLeagueGame', { gameId, profileId });
    navigate('/game-loading', {
      state: {
        rejoin: true,
      },
    });
  };

  const handleGenerateLeagueGames = (profileId: string) => {
    if (!socket) return;
    socket.emit('generateLeagueGames', {
      profileId,
      leagueName: 'gold',
    });
  };

  const handleCreateArenaGame = (
    profileId: string,
    arenaNight: number,
    arenaGame: number,
    arenaSet: number,
    timeLimit: number,
    arenaStartTimeMin: number
  ) => {
    if (!socket) return;
    socket.emit('createArenaGame', {
      profileId,
      arenaNight,
      arenaGame,
      arenaSet,
      timeLimit,
      arenaStartTimeMin,
    });
  };

  const handleCreateArenaTeamGamesForRound = (
    profileId: string,
    arenaNight: number,
    arenaRound: number,
    arenaSeason: number,
    arenaSet: number,
    timeLimit: number,
    arenaStartTimeMin: number
  ) => {
    if (!socket) return;
    socket.emit('createArenaTeamGamesForRound', {
      profileId,
      arenaNight,
      arenaRound,
      arenaSeason,
      arenaSet,
      timeLimit,
      arenaStartTimeMin,
    });
  };

  const handleStartScheduledLeagueGames = (profileId: string) => {
    if (!socket) return;
    socket.emit('startScheduledLeagueGames', {
      profileId,
      leagueName: 'gold',
      division: 't10',
    });
  };

  const handleStartArenaGame = (profileId: string, gameId: string) => {
    if (!socket) return;
    socket.emit('startArenaGame', {
      profileId,
      gameId,
    });
  };

  const handleStartArenaTeamGames = (profileId: string) => {
    if (!socket) return;
    socket.emit('startArenaTeamGamesForRound', {
      profileId,
    });
  };
  return {
    handleCreateLeagueGame,
    handleGenerateLeagueGames,
    handleJoinGameAsSpectator,
    handleJoinLeagueGame,
    handleRejoinGame,
    handleLeaveGame,
    handleRemoveGameClick,
    handleCreateArenaGame,
    handleCreateArenaTeamGamesForRound,
    handleSpectateClick,
    handleStartScheduledLeagueGames,
    handleStartArenaGame,
    handleStartArenaTeamGames,
  };
};
