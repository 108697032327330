import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box } from '@mui/system';

type PropTypes = {
  closeImageDisplay: () => void;
  imgSource: string;
  openImageDisplay: boolean;
};

export const ImageDisplay = ({
  closeImageDisplay,
  imgSource,
  openImageDisplay,
}: PropTypes) => {
  return (
    <Dialog open={openImageDisplay} onClose={closeImageDisplay} maxWidth={'md'}>
      <DialogContent sx={{ width: '100%', height: '100%' }}>
        <Box
          component="img"
          src={imgSource}
          alt="image"
          sx={{ width: '100%', height: 'auto', objectFit: 'contain' }}
        ></Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeImageDisplay} fullWidth>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
