import React from 'react';
import { Stack, Typography } from '@mui/material';

type Props = {
  message: string;
};

const MaintenanceBanner = ({ message }: Props) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      height={'36px'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        background:
          'linear-gradient(106deg, rgb(30 30 30) 0%, rgb(90 0 0) 50%, rgb(30 30 30) 100%)',
      }}
    >
      <Typography>{message}</Typography>
    </Stack>
  );
};

export default MaintenanceBanner;
