import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, DialogTitle, Stack, Typography, useTheme } from '@mui/material';
import Countdown from 'react-countdown';
import { countdownRendererMinutes } from '../utils/countdown';
import useQuickPlayManager from '../pages/quick-play/hooks/useQuickPlayManager';
import { LobbyGame } from '../types/game';
import { useLocation, useNavigate } from 'react-router-dom';
import useArenaGameManager from '../pages/arena/hooks/useArenaGameManager';

type PropTypes = {
  arenaGame: LobbyGame;
  onClose: () => void;
  open: boolean;
  profileId: string;
};

export const JoinArenaTeamGameDialog = ({
  arenaGame,
  onClose,
  open,
  profileId,
}: PropTypes) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const { arenaStartTime, gameId } = arenaGame;

  const { handleJoinArenaTeamGame } = useArenaGameManager();

  const handleJoinClick = () => {
    handleJoinArenaTeamGame(profileId, gameId);
    if (location.pathname !== '/game-session') {
      navigate('/pub');
    }
    onClose();
  };

  return (
    <Dialog open={open} maxWidth={'sm'} fullWidth>
      <DialogTitle textAlign={'center'}>JOIN PUB TEAM GAME</DialogTitle>
      <DialogContent>
        <Stack py={2}>
          <Typography fontFamily={'noto-sans'} textAlign={'center'} py={2}>
            Pub Game is starting in:
          </Typography>
          {arenaStartTime && (
            <Typography
              variant="h6"
              textAlign={'center'}
              sx={{
                pb: 1,
                color: theme.palette.primary.light,
                textShadow: `0 0 1px ${theme.palette.primary.main}, 10px 0px 20px ${theme.palette.primary.main}, 0px 0px 16px ${theme.palette.primary.main}`,
              }}
            >
              <Countdown
                date={arenaStartTime}
                renderer={countdownRendererMinutes}
                onComplete={onClose}
              />
            </Typography>
          )}
          <Box width={'100%'} display="flex" justifyContent={'center'} py={4}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleJoinClick}
              disabled={arenaGame.gameStarted || arenaGame.gameOver}
              size="large"
            >
              JOIN GAME
            </Button>
          </Box>
          <Box width={'100%'} display="flex" justifyContent={'center'}>
            <Button onClick={onClose}>NO THANKS</Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
