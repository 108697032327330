import React, { useState } from 'react';
import {
  IconButton,
  Avatar,
  Menu,
  Divider,
  MenuItem,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { Settings, Logout } from '@mui/icons-material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Profile } from '../../../types/player';
import { useLogout } from '../../../hooks/auth/useLogout';
import { DrawerHeader } from '../drawer-nav/drawer-header';
import { useProfileContext } from '../../../contexts/ProfileProvider';
import { useNavigate } from 'react-router-dom';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { getAvatarSrc } from '../../../api/cloudinary';

type PropTypes = {
  profile: Profile;
};

export const ProfileMenu = ({ profile }: PropTypes) => {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { clearProfile } = useProfileContext();

  const onLogoutSuccess = () => {
    clearProfile();
    navigate('/login');
  };

  const { mutate: logout } = useLogout(onLogoutSuccess);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleGameSettingsClick = () => {
    navigate('/game-settings');
    handleCloseUserMenu();
  };

  const handleHelpClick = () => {
    navigate('/help');
    handleCloseUserMenu();
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <IconButton
        onClick={handleOpenUserMenu}
        sx={{
          borderRadius: 1,
          '&:hover': {
            borderRadius: 1,
          },
        }}
      >
        <Avatar
          alt={profile?.username}
          src={getAvatarSrc(
            profile?.avatarPublicId,
            profile?.avatarUrl,
            80,
            80
          )}
          sx={{
            borderRadius: 1,
          }}
        />
      </IconButton>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              width: '300px',
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              // arrow for the popover
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
      >
        <DrawerHeader
          handleCloseUserMenu={handleCloseUserMenu}
          profile={profile}
        />
        <Divider />

        {/* <MenuItem onClick={handleCloseUserMenu}>
          <ListItemIcon>
            <AccountBoxIcon fontSize="small" />
          </ListItemIcon>
          <Typography textAlign="center">Account</Typography>
        </MenuItem> */}

        <MenuItem onClick={handleGameSettingsClick}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <Typography textAlign="center">Game Settings</Typography>
        </MenuItem>

        <MenuItem onClick={handleHelpClick}>
          <ListItemIcon>
            <HelpOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography textAlign="center">Help</Typography>
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
