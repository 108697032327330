import { useQuery } from '@tanstack/react-query';
import { fetchArenaTeams } from '../../../../api/apiServices';

const useFetchArenaTeams = (season?: number) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'arenaTeams',
      {
        season,
      },
    ],
    queryFn: fetchArenaTeams,
    enabled: !!season,
  });

  return {
    refetch,
    isLoading,
    data,
  };
};

export default useFetchArenaTeams;
