import { Box, Container } from '@mui/material';
import React from 'react';
import { SideNav } from '../navigation/SideNav';
import PlayNav from '../navigation/PlayNav';
import { useQuickPlayGamesContext } from '../contexts/QuickPlayGamesProvider';
import { HEADER_HEIGHT } from '../components/header';
import { useLobbyGamesContext } from '../contexts/LobbyGamesProvider';

type Props = {
  children: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
};

const BasicLayout = ({ children, maxWidth }: Props) => {
  const { maintenance } = useLobbyGamesContext();
  const { openPlayDrawer, setOpenPlayDrawer } = useQuickPlayGamesContext();

  return (
    <>
      <SideNav handleOpenPlayDrawer={() => setOpenPlayDrawer(true)} />

      <PlayNav
        open={openPlayDrawer}
        handleClose={() => setOpenPlayDrawer(false)}
      />

      <Box
        sx={{
          height: `calc(100vh - ${HEADER_HEIGHT}px)`,
          ml: openPlayDrawer ? '360px' : '200px',
          transition: 'margin-left 0.2s ease-in-out',
          p: 4,
          mt: maintenance.scheduled ? 8 : 0,
        }}
      >
        <Container maxWidth={maxWidth || 'lg'}>{children}</Container>
      </Box>
    </>
  );
};

export default BasicLayout;
