import React from 'react';

type Props = {
  glow?: boolean;
  handleLogoClick: () => void;
};

export const Logo = ({ glow, handleLogoClick }: Props) => {
  return (
    <div
      style={{
        cursor: 'pointer',
        filter: glow
          ? 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5))'
          : 'inherit',
      }}
    >
      <img
        alt="PAR"
        src="/pink_logo.png"
        className="par-logo-dark-league"
        onClick={handleLogoClick}
      ></img>
    </div>
  );
};
