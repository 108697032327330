import { useQuery } from '@tanstack/react-query';
import { fetchDailySetsPlayed } from '../../../api/apiServices';

const useFetchDailySetsPlayed = (profileId: string) => {
  const { data } = useQuery({
    queryKey: ['dailySetsPlayed', { profileId }],
    queryFn: fetchDailySetsPlayed,
  });

  return {
    data,
  };
};

export default useFetchDailySetsPlayed;
