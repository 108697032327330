import {
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemButton,
  useTheme,
  Stack,
  Typography,
  IconButton,
} from '@mui/material';
import React, { useMemo } from 'react';
import { Box } from '@mui/system';
import { getAvatarSrc } from '../../../api/cloudinary';
import { ArenaTeamLiveRoundScores, Player } from '../../../types/player';
import { ArenaTeam, SortedArenaTeam } from '../../arena-team/types';
import { assignArenaTeamRanks } from '../../../utils/game';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { getFuzzyPenalty } from './utils';

type PropTypes = {
  arenaTeamId?: string;
  arenaTeams: ArenaTeam[];
  arenaTeamLiveRoundScores: ArenaTeamLiveRoundScores;
  handleTeamClick: (teamId: string) => void;
  selectedTeamId?: string;
  sortedPlayers?: Player[];
  spectate?: boolean;
  teamBestBalls?: Record<string, Player[]>;
};

export const ArenaTeamsLiveLeaderboard = ({
  arenaTeamId,
  arenaTeams,
  arenaTeamLiveRoundScores,
  handleTeamClick,
  selectedTeamId,
  sortedPlayers,
  spectate,
  teamBestBalls,
}: PropTypes) => {
  const theme = useTheme();

  const [animationParent] = useAutoAnimate<any>({
    duration: 250,
  });

  const sortedArenaTeams = useMemo(
    () => formatArenaTeams(arenaTeams, arenaTeamLiveRoundScores),
    [arenaTeams, arenaTeamLiveRoundScores]
  );

  return (
    <Stack>
      <Stack
        position="relative"
        justifyContent={'center'}
        alignItems={'center'}
        py={1}
      >
        <Typography
          variant="body2"
          fontWeight={600}
          color="primary"
          textAlign={'center'}
        >
          TEAMS ({sortedArenaTeams.length})
        </Typography>
      </Stack>

      <List
        dense={true}
        ref={animationParent}
        sx={{
          padding: 0,
        }}
      >
        {sortedArenaTeams?.map((t, i) => (
          <Box key={t._id}>
            <ListItem
              disablePadding
              sx={{
                mt: 1,
                bgcolor:
                  selectedTeamId === t._id && spectate
                    ? '#585858c2'
                    : 'transparent',
                border:
                  t._id === arenaTeamId && !spectate ? '1px solid' : 'none',
                borderColor: t._id === arenaTeamId ? 'primary.dark' : 'none',
              }}
            >
              <ListItemButton onClick={() => handleTeamClick(t._id)}>
                <ListItemText
                  primary={t.rank + '.'}
                  sx={{
                    marginRight: 1,
                    textShadow: `0 0 0px ${theme.palette.primary.dark}, 20px 0px 40px ${theme.palette.primary.dark}, 0px 0px 16px ${theme.palette.primary.dark}`,
                  }}
                  primaryTypographyProps={{
                    style: {
                      fontWeight: 600,
                    },
                  }}
                />
                <Avatar
                  src={getAvatarSrc(t.avatarPublicId, t.avatarUrl, 50, 50)}
                  variant="rounded"
                  sx={{
                    height: 20,
                    width: 20,
                  }}
                >
                  {!t.avatarUrl ? t.teamName.charAt(0) : null}
                </Avatar>
                <ListItemText
                  primary={t.teamName}
                  sx={{
                    marginLeft: 1,
                    marginRight: 2,
                    width: { sm: '150px' },
                    textShadow: `0 0 0px ${theme.palette.primary.dark}, 20px 0px 40px ${theme.palette.primary.dark}, 0px 0px 8px ${theme.palette.primary.dark}`,
                  }}
                  primaryTypographyProps={{
                    style: {
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    },
                  }}
                />
                <ListItemText
                  primary={`${t.parScore > 0 ? '+' : ''}${t.parScore}`}
                  sx={{ textAlign: 'right' }}
                  primaryTypographyProps={{
                    style: {
                      fontWeight: 600,
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            {teamBestBalls?.[t._id] &&
              teamBestBalls[t._id].map((p) => {
                const totalScore =
                  p.currentScore + getFuzzyPenalty(p.fuzzyScore);
                const rank = sortedPlayers?.find(
                  (player) => player.profileId === p.profileId
                )?.rank;
                return (
                  <ListItem disablePadding sx={{ pl: 2, pr: 0.5 }}>
                    <ListItemButton
                      key={p.profileId}
                      onClick={() => handleTeamClick(t._id)}
                    >
                      <ListItemText
                        primary={rank + '.'}
                        sx={{
                          marginRight: 1,
                        }}
                      />
                      <Avatar
                        src={getAvatarSrc(
                          p.avatarPublicId,
                          p.avatarUrl,
                          50,
                          50
                        )}
                        variant="rounded"
                        sx={{
                          height: 20,
                          width: 20,
                        }}
                      >
                        {!p.avatarUrl ? p.username.charAt(0) : null}
                      </Avatar>
                      <ListItemText
                        primary={p.username}
                        sx={{
                          marginLeft: 1,
                          marginRight: 2,
                          width: { sm: '150px' },
                        }}
                        primaryTypographyProps={{
                          style: {
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            color: theme.palette.success.main,
                          },
                        }}
                      />
                      {p.currentScore !== undefined &&
                      p.currentScore !== null ? (
                        <ListItemText
                          primary={`${totalScore > 0 ? '+' : ''}${totalScore}`}
                          sx={{ textAlign: 'right' }}
                          primaryTypographyProps={{
                            style: {
                              width: '40px',
                              textAlign: 'left',
                              color: theme.palette.success.main,
                            },
                          }}
                        />
                      ) : null}
                      <ListItemText
                        primary={`${p.parScore > 0 ? '+' : ''}${p.parScore}`}
                        sx={{ textAlign: 'right' }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </Box>
        ))}
      </List>
    </Stack>
  );
};

const formatArenaTeams = (
  arenaTeams: ArenaTeam[],
  arenaTeamLiveRoundScores: ArenaTeamLiveRoundScores
) => {
  const arenaTeamsWithScores: SortedArenaTeam[] = [];
  for (const teamId of Object.keys(arenaTeamLiveRoundScores)) {
    const arenaTeam = arenaTeams.find((t) => t._id === teamId);
    if (!arenaTeam) {
      continue;
    }

    let parScore = 0;
    for (const questionIndex of Object.keys(arenaTeamLiveRoundScores[teamId])) {
      const value = arenaTeamLiveRoundScores[teamId][questionIndex] as any;

      // this is a bad construct coming from the "input server"
      const score = typeof value !== 'number' ? 0 : value;

      parScore = parScore + (score || 0);
    }

    arenaTeamsWithScores.push({
      ...arenaTeam,
      parScore,
      rank: 0,
    });
  }

  const rankedArenaTeams = assignArenaTeamRanks(
    arenaTeamsWithScores.sort((a, b) => a.parScore - b.parScore)
  );

  return rankedArenaTeams;
};
