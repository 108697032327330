import React from 'react';
import { Box, Typography } from '@mui/material';

export const TOTAL_WIDTH = 200;
export const SLANTED_LINE_WIDTH = 40;
export const MIRROR_OFFSET = 16;
const LINE_PADDING = 16;
const LINE_HEIGHT = '1px';

type PropTypes = {
  playerInfoDisplayPosition: 'left' | 'right';
  text: string;
};

const PlayerSlantedLineTitle = ({
  playerInfoDisplayPosition,
  text,
}: PropTypes) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: LINE_HEIGHT,
        width: TOTAL_WIDTH,
        '&::before': {
          content: '""',
          position: 'absolute',
          height: LINE_HEIGHT,
          backgroundColor: '#F8EE95',
          width: SLANTED_LINE_WIDTH,
          transform:
            playerInfoDisplayPosition === 'left'
              ? 'rotate(-20deg)'
              : 'rotate(20deg)',
          transformOrigin:
            playerInfoDisplayPosition === 'left' ? 'right' : 'left',
          left:
            playerInfoDisplayPosition === 'left'
              ? '0'
              : TOTAL_WIDTH - SLANTED_LINE_WIDTH + LINE_PADDING,
          top: '0',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          height: LINE_HEIGHT,
          backgroundColor: '#F8EE95',
          width: TOTAL_WIDTH - SLANTED_LINE_WIDTH - LINE_PADDING,
          left:
            playerInfoDisplayPosition === 'left'
              ? SLANTED_LINE_WIDTH
              : MIRROR_OFFSET + LINE_PADDING,
          top: '0',
        },
      }}
    >
      <Typography
        sx={{
          position: 'absolute',
          bottom: '4px',
          left:
            playerInfoDisplayPosition === 'left'
              ? SLANTED_LINE_WIDTH
              : MIRROR_OFFSET,
          width: TOTAL_WIDTH - SLANTED_LINE_WIDTH,
          maxWidth: TOTAL_WIDTH - SLANTED_LINE_WIDTH,
          textAlign: 'center',
          zIndex: 2,
          whiteSpace: 'normal',
          overflowWrap: 'break-word',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default PlayerSlantedLineTitle;
