import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, Typography, useTheme } from '@mui/material';

type PropTypes = {
  close: () => void;
  open: boolean;
};

export const SoloPunchcardInfoDialog = ({ close, open }: PropTypes) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="sm"
      sx={{
        '& .paper': {
          borderStyle: 'solid',
          borderColor: theme.palette.ranked.main,
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4" textAlign={'center'} mb={2}>
          What is the Solo Punchcard?
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ width: '100%', height: '100%' }}>
        <Stack spacing={4}>
          <Typography>
            For those that enjoy playing solo games, the Punchcard is a feature
            where you can track your progress across different difficulties and
            categories.
          </Typography>
          <Typography>
            Click inside a box to see details for that difficulty-category
          </Typography>
          <Typography>
            Many top players have impressive Punchcards. Browse their profiles
            to see what has been achieved.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close} fullWidth>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
