import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';

import { useGameContext } from '../../contexts/GameProvider';
import { useProfileContext } from '../../contexts/ProfileProvider';
import { StyledPaperItem } from '../../mui-theme/mui-components';
import useFetchUserArenaAchievements from './ArenaProfile/hooks/useFetchUserArenaAchievements';
import { ProfileHeader } from './ProfileHeader/ProfileHeader';
import { useParams } from 'react-router-dom';
import { EditUsernameDialog } from './ProfileHeader/EditUsernameDialog';
import useFetchProfileById from '../../hooks/useFetchProfileById';
import { ProfileTab } from './types';
import ProfileTabs from './ProfileTabs';
import ArenaProfile from './ArenaProfile/ArenaProfile';
import QuickPlayProfile from './QuickPlayProfile/QuickPlayProfile';
import DailiesProfile from './DailiesProfile/DailiesProfile';
import useFetchQuickPlayAllAchievements from './hooks/useGetAllAchievements';
import ProfileSidebar from './ProfileSidebar/ProfileSidebar';
import useFetchArenaCurrentSeason from './ArenaProfile/hooks/useFetchArenaCurrentSeason';
import BasicLayout from '../../BasicLayout/BasicLayout';

const ProfilePage = () => {
  const { profileId } = useParams();

  const { profile } = useProfileContext();

  const { profile: viewProfile } = useFetchProfileById(profileId);

  const { setArenaAchievements } = useGameContext();

  const { data: arenaCurrentSeasonData } = useFetchArenaCurrentSeason();

  const { data: quickPlayAllAchievements } = useFetchQuickPlayAllAchievements();

  const arenaSeason = arenaCurrentSeasonData?.arenaSeason;
  const arenaSeasonEnd = arenaCurrentSeasonData?.arenaSeasonEnd;

  const { data: arenaAchievementsData, refetch } =
    useFetchUserArenaAchievements(viewProfile?._id, arenaSeason);

  const quickPlayShowcase = viewProfile?.achievementsShowcase
    ? quickPlayAllAchievements?.filter((a) =>
        viewProfile?.achievementsShowcase.includes(a.name)
      )
    : [];

  const [openEditUsernameDialog, setOpenEditUsernameDialog] = useState(false);

  useEffect(() => {
    if (!arenaAchievementsData) return;
    setArenaAchievements(arenaAchievementsData.arenaAchievements);
  }, [arenaAchievementsData]);

  const [activeTab, setActiveTab] = useState<ProfileTab>('overview');

  const handleBreadcrumbClick = (tab: ProfileTab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <BasicLayout>
        <Grid container spacing={3} className="CONTAINER">
          <Grid item xs={12} sm={8}>
            <Stack pb={20}>
              <ProfileHeader
                allowEdits={profile?._id === profileId}
                handleEditUsernameClick={() => setOpenEditUsernameDialog(true)}
                viewProfile={viewProfile}
              />

              <StyledPaperItem
                sx={{ display: { xs: 'block', sm: 'none' }, mb: 2 }}
              >
                <ProfileSidebar
                  arenaAchievementsData={arenaAchievementsData}
                  canEditShowcase={profile?._id === viewProfile?._id}
                  onEditSuccess={refetch}
                  profileId={profileId}
                  quickPlayShowcase={quickPlayShowcase}
                />
              </StyledPaperItem>

              <ProfileTabs
                activeTab={activeTab}
                handleBreadcrumbClick={handleBreadcrumbClick}
              />

              {activeTab === 'arena' && viewProfile ? (
                <ArenaProfile
                  isAdmin={profile?.admin}
                  arenaSeason={arenaSeason}
                  arenaSeasonEnd={arenaSeasonEnd}
                  profileId={profile?._id}
                  viewProfile={viewProfile}
                />
              ) : activeTab === 'overview' && viewProfile ? (
                <QuickPlayProfile viewProfile={viewProfile} />
              ) : activeTab === 'dailies' && viewProfile ? (
                <DailiesProfile
                  isAdmin={profile?.admin}
                  profileId={profile?._id}
                  viewProfile={viewProfile}
                />
              ) : null}
            </Stack>
          </Grid>

          <Grid item xs={0} sm={4} display={{ xs: 'none', sm: 'block' }}>
            <Stack sx={{ position: 'sticky', top: 32 }}>
              <ProfileSidebar
                arenaAchievementsData={arenaAchievementsData}
                canEditShowcase={profile?._id === viewProfile?._id}
                onEditSuccess={refetch}
                profileId={profileId}
                quickPlayShowcase={quickPlayShowcase}
              />
            </Stack>
          </Grid>
        </Grid>
      </BasicLayout>

      {profile ? (
        <EditUsernameDialog
          close={() => setOpenEditUsernameDialog(false)}
          fullName={profile.fullName}
          handleSaveSuccess={() => {}}
          location={profile.location}
          open={openEditUsernameDialog}
          profileId={profile._id}
          genderPronoun={profile.genderPronoun}
          username={profile.username}
        />
      ) : null}
    </>
  );
};

export default ProfilePage;
