import { useMutation } from '@tanstack/react-query';
import { fetchArenaCategoryQuestions } from '../../../../api/apiServices';
import { Question } from '../../../../types/game';

const useFetchArenaCategoryQuestions = (
  handleOnSuccess: (data: any) => void
) => {
  const { mutate } = useMutation({
    mutationFn: fetchArenaCategoryQuestions,
    onSuccess: handleOnSuccess,
  });

  return {
    fetchArenaCategoryQuestions: mutate,
  };
};

export default useFetchArenaCategoryQuestions;
