import {
  Stack,
  Typography,
  ListItem,
  ListItemButton,
  ListItemText,
  Avatar,
  useTheme,
  List,
  Box,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import { getAvatarSrc } from '../../../api/cloudinary';
import { Player, RoundScore } from '../../../types/player';
import { formattedScore } from '../../../components/scorecard';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getFuzzyPenalty } from './utils';

type Props = {
  profileId: string;
  questionIndex?: number;
  roundScores: Record<number, RoundScore>;
  sortedPlayers: Player[];
};

const MyStatsLeaderboard = ({
  profileId,
  questionIndex,
  roundScores,
  sortedPlayers,
}: Props) => {
  const theme = useTheme();

  const [show, setShow] = useState(false);

  const underParScoreBallStyle = {
    bgcolor: `${theme.palette.success.main}40`,
    color: 'text.primary',
    textAlign: 'center',
    boxShadow: `0 0 1px 1px ${theme.palette.success.main}, 0 0 1px 1px ${theme.palette.success.main} inset`,
  };

  const timedoutScoreBallStyle = {
    bgcolor: `${theme.palette.error.main}40`,
    color: 'error.contrastText',
    display: 'inline-flex',
    boxShadow: `0 0 1px 1px ${theme.palette.error.main}, 0 0 1px 1px ${theme.palette.error.main} inset`,
  };

  const player = sortedPlayers?.find(
    (player) => player.profileId === profileId
  );

  const totalScore = player?.currentScore + getFuzzyPenalty(player.fuzzyScore);

  const nameColor = (player: Player, questionIndex?: number) => {
    if (questionIndex === undefined) return 'white';
    if (player.timerLimitReached) return theme.palette.error.main;
    if (player.currentScore !== null) return theme.palette.success.main;
    return 'white';
  };

  const getScoreBall = (
    index: number,
    roundScore: RoundScore,
    questionIndex?: number
  ) => {
    const score = roundScore.score;

    let scoreStyle: any = {
      bgcolor: theme.palette.mode === 'dark' ? '#434343' : 'undefined',
      color: 'error.contrastText',
    };
    let avatarShape: any = 'rounded';
    if (score < 0) {
      scoreStyle = underParScoreBallStyle;
      avatarShape = 'round';
    }
    if (roundScore.timerLimitReached) {
      scoreStyle = timedoutScoreBallStyle;
      avatarShape = 'square';
    }

    return (
      <Stack key={index}>
        <Avatar
          sx={{
            ...scoreStyle,
            height: 22,
            width: 22,
            fontSize: 12,
            fontWeight: 600,
          }}
          variant={avatarShape}
        >
          {score === null ? '?' : formattedScore(score)}
        </Avatar>
        {questionIndex === index && <ArrowDropUpIcon color="secondary" />}
      </Stack>
    );
  };

  return (
    <Stack
      sx={{
        bgcolor: '#1f314c8c',
      }}
    >
      <Stack
        position="relative"
        justifyContent={'center'}
        alignItems={'center'}
        py={1}
      >
        <IconButton
          sx={{ position: 'absolute', left: 2 }}
          onClick={() => setShow((prev) => !prev)}
        >
          {show ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>

        <Typography
          variant="body2"
          fontWeight={600}
          color="primary"
          textAlign={'center'}
        >
          MY STATS
        </Typography>
      </Stack>

      {show && (
        <>
          <List dense={true} sx={{ pt: 0 }}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  primary={player?.rank + '.'}
                  sx={{
                    marginRight: 1,
                  }}
                  primaryTypographyProps={{
                    style: {
                      fontWeight: 600,
                    },
                  }}
                />

                <Avatar
                  src={getAvatarSrc(
                    player?.avatarPublicId,
                    player?.avatarUrl,
                    50,
                    50
                  )}
                  variant="rounded"
                  sx={{
                    height: 20,
                    width: 20,
                  }}
                >
                  {!player?.avatarUrl ? player?.username.charAt(0) : null}
                </Avatar>

                <ListItemText
                  primary={player?.username}
                  sx={{
                    marginLeft: 1,
                    marginRight: 2,
                    width: { sm: '150px' },
                  }}
                  primaryTypographyProps={{
                    style: {
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      fontWeight: 600,
                      color: nameColor(player, questionIndex),
                    },
                  }}
                />
                {player?.currentScore !== undefined &&
                player?.currentScore !== null ? (
                  <ListItemText
                    primary={`${totalScore > 0 ? '+' : ''}${totalScore}`}
                    sx={{ textAlign: 'right' }}
                    primaryTypographyProps={{
                      style: {
                        width: '40px',
                        textAlign: 'left',
                        color: nameColor(player, questionIndex),
                      },
                    }}
                  />
                ) : null}
                <ListItemText
                  primary={`${player?.parScore > 0 ? '+' : ''}${
                    player?.parScore
                  }`}
                  sx={{ textAlign: 'right' }}
                  primaryTypographyProps={{
                    style: {
                      fontWeight: 600,
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>

          <Stack direction={'row'} spacing={1} justifyContent={'center'}>
            {Object.values(roundScores).map((rs, i) =>
              getScoreBall(i, rs, questionIndex)
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default MyStatsLeaderboard;
